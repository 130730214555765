/* eslint-disable @typescript-eslint/no-explicit-any */
import BlocQuerySet from '../BlocQuery/BlocQuerySet';
import DocumentReferenceSet from './DocumentReferenceSet';
import type { BlocCache } from './BlocCache';
import type { BlocCacheUpdateOptions } from '../types';
import type {
  BlocCachedDocumentSnapshotListener, BlocErrorFunction, BlocQueryConstraint, BlocQuerySnapshotListener, BlocUnsubscribeFunction, DeleteDocumentOptions
} from '../types';
import type { DocumentData } from '@mindhiveoy/firebase-schema';
import type { WithId } from '@mindhiveoy/schema';
import type CachedDocumentReference from './CachedDocumentReference';
import type DatabaseController from '../databaseController/DatabaseController';

export type CollectionReferenceListener<T extends DocumentData> = (docs: DocumentReferenceSet<T>) => void;

export type DataChangeCallbackFunction<T> = (args: {
  docId: string;
  oldData: T | null;
  newData: T | null;
  dispatchChangeEvent: () => void;
}) => void;

export interface AddDocumentReferenceArgs<T> {
  docId: string;
  candidate?: boolean;
  initialData?: T;
  onDataChange?: DataChangeCallbackFunction<T>;
}

/**
 * An item controlling a single collection reference in the global cache.
 */
export class CachedCollectionReference<T extends DocumentData> {
  private $referenceCount = 0;

  /**
   * Set containing an instance for each active document reference in the
   * collection. The document reference will be active, when there is a
   * snapshot listener for some query or a single document referring into it.
   */
  private $documentReferenceSet: DocumentReferenceSet<T>;
  private $querySet!: BlocQuerySet<T>;
  /**
   *
   * @param {string} collectionPath
   * @param {DatabaseController<T>} controller
   */
  constructor(
    public readonly collectionPath: string,
    private controller: DatabaseController<T>,
    public readonly cache: BlocCache
  ) {
    this.$documentReferenceSet = new DocumentReferenceSet<T>(
      this,
      controller.ignoredFields
    );
    // this.$documentReferenceSet.addDocumentReferenceListener(
    //   this.onDocumentReferenceChange
    // );
  }
  /**
   * @return {number} The number of active references.
   */
  public get referenceCount(): number {
    return this.$referenceCount;
  }
  /**
   * Add a new reference to collection.
   * @return {number} The number of references left to this collection.
   */
  addRef = (): number => {
    return this.$referenceCount++;
  };
  /**
   * Remove a single reference from collection.
   * @return {number} The number of references left to this collection.
   */
  removeRef = (): number => {
    return --this.$referenceCount;
  };
  /**
   * Indicates if the document exists in collection has been initialized.
   * @param {string} docId
   * @return {boolean} True if the document exists in cache.
   */
  isDocumentCached(docId: string): boolean {
    return this.$documentReferenceSet.isDocumentCached(docId);
  }
  /**
   * Change document reference's id in the document reference set
   * from from oldId to newId.
   * @param {string}  fromDocId
   * @param {string}  toDocId
   */
  changeDocumentId(fromDocId: string, toDocId: string) {
    this.$documentReferenceSet.changeDocumentId(fromDocId, toDocId);
  }
  /**
   *
   * @param {string} docIdCandidate
   * @param {T} data
   * @return {any}
   */
  public create = async (docIdCandidate: string, data: T): Promise<{
    docId: any;
    data: any;
  }> => {
    // TODO: Implementation
    return {
      docId: docIdCandidate,
      data,
    };
  };
  /**
   *
   * @param {string}    docId
   * @return {boolean}  True when document with id exists, otherwise false
   */
  public has = (docId: string): boolean => {
    return this.$documentReferenceSet.exists(docId);
  };
  /**
   * Release resources before destruction.
   */
  dispose = () => {
    this.$documentReferenceSet?.dispose();
    this.$querySet?.dispose();
    this.controller.dispose();
    this.cache.removeCollectionReference(this.collectionPath);
  };
  /**
   * Get a document reference from the collection.
   * @param {string} docId The document id
   * @return {CachedDocumentReference<T> | undefined}}
   */
  getDocumentReference = (docId: string) => {
    const {
      docRef,
    } = this.$documentReferenceSet.get(docId, {
      method: 'create',
    });
    return docRef;
  };
  /**
   * Add reference to a specific document in collection.
   * @param {string}   docId         The unique id of the document.
   * @param {boolean}  candidate     Indicated, if the document id has been created optimistic manner at the front end. When the id for a new
   *                                 document is being created at the frond end, it may be used locally until it will be verified or being rejected
   *                                 by the backend.
   * @param {T=}       initialData   The initial data, when document is created within the call.
   * @return {DocumentReference<T>}  Document reference
   */
  addDocumentReference = ({
    docId,
    candidate,
    initialData,
    onDataChange,
  }: AddDocumentReferenceArgs<T>
  ) => {
    const docExists = this.$documentReferenceSet.exists(docId);

    const {
      docRef,
    } = this.$documentReferenceSet.get(docId, {
      method: docExists ? 'get' : 'set',
      candidate,
      initialData,
      onDataChange,
    });
    docRef?.addRef();

    if (!docExists && docRef) {
      this.$querySet?.validate([docRef,]);
    }
    return docRef;
  };

  public fetchDoc = async (docId: string) => {
    if (!this.$querySet) {
      this.$querySet = new BlocQuerySet(this.$documentReferenceSet);
    }
    return this.$querySet.fetchDoc(
      docId,
      this.controller
    );
  };

  /**
   *
   * @param {BlocQueryConstraint[]}query
   * @param {BlocQuerySnapshotListener<T>}onSnapshot
   * @return {any}
   */
  public onQuerySnapshot = (
    query: BlocQueryConstraint[] = [],
    onSnapshot: BlocQuerySnapshotListener<T>,
    onError: BlocErrorFunction
  ) => {
    if (!this.$querySet) {
      this.$querySet = new BlocQuerySet(this.$documentReferenceSet);
    }
    return this.$querySet.onSnapshot({
      query,
      create: (query, updateDocs) => {
        const unsubscribe = this.controller.onQuerySnapshot(
          query,
          updateDocs,
          onError
        );
        return () => unsubscribe && unsubscribe();
      },
      onSnapshot,
    });
  };

  /**
   *
   * @param {string}  docId
   * @param {keyof T[]} fields
   * @param {BlocCachedDocumentSnapshotListener<T>} onSnapshot
   * @param {BlocErrorFunction=} onError
   *
   * @return {BlocUnSubscribeFunction}
   */
  public onDocumentSnapshot(
    docId: string,
    fields: (keyof T)[],
    onSnapshot: BlocCachedDocumentSnapshotListener<T>,
    onError: BlocErrorFunction
  ): BlocUnsubscribeFunction;
  /**
   *
   * @param {string}  docId
   * @param {BlocCachedDocumentSnapshotListener<T>} onSnapshot
   * @param {BlocErrorFunction=} onError
   *
   * @return {BlocUnSubscribeFunction}
   */
  public onDocumentSnapshot(
    docId: string,
    onSnapshot: BlocCachedDocumentSnapshotListener<T>,
    onError: BlocErrorFunction
  ): BlocUnsubscribeFunction;
  /**
   *
   * @param {string} docId
   * @param {any} fieldsOrSnapshotListener
   * @param {any} onSnapshotOrError
   * @param {any} errorOrNothing
   * @return {BlocUnsubscribeFunction}
   */
  public onDocumentSnapshot(
    docId: string,
    fieldsOrSnapshotListener: (keyof T)[] | BlocCachedDocumentSnapshotListener<T>,
    onSnapshotOrError: BlocCachedDocumentSnapshotListener<T> | BlocErrorFunction,
    errorOrNothing?: BlocErrorFunction
  ): BlocUnsubscribeFunction {
    const {
      fields,
      onError,
      onSnapshot,
    } = this.resolveDocumentSnapshotArgs(
      fieldsOrSnapshotListener,
      onSnapshotOrError,
      errorOrNothing! // TODO: Fix this
    );

    return this.$documentReferenceSet.onSnapshot({
      docId,
      fields,
      create: (query, updateDoc) => {
        return this.controller.onDocumentSnapshot(
          query,
          updateDoc as any, // TODO: Fix this for TS5
          onError
        );
      },
      onSnapshot,
    });
  }
  /**
   * Resolve method overloading args.
   *
   * @param {any} fieldsOrSnapshotListener
   * @param {any} onSnapshotOrError
   * @param {any} onErrorOrNothing
   * @return {any}
   */
  private resolveDocumentSnapshotArgs = (
    fieldsOrSnapshotListener: (keyof T)[] | BlocCachedDocumentSnapshotListener<T>,
    onSnapshotOrError: BlocCachedDocumentSnapshotListener<T> | BlocErrorFunction,
    onErrorOrNothing: BlocErrorFunction): {
      onSnapshot: BlocCachedDocumentSnapshotListener<T>;
      onError: BlocErrorFunction;
      fields?: (keyof T)[];
    } => {
    if (Array.isArray(fieldsOrSnapshotListener)) {
      return {
        onSnapshot: onSnapshotOrError as BlocCachedDocumentSnapshotListener<T>,
        onError: onErrorOrNothing,
        fields: fieldsOrSnapshotListener as unknown as (keyof T)[],
      };
    }
    return {
      onSnapshot: fieldsOrSnapshotListener as BlocCachedDocumentSnapshotListener<T>,
      onError: onSnapshotOrError as BlocErrorFunction,
    };
  };

  /**
   * Release a document reference.
   * @param {string} docId The id of the document.
   */
  removeDocumentReference = (
    docId: string
  ) => {
    const {
      docRef,
    } = this.$documentReferenceSet.get(docId);
    if (!docRef) {
      return;
    }
    const referenceCount = docRef.removeRef();
    this.cache.removeCollectionReference(this.collectionPath);

    if (referenceCount <= 0) {
      this.$documentReferenceSet.delete(docId);

      if (this.$documentReferenceSet.size === 0) {
        this.dispose();
      }
    }
  };
  /**
   *
   * @param {string} docId                        The unique id of the document.
   * @param {Partial<T>}  data
   * @param {CacheDocumentRemoteCrudOptions}  options
   */
  public async set(
    docId: string,
    data: Partial<T>,
    options?: BlocCacheUpdateOptions
  ): Promise<WithId<T>>;
  /**
   *
   * @param {WithId<Partial<T>>[]}  docs
   * @param {CacheDocumentRemoteCrudOptions}  options
   */
  public async set(
    docs: Array<WithId<Partial<T>>>,
    options?: BlocCacheUpdateOptions
  ): Promise<WithId<T>[]>;

  /**
   * Set a document in the cache.
   *
   * @param docIdOrDocs
   * @param dataOrOptions
   * @param possibleOptions
   * @returns
   */
  public async set(
    docIdOrDocs: string | Array<WithId<Partial<T>>>,
    dataOrOptions?: Partial<T> | BlocCacheUpdateOptions,
    possibleOptions?: BlocCacheUpdateOptions
  ): Promise<WithId<T> | WithId<T>[]> {
    const resolveDocument = (docId: any, data: any, options: any) => {
      const [changed, _data, ref,] =
        this.$documentReferenceSet
          .setDocumentData(
            docId,
            data,
            options
          );

      return {
        doc: {
          _id: docId,
          ..._data,
        },
        changed,
        ref,
      };
    };

    if (typeof docIdOrDocs === 'string') {
      const docId = docIdOrDocs;
      const data = dataOrOptions as Partial<T>;
      const options = possibleOptions as BlocCacheUpdateOptions ?? {};

      const {
        doc, changed, ref,
      } = resolveDocument(docId, data, options);

      if (changed) {
        this.$querySet?.validate([ref,]);
      }
      return doc as any; // TODO: Fix this for TS5
    }
    const docs = docIdOrDocs as WithId<Partial<T>>[];
    const options = dataOrOptions as BlocCacheUpdateOptions ?? {};

    const hasChanges = false;
    const refs: CachedDocumentReference<T>[] = [];
    const result: WithId<T>[] = [];

    if (docs?.forEach) {
      docs.forEach((d) => {
        const {
          doc, changed, ref,
        } = resolveDocument(d._id, d, options);
        result.push(doc as any); // TODO: Fix this for TS5
        changed && refs.push(ref);
        hasChanges !== changed;
      });

      if (hasChanges) {
        this.$querySet?.validate(refs);
      }
    } else {
      console.error(`Unexpected docIdOrDocs type: ${typeof docIdOrDocs} value: ${docIdOrDocs}`);
    }
    return result;
  }

  /**
   * @param {string} docId
   * @param {CacheCollectionUpdateOptions} options
   */
  public delete = async (
    docId: string,
    options?: DeleteDocumentOptions
  ): Promise<void> => {
    const {
      docRef,
    } = this.$documentReferenceSet.get(docId);

    if (!docRef) {
      return;
    }
    if (!options?.fromDocumentReference) {
      this.$documentReferenceSet.delete(docId);
    }

    this.$querySet?.validate([docRef,]);
  };

  /**
   *
   * @param {CachedDocumentReference<T>[]} docs
   */
  public validate = (docs: CachedDocumentReference<T>[]) => {
    this.$querySet?.validate(docs);
  };
  /**
 * Document reference change listener function.
 *
 * @param {DocumentReferenceListenerArgs} args
 * @param {string} args.docId           The id of the document.
 * @param {T | null} args.data                 New / current data of the document.
 * @param {T | null} args.priorData            Data's prior content before event triggering the listener.
 * @param {any} args.sender             The sender entity of the listener event.
 * @param {boolean} args.docIdChanged   Optional argument, that will be true in special case, where
 *                                      backend has forced the change of the of the document id.
 */
  // private onDocumentReferenceChange: DocumentReferenceListener<T> = ({
  //   data,
  //   priorData,
  // }) => {
  //   // TODO: Evaluate can this event event trigger if the data has not changed
  //   if (!hasDocumentChanged(
  //     data,
  //     priorData
  //   )) {
  //     return;
  //   }
  //   // this.listeners.fire(this.$documentReferenceSet);
  // };
}
