/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimatePresence, motion } from 'framer-motion';
import { BUTTON_TAP_GESTURE } from 'components/session/SessionEditor/RestrictedSessionEditor/Inspector/FacilitateInspector/FilterEditor/QuadrantFilterEditor/QuadrantButton';
import { Divider, IconButton, useMediaQuery } from '@mui/material';
import {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import theme from 'theme';
import useScroll from 'utils/hooks/useScroll';
import type { Media } from '@shared/schema/src';

const NavContainer = styled(motion.div)(({
  theme,
}) => `
  position: absolute;
  display: flex;
  top: 0,
  justify-content: space-between;
  align-items: center;
  z-index: -1;  
  background-color: ${theme.palette.background.paper};
  padding: ${theme.spacing(1)};
  border-radius: 12px;
`);

const Content = styled.div`
  max-width: 128px;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }  
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  @media(max-width: 600px) {
    max-width: 118px;
  }
  padding: 4px;
`;

const DotContainer = styled(motion.div)`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding-left: 40px;
  padding-right: 40px;
`;

const StyledButton = motion(IconButton);

const CanvasDot = styled$(motion.div)<{
  $isSelected: boolean,
}>(({
  $isSelected,
}) => `
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    transform: ${$isSelected ? 'none' : 'scale(1.1)'};
  }
`);

const DotText = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1px;
`;

const ActiveText = styled.div(({
  theme,
}) => `
  font-size: 0.8rem;
  color: ${theme.palette.primary.contrastText};
  font-weight: bold;
`);

const ActiveDivider = styled(Divider)(({
  theme,
}) => `
  border-color: ${theme.palette.primary.contrastText};
  width: 50%;
  align-self: center;
  margin-bottom: -1px;
  margin-top: -1px;
  border-bottom-width: initial;
`);

export type TypeCanvasItem = {
  id: string;
  title: string;
  image: string;
  media: Media;
  isAnswered: boolean;
};

interface TinyCanvasNavigatorProps {
  canvases?: TypeCanvasItem[]; // TODO: typing
  selected: string;
  hidden?: boolean;
  onNextClick: () => void;
  onPreviousClick: () => void;
  onClick: (id: string) => void;
}

const duration = 0.3;

const INITIAL_STYLE = {
  opacity: 0,
  top: 0,
};

const TinyCanvasNavigator = ({
  canvases = [],
  selected,
  hidden = false,
  onNextClick,
  onPreviousClick,
  onClick,
}: TinyCanvasNavigatorProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [canScroll, setCanScroll,] = useState<boolean>(false);
  const [maxScrollPosition, setMaxScrollPosition,] = useState<number>(0);
  const [scrollPosition, setScrollPosition,] = useState<number>(0);

  const selectedIndex = useMemo(
    () => canvases.findIndex((c) => c.id == selected),
    [canvases, selected,]
  );

  const {
    isScrolling = false,
  } = useScroll();

  const containerRef = useRef<HTMLDivElement>(null);

  const handlePreviousOnClick = useCallback(() => {
    onPreviousClick();
  }, [onPreviousClick,]);

  const handleNextOnClick = useCallback(() => {
    onNextClick();
  }, [onNextClick,]);

  const scrollToSelection = useCallback(() => {
    const index = canvases.findIndex((c) => c.id == selected);
    if (index >= 0) {
      const left = calcScrollPos(index, isMobile);

      ref.current?.scroll({
        left,
        behavior: 'smooth',
      });
    }
  }, [canvases, isMobile, selected,]);

  // regular element scroll without clicking arrows
  const handleScroll = useCallback(() => {
    setScrollPosition(ref.current?.scrollLeft ?? 0);
  }, []);

  // dot click navigation
  const handleClick = useCallback((id: string) => {
    onClick?.(id);
  }, [onClick,]);

  // checks for hiding the arrow icons
  useEffect(() => {
    if (ref.current) {
      const isScrollable = ref.current.scrollWidth > ref.current.clientWidth;
      setCanScroll(isScrollable);
      const maxPos = calcScrollPos(canvases.length - 1, isMobile);
      setMaxScrollPosition(maxPos);
    }
  }, [ref.current?.scrollWidth, ref.current?.clientWidth, canvases.length, isMobile,]);

  useEffect(() => {
    scrollToSelection();

    // This is a hack to make sure the scroll position is correct after the animation
    // TODO: Refactor navigator to base on framer-motion divs that animated in visible area
    //       instead of scrolling the whole list
    setTimeout(() => {
      scrollToSelection();
      setTimeout(() => {
        scrollToSelection();
        setTimeout(() => {
          scrollToSelection();
        }, 500);
      }, 400);
    }, 300);
  }, [canvases, scrollToSelection, selected,]);

  const [containerHeight, setContainerHeight,] = useState(80);

  useEffect(() => {
    const containerHeight = containerRef.current?.clientHeight || 80;
    setContainerHeight(containerHeight);
  }, [containerRef.current?.clientHeight,]);

  const variants = useMemo(() => {
    return {
      show: {
        opacity: 1,
        top: -containerHeight - 32,
      },
      hide: {
        opacity: 0,
        top: 0,
      },
    };
  }, [containerHeight,]);

  useEffect(() => {
    scrollToSelection();
  }, [canvases, scrollToSelection, selected,]);

  return (
    <AnimatePresence>
      <NavContainer
        ref={containerRef}
        key="canvas-nav"
        animate={hidden ? 'hide' : isScrolling ? 'hide' : 'show'}
        variants={variants}
        exit={{
          opacity: 0,
        }}
        initial={INITIAL_STYLE}
        transition={{
          duration,
        }}>
        <StyledButton
          style={{
            opacity: canScroll && scrollPosition > 0 ? 1 : 0,
          }}
          onClick={handlePreviousOnClick} >
          <ChevronLeft />
        </StyledButton>
        <Content
          onScroll={handleScroll}
          ref={ref}
        >
          <DotContainer
            layout
            transition={{
              staggerChildren: 0.2,
            }}>
            {canvases?.map(({
              id,
              isAnswered,
            }, index) =>
              <TinyCanvasItem
                key={id}
                id={id}
                isAnswered={isAnswered}
                canvasesLength={canvases.length}
                selected={selected}
                selectedIndex={selectedIndex}
                index={index}
                isMobile={isMobile}
                onClick={handleClick}
              />)}
          </DotContainer>
        </Content>
        <StyledButton
          style={{
            //   Browser scroll trigger is not accurate, so we use a small threshold to hide the arrow
            // when the scroll is almost at the end of the list
            opacity: canScroll && maxScrollPosition - scrollPosition > 4 ? 1 : 0,
          }}
          onClick={handleNextOnClick}>
          <ChevronRight />
        </StyledButton>
      </NavContainer>
    </AnimatePresence>
  );
};

// /**
//    * Returns a factor for calculating the correct position for different screen sizes
//    *
//    * @return {number} factor
//    */
// const getScreenSize = (): number => {
//   const isWindow = typeof window !== 'undefined';
//   if (!isWindow) {
//     return 3;
//   }
//   const {
//     innerWidth,
//   } = window;

//   if (innerWidth <= 380) {
//     return 3;
//   }
//   if (innerWidth <= 400) {
//     return 2.7;
//   }
//   if (innerWidth <= 600) {
//     return 2.6;
//   }
//   if (innerWidth <= 1300) {
//     return 2.3;
//   }
//   return 2.1;
// };

interface TinyCanvasItemProps {
  id: string;
  isAnswered?: boolean;
  index: number;
  isMobile: boolean;
  selected: string;
  selectedIndex?: number;
  canvasesLength: number;
  onClick?: (id: string) => void;
}

const TinyCanvasItem = ({
  id,
  isAnswered = false,
  canvasesLength,
  index,
  isMobile,
  selected,
  selectedIndex,
  onClick,
}: TinyCanvasItemProps) => {
  const isSelected = selected === id;

  const handleClick = useCallback((event: React.MouseEvent | React.TouchEvent) => {
    onClick?.(id);
    event.stopPropagation();
  }, [onClick, id,]);

  let opacity = Math.pow(1 - Math.abs((selectedIndex ?? 0) - index) / 3.5, 0.8);
  if (isNaN(opacity)) {
    opacity = 1;
  }
  const size = isSelected ? '2.5rem' : isMobile ? '0.8rem' : '1rem';

  return <CanvasDot
    key={id}
    // layoutId={`dot_${canvas.id}`}
    // layout
    $isSelected={isSelected}
    initial={{
      scale: 0,
      opacity,
    }}
    whileHover={!isSelected ? {
      scale: 1.1,
      opacity: 1,
    } : undefined}
    whileTap={
      BUTTON_TAP_GESTURE
    }
    animate={{
      height: size,
      width: size,
      scale: 0.9,
      opacity,
      backgroundColor: isAnswered ? theme.palette.background.graph : theme.palette.primary.main,
    }}
    exit={{
      scale: 0,
      opacity: 0,
    }}
    transition={{
      duration,
    }}
    onClick={handleClick}
    onTap={handleClick as any}
  >
    <Dot
      isSelected={isSelected}
      index={index}
      canvasesLength={canvasesLength}
    />
  </CanvasDot>;
};

const Dot = ({
  isSelected,
  index: i,
  canvasesLength,
}: any) => {
  return <AnimatePresence>
    <DotText key="dot-text"
      animate={{
        scale: isSelected ? 0.9 : 0,
        opacity: isSelected ? 1 : 0,
      }}
      transition={{
        duration,
      }}
    >
      <ActiveText>{i + 1}</ActiveText>
      <ActiveDivider />
      <ActiveText>{canvasesLength}</ActiveText>
    </DotText>
  </AnimatePresence>;
};

const calcScrollPos = (index: number, isMobile: boolean) => {
  return index * (isMobile ? 18 : 20);
};

export default TinyCanvasNavigator;
