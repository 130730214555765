import { AnimatePresence, motion } from 'framer-motion';
import { forwardRef } from 'react';
import styled$ from 'utils/react/styled$';
import type { AnimationControls } from 'framer-motion';

const Shadow = styled$(motion.div)<{
  $width: string | number;
  $height: string | number;
}>(({
  theme,
  $width,
  $height,
}) => `
  position: absolute;
  background-color: #7cbac9;
  left: 0;
  top: ${theme.spacing(0.5)};
  width: ${$width};
  height: ${$height};
  border-radius: ${theme.shape.borderRadius}px;
  z-index: 100;
`);

interface ShadowKnobProps {
  width: string | number;
  height: string | number;
  animate: AnimationControls;
  visible?: boolean;
}

const ENTER = {
  opacity: 0,
};
const ACTIVE = {
  opacity: 1,
};

const LEAVE = {
  opacity: 0,
  transition: {
    duration: 0.6,
  },
};

const ShadowKnob = forwardRef(({
  width,
  height,
  animate,
  visible = true,
}: ShadowKnobProps, ref) => {
  return <AnimatePresence>
    {visible ?
      <motion.div ref={ref as any}
        animate={animate}
      >
        <Shadow
          id="shadow-knob"
          className='shadow-knob'
          initial={ENTER}
          animate={ACTIVE}
          exit={LEAVE}
          $width={width}
          $height={height}
        />
      </motion.div> : null}
  </AnimatePresence>;
});

ShadowKnob.displayName = 'ShadowKnob';

export default ShadowKnob;
