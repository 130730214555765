import type { AxiosResponse } from 'axios';
import type { EditorMode } from 'components/session/SessionEditor/RestrictedSessionEditor/components/toolbar/ModeSwitch';
import type { ParsedUrlQuery } from 'querystring';
import type { PathParams } from '@shared/schema/src/pathParams';
import type { SessionStatus } from '@shared/schema/src';

// eslint-disable-next-line valid-jsdoc
/**
 * Generate an URL based on PathParam's info.
 *
 * @param {PathParams}        params  Path params
 * @param {keyof PathParams}  leaf    Depth of the based on `QueryPropsDepthMap`.
 * @param {T}                 command Command included in url if any
 * @returns {string}                  URL presentation based on path params.
 */
export const generatePathUrl = <T = string>(
  params: PathParams | ParsedUrlQuery,
  leaf: keyof PathParams,
  command?: T
): string => {
  const {
    spaceId,
    projectId,
    sessionId,
    canvasId,
  } = params;

  let result;

  switch (leaf) {
    case 'canvasId':
      result = `/${spaceId}/${projectId}/${sessionId}/${canvasId}/`;
      break;
    case 'sessionId':
      result = `/${spaceId}/${projectId}/${sessionId}`;
      break;
    case 'projectId':
      result = `/${spaceId}/${projectId}`;
      break;
    case 'spaceId':
      result = `/${spaceId}`;
      break;

    default:
      throw new Error(`URL generation for leaf ${leaf} not implemented yet`);
  }
  if (command) {
    return `${result}?${command}`;
  }
  return result;
};

export const isEditModeKey = (key: string) =>
  key === 'edit' || key === 'facilitate' || key === 'analyze';

export const hasEditMode = (query: ParsedUrlQuery) => {
  return 'edit' in query || 'facilitate' in query || 'analyze' in query;
};

export const resolveEditorMode = (query: ParsedUrlQuery): EditorMode => {
  if ('facilitate' in query) {
    return 'facilitate';
  }
  if ('analyze' in query) {
    return 'analyze';
  }
  if ('preview' in query) {
    return 'preview';
  }
  return 'edit';
};

/**
 * Download a page from given URL. This can be used also fo download local
 * blob URL with desired file name.
 *
 * @param {string} uri
 * @param {string} fileName Desired filename to be used when mime type will cause download.
 */
export const downloadURI = (uri: string, fileName: string) => {
  const link = document.createElement('a');
  link.setAttribute('download', fileName);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * Resolve default editor mode based on query status. This is used as facilitator
 * most emphasized operations correlate with query status. So if the query is in the
 * draft state all operations are focused on editing. When the query is active, the
 * operations are most likely focused on facilitation and when closed there is only
 * data analysis available to do.
 *
 * @param {SessionStatus} status The current query status
 * @return {EditorMode}        The preferred editor mode.
 */
export const resolveEditorModeFromSessionStatus = (status: SessionStatus = 'draft') => {
  let editMode: EditorMode = 'edit';

  switch (status) {
    case 'closed':
      editMode = 'analyze';
      break;

    case 'active':
      editMode = 'facilitate';
      break;

    case 'draft':
    default:
      editMode = 'edit';
  }
  return editMode;
};

/**
 * Downloads the response data as a file with the given fileName.
 *
 * @param {AxiosResponse} response - The Axios response object containing the data to be downloaded.
 * @param {string} fileName - The name of the file to be downloaded.
 * @param {string} [defaultContentType='application/octet-stream'] - The default content type of the file if the response does not provide one.
 * @return {void} This function does not return anything.
 */
export const downloadResponseAsFile = (
  response: AxiosResponse,
  fileName: string,
  defaultContentType = 'application/octet-stream'
) => {
  const contentType = response.headers['content-type'] ?? defaultContentType;

  // eslint-disable-next-line compat/compat
  const blob = new Blob(
    [response.data,],
    {
      type: contentType,
    }
  );
  // eslint-disable-next-line compat/compat
  const blobUrl = URL.createObjectURL(blob);

  downloadURI(blobUrl, fileName);
};

const youtubeDomains = ['youtube.com', 'youtu.be',];

/**
 * Check if given URL is a YouTube URL.
 *
 * @param url The URL to check
 * @returns
 */
export const isYouTubeUrl = (url?: string) => {
  if (!url) {
    return false;
  }
  return youtubeDomains.some((domain) => url.includes(domain));
};

/**
 * Resolve a relative directory path from the current directory path.
 * @param currentPth    The current directory path
 * @param relativePath  The relative directory path
 * @returns             The resolved directory path
 */
export const resolveRelativeDirectoryPath = (currentPth: string, relativePath: string) => {
  const currentPathParts = currentPth.split('/');
  const relativePathParts = relativePath.split('/');

  const newPathParts = currentPathParts.slice();

  for (const part of relativePathParts) {
    if (part === '..') {
      newPathParts.pop();
      continue;
    }
    if (part !== '.') {
      newPathParts.push(part);
    }
  }

  return newPathParts.join('/');
};
