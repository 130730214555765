import { ScreenContext } from './useScreenContext';
import { useCallback, useMemo, useState } from 'react';
import React from 'react';
import type { PropsWithChildren } from 'react';

/**
 * ScreenProvider will share screen level state information between components.
 */
const ScreenProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [leftDrawerOpen, setLeftDrawerOpen,] = useState(false);
  const [rightDrawerOpen, setRightDrawerOpen,] = useState(false);
  const [contactUsFormOpen, setContactUsFormOpen,] = useState(false);
  const [screenMode, setScreenMode,] = useState<'standard' | 'superuser'>('standard');

  const toggleScreenMode = useCallback(() => {
    // TODO: Restrict for system user only
    setScreenMode((prev) => prev === 'standard' ? 'superuser' : 'standard');
  }, []);

  const state: ScreenContext = useMemo(() => ({
    contactUsFormOpen,
    leftDrawerOpen,
    rightDrawerOpen,
    screenMode,
    setContactUsFormOpen,
    setLeftDrawerOpen,
    setRightDrawerOpen,
    toggleScreenMode,
  }), [
    contactUsFormOpen,
    leftDrawerOpen,
    rightDrawerOpen,
    screenMode,
    setContactUsFormOpen,
    setLeftDrawerOpen,
    setRightDrawerOpen,
    toggleScreenMode,
  ]);

  return (
    <ScreenContext.Provider value={state}>
      {children}
    </ScreenContext.Provider>
  );
};

export default ScreenProvider;
