import { PexelCopyright, extractMediaAuthorInfoFromPexel } from './MediaCopyright/PexelCopyright';
import { motion } from 'framer-motion';
import { useTheme } from '@emotion/react';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import Stack from '@mui/material/Stack';
import type { Media } from '@shared/schema/src';
import type { Video } from '@shared/schema/src/https/media/pexelTypes';

interface PexelVideoProps {
  item: Video;
  onClick?: (item: Partial<Media>) => void;
}
export const PexelVideo = ({
  item,
  onClick,
}: PexelVideoProps) => {
  const theme = useTheme();

  const handleVideoSelect = useCallback((event: React.MouseEvent) => {
    const newMedia = {
      ...extractMediaAuthorInfoFromPexel(item),
      type: 'video',
      videoURL: item.video_files[0].link,
    } as Media;
    onClick?.(newMedia);
    event.preventDefault();
  }, [item, onClick,]);

  const videoRef = useRef<HTMLVideoElement>(null);

  useLayoutEffect(() => {
    if (typeof window === 'undefined' || !videoRef.current) {
      return;
    }

    videoRef.current?.play();

    videoRef.current.onended = (event: any) => {
      const element = event.target;
      element.load();
      element.play();
    };
  }, []);

  return <Stack
    key={item.id}
    onClick={handleVideoSelect}
    position="relative"
  >
    <motion.video
      muted
      whileHover={{
        borderColor: 'rgba(255,255,255,1)',
      }}
      loop
      preload="auto"
      autoPlay
      poster={item.video_pictures[0].picture}
      src={item.video_files[0].link}
      style={{
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0,0)',
        borderRadius: theme.shape.borderRadius,
      }}
      ref={videoRef}
    >
      {
        item.video_files.map((videoFile) => {
          return <source key={videoFile.id}
            src={videoFile.link}
            type={videoFile.file_type} />;
        })
      }
    </motion.video>
    <PexelCopyright item={item} />
  </Stack>;
};
