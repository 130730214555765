import { PARENT_ID_ROOT } from '@shared/schema/src';
import { hasEditMode } from 'utils/urls';
import { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import type { CommentId } from '@shared/schema/src';

const parseCommentPath = (commentId?: string | string[]): string[] => {
  if (!commentId) {
    return [];
  }
  return Array.isArray(commentId) ? commentId : [commentId,];
};

export const parseCommentIdPath = (ids: string[]): string => {
  if (ids.length == 0) {
    return '';
  }
  return ids.reduce((current, id) => (current ? `${current}/` : '') + id);
};

export const getLeafCommentId = (commentId: CommentId | CommentId[]): CommentId => {
  return Array.isArray(commentId) ?
    commentId.length > 0 ?
      commentId[commentId.length - 1] :
      undefined as unknown as CommentId :
    commentId;
};

const useCommentPath = () => {
  const {
    query,
    query: {
      spaceId,
      projectId,
      sessionId,
      canvasId,
      commentId,
    },
    push,
  } = useRouter();

  const [commentPath, setCommentPath,] = useState<string[]>(parseCommentPath(commentId));

  const focusedCommentId = useMemo(() => {
    const currentPath = parseCommentPath(commentId);
    return currentPath.length ? currentPath.at(-1) : PARENT_ID_ROOT;
  }, [commentId,]);

  useEffect(() => {
    const currentPath = parseCommentPath(commentId);
    setCommentPath(currentPath);
  }, [commentId,]);

  const goToRootComment = useCallback(() => {
    const editMode = hasEditMode(query);
    const path = `/${spaceId}/${projectId}/${sessionId}/${canvasId}${editMode ? '?facilitate' : ''}`;
    push(path, path, {
      shallow: true,
    });
    setCommentPath(['',]);
  }, [query, push, spaceId, projectId, sessionId, canvasId,]);

  const goToParentComment = useCallback(() => {
    const editMode = hasEditMode(query);

    const commentIds = commentPath.slice(0, commentPath.length - 1);
    let path = `/${spaceId}/${projectId}/${sessionId}/${canvasId}/${parseCommentIdPath(commentIds)}`;
    if (editMode) {
      path += `?facilitate`;
    }
    push(path, path, {
      shallow: true,
    });
    setCommentPath(commentIds);
  }, [query, commentPath, spaceId, projectId, sessionId, canvasId, push,]);

  const goToChildComment = useCallback((commentId: CommentId) => {
    const editMode = hasEditMode(query);
    if (commentId) {
      if (commentPath[commentPath.length - 1] === commentId) {
        return;
      }

      const commentIds = commentPath.slice(0);
      commentIds.push(commentId);
      push(`/${spaceId}/${projectId}/${sessionId}/${canvasId}/${parseCommentIdPath(commentIds)}${editMode ? '?facilitate' : ''}`);
      setCommentPath(commentIds);
    }
  }, [query, commentPath, push, spaceId, projectId, sessionId, canvasId,]);

  return {
    commentId: focusedCommentId,
    commentPath,
    goToParentComment,
    goToChildComment,
    goToRootComment,
  };
};

export default useCommentPath;
