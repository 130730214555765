/**
 * Represents a value with a unit of measurement.
 */
export interface UnitValue {
  value: number;
  unit: 'px' | 'rem' | '%';
}

/**
 * Converts a value in rem to pixels.
 * @param {number} rem - The value in rem to convert.
 * @returns {number} The value in pixels.
 */
export const remToPixels = (rem: number): number => {
  if (typeof window === 'undefined') {
    return 24;
  }
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

/**
 * Converts a value with a unit of measurement to pixels.
 * @param {UnitValue} value - The value to convert.
 * @param {number} dimension - The dimension to use for percentage values.
 * @returns {number} The value in pixels.
 * @throws {Error} If the unit is unknown.
 */
export const unitValueToPixels = (
  value: UnitValue, dimension: number): number => {
  switch (value.unit) {
    case 'px':
      return value.value;
    case 'rem':
      return remToPixels(value.value);
    case '%':
      return dimension * (value.value / 100);
    default:
      throw new Error(`Unknown unit ${value.unit}`);
  }
};
