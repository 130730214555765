import { BlocQuery } from '@mindhiveoy/bloc/BlocQuery';
import { createSession } from './sessionApi';
import { createUseBlocHook } from '@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import { validateSessionProps } from '@shared/schema/src';
import type { BlocErrorFunction } from '@mindhiveoy/bloc/types';
import type { ProjectParams, Session } from '@shared/schema/src';

/**
 *
 */
export class SessionsBloc extends BlocQuery<Session, ProjectParams> {
  /**
   *
   * @param {ProjectParams}  params  Params defining the path to specific project's sessions
   * @param {ErrorFunction}  onError Error listener
   */
  constructor(
    params: ProjectParams,
    onError?: BlocErrorFunction
  ) {
    validateSessionProps(params, 'projectId');

    const {
      spaceId, projectId,
    } = params;

    super({
      collectionPath:
        `spaces/${spaceId
        }/projects/${projectId
        }/sessions`,
      params,
      onError,
    });
  }

  /**
   * Create a new session.
   *
   * @param {Session}     session        Session base data
   * @return {CreateSessionResponse} A data object containing the id of the created project and project's initial data.
   */
  public createSession = async (
    session: Partial<Session> & Pick<Session, 'name'>
  ) => {
    return this._create(
      async () => {
        return await createSession({
          spaceId: this.params.spaceId,
          projectId: this.params.projectId,
          session: session as Session,
        });
      },
      this.onError
    );
  };
}

export const useSessions = createUseBlocHook<Session, ProjectParams, SessionsBloc>(
  ({
    params,
    onError,
  }) => new SessionsBloc(params, onError)
);
