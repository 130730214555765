/* eslint-disable @typescript-eslint/no-explicit-any */
import type { PropertyConfigs } from '../../builders/componentBuilder';
import type { PropertyEditor } from '../../propertyEditors/PropertyEditorTypes';

export interface SectionPresentation {
  title: string;
  props: PropertyEditor[];
}

export interface CategoryPresentation {
  title: string;
  sections: SectionPresentation[];
}

export const DEFAULT_CATEGORY = 'general';
export const DEFAULT_SECTION = 'default';

export const extractCategoryPresentation = (props: PropertyConfigs<any> = {}): CategoryPresentation[] => {
  const categories = new Map<string,
    Map<string | typeof DEFAULT_SECTION, PropertyEditor[]>>();

  const resolveSections = (category: string, property: PropertyEditor) => {
    let categoryPresentation = categories.get(category);
    if (!categoryPresentation) {
      categoryPresentation = new Map();
      categories.set(category, categoryPresentation);
    }

    const section = property.section ?? DEFAULT_SECTION;

    let sectionProperties = categoryPresentation.get(section);
    if (!sectionProperties) {
      sectionProperties = [];
      categoryPresentation.set(section, sectionProperties);
    }
    sectionProperties.push(property);
  };

  Object.values(props).forEach((property) => {
    const category = property.category ?? DEFAULT_CATEGORY;

    if (Array.isArray(category)) {
      category.forEach((cat) => resolveSections(cat, property as any)); // TODO fix text align and media property editor conflict
    } else {
      resolveSections(category, property as any);
    }
  });
  const result: CategoryPresentation[] = [];

  const categoryEntries = Array.from(categories.entries());

  categoryEntries.forEach(([category, sections,]) => {
    const presentation: CategoryPresentation = {
      title: category ?? DEFAULT_CATEGORY,

      sections: Array.from(
        sections.entries()
      ).map(([name, value,]) => {
        return {
          title: name ?? DEFAULT_CATEGORY,
          props: value,
        };
      }),
    };
    result.push(presentation);
  });

  return result;
};
