import { StyledSvg } from '../../graphs/SliderCanvasGraph/Chart1D';
import { useMemo } from 'react';
import type { CSSProperties } from 'react';
import type { MatrixMode, RangeItem } from '@shared/schema/src';

interface SliderHelperLinesProps {
  range: RangeItem[];
  knobSize: number;
  color: string;
  style?: CSSProperties;
  mode?: MatrixMode;
}
/**
 * Draw helper lines for the slider to indicate the discrete steps defined by the range.
 *
 * For the continuous mode, the helper lines are drawn at the start and end of the range.
 * For the discrete mode, the helper lines are drawn at each discrete step.
 *
 * @param props - The props.
 * @returns       The component.
 */
export const SliderHelperLines = ({
  color,
  knobSize,
  mode = 'discrete',
  range,
  style,
}: SliderHelperLinesProps) => {
  const axisHelperLines = useMemo(() => {
    const result = [];

    const start = mode === 'discrete' ? 1 : 0;
    const end = mode === 'discrete' ? range.length + 1 : range.length;

    const calcStrokewidth = (i: number): string | number | undefined => {
      if (mode === 'discrete') {
        return 2;
      }
      // if continuous, draw a thicker line at the start and end as the half of the line
      // is rendered outside of the visible area.
      return i === start || i === end ? 4 : 2;
    };

    for (let i = start; i < end; i++) {
      const x = i * 1000 / (end - 1);

      result.push(<line
        key={`c_${i}`}
        y1={0}
        x1={x}
        y2={1000}
        x2={x}
        stroke={color}
        strokeWidth={calcStrokewidth(i)} />);
    }
    return result;
  }, [color, mode, range.length,]);

  const s: CSSProperties = useMemo(() => ({
    ...style,
    position: 'absolute',
    margin: 0,
    left: mode === 'discrete' ? 0 : knobSize / 2,
    padding: 0,
    width: mode === 'discrete' ? '100%' : `calc(100% - ${knobSize}px)`,
  }), [knobSize, mode, style,]);

  return <StyledSvg
    height="100%"
    fill="none"
    preserveAspectRatio='none'
    viewBox="0 0 1000 1000"
    xmlns="http://www.w3.org/2000/svg"
    style={s}
  >
    {axisHelperLines}
  </StyledSvg>;
};
