
// interface CommentSpecs {
//   comment: WithId<Comment>;
//   z: number;
//   x: number;
//   y: number;
// }

/**
 * A mode for comments, where new comments will float in the 3d space from bottom to top
 * a they appear. Now comments will randomly find their places in the 3d space so that all
 * will appear to the screen from the bottom and will eventually float to the top.
 *
 * Comments are rendered as 3d objects, which are positioned in the 3d space using css.
 * @returns
 */
export const CommentFeed3D = () => {
  // const screenContext = usePresentationScreenContext();
  // const [comments, setComments,] = useState<WithId<CommentSpecs>[]>([]);
  // const commentsBloc = useLatestComments(screenContext);

  // const currentComments = useRef<Map<string, WithId<CommentSpecs>>>(new Map());

  // useEffect(
  //   () => commentsBloc.subscribe((comments) => {
  //     // Resolve new comments from the list and add them to the currentComments with a random position

  //     // Remove comments that are no longer in the list

  //     // Update the comments state with the new list of comments

  //   }), [commentsBloc,]
  // );

  return <div
    style={{
      perspective: '1000px',
      height: '100%',
      width: '100%',
    }}
  >

    not implemented yet
  </div>;
};
