/* eslint-disable max-len */
import { UserProjectInfo } from './users/user';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */
import {
  DataType, DocumentFields, FieldType, FirestoreSchemaModel,
  doc, schemaSpaceFields, schemaUserFields
} from '@mindhiveoy/firebase-schema';
import { DraftCanvasDocument, DraftSessionDocument, WithCreationInfo, WithModificationInfo } from './common';
import { Invitation } from './users/Invitation';
import { Member, User } from './users/user';
import { ShortUserRoleSet, UserRoleSet, userRole, userRoleContext } from '@mindhiveoy/auth';

import { AccountTransactionDoc } from './payments/Account';
import { Answer } from './spaces/projects/sessions/canvases';
import { Canvas } from './spaces/projects/sessions/canvases/canvas';
import { Comment } from './spaces/projects/sessions/canvases/comments';
import { CustomerDoc } from './payments/Customer';
import { EmailData } from './emailCenter';
import {
  FeedItem, InquiryDoc, InvoiceDoc, MessageDoc, NotificationDoc, Panel, ProjectMessageDoc, ProjectSettingDoc, UserMessageDoc, UserSettings
} from '.';
import { ModuleId } from './modules';
import { PageDoc } from './pages';
import { PaymentDoc } from './payments/Payment';
import { PlanId } from './plans';
import { Project, ProjectMember } from './spaces/projects/project';
import { Session, SessionStatistics, } from './spaces/projects/sessions/session';
import { Space, SpaceSettings } from './spaces/space';
import { TemplateDoc } from './spaces/projects/template';
import { BotDoc } from './spaces/projects/bot';

const roleContexes = {
  project: userRoleContext({
    reference: 'projectMembers',
    permission: {
      full: ['facilitator', 'admin', 'system'],
    },
    level: 4,
    defaultRole: 'panelist',
  }),

  space: userRoleContext({
    reference: 'members',
    permission: {
      full: ['admin', 'system',],
      read: ['member',],
    },
    level: 2,
    defaultRole: 'member',
  }),

  platform: userRoleContext({
    reference: 'users',
    permission: {
      full: ['system',],
    },
    level: 0,
    defaultRole: 'guest',
  }),
};

// TODO: Merge ContextId and this UserRoleContextId. They are the same.
export type UserRoleContextId = keyof typeof roleContexes;

/**
 * Custom role's for the platform
 */
const roles = {
  /**
   * User with full rights for a single project
   */
  facilitator: userRole<UserRoleContextId>({
    name: 'Facilitator',
    context: 'project',
  }),
  /**
   * Participant in a single project
   */
  panelist: userRole<UserRoleContextId>({
    name: 'Panelist',
    context: 'project',
  }),
  /**
   * User with full access for a single space
   */
  admin: userRole<UserRoleContextId>({
    name: 'Admin',
    context: 'space',
  }),
  /**
   * User with basic rights for a single space
   */
  member: userRole<UserRoleContextId>({
    name: 'Member',
    context: 'space',
  }),


};

export type UserRoleId = UserRoleSet<keyof typeof roles>;

// TODO autogenerate short user role id's
export type ShortUserRoleId = ShortUserRoleSet<'a' | 'f' | 'p' | 'm' | 'b'>;

const changeInfoFields: DocumentFields<Required<WithCreationInfo & WithModificationInfo>, UserRoleId> = {
  created: {
    dataType: DataType.DateAndTime,
    type: FieldType.Descriptor,
    indexed: true,
  },
  creatorId: {
    // TODO Make as reference
    dataType: DataType.Text,
    type: FieldType.Descriptor,
  },
  lastModified: {
    dataType: DataType.DateAndTime,
    type: FieldType.Descriptor,
    indexed: true,
  },
  modifierId: {
    // TODO Make as reference
    dataType: DataType.Text,
    type: FieldType.Descriptor,
  },
};

export const schema: FirestoreSchemaModel<UserRoleId, ShortUserRoleId> = {

  packages: [
    'auth',
    'firestore',
    'functions',
    // 'messaging',
    'storage',
    'performance',
  ],

  roles,

  roleContext: {
    contextPath: ['spaces', 'spaceId', 'projects', 'projectId',],
    roleContexes,
  },

  collections: {
    /**
     * Organizations represent a single restaurant
     */
    spaces: {
      doc: doc<Space, UserRoleId>({
        paramId: 'spaceId',
        docIdLength: 5,
        info: 'User Space for a single user or an organization to run facilitated projects.',
        access: {
          permissions: {
            read: ['anyone',],
            write: ['system', 'admin',],
            list: ['anyone',], // TODO restrict the list access
          },
          membersCollectionName: 'members',
        },
        mappings: {
          onCreate: [{
            type: 'update-doc',
            targetPath: 'users/{uid}/userSettings/frontend_settings',
            dataSources: {
              user: 'users/{uid}',
            },
            fields: [
              {
                from: 'name',
                to: 'spaces.{spaceId}.name',
              }, {
                from: 'description',
                to: 'spaces.{spaceId}.description',
              },
              {
                from: 'media',
                to: 'spaces.{spaceId}.media',
              },
            ],
          },],
          onUpdate: [{
            type: 'update-collection',
            targetPath: 'users/{_uid}/userSettings/frontend_settings',
            fields: [{
              from: 'name',
              to: 'spaces.{spaceId}.name',
            }, {
              from: 'description',
              to: 'spaces.{spaceId}.description',
            }, {
              from: 'media',
              to: 'spaces.{spaceId}.media',
            },
            ],
            iterators: {
              _uid: {
                dataSource: 'spaces/{spaceId}/members',
                field: 'uid',
              },
            },
          },
          {
            type: 'update-collection',
            targetPath: 'spaces/{spaceId}/projects/{_projectId}',
            fields: [{
              from: 'name',
              to: 'spaceName',
            },
            ],
            iterators: {
              _projectId: {
                dataSource: 'spaces/{spaceId}/projects',
                field: 'id',
              },
            },
          },

          ],
          onDelete: [{
            type: 'update-collection',
            targetPath: 'users/{_uid}/userSettings/frontend_settings',
            iterators: {
              _uid: {
                dataSource: 'spaces/{spaceId}/members',
                field: 'uid',
              },
            },
            fields: [{
              from: '_deleteField',
              to: 'spaces.{spaceId}',
            },],
          },],
        },
        fields: {
          ...schemaSpaceFields<PlanId, ModuleId, UserRoleId>(),
          ...changeInfoFields,
          plan: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            defaultValue: 'free',
            required: true,
          },
          modules: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            defaultValue: [],
          },
          projectNo: {
            dataType: DataType.Integer,
            type: FieldType.Descriptor,
            defaultValue: 0,
            description: 'Project number counter. This is used to keep the project id small as possible.',
          },
          description: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          name: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            indexed: true,
          },
          media: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
            stringified: true,
          },
          seatStats: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
          owners: {
            dataType: DataType.Array,
            type: FieldType.Descriptor,
            description: 'Array of space owners',
          },
        },
        collections: {
          spaceSettings: {
            doc: doc<SpaceSettings, UserRoleId>({
              paramId: 'spaceSettingId',
              info: 'Space settings',
              access: {
                permissions: {
                  read: ['system', 'admin',],
                  write: ['system',],
                  list: ['system',],
                },
              },
              fields: {
                type: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                  required: true,
                  indexed: true,
                },
                users: {
                  dataType: DataType.Map,
                  type: FieldType.Descriptor,
                  required: false,
                },
              },
            }),
          },
          members: {
            doc: doc<Member<ShortUserRoleId>, UserRoleId>({
              paramId: 'memberId',
              info: 'A member of a specific space.',
              access: {
                permissions: {
                  read: ['system', 'admin',],
                  update: ['system', 'admin',],
                  list: ['system', 'admin',],
                },
              },
              mappings: {
                onCreate: [
                  {
                    type: 'update-doc',
                    targetPath: 'users/{uid}/userSettings/frontend_settings',
                    dataSources: {
                      space: 'spaces/{spaceId}',
                    },
                    fields: [
                      {
                        dataSource: 'space',
                        from: 'name',
                        to: 'spaces.{spaceId}.name',
                      },
                      {
                        dataSource: 'space',
                        from: 'description',
                        to: 'spaces.{spaceId}.description',
                      },
                      {
                        dataSource: 'space',
                        from: 'media',
                        to: 'spaces.{spaceId}.media',
                      },
                    ],
                  },

                ],

                onUpdate: [
                  {
                    type: 'update-doc',
                    targetPath: 'users/{uid}/userSettings/frontend_settings',
                    dataSources: {
                      space: 'spaces/{spaceId}',
                    },
                    fields: [
                      {
                        dataSource: 'space',
                        from: 'name',
                        to: 'spaces.{spaceId}.name',
                      },
                      {
                        dataSource: 'space',
                        from: 'description',
                        to: 'spaces.{spaceId}.description',
                      },
                      {
                        dataSource: 'space',
                        from: 'media',
                        to: 'spaces.{spaceId}.media',
                      },
                    ],
                  },
                ],

                onDelete: [
                  {
                    type: 'update-doc',
                    fields: [{
                      from: '_deleteField',
                      to: 'spaces.{spaceId}',
                    },],
                    targetPath: 'users/{uid}/userSettings/frontend_settings',
                  },

                ],
              },
              fields: {
                id: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                  description: 'Member id in context',
                },
                uid: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                  description: 'Member id in context',
                },
                role: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                  description: 'User role',
                },
                status: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                  description: 'User\'s subscription plan',
                  indexed: true,
                },
                displayName: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                  description: 'Member\'s display name seen by him or herself and possibly other members.',
                },
                lastVisitedAt: {
                  dataType: DataType.DateAndTime,
                  type: FieldType.Descriptor,
                  description: 'Last time the member visited the space.',
                  indexed: true,
                },
                extra: {
                  dataType: DataType.Map,
                  type: FieldType.Descriptor,
                  description: 'Extra information wrapped to a single field to decrease the need for schema changes and to limit us of Firestore exempts.',
                },
              },
            }),
          },
          projects: {
            doc: doc<Project, UserRoleId>({
              paramId: 'projectId',
              docIdLength: 3,
              access: {
                functions: {
                  isOpen: 'return resource.data.authStrategy == "open";',
                  isOpenProject: 'return get(/databases/$(database)/documents/spaces/$(spaceId)/projects/$(projectId)).data.authStrategy == "open";',
                  getMember: 'return exists(/databases/$(database)/documents/spaces/$(spaceId)/projects/$(projectId)/projectMembers/$(request.auth.uid)) ? get(/databases/$(database)/documents/spaces/$(spaceId)/projects/$(projectId)/projectMembers/$(request.auth.uid)).data : null;'
                },
                permissions: {
                  full: ['system', 'admin', 'facilitator',],
                  read: ['system', 'admin', 'isOpen', 'panelist',],
                  list: ['anyone',], // TODO Restrict for actual users
                  write: ['system', 'admin', 'facilitator',],
                },
                membersCollectionName: 'projectMembers',
              },
              mappings: {
                onCreate: [{
                  type: 'create-doc',
                  dataSources: {
                    user: 'users/{uid}',
                  },
                  fields: [
                    {
                      from: 'name',
                      to: 'name',
                    }, {
                      from: 'description',
                      to: 'description',
                    }, {
                      from: 'media',
                      to: 'media',
                    }, {
                      from: 'spaceName',
                      to: 'spaceName',
                    },
                    {
                      type: 'param',
                      fromParam: 'spaceId',
                      to: 'spaceId',
                    },
                  ],
                  targetPath: 'users/{uid}/usersProjects/{spaceId_projectId}',
                },],
                onUpdate: [{
                  type: 'update-collection',

                  iterators: {
                    _uid: {
                      dataSource: 'spaces/{spaceId}/projects/{projectId}/projectMembers',
                      field: 'uid',
                    },
                  },

                  merge: true,

                  fields: [
                    {
                      from: 'name',
                      to: 'name',
                    }, {
                      from: 'description',
                      to: 'description',
                    }, {
                      from: 'media',
                      to: 'media',
                    },
                    {
                      from: 'spaceName',
                      to: 'spaceName',
                    },
                  ],
                  targetPath: 'users/{_uid}/usersProjects/{spaceId_projectId}',
                },
                ],
                onDelete: [
                  {
                    type: 'delete-collection', // TODO: This must be some sort of delete set, where uid is fetched from query of project members

                    iterators: {
                      _uid: {
                        dataSource: 'spaces/{spaceId}/projects/{projectId}/projectMembers',
                        field: 'uid',
                      },
                    },

                    targetPath: 'users/{_uid}/usersProjects/{spaceId_projectId}',
                  },
                ],
              },
              fields: {
                id: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                name: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                  required: true,
                },
                description: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                authStrategy: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                owners: {
                  dataType: DataType.Array,
                  type: FieldType.Descriptor,
                },
                facilitators: {
                  dataType: DataType.Array,
                  type: FieldType.Descriptor,
                },
                nicks: {
                  dataType: DataType.Array,
                  defaultValue: [],
                  type: FieldType.Descriptor,
                },
                media: {
                  dataType: DataType.Map,
                  type: FieldType.Descriptor,
                  stringified: true,
                },
                querySequence: {
                  dataType: DataType.Integer,
                  type: FieldType.Descriptor,
                },
                keyWords: {
                  dataType: DataType.Array,
                  type: FieldType.Descriptor,
                },
                status: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                statusText: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                spaceName: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                screens: {
                  dataType: DataType.Map,
                  type: FieldType.Descriptor,
                },
              },
              collections: {
                bots: {
                  doc: doc<BotDoc, UserRoleId>({
                    paramId: 'botId',
                    access: {
                      permissions: {
                        full: ['system', 'admin', 'facilitator',],
                        read: ['system', 'admin', 'facilitator',],
                        list: ['system', 'admin', 'facilitator',],
                      },
                    },
                    fields: {
                      content: {
                        dataType: DataType.Map,
                        type: FieldType.Descriptor,
                      },
                    },
                  }),
                },
                projectMessages: {
                  doc: doc<ProjectMessageDoc, UserRoleId>({
                    paramId: 'messageId',
                    access: {
                      permissions: {
                        full: ['system', 'admin', 'facilitator',],
                        list: ['system', 'admin', 'facilitator',],
                        read: ['system', 'admin', 'facilitator',],
                      },
                    },
                    fields: {
                      content: {
                        dataType: DataType.Map,
                        type: FieldType.Descriptor,
                      },
                    },
                  }),
                },
                feed: {
                  doc: doc<FeedItem, UserRoleId>({
                    paramId: 'feedItemId',
                    access: {
                      permissions: {
                        full: ['system', 'admin', 'facilitator',],
                        list: ['system', 'admin', 'panelist', 'facilitator', 'isOpenProject',], // TODO restrict the list access
                        read: ['system', 'admin', 'panelist', 'facilitator', 'isOpenProject',], // TODO restrict the list access
                      },
                    },

                    fields: {
                      avatar: {
                        dataType: DataType.URL,
                        type: FieldType.Descriptor,
                      },
                      type: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      title: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      message: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      nick: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      path: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      projectId: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      sessionId: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      spaceId: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      canvasId: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      created: {
                        dataType: DataType.Integer,
                        type: FieldType.Descriptor,
                        indexed: true,
                      },
                      targetContent: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      targetId: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      targetNick: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },

                    },
                  }),
                },
                settings: {
                  doc: doc<ProjectSettingDoc, UserRoleId>({
                    paramId: 'settingId',
                    access: {
                      permissions: {
                        read: ['system', 'admin', 'facilitator',],
                      },
                    },
                    fields: {
                      alterEgoSet: {
                        dataType: DataType.Array,
                        type: FieldType.Descriptor,
                      },
                      usedAlterEgoIds: {
                        dataType: DataType.Array,
                        type: FieldType.Descriptor,
                      },
                      doc: {
                        dataType: DataType.Map,
                        type: FieldType.Descriptor,
                      },
                    },
                  }),
                },
                sessions: {
                  doc: doc<Session, UserRoleId>({
                    paramId: 'sessionId',
                    access: {
                      functions: {
                        isOpenSession: 'return get(/databases/$(database)/documents/spaces/$(spaceId)/projects/$(projectId)/sessions/$(sessionId)).data.authStrategy == "open";',
                      },
                      permissions: {
                        full: ['system', 'admin', 'facilitator',],
                        list: ['anyone',], // TODO restrict the list access
                        read: ['system', 'admin', 'panelist', 'isOpenProject', 'isOpenSession'],
                      },
                    },
                    fields: {
                      name: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        required: true,
                      },
                      activeCanvasId: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      metadata: {
                        dataType: DataType.Map,
                        type: FieldType.Descriptor,
                      },
                      mode: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        defaultValue: 'self-paced',
                      },
                      canvasStrip: {
                        dataType: DataType.Array,
                        type: FieldType.Descriptor,
                        stringified: true,
                        serialized: true,
                      },
                      desc: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      status: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        required: true,
                        defaultValue: 'draft',
                      },
                      authStrategy: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        defaultValue: 'restricted',
                      },
                      content: {
                        dataType: DataType.Map,
                        type: FieldType.Descriptor,
                      },
                      canvasSequence: {
                        dataType: DataType.Integer,
                        type: FieldType.Descriptor,
                      },
                      media: {
                        dataType: DataType.Map,
                        type: FieldType.Descriptor,
                        stringified: true,
                      },
                      canvasCount: {
                        dataType: DataType.Integer,
                        type: FieldType.Descriptor,
                      },
                      statusText: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      answerVisibility: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      commenting: {
                        dataType: DataType.Boolean,
                        type: FieldType.Descriptor,
                      },
                      showAnswer: {
                        dataType: DataType.Boolean,
                        type: FieldType.Descriptor,
                      },
                      showStats: {
                        dataType: DataType.Boolean,
                        type: FieldType.Descriptor,
                      },
                      voting: {
                        dataType: DataType.Boolean,
                        type: FieldType.Descriptor,
                      },
                      hasDraft: {
                        dataType: DataType.Boolean,
                        type: FieldType.Descriptor,
                      },
                      config: {
                        dataType: DataType.Map,
                        type: FieldType.Descriptor,
                      },
                    },
                    collections: {
                      canvases: {
                        doc: doc<Canvas, UserRoleId>({
                          paramId: 'canvasId',
                          access: {
                            permissions: {
                              full: ['system', 'admin', 'facilitator',],
                              read: ['system', 'admin', 'panelist', 'facilitator', 'isOpenProject', 'isOpenSession'],
                              list: ['system', 'admin', 'panelist', 'facilitator', 'isOpenProject',], // TODO restrict the list access
                            },
                          },
                          fields: {
                            paramId: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                            },
                            type: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                            },
                            content: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                            },
                            desc: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                            },
                            name: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                            },
                            axis: {
                              dataType: DataType.Map,
                              type: FieldType.Descriptor,
                              stringified: true,
                            },
                            value: {
                              dataType: DataType.Float,
                              type: FieldType.Descriptor,
                            },
                            min: {
                              dataType: DataType.Float,
                              type: FieldType.Descriptor,
                            },
                            max: {
                              dataType: DataType.Float,
                              type: FieldType.Descriptor,
                            },
                            orderNo: {
                              dataType: DataType.Integer,
                              type: FieldType.Descriptor,
                              indexed: true,
                            },
                            mode: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                            },
                            interaction: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                            },
                            frequencies: {
                              dataType: DataType.Map,
                              type: FieldType.Descriptor,
                            },
                            statistics: {
                              dataType: DataType.Map,
                              type: FieldType.Descriptor,
                            },
                            media: {
                              dataType: DataType.Map,
                              type: FieldType.Descriptor,
                              stringified: true,
                            },
                            answerVisibility: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                            },
                            // TODO Consider to move flags to a single object
                            commenting: {
                              dataType: DataType.Boolean,
                              type: FieldType.Descriptor,
                            },
                            showAnswer: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                            },
                            showStats: {
                              dataType: DataType.Boolean,
                              type: FieldType.Descriptor,
                            },
                            showAxisLabels: {
                              dataType: DataType.Boolean,
                              type: FieldType.Descriptor,
                              defaultValue: true,
                            },
                            tickLabelsPresentation: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                            },
                            showTickLabels: {
                              dataType: DataType.Boolean,
                              type: FieldType.Descriptor,
                            },
                            showMinMaxLabels: {
                              dataType: DataType.Boolean,
                              type: FieldType.Descriptor,
                            },
                            showAxisArrows: {
                              dataType: DataType.Boolean,
                              type: FieldType.Descriptor,
                              defaultValue: false,
                            },
                            voting: {
                              dataType: DataType.Boolean,
                              type: FieldType.Descriptor,
                            },
                            elements: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                              serialized: true,
                            },
                            config: {
                              dataType: DataType.Map,
                              type: FieldType.Descriptor,
                            },
                            commentInfo: {
                              dataType: DataType.Map,
                              type: FieldType.Descriptor,
                            },
                          },
                          collections: {
                            answers: {
                              doc: doc<Answer, UserRoleId>({
                                paramId: 'answerId',
                                access: {
                                  functions: {
                                    isOwner: 'let member = getMember(request); return member != null && member.id == resource.data.memberId;',
                                  },
                                  permissions: {
                                    read: ['system', 'admin', 'panelist', 'facilitator', 'isOpenProject',],
                                    create: ['system', 'admin', 'facilitator', 'panelist', 'isOpenProject',],
                                    update: ['system', 'admin', 'owner',],
                                    list: ['system', 'admin', 'panelist', 'facilitator', 'isOpenProject',], // TODO restrict the list access
                                  },
                                },
                                fields: {
                                  value: {
                                    dataType: DataType.Text,
                                    type: FieldType.Descriptor,
                                  },
                                  memberId: {
                                    dataType: DataType.Text,
                                    type: FieldType.Descriptor,
                                  },
                                  type: {
                                    dataType: DataType.Text,
                                    type: FieldType.Descriptor,
                                  },
                                  ...changeInfoFields,
                                },
                              }),
                            },
                            comments: {
                              doc: doc<Comment, UserRoleId>({
                                paramId: 'commentId',
                                indexes: {
                                  comments: [{
                                    fieldName: 'parentId',
                                  }, {
                                    fieldName: 'created',
                                  },],
                                },
                                access: {
                                  functions: {
                                    // isOwner: 'return request.auth.token.membership[spaceId + "_" + projectId].memberId == request.data.creatorId;',
                                    /**
                                      * Will only change likes.
                                      *
                                      * TODO: Restrict votes to member id specific.
                                      *
                                      * TODO: Talk with product owner, should facilitator have a right to vote?
                                      */
                                    // eslint-disable-next-line max-len
                                    isLike: 'return (isPanelist(request) || isFacilitator(request)) && request.resource.data.diff(request.resource.data).affectedKeys().hasOnly(["likes", "votes"]);',
                                  },
                                  permissions: {
                                    full: ['facilitator', 'admin', 'system'], // TODO We need to restrict facilitator's editing to specific fields on others comments
                                    read: ['system', 'admin', 'facilitator', 'panelist', 'isOpenProject',],
                                    create: ['facilitator', 'admin', 'panelist', 'isOpenProject',],
                                    update: ['isLike', 'isOpenProject',],
                                    list: ['system', 'admin', 'facilitator', 'panelist', 'isOpenProject',], // TODO restrict the list access
                                  },
                                },
                                fields: {
                                  replyCount: {
                                    dataType: DataType.Integer,
                                    type: FieldType.Descriptor,
                                  },
                                  parentPath: {
                                    dataType: DataType.Text,
                                    type: FieldType.Descriptor,
                                  },
                                  text: {
                                    dataType: DataType.Text,
                                    type: FieldType.Descriptor,
                                  },
                                  value: {
                                    dataType: DataType.Map,
                                    type: FieldType.Descriptor,
                                  },
                                  likes: {
                                    dataType: DataType.Integer,
                                    type: FieldType.Descriptor,
                                    defaultValue: {
                                      down: 0,
                                      up: 0,
                                    },
                                    description: 'The like sums for both: up and down.',
                                  },
                                  votes: {
                                    dataType: DataType.Array,
                                    type: FieldType.Descriptor,
                                  },
                                  replies: {
                                    dataType: DataType.Array,
                                    type: FieldType.Descriptor,
                                  },
                                  featured: {
                                    dataType: DataType.Boolean,
                                    type: FieldType.Descriptor,
                                  },
                                  avatar: {
                                    dataType: DataType.Text,
                                    type: FieldType.Descriptor,
                                  },
                                  nick: {
                                    dataType: DataType.Text,
                                    type: FieldType.Descriptor,
                                  },
                                  answer: {
                                    dataType: DataType.Map,
                                    type: FieldType.Descriptor,
                                  },
                                  role: {
                                    dataType: DataType.Text,
                                    type: FieldType.Descriptor,
                                  },
                                  parentId: {
                                    dataType: DataType.Text,
                                    type: FieldType.Descriptor,
                                  },
                                  firstLinkInfo: {
                                    dataType: DataType.Map,
                                    type: FieldType.Descriptor,
                                  },
                                  hashTags: {
                                    dataType: DataType.Array,
                                    type: FieldType.Descriptor,
                                  },
                                  labels: {
                                    dataType: DataType.Array,
                                    type: FieldType.Descriptor,
                                  },
                                  links: {
                                    dataType: DataType.Array,
                                    type: FieldType.Descriptor,
                                  },
                                  mentions: {
                                    dataType: DataType.Array,
                                    type: FieldType.Descriptor,
                                  },
                                  quadrant: {
                                    dataType: DataType.Integer,
                                    type: FieldType.Descriptor,
                                  },
                                  ...changeInfoFields,
                                },
                              }),
                            },
                          },
                        }),
                      },
                      canvasDrafts: {
                        doc: doc<DraftCanvasDocument, UserRoleId>({
                          paramId: 'canvasId',
                          access: {
                            permissions: {
                              full: ['system', 'admin', 'facilitator',],
                              read: ['system', 'admin', 'facilitator',],
                              list: ['system', 'admin', 'facilitator',],
                            },
                          },
                          fields: {
                            status: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                            },
                            content: {
                              dataType: DataType.Map,
                              type: FieldType.Descriptor,
                            },
                          },
                        }),
                      },
                      sessionStatistics: {
                        doc: doc<SessionStatistics, UserRoleId>({
                          paramId: 'statId',
                          access: {
                            permissions: {
                              write: ['system', 'admin', 'facilitator',],
                              read: ['anyone',],
                              list: ['system', 'admin', 'facilitator',],
                            },
                          },
                          fields: {
                            type: {
                              dataType: DataType.Text,
                              type: FieldType.Descriptor,
                              required: true,
                            },
                            tags: {
                              dataType: DataType.Array,
                              type: FieldType.Descriptor,
                            },
                            labels: {
                              dataType: DataType.Array,
                              type: FieldType.Descriptor,
                            },
                          },
                        }),
                      },
                    },
                  }),
                },
                sessionDrafts: {
                  doc: doc<DraftSessionDocument, UserRoleId>({
                    paramId: 'draftId',
                    access: {
                      permissions: {
                        full: ['system', 'admin', 'facilitator',],
                        read: ['system', 'admin', 'facilitator',],
                        list: ['system', 'admin', 'facilitator',],
                      },
                    },
                    fields: {
                      status: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                      },
                      content: {
                        dataType: DataType.Map,
                        type: FieldType.Descriptor,
                      },
                    },
                  }),
                },
                panels: {
                  doc: doc<Panel, UserRoleId>({
                    paramId: 'panelId',
                    info: 'A single panel of experts',
                    access: {
                      permissions: {
                        write: ['system', 'admin', 'facilitator',],
                        read: ['system', 'admin', 'facilitator',],
                        list: ['system', 'admin', 'facilitator',],
                      },
                    },
                    fields: {
                      cells: {
                        dataType: DataType.Map,
                        type: FieldType.Descriptor,
                        required: true,
                        description: 'Members of each cell in matrix pointed out by row_column -key.',
                      },
                      columnLabels: {
                        dataType: DataType.Array,
                        type: FieldType.Descriptor,
                        required: true,
                        description: 'Column labels of the matrix.',
                      },
                      rowLabels: {
                        dataType: DataType.Array,
                        type: FieldType.Descriptor,
                        required: true,
                        description: 'Row labels of the matrix.',
                      },
                      description: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        description: 'Optional description of the panel.',
                      },
                      id: {
                        dataType: DataType.DocumentId,
                        type: FieldType.Descriptor,
                        required: true,
                        description: 'Internal identifier of the panel.',
                      },
                      name: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        description: 'Name of the panel.',
                      },
                    },
                  }),
                },
                projectMembers: {
                  doc: doc<ProjectMember, UserRoleId>({
                    paramId: 'memberId',
                    info: 'A member of a specific project.',

                    access: {
                      permissions: {
                        write: ['system', 'admin', 'facilitator',],
                        read: ['system', 'admin', 'facilitator',],
                        list: ['system', 'admin', 'facilitator',],
                      },
                    },

                    mappings: {
                      onCreate: [
                        {
                          targetPath: '/users/{uid}/usersProjects/{spaceId_projectId}',
                          dataSources: {
                            project: '/spaces/{spaceId}/projects/{projectId}',
                          },
                          type: 'create-doc',
                          // when: 'args.data.status === "a"',
                          fields: [
                            {
                              dataSource: 'project',
                              from: 'name',
                              to: 'name',
                            }, {
                              dataSource: 'project',
                              from: 'description',
                              to: 'description',
                            }, {
                              dataSource: 'project',
                              from: 'media',
                              to: 'media',
                            }, {
                              type: 'param',
                              fromParam: 'spaceId',
                              to: 'spaceId',
                            },
                          ],
                        },
                      ],
                      onUpdate: [{
                        targetPath: '/users/{uid}/usersProjects/{spaceId_projectId}',
                        dataSources: {
                          project: '/spaces/{spaceId}/projects/{projectId}',
                        },
                        type: 'create-doc',
                        // when: 'args.data.status === "a"',
                        fields: [
                          {
                            dataSource: 'project',
                            from: 'name',
                            to: 'name',
                          }, {
                            dataSource: 'project',
                            from: 'description',
                            to: 'description',
                          }, {
                            dataSource: 'project',
                            from: 'media',
                            to: 'media',
                          }, {
                            type: 'param',
                            fromParam: 'spaceId',
                            to: 'spaceId',
                          },
                        ],
                      },],
                      onDelete: [
                        {
                          type: 'delete-doc',
                          targetPath: '/users/{uid}/usersProjects/{spaceId_projectId}',
                        },
                      ],
                    },
                    fields: {
                      id: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        description: 'User\'s member id',
                      },
                      uid: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        description: 'User\'s id on platform',
                      },
                      role: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        description: 'User\'s role',
                      },
                      status: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        description: 'User\'s subscription plan',
                      },
                      displayName: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        description: 'Member\'s display name seen by him or herself and possibly other members.',
                      },
                      avatar: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        description: 'Member\'s avatar URL.',
                      },
                      nick: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        description: 'Member\'s nick in the project used to refer the member in chat without revealing his or her identity.',
                      },
                      email: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        description: 'Member\'s email.',
                        indexed: true,
                      },
                      extra: {
                        dataType: DataType.Text,
                        type: FieldType.Descriptor,
                        description: 'Member\'s description seen by him or herself and possibly other members.',
                      },
                      lastVisitedAt: {
                        dataType: DataType.DateAndTime,
                        type: FieldType.Descriptor,
                        description: 'Last time the member visited the space.',
                        indexed: true,
                      },
                    },
                  }),
                },
                templates: {
                  doc: doc<TemplateDoc, UserRoleId>({
                    paramId: 'templateId',
                    access: {
                      permissions: {
                        read: ['facilitator', 'admin', 'system',],
                        list: ['facilitator', 'admin', 'system',],
                        create: ['facilitator', 'admin', 'system',],
                        update: ['facilitator', 'admin', 'system',],
                        delete: ['facilitator', 'admin', 'system',],
                      },
                    },
                    fields: {
                      content: {
                        dataType: DataType.Map,
                        type: FieldType.Descriptor,
                      },
                    },
                  }),
                },
              },
            }),
          },

          invoices: {
            doc: doc<InvoiceDoc, UserRoleId>({
              paramId: 'invoiceId',
              access: {
                permissions: {
                  read: ['system',],
                  list: ['system',],
                  create: ['system',],
                  update: ['system',],
                },
              },
              fields: {
                ...changeInfoFields,

                content: {
                  dataType: DataType.Map,
                  type: FieldType.Descriptor,
                  description: 'Invoice content packed under a single object to save exception fields',
                  required: true,
                },
              },
            }),
          },
          transactions: {
            doc: doc<AccountTransactionDoc, UserRoleId>({
              paramId: 'transactionId',
              access: {
                permissions: {
                  read: ['system',],
                  list: ['system',],
                  create: ['system',],
                  update: ['system',],
                },
              },
              fields: {
                content: {
                  dataType: DataType.Map,
                  type: FieldType.Descriptor,
                  description: 'Transaction content packed under a single object to save exception fields',
                  required: true,
                },
              },
            }),
          },
        },
      }),
    },

    pages: {
      doc: doc<PageDoc, UserRoleId>({
        paramId: 'pageId',
        access: {
          permissions: {
            read: ['anyone',],
            full: ['system',],
          },
        },
        fields: {
          content: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
            description: 'Page content packed under a single object to save exception fields',
          },
        },
      }),
    },

    users: {
      doc: doc<User<ShortUserRoleId>, UserRoleId>({
        paramId: 'uid',
        info: 'All Aavistus users.',

        access: {
          functions: {
            isOwner: 'return request.auth != null && request.auth.uid == uid;',
            isAuthenticated: 'return request.auth != null',
          },
          permissions: {
            read: ['system', 'owner',],
            list: ['system',],
            create: ['isAuthenticated',],
            update: ['system', 'owner',],
          },
        },

        mappings: {
          onCreate: [
            {
              type: 'create-doc',
              postOperation: true,
              targetPath: '/users/{uid}/userSettings/frontend_settings',
              fields: [{
                type: 'value',
                to: 'type',
                fromValue: 'frontend',
              }, {
                type: 'value',
                to: 'spaces',
                fromValue: {},
              },],
            },
          ],
          onDelete: [{
            type: 'update-collection',
            targetPath: 'spaces/{_spaceId}/members/{uid}',
            fields: [{
              type: 'value',
              to: 'status',
              fromValue: 'deleted',
            },
            ],
            iterators: {
              _spaceId: {
                dataSource: 'users/{uid}/userSettings/frontend_settings/spaces',
                field: 'spaceId',
              },
            },
          },
          ],
        },
        fields: {
          ...(schemaUserFields<PlanId, ModuleId, UserRoleId>() as any),

          extra: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
            description: 'Extra information about the user.',
          },
          // emailVerified: {
          //   dataType: DataType.Boolean,
          //   type: FieldType.Descriptor,
          //   description: 'Is email verified or not',
          // },
          // freezeVideoPlayback: {
          //   dataType: DataType.Boolean,
          //   type: FieldType.Descriptor,
          //   defaultValue: true,
          //   description: 'An accessibility setting for pausing automatic video play.',
          // },
          // showOnboardingFlow: {
          //   dataType: DataType.Boolean,
          //   type: FieldType.Descriptor,
          //   defaultValue: true,
          //   description: 'Show introduction flow when the user enters a session level for the first time.',
          // },
        },

        collections: {
          notifications: {
            doc: doc<NotificationDoc, UserRoleId>({
              paramId: 'notificationId',
              access: {
                functions: {
                  isOwner: 'return request.auth != null && request.auth.uid == uid;',
                },
                permissions: {
                  read: ['system', 'owner',],
                  create: ['owner',],
                  update: ['system', 'owner',],
                  list: ['system', 'owner',],
                },
              },
              excludeMappings: 'all',

              fields: {
                commentId: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                created: {
                  dataType: DataType.Integer,
                  type: FieldType.Descriptor,
                },
                mentioner: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                message: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                path: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                avatar: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                projectId: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                projectTitle: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                sessionId: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                queryTitle: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                canvasId: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                canvasTitle: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                spaceId: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                spaceTitle: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                title: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
                type: {
                  dataType: DataType.Text,
                  type: FieldType.Descriptor,
                },
              },
            }),
          },
          messages: {
            doc: doc<UserMessageDoc, UserRoleId>({
              paramId: 'messageId',
              access: {
                functions: {
                  isOwner: 'return request.auth != null && request.auth.uid == uid;',
                },
                permissions: {
                  full: ['system', 'owner',],
                },
              },
              excludeMappings: 'all',

              fields: {
                content: {
                  dataType: DataType.Map,
                  type: FieldType.Descriptor,
                },
                // type: {
                //   dataType: DataType.Text,
                //   type: FieldType.Descriptor,
                //   indexed: true,
                // },
              },
            }),
          },
          userSettings: {
            doc: doc<UserSettings, UserRoleId>({
              paramId: 'settingId',
              namedDocuments: ['frontend_settings', 'backend_settings',],
              access: {
                functions: {
                  // User is the owner and the document is frontend type
                  isFrontend: 'return request.auth.uid == uid && resource.data.type == "frontend";',
                },
                permissions: {
                  read: ['system', 'isFrontend',],
                  create: ['system',],
                  update: ['system',],
                  list: ['system',],
                },
              },
              fields: {
                spaces: {
                  dataType: DataType.Map,
                  type: FieldType.Descriptor,
                  defaultValue: {},
                },
                type: {
                  dataType: DataType.Map,
                  type: FieldType.Descriptor,
                  defaultValue: 'backend',
                },
                notificationTokens: {
                  dataType: DataType.Array,
                  type: FieldType.Descriptor,
                  description: 'An array of user tokens used for sending push messages.',
                },
              },
            }),
          },
          usersProjects: {
            doc: doc<UserProjectInfo, UserRoleId>({
              paramId: 'projectId',
              access: {
                functions: {
                  isOwner: 'return request.auth.uid == uid',
                },
                permissions: {
                  list: ['system', 'owner',],
                  read: ['system', 'owner',],
                  write: ['system',],
                },
              },
              indexes: {
                myProjects: [{
                  fieldName: 'spaceId',
                  order: 'asc',
                }, {
                  fieldName: 'name',
                  order: 'asc',
                },],
              },
              excludeMappings: 'all',
              fields: {
                description: {
                  type: FieldType.Descriptor,
                  dataType: DataType.Text,
                },
                media: {
                  type: FieldType.Descriptor,
                  dataType: DataType.Map,
                },
                name: {
                  type: FieldType.Descriptor,
                  dataType: DataType.Text,
                  indexed: true,
                },
                spaceName: {
                  type: FieldType.Descriptor,
                  dataType: DataType.Text,
                },
                spaceId: {
                  type: FieldType.Descriptor,
                  dataType: DataType.Text,
                  indexed: true,
                },
              },
            }),
          },
        },
      }),
    },

    customers: {
      doc: doc<CustomerDoc, UserRoleId>({
        paramId: 'customerId',
        docIdLength: 20,
        info: 'Aavistus customers',
        access: {
          functions: {
            isCustomer: 'let user = getUser(request); return request.auth.uid != null && user.customerId == customerId;',
            isAuthenticated: 'return request.auth != null;',
          },
          permissions: {
            read: ['isCustomer', 'system',],
            list: ['isCustomer', 'system',],
            create: ['isAuthenticated', 'system',],
            update: ['isCustomer', 'system',],
          },
        },

        fields: {
          content: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
        },
        collections: {

          payments: {
            doc: doc<PaymentDoc, UserRoleId>({
              paramId: 'paymentId',
              access: {
                functions: {
                  // eslint-disable-next-line max-len
                  isCustomer: 'return request.auth.uid != null && get(/databases/$(database)/documents/users/$(request.auth.uid)).data.customerId == customerId;',
                },
                permissions: {
                  read: ['isCustomer', 'system',],
                  list: ['isCustomer', 'system',],
                  create: ['isCustomer', 'system',],
                  update: ['isCustomer', 'system',],
                },
              },
              fields: {
                content: {
                  dataType: DataType.Map,
                  type: FieldType.Descriptor,
                  description: 'Payment content',
                },
              },
            }),
          },
        },
      }),
    },

    invitations: {
      doc: doc<Invitation<ShortUserRoleId>, UserRoleId>({
        paramId: 'invitationToken',
        access: {
          permissions: {
            read: ['anyone',],
          },
        },
        fields: {
          email: {
            dataType: DataType.Email,
            type: FieldType.Descriptor,
            required: true,
          },
          displayName: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          platformName: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          spaceName: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          media: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
          phoneNumber: {
            dataType: DataType.PhoneNumber,
            type: FieldType.Descriptor,
          },
          role: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          created: {
            dataType: DataType.UTC, // TODO: Include UTC to Firebase schema
            type: FieldType.Descriptor,
            indexed: true,
            required: true,
          },
          contextId: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          params: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
          memberId: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          expires: {
            dataType: DataType.Integer,
            type: FieldType.Descriptor,
            indexed: true,
          },
          status: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          projectName: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          isPlatformUser: {
            dataType: DataType.Boolean,
            type: FieldType.Descriptor,
          },
          error: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
          message: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
        },
      }),
    },

    inquiries: {
      doc: doc<InquiryDoc, UserRoleId>({
        paramId: 'inquiryId',
        access: {
          permissions: {
            read: ['anyone',],
          },
        },
        fields: {
          content: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
            description: 'Inquiry content',
          },
        },
      }),
    },

    messageCenter: {
      doc: doc<MessageDoc, UserRoleId>({
        paramId: 'messageId',
        access: {
          functions: {
            isOwner: 'return request.auth != null && request.resource.data.content.sender.uid != null && request.auth.uid == request.resource.data.content.sender.uid;',
          },
          permissions: {
            full: ['system', 'owner',],
          },
        },
        fields: {
          content: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
            description: 'Message content',
          },
        },
      }),
    },

    mailCenter: {
      doc: doc<EmailData, UserRoleId>({
        paramId: 'emailToken',
        info: 'MailCenter to handle all emails, that leave from app.',
        excludeMappings: 'all',

        fields: {
          to: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
            required: true,
          },
          status: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          htmlPart: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            required: true,
          },
          textPart: {
            dataType: DataType.DocumentId,
            type: FieldType.Descriptor,
            required: true,
          },
          fromUser: {
            dataType: DataType.Integer,
            type: FieldType.Descriptor,
          },
          subject: {
            dataType: DataType.DateAndTime,
            type: FieldType.Descriptor,
          },
          error: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
        },
      }),
    },
  },
};
