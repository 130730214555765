/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ErrorBoundary } from '@sentry/react';
import { useCanvas } from '../../../../bloc/canvas/CanvasBloc';
import Answer2DMatrix from '../../../canvas/canvasTypes/Matrix2DQuestionCanvas/Answer2DMatrix';
import Center from '../../../common/layout/Center';
import styled from '@emotion/styled';
import usePathParams from '../../../../utils/hooks/usePathParams';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { Canvas, Matrix2DQuestionCanvas } from '@shared/schema/src';
import type { Graph2DWidgetProps } from '../renderers/Graph2DWidget';
import type { WithId } from '@mindhiveoy/schema';

const AutoMargin = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`;

const Graph2DWidgetEditor: BuilderFunction<Graph2DWidgetProps> = ({
  data,
}: Graph2DWidgetProps) => {
  const {
    sessionId,
    canvasId,
  } = data;

  const {
    spaceId,
    projectId,
  } = usePathParams();

  const canvasParams = useMemo(() => {
    return {
      spaceId,
      projectId,
      sessionId,
      canvasId,
    };
  }, [canvasId, projectId, sessionId, spaceId,]);

  if (!sessionId) {
    return <Center>
      Please pick session from inspector
    </Center>;
  }

  if (!canvasId) {
    return <Center>
      Please pick canvas from inspector
    </Center>;
  }
  return <Matrix
    data={data}
    canvasParams={canvasParams}
  />;
};

const Matrix = ({
  data,
  canvasParams,
}: any) => {
  const [height, setHeight,] = useState<number>(0);
  const [width, setWidth,] = useState<number>(0);

  const scale = 1.5;

  const [canvasFromBloc, setCanvas,] = useState<WithId<Canvas>>();

  const canvasBloc = useCanvas(canvasParams);

  useEffect(
    () => canvasBloc.subscribe(
      setCanvas,
      (error) => console.error(error)
    ), [canvasBloc,]
  );

  // Generate Size for the Graph child component, based on his parent props
  const generateSize = useCallback((height: number, width: number): void => {
    setHeight(height / scale);
    setWidth(width / scale);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      generateSize(height, width);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
    // Height and Width states are modified
    // TODO: This should be based on the element size
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateSize,]);

  return <AutoMargin ref={(newRef) => {
    setHeight(newRef?.clientHeight ?? 0);
    setWidth(newRef?.clientWidth ?? 0);
  }}>
    <Center>
      <ErrorBoundary>
        <Answer2DMatrix
          size={height > width ? {
            height: height / scale, width: height / scale,
          } :
            {
              height: width / scale, width: width / scale,
            }}
          characteristic={data.characteristics}
          canvas={data?.dataMode === 'snapshot' && data?.snapshot?.statistics ?
            {
              ...data?.snapshot, statistics: {
                ...data?.snapshot.statistics,
                averageX: data?.answer.x,
                averageY: data?.answer.y,
              },
            } as Matrix2DQuestionCanvas :
            canvasFromBloc as Matrix2DQuestionCanvas}
        />
      </ErrorBoundary>
    </Center>
  </AutoMargin>;
};

Graph2DWidgetEditor.config = {
  type: 'graph-2d',
  displayName: 'Graph 2d',
  props: {
    sessionId: {
      type: 'session-picker',
      defaultValue: '',
      displayName: 'Session',
    },
    canvasId: {
      type: 'canvas-picker',
      defaultValue: '',
      displayName: 'Canvas',
    },
    characteristics: {
      type: 'stat-characteristic',
      options: [
        {
          value: 'mean',
          label: 'Mean',
        },
        {
          value: 'average',
          label: 'Average',
        },
        {
          value: 'q1',
          label: '1. quartile',
        },
        {
          value: 'median',
          label: 'Median',
        },
        {
          value: 'q3',
          label: '3. quartile',
        },
      ],
    },
    dataMode: {
      type: 'data-mode',
      defaultValue: 'real-time',
      options: [
        {
          value: 'snapshot',
          label: 'Snapshot',
        }, {
          value: 'real-time',
          label: 'Real time',
        },
      ],
    },
  },
};

export default Graph2DWidgetEditor;
