/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable require-jsdoc */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractComponentModel } from './AbstractComponentModel';
import { ListenerHelper } from '../../../utils/listener/listenerHelper';
import { deleteObjectField, getObjectField, setObjectField } from '@mindhiveoy/foundation';
import { extractDeletedProperties } from './extractDeletedProperties';
import { startTransition } from 'react';
import Memento, { MementoItemInstance } from '../Memento';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import type { BuilderComponentPropsBase, BuilderComponentPropsBaseWithId } from '../widgets/PageWidgetRenderer';

export type ComponentArrayModelDataChangeListener = (
  components: BuilderComponentPropsBase<any>[]
) => void;

export interface ComponentDataChange<D = any> {
  oldValue?: BuilderComponentPropsBase<D>;
  newValue?: BuilderComponentPropsBase<D>;
  sourcePropertyName?: string;
}

export type ComponentModelDataChangeListener<D = any> = (
  change: ComponentDataChange<D>,
  reset?: boolean
) => void;

export type ComponentModelSelectionListener = (selection: BuilderComponentPropsBaseWithId | undefined) => void;

export type ComponentModelType = 'single' | 'array';

// eslint-disable-next-line valid-jsdoc
/**
 * Component model to operate with builder's data
 * TODO: fully covered unit testing
 */
export class ComponentModel<D> extends AbstractComponentModel<BuilderComponentPropsBase<D>> {
  public readonly name = 'ComponentModel';

  setData(data: D) {
    const oldValue = cloneDeep(this.$component);
    this.$component.data = data;

    this.fireDataChangeEvent({
      oldValue,
      newValue: this.$component,
    });
  }
  private $dataChangeListeners = new ListenerHelper<ComponentModelDataChangeListener>();

  private $component: BuilderComponentPropsBase<D>;

  private $history = new Memento();
  /**
   *
   * @param {BuilderComponentProps} component  Initial content of the component model
   */
  constructor(component: BuilderComponentPropsBase<D>) {
    super();
    this.$component = component;
  }

  public get history() {
    return this.$history;
  }
  /**
   * Reset the model.
   * @param {BuilderComponentPropsBase<D>} component New component data to reset the state.
   */
  reset(component?: BuilderComponentPropsBase<D>) {
    try {
      this.$state = 'resetting';
      this.$history.reset();
      const oldValue = this.$component;
      this.$component = component!;
      this.fireDataChangeEvent({
        oldValue,
        newValue: component!,
      }, true);
    } finally {
      this.invalidated = false;
      this.state = 'default';
    }
  }

  isEqual = (data: BuilderComponentPropsBase<D>): boolean => {
    return isEqual(this.$component, data);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  prepare = () => {
    this.validateBeforeSaveListeners.fire(this.$component);
  };

  public invalidate = () => {
    if (this.state === 'resetting') {
      return;
    }
    this.invalidated = true;
    // TODO: Make later, now trigger the change with brute force.
    this.fireDataChangeEvent({
      oldValue: this.$component,
      newValue: this.$component,
    });
  };

  /**
   * Return the current component data.
   *
   * @return {BuilderComponentPropsBase<D> | undefined} The current component data.
   */
  public get component() {
    const result = cloneDeep(this.$component);
    // startTransition(() => {
    // TODO: Study this functionality, there is a potential issue with the execution order of events
    this.validateBeforeSaveListeners.fire(result);
    // });
    return result;
  }

  /**
   * Replace the whole data of the component model with a new one.
   *
   * @param {BuilderComponentPropsBase<D>} data New data
   */
  public set component(data: BuilderComponentPropsBase<D>) {
    const oldValue = this.$component;
    this.$component = cloneDeep(data);
    this.fireDataChangeEvent({
      oldValue,
      newValue: this.$component,
    });
  }

  /**
   * Subscribe to listen component model data changes
   * @param {ComponentModelDataChangeListener} listener Listener function
   * @deprecated Use subscribeToDataChange instead.
   */
  private _addDataChangeListener = (listener: ComponentModelDataChangeListener) => {
    this.$dataChangeListeners.add(listener);
    // Fire when registering to get the initial state.
    listener({
      oldValue: undefined,
      newValue: this.$component,
    });
  };

  public subscribeToDataChange = (listener: ComponentModelDataChangeListener) => {
    this._addDataChangeListener(listener);
    return () => {
      this._removeDataChangeListener(listener);
    };
  };

  /**
   * Indicate if the model has any data
   * @return {true} True when empty.
   */
  public isEmpty = () => {
    return this.$component === undefined;
  };

  public isModified = () => {
    return this.$history.hasChanged() || this.invalidated;
  };

  public clearHistory = () => {
    this.$history.reset();
    this.invalidated = false;
    // TODO: Update component state based memento
    this.fireDataChangeEvent({
      oldValue: this.$component,
      newValue: this.$component,
    });
  };

  /**
   * Unsubscribe to listen component model data changes.
   *
   * @param {ComponentModelDataChangeListener} listener Listener function
   * @deprecated Use subscribeToDataChange instead.
   */
  private _removeDataChangeListener = (listener: ComponentModelDataChangeListener) => {
    this.$dataChangeListeners.remove(listener);
  };

  /**
   * Update a single property of one component
   * @param {string}             propertyName Property name
   * @param {any}                value        The new value.
   * @param {string | undefined} [type]       The type of the property.
   * @return {any} the new value.
   */
  private _updateProperty = <P extends keyof D>(
    propertyName: keyof D | string,
    value: D[P] | any,
    ...args: any[]
  ) => {
    if (!this.$component) {
      console.error('No component defined.');
      return;
    }
    const before = cloneDeep(this.$component.data);
    const oldValue = this.$component;

    const widgetData = cloneDeep(this.$component);

    let data: any = widgetData.data ?? {};

    const updateProperty = (propertyName: any, value: any) => {
      if (!propertyName) {
        console.error('No property name defined.');
        return;
      }
      data = setObjectField(data, propertyName as string, value);
    };

    updateProperty(propertyName, value);

    while (args.length > 0) {
      const propertyName = args.pop();
      const value = args.pop();
      updateProperty(propertyName, value);
    }

    this._updateData(widgetData, propertyName, before, oldValue, value, data);
  };

  private _updateData = <P extends keyof D>(
    widgetData: BuilderComponentPropsBase<D>,
    propertyName: keyof D | string,
    before: D,
    oldValue: BuilderComponentPropsBase<D>,
    value: D[P] | any,
    data: any
  ) => {
    widgetData.data = data;

    const child = this.childModelFieldMap.get(propertyName as string);
    if (child) {
      child.upstreamPropertyUpdate(propertyName as any, value);
    }

    // TODO: match property name to merge consecutive changes.
    this.$history.change(
      propertyName as string,
      new MementoItemInstance(this, before!,
        cloneDeep(data)!
      )
    );

    this.$component = widgetData as BuilderComponentPropsBase<D>;
    // TODO: Control event transmit during recursive update.
    this.fireDataChangeEvent({
      oldValue,
      newValue: this.$component,
      sourcePropertyName: propertyName as string,
    });

    return widgetData.data;
  };

  getPropertyValue = (fieldPath: string) => {
    const data = this.$component.data;
    if (!data) {
      return undefined;
    }
    return getObjectField(data, fieldPath);
  };

  deleteProperty = (fieldPath: string) => {
    let newData = cloneDeep(this.$component.data) as any;
    const path = fieldPath.split('.');
    newData = deleteObjectField(newData, path);
    this.setData(newData);
  };

  public get updateProperty() {
    return this._updateProperty;
  }

  public set updateProperty(value) {
    this._updateProperty = value;
  }

  /**
   * Extract the deleted properties compared to the initial data.
   * @param {T} initialData  The initial data to compare against.
   * @returns {string[]}    The list of deleted properties as strings in dot notation to point out to the property.
   */
  extractDeletedProperties = <T>(initialData: T): string[] => {
    return extractDeletedProperties(this.$component.data, initialData);
  };

  private fireDataChangeEvent = (
    args: ComponentDataChange,
    reset = false
  ) => {
    if (isEqual(args.oldValue?.data, args.newValue?.data)) {
      return;
    }
    const _args = cloneDeep(args);

    startTransition(() => {
      this.$dataChangeListeners.fire(_args, reset);
    });
  };
}
