import { LABEL_PADDING_REM, MOBILE_THRESHOLD, TICK_FONT_SIZE_REM } from '../../labelUnits';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import type { MatrixMode, RangeItem } from '@shared/schema/src';
import type { Render2DTickFunction } from '../layouts/Matrix2DLayout';

const RangeTicks = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  font-size: ${TICK_FONT_SIZE_REM}rem;
  flex-direction: column;
  justify-content: center;
`;

const TickLabel = styled$.div<{
  $justify?: 'left' | 'right';
}>(({
  $justify = 'left',
}) => `
  writing-mode: vertical-${$justify === 'left' ? 'lr' : 'rl'};  
  position: relative;
  flex: 1;
  writing-mode: vertical-rl;
  box-sizing: border-box;
  padding-left: ${LABEL_PADDING_REM}rem;
  padding-right: ${LABEL_PADDING_REM}rem;
  width: ${TICK_FONT_SIZE_REM + LABEL_PADDING_REM * 2}rem;
  transform: rotate(180deg);
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media(max-width: ${MOBILE_THRESHOLD}px) {
    white-space: nowrap;
    font-size: ${TICK_FONT_SIZE_REM / 2}rem;
    padding-left: ${LABEL_PADDING_REM / 2}rem;
    padding-right: ${LABEL_PADDING_REM / 2}rem;
    width: ${TICK_FONT_SIZE_REM / 2 + LABEL_PADDING_REM}rem;
  }
`);

const DiscreteTickLabel = styled$.div<{
  $justify?: 'left' | 'right';
}>(({
  $justify = 'left',
}) => `
  writing-mode: vertical-${$justify === 'left' ? 'lr' : 'rl'};
  flex: 1;
  box-sizing: border-box;
  padding-left: ${LABEL_PADDING_REM}rem;
  padding-right: ${LABEL_PADDING_REM}rem;
  margin: 0;
  transform: rotate(180deg);
  text-align: center;
  width: ${TICK_FONT_SIZE_REM + LABEL_PADDING_REM * 2}rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media(max-width: ${MOBILE_THRESHOLD}px) {
    font-size: ${TICK_FONT_SIZE_REM / 2}rem;
    padding-left: ${LABEL_PADDING_REM / 2}rem;
    padding-right: ${LABEL_PADDING_REM / 2}rem;
    width: ${TICK_FONT_SIZE_REM / 2 + LABEL_PADDING_REM}rem;
  }
`);

export interface TickMarksProps {
  mode: MatrixMode;
  justify?: 'left' | 'right';
  range: RangeItem[];
  side: 'left' | 'right';
  onRenderTick?: Render2DTickFunction;
}

export const YTickMarks = ({
  range,
  mode = 'discrete',
  justify = 'left',
  side,
  onRenderTick,
}: TickMarksProps) => {
  const reversedRange = useMemo(() => {
    return range.slice(0).reverse();
  }, [range,]);

  return <RangeTicks>
    {
      reversedRange.map(
        ({
          label,
          value,
        }, index) => {
          const content = onRenderTick?.({
            axis: 'y',
            item: {
              label,
              value,
            },
            side,
            index: reversedRange.length - index - 1,
          }) ?? label;

          return mode === 'continuous' ?
            <TickLabel key={value}
              $justify={justify}>
              {content}
            </TickLabel> :
            <DiscreteTickLabel
              $justify={justify}
              key={value}>
              {content}
            </DiscreteTickLabel>;
        })
    }

  </RangeTicks>;
};
