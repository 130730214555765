
import { motion } from 'framer-motion';
import AnimateWidth from 'components/common/AnimateWidth';
import DeleteIcon from '@mui/icons-material/Close';
import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import useStopPropagation from 'utils/hooks/useStopPropagation';
import type { CSSProperties } from 'react';

const chipVariants = {
  closed: {
    opacity: 0,
    width: 0,
  },
  open: {
    opacity: 1,
    width: 'auto',
  },
};

const Label = styled.span`
  padding-bottom: 0.1rem;
`;

const ChipContainer = styled$(motion.div)<{
  $highlighted?: boolean;
}>(({
  theme,
  $highlighted,
}) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing(0.5)};
  color: ${$highlighted ? 'rgba(255,255,255,0.7)' : theme.palette.primary.light};
  border-color: ${$highlighted ? 'rgba(255,255,255,0.7)' : theme.palette.primary.light};
  border-radius: 1rem;
  padding: 0.1rem 0.1rem 0.1rem 0.5rem;
  border-style: solid;
  border-width: 1px;
  font-size: 0.75rem;
  height: 2rem;
`);

interface LabelChipProps {
  editing?: boolean;
  label: string;
  layoutIdPrefix: string;
  highlighted?: boolean;
  onDelete?: (label: string) => void;
}

const iconStyle: CSSProperties = {
  paddingTop: '0.1rem',
  overflow: 'hidden',
};

export const LabelChip = ({
  editing,
  highlighted,
  label,
  layoutIdPrefix,
  onDelete,
}: LabelChipProps) => {
  const stopPropagation = useStopPropagation();

  const handleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onDelete?.(label);
  }, [label, onDelete,]);

  const hoverStyle = useMemo(() => editing ? {
    color: 'rgba(255, 255, 255, 0.8)',
  } : undefined, [editing,]);

  return <ChipContainer
    $highlighted={highlighted}
    variants={chipVariants}
    initial="closed"
    animate="open"
    exit="closed"
    layoutId={`${layoutIdPrefix}_${label}`}
    whileHover={hoverStyle}
    onClick={stopPropagation}
  >
    <Label>{label}</Label>
    <AnimateWidth visible={!!editing}>
      <DeleteIcon
        onClick={editing ? handleClick : undefined}
        style={iconStyle} />
    </AnimateWidth>
  </ChipContainer>;
};
