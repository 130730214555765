/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable require-jsdoc */
import type { MentionSuggestion } from '@mindhiveoy/react-text-editor/plugins/MentionsPlugin';
import type { ProjectMemberInfo } from '@shared/schema/src';

/**
 * A singleton class that holds the list of mentions for the current project.
 */
class MentionLookupService {
  private static instance: MentionLookupService;
  private mentionsList: MentionSuggestion[] = [];

  private constructor() {
    if (MentionLookupService.instance) {
      return MentionLookupService.instance;
    }
    MentionLookupService.instance = this;
    return this;
  }

  static getInstance(): MentionLookupService {
    if (!MentionLookupService.instance) {
      MentionLookupService.instance = new MentionLookupService();
    }
    return MentionLookupService.instance;
  }

  private setMentionsList(mentionsList: MentionSuggestion[]) {
    this.mentionsList = mentionsList;
  }

  public setMentionsListFromProjectMemberInfo(projectMemberInfo: ProjectMemberInfo[]) {
    const mentionsList: MentionSuggestion[] = [];
    projectMemberInfo.forEach((member) => {
      mentionsList.push({
        name: member.nick,
        uid: member.id,
        imageUrl: member.avatar!,
      });
    });
    this.setMentionsList(mentionsList);
  }

  public searchMentions(mentionString: string | null): MentionSuggestion[] {
    const instance = MentionLookupService.getInstance();
    return instance.mentionsList.filter((mention) =>
      mentionString || mentionString === '' ?
        mention.name.toLowerCase()?.includes(mentionString?.toLowerCase()) :
        false
    );
  }
}

export default MentionLookupService;
