/* eslint-disable @typescript-eslint/no-explicit-any */
import { COLOR_PALETTE } from 'components/builder/propertyEditors/lookAndFeel/ColorPickerEditor';
import { statCharacteristicConfig } from 'components/builder/propertyEditors/stats/StatCharacteristicEditor';
import isNumber from 'lodash/isNumber';
import type { CanvasType, StatCharacteristics, Statistics } from '@shared/schema/src';

interface StatValue {
  color: string;
  asText: string;
  label: string;
  type: CanvasType;
  value: any;
}
interface Args {
  data: Statistics;
  characteristics: StatCharacteristics;
}

export const legendColorMap: Record<keyof StatCharacteristics, string> = {
  mean: COLOR_PALETTE[0],
  average: COLOR_PALETTE[1],
  q1: COLOR_PALETTE[2],
  median: COLOR_PALETTE[4],
  q3: COLOR_PALETTE[3],
  stdDeviation: COLOR_PALETTE[5],
  variance: COLOR_PALETTE[8],
};

const objectMapKeys = Object.keys(legendColorMap);

const objectKeysIndexes = objectMapKeys.reduce((previous, key, index) => ({
  ...previous,
  [key]: index,
}), {});

/**
 * Aggregate statistics to array based on statistics data and characteristics set to be seen.
 *
 * @param {Args}  args
 * @param {string[]} skipCharacteristics - Characteristics to skip from the return value
 * @return {StatValue[] | null}
 */
export const aggregateStats = ({
  data,
  characteristics,
}: Args, skipCharacteristics: string[] = []): StatValue[] | null => {
  if (!data || !characteristics) {
    return null;
  }

  // Only keys that are active and not in skipCharacteristics
  const keys = Object
    .entries(characteristics)
    .filter(([key, value,]) => !!value && !skipCharacteristics.includes(key))
    .map(([key,]) => key);

  // Sort keys by order of appearance in colorMap
  keys.sort((a, b) => {
    const aIndex = (objectKeysIndexes as any)[a];
    const bIndex = (objectKeysIndexes as any)[b];
    return aIndex - bIndex;
  });

  switch (data.type) {
    case '1d':
      return keys.map((key) => {
        const x = (data as any)[key];
        let asText = '(-)';
        if (isNumber(x)) {
          asText = x.toFixed(2);
        }
        const label = (statCharacteristicConfig as any)[key]?.displayName;
        return {
          color: (legendColorMap as any)[key] ?? 'blue',
          asText,
          label,
          type: '1d',
          value: x,
        };
      });
    case '2d':
      return keys.map((key) => {
        const x = (data as any)[`${key}X`];
        const y = (data as any)[`${key}Y`];
        let asText = '(-)';
        if (isNumber(x) && isNumber(y)) {
          asText = `(${x.toFixed(2)}, ${y.toFixed(2)})`;
        }
        const label = (statCharacteristicConfig as any)[key]?.displayName;
        return {
          color: (legendColorMap as any)[key] ?? 'blue',
          asText,
          label,
          type: '2d',
          value: {
            x, y,
          },
        };
      });

    default:
      throw new Error(`Unknown statistics type:${data['type']}`);
  }
};
