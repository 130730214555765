import type { RenderHTMLProps } from './ReadMore';

export const RenderHTML = ({
  html, contentStyles,
}: RenderHTMLProps): JSX.Element => {
  return <span style={contentStyles}
    dangerouslySetInnerHTML={{
      __html: html,
    }}>
  </span>;
};
