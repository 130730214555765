
export interface DocumentPathDescriptor {
  collectionPath: string;
  docId: string;
}
/**
 * Normalize and validate document database path.
 *
 * @param {string} path The path to be normalized.
 * @return {string} Normalized presentation of the document path
 * @throw {Error} If the path is not valid Firestore document path.
 */
const normalizeDatabasePath = (path: string): string => {
  if (!path) {
    throw new Error(`Path is empty, can not be normalized.`);
  }

  if (path.indexOf('//') >= 0) {
    throw new Error(`Invalid firestore path, contain double slashed: ${path}.`);
  }

  const elements = path.split('/');
  if (!elements[elements.length - 1]) {
    if (elements.length % 2 === 0) {
      throw new Error(`Invalid firestore path, document path can not end to a slash.`);
    }
    elements.pop();
  }
  return elements
    .reduce((previous, current) => previous ? `${previous}/${current}` : current);
};

export default normalizeDatabasePath;
