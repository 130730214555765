import {
  Button, DialogActions, ToggleButton, ToggleButtonGroup, Typography
} from '@mui/material';
import { PexelVideo } from '../PexelVideo';
import { UnsplashImage } from '../UnsplashImage';
import { searchImages, searchVideos } from '../searchAPI';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import GeneralDialog from 'components/info/GeneralDialog';
import Masonry from '@mui/lab/Masonry';
import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import usePathParams from 'utils/hooks/usePathParams';
import type { Media } from '@shared/schema/src';
import type { UnsplashPhotoResponse } from '@shared/schema/src/https/media/unsplashTypes';
import type { Video } from '@shared/schema/src/https/media/pexelTypes';

interface MediaContentDialogProps {
  open: boolean;
  mode?: 'mixed' | 'photo' | 'video'
  // TODO: Return media instead of UnsplashPhotoResponse
  onSelect?: (item: Partial<Media>) => void;
  onCancel?: () => void;
}
export const MediaContentDialogDialog = ({
  open,
  mode = 'mixed',
  onSelect,
  onCancel,
}: MediaContentDialogProps) => {
  const theme = useTheme();

  const {
    t,
  } = useTranslation();

  const {
    spaceId, projectId,
  } = usePathParams();

  const page = useRef(0);
  const totalPages = useRef(0);

  const loading = useRef(false);
  const currentKeys = useRef<string[]>([]);

  const [searchMode, setSearchMode,] = useState<'photo' | 'video'>(mode === 'mixed' ? 'photo' : mode);

  const [photos, setPhotos,] = useState<UnsplashPhotoResponse[]>([]);
  const [videos, setVideos,] = useState<Video[]>([]);

  const handleMediaSelect = useCallback((media: Partial<Media>) => {
    setPhotos([]);
    setVideos([]);
    onSelect?.(media);
  }, [onSelect,]);

  const fetch = useCallback(async (keys: string[], mode: 'photo' | 'video') => {
    switch (mode) {
      case 'photo':
        searchImages({
          keys,
          spaceId,
          projectId,
        }, (results) => {
          totalPages.current = results.total_pages;
          page.current = 0;
          loading.current = false;
          setPhotos(results.results);
        });
        break;

      case 'video':
        searchVideos({
          keys,
          spaceId,
          projectId,
        }, (results) => {
          totalPages.current = results.total_results;
          page.current = 0;
          loading.current = false;
          setVideos(results.videos);
        });
        break;

      default:
        break;
    }
  }, [projectId, spaceId,]);

  const handleChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    if (keyword.length < 3) {
      return;
    }
    loading.current = true;
    page.current = 0;

    const keys = keyword.trim().split(' ');
    currentKeys.current = keys;

    fetch(keys, searchMode);
  }, [fetch, searchMode,]);

  const masonryRef = useRef<HTMLDivElement>(null);

  // listen masonryRef scroll event and trigger load more if scroll reach bottom
  useEffect(() => {
    const masonry = masonryRef.current;
    if (!masonry) {
      return;
    }

    const handleScroll = () => {
      if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
        if (loading.current) {
          return;
        }
        loading.current = true;

        page.current += 1;

        switch (searchMode) {
          case 'photo':
            searchImages({
              keys: currentKeys.current,
              spaceId,
              projectId,
              page: page.current,
            }, (photos) => {
              loading.current = false;
              setPhotos((current) => current ? [...current, ...photos.results,] : photos.results);
            });
            break;

          case 'video':
            searchVideos({
              keys: currentKeys.current,
              spaceId,
              projectId,
              page: page.current,
            }, (result) => {
              loading.current = false;
              setVideos((current) => current ? [...current, ...result.videos,] : result.videos);
            });
            break;

          default:
            break;
        }
      }
    };

    masonry.addEventListener('scroll', handleScroll);
    return () => {
      masonry.removeEventListener('scroll', handleScroll);
    };
  }, [masonryRef, projectId, searchMode, spaceId,]);

  const handleCloseClick = useCallback(() => {
    onCancel?.();
  }, [onCancel,]);

  const handleChangeSearchMode = useCallback((event: React.MouseEvent<HTMLElement>, value: string) => {
    setSearchMode(value as 'photo' | 'video');
    if (currentKeys.current.length > 0) {
      fetch(currentKeys.current, value as 'photo' | 'video');
    }
  }, [fetch,]);

  const title = useMemo(() => {
    switch (mode) {
      case 'mixed':
        return t('Search for a media content');

      case 'photo':
        return t('Search for a photos');

      case 'video':
        return t('Search for a videos');
    }
  }, [mode, t,]);

  return <GeneralDialog
    open={open}
    size='full'
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100dvh',
        alignItems: 'space-between',
        padding: 32,
        overflow: 'hidden',
      }}
    >
      <div style={{
        flex: 0,
        width: '100%',
      }}>
        <Typography variant='h3'>
          {title}
        </Typography>

        <Stack direction="row"
          alignItems={'center'}
          spacing={2}>
          {mode === 'mixed' ? <ToggleButtonGroup
            value={searchMode}
            exclusive
            onChange={handleChangeSearchMode}
            aria-label="Search mode"
          >
            {
              // TODO: Find out, why the height of the buttons is not 3.5rem
            }
            <ToggleButton value="photo"
              style={{
                marginTop: -3,
                paddingTop: 0,
                paddingBottom: 0,
                height: '3.5rem',
              }}
              aria-label={t('Search photos')}>
              Photos
            </ToggleButton>
            <ToggleButton value="video"
              style={{
                marginTop: -3,
                paddingTop: 0,
                paddingBottom: 0,
                height: '3.5rem',
              }}
              aria-label={t('Search videos')}>
              Videos
            </ToggleButton>
          </ToggleButtonGroup> : null}

          <TextField
            fullWidth
            placeholder='keywords...'
            onChange={handleChange} />
        </Stack>
      </div>
      <div
        ref={masonryRef}
        style={{
          flexGrow: 1,
          overflowY: 'auto',
        }}>
        {searchMode === 'photo' && photos.length === 0 || searchMode === 'video' && videos.length === 0 ?
          <Typography variant='h4'
            style={{
              textAlign: 'center',
              marginTop: theme.spacing(4),
            }}>
            {t('Please, type keywords to search for media content.')}
          </Typography> : null}
        <Masonry
          columns={4}
          spacing={3}

        >
          {searchMode === 'photo' ? photos.map((item) => <UnsplashImage
            item={item}
            key={item.id}
            onClick={handleMediaSelect} />
          ) :
            videos.map((item, index) => <PexelVideo item={item}
              onClick={handleMediaSelect}
              key={index} />)
          }
        </Masonry>
      </div>
      <DialogActions style={{
        paddingTop: theme.spacing(2),
      }}>
        <Button onClick={handleCloseClick}>{t('Cancel')}</Button>
      </DialogActions>
    </div>
  </GeneralDialog >;
};
