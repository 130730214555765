/* eslint-disable space-before-function-paren */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable valid-jsdoc */
/* eslint-disable require-jsdoc */
import { CommentBloc } from '../../../bloc/comment/CommentBloc';
import { CommentTimer } from './CommentTimer';
import { LinkPreview } from './LinkPreview';
import { MessageScope } from '../../../@shared/schema/src';
import { Owned, useAuth } from '@mindhiveoy/react-auth';
import { Show } from '../../../components/auth/Show';
import { commentVariants } from '../../../utils/variants';
import { extractDateToMilliseconds, renderMoment } from '../../../utils/timeUtils';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Avatar from '@mui/material/Avatar';
import CanvasMiniatureElement from '../../../components/canvas/CanvasMiniatureElement';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import React, { useCallback, useState } from 'react';
import StatusChip from '../../../components/info/StatusChip';
import type { Canvas, Comment, WithBlocSystemFields } from '../../../@shared/schema/src';

import { Button, Skeleton } from '@mui/material';
import { CommentContentRow } from './CommentContentRow';
import { CommentCount } from './CommentCount';
import { CommentReplyBadge } from './CommentReplyBadge';
import { LabelRow } from './LabelRow';
import { PresetEditorPlugin, defaultPresetPlugins } from '@mindhiveoy/react-text-editor/TextEditor';
import { Voting } from './Voting';
import { convertCommentNodes } from '../utils/commentNodeUtils';
import { flattenArray } from '@mindhiveoy/react-text-editor/utils';
import { hasEditMode } from 'utils/urls';
import { useMessages } from 'components/messages/MessageContext';
import { useTranslation } from 'next-i18next';
import AnimateWidth from 'components/common/AnimateWidth';
import CloseIcon from '@mui/icons-material/Close';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import EmailIcon from '@mui/icons-material/Email';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Paper from '@mui/material/Paper';
import SellIcon from '@mui/icons-material/Sell';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled';
import styled$ from '../../../utils/react/styled$';
import theme, { chipColors } from '../../../theme';
import usePathParams from 'utils/hooks/usePathParams';
import type { SerializedLexicalNode } from 'lexical';
import type { WithId } from '@mindhiveoy/schema';

const InfoRow = styled.div(({
  theme,
}) => `
  display: flex;
  gap: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(0.5)};
  @media(max-width: 600px) {
    min-height: 80px;
  }
`);

const UserInfo = styled.div(({
  theme,
}) => `
  display: inline-grid; 
  width: 100%;
  gap: ${theme.spacing(1)};
  grid-template-columns: min-content min-content auto min-content min-content;
  grid-auto-columns: minmax(min-content, max-content);
  grid-auto-flow: dense;

  @media(max-width: 700px) {
    grid-template-columns: auto min-content;

    // nick
    div::nth-of-type(1) { 
      grid-column: 1;
      grid-row: 1 / 1;
      text-align: left;
    }

    // time element
    span { 
      grid-column: 1;
      grid-row: 3 / 3;
      text-align: left;
    }

    // facilitator badge
    div:nth-of-type(2) {
      grid-column: 1;
      grid-row: 2 / 2;
      text-align: left;
    }
  }
`);

const LeftRowElement = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing(1)};

  @media(max-width: 600px) {
    align-items: flex-start;
  }
`);

const MiddleRowElement = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing(1)};
  @media(max-width: 600px) {
    align-items: flex-start;
  }
`);

// const FeaturedNotSelected = styled(StarBorder)`
//   margin-left: auto;
//   cursor: pointer;
//   &:hover {
//     filter: drop-shadow(0 0 0.01rem #d1d1d1);
//   }
// `;

// const FeaturedSelected = styled(Star)`
//   margin-left: auto;
//   fill: #eeac04;
//   cursor: pointer;
//   &:hover {
//     filter: drop-shadow(0 0 0.02rem #d1d1d1);
//   }
// `;

const ActionRow = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(1, 0, 0, 0)};
`);

export const ContentContainer = styled.div`
  z-index: 0;
`;

const Container = styled$(motion.li)<{
  $isParent?: boolean,
  $isFirstLevel?: boolean,
}>(({
  theme,
  $isParent,
  $isFirstLevel = false,
}) => {
  const marginLeft = $isParent || $isFirstLevel ? theme.spacing(0) : theme.spacing(10);
  const marginLeftMobile = $isParent || $isFirstLevel ? theme.spacing(0) : theme.spacing(4);

  return `
    border-radius: ${theme.shape.borderRadius}px;
    margin-left: ${marginLeft};
    background-color: ${$isParent && !$isFirstLevel ? theme.palette.background.innerPaper : theme.palette.background.comment};
    padding: ${theme.spacing(2)};
    gap: ${theme.spacing(0.5)};
    list-style-type: none;
    width: calc(100% - ${marginLeft});
    position: relative;
    box-sizing: border-box;
    transition: height 0.3s ease-in-out;  
    @media (max-width: 600px) {
      margin-bottom: 0;
      margin-left: ${marginLeftMobile};
      width: calc(100% - ${marginLeftMobile});
    }
  `;
});

const EditingActionsButton = styled(Button)(({
  theme,
}) => `
  padding: ${theme.spacing(0.5)};
  border-radius:12px;
`);

export const IconContainer = styled$.div<{
  $withParentGap?: boolean;
}>(({
  theme,
  $withParentGap,
}) => `
  display: flex;
  align-items: center;
  ${$withParentGap ? 'gap: inherit;' : `gap: ${theme.spacing(1)};`}
`);

// const StyledChatBubbleIcon = styled(ChatBubbleOutlineIcon)(({
//   theme,
// }) => `
//   margin-left: ${theme.spacing(2)};
//   cursor: pointer;
//   &:hover {
//     filter: drop-shadow(0 0 0.01rem #d1d1d1);
//   }
// `);

export const EditorContainer = styled.div(({
  theme,
}) => `
  background-color: rgba(255,255,255,0.1);
  margin-left: -4px;
  margin-right: 4px;
  margin-top: calc(${theme.spacing(2)} - 4px);
  margin-bottom: -4px;
  padding: 4px;
  border-radius: 4px;
  transition: all 1s ease-in-out;
`);

export const EditorArea = styled.div`
  padding: 0 0px;
`;

export const Nick = styled$.span<{
  $isMobile?: boolean;
}>(({
  $isMobile,
}) => `
  ${$isMobile && 'font-size: 0.9rem;'};
  white-space: nowrap;
`);

export const StyledAvatar = styled(Avatar)`
  display: inline-flex;
  background-color: #cccccc;
  width: 44px;
  height: 44px;

  @media(max-width: 600px) {
    width: 49px;
    height: 49px; 
  }
`;

export const labelBadgeStyles = {
  marginRight: '6px',
  color: theme.palette.primary.main,
  backgroundColor: '#55595b40',
  border: '1px solid #5bc2fa',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 200,
  padding: '15px 2px',
  height: 32,
};

export const panelistBadgeStyles = {
  maxHeight: '22px',
  marginLeft: 0,
  backgroundColor: 'transparent',
  color: chipColors.panelist.color,
};

const DateText = styled$.span<{
  $isMobile?: boolean;
}>(({
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  theme,
  $isMobile,
}) => `
  font-size: ${$isMobile ? 12 : 14}px;
  display: flex;
  align-items: center;
`);

export const CommentIconButton = styled(IconButton)`
  padding: 0;
`;

export const CountText = styled.span(({
  theme,
}) => `
  font-size: 12px;
  margin-left: ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};
`);

export const LabelContainer = styled(motion.div)(({
  theme,
}) => `
  margin-top: ${theme.spacing(1)};
`);

export const LabelPaper = styled(Paper)(({
  theme,
}) => `
  background-color: ${theme.palette.background.default}FA;
  border: 1px solid ${theme.palette.background.comment}FA;
`);

export const SuggestionsPaper = styled(Paper)(({
  theme,
}) => `
  margin-top: 0 !important;
  border: 1px solid ${theme.palette.background.comment}FA;
  .MuiAutocomplete-listbox {
    padding: 0;
    .MuiAutocomplete-option {
      background-color:${theme.palette.background.paperDropdown};
    }
    .MuiAutocomplete-option.Mui-focused {
      background-color: ${theme.palette.background.paper};
      .MuiChip-root {
        border: 1px solid ${theme.palette.primary.main};
      }
     .MuiChip-label {
      color: ${theme.palette.primary.main};
     }
    }
  }
`);

export const LabelTextfield = styled(TextField)(({
  theme,
}) => `
  padding-top: ${theme.spacing(4)};
  .MuiInputLabel-root {
    top: 0;
    left: ${theme.spacing(1.5)};
    font-size: 1.2rem;
  }
  & .MuiInputBase-root {
    margin: 0;
    padding: ${theme.spacing(1)};
  }
`);

export const LabelText = styled.div(({
  theme,
}) => `
  margin: ${theme.spacing(1, 0, 0, 2)};
  font-size: 12px;
  color: ${theme.palette.primary.main};
`);

export const Suggestions = styled.li(({
  theme,
}) => `
  background-color: ${theme.palette.background.graph};
  color: black
`);

export const commentEditorPlugins = defaultPresetPlugins.filter((plugin) => plugin !== PresetEditorPlugin.TOOLBAR);

export interface CommentItemProps {
  canvas?: WithId<Canvas>;
  comment: WithId<Comment>;
  featured?: boolean;
  showFeaturedIcon?: boolean;
  showMessageIcon?: boolean;
  // replies?: number;
  hideComments?: boolean;
  isMobile: boolean;
  voting?: boolean;
  showAnswer: boolean;
  disabled?: boolean;
  noLayout?: boolean;

  animateHeight?: boolean;
  layoutIdPrefix?: string;
  firstLevel?: boolean;
  /**
   * Indicates that the item is showing the parent comment
   * which will follow the replies. So reply -button is not needed.
   *
   */
  parent?: boolean;
  transitionDirection?: number;
  onClick?: (comment: WithId<Comment>) => void;
  onCloseClick?: (comment: WithId<Comment>) => void;
  onCommentAnimationCompleted?: (commentId: string) => void;
  onEditClick?: (comment: WithId<Comment>) => void;
  onFeaturedClick?: (comment: WithId<Comment>) => void;
  onMessageClick?: (comment: WithId<Comment>) => void;
  onReplyClick?: (comment: WithId<Comment>) => void;
  onShowRepliesOnClick?: (comment: WithId<Comment>) => void;
}

const CommentItem = (props: Omit<CommentItemProps, 'comment'> & {
  comment: WithId<Comment> | undefined,
}) => {
  if (!props.comment) {
    // TODO: Better skeleton
    return <Skeleton variant="rounded"
      width="100%"
      height={100}
    />;
  }
  return <CommentItemInstance {...props as any} />;
};

const CommentItemInstance = ({
  comment,
  hideComments = false,
  isMobile,
  layoutIdPrefix,
  parent,
  firstLevel,
  showAnswer,
  transitionDirection,
  voting,
  onClick,
  onCloseClick,
  onCommentAnimationCompleted,
  onMessageClick,
  onReplyClick,
  onShowRepliesOnClick,
}: CommentItemProps) => {
  const pathParams = usePathParams();

  const {
    query,
  } = useRouter();

  const {
    t,
  } = useTranslation();

  const isOptimistic = !!(comment as unknown as WithBlocSystemFields)?._isOptimistic;

  const {
    selectedComment,
    openMessageWindowForScope,
  } = useMessages();

  const [timer, setTimer,] = useState<CommentTimer>();
  const [editingText, setEditingText,] = useState<boolean>(false);
  const [editingLabels, setEditingLabels,] = useState<boolean>(false);
  // TODO: Study this, does it really make any sense to have two states for the same thing?
  const [editorHtmlContent, setEditorHtmlContent,] = useState<string>(comment?.text || '');
  const [editorJSONContent, setEditorJSONContent,] = useState<SerializedLexicalNode | undefined>(undefined);

  const [creationDateString, setCreationDateString,] = useState(
    renderMoment(extractDateToMilliseconds(comment?.created)));

  const {
    memberId,
    user,
    role,
  } = useAuth();

  const handleVoteUp = useCallback(async () => {
    const id = memberId ?? user?.uid;
    if (!id) {
      return;
    }
    try {
      setEditingLabels(false);
      const commentBloc = new CommentBloc(
        pathParams,
        comment._id
      );
      await commentBloc.toggleVote(id, 'up');
    } catch (e) {
      console.error(e);
    }
  }, [comment._id, memberId, pathParams, user?.uid,]);

  const handleVoteDown = useCallback(async () => {
    const id = memberId ?? user?.uid;
    if (!id) {
      return;
    }
    try {
      setEditingLabels(false);
      const commentBloc = new CommentBloc(
        pathParams,
        comment._id
      );
      await commentBloc.toggleVote(id, 'down');
    } catch (e) {
      console.error(e);
    }
  }, [comment._id, memberId, pathParams, user?.uid,]);

  const handleReplyWithFocusClick = useCallback((event: React.MouseEvent<any, MouseEvent>) => {
    setEditingLabels(false);
    onReplyClick?.(comment);
    event.stopPropagation();
    event.preventDefault();
  }, [comment, onReplyClick,]);

  const handleShowRepliesClick = useCallback((event: React.MouseEvent<any, MouseEvent>) => {
    setEditingLabels(false);
    onShowRepliesOnClick?.(comment);
    event.stopPropagation();
    event.preventDefault();
  }, [comment, onShowRepliesOnClick,]);

  const handleEditComment: React.MouseEventHandler<HTMLButtonElement> = useCallback(async (event) => {
    setEditingLabels(false);
    setEditingText(true);
    event.stopPropagation();
  }, []);

  const saveComment = useCallback(async (htmlString: string, json: SerializedLexicalNode) => {
    setEditingText(false);
    try {
      const flatJsonContent = flattenArray([json,]);
      const analyzedComment = convertCommentNodes(flatJsonContent);
      const editedComment: any = {
        text: htmlString,
        hashTags: analyzedComment.hashTags,
        links: analyzedComment.links,
      };
      const newComment = {
        ...comment,
        text: htmlString,
      };
      const hasFirstLinkInfoUrl = !!newComment.firstLinkInfo?.url;
      // find new mentions based on mention.nick, not the whole mention object
      const newMentions = analyzedComment.mentions.filter((mention) => !newComment.mentions?.find((m) => m.nick === mention.nick));
      editedComment.mentions = [...newComment.mentions || [], ...newMentions,];

      const commentBloc = new CommentBloc(
        pathParams,
        newComment._id
      );

      await commentBloc.updateComment(
        hasFirstLinkInfoUrl ? {
          ...newComment,
          ...editedComment,
          firstLinkInfo: {
            ...comment.firstLinkInfo,
            showPreview: true,
          },
        } : {
          ...newComment,
          ...editedComment,
        });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [comment, pathParams,]);

  const handleSaveEditedComment: React.MouseEventHandler<HTMLButtonElement> = useCallback(async (event) => {
    try {
      saveComment(editorHtmlContent, editorJSONContent!);
    } catch (e) {
      console.error(e);
    }
    event.stopPropagation();
  }, [saveComment, editorHtmlContent, editorJSONContent,]);

  const cancelCommentEditing = useCallback((event: React.MouseEvent) => {
    setEditingText(false);
    event.stopPropagation();
  }, []);

  // const handleShowRepliesClick = useCallback(() => {
  //   setEditingLabels(false);
  //   onShowRepliesOnClick && onShowRepliesOnClick();
  // }, [onShowRepliesOnClick,]);

  // const handleFeaturedClick = useCallback(() => {
  //   onFeaturedClick && onFeaturedClick(comment);
  //   commentBloc.feature(user?.uid, memberId, !featured, comment);
  // }, [comment, commentBloc, featured, memberId, onFeaturedClick, user?.uid,]);

  const handleCloseClick = useCallback((event: React.MouseEvent) => {
    onCloseClick && onCloseClick(comment);

    event.stopPropagation();
    event.preventDefault();
  }, [comment, onCloseClick,]);

  const onMomentRender = useCallback(() => {
    setCreationDateString(renderMoment(extractDateToMilliseconds(comment?.created)));
  }, [comment?.created,]);

  useEffect(() => {
    const timer = new CommentTimer();
    setTimer(timer);
    return () => timer.dispose();
  }, []);

  useEffect(() => {
    if (!timer) {
      return;
    }
    const when = extractDateToMilliseconds(comment?.created);
    timer.start(when, onMomentRender);
  }, [comment?.created, onMomentRender, timer,]);

  const handleCommentAnimationComplete = useCallback(() => {
    onCommentAnimationCompleted && onCommentAnimationCompleted(comment._id);
  }, [comment?._id, onCommentAnimationCompleted,]);

  const handleLinkPreviewRemove = useCallback(async () => {
    try {
      const commentBloc = new CommentBloc(
        pathParams,
        comment._id
      );
      commentBloc.updateComment({
        firstLinkInfo: {
          ...comment.firstLinkInfo,
          showPreview: false,
        } as any,
      });
    } catch (e) {
      console.error(e); // TODO: show error to user
    }
  }, [comment._id, comment.firstLinkInfo, pathParams,]);

  const handleLabelEditorToggle = useCallback((event: React.MouseEvent) => {
    setEditingLabels((value) => {
      const result = !value;
      if (result) {
        setEditingText(false);
      }
      return result;
    });
    event.stopPropagation();
  }, []);

  const handleLabelEditorClose = useCallback(() => {
    setEditingLabels(false);
  }, []);

  const handleMessageClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setEditingLabels(false);
    onMessageClick?.(comment);
    openMessageWindowForScope?.(MessageScope.DIRECT, comment);
  }, [comment, onMessageClick, openMessageWindowForScope,]);

  const handleCommentClick = useCallback((event: React.MouseEvent) => {
    onClick?.(comment);
    event.stopPropagation();
  }, [comment, onClick,]);

  if (!comment?._id) {
    return null;
  }

  return <Container
    data-test-id='comment-item-container'
    $isParent={parent}
    $isFirstLevel={firstLevel}
    id={comment._id}
    layoutRoot
    variants={commentVariants}
    custom={transitionDirection}
    onClick={handleCommentClick}
    initial="hidden"
    animate="show"
    exit="exit"
    onAnimationComplete={handleCommentAnimationComplete}
  >
    {/* {comment.creatorId ? */}
    <Owned byMember={comment.creatorId}>
      <InfoRow key="info" >
        <StyledAvatar
          src={comment?.avatar}
          alt={comment?.nick} />
        <UserInfo>
          <LeftRowElement>
            <Nick $isMobile={isMobile}>@{comment?.nick}</Nick>
          </LeftRowElement>

          <MiddleRowElement>
            {comment.role === 'f' ?
              <StatusChip showOnMobile
                status='facilitator' /> :
              null
            }
          </MiddleRowElement>

          <DateText $isMobile={isMobile}
            aria-label={t('time-since-commenting')}>
            {creationDateString}
          </DateText>
        </UserInfo>

        {showAnswer ?
          <CanvasMiniatureElement
            answer={comment.answer!} /> : null
        }

        {onCloseClick ?
          <IconButton onClick={handleCloseClick}>
            <CloseIcon />
          </IconButton> : null}
      </InfoRow>

      {/* Comment content */}
      <CommentContentRow
        key="content"
        comment={comment}
        editing={editingText}
        onEditorHtmlChange={setEditorHtmlContent}
        onEditorJSONChange={setEditorJSONContent}
        onSubmitHtml={saveComment as any}
      />

      <ActionRow key="action" >
        {/* Icons */}
        {voting && !isOptimistic ?
          <Voting likes={comment?.likes as any}
            disabled={isOptimistic}
            onVoteDown={handleVoteDown}
            onVoteUp={handleVoteUp}
          /> : null}

        {/* Comment actions */}
        <Show forOwner>
          <AnimateWidth visible={!editingText}>
            <IconContainer>
              <IconButton disabled={isOptimistic}
                onClick={handleEditComment}>
                <EditIcon fontSize='small' />
              </IconButton>
            </IconContainer>
          </AnimateWidth>
          <AnimateWidth visible={editingText}>
            <IconContainer>
              <EditingActionsButton
                variant='text'
                color='inherit'
                onClick={cancelCommentEditing}>
                {t('Cancel')}
              </EditingActionsButton>
              <EditingActionsButton
                variant='outlined'
                color='secondary'
                onClick={handleSaveEditedComment}
              >
                {t('Save')}
              </EditingActionsButton>
            </IconContainer>
          </AnimateWidth>
        </Show>

        {/* Label icon */}
        <IconContainer>
          {role == 'f' &&
            <IconButton disabled={isOptimistic}
              onClick={handleLabelEditorToggle}>
              <SellIcon fontSize='small' />
            </IconButton>
          }
        </IconContainer>

        {/* Message icon */}
        {role === 'f' && hasEditMode(query) &&
          <IconContainer>
            <IconButton disabled={isOptimistic}
              onClick={handleMessageClick}>
              {selectedComment?._id !== undefined && selectedComment?._id === comment._id ?
                <EmailIcon fontSize='small' /> :
                <MailOutlineIcon fontSize='small' />
              }
            </IconButton>
          </IconContainer>}

        {
          onReplyClick ?
            <IconContainer>
              <IconButton disabled={isOptimistic}
                onClick={handleReplyWithFocusClick}>
                <CommentReplyBadge
                  value={comment.replyCount} />
                <CommentOutlinedIcon fontSize='small' />
              </IconButton>
            </IconContainer> : null}

        {/* {showFeaturedIcon && replies && replies > 0 ?
            <StyledCommentBadge badgeContent={replies}
              color='secondary' >
              <StyledChatBubbleIcon fontSize='small'
                onClick={handleShowRepliesClick} />
            </StyledCommentBadge> : null} */}
      </ActionRow>
      {!hideComments ? <CommentCount
        comment={comment}
        onReplyClick={handleShowRepliesClick}
      /> : null}
      <Show withRoles={['facilitator', 'admin', 'system',]}>
        <LabelRow
          key="label"
          layoutIdPrefix={layoutIdPrefix}
          editing={editingLabels}
          comment={comment}
          onLabelEditorClose={handleLabelEditorClose}
        />
      </Show>

      {comment.firstLinkInfo?.showPreview ?
        <LinkPreview
          key="link"
          permissionToRemove={comment.creatorId == memberId}
          onRemoveLinkClick={handleLinkPreviewRemove}
          isMobile={isMobile}
          info={comment.firstLinkInfo} /> : null}
    </Owned>
  </Container>;
};

export default CommentItem;
