import { Item } from './Item';
import { motion } from 'framer-motion';
import { useAuth } from '@mindhiveoy/react-auth';
import { useNotifications } from '../../../bloc/notifications/NotificationsBloc';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Feed from '../Feed';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import theme from 'theme';
import type { NotificationDoc } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

const NotificationsFeed = () => {
  const {
    user,
    role,
  } = useAuth();

  const {
    t,
  } = useTranslation();

  const notifications = useNotifications({
    uid: user?.uid,
  });
  const [items, setItems,] = useState<WithId<NotificationDoc>[]>([]);
  useEffect(() => notifications.subscribe((items) => {
    setItems(!items?.length ? [] : items);
  }), [notifications,]);

  return <Feed >{
    items.length ?
      items.map((item) => <Item
        role={role!}
        key={item._id}
        item={item} />) :
      <>{t('no-notifications-available')}
      </>

  }</Feed>;
};

export const StyleFeedItem = styled(motion.div)(({
  theme,
}) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  margin: ${theme.spacing(1)};
  padding: ${theme.spacing(2)};
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${theme.palette.background.innerPaper};
  cursor: pointer;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${theme.palette.primary.main};
    & .MuiAvatar-root {
      transform: scale(1.05);
    }
  }
`);

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${theme.spacing(1)};
`;

export const MessageArea = styled.div`
  // max-height: 120px;
  white-space: break-spaces;
  wrap: break-;
  text-overflow: ellipsis;
  margin-top: ${theme.spacing(1)};
`;

export const InfoItem = styled.div(({
  theme,
}) => `
  margin-left: ${theme.spacing(1)};
`);

export default NotificationsFeed;
