import { Alert } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { getAuth, sendPasswordResetEmail as sendPasswordResetEmailFunction } from 'firebase/auth';
import { translateErrorCodeToHumanReadableError } from 'utils/localization';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import type { TFunction } from 'next-i18next';

import FormElement from 'components/forms/FormElement';
import React, { useCallback, useState } from 'react';
import SectionTitle from 'components/layout/SectionTitle';
import TextField from '@mui/material/TextField';
import isEmail from 'validator/lib/isEmail';
import router from 'next/router';
import styled from '@emotion/styled';
import useForm, { MessageType } from 'ts-react-form-validator';
import type { FormValidationRules } from 'ts-react-form-validator';

interface ResetPasswordForm {
  email: string;
}

const ResetPasswordContentContainer = styled.div(({
  theme,
}) => `
  width: 100%;
  max-width: 700px;
  min-width: min(500px, 90vw);
  height: 100%;
  padding: ${theme.spacing(0, 2, 2, 2)};
  text-align: center;
`);

const StyledButton = styled(Button)(({
  theme,
}) => `
  width: 100%;
  padding: ${theme.spacing(2)};
  align-self: flex-end;
  align-items: center;
  text-align: center;
  margin-top: ${theme.spacing(2)};
`);

const StyledAlert = styled(Alert)(({
  theme,
}) => `
  justify-content: center;
  margin-top: ${theme.spacing(4)};
`);

interface Form {
  email: string;
}

const getRules = (t: TFunction): FormValidationRules<ResetPasswordForm> => {
  const rules: FormValidationRules<ResetPasswordForm> = {
    fields: {
      email: {
        required: true,
        requiredText: () => t('Required field'),
        validate: (value?: string) => value && isEmail(value) || {
          type: MessageType.ERROR,
          message: t('Email address must be in valid email format (e.g. test@test.fi)'),
        },
      },
    },
    validateForm: (form) => {
      const messages = {};
      return {
        ...form, messages,
      };
    },
  };
  return rules;
};

const emptyForm = (): Form => ({
  email: '',
});

interface ResetPasswordPageComponentProps {
  onBackClick?: () => void;
}

const ResetPasswordPageComponent = ({
  onBackClick,
}: ResetPasswordPageComponentProps) => {
  const {
    t,
  } = useTranslation('common');
  const [loading, setLoading,] = useState<boolean>(false);
  const [alert, setAlert,] = useState<{ severity: 'success' | 'error', message: string; }>();

  const {
    values: {
      email,
    },
    events,
    isFormValid,
    messages,
  } = useForm<Form>(emptyForm(), getRules(t));

  const renderField = (key: keyof ResetPasswordForm, label: string) => {
    return (
      <TextField
        disabled={loading}
        id={key}
        label={label}
        error={!!messages.email}
        helperText={!!messages.email && messages.email.type === MessageType.ERROR ? messages.email.message : ' '}
        {...events}
        variant='outlined'
      />
    );
  };

  const sendPasswordResetEmail = useCallback(async () => {
    try {
      setLoading(true);
      const auth = getAuth();
      await sendPasswordResetEmailFunction(auth, email);
      setAlert({
        message: t('We have sent you an email with instructions how to reset the password'),
        severity: 'success',
      });
    } catch (e: any) {
      console.error('Error occurred when sending password reset email: ', e);
      const humanReadableErrorMessage = translateErrorCodeToHumanReadableError(e.code, t);
      setAlert({
        message: humanReadableErrorMessage, severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [email, t,]);

  const handleBackClick = useCallback((event: React.MouseEvent) => {
    if (onBackClick) {
      onBackClick();
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    router.push('/');
  }, [onBackClick,]);

  return <ResetPasswordContentContainer>
    <SectionTitle>{t('Send a reset email')} </SectionTitle>
    <FormElement field="email">
      {renderField('email', 'Email')}
    </FormElement>
    <StyledButton
      fullWidth
      color='secondary'
      onClick={sendPasswordResetEmail}
      disabled={loading || !isFormValid}
      variant='contained'>
      {loading ? <CircularProgress size={17} /> : t('Send')}
    </StyledButton>
    <StyledButton
      fullWidth
      onClick={handleBackClick}
      variant='outlined'>
      {t('Return')}
    </StyledButton>

    {alert &&
      <StyledAlert severity={alert.severity} >
        {alert.message}
      </StyledAlert>}

  </ResetPasswordContentContainer>;
};

export default ResetPasswordPageComponent;
