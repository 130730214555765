/* eslint-disable @typescript-eslint/no-explicit-any */
import { animate, motion, useMotionValue } from 'framer-motion';
import { itemTransitionStyle } from './CategoryContainer';
import {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { zeroOffset } from './CategoryContainerModel';
import type { CategoryContainerModel, CategoryDescriptor, Offset, WithIdInfo } from './CategoryContainerModel';
import type { MotionStyle } from 'framer-motion';
import type { RefObject } from 'react';

interface RenderCategoryItemFunctionArgs<T extends WithIdInfo> {
  data: T;
  itemId: string;
  categoryId: string;
  isDragged?: boolean;
  draggingTarget?: string;
}

export type RenderCategoryItemFunction<T extends WithIdInfo> =
  (args: RenderCategoryItemFunctionArgs<T>) => JSX.Element

interface DraggableDivProps<C extends CategoryDescriptor = CategoryDescriptor, T extends WithIdInfo = any> {
  model: CategoryContainerModel<C, T>;
  categoryId: string;
  id: string;
  data: T;
  offset: Offset;
  onRender: RenderCategoryItemFunction<T>;
}

const CategoryItem = ({
  id,
  categoryId,
  model,
  data,
  offset = zeroOffset,
  onRender,
}: DraggableDivProps
) => {
  const x = useMotionValue(offset.x);
  const y = useMotionValue(offset.y);

  const ref = useRef<HTMLDivElement>(null);

  const [dragging, setDragging,] = useState(false);

  useEffect(() => {
    model.setItemRef(id, ref);
  }, [categoryId, id, model,]);

  const handleDragStart = useCallback(() => {
    setDragging(true);

    model.startItemDrag(categoryId, id);
  }, [categoryId, id, model,]);

  const handleDragEnd = useCallback(async () => {
    model.endItemDrag();
    const promises = [];

    promises.push(animate(x, 0));
    promises.push(animate(y, 0));

    await Promise.all(promises);
    setDragging(false);
  }, [model, x, y,]);

  const handleDrag = useCallback((event: MouseEvent | TouchEvent | PointerEvent) => {
    const {
      pageX,
      pageY,
    } = event as any;

    // model.onItemDrag(id, rect.x + rect.width / 2, rect.y + rect.height / 2);
    model.onItemDrag(id, pageX, pageY);
  }, [id, model,]);

  useEffect(() => {
    if (dragging) {
      return;
    }
    animate(y, offset.y);
  }, [dragging, offset, x, y,]);

  const content = useMemo(() => {
    if (onRender) {
      return <>{onRender({
        data,
        itemId: id,
        categoryId,
        isDragged: dragging,
        draggingTarget: model.currentDraggingTarget,
      })}</>;
    }
    return 'Please, define the item renderer.';
  }, [categoryId, data, dragging, id, model.currentDraggingTarget, onRender,]);

  const layoutId = useMemo(() => `item_${id}`, [id,]);

  const hoverStyle = useMemo(
    () => ({
      scale: calculateHoverScale(ref),
    }),
    []);

  const handleLayoutComplete = useCallback(() => {
    animate(y, offset.y, {
      duration: 0.5,
    });
  }, [offset.y, y,]);

  const itemStyle: MotionStyle = useMemo(() => ({
    width: '100%',
    opacity: dragging ? 0.5 : 1,
    x,
    y,
    z: dragging ? 100 : 0,
    scale: 1,
    overflow: 'hidden',
  }), [dragging, x, y,]);

  return (
    <motion.div
      ref={ref}
      drag
      layoutId={layoutId}
      layout="position"
      whileHover={hoverStyle}
      style={itemStyle}
      transition={itemTransitionStyle}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDrag={handleDrag}
      onLayoutAnimationComplete={handleLayoutComplete}
    >
      {content}
    </motion.div>
  );
};

export default CategoryItem;

const HOVER_PIXELS = 8;

const calculateHoverScale = (ref: RefObject<HTMLDivElement>) => {
  if (!ref?.current) {
    return 1;
  }
  const {
    clientWidth,
    clientHeight,
  } = ref.current;
  if (clientWidth > clientHeight) {
    return (clientWidth + HOVER_PIXELS) / clientWidth;
  }
  return (clientHeight + HOVER_PIXELS) / clientHeight;
};
