/**
 * Clean change info fields from the value. This is useful when comparing
 * doing deep change comparison for the value.
 *
 * @param value   Value to clean
 * @returns   Cleaned value
 */

export const cleanChangeInfoFields = (value: any) => {
  const result = { ...value };
  delete result.created;
  delete result.creatorId;
  delete result.lastModified;
  delete result.lastModifierId;
  return result;
};
