import * as React from 'react';
import type { MentionTypeaheadOption } from './MentionTypeaheadOption';

type Args = {
  index: number;
  isSelected: boolean;
  option: MentionTypeaheadOption;
  onClick: () => void;
  onMouseEnter: () => void;
};

/**
 * A typeahead menu item for mentions.
 * @param param0
 * @returns
 */
export const MentionsTypeaheadMenuItem = ({
  index,
  isSelected,
  option,
  onClick,
  onMouseEnter,
}: Args) => {
  let className = 'item';
  if (isSelected) {
    className += ' selected';
  }
  return (
    <li
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={`typeahead-item-${index}`}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {option.picture}
      <span className="text">{option.name}</span>
    </li>
  );
};
