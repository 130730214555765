import { platformEventSystem } from 'components/platformEvents/PlatformEventSystem';
import type { AuthContext } from '..';
import type { User as FirebaseUser } from 'firebase/auth';

export const sendPlatformMessageWithFirebaseUserInfo = (context: AuthContext, user: FirebaseUser | null) => {
  const oldUser = context.user;

  if (!oldUser && user || oldUser && !user || oldUser?.uid !== user?.uid) {
    platformEventSystem.sendPlatformEvent({
      type: 'user-auth-changed',
    });
  };
};
