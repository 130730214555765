import { ADMIN_BUNDLE } from '@shared/schema/src/https/bundles';
import { createBundleFunctionFactory } from 'utils/firebase/bundles/createBundleFunctionFactory';
import type { CreateCanvasDraftMessage } from '@shared/schema/src/https/session/createCanvasDraft';
import type { DeleteCanvasDraftMessage } from '@shared/schema/src/https/session/deleteCanvasDraft';
import type { DuplicateCanvasDraftMessage } from '@shared/schema/src/https/session/duplicateCanvasDraft';
import type { SetCanvasOrderDraftMessage } from '@shared/schema/src/https/session/setCanvasOrderDraft';
import type { UpdateCanvasDraftMessage } from '@shared/schema/src/https/session/updateCanvasDraft';

const factory = createBundleFunctionFactory(ADMIN_BUNDLE);

export const createCanvasDraft = factory<CreateCanvasDraftMessage>(
  'create-canvas-draft'
);

export const updateCanvasDraft = factory<UpdateCanvasDraftMessage>(
  'update-canvas-draft'
);

export const deleteCanvasDraft = factory<DeleteCanvasDraftMessage>(
  'delete-canvas-draft'
);

export const setCanvasOrderDraft = factory<SetCanvasOrderDraftMessage>(
  'set-canvas-order-draft'
);

export const duplicateCanvasDraft = factory<DuplicateCanvasDraftMessage>(
  'duplicate-canvas-draft'
);
