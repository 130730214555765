/* eslint-disable sonarjs/no-duplicate-string */
import { SliderContainer, SliderLayout } from '../Matrix2DQuestionCanvas/layouts/SlidersLayout';
import { useAnswer } from '../../../../bloc/answer/AnswerBloc';
import { useAuth } from '@mindhiveoy/react-auth';
import React, { useCallback, useEffect, useState } from 'react';
import ShowAnswer from 'components/canvas/components/ShowAnswer';
import Slider1D from '../../components/Slider1D';
import StatisticLegend from '../../components/StatisticLegend';
import styled$ from '@emotion/styled';
import usePathParams from 'utils/hooks/usePathParams';
import type { Answer, Scale1DAnswer, Session, SliderQuestionCanvas } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export const DEFAULT_GRAPH2D_SIZE: Size = {
  width: 400,
  height: 400,
};

export const Graph = styled$.div(({
  theme,
}) => `
  --side-area: 0.2;
  @media(max-width: 600px) {
    --side-area: 0;
  }
  --grid-area: min(100%, ${theme.shape.maxEditorAreaWidth}px);
  --inner-area: calc(var(--grid-area) * (1 - 2 * var(--side-area)));
  @media only screen and (width: 600px) {
    --grid-area: min(70%, ${theme.shape.maxEditorAreaWidth}px);
  }    
  --grid-size: calc(var(--grid-area) * var(--inner-area));
  --cell-size: calc(var(--grid-area) * var(--side-area));
  position: relative;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: var(--cell-size) var(--inner-area) var(--cell-size);
  grid-template-rows: 1fr auto 1fr;
  box-sizing: content-box;
  grid-template-areas:
    "luc        top-label     ruc"
    "left-label main          right-label"
    "llc        bottom-label  rlc";
  margin-bottom: ${theme.spacing(1)};
`);

export const TopLabel = styled$.div<{ size: Size; }>(({
  size = {
    width: 400,
  },
  theme,
}) => `
  grid-area: top-label;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: ${size.width}px;
  width: ${size.width}px;
  margin-bottom: ${theme.spacing(2)};
  @media(max-width: 600px) {
    margin-bottom: ${theme.spacing(1)};
  }
`);

export const BottomLabel = styled$.div<{ size: Size; }>(({
  size = {
    width: 400,
  },
}) => `
  grid-area: bottom-label;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: ${size.width}px;
  width: ${size.width}px;
`);

export const LeftLabel = styled$.div<{ size: Size; }>(({
  size = {
    height: 400,
  },
}) => `
  grid-area: left-label;
  position: relative;
  display: flex;
  flex-direction: row;
  height: ${size.height}px;
  max-height: ${size.height}px;
`);

export const LeftAxisLabel = styled$.div`
  writing-mode: vertical-lr;
  display: block;
  text-align: center;
  height: 100%;
  transform: rotate(180deg);
`;

export const RightAxisLabel = styled$.div`
  writing-mode: vertical-lr;
  display: block;
  text-align: center;
  height: 100%;
  transform: rotate(180deg);
`;

export const RightLabel = styled$.div<{ size: Size; }>(({
  size = {
    height: 400,
  },
}) => `
  grid-area: right-label;
  position: relative;
  display: flex;
  flex-direction: row;
  height: ${size.height}px;
  max-height: ${size.height}px;
`);

export type EmptyGraphCornerPosition = 'ruc' | 'rlc' | 'llc' | 'luc';

export const GraphCorner = styled$.div`
  display: flex;
  position: absolute;
  min-width: 48px;
  min-height: 48px;
  max-width: 40%;
  max-height: 40%;
`;

export interface Size {
  width: number;
  height: number;
}

export interface SliderQuestionCanvasProps {
  session?: Session;
  canvas: WithId<SliderQuestionCanvas>;
  size?: Size;
  graphOnly?: boolean;
  answer?: Scale1DAnswer;
  graphBackgroundColor?: string;
}

const SliderQuestionCanvasComponent = ({
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  canvas,
}: SliderQuestionCanvasProps) => {
  const {
    user,
    memberId,
  } = useAuth();
  // For anonymous users, we use their current uid:s. We need to think this further when we raise anonymous to identified user
  const mid = user?.isAnonymous ? user.uid : memberId;

  const pathParams = usePathParams({
    canvasId: canvas._id,
  });
  const [answer, setAnswer,] = useState<number>();
  const answerBloc = useAnswer({
    ...pathParams, answerId: memberId,
  });

  useEffect(() => {
    const onSnapshot = (answer?: WithId<Answer, string>) => {
      if (!answer) {
        return;
      }
      setAnswer(answer?.value !== null || answer?.value !== undefined ? answer.value as number : undefined);
    };
    return answerBloc?.subscribe(onSnapshot, (error) => console.error(error));
  }, [answerBloc,]);

  const handleChange = useCallback(async (value: number) => {
    if (value !== null && value !== undefined) {
      try {
        await answerBloc.set(
          mid as string,
          {
            type: 'scale1d',
            memberId: mid as string,
            value,
          }
        );
      } catch (e) {
        console.error('Error setting new answer value: ', e);
      }
    } else {
      setAnswer(value);
    }
  }, [answerBloc, mid,]);

  const handleClearAnswer = useCallback(async () => {
    try {
      await answerBloc.erase();
      setAnswer(undefined);
    } catch (e) {
      console.error('Error clearing answer: ', e);
    }
  }, [answerBloc,]);

  if (!canvas) {
    return null;
  }
  return <>
    <SliderContainer>
      <SliderLayout
        axisLabel={canvas.axis.mainLabel}
        showAxisLabels={canvas.showAxisLabels}
        showMinMaxLabels={canvas.showMinMaxLabels}
      >
        <Slider1D
          value={answer}
          max={canvas?.axis?.max}
          layoutId="slider-x"
          mode={canvas?.mode}
          axis={canvas.axis}
          minLabel={canvas.axis.minLabel}
          maxLabel={canvas.axis.maxLabel}
          showMinMax={canvas.showMinMaxLabels}
          showTicks={canvas.showTickLabels}
          onChange={handleChange}
        />
      </SliderLayout>
    </SliderContainer>

    <ShowAnswer
      hasAnswer={answer !== undefined}
      onClear={handleClearAnswer}
    />

    <div style={{
      width: '100%',
    }}>
      <StatisticLegend
        statistics={canvas.statistics}
        analyzeOptions={canvas.analyzeOptions}
      />
    </div>
  </>;
};

export default SliderQuestionCanvasComponent;
