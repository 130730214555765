/* eslint-disable @typescript-eslint/no-explicit-any */

import type { BuilderComponentPropsBase } from '../BuilderComponentTypes';

import { mapVerticalAlign } from '../editors/mapVerticalAlign';
import { useMemo } from 'react';
import MediaContainer from 'components/layout/MediaContainer';
import ReadMore from 'components/common/ReadMore';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import type { BuilderFunction } from 'components/builder/builders/componentBuilder';
import type { CSSProperties } from 'react';
import type { QuestionDescriptionWidget } from '@shared/schema/src';

const AutoMargin = styled.div`
  margin: auto;
`;

export interface QuestionDescriptionWidgetProps extends BuilderComponentPropsBase<QuestionDescriptionWidget> {
  type: 'question-description';
}

const QuestionDescriptionWidgetComponent: BuilderFunction<QuestionDescriptionWidgetProps, QuestionDescriptionWidget> = ({
  data,
  data: {
    background,
  },
}: QuestionDescriptionWidgetProps) => {
  return background ?
    <MediaContainer
      media={background}>
      <Content data={data} />
    </MediaContainer > :
    <Content data={data} />;
};

interface ContentProps {
  data: QuestionDescriptionWidget;
}
const Content = ({
  data: {
    questionTitle,
    questionDescription,
    content,
    textAlign,
    verticalAlign,
  },
}: ContentProps) => {
  const verticalStyle = useMemo(() => {
    const result: CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: mapVerticalAlign(verticalAlign),
      position: 'relative',
      width: '100%',
      height: '100%',
      padding: 16, // TODO: theme based margins
    };
    return result;
  }, [verticalAlign,]);

  const style = useMemo(() => {
    const result: CSSProperties = {
      textAlign: textAlign as any,
      // TODO: mapping
    };
    return result;
  }, [textAlign,]);

  return <AutoMargin>
    <div style={verticalStyle}>
      <Typography
        component={'span'}
        style={style}
        variant="h4"
      >
        <ReadMore
          text={questionTitle}
          length={450}
        />
      </Typography>
      {questionDescription &&
        <Typography
          component={'span'}
          style={style}
          variant="caption"
        >
          <ReadMore
            text={questionDescription}
            length={450}
          />
        </Typography>}

      <Typography
        component={'span'}
        style={style}
        variant="caption"
      >
        <ReadMore
          text={content}
          length={450}
        />
      </Typography>
    </div>
  </AutoMargin>;
};

QuestionDescriptionWidgetComponent.config = {
  type: 'question-description',

  displayName: 'Question Description',
  props: {
    textAlign: {
      category: 'general',
      type: 'text-align',
      defaultValue: 'left',
      displayName: 'Text align',
    },
    verticalAlign: {
      category: 'general',
      type: 'vertical-align',
      defaultValue: 'middle',
      displayName: 'Vertical alignment',
    },
    background: {
      category: 'look',
      type: 'media',
      displayName: 'Background',
    },
    dataMode: {
      category: 'data',
      type: 'picker',
      displayName: 'Data mode',
      defaultValue: 'real-time',
      options: [
        {
          label: 'Real time',
          value: 'real-time',
        },
        {
          label: 'Snapshot',
          value: 'snapshot',
        },
      ],
    },
    sessionId: {
      category: 'data',
      type: 'session-picker',
      defaultValue: '',
      displayName: 'Session',
    },
    canvasId: {
      category: 'data',
      type: 'canvas-picker',
      defaultValue: '',
      displayName: 'Canvas',
    },
  },
};

export default QuestionDescriptionWidgetComponent;
