import { CommentFeedPresentation } from './CommentFeed';
import { usePresentationScreenContext } from 'components/screens/presentation/ScreenRenderer/PresentationScreenContext';
import Center from 'components/common/layout/Center';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import LoadingIndicator from 'components/common/LoadingIndicator';
import type { BuilderComponentPropsBase } from '../../PageWidgetRenderer';
import type { BuilderFunction } from '../../../builders/componentBuilder';
import type { Canvas } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export interface CommentFeedProps {
  mode?: 'feed' | '3d';
}
export interface CommentFeedWidgetProps extends BuilderComponentPropsBase<CommentFeedProps> {
  type: 'comment-feed';
  canvas?: WithId<Canvas>;
}

const CommentFeedWidget: BuilderFunction<CommentFeedWidgetProps, CommentFeedProps> = (
  props
) => {
  const screenContext = usePresentationScreenContext();

  const presentation = usePresentationScreenContext();

  if (!presentation) {
    // Edit mode
    return <Center>
      <div style={{
        textAlign: 'center',
        margin: 'auto',
        maxWidth: 300,
      }}>
        <DynamicFeedIcon
          style={{
            fontSize: 80,
          }} />
        <h1>Comment feed</h1>
        <p>Participant&apos;s live comment feed will be seen in here.</p>
      </div>
    </Center>;
  }
  if (!screenContext) {
    return <LoadingIndicator />;
  }
  return <CommentFeedPresentation
    canvas={props.canvas}
    data={props.data}
  />;
};

CommentFeedWidget.config = {
  type: 'comment-feed',
  displayName: 'Comment Feed',
  icon: <DynamicFeedIcon />,
  props: {
    mode: {
      type: 'picker',
      displayName: 'Mode',
      default: 'feed',
      options: [
        {
          value: 'feed', label: 'Feed',
        },
        {
          value: '3d', label: '3D Space',
        },
      ],
    },
  },
};

export default CommentFeedWidget;
