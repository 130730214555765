
export const resolveFieldPaths = (firstFieldPath: string, ...paths: string[]): string => {
  /**
   * Combine paths elements by adding a dot between them when the next
   * element is not starting with a square bracket. When the next element
   * starts with a square bracket, it is assumed that it is an array index
   * the content will be added without a dot.
   */
  const combinePathsElements = (): string => {
    let result = '';
    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      if (path.startsWith('[')) {
        result += path;
        continue;
      }
      if (result.length > 0) {
        result += `.${path}`;
        continue;
      }
      result = path;
    }
    return result;
  };

  const elements = combinePathsElements();
  if (!firstFieldPath) {
    return elements;
  }
  if (elements.startsWith('[')) {
    return `${firstFieldPath}${elements}`;
  }
  return `${firstFieldPath}.${elements}`;
};
