import { BundleError } from '@shared/schema/src/https/HttpMessage';
import { firebaseApp } from 'schema';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { isDevelopmentMode } from '@mindhiveoy/foundation';
import config from 'config';
import type { HttpMessage, HttpMessageResponse } from '@shared/schema/src/https/HttpMessage';
import type { HttpsCallableOptions } from 'firebase/functions';

const functions = getFunctions(firebaseApp(), config.environment.functionsRegion);

/**
 * Bundle function call is a helper function that will help you to create
 * @param bundle  Bundle name
 * @param type The actual message to be sent to the backend
 * @returns       Function that will call the backend with the given message
 *                and return the response
 * @throws        BundleError if the backend returns an error
 */
export const bundleFunctionCall = <Message extends HttpMessage<any, any, any>, Response>(
  bundle: string,
  type: Message['type'],
  options?: HttpsCallableOptions
) => {
  return async (payload: Message['payload']): Promise<Response> => {
    const api = httpsCallable<Message, HttpMessageResponse<Response>>(
      functions,
      bundle,
      options
    );

    try {
      const response = await api({
        type,
        payload,
      } as any);

      if (response.data.status === 'error') {
        console.error(
          payload,
          response.data.code, response.data.error);
        throw new BundleError(response.data.code, response.data.error);
      }
      return response.data.response;
    } catch (error: any) {
      if (isDevelopmentMode()) {
        console.error({
          message: 'network error:',
          bundle,
          options,
          payload,
          error,
        });
      }
      throw new BundleError('network', error);
    }
  };
};
