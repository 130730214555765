import { keyPathFieldNames } from '.';
import { validateKeyPath } from './validateKeyPath';
import type { CacheKeyPath } from '.';

type PathType = keyof CacheKeyPath;

export const pathHierarchy: PathType[] = ['spaceId', 'projectId', 'sessionId', 'canvasId',];
/**
   * Extract the CacheKeyPath to a string that will be used as a key in the cache. The key
   * format is to concatenate the ids of the elements in the key path and separate them with
   * lodash. The key path will follow the order of the elements in the key path: space, project,
   * session, canvas.
   *
   * @param keyPath The key path to extract the string from.
   * @returns The string representation of the key path.
   */
export const getKeyPathString = (keyPath: CacheKeyPath): string => {
  validateKeyPath(keyPath);

  const keyParts: string[] = [];
  for (const key of keyPathFieldNames) {
    const value = keyPath[key];
    if (value) {
      keyParts.push(value);
      continue;
    }
    break;
  }
  return keyParts.join('_');
};
