/* eslint-disable sonarjs/no-duplicate-string */
import { AccountReservation, CurrencyString, MoneyReservationMap, SupportedCurrency } from './Account';
import { Currency } from '@mindhiveoy/schema';
import get from 'lodash/get';
import BigInt from 'big-integer';

const CHARACTERS = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_-';
interface ConstructorParams {
  balance: CurrencyString;
  reservedBalance: CurrencyString;
  currency: SupportedCurrency;
  reservations?: MoneyReservationMap;
}
export type PaymentErrorCode =
  'no-balance' |
  'reservation-not-found' |
  'invalid-amount' |
  'internal';
/**
 * Error thrown by the account controller.
 */
class AccountControllerError extends Error {
  /**
   * Create a new account controller error.
   * @param {PaymentErrorCode}  code  The error code
   * @param {string}                      message  The error message
   */
  constructor(
    public code: PaymentErrorCode,
    message: string
  ) {
    super(message);
  }
}
/**
 * Helper class to handle account operations with accuracy.
 */
export class AccountController {
  /**
   * The number of decimals used to store the account balance. The
   */
  private static ACCOUNT_PRECISION = 6;

  private static CENT_DIVIDER = Math.pow(10, AccountController.ACCOUNT_PRECISION - 2);

  private _balance;

  private _reservedBalance;

  public readonly currency: SupportedCurrency;
  private reservations: MoneyReservationMap;

  /**
   * Create a new account controller.
   * @param {AccuracyCurrency}    balance         The account balance
   * @param {AccuracyCurrency}    reservedBalance The account reserved balance. The reserved balance is
   *                                              the sum of all reservations. Reservations are made to avoid
   *                                              to use more money than the account balance has.
   * @param {SupportedCurrency}   currency        The account currency
   * @param {MoneyReservationMap} reservations    The account reservations. Each reservation has its own id
   *                                              and the amount of money reserved.
   */
  constructor({
    balance,
    reservedBalance,
    currency,
    reservations = {},
  }: ConstructorParams) {
    this.currency = currency;
    this.reservations = reservations;
    this._balance = BigInt(balance);
    this._reservedBalance = BigInt(reservedBalance);
  }

  /**
   * Generate a random reservation id.
   * @return {string} The reservation id
   */
  private generateReservationId = () => {
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += CHARACTERS.charAt(Math.floor(Math.random() * CHARACTERS.length));
    }
    return result;
  };

  /**
   * Deposit money to the account.
   *
   * @param {Currency | CurrencyString} amount Amount to deposit in cents
   */
  public deposit = (amount: Currency | CurrencyString) => {
    switch (typeof amount) {
      case 'string':
        const value = BigInt(amount);
        if (value.lt(0)) {
          throw new AccountControllerError('invalid-amount', 'Invalid amount');
        }
        this._balance = this._balance.add(value);
        break;
      case 'number':
        if (amount < 0) {
          throw new AccountControllerError('invalid-amount', 'Invalid amount');
        }
        this._balance = this._balance
          .add(
            BigInt(amount)
              .multiply(AccountController.CENT_DIVIDER)
          );
        break;
      default:
        throw new Error('Invalid amount type');
    }
  };

  /**
   * Evaluate if the account has enough balance to make a reservation or withdrawal.
   * @param {Currency | CurrencyString}  amount  The amount to evaluate in nano currency
   * @return {boolean}                  True if the account has enough balance
   */
  public hasBalanceFor = (amount: Currency | CurrencyString) => {
    switch (typeof amount) {
      case 'string': {
        const availableBalance = this._balance
          .subtract(this._reservedBalance)
          .subtract(
            amount
          );
        return availableBalance.geq(0);
      }

      case 'number': {
        const availableBalance = this._balance
          .subtract(this._reservedBalance)
          .subtract(
            BigInt(amount)
              .multiply(AccountController.CENT_DIVIDER
              )
          );
        return availableBalance.geq(0);
      }
      default:
        throw new AccountControllerError('internal', 'Invalid amount type');
    }
  };

  /**
   * Withdraw money from the account with accuracy.
   * @param {AccuracyCurrency}  amount        The amount to withdraw in nano currency
   * @param {string}            reservationId The reservation id
   * @return {AccountReservation}  The reservation
   */
  public withdrawAccuracy = (
    amount: CurrencyString,
    reservationId: string
  ): AccountReservation => {
    const balance = this._balance.subtract(BigInt(amount));
    const withReservations = balance.subtract(this._reservedBalance);

    if (withReservations.lt(0)) {
      throw new AccountControllerError('no-balance', 'Not enough balance');
    }
    this._balance = balance;
    return this.release(reservationId);
  };

  /**
   * Withdraw money from the account.
   * @param {Currency}  amount  The amount to withdraw in cents
   */
  public withdraw = (amount: Currency) => {
    const a = BigInt(amount);
    const multiplied = a.multiply(AccountController.CENT_DIVIDER);

    const newBalance =
      this._balance
        .subtract(
          multiplied
        );

    const withReservations = newBalance.subtract(this._reservedBalance);
    if (withReservations.lt(0)) {
      throw new AccountControllerError('no-balance', 'Not enough balance');
    }
    this._balance = newBalance;
  };

  /**
   * Reserve money from the account. This is used to reserve money for AI
   * operations, to avoid to use more money than the account balance has.
   * @param {AccuracyCurrency}  amount  The amount to reserve in nano currency
   * @param {number}            tokens  The number of estimated tokens to derive the amount to reserve.
   * @return {string}  The reservation id
   * @throws {AccountControllerError} If the account has not enough balance
   */
  public reserve = (
    amount: CurrencyString,
    tokens?: number
  ): {
    reservationId: string;
    reservation: AccountReservation;
  } => {
    const balance = this._balance.subtract(this._reservedBalance);

    if (balance.lt(amount)) {
      throw new AccountControllerError('no-balance', 'Not enough balance');
    }
    this._reservedBalance = this._reservedBalance.add(BigInt(amount));

    const getUniqueReservationId = () => {
      let reservationId = this.generateReservationId();
      while (get(this.reservations, reservationId)) {
        reservationId = this.generateReservationId();
      }
      return reservationId;
    };

    const reservationId = getUniqueReservationId();
    const reservation: AccountReservation = {
      amount,
      created: new Date(),
      tokens,
    };

    this.reservations[reservationId] = reservation;
    return {
      reservationId,
      reservation,
    };
  };

  /**
   * Release a reservation.
   * @param {string}  reservationId The reservation id to release
   * @return {AccountReservation}  The reservation
   */
  public release = (reservationId: string): AccountReservation => {
    const reservation = this.reservations[reservationId];
    if (!reservation) {
      throw new AccountControllerError(
        'reservation-not-found',
        'Reservation not found'
      );
    }
    this._reservedBalance = this._reservedBalance.subtract(reservation.amount);
    const result = this.reservations[reservationId];
    delete this.reservations[reservationId];
    return result;
  };

  /**
   * Complete a reservation.
   * @return {Currency}  The new account balance
   */
  public toCurrency = (): Currency => {
    return Number(this._balance) / AccountController.CENT_DIVIDER;
  };

  /**
   * Show current balance with 6 digits using locale dependent decimal separator. THe
   * value will be shown with 6 decimals always.
   * @return {string} The balance with 6 decimals
   */
  public toDecimal = (): string => {
    const balance = this.toCurrency();
    return balance.toFixed(AccountController.ACCOUNT_PRECISION);
  };

  /**
   * Convert a currency amount to accuracy.
   * @param {Currency}  amount  The amount in currency
   * @return {AccuracyCurrency} The amount in accuracy
   */
  public static currencyToCurrencyString = (amount: Currency): CurrencyString => {
    return BigInt(amount).multiply(AccountController.CENT_DIVIDER).toString();
  };

  /**
   * The current account balance.
   */
  public get balance(): CurrencyString {
    return this._balance.toString();
  }

  /**
   * The current account reserved balance.
   */
  public get reservedBalance(): CurrencyString {
    return this._reservedBalance.toString();
  }

  /**
   * The current account available balance.
   */
  public get balanceAvailable(): CurrencyString {
    return this._balance.subtract(this._reservedBalance).toString();
  }
}
