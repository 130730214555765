import { css } from '@emotion/react';
import MediaContainer from './MediaContainer';
import React from 'react';
import styled$ from 'utils/react/styled$';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { ForwardRefRenderFunction } from 'react';
import type { Media } from '@shared/schema/src/common';
import type { PropsWithChildren } from 'react';

const Content = styled$.div<{
  $isMobile?: boolean;
  $mobileWithPadding?: boolean;
}>(({
  theme,
  $isMobile,
  $mobileWithPadding,
}) => css`
  display: flex;
  position: relative;
  flex: 1 0 auto;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-left: ${theme.spacing($isMobile && !$mobileWithPadding ? 0 : $mobileWithPadding ? 2 : 4)};
  padding-right: ${theme.spacing($isMobile && !$mobileWithPadding ? 0 : $mobileWithPadding ? 2 : 4)};
  padding-top: ${theme.constants.headerHeight};
  padding-bottom: ${$isMobile ? '3vh' : theme.constants.bottomHeight};
`);

interface ContentContainerProps {
  style?: React.CSSProperties;
  media?: Media;
  mobileWithPadding?: boolean;
}

const Container: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren<ContentContainerProps>> = (
  {
    children,
    style,
    media,
    mobileWithPadding,
  }: PropsWithChildren<ContentContainerProps>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return media ?
    <MediaContainer style={style}
      media={media}>
      {children}
    </MediaContainer> :
    <Content
      ref={ref}
      $mobileWithPadding={mobileWithPadding}
      $isMobile={isMobile}
      id="content-container"
      style={style}
      data-test-id="content-container">
      {children}
    </Content>;
};

const ContentContainer = React.forwardRef(
  Container
);

ContentContainer.displayName = 'ContentContainer';

export default ContentContainer;
