import type { VerticalAlign } from '@shared/schema/src';

export const mapVerticalAlign = (align?: VerticalAlign): string => {
  switch (align) {
    case 'top':
      return 'flex-start';
    case 'bottom':
      return 'flex-end';
    case 'middle':
    default:
      return 'center';
  }
};
