/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAnswers } from 'bloc/answer/AnswersBloc';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import ContinuousMatrixGridGraph from './modes/ContinuousMatrixGridGraph';
import DiscreteMatrixGridGraph from 'components/canvas/graphs/Matrix2DCanvasGraph/modes/DiscreteMatrixGridGraph';
import type { AnalyzeOptions, Matrix2DQuestionCanvas, Scale2DAnswer, Session } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

interface Graph2DProps {
  session?: WithId<Session>;
  canvas?: WithId<Matrix2DQuestionCanvas>;
  analyzeOptions?: AnalyzeOptions;
}
const AnalyzeGraph2D = ({
  // session,
  canvas,
}: Graph2DProps) => {
  const {
    t,
  } = useTranslation();

  if (!canvas) {
    return null;
  }
  switch (canvas.mode) {
    case 'discrete':
      return <DiscreteMatrixGridGraph
        axis={canvas.axis}
        frequencies={canvas.frequencies}
        canvasMode={canvas.mode}
        // minX={canvas.axis?.x?.min}
        // minY={canvas.axis?.y?.min}
        // session={session}
        // canvas={canvas}
        maxX={canvas.axis?.x?.max}
        maxY={canvas.axis?.y?.max}
        statistics={canvas.statistics as any}
        analyzeOptions={canvas.analyzeOptions}
      />;
    case 'continuous':
      return <ContinuosModeGraph
        canvas={canvas}
      />;

    default:
      return <div>{t('unknown-mode')}: {canvas.mode}</div>;
  }
};

const ContinuosModeGraph = ({
  canvas,
}: any) => {
  const [answers, setAnswers,] = useState<WithId<Scale2DAnswer>[]>([]);

  const answersBlock = useAnswers();

  useEffect(() => {
    return answersBlock.subscribe((newAnswers) => {
      setAnswers(newAnswers as any);
    });
  }, [answersBlock,]);

  return <ContinuousMatrixGridGraph
    axis={canvas.axis}
    answers={answers}
    // minX={canvas.axis?.x?.min}
    // minY={canvas.axis?.y?.min}
    maxX={canvas.axis?.x?.max}
    maxY={canvas.axis?.y?.max}
    analyzeOptions={canvas.analyzeOptions}
  />;
};
export default AnalyzeGraph2D;
