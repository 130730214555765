/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCanvas } from '../../../../bloc/canvas/CanvasBloc';
import { useEffect, useState } from 'react';
import StatInfo from '../../../session/SessionEditor/RestrictedSessionEditor/SessionEditor/modes/analyze/StatInfo';
import styled from '@emotion/styled';
import usePathParams from 'utils/hooks/usePathParams';
import type { BuilderComponentPropsBase } from '../BuilderComponentTypes';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { Canvas } from '@shared/schema/src';
import type { StatisticsWidget } from '@shared/schema/src/spaces/projects/sessions/canvases';
import type { WithId } from '@mindhiveoy/schema';

const AutoMargin = styled.div`
  margin: auto;
`;

export interface StatisticsWidgetProps extends BuilderComponentPropsBase<StatisticsWidget> {
  type: 'statistics';
}

const StatisticsWidgetComponent: BuilderFunction<StatisticsWidgetProps, StatisticsWidget> = ({
  data: {
    sessionId,
    canvasId,
  },
}: StatisticsWidgetProps) => {
  const pathParams = usePathParams();
  const [canvasFromBloc, setCanvas,] = useState<WithId<Canvas>>();
  const canvasBloc = useCanvas({
    spaceId: pathParams.spaceId,
    projectId: pathParams.projectId,
    sessionId,
    canvasId,
  });

  useEffect(() => canvasBloc.subscribe(
    setCanvas,
    (error) => console.error(error)
  ), [canvasBloc,]);

  return <AutoMargin>
    <StatInfo statistics={canvasFromBloc?.statistics} />
  </AutoMargin>;
};

StatisticsWidgetComponent.config = {
  type: 'statistics',

  displayName: 'Statistics',
  props: {
    dataMode: {
      category: 'general',
      type: 'picker',
      displayName: 'Data mode',
      defaultValue: 'real-time',
      options: [
        {
          label: 'Real time',
          value: 'real-time',
        },
        {
          label: 'Snapshot',
          value: 'snapshot',
        },
      ],
    },
    sessionId: {
      category: 'general',
      defaultValue: '',
      type: 'session-picker',
      displayName: 'Session',
    },
    canvasId: {
      category: 'general',
      defaultValue: '',
      type: 'canvas-picker',
      displayName: 'Canvas',
    },
    characteristics: {
      type: 'stat-characteristic',
      options: [{
        value: 'mean',
        label: 'Mean',
      },
      {
        value: 'average',
        label: 'Average',
      },
      {
        value: 'q1',
        label: '1. quartile',
      },
      {
        value: 'median',
        label: 'Median',
      },
      {
        value: 'q3',
        label: '3. quartile',
      },
      ],
    },
  },
};

export default StatisticsWidgetComponent;
