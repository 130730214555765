import { CharacteristicPlot } from './CharacteristicPlot';
import { FrequencyDot2DGraph } from '../../FrequencyDot2DGraph';
import { useMemo, useRef } from 'react';
import Graph2DCanvas from '../../../../canvasTypes/Matrix2DQuestionCanvas/Graph2DCanvas';
import type { AnalyzeOptions, Axis2D, Frequencies, Statistics2D } from '@shared/schema/src';
import type { PropsWithChildren } from 'react';
import type { Size } from '../../../../canvasTypes/Matrix2DQuestionCanvas';

export const MIN_RADIUS = 0.1;
export const MAX_RADIUS = 0.95;

interface DiscreteMatrixGridGraphProps {
  axis: Axis2D;
  size?: Size;
  color?: string;
  knobColor?: string;
  frequencies?: Frequencies;
  canvasMode?: 'continuous' | 'discrete';
  statistics?: Statistics2D;
  maxX?: number;
  maxY?: number;
  analyzeOptions?: AnalyzeOptions;
}

const DiscreteMatrixGridGraph = ({
  axis,
  // canvas,
  children,
  // session,
  color = 'lightgray',
  frequencies,
  // isPresentation = false,
  knobColor = 'darkgray',
  maxX,
  maxY,
  statistics,
  analyzeOptions,
}: PropsWithChildren<DiscreteMatrixGridGraphProps>) => {
  const scaleY = (maxY ?? 1) * 2;
  const columnCount = axis?.x.range.length ?? 0;
  const rowCount = axis?.y.range.length ?? 0;

  const ref = useRef<HTMLDivElement>(null);

  const maxFrequencyValue = useMemo(() => frequencies ?
    Object
      .values(frequencies)
      .reduce(
        (current, value) => Math.max(current, value), 1) :
    1, [frequencies,]);

  return <Graph2DCanvas
    ref={ref}
    rowCount={rowCount}
    data-id='discrete-matrix-grid-graph'
    columnCount={columnCount}
  >
    <FrequencyDot2DGraph
      data-id='frequency-dot-2d-graph'
      rowCount={rowCount}
      columnCount={columnCount}
      color={color}
      frequencies={frequencies}
      maxFrequencyValue={maxFrequencyValue}
      analyzeOptions={analyzeOptions}
      statistics={statistics}
      knobColor={knobColor}
      minX={-maxX!}
      minY={-maxY!}
      scaleY={scaleY}
      axis={axis}
    />
    <CharacteristicPlot
      analyzeOptions={analyzeOptions!}
      statistics={statistics!}
      axis={axis}
    />
    {
      children
      // isPresentation && <PinnedMembers
      //   canvas={canvas}
      //   session={session!}
      // />
    }
  </Graph2DCanvas>;
};

export default DiscreteMatrixGridGraph;
