import { Button, useMediaQuery, useTheme } from '@mui/material';
import { useAuthentication } from '../AuthProvider';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import LocaleSelector from 'components/layout/LocaleSelector';
import styled from '@emotion/styled';

const ButtonArea = styled.div(({
  theme,
}) => `
  position: relative;
  z-index: 2000;
  width: fit-content;
  display: flex;
  align-items: center;
  position: absolute;
  right: ${theme.spacing(4)};
`);

export const SigningButtons = () => {
  const {
    t,
  } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    openSignInDialog,
    openSignUpDialog,
  } = useAuthentication();

  const handleSignUp = useCallback(() => {
    openSignUpDialog();
  }, [openSignUpDialog,]);

  // TODO: Refactor responsibilities to be correct - there is locale selector here
  return <ButtonArea>
    <LocaleSelector />

    <Button
      variant='text'
      onClick={openSignInDialog}
    >
      {t('Sign in')}
    </Button>
    &nbsp;
    {!isMobile ? <Button
      variant='contained'
      onClick={handleSignUp}
    >
      {t('Sign up')}
    </Button> : null}
  </ButtonArea>;
};
