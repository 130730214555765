/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react';

import { useCanvas } from '../../../../bloc/canvas/CanvasBloc';
import Center from '../../../common/layout/Center';
import StatInfo from '../../../session/SessionEditor/RestrictedSessionEditor/SessionEditor/modes/analyze/StatInfo';
import styled from '@emotion/styled';
import usePathParams from '../../../../utils/hooks/usePathParams';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { Canvas } from '@shared/schema/src';
import type { StatisticsWidgetProps } from '../renderers/StatisticsWidget';
import type { WithId } from '@mindhiveoy/schema';

const AutoMargin = styled.div`
  margin: auto;
`;

const StatisticsWidgetEditor: BuilderFunction<StatisticsWidgetProps> = ({
  data: {
    sessionId,
    canvasId,
  },
}: StatisticsWidgetProps) => {
  const {
    spaceId,
    projectId,
  } = usePathParams();

  const canvasParams = useMemo(() => {
    return {
      spaceId,
      projectId,
      sessionId,
      canvasId,
    };
  }, [canvasId, projectId, sessionId, spaceId,]);

  if (!sessionId) {
    return <Center>
      Please pick session from inspector
    </Center>;
  }

  if (!canvasId) {
    return <Center>
      Please pick canvas from inspector
    </Center>;
  }
  return <Statistics
    canvasParams={canvasParams}
  />;
};

const Statistics = ({
  canvasParams,
}: any) => {
  const [canvasFromBloc, setCanvas,] = useState<WithId<Canvas>>();
  const canvasBloc = useCanvas(canvasParams);

  useEffect(
    () => canvasBloc.subscribe(
      setCanvas,
      (error) => console.error(error)
    ),
    [canvasBloc,]
  );

  return <Center>
    <AutoMargin>
      <StatInfo statistics={canvasFromBloc?.statistics} />
    </AutoMargin>
  </Center>;
};

StatisticsWidgetEditor.config = {
  type: 'statistics',
  displayName: 'Statistics',
  props: {
    sessionId: {
      type: 'session-picker',
      defaultValue: '',
      displayName: 'Session',
    },
    canvasId: {
      type: 'canvas-picker',
      defaultValue: '',
      displayName: 'Canvas',
    },
    characteristics: {
      type: 'stat-characteristic',
      options: [{
        value: 'mean',
        label: 'Mean',
      },
      {
        value: 'average',
        label: 'Average',
      },
      {
        value: 'q1',
        label: '1. quartile',
      },
      {
        value: 'median',
        label: 'Median',
      },
      {
        value: 'q3',
        label: '3. quartile',
      },
      ],
    },
    dataMode: {
      type: 'data-mode',
      defaultValue: 'real-time',
      options: [
        {
          value: 'snapshot',
          label: 'Snapshot',
        }, {
          value: 'real-time',
          label: 'Real time',
        },
      ],
    },
  },
};

export default StatisticsWidgetEditor;
