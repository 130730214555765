/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  Canvas, CanvasId, CanvasStripInfo, DeleteCanvasDataResponse, DraftCanvasDocument, Session, WithChanges
} from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

import { startTransition, useCallback, useRef } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import usePathParams from 'utils/hooks/usePathParams';
import type { PropsWithChildren } from 'react';

import * as nanoid from 'nanoid';
import { CommandQueue } from '../../../../../utils/commandQueue';
import { areEqual } from './utils/areEqual';
import { attachCallerId, isCaller } from './utils/callerLogic';
import type {
  CanvasObserverFunction, CanvasesObserverFunction, DraftSessionDataContextType,
  DraftSessionDataContextType as SessionDraftContextType, SessionObserverFunction
} from './SessionDraftContextType';
import type { PublishSessionDraftRequest } from '@shared/schema/src/https/session/publishSessionChanges';
import type { RevertSessionDraftRequest } from '@shared/schema/src/https/session/revertSessionChanges';

import { cancelBackendCalls, forceBackendCalls, saveCanvasToBackendDebounced, saveSessionToBackendDebounced } from './draftAPI';
import { collection, getFirestore, onSnapshot, query } from 'firebase/firestore';
import { createCanvasDraft, deleteCanvasDraft, duplicateCanvasDraft } from 'bloc/canvasDraft/canvasDraftApi';
import { deserializeCanvas, deserializeCanvasDraft } from './utils/serializers';
import { disposeSessionListener, sessionListener } from './sessionListener';
import { firebaseApp } from 'schema';
import { immutable } from '@mindhiveoy/bloc/utils/immutable';
import { mergeWithDrafts } from './utils/mergeWithDrafts';
import { publishSessionDraft, revertSessionDraft } from 'bloc/session/sessionApi';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

export type UnsubscribeFunction = () => void;

/**
 * Change id generator for draft document changes. With this id, we can track if the
 * change coming back from the Firestore is the same as the one we sent.
 * @returns {string} The change id
 */
const generateChangeId = () => nanoid.nanoid(16);
// Set up the context

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop: any = () => { };

export const SessionDraftContext = React.createContext<SessionDraftContextType>({
  activeCanvasId: null,
  canvases: [],
  hasChanges: false,
  projectId: '',
  publishing: false,
  reverting: false,
  saving: false,
  sessionId: '',
  spaceId: '',
  changeActiveCanvas: () => noop,
  createCanvas: () => noop,
  deleteCanvas: () => noop,
  duplicateCanvas: () => noop,
  getCanvas: () => noop,
  isDraftOnly: () => noop,
  publish: () => noop,
  revert: () => noop,
  saveCanvasDraft: () => noop,
  saveSessionDraft: () => noop,
  subscribeToCanvas: () => noop,
  subscribeToCanvases: () => noop,
  subscribeToSession: () => noop,
  updateCanvasDraft: () => noop,
  updateSessionDraft: () => noop,
});

const firestore = getFirestore(firebaseApp());

export type Canvases = Record<CanvasId, WithId<Canvas>>;

/**
 * The provider for the session draft context
 */
class ChangeMapper {
  private canvasChangeMap = new Map<string, Set<string>>();

  hasChangeId = (canvasId: string, changeId: string) => {
    const set = this.canvasChangeMap.get(canvasId);
    if (!set) {
      return false;
    }
    return set.has(changeId);
  };

  addChangeId = (canvasId: string, changeId: string) => {
    const changeIds = this.canvasChangeMap.get(canvasId) ?? new Set<string>();
    changeIds.add(changeId);
    this.canvasChangeMap.set(canvasId, changeIds);
  };

  removeChangeId = (canvasId: string, changeId: string) => {
    const changeIds = this.canvasChangeMap.get(canvasId);
    if (changeIds) {
      changeIds.delete(changeId);
      if (changeIds.size === 0) {
        this.canvasChangeMap.delete(canvasId);
      }
    }
  };
}
// Set up the provider
export const DraftSessionDataProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const params = usePathParams();

  const commandQueue = useRef(new CommandQueue());

  const [spaceId, setSpaceId,] = useState(params.spaceId);
  const [projectId, setProjectId,] = useState(params.projectId);
  const [sessionId, setSessionId,] = useState(params.sessionId);
  const [hasChanges, setHasChanges,] = useState(false);
  const [saving, setSaving,] = useState(false);
  const [publishing, setPublishing,] = useState(false);

  const [reverting, setReverting,] = useState(false);

  const [canvases, setCanvases,] = useState<WithId<Canvas>[]>([]);
  const canvasData = useRef<Canvases>({});
  const draftMap = useRef<Record<CanvasId, DraftCanvasDocument>>({});

  const sessionData = useRef<WithId<Session>>();

  const [activeCanvasId, setActiveCanvasId,] = useState<CanvasId | null>(null);
  const canvasIdSubscriptions = useRef<Record<CanvasId, CanvasObserverFunction[]>>({});
  const canvasesSubscriptions = useRef<CanvasesObserverFunction[]>([]);
  const sessionSubscription = useRef<SessionObserverFunction[]>([]);

  const sessionDraftIdMap = useRef<Set<string>>(new Set<string>());
  const canvasChangeMap = useRef<ChangeMapper>(new ChangeMapper());

  const isDraftOnly = useCallback((canvasId: CanvasId | null) => {
    if (!canvasId) {
      return false;
    }
    const canvas = canvasData.current[canvasId];
    return !!(canvas as any)?._draft;
  }, []);

  useEffect(() => {
    setSpaceId(params.spaceId);
    setProjectId(params.projectId);
    setSessionId(params.sessionId);
  }, [params,]);

  const fireTriggerForCanvases = useCallback((canvases: WithId<Canvas>[], caller?: any) => {
    const subscribers = canvasesSubscriptions.current;
    if (subscribers) {
      const subs = subscribers.slice(0);
      const event = cloneDeep(canvases);

      startTransition(() => {
        subs.forEach((subscriber) => {
          try {
            if (isCaller(subscriber, caller)) {
              return;
            }
            subscriber(event);
          } catch (e) {
            console.error(e);
          }
        });
      });
    }
  }, []);

  const mapCanvases = useCallback((_canvasStrip: CanvasStripInfo[]) => {
    const canvasStrip = _canvasStrip ?? cloneDeep(sessionData.current?.canvasStrip);
    if (!canvasStrip) {
      return;
    }
    const canvases: WithId<Canvas>[] = [];

    canvasStrip.forEach((item) => {
      const canvas = immutable(canvasData.current[item._id]);
      if (canvas) {
        // item.name = canvas.name;
        // item.media = canvas.media;
        canvases.push(canvas);
      }
    });

    setCanvases(canvases);
    return canvases;
  }, []);

  const changeActiveCanvas = useCallback(async (canvasId: CanvasId) => {
    commandQueue.current.execute(async () => {
      let activeCanvasId: CanvasId | null = null;

      let resolve: any;
      const waitForCanvasId = new Promise((_resolve) => {
        resolve = _resolve;
      });

      setActiveCanvasId((_activeCanvasId) => {
        activeCanvasId = _activeCanvasId;
        resolve?.();
        return canvasId;
      });

      await waitForCanvasId;

      if (activeCanvasId === canvasId || !canvasId || !activeCanvasId) {
        return;
      }

      if (!sessionData.current || !canvasData.current[activeCanvasId!]) {
        return;
      }
      // Run immediately any delayed backend calls
      // TODO:
      forceBackendCalls({
        spaceId,
        projectId,
        sessionId,
        // session: sessionData.current!,
        canvas: immutable(canvasData.current[activeCanvasId!]),
        canvasId: activeCanvasId!,
      }, {
        spaceId,
        projectId,
        sessionId,
        session: immutable(sessionData.current!),
      }, setSaving
      );
    });
  }, [projectId, sessionId, spaceId,]);

  const fireCanvasTriggers = useCallback((canvasIds: CanvasId[], caller?: any) => {
    canvasIds.forEach((canvasId) => {
      const subscribers = canvasIdSubscriptions.current[canvasId];
      if (subscribers) {
        const message = cloneDeep(canvasData.current[canvasId]);
        subscribers.forEach((subscriber) => {
          try {
            if (isCaller(subscriber, caller)) {
              return;
            }
            subscriber(message);
          } catch (e) {
            console.error(e);
          }
        });
      }
    });
  }, []);

  // Debounced save functions
  const _saveCanvasDraft = useCallback(
    (canvasId: CanvasId, canvas: WithId<Canvas>, caller?: any) => {
      const changes = cloneDeep(canvas) as any as WithChanges;
      let changeId = changes._changeId;

      const currentCanvas = canvasData.current[canvasId];

      if (areEqual(currentCanvas, canvas)) {
        return;
      }
      setHasChanges(true);

      changeId = generateChangeId();
      changes._changeId = changeId;
      changes._status = 'draft';

      canvasChangeMap.current.addChangeId(canvasId, changeId);

      const triggers: any[] = [];

      if (currentCanvas && sessionData.current &&
        (currentCanvas?.name !== canvas.name ||
          !isEqual(currentCanvas?.media, canvas.media))
      ) {
        const canvasStrip = sessionData.current.canvasStrip;

        const item = canvasStrip.find((c: any) => c._id === canvasId);
        if (item) {
          const newCanvasStrip = cloneDeep(canvasStrip);
          const index = newCanvasStrip.findIndex((c: any) => c._id === canvasId);
          if (index >= 0) {
            newCanvasStrip[index] = {
              ...item,
              name: canvas.name,
              media: canvas.media,
            };
          }

          const session = {
            ...sessionData.current,
            _id: sessionId,
            canvasStrip: newCanvasStrip,
          } as WithId<Session>;

          const subscribers = sessionSubscription.current;
          if (subscribers) {
            const subs = subscribers.slice(0);
            const event = cloneDeep(session);

            triggers.push(() => {
              subs.forEach((subscriber) => {
                try {
                  if (isCaller(subscriber, caller)) {
                    return;
                  }
                  subscriber(event);
                } catch (e) {
                  console.error(e);
                }
              });
            });
          }
        }
      }

      // canvasChangeMap.current.addChangeId(canvasId, changeId);
      canvas = cloneDeep(canvas) as any as WithId<Canvas>;

      canvasData.current[canvasId] = canvas;

      draftMap.current = {
        ...draftMap.current,
        [canvasId]: {
          status: 'modified',
          content: canvas,
        },
      };

      const canvases = cloneDeep(mapCanvases(sessionData.current?.canvasStrip ?? []));

      canvases && triggers.push(() => {
        fireTriggerForCanvases(canvases, caller);
      });

      const send = async () => {
        try {
          if (!sessionData.current) {
            return;
          }
          await saveCanvasToBackendDebounced.trigger({
            spaceId,
            projectId,
            sessionId,
            canvasId,
            canvas,
            // session: sessionData.current,
          }, setSaving);
        } catch (e) {
          console.error(e);
          // handle the error, e.g. by setting some error state
        }
      };
      send();

      startTransition(() => {
        triggers.forEach((trigger) => {
          trigger();
        });
      });
    }, [fireTriggerForCanvases, mapCanvases, projectId, sessionId, spaceId,]
  );

  const saveCanvasDraft = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (canvasId: CanvasId, canvas: WithId<Canvas>, caller?: any) => {
      const changes = cloneDeep(canvas);
      commandQueue.current.execute(async () => {
        _saveCanvasDraft(canvasId, changes);
      });
    }, [_saveCanvasDraft,]);

  const updateCanvasDraft = useCallback(
    (canvasId: CanvasId, canvas: Partial<WithId<Canvas>>) => {
      commandQueue.current.execute(async () => {
        if (!canvas) {
          return;
        }
        const newCanvas = {
          ...canvasData.current[canvasId],
          ...canvas,
        } as WithId<Canvas>;
        _saveCanvasDraft(canvasId, newCanvas);
      });
    }, [_saveCanvasDraft,]);

  const updateSessionDraft = useCallback((session: Partial<WithId<Session>>) => {
    commandQueue.current.execute(async () => {
      const newSession = cloneDeep({
        ...sessionData.current,
        ...session,
      }) as WithId<Session>;
      sessionData.current = newSession;

      setHasChanges(true);

      const subscribers = sessionSubscription.current;
      if (subscribers) {
        const subs = subscribers.slice(0);
        const event = cloneDeep(newSession);

        startTransition(() => {
          subs.forEach((subscriber) => {
            try {
              subscriber(event);
            } catch (e) {
              console.error(e);
            }
          });
        });
      }
    });
  }, []);

  const saveSessionDraft = useCallback((session: WithId<Session>) => {
    commandQueue.current.execute(async () => {
      if (!session || isEqual(sessionData.current, session)) {
        return;
      }
      const changeId = generateChangeId();
      const changes = session as any as WithChanges;

      changes._changeId = changeId;
      sessionDraftIdMap.current.add(changeId);

      sessionData.current = session;
      setHasChanges(true);

      const subscribers = sessionSubscription.current;
      if (subscribers) {
        const _session = cloneDeep(session);
        startTransition(() => {
          subscribers.forEach((subscriber) => {
            try {
              subscriber(_session);
            } catch (e) {
              console.error(e);
            }
          });
        });
      }

      saveSessionToBackendDebounced.trigger({
        spaceId,
        projectId,
        sessionId,
        session,
        status: 'modified',
      }, setSaving);
    });
  }, [projectId, sessionId, spaceId,]);

  // Firestore listeners
  useEffect(() => {
    sessionListener(
      spaceId,
      projectId,
      sessionId,
      (session) => {
        commandQueue.current.execute(async () => {
          const changes = session as any as WithChanges;

          let changeId = changes._changeId;
          if (changeId && sessionDraftIdMap.current.has(changeId)) {
            // This is the change we sent, ignore it
            // sessionDraftIdMap.current.delete(changeId);
            return;
          }

          changeId = changeId ?? generateChangeId();
          changes._changeId = changeId;
          sessionDraftIdMap.current.add(changeId);
          sessionData.current = cloneDeep(session);

          const subscribers = sessionSubscription.current;

          const canvases = mapCanvases(session?.canvasStrip ?? []);
          if (canvases) {
            fireTriggerForCanvases(canvases);
          }

          if (subscribers) {
            const event = cloneDeep(session);
            startTransition(() => {
              subscribers.forEach((subscriber) => {
                try {
                  subscriber(event);
                } catch (e) {
                  console.error(e);
                }
              });
            });
          }
        });
      }
    );
  }, [fireTriggerForCanvases, mapCanvases, projectId, sessionId, spaceId,]);

  useEffect(() => {
    const sessionPath = `spaces/${spaceId}/projects/${projectId}/sessions/${sessionId}`;
    const canvasesRef = query(collection(firestore, `${sessionPath}/canvases`));
    const canvasDraftsRef = query(collection(firestore, `${sessionPath}/canvasDrafts`));

    // let canvasMap: Record<CanvasId, WithId<Canvas>> = {};

    // We will listen both: the actual canvases and drafts. The draft canvases are
    // fetched first to avoid the flickering of the data.
    // This code will merge a map of canvases with data from drafts and actual canvases
    // The draft ones will override the actual ones.

    const subscribeToActualCanvases = () => {
      let initial = true;
      return onSnapshot(canvasesRef, (docs) => {
        commandQueue.current.execute(async () => {
          const map: Record<CanvasId, WithId<Canvas>> = cloneDeep({
            ...canvasData.current,
          });

          const changedIds: string[] = [];

          if (initial) {
            initial = false;
            docs.forEach((doc) => {
              const canvas = deserializeCanvas(doc.data(), doc.id);
              if (!canvas) {
                return;
              }

              const changes = canvas as any as WithChanges;
              changes._status = 'published';
              const changeId = changes._changeId ?? generateChangeId();
              changes._changeId = changeId;

              map[doc.id] = canvas;
              canvasChangeMap.current.addChangeId(doc.id, changeId);

              changedIds.push(doc.id);
            });
          } else {
            docs.docChanges().forEach(({
              type,
              doc,
            }) => {
              const data: any = deserializeCanvas(doc.data(), doc.id);
              if (!data) {
                delete map[doc.id];
                return;
              }
              switch (type) {
                case 'added':
                case 'modified':
                  map[doc.id] = data;
                  break;

                case 'removed':
                  delete map[doc.id];
                  return;
              }
              const changes = data as any as WithChanges;
              changes._status = 'published';
              const changeId = changes._changeId ?? generateChangeId();
              changes._changeId = changeId;
              if (canvasData.current[doc.id]) {
                return;
              }
              if (!canvasChangeMap.current.hasChangeId(doc.id, changeId)) {
                canvasChangeMap.current.addChangeId(doc.id, changeId);
                changedIds.push(doc.id);
              }
            });
          }

          canvasData.current = mergeWithDrafts(map, draftMap.current);

          const canvases = mapCanvases(sessionData.current?.canvasStrip ?? []);
          if (canvases) {
            fireTriggerForCanvases(canvases);
          }
          fireCanvasTriggers(changedIds);
        });
      });
    };
    let unsubscribe: any = undefined;

    const unsubscribeDraft = onSnapshot(canvasDraftsRef, (docs) => {
      commandQueue.current.execute(async () => {
        const map: Record<CanvasId, DraftCanvasDocument> = {
          ...draftMap.current,
        };

        const changedCanvases: string[] = [];

        if (!unsubscribe) {
          unsubscribe = subscribeToActualCanvases();
        }

        docs.docChanges().forEach(({
          type,
          doc,
        }) => {
          if (type === 'removed') {
            changedCanvases.push(doc.id);
            delete map[doc.id];
            return;
          }
          const draft = deserializeCanvasDraft(doc.data(), doc.id);
          if (!draft) {
            return;
          }

          const changes = draft.content as any as WithChanges;
          changes._status = 'published';
          const changeId = changes._changeId;

          if (draftMap.current[doc.id] && canvasChangeMap.current.hasChangeId(doc.id, changeId)) {
            return;
          }
          canvasChangeMap.current.addChangeId(doc.id, changeId);
          changedCanvases.push(doc.id);

          map[doc.id] = draft;
        });
        if (!changedCanvases.length) {
          return;
        }
        draftMap.current = map;
        canvasData.current = mergeWithDrafts(canvasData.current, draftMap.current);

        const canvases = mapCanvases(sessionData.current?.canvasStrip ?? []);
        if (canvases) {
          fireTriggerForCanvases(canvases);
        }

        fireCanvasTriggers(changedCanvases);
      });
    });

    return () => {
      unsubscribe?.();
      unsubscribeDraft?.();
    };
  }, [fireCanvasTriggers, fireTriggerForCanvases, mapCanvases, projectId, sessionId, spaceId,]);

  // Publish function
  const publish = useCallback(async () => {
    commandQueue.current.execute(async () => {
      setPublishing(true);
      try {
        // Cancel any pending backend save calls
        cancelBackendCalls();

        const params: PublishSessionDraftRequest = {
          spaceId,
          projectId,
          sessionId,
        };
        if (activeCanvasId) {
          params.activeCanvasId = activeCanvasId;
          params.canvas = canvasData.current[activeCanvasId];
        }
        if (sessionData.current) {
          params.session = sessionData.current;
        }
        // Call the backend to save the changes
        // Reset the state after publishing
        await publishSessionDraft(params);
        setHasChanges(false);
      } catch (error) {
        console.error(error);
      } finally {
        setPublishing(false);
        setSaving(false);
      }
    });
  }, [activeCanvasId, projectId, sessionId, spaceId,]);

  // Revert function
  const revert = useCallback(async () => {
    commandQueue.current.execute(async () => {
      setReverting(true);
      try {
        // Cancel any pending backend save calls
        cancelBackendCalls();

        const params: RevertSessionDraftRequest = {
          spaceId,
          projectId,
          sessionId,
        };

        setHasChanges(false);

        Object.keys(canvasData.current).forEach((canvasId) => {
          // const canvas = canvasData.current[canvasId];
          if (isDraftOnly(canvasId)) {
            delete canvasData.current[canvasId];
          }
        });

        if (sessionData.current) {
          const strip = sessionData.current.canvasStrip;
          // remove all items form the strip that have no _id match with canvasData.current's keys
          sessionData.current.canvasStrip = strip.filter((item) => canvasData.current[item._id]);
        }

        await revertSessionDraft(params);
      } catch (error) {
        console.error(error);
      } finally {
        setReverting(false);
        setSaving(false);
      }
    });
  }, [isDraftOnly, projectId, sessionId, spaceId,]);

  const subscribeToCanvas = useCallback((canvasId: CanvasId, callback: CanvasObserverFunction) => {
    commandQueue.current.execute(async () => {
      const subscribers = canvasIdSubscriptions.current[canvasId] ?? [];

      subscribers.push(callback);

      attachCallerId(subscribers, callback);

      canvasIdSubscriptions.current[canvasId] = subscribers;

      try {
        callback(canvasData.current[canvasId]);
      } catch (e) {
        console.error(e);
      }
    });

    return () => {
      // unsubscribe();

      commandQueue.current.execute(async () => {
        const subscribers = canvasIdSubscriptions.current[canvasId] ?? [];
        const index = subscribers.indexOf(callback);
        if (index >= 0) {
          subscribers.splice(index, 1);
        }
      });
    };
  }, []);

  const subscribeToSession = useCallback((callback: SessionObserverFunction) => {
    commandQueue.current.execute(async () => {
      const subscribers = sessionSubscription.current;

      subscribers.push(callback);
      sessionSubscription.current = subscribers;

      try {
        callback(sessionData.current);
      } catch (e) {
        console.error(e);
      }
    });
    return () => {
      commandQueue.current.execute(async () => {
        const subscribers = sessionSubscription.current;
        const index = subscribers.indexOf(callback);
        if (index >= 0) {
          subscribers.splice(index, 1);
        }
      });
    };
  }, []);

  const subscribeToCanvases = useCallback((callback: CanvasesObserverFunction) => {
    commandQueue.current.execute(async () => {
      const subscribers = canvasesSubscriptions.current;

      subscribers.push(callback);
      canvasesSubscriptions.current = subscribers;

      const canvases = mapCanvases(sessionData.current?.canvasStrip ?? []);
      try {
        canvases && callback(canvases);
      } catch (e) {
        console.error(e);
      }
    });
    return () => {
      commandQueue.current.execute(async () => {
        const subscribers = canvasesSubscriptions.current;
        const index = subscribers.indexOf(callback);
        if (index >= 0) {
          subscribers.splice(index, 1);
        }
      });
    };
  }, [mapCanvases,]);

  const _createCanvasDraft = useCallback(async (canvas: Partial<Canvas>, after?: CanvasId) => {
    try {
      setSaving(true);

      const result = await createCanvasDraft({
        spaceId,
        projectId,
        sessionId,
        canvas,
        after,
        session: sessionData.current!,
      });

      const newCanvas = result.canvas;

      commandQueue.current.execute(async () => {
        draftMap.current[newCanvas._id] = {
          status: 'created',
          content: result.canvas,
        };

        canvasData.current[newCanvas._id] = {
          ...result.canvas,
          _draft: true,
        } as any;
        canvasChangeMap.current.addChangeId(newCanvas._id, (newCanvas as any)._changeId);

        setHasChanges(true);
        setActiveCanvasId(result.canvas._id);

        sessionData.current = {
          ...sessionData.current,
          canvasStrip: result.canvasStrip,
        } as any;

        const canvases = mapCanvases(result.canvasStrip);
        if (canvases) {
          fireTriggerForCanvases(canvases);
        }
      });
      return result.canvas;
    } finally {
      setSaving(false);
    }
  }, [fireTriggerForCanvases, mapCanvases, projectId, sessionId, spaceId,]);

  const _duplicateCanvasDraft = useCallback(async (canvasId: CanvasId) => {
    try {
      setSaving(true);
      const result = await duplicateCanvasDraft({
        spaceId,
        projectId,
        sessionId,
        canvasId,
      });
      const newCanvas = result.canvas;

      commandQueue.current.execute(async () => {
        draftMap.current[newCanvas._id] = {
          status: 'created',
          content: newCanvas,
        };

        canvasData.current[newCanvas._id] = {
          ...newCanvas,
          _draft: true,
        } as any;

        canvasChangeMap.current.addChangeId(newCanvas._id, (newCanvas as any)._changeId);

        setHasChanges(true);
        setActiveCanvasId(newCanvas._id);

        const canvases = mapCanvases(result.canvasStrip);
        if (canvases) {
          fireTriggerForCanvases(canvases);
        }
      });
      return newCanvas;
    } finally {
      setSaving(false);
    }
  }, [fireTriggerForCanvases, mapCanvases, projectId, sessionId, spaceId,]);

  const _deleteCanvasDraft = useCallback(async (canvasId: CanvasId) => {
    return new Promise((resolve) => {
      commandQueue.current.execute(async () => {
        try {
          setSaving(true);
          if (!sessionData?.current) {
            resolve(undefined);
            return;
          }

          const canvasStrip = cloneDeep(sessionData.current.canvasStrip) ?? [];
          const index = canvasStrip.findIndex((c) => c._id === canvasId);
          if (index >= 0) {
            canvasStrip.splice(index, 1);
            // sessionData.current.canvasStrip = canvasStrip;
          }
          const ind = Math.min(index, canvasStrip.length - 1);
          const activeCanvasId = canvasStrip[ind] ? canvasStrip[ind]._id : undefined;
          if (activeCanvasId) {
            setActiveCanvasId(activeCanvasId);
          }

          const newData = cloneDeep(canvasData.current);

          delete newData[canvasId];

          canvasData.current = newData;
          sessionData.current.canvasStrip = canvasStrip;

          const canvases = mapCanvases(canvasStrip);
          canvases && fireTriggerForCanvases(canvases);

          await deleteCanvasDraft({
            spaceId,
            projectId,
            sessionId,
            canvasId,
          });
          setHasChanges(true);

          resolve(undefined);
        } finally {
          setSaving(false);
        }
      });
    }) as Promise<DeleteCanvasDataResponse | undefined>;
  }, [fireTriggerForCanvases, mapCanvases, projectId, sessionId, spaceId,]);

  useEffect(() => {
    // TODO: Make the saving the state possible in the dispose
    return () => disposeSessionListener();
  }, []);

  const value: DraftSessionDataContextType = useMemo(() => ({
    spaceId,
    projectId,
    sessionId,
    activeCanvasId,
    reverting,
    hasChanges,
    saving,
    publishing,
    canvases,
    changeActiveCanvas,
    getCanvas: (canvasId: CanvasId) => immutable(canvasData.current[canvasId]),
    createCanvas: _createCanvasDraft,
    duplicateCanvas: _duplicateCanvasDraft,
    deleteCanvas: _deleteCanvasDraft,
    isDraftOnly,
    saveCanvasDraft,
    saveSessionDraft,
    updateCanvasDraft,
    updateSessionDraft,
    subscribeToCanvas,
    subscribeToSession,
    subscribeToCanvases,
    publish,
    revert,
  }), [spaceId, projectId, sessionId, activeCanvasId, hasChanges, saving, publishing,
    canvases, reverting, changeActiveCanvas, _createCanvasDraft, _duplicateCanvasDraft,
    _deleteCanvasDraft, saveCanvasDraft, saveSessionDraft, updateCanvasDraft,
    updateSessionDraft, subscribeToCanvas, subscribeToSession, subscribeToCanvases,
    publish, revert, isDraftOnly,]);

  return (
    <SessionDraftContext.Provider value={value}>
      {children}
    </SessionDraftContext.Provider>
  );
};
