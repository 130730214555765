import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import Button from '../../common/Button';
import React, { useCallback } from 'react';

interface ClearAnswerButtonProps {
  hasAnswer: boolean;
  onClear?: () => void;
}
/**
 * Clear answer button used with canvas questions to erase the user's answer.
 *
 * @param {ClearAnswerButtonProps} props
 * @return {JSX.Element}
 */
export const ClearAnswerButton = ({
  hasAnswer,
  onClear,
}: ClearAnswerButtonProps) => {
  const {
    t,
  } = useTranslation();

  const handlePress = useCallback((event: React.MouseEvent) => {
    onClear?.();
    event.stopPropagation();
    event.preventDefault();
  }, [onClear,]);

  return <motion.div
    key="delete-answer"
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }}
    initial={{
      scale: 0,
    }}
    animate={{
      scale: 1,
      opacity: hasAnswer ? 1 : 0,
      transition: {
        duration: 0.3,
      },
    }}
    exit={{
      scale: 0,
      height: 0,
      maxHeight: 0,
    }}
    transition={{
      duration: 0.3,
    }}
  >
    <Button
      key="clear-button"
      disabled={!hasAnswer}
      onClick={handlePress}
    >{t('clear-answer')}
    </Button>
  </motion.div>;
};
