import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef } from 'react';
import Center from 'components/common/layout/Center';
import styled from '@emotion/styled';

const Container = styled(motion.div)(({
  theme,
}) => `
  cursor: pointer;
  position: absolute;
  background-color: ${theme.palette.primary.main};
  border-radius: 50%;
  font-size: 0.75rem;
  aspect-ratio: 1;
  top: -0.1rem;
  right: -0.1rem;
  padding: 0.2rem;
`);

interface CommentReplyBadgeProps {
  value?: number;
}

export const CommentReplyBadge = ({
  value,
}: CommentReplyBadgeProps) => {
  const lastValue = useRef<number | undefined>();
  const animation = useAnimation();
  const animating = useRef(false);

  // Make an animation pulse, when value changes
  useEffect(() => {
    if (animating.current) {
      return;
    }
    const animate = async () => {
      if (lastValue.current && lastValue.current !== value) {
        try {
          animating.current = true;
          await animation.start({
            scale: [1.5, 1,],
          });
        } finally {
          animating.current = false;
        }
      }
      lastValue.current = value;
    };
    animate();
  }, [animation, value,]);

  if (!value) {
    return null;
  }
  return <Container
    animate={animation}
    transition={{
      duration: 1,
      type: 'spring',
    }}
  >
    <Center>
      {value}
    </Center>
  </Container>;
};
