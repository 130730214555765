import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import MessagesFeed from '../../feed/MessagesFeed';
import NotificationsFeed from '../../feed/NotificationsFeed';
import ProjectFeed from '../../feed/ProjectFeed';
import React from 'react';
import styled from '@emotion/styled';
import type { DrawerMenuItemId } from '.';

const ModalViewContainer = styled(motion.div)(({
  theme,
}) => `
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.palette.background.paper};
`);

interface FeedViewsProps {
  selected?: DrawerMenuItemId;
}

const FeedViews = ({
  selected,
}: FeedViewsProps) => {
  const {
    t,
  } = useTranslation();

  switch (selected) {
    case 'bookmarks': {
      return <ModalViewContainer layoutId={`p_${selected}`}>
        {t('bookmarks')}
      </ModalViewContainer>;
    }
    case 'notifications': {
      return <ModalViewContainer layoutId={`p_${selected}`}>
        <NotificationsFeed />
      </ModalViewContainer>;
    }

    case 'project-feed': {
      return <ModalViewContainer layoutId={`p_${selected}`}>
        <ProjectFeed />
      </ModalViewContainer>;
    }

    case 'messages': {
      return <ModalViewContainer layoutId={`p_${selected}`}>
        <MessagesFeed />
      </ModalViewContainer>;
    }

    default:
      return null;
  }
};

export default FeedViews;
