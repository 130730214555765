import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

import CompositePropertyEditorControl from '../CompositePropertyEditorControl';
import type { AnalyzeOptions } from '@shared/schema/src';
import type { AnalyzeOptionsPropertyEditor } from '../PropertyEditorTypes';
import type { PropertyConfig } from 'components/builder/builders/componentBuilder';

const AnalyzeOptionsConfig: PropertyConfig<AnalyzeOptions> = {
  stats: {
    type: 'characteristics',
    defaultValue: {},
  },
  showValues: {
    type: 'checker',
    displayName: 'Show values with legend',
    defaultValue: false,
  },
};

export const AnalyzeOptionsEditor: PropertyEditorFunction<AnalyzeOptions, AnalyzeOptionsPropertyEditor> = ({
  data,
  component,
  propertyName,
  propertyNamePath,
  propertyConfig: {
    defaultValue,
  },
  onDelete,
  onSave,
}) => {
  return <CompositePropertyEditorControl
    component={component}
    propertyName={propertyName}
    propertyNamePath={propertyNamePath}
    data={data ?? defaultValue ?? {}}
    config={AnalyzeOptionsConfig}
    onDelete={onDelete}
    onSave={onSave}
  />;
};

AnalyzeOptionsEditor.config = {
  type: 'analyze-options',
  displayName: 'Analysis',
};

export default AnalyzeOptionsEditor;
