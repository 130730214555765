import { hasUserRoleChanged } from './hasUserRoleChanged';
import { platformEventSystem } from 'components/platformEvents/PlatformEventSystem';
import type { AuthContext } from '..';
import type { ShortUserRoleId } from '@shared/schema/src/schema';
import type { User } from '@shared/schema/src';

export const sendPlatformMessageWithPlatformUserInfo = (context: AuthContext, user: User<ShortUserRoleId>) => {
  if (hasUserRoleChanged(context, user)) {
    platformEventSystem.sendPlatformEvent({
      type: 'user-auth-changed',
    });
  }
};
