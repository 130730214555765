/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */

import { COMMAND_PRIORITY_HIGH, KEY_ENTER_COMMAND } from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { useCallback, useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { CommandListener, LexicalEditor } from 'lexical';

interface SubmitPluginProps {
  onSubmit: (editor: LexicalEditor) => void;
}
/**
 * Custom plugin to submit the editor content on Ctrl+Enter or Cmd+Enter
 */
const SubmitPlugin = ({
  onSubmit,
}: SubmitPluginProps) => {
  const [editor,] = useLexicalComposerContext();

  const onEnter: CommandListener<KeyboardEvent> = useCallback(
    (event, editor) => {
      const {
        ctrlKey,
        metaKey,
      } = event;

      if (ctrlKey || metaKey) {
        event.preventDefault();
        event.stopImmediatePropagation();
        onSubmit(editor);
        return true;
      }
      return false;
    },
    [onSubmit,]
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        onEnter,
        COMMAND_PRIORITY_HIGH)
    );
  }, [editor, onEnter,]);

  return null;
};

export default SubmitPlugin;
