import FormControl from '@mui/material/FormControl';

import { NumericFormat } from 'react-number-format';
import { TextField, Typography } from '@mui/material';
import {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'next-i18next';
import React from 'react';
import type { CurrencyPropertyEditor } from 'components/builder/propertyEditors/PropertyEditorTypes';
import type { KeyboardEventHandler } from 'react';
import type { NumericFormatProps } from 'react-number-format';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

// import CurrencyTextField from '@unicef/material-ui-currency-textfield';
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const {
      onChange, ...other
    } = props;

    const {
      i18n,
    } = useTranslation();

    const {
      decimalSeparator,
      thousandSeparator,

    } = useMemo(() => {
      const language = (i18n as any)?.language;
      return {
        decimalSeparator: language === 'fi' ? ',' : '.',
        thousandSeparator: language === 'fi' ? '.' : ',',
      };
    }, [i18n,]);

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        decimalScale={2}
        fixedDecimalScale
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        valueIsNumericString
        prefix="€"
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  }
);

const CurrencyEditor: PropertyEditorFunction<number, CurrencyPropertyEditor> = ({
  data,
  propertyName,
  propertyConfig: {
    autoFocus,
    displayName,
    defaultValue,
    section,
  },
  onSave,
}) => {
  const numberToString = useCallback((value: number): string => {
    if (!value && value !== 0) {
      return '';
    }
    return value.toFixed(2);
  }, []);

  const stringToNumber = useCallback((value: string): number | undefined => {
    if (!value) {
      return undefined;
    }
    return parseFloat(value);
  }, []);

  const [value, setValue,] = useState<string>(numberToString(data ??
    typeof defaultValue === 'number' ? defaultValue! / 100 : 0
  ));
  const ref = useRef<HTMLInputElement>(null);
  const [editing, setEditing,] = useState(false);

  // TODO: input validation
  useEffect(() => {
    if (editing) {
      return;
    }
    const currency = (data ?? defaultValue) / 100;
    const v = numberToString(currency);

    if (value !== v) {
      setValue(v);
    }
  }, [data, defaultValue, editing, numberToString, value,]);

  const handleSave = useCallback((value: string) => {
    const newValue = value?.trim();

    const v = stringToNumber(newValue);
    if (Number.isNaN(v) || v === undefined) {
      return;
    }
    if (v !== data && onSave) {
      onSave(propertyName, v * 100);
    }
  }, [data, onSave, propertyName, stringToNumber,]);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback((event) => {
    const value = event.target.value;
    setValue(value);
    handleSave(value);
  }, [handleSave,]);

  const handleFocus = useCallback((event: any) => {
    event.target.select();
    setEditing(true);
  }, []);

  const handleBlur = useCallback(() => {
    setEditing(false);
    handleSave(value);
  }, [handleSave, value,]);

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      handleSave(value);
    }
  }, [handleSave, value,]);

  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [autoFocus,]);

  return <>
    {
      section &&
      <>
        <Typography component={'span'}
          variant="h4"
          id={`${value}-label`}>{section}
        </Typography>
        <br />
      </>
    }
    <FormControl fullWidth>
      <TextField
        ref={ref}
        variant="outlined"
        InputProps={{
          inputComponent: NumericFormatCustom as any,
        }}
        placeholder={propertyName}
        label={displayName ?? propertyName}
        id={propertyName}
        value={value}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        fullWidth
      />
    </FormControl>
  </>;
};

CurrencyEditor.config = {
  type: 'currency',
};

export default CurrencyEditor;
