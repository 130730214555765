/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import useFirestoreErrorHandler from '../../utils/hooks/useFirestoreErrorHandler';
import usePathParams from '../../utils/hooks/usePathParams';
import type { BlocErrorFunction } from '../../@mindhiveoy/bloc/types';
import type { DocumentData } from '@mindhiveoy/firebase-schema';
import type BlocBase from '../bloc/BlocBase';

interface CreateBlocArgs<P> {
  params: P;
  onError?: BlocErrorFunction;
}

/**
 * useBlocHook helper function to create bloc document specific hooks.
 *
 * @template T The type of the document data.
 * @template P The type of the params passed to the bloc.
 * @template B The type of the bloc ie. class name.
 *
 * @param {*} createBloc  Bloc create function.
 * @return {B}            Return the bloc
 */
export const createUseBlocHook = <
  T extends DocumentData,
  P,
  B extends BlocBase<T, P>,
>
  (
    createBloc: (args: CreateBlocArgs<P>) => B
  ) => {
  return (
    inputParams?: Partial<P>,
    isReady = true
  ): B => {
    const params = usePathParams(inputParams as any) as unknown as P;

    const onError = useFirestoreErrorHandler();

    const bloc = useRef<B | null>(
      null
    );

    if (bloc.current === null && isReady) {
      bloc.current = createBloc({
        params,
        onError,
      });
    }

    const _bloc = bloc.current;
    if (_bloc && _bloc.areParamsEqual(params)) {
      return bloc.current as B;
    }
    _bloc?.dispose();

    if (!isReady) {
      return bloc.current as B;
    }

    bloc.current = createBloc({
      params,
      onError,
    });

    return bloc.current as B;
  };
};
