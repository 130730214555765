import FormControl from '@mui/material/FormControl';

import type { PropertyEditorFunction } from '../../../builders/propertyEditorBuilder';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { ALL_STYLES_ALLOWED } from 'components/editing/HTMLInplaceEditor';
import { FieldSet } from 'components/forms/FieldSet';
import { Legend } from 'components/forms/Legend';
import type { HTMLPropertyEditor } from 'components/builder/propertyEditors/PropertyEditorTypes';

import { capitalizeFirstLetter } from 'utils/common';
import { useHTMLEditorContext } from './HTMLEditorContext';
import LexicalInplaceEditor from 'components/editing/LexicalInplaceEditor';
import styled from '@emotion/styled';

const StyledFormControl = styled(FormControl)(({
  theme,
}) => `
  margin-bottom: ${theme.spacing(2)};
`);

const HTMLEditor: PropertyEditorFunction<string, HTMLPropertyEditor> = ({
  data,
  disabled,
  propertyName,
  propertyConfig: {
    displayName,
    defaultValue,
    rows = 1,
    border = 'none',
  },
  onSave,
}) => {
  const [value, setValue,] = useState(!data ? defaultValue : data);
  const [editing, setEditing,] = useState(false);

  const htmlEditorContent = useHTMLEditorContext();

  useEffect(() => {
    if (editing) {
      return;
    }
    setValue((value) => data !== value ? data : value);
  }, [data, editing,]);

  const handleSave = useCallback((html: string) => {
    if (html !== data && onSave) {
      onSave(propertyName, html);
    }
  }, [data, onSave, propertyName,]);

  const handleBlur = useCallback(() => {
    setEditing(false);
  }, []);

  const handleChange = useCallback((html: string | undefined) => {
    setValue((value) => html !== value ? html : value);
    if (!html || html === '<p><br /></p>') {
      // NOTE: This is a workaround for the LexicalInplaceEditor not allowing empty content and
      //       and causing the editor to trigger change event in the initial render.
      return;
    }
    handleSave(html);
  }, [handleSave,]);

  const label = useMemo(
    () => capitalizeFirstLetter(displayName || propertyName === 'desc' ? 'description' : ''),
    [displayName, propertyName,]);

  const style = useMemo(() => ({
    width: '100%',
    minHeight: `${rows * 1.5}rem`,
    maxHeight: '30dvh',
  }), [rows,]);

  return <StyledFormControl
    fullWidth
    disabled={disabled}
    id={propertyName}>
    <FieldSet>
      <Legend>{label}</Legend>
      <LexicalInplaceEditor
        allowedStyles={ALL_STYLES_ALLOWED}
        border={border}
        fixedToolbar={htmlEditorContent.fixedToolbar}
        forceEdit
        initialHtml={value}
        showToolbar
        style={style}
        onHtmlChange={handleChange}
        onBlur={handleBlur}
      />
    </FieldSet>
  </StyledFormControl>;
};

HTMLEditor.config = {
  type: 'html',
};

export default HTMLEditor;
