import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { firebaseApp } from '../../schema';
import { getKeyPathString } from './getKeyPathString';
import type { CanvasParams, ProjectParams, SessionParams, SpaceParams } from '../../@shared/schema/src';
import type { Firestore } from 'firebase/firestore';
import type { UTCTime } from '@mindhiveoy/schema';
/**
 * The cache key path defines the path to the element in the cache using
 * ids of the space, project, session, and canvas, depending on the element
 * depth in the application.
 */
export type CacheKeyPath = SpaceParams & Partial<ProjectParams> & Partial<SessionParams> & Partial<CanvasParams>;

export const ELEMENT_CACHE_LOCAL_STORAGE_KEY = 'ElementCache';

type KeyField = keyof CanvasParams;

/**
 * The type of the item in the cache.
 *
 * - 'd': data
 * - 'e': error   Error will be stored in the cache to make sure that the new database call is not
 *                made for the same element repeatedly.
 */
type ItemType = 'd' | 'e';

interface StorageItem<T> {
  /**
   * The type of the item in the cache.
   */
  type: ItemType;
  /**
   * The text representation of the item in the cache.
   */
  data: T;
  /**
   * The time when the item was last updated in the cache.
   */
  updated: UTCTime;
}

interface CacheItem<T> {
  /**
   * The key of the item in the cache.
   */
  key: string;
  /**
   * The item in the cache.
   */
  item: StorageItem<T>;
}
/**
 * Extract the CacheKeyPath to a string that will be used as a key in the cache. The key
 * format is to concatenate the ids of the elements in the key path and separate them with
 *
 */
const CACHE_EXPIRATION_TIME = 7 * 24 * 60 * 60 * 1000;

export const keyPathFieldNames: KeyField[] = ['spaceId', 'projectId', 'sessionId', 'canvasId',];

export const keyPathCollectionNames: CacheKeyPath = {
  spaceId: 'spaces',
  projectId: 'projects',
  sessionId: 'sessions',
  canvasId: 'canvases',
};

export type ElementCacheCallback<T> = (text: T) => void;

interface DataRecord {
  name: string;
}

interface GetArgs<T> {
  keyPath: CacheKeyPath;
  defaultValue: T;
}

interface SetArgs<T> {
  keyPath: CacheKeyPath;
  data: T;
}
/**
 * ElementCache will store elements needed regularly in the application. The key
 * motivation is to limit the number database queries and to improve the performance
 * and limit the cost of the application.
 *
 * The cache will consist store data in memory and in the local storage. The data
 * will be hashed and stored in the local storage. The data will be stored in memory
 * as long as the application is running. The data will be stored in the local storage
 * to persist the data between sessions.
 *
 * Cached elements are retrieved by the key of the collection and the id of the element.
 *
 * If the element is not found in the cache, the element will be fetched from the database
 * and stored in the cache.
 */
export class ElementCache<T> {
  private cache: Map<string, StorageItem<T>> = new Map();

  private firestore: Firestore;

  private observers: Map<string, ElementCacheCallback<T>[]> = new Map();

  /**
   * Constructor for initializing the cache expiration time.
   *
   * @param {function} cleanOut - the function to clean out the data for the cache. This function
   *                              will be called before storing the data in the cache.
   * @param {number} cacheExpirationTime - the expiration time for cache
   */
  constructor(
    private cleanOut: <D extends T>(data: D) => T,
    private cacheExpirationTime: number = CACHE_EXPIRATION_TIME
  ) {
    this.firestore = getFirestore(firebaseApp());

    this.loadCacheFromLocalStorage();
  }
  /**
   * Get an element from the cache.
   *
   * @param collection The collection to get the element from.
   * @param defaultValue The default value to return if the element is not found in the cache.
   */
  public get = async ({
    keyPath,
    defaultValue,
  }: GetArgs<T>) => {
    const key = getKeyPathString(keyPath);
    const element = this.cache.get(key);

    return this.resolveElement(
      element,
      keyPath,
      defaultValue
    );
  };

  /**
   * Set elements to the cache and notify the observers. The elements are stored in the cache
   * with the key of the collection and the id of the element.
   *
   * @param args The arguments for setting the elements to the cache.
   * @param args.keyPath The key path of the element to set.
   * @param args.data The data of the element to set.
   */
  public set = (args: SetArgs<T> | SetArgs<T>[]) => {
    const _arguments = Array.isArray(args) ? args : [args,];

    const items = _arguments.map(({
      keyPath,
      data,
    }) => {
      const key = getKeyPathString(keyPath);

      const item: StorageItem<T> = {
        type: 'd',
        data: this.cleanOut(data),
        updated: Date.now(),
      };

      return {
        key,
        item,
      };
    });

    this.saveItems(items);
  };

  /**
   * Save cache items to the cache and notify the observers.
   *
   * @param cacheItems
   */
  private saveItems = (itemsOrKey: string | CacheItem<T>[], item?: StorageItem<T>) => {
    const cacheItems = typeof itemsOrKey === 'string' ? [{
      key: itemsOrKey,
      item,
    },] as CacheItem<T>[] : itemsOrKey;

    cacheItems.forEach(({
      key,
      item,
    }) => {
      if (!item) {
        return;
      }

      this.cache.set(key, {
        ...item,
        data: this.cleanOut(item.data),
        updated: Date.now(),
      });
    });

    this.saveElementToLocalStorage();

    cacheItems.forEach(({
      key,
      item,
    }) => {
      if (item.type === 'd') {
        this.notifyObservers(key, item.data);
      }
    });
  };

  /**
   * Notify the observers of the cache. This is used to notify the observers of the cache
   * when the cache item is updated.
   *
   * @param elementKey    The key of the element to notify the observers of.
   * @param data          The data of the element to notify the observers of.
   */
  private notifyObservers = (elementKey: string, data: T) => {
    const observers = this.observers.get(elementKey);
    if (observers) {
      observers.forEach((observer) => observer(data));
    }
  };

  /**
   * Notify all observers of the cache. This is used to notify all observers of the cache
   * when the cache is loaded from the local storage.
   */
  private notifyAllObservers = () => {
    this.observers.forEach((observers, key) => {
      const element = this.cache.get(key);
      if (element?.type === 'd') {
        observers.forEach((observer) => observer(element.data));
      }
    });
  };

  /**
   * Subscribe to the cache item with the given keyPath and id. The callback will be
   * called with the text content of the cache item if the item is found in the cache.
   *
   * @param keyPath
   * @param id
   * @param callback
   */
  public subscribe = (
    keyPath: CacheKeyPath,
    callback: (data: T) => void
  ) => {
    const key = getKeyPathString(keyPath);

    const observers = this.observers.get(key) ?? [];

    if (!observers.includes(callback)) {
      observers.push(callback);
    }
    this.observers.set(key, observers);

    const element = this.cache.get(key);
    if (element) {
      callback(element.data);
    }

    return () => {
      const observers = this.observers.get(key);
      if (!observers) {
        return;
      }
      observers?.splice(observers.indexOf(callback), 1);
      if (observers.length === 0) {
        this.observers.delete(key);
      }
    };
  };

  private resolveElement = async (
    element: StorageItem<T> | undefined,
    keyPath: CacheKeyPath,
    defaultValue: T
  ): Promise<T> => {
    switch (this.resolveProcedure(element)) {
      case 'fetch':
        const fetchedElement = await this.fetchElement(keyPath, defaultValue);
        return this.resolveElement(fetchedElement, keyPath, defaultValue);
      case 'error':
        return defaultValue;
      case 'text':
      default:
        return element ? element.data : defaultValue;
    }
  };

  /**
   * Fetch an element from the database and store it in the cache.
   *
   * @param keyPath The collection to get the element from.
   * @param defaultValue The default value to return if the element is not found in the database.
   * @returns The element from the database or the default value if not found.
   */
  private fetchElement = async (
    keyPath: CacheKeyPath,
    defaultValue = {} as T
  ): Promise<StorageItem<T> | undefined> => {
    const key = getKeyPathString(keyPath);

    try {
      const ref = this.generateFirestoreRef(keyPath);

      const doc = await getDoc(ref);
      const data = doc.data();

      const element: StorageItem<T> = data ? {
        type: 'd',
        data: this.cleanOut(data as T),
        updated: Date.now(),
      } : {
        type: 'e',
        data: defaultValue,
        updated: Date.now(),
      };

      this.saveItems(key, element);
      return element;
    } catch (error) {
      const element: StorageItem<T> = {
        type: 'e',
        data: defaultValue,
        updated: Date.now(),
      };

      this.saveItems(key, element);
      return element;
    }
  };

  private generateFirestoreRef = (keyPath: CacheKeyPath) => {
    return doc(this.firestore, this.generateCollectionPath(keyPath));
  };

  private generateCollectionPath = (keyPath: CacheKeyPath) => {
    let collectionPath = '';

    for (const key of keyPathFieldNames) {
      const value = keyPath[key];
      if (value) {
        collectionPath += `/${keyPathCollectionNames[key]}/${value}`;
        continue;
      }
      break;
    }
    return collectionPath;
  };

  private resolveProcedure = (element: StorageItem<T> | undefined) => {
    if (!element) {
      return 'fetch';
    }

    if (element.type === 'e') {
      return 'error';
    }

    if (this.isExpired(element)) {
      return 'fetch';
    }
    return 'text';
  };

  private isExpired = (element: StorageItem<T>) => {
    return Date.now() - element.updated > this.cacheExpirationTime;
  };

  private saveElementToLocalStorage = () => {
    const array = Array.from(this.cache.entries());
    // filter out the expired elements
    const filteredArray = array.filter(([, element,]) => !this.isExpired(element));

    localStorage.setItem(ELEMENT_CACHE_LOCAL_STORAGE_KEY, JSON.stringify(filteredArray));
  };

  private loadCacheFromLocalStorage = () => {
    if (typeof localStorage === 'undefined') {
      return;
    }
    const cacheString = localStorage.getItem(ELEMENT_CACHE_LOCAL_STORAGE_KEY);
    if (!cacheString) {
      this.cache = new Map();
      return;
    }

    const cacheArray = JSON.parse(cacheString) as CacheItem<T>[];

    this.cache = new Map();

    const prepareItem = (item: StorageItem<T>) => {
      switch (item.type) {
        case 'd':
          return {
            ...item,
            data: this.cleanOut(item.data),
          };
        case 'e':
        default:
          return item;
      }
    };

    cacheArray.forEach(({
      key,
      item,
    }) => {
      if (!item) {
        return;
      }
      this.cache.set(
        key,
        prepareItem(item)
      );
    });

    this.notifyAllObservers();
  };
}

const elementCache = new ElementCache<DataRecord>(
  (data) => {
    return {
      name: data.name,
    };
  }
);

export default elementCache;
