/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import type { CSSProperties } from '@emotion/serialize';

import { useTheme } from '@mui/material/styles';
import InplaceEditor from '../../../../editing/InplaceEditor';
import styled from '@emotion/styled';
import type { TextProps } from '../../renderers/TextWidget';

const StyledTypography = styled(Typography)`
  position: relative;
`;

export interface TextWidgetEditorProps {
  data: TextProps;
  onSave?: (field: keyof TextProps, value: string) => void;
}

const TextWidgetEditorPure = ({
  data: {
    text = 'insert text here',
    textAlign = 'left',
    variant = 'body1',
    color,
  },
  onSave,
}: TextWidgetEditorProps) => {
  const theme = useTheme();

  const style: CSSProperties = useMemo(() => {
    const style: CSSProperties = theme.typography ?
      // eslint-disable-next-line security/detect-object-injection
      (theme.typography as any)[variant] :
      {
        fontSize: 16,
      };

    return {
      ...style,
      color,
      textAlign: textAlign as any,
      wordWrap: 'break-word',
      maxWidth: '100%',
    };
  }, [color, textAlign, theme.typography, variant,]);

  const handleSave = useCallback((_name: string, value: string) => {
    onSave?.(_name as keyof TextProps, value);
  }, [onSave,]);

  return <StyledTypography variant={variant}>
    <InplaceEditor
      name='text'
      value={text}
      multiline
      style={style as any}
      onEditDone={handleSave}
    />
  </StyledTypography>;
};

export default TextWidgetEditorPure;
