import type { MentionInfo } from '@shared/schema/src';

export interface CommentAnalysisResult {
  mentions: MentionInfo[];
  links: string[];
  hashTags: string[];
}

/**
 * This helper function converts the Lexical nodes to a more usable format.
 */
export const convertCommentNodes = (nodes: any[]): CommentAnalysisResult => {
  const mentions: MentionInfo[] = [];
  const links: string[] = [];
  const hashTags: string[] = [];

  nodes?.forEach((node) => {
    switch (node.type) {
      case 'mention':
        node.mentionName?.length &&
          mentions.push({
            nick: node.mentionName,
            id: node.uid ?? '',
          });
        break;
      case 'autolink':
        node.url?.length && links.push(node.url);
        break;
      case 'hashtag':
        node.text?.length && hashTags.push(node.text);
        break;
    }
  });

  return {
    mentions,
    links,
    hashTags,
  };
};
