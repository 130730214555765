import { ToolbarIcon } from './ToolbarIcon';
import { useCallback } from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.button<{
  $active?: boolean;
}>(({
  theme,
}) => `
  border: 0;
  display: flex;
  background: none;
  padding: 6px;
  cursor: pointer;
  vertical-align: middle;
  color: ${theme.palette.text.primary}; 
`);
export interface ToolbarButtonProps {
  active?: boolean;
  disabled?: boolean;
  image: 'undo' | 'redo' | 'bold' | 'italic' | 'underline' | 'strikethrough' | 'bullet-list' | 'numbered-list' | 'send';
  onClick: () => void;
  'ariaLabel': string;
}
export const ToolbarButton = ({
  active,
  ariaLabel,
  image,
  onClick,
}: ToolbarButtonProps) => {
  const handleClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onClick();
  }, [onClick,]);

  return <StyledButton
    onClick={handleClick}
    $active={active}
    aria-label={ariaLabel}
  >
    <ToolbarIcon
      image={image}
      active={active} />
  </StyledButton>;
};
