/**
 * Detect if to arrays of objects with id's are in the same order
 * @param {T[]} items   - The first array
 * @param {T[]} _items - The second array
 * @param {*}   match  - A function to match the items
 * @return   {boolean} - True if the arrays are in the same order
 */
export function isItemOrderSame<T = {
  id: string;
} & Record<string, unknown>>(
  items: T[], _items: T[],
  match: (a: T, b: T) => boolean) {
  if (items.length !== _items.length) {
    return false;
  }
  for (let i = 0; i < items.length; i++) {
    if (!match(items[i], _items[i])) {
      return false;
    }
  }
  return true;
}
