/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import type { CanvasId, ProjectId, SessionId } from '@shared/schema/src';
import type { SpaceId } from '@mindhiveoy/schema';

interface PresentationScreenContextType {
  spaceId: SpaceId;
  projectId: ProjectId;
  sessionId: SessionId;
  canvasId: CanvasId;
}

export const PresentationScreenContext = React.createContext<PresentationScreenContextType | null>(null);

interface PresentationScreenContextProps {
  initialValue: Partial<PresentationScreenContextType>
}

export const PresentationScreenContextProvider = ({
  children,
  initialValue,
}: React.PropsWithChildren<PresentationScreenContextProps>) => {
  const current = usePresentationScreenContext();

  const value = useRef({
    spaceId: initialValue.spaceId ?? current?.spaceId,
    projectId: initialValue.projectId ?? current?.projectId,
    sessionId: initialValue.sessionId ?? current?.sessionId,
    canvasId: initialValue.canvasId ?? current?.canvasId,
  });

  Object.keys(initialValue).forEach(
    (key) => {
      if ((value.current as any)[key] !== (initialValue as any)[key]) {
        (value.current as any)[key] = (initialValue as any)[key]!;
      }
    });

  return <PresentationScreenContext.Provider value={value.current as any}>
    {children}
  </PresentationScreenContext.Provider>;
};

export const usePresentationScreenContext = () => {
  return React.useContext(PresentationScreenContext);
};
