import { aggregateStats } from '../../../utils/aggregateStats';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import type { AnalyzeOptions, Statistics } from '@shared/schema/src';

const LegendFrame = styled.div(({
  theme,
}) => `
  position: relative;
  width: 100%;
  margin: auto;
  height: min-content;
  min-height: 40px;
  min-width: 100px;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  border-radius: 12px;
  border: solid #4e4d4f 2px;
  background-color: rgba(32,32,32,0.6);
  padding: ${theme.spacing(1)};
  gap: ${5};
`);

const LegendCue = styled$.div<{
  $color: string;
}>(({
  $color = 'blue',
}) => `
  width: 1rem;
  height: 1rem;
  background-color: ${$color};
  border-radius: 4px;
`);

// TODO: Use a table
const LegendCell = styled.td`
  vertical-align: middle;
  padding: 2px;
`;

const LegendLabel = styled.span(({
  theme,
}) => `
  margin: auto;
  text-align: left;
  white-space: nowrap;
  margin-left: ${theme.spacing(1)};
`);

const LegendValue = styled.span(({
  theme,
}) => `
  margin: auto;
  text-align: left;
  white-space: nowrap;
  color: rgba(192,192,192,0.7);
  margin-left: ${theme.spacing(1)};
`);

const LegendItem = styled.tr`
  min-height: 1rem;
  width: 100%;
  position: relative;
`;

const Stats = styled.table(({
  theme,
}) => `
  grid-gap: ${theme.spacing(1)};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr)); /* see notes below */
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
`);

interface StatisticLegendContainerProps {
  statistics?: Statistics;
  analyzeOptions?: AnalyzeOptions;
}
const StatisticLegend = ({
  statistics,
  analyzeOptions,
}: StatisticLegendContainerProps) => {
  const stats = useMemo(() => {
    if (!analyzeOptions?.stats || !statistics) {
      return null;
    }

    return aggregateStats({
      data: statistics,
      characteristics: analyzeOptions.stats,
    });
  }, [analyzeOptions?.stats, statistics,]);

  if (!stats) {
    return null;
  }
  return <LegendFrame>
    <Stats>
      {stats.map(
        ({
          label,
          asText: value,
          color,
        }) =>
          <LegendItem key={label}>
            <LegendCell>
              <LegendCue $color={color} />
            </LegendCell>
            <LegendCell>
              <LegendLabel>
                {label}
              </LegendLabel>
            </LegendCell>
            {analyzeOptions?.showValues && <LegendCell>
              <LegendValue>
                {value}
              </LegendValue>
            </LegendCell>}
          </LegendItem>
      )}
    </Stats>
  </LegendFrame>;
};

export default StatisticLegend;
