import { deserializeSession } from './utils/serializers';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { firebaseApp } from 'schema';
import { forceBackendCalls } from './draftAPI';
import type { ProjectId, Session, SessionId } from '@shared/schema/src';
import type { SaveCanvasDraftRequest } from '@shared/schema/src/https/session/saveCanvasDraft';
import type { SaveSessionDraftRequest } from '@shared/schema/src/https/session/saveSessionDraft';
import type { SessionObserverFunction } from './SessionDraftContextType';
import type { SpaceId, WithId } from '@mindhiveoy/schema';

const firestore = getFirestore(firebaseApp());

let currentSessionIdentifier: string;
// let sessionSnapshotCallback: SessionObserverFunction;
// let sessionDraftSnapshotCallback: SessionObserverFunction;
let unsubscribeSession: (() => void) | null;
let unsubscribeSessionDraft: (() => void) | null;
let hasDraft = false;

let session: WithId<Session> | undefined;

export const sessionListener = (
  spaceId: SpaceId,
  projectId: ProjectId,
  sessionId: SessionId,
  onSessionSnapshot: SessionObserverFunction
) => {
  // sessionSnapshotCallback = onSessionSnapshot;
  // sessionDraftSnapshotCallback = onSessionDraftSnapshot;
  const sessionIdentifier = `${spaceId}/${projectId}/${sessionId}`;

  if (currentSessionIdentifier === sessionIdentifier && session) {
    return;
  }

  currentSessionIdentifier = sessionIdentifier;
  disposeSessionListener();

  const projectPath = `spaces/${spaceId}/projects/${projectId}`;

  const sessionDraftRef = doc(firestore, `${projectPath}/sessionDrafts/${sessionId}`);
  const sessionRef = doc(firestore, `${projectPath}/sessions/${sessionId}`);

  const subscribeToActualSession = () => {
    return onSnapshot(
      sessionRef,
      (doc) => {
        const data = doc.data();
        if (hasDraft || !data) {
          return;
        }
        session = deserializeSession(sessionId, {
          ...doc.data(),
          _id: doc.id,
        });
        onSessionSnapshot(session);
      }
    );
  };

  // unsubscribeSession = subscribeToActualSession();

  unsubscribeSessionDraft = onSnapshot(
    sessionDraftRef,
    (doc) => {
      const data = doc.data();
      if (!data) {
        hasDraft = false;
        if (!unsubscribeSession) {
          unsubscribeSession = subscribeToActualSession();
        }
        return;
      }

      hasDraft = true;
      if (unsubscribeSession) {
        unsubscribeSession();
      }

      session = deserializeSession(sessionId, {
        ...data.content,
      });

      onSessionSnapshot(session);
    }
  );
};

export const disposeSessionListener = (
  canvasArgs?: SaveCanvasDraftRequest,
  sessionArgs?: SaveSessionDraftRequest
) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = () => { };

  unsubscribeSession?.();
  unsubscribeSession = null;
  unsubscribeSessionDraft?.();
  unsubscribeSessionDraft = null;
  if (canvasArgs && sessionArgs) {
    forceBackendCalls(canvasArgs, sessionArgs, noop);
  }
  currentSessionIdentifier = '';
  hasDraft = false;
};
