import { Canvas, Comment } from '../spaces/projects/sessions/canvases';
import { FirestoreTimestamp } from '../utils/firestoreMappings';
import { PathParams } from '../pathParams';
import { ProjectMemberInfo } from '../spaces/projects/project';
import { Session } from '../spaces/projects/sessions/session';
import { UTCTime, WithId } from '@mindhiveoy/schema';
import { UserRoleId } from '../schema';

export enum MessageAttachmentType {
  COMMENT = 'comment',
  CANVAS = 'canvas',
  SESSION = 'session',
  PROJECT = 'project',
  SPACE = 'space',
  PLATFORM = 'platform',
}

export interface MessageAttachmentBase {
  type: MessageAttachmentType;
  url: string;
  shortText: string;
}

export interface MessageAttachmentComment extends MessageAttachmentBase {
  type: MessageAttachmentType.COMMENT;
  longText?: string;
  selectedComment?: WithId<Comment>;
}

export interface MessageAttachmentCanvas extends MessageAttachmentBase {
  type: MessageAttachmentType.CANVAS;
  selectedCanvas?: WithId<Canvas>;
}

export interface MessageAttachmentSession extends MessageAttachmentBase {
  type: MessageAttachmentType.SESSION;
  selectedSession?: WithId<Session>;
}

export interface MessageAttachmentProject extends MessageAttachmentBase {
  type: MessageAttachmentType.PROJECT;
}

export type MessageAttachment = MessageAttachmentComment | MessageAttachmentCanvas | MessageAttachmentSession | MessageAttachmentProject;

export enum MessageRecipientsType {
  SELECTED = 'selected',
  FILTER = 'filter',
}

export enum MessageRecipientsOptionType {
  GROUP = 'group',
  PARTICIPANTS = 'participants',
}

export interface ProjectGroupInfo {
  id: string;
  name: string;
}

export interface MessageRecipientInputBase {
  type: MessageRecipientsOptionType;
}
export interface MessageRecipientGroup extends MessageRecipientInputBase {
  type: MessageRecipientsOptionType.GROUP;
  group?: ProjectGroupInfo;
}

export interface MessageRecipientParticipants extends MessageRecipientInputBase {
  type: MessageRecipientsOptionType.PARTICIPANTS;
  selectedRecipients?: ProjectMemberInfo[];
}

export type MessageRecipientInput = MessageRecipientGroup | MessageRecipientParticipants;

export interface MessageSender {
  member: MessageSenderWithRole;
  uid: string;
  email: string;
  displayName: string;
}

export interface MessageForm {
  subject: string;
  message: string;
  attachment?: MessageAttachment;
  recipients: MessageRecipientInput;
}

export interface Message extends MessageForm {
  scope: MessageScope;
  /**
   * The context of the message.
   */
  context: 'project' | 'space' | 'platform';
  /**
   * The path to the resource that the message is related to.
   */
  pathParams: PathParams;
  /**
   * The sender of the message.
   */
  sender?: MessageSender;
  /**
   * The time the message was created.
   */
  created?: UTCTime | FirestoreTimestamp;
}

export enum MessageScope {
  GENERAL,
  FILTER,
  DIRECT,
}

export interface MessageDoc {
  content: Message;
}

export interface UserMessageDoc {
  content: Message;
}

export interface ProjectMessageDoc extends MessageDoc { };

export interface UserMessageDoc extends MessageDoc { };


export interface MessageSenderWithRole extends ProjectMemberInfo {
  displayName: string;
  email: string;
  role: UserRoleId;
}

export enum ProcessedMessageType {
  RECEIVED = 'received',
  SENT = 'sent',
  BOTH = 'both',
}

export interface BaseProcessedMessage {
  type: ProcessedMessageType;
  sender: MessageSenderWithRole;
  date: UTCTime | FirestoreTimestamp;
  subject: string;
  message: string;
  path?: string;
  pathParams: PathParams;
  attachmentType?: MessageAttachmentType;
}

export interface ReceivedMessage extends BaseProcessedMessage {
  type: ProcessedMessageType.RECEIVED;
}

export interface SentMessage extends BaseProcessedMessage {
  type: ProcessedMessageType.SENT;
  recipients: ProjectMemberInfo[]; // we don't want to expose this to all recipients
  recipientsString: string;
}

export interface BothSentAndReceivedMessage extends Omit<SentMessage, 'type'> {
  type: ProcessedMessageType.BOTH;
}

export type ProcessedMessage =
  ReceivedMessage | SentMessage | BothSentAndReceivedMessage;

export interface ProcessedMessageDoc {
  content: ProcessedMessage;
  type: ProcessedMessageType;
}

