import { generateUserRoleContextShortcut } from '@mindhiveoy/auth';
import { updateMemberLoginInfo } from 'bloc/admin/member/membersApi';
import type { Member } from '@shared/schema/src';
import type { Membership, Memberships } from '@mindhiveoy/schema';
import type { UserContext, UserRoleContextDescriptor } from '@mindhiveoy/auth';

type MembershipListenerCallback = (membership?: Membership<any>) => void;

interface MembershipListener {
  user: any;
  userContext: UserContext;
  callback: MembershipListenerCallback;
}
/**
 * The interval to update the login info of the member in the context.
 */
const LOGIN_INFO_UPDATE_INTERVAL = 5 * 60 * 1000; // 5 minutes

/**
 * The RoleManager class is a singleton that manages the roles of the users in the browser context.
 */
class RoleManager<ShortUserRoleId> {
  private static _singleton?: RoleManager<any>;

  public static getInstance = (roleContext: UserRoleContextDescriptor<any, any>) => {
    if (!RoleManager._singleton) {
      RoleManager._singleton = new RoleManager(roleContext);
    }
    return RoleManager._singleton;
  };

  private _listeners: MembershipListener[] = [];
  private _memberships: Memberships<ShortUserRoleId> = {};

  /**
   *
   * @param {UserRoleContextDescriptor<any, any>} roleContext
   */
  private constructor(private roleContext: UserRoleContextDescriptor<any, any>) {
  }

  private memberShipUpdateCache = new Map<string, Date>();

  /**
   * Set the memberships of the user.
   *
   * @param memberships
   */
  public setMemberships(memberships: Memberships<ShortUserRoleId>) {
    this._memberships = memberships;

    this._listeners.forEach((listener) => {
      const membership = this.getMembership(
        listener.user, listener.userContext
      );
      listener.callback(membership);
    });
  }

  /**
   * Clear the memberships of the user.
   */
  public clearMemberships = () => {
    this._memberships = {};
    this._listeners = [];
  };

  /**
   * Get the membership of the user.
   *
   * @param userContext   The user context
   * @returns             The membership of the user in the context
   */
  public getMembership = (user: any, userContext: UserContext) => {
    const shortcut = generateUserRoleContextShortcut(this.roleContext, userContext);

    if (user?.role === 's') {
      return { // TODO Optimize this
        id: user.uid,
        role: 's',
        status: 'a',
        uid: user.uid,
      } as Member<ShortUserRoleId>;
    }

    const resolveMembership = () => {
      let membership = this._memberships[shortcut];
      if (!membership) {
        const index = shortcut.lastIndexOf('_');
        if (index > 0) {
          membership = this._memberships[shortcut.substring(0, index)];
          if (membership) {
            return membership;
          }
        }
      }
      return membership;
    };
    const membership = resolveMembership();

    if (!membership) {
      return undefined;
    }

    const lastUpdate = this.memberShipUpdateCache.get(shortcut);
    if (!lastUpdate || new Date().getTime() - lastUpdate.getTime() > LOGIN_INFO_UPDATE_INTERVAL) {
      this.memberShipUpdateCache.set(shortcut, new Date());
      // Trigger update of the login info for the member in this context
      updateMemberLoginInfo({
        memberId: membership.id,
        userContext,
      });
    }

    return membership;
  };

  public subscribe = (
    user: any,
    userContext: UserContext,
    callback: MembershipListenerCallback
  ) => {
    const index = this._listeners
      .findIndex((listener) => listener.userContext === userContext);

    if (index < 0) {
      this._listeners.push({
        user,
        userContext,
        callback,
      });
      const membership = this.getMembership(user, userContext);

      callback(membership);
    };
    return () => {
      this._listeners = this._listeners.filter((listener) => listener.callback !== callback);
    };
  };
}

export default RoleManager;
