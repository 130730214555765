import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import React, { useCallback, useEffect, useState } from 'react';
import type { PropertyEditorFunction } from '../../../builders/propertyEditorBuilder';

import { useSessions } from 'bloc/session/SessionsBloc';
import PropertyEditorControl from '../../PropertyEditorControl';
import Select from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material/Select';
import type { Session } from '@shared/schema/src';
import type { SessionPickerPropertyEditor } from '../../PropertyEditorTypes';
import type { WithId } from '@mindhiveoy/schema';

const SessionPickerEditor: PropertyEditorFunction<string, SessionPickerPropertyEditor> = ({
  data,
  propertyName,
  propertyConfig,
  onSave,
}) => {
  const [value, setValue,] = useState(data ?? propertyConfig.defaultValue);

  const sessionsBloc = useSessions();

  useEffect(() => {
    if (data && data !== value) {
      setValue(data);
    }
  }, [data, value,]);

  const [options, setOptions,] = useState<WithId<Session>[]>([]);

  useEffect(() => {
    return sessionsBloc.subscribe(setOptions);
  }, [sessionsBloc,]);

  const handleChange = useCallback((event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (newValue !== data && onSave) {
      onSave(propertyName, newValue);
    }
  }, [data, onSave, propertyName,]);

  const id = `editor-${ propertyName}`;

  return <PropertyEditorControl>
    <InputLabel id={`${id}-label`}>{propertyConfig.displayName ?? propertyName}</InputLabel>
    <Select
      labelId={`${id }-label`}
      id={`${id }-helper`}
      value={value}
      label={propertyName}
      onChange={handleChange}
    >
      { options.map(({
        _id, name,
      }) =>
        <MenuItem key={_id}
          value={_id}>{name}</MenuItem>
      )}
    </Select>
  </PropertyEditorControl>;
};

SessionPickerEditor.config = {
  type: 'session-picker',
  displayName: 'Session',
};

export default SessionPickerEditor;
