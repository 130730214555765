
export const prepareYouTubeUrl = (url?: string) => {
  if (!url) {
    return null;
  }
  url = url.trim();

  // https://youtu.be/_tuLd3h19Fw?si=8ltccE_RKy5Frp7e
  // https://www.youtube.com/watch?v=_tuLd3h19Fw&list=RD_tuLd3h19Fw&start_radio=1
  if (url.includes('youtube.com') || url.includes('watch')) {
    const videoIdRegex = /(?:\?|&)v=([^&]+)/;
    const match = url.match(videoIdRegex);
    if (match) {
      const videoId = match[1];
      //      https://youtu.be/_tuLd3h19Fw?si=iZZlLrcssHkQV3pl
      return `https://youtu.be/${videoId}?si=iZZlLrcssHkQV3pl`;
    }
  }

  if (url.includes('youtu.be')) {
    return url;
  }
  return null;
};
