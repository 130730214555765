import type { BuilderConfig, BuilderConfigPropsFunction, PropertyConfig, PropertyConfigs } from 'components/builder/builders/componentBuilder';
import type { PropertyEditor } from 'components/builder/propertyEditors/PropertyEditorTypes';

type MindError = {
  nature: 'error' | 'warning' | 'info';
  message: string;
  localeKey?: string;
};

type FormError = string | MindError;

export type FormErrors = {
  [propertyName: string]: FormError;
};

interface ValidatePropertyFormResult {
  isValid: boolean;
  errors?: FormErrors;
}

/**
 * Validates the form data against the property editors.
 *
 * @param config  The builder config
 * @param data    The form data
 * @returns       True if the form data is valid, false otherwise.
 */
export const validatePropertyForm = (config: BuilderConfig<any>, data: any): ValidatePropertyFormResult => {
  const propertyEditors: ([string, Partial<PropertyConfigs<any>> | BuilderConfigPropsFunction<any>][]) =
    config?.props ? Object.entries(config?.props) : [];

  const resolveEditor = (property: PropertyConfig<any> | BuilderConfigPropsFunction<any>): PropertyEditor => {
    if (typeof property === 'function') {
      return property(data, data) as Partial<PropertyConfigs<any>>;
    }
    return property;
  };

  let isValid = true;
  const errors: {
    [propertyName: string]: string;
  } = {};

  for (const [name, prop,] of propertyEditors) {
    const propertyEditor = resolveEditor(prop);

    if (propertyEditor.required && !data[name]) {
      isValid = false;
      errors[name] = propertyEditor.requiredMessage || 'This field is required';
      continue;
    }
    if (propertyEditor.validate) {
      const result = propertyEditor.validate(data[name]);
      if (result !== true) {
        isValid = false;
        errors[name] = result;
      }
    }
  }
  const result: ValidatePropertyFormResult = {
    isValid,
  };
  if (!isValid) {
    result.errors = errors;
  }
  return result;
};
