import { AnimatePresence, motion } from 'framer-motion';
import {
  createContext, useCallback, useContext, useMemo,
  useRef,
  useState
} from 'react';
import styled from '@emotion/styled';
import type { CSSProperties, PropsWithChildren } from 'react';

export type ViewSlideDirection = 'left-to-right' | 'right-to-left';

const transition = {
  type: 'tween',
  duration: 0.6,
};

const SlideContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

/**
 * The context type for the view slider component.
 */
export interface ViewSliderContextType<T extends ViewSliderModel = ViewSliderModel> {
  /**
   * The data model to define the context data for the child
   * components in a single slider view.
   */
  model: T;
  /**
   * The direction of the view slide.
   */
  direction: ViewSlideDirection;
}

// eslint-disable-next-line sonarjs/no-duplicate-string
const DEFAULT_DIRECTION = 'left-to-right';

const defaultStyle: CSSProperties = {
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
};

export interface ViewSliderModel {
  _id: string | number;
  /**
   * Indicates if the view is currently animating.
   */
  _isAnimating?: boolean;
}

/**
 * Props for the ViewSlider component.
 *
 * @template T - The type of the ViewSlider model.
 */
/**
 * Props for the ViewSlider component.
 */
interface ViewSliderProps<T extends ViewSliderModel> {
  /**
   * The data model to define the context data for the child
   * components in a single slider view.
   */
  model: T;
  /**
   * The direction of the view slide.
   */
  direction: React.RefObject<ViewSlideDirection>;
  /**
   * Additional styles for the ViewSlider component container.
   *
   * Note: The component will override the position field to be
   *       relative always to make the effect work.
   */
  style?: CSSProperties;
}

/**
 * This component manages child components within a sliding view, ensuring that they adhere
 * to specific guidelines to maintain the integrity of the slide effect and the application's state.
 *
 * Guidelines for Child Components:
 * - Child components must respect the width of the parent component's area. Elements rendering
 *   outside the visible area can disrupt the slide effect.
 * - Child components should derive their context solely from from the 'useViewSliderContext' -hook's model,
 *   ensuring consistency with the application's state at the initiation of the sliding action.
 * - Avoid dependency on external or independent variables (e.g., URL parameters, paths or global variables)
 *   that can change independently of the model state.
 *
 * @template T Extends ViewSliderModel. Represents the data model type that the component accepts.
 *             Each model must include a mandatory `_id` field as a unique identifier.
 *
 * Usage of Context Hook:
 * - Child components should use the `useViewSliderContext` hook to obtain the model context.
 *   This standardization ensures consistent state management across child components.
 *
 * Creating a Custom Context Hook:
 * - Developers can create a custom context hook if needed. The custom hook should cast the
 *   ViewSliderContext to use the specific type, ensuring type safety.
 *
 * ```typescript
 * export const useMyContext = () => {
 *   return useContext<MyContextType>(ViewSliderContext);
 * };
 * ```
 * @template T The type of the model associated with each view.
 * @param {ViewSliderProps<T>} props The component props.
 * @returns {JSX.Element} The rendered ViewSlider component.
 */
const ViewSlider = <T extends ViewSliderModel = ViewSliderModel>({
  model,
  direction,
  style = defaultStyle,
  children,
}: PropsWithChildren<ViewSliderProps<T>>): JSX.Element => {
  // The direction must be stored to a local ref, so that both views: the one
  // sliding in and the one sliding out will have the access to the same
  // direction variable. The out sliding view will have stored old state with
  // other solutions.
  const localDirection = useRef(direction.current);
  localDirection.current = direction.current;

  const [_isAnimating, setAnimating,] = useState(false);

  const variants = useMemo(() => ({
    initial: () => ({
      x: localDirection.current === 'left-to-right' ? '-100%' : '100%',
      zIndex: 0,
    }),
    open: {
      x: '0%',
      z: 1,
    },
    exit: () => ({
      x: localDirection.current === 'left-to-right' ? '100%' : '-100%',
      zIndex: 0,
    }),
  }), []);

  const handleAnimationStart = useCallback(() => setAnimating(true), []);

  const handleAnimationComplete = useCallback(() => setAnimating(false), []);

  const _style: CSSProperties = useMemo(() => ({
    ...defaultStyle,
    ...style,
    position: 'relative',
    overflow: 'hidden',
  }), [style,]);

  const _model = useMemo(() => ({
    ...model,
    _isAnimating,
  }), [model, _isAnimating,]);

  return (
    <div style={_style}>
      <AnimatePresence initial={false}
      >
        <SlideContainer
          key={model._id}
          initial="initial"
          animate="open"
          exit="exit"
          variants={variants}
          transition={transition}
          onAnimationStart={handleAnimationStart}
          onAnimationComplete={handleAnimationComplete}
        >
          <ViewProvider model={_model}
            direction={direction.current ?? DEFAULT_DIRECTION} >
            {children}
          </ViewProvider>
        </SlideContainer>
      </AnimatePresence>
    </div>
  );
};

/**
 * The view provider component to provide the context data for the child components in a single slider view.
 * This component is only used inside of the ViewSlider component.
 *
 * @param props
 * @returns
 */
const ViewProvider = (props: PropsWithChildren<ViewSliderContextType>) => {
  return (
    <ViewSliderContext.Provider value={props}>
      {props.children}
    </ViewSliderContext.Provider>
  );
};

/**
 * The context for the view slider component. Only used inside of this module.
 */
const ViewSliderContext = createContext<ViewSliderContextType | null>(null);

/**
 * Hook to get the context data for the view slider component. Use this hook to get the
 * context data for the child components in a single slider view.
 *
 * @returns The context data for the view slider component.
 */
export const useViewSliderContext = <T extends ViewSliderModel = ViewSliderModel>() => {
  return useContext<ViewSliderContextType<T>>(ViewSliderContext as any);
};

export default ViewSlider;
