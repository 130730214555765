export const toFixed = (value: number | undefined, defaultValue: null) => {
  try {
    if (value === undefined) {
      return defaultValue;
    }
    return value.toFixed(1);
  } catch (e) {
    return defaultValue;
  }
};
