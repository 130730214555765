import { useEffect, useRef, useState } from 'react';
import { useLatestComments } from 'bloc/comment/CommentsBloc';
import { usePresentationScreenContext } from 'components/screens/presentation/ScreenRenderer/PresentationScreenContext';
import { useTheme } from '@emotion/react';
import CommentItem from 'components/comments/CommentItem';
import type { Canvas, Comment } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

interface CommentThreadFeedProps {
  canvas?: WithId<Canvas>;
}

export const CommentThreadFeed = ({
  canvas,
}: CommentThreadFeedProps) => {
  const theme = useTheme();
  const screenContext = usePresentationScreenContext();
  const [comments, setComments,] = useState<WithId<Comment>[]>([]);
  const commentsBloc = useLatestComments(screenContext!);
  const feedRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => commentsBloc.subscribe(setComments), [commentsBloc,]
  );
  useEffect(() => {
    // Scroll to the bottom of the feed when the comments change
    setTimeout(() => {
      feedRef.current?.scroll({
        top: feedRef.current.scrollHeight, behavior: 'smooth',
      });
    }, 500);
  }, [comments,]);

  return <div
    ref={feedRef}
    style={{
      padding: theme.spacing(2),
      maxHeight: '100dvh',
      height: '100%',
      width: '100%',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      fontSize: 24,
    }}
  >
    <div style={{
      height: '100%',
      width: '100%',
    }} />
    {comments.map((comment) => <CommentItem
      canvas={canvas}
      key={comment._id}
      hideComments
      comment={comment}
      isMobile={false}
      showAnswer
    />)}
  </div>;
};
