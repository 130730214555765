import { AnswerId } from './spaces/projects/sessions/canvases';
import {
  CanvasId, CommentId, CustomerId, FeedItemId, InvoiceId, NotificationId, PageId, PanelId, PaymentId, ProjectId, ProjectSettingDocType, ScreenId, SessionId, SpaceId, SpaceSettingDocId
} from '.';

import { ReportId } from './spaces/projects/reports';
import { TemplateId } from './spaces/projects/template';
import { UserId } from '@mindhiveoy/schema';
import { isRunningInStoryBook } from './utils/system';

export type SpaceParams = Pick<PathParams, 'spaceId'>;
export type SpaceSettingsParams = Pick<PathParams, 'spaceId' | 'spaceSettingsId'>;
export type UserParams = Pick<PathParams, 'userId'>;
export type CustomerParams = Pick<PathParams, 'customerId'>;
export type PaymentParams = Pick<PathParams, 'customerId' | 'paymentId'>;
export type InvoiceParams = Pick<PathParams, 'spaceId' | 'invoiceId'>;
export type NotificationParams = Pick<PathParams, 'userId' | 'notificationId'>;
export type ProjectParams = Pick<PathParams, 'spaceId' | 'projectId'>;
export type ProjectSettingParams = Pick<PathParams, 'spaceId' | 'projectId' | 'settingId'>;
export type SessionParams = Pick<PathParams, 'spaceId' | 'projectId' | 'sessionId'>;
export type FeedItemParams = Pick<PathParams, 'spaceId' | 'projectId' | 'feedItemId'>;
export type CanvasParams = Pick<PathParams, 'spaceId' | 'projectId' | 'sessionId' | 'canvasId'>;

export type ReportParams = Pick<PathParams, 'spaceId' | 'projectId' | 'reportId'>;
export type ScreenParams = Pick<PathParams, 'spaceId' | 'projectId' | 'screenId'>;
export type PageParams = Pick<PathParams, 'pageId'>;
export type PanelParams = Pick<PathParams, 'spaceId' | 'projectId' | 'panelId'>;
export type TemplateParams = Pick<PathParams, 'spaceId' | 'projectId' | 'templateId'>;

export type AnswerParams = Pick<PathParams, 'spaceId' | 'projectId' | 'sessionId' | 'canvasId' | 'answerId'>;
export type CommentParams = Pick<PathParams, 'spaceId' | 'projectId' | 'sessionId' | 'canvasId' | 'commentId'>;
export type InvitationParams = Pick<PathParams, 'invitationId'>;

export type NextQueryParams =
  AnswerParams |
  CanvasParams |
  CommentParams |
  CustomerParams |
  FeedItemParams |
  InvitationParams |
  InvoiceParams |
  PageParams |
  PanelParams |
  PaymentParams |
  ProjectParams |
  ScreenParams |
  SessionParams |
  SpaceParams |
  TemplateParams;

export type InvitationId = string;

export interface PathParams {
  answerId: AnswerId;
  canvasId: CanvasId;
  commentId: CommentId | CommentId[];
  customerId: CustomerId;
  feedItemId: FeedItemId;
  invitationId: InvitationId;
  invoiceId: InvoiceId;
  notificationId: NotificationId;
  pageId: PageId;
  panelId: PanelId;
  paymentId: PaymentId;
  projectId: ProjectId;
  reportId: ReportId;
  screenId: ScreenId;
  sessionId: SessionId;
  settingId: ProjectSettingDocType;
  spaceId: SpaceId;
  spaceSettingsId: SpaceSettingDocId;
  templateId: TemplateId;
  userId: UserId;
}
export interface WithPathParamProps {
  params?: PathParams;
}

type PathKey = keyof PathParams;

/**
 * This is a map of query path params and their depth level in the hierarchy.
 *
 * All valid param combinations are listed in the array by first row to have
 * root level params, then second row to have second level params, etc.
 */
const queryPropsDepthMap: ((PathKey | PathKey[])[]) = [
  ['spaceId', 'userId', 'pageId', 'customerId',],
  ['projectId', 'notificationId', 'paymentId', 'invoiceId',],
  ['sessionId', 'reportId', 'panelId', 'feedItemId', 'screenId', 'settingId', 'templateId',],
  'canvasId',
  ['commentId', 'answerId',],
];

const queryPropsDepthLevel = {
  spaceId: 1,
  pageId: 1,
  userId: 1,
  customerId: 1,
  projectId: 2,
  paymentId: 2,
  invoiceId: 2,
  notificationId: 1,
  sessionId: 3,
  feedItemId: 3,
  reportId: 3,
  panelId: 3,
  canvasId: 4,
  commentId: 5,
  answerId: 5,
  templateId: 3,
};

export type QueryPathLevel = keyof typeof queryPropsDepthLevel;

export const validateSessionProps = (params: Partial<PathParams> | { [index: string]: string; }
  , level: QueryPathLevel) => {
  // Skip validation in server side
  if (typeof window === 'undefined' || isRunningInStoryBook()) {
    return;
  }

  let index = 0;
  const depth = queryPropsDepthLevel[level];
  while (index < depth) {
    const paramName = queryPropsDepthMap[index++];
    if (Array.isArray(paramName)) {
      let pass = false;

      for (const param of paramName) {
        if (pass) {
          break;
        }
        if (params[param]) {
          pass = true;
        }
      }
      if (!pass) {
        throw new Error(
          `Required query path param ${JSON.stringify(paramName)
          } is not defined. You are probably trying to read the context in a wrong level in hierarchy.`
        );
      }
    } else {
      if (params[paramName] === undefined) {
        throw new Error(`Required query path param ${paramName} is not defined. You are probably trying to read the context in a wrong level in hierarchy.`);
      }
    }
  }
};

/**
 * Extract a path presentation from path params based on params level information and param values.
 * @param {PathParams} params  The path parameters
 * @return {string} Path based on param information.
 */
export const extractPathFromParams = (params: Partial<PathParams>): string => {
  let result = '';
  let depth = 0;

  const getKeyMatch = (level: (keyof PathParams)[]) => {
    for (const key of level) {
      if (params[key]) {
        return key;
      }
    }
    return null;
  };

  const parseValue = (value: string | string[] | undefined): string | undefined => {
    if (!value) {
      return undefined;
    }
    return Array.isArray(value) ?
      value.reduce(
        (current, value) => current ? `${current}/${value}` : value
      ) : value;
  };

  while (depth < queryPropsDepthMap.length) {
    const level = queryPropsDepthMap[depth++];

    const key = getKeyMatch(Array.isArray(level) ? level : [level,]);
    if (!key) {
      return result;
    }
    const value = parseValue(params[key]);
    if (!value) {
      return result;
    }
    result = result ? `${result}/${value}` : value;
  }
  return result;
};


