import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const preventClickBubbling = (event: React.MouseEvent) => {
  event.stopPropagation();
  event.preventDefault();
};

/**
 * Container to stop event bubbling to parent elements. This is useful for
 * overlay editors and drawers that should not trigger events on the background.
 *
 * @return {JSX.Element}
 */
const PreventBubbling = ({
  children,
}: PropsWithChildren<unknown>) => <Container onClick={preventClickBubbling}>{
  children
}</Container>;

export default PreventBubbling;
