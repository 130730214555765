/* eslint-disable @typescript-eslint/no-explicit-any */
import CanvasMiniatureItem from './CardStrip/CardStripItemWithIcons';
import type { RenderItemEvent } from './CardStrip';

export const handleItemRender: RenderItemEvent = ({
  card,
  cardHeight,
  cardWidth,
  showAnswerIcon = false,
  menu,
  mode,
  selected,
  reflection,
  onClick,
  onEdit,
  onDelete,
  onArchive,
}: any) =>
  <CanvasMiniatureItem
    key={card._id}
    card={card}
    cardWidth={cardWidth}
    cardHeight={cardHeight}
    showAnswerIcon={showAnswerIcon}
    reflection={reflection}
    menu={menu}
    mode={mode}
    selected={selected}
    onClick={onClick}
    onEdit={onEdit}
    onDelete={onDelete}
    onArchive={onArchive}
  />;
