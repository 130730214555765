import FormControl from '@mui/material/FormControl';

import { useCallback, useEffect, useRef, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import type { ActionKeyEvent, TextPropertyEditor } from '../PropertyEditorTypes';
import type { KeyboardEventHandler } from 'react';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

const TextEditor: PropertyEditorFunction<string, TextPropertyEditor> = ({
  data,
  propertyName,
  disabled,
  propertyConfig: {
    defaultValue,
    displayName,
    message,
    multiline = false,
    password,
    required = false,
    rows = 4,
    testId = propertyName,
    onActionKey,
  },
  component,
  onBlur,
  onFocus,
  onPressEnter,
  onSave,
}) => {
  const [value, setValue,] = useState<string>(data && data.length !== 0 ? data : defaultValue ?? ' ');
  const ref = useRef<HTMLInputElement>(null);
  const [editing, setEditing,] = useState(false);
  const [checkField, setCheckField,] = useState(false);

  useEffect(() => {
    if (editing) {
      return;
    }
    // if (data !== undefined && data !== value) {
    if (data !== value) {
      setValue(data);
      setCheckField(false);
    }
  }, [data, editing, value,]);

  const handleSave = useCallback((value: string) => {
    const newValue = value?.trim();

    if (newValue !== data && onSave) {
      onSave(propertyName, newValue);
    }
  }, [data, onSave, propertyName,]);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback((event) => {
    const value = event.target.value;
    setValue(value);
    handleSave(value);
    event?.stopPropagation();
  }, [handleSave,]);

  const handleFocus = useCallback((event: any) => {
    event.target.select();
    onFocus?.(propertyName);
    setEditing(true);
  }, [onFocus, propertyName,]);

  const handleBlur = useCallback(() => {
    setEditing(false);
    setCheckField(true);
    onBlur?.(propertyName);
    handleSave(value);
  }, [handleSave, onBlur, propertyName, value,]);

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    let solved = false;
    const actionEvent: ActionKeyEvent = {
      key: 'Enter',
      propertyValue: value,
      component,
      markSolved: () => {
        solved = true;
      },
    };
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        event.stopPropagation();

        if (onActionKey) {
          onActionKey(actionEvent);
          if (solved) {
            return;
          }
        }
        handleSave(value);

        onPressEnter?.();
        break;

      case 'Escape':
        event.preventDefault();
        event.stopPropagation();
        if (onActionKey) {
          actionEvent.key = 'Escape';
          onActionKey(actionEvent);
          if (solved) {
            return;
          }
        }
        break;
    }
  }, [component, handleSave, onActionKey, onPressEnter, value,]);

  return <>
    <FormControl
      fullWidth
      variant="outlined"
      disabled={disabled}
    >
      <InputLabel htmlFor={propertyName}>
        {displayName}
      </InputLabel>
      <OutlinedInput
        data-testid={testId}
        disabled={disabled}
        fullWidth
        id={propertyName}
        label={displayName ?? propertyName}
        multiline={multiline}
        placeholder={displayName ?? propertyName}
        ref={ref}
        required={required}
        rows={rows}
        type={password ? 'password' : 'text'}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyUp={handleKeyUp}
      />
    </FormControl>
    {required && value === undefined && checkField && message &&
      <div style={{
        color: '#E94926',
      }}>
        {message}
      </div>
    }
  </>;
};

TextEditor.config = {
  type: 'text',
};

export default TextEditor;
