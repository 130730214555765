import { KNOB_SIZE } from '../../common/Knob';
import { LABEL_PADDING_REM, MOBILE_THRESHOLD, TICK_FONT_SIZE_REM } from '../../labelUnits';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import type { MatrixMode, RangeItem } from '@shared/schema/src';
import type { Render2DTickFunction } from '../layouts/Matrix2DLayout';

const DiscreteRangeTicks = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  font-size: ${TICK_FONT_SIZE_REM}rem;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  text-wrap: no-wrap;
  position: relative;
  text-overflow: ellipsis;
  padding-top: ${LABEL_PADDING_REM}rem;
  padding-bottom: ${LABEL_PADDING_REM}rem;
  @media(max-width: ${MOBILE_THRESHOLD}px) {
    font-size: ${TICK_FONT_SIZE_REM / 2}rem;
    padding-top: ${LABEL_PADDING_REM / 2}rem;
    padding-bottom: ${LABEL_PADDING_REM / 2}rem;
  }
`;

const DiscreteTick = styled$.div`
  box-sizing: border-box;
  flex: 1;
  align-self: center;
  padding-left: ${LABEL_PADDING_REM}rem;
  padding-right: ${LABEL_PADDING_REM}rem;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  @media(max-width: ${MOBILE_THRESHOLD}px) {
    font-size: ${TICK_FONT_SIZE_REM / 2}rem;
    padding-left: ${LABEL_PADDING_REM / 2}rem;
    padding-right: ${LABEL_PADDING_REM / 2}rem;
  }
`;

const ContinuousTick = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
`;

const ContinuousRangeTicks = styled$.div<{
  $knobSize: number;
}>(({
  $knobSize,
}) => `
  display: flex;
  width: calc(100% + 100px);
  font-size: ${TICK_FONT_SIZE_REM}rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: ${$knobSize / 2}px;
  padding-right: ${$knobSize / 2}px;
  box-sizing: border-box;
  text-align: center;
  text-wrap: no-wrap;
  position: relative;
  text-overflow: ellipsis;
  padding-top: ${LABEL_PADDING_REM}rem;
  padding-bottom: ${LABEL_PADDING_REM}rem;
  @media(max-width: ${MOBILE_THRESHOLD}px) {
    font-size: ${TICK_FONT_SIZE_REM / 2}rem;
    padding-top: ${LABEL_PADDING_REM / 2}rem;
    padding-bottom: ${LABEL_PADDING_REM / 2}rem;
  }
`);
// width: calc((100% - ${$knobSize}px) * ${(($rangeLength + 1) / $rangeLength).toFixed(1)});

export interface TickMarksProps {
  knobSize?: number;
  mode: MatrixMode;
  range: RangeItem[];
  side: 'top' | 'bottom';
  onRenderTick?: Render2DTickFunction;
}

export const XTickMarks = ({
  knobSize = KNOB_SIZE,
  range,
  mode = 'discrete',
  side,
  onRenderTick,
}: TickMarksProps) => {
  switch (mode) {
    case 'discrete':
      return <DiscreteRangeTicks>
        {
          range.map(
            ({
              label,
              value,
            }, index) => {
              const content = onRenderTick?.({
                axis: 'x',
                item: {
                  label,
                  value,
                },
                side,
                index,
              }) ?? label;

              return <DiscreteTick key={index}>
                {content}
              </DiscreteTick>;
            })
        }
      </DiscreteRangeTicks>;

    case 'continuous':

      return <ContinuousRangeTicks
        $knobSize={knobSize}
      >
        {
          range.map(
            ({
              label,
              value,
            }, index) => {
              const content = onRenderTick?.({
                axis: 'x',
                item: {
                  label,
                  value,
                },
                side,
                index,
              }) ?? label;

              return <ContinuousTick
                key={index}>
                <span style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  position: 'relative',
                  width: 100,
                  marginLeft: '-50%',
                  textAlign: 'center',
                }}>
                  {content}
                </span>
              </ContinuousTick>;
            })
        }
      </ContinuousRangeTicks>;

    default:
      return null;
  }
};
