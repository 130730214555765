import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import styled$ from '../../utils/react/styled$';

export const Chevron = styled$(ChevronLeftIcon)<{
  $open: boolean;
  $anchor?: 'left' | 'right';
}>(({
  $open,
  $anchor = 'left',
  theme,
}) => `
  transform: rotate(${$open ? $anchor === 'left' ? 0 : 180 : $anchor === 'left' ? 180 : 0}deg);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  margin-right: ${$anchor === 'left' ? theme.spacing(1) : 0};
  margin-left: ${$anchor === 'right' ? theme.spacing(1) : 0};
`);
