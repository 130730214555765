import { useMemo } from 'react';
import ImageIcon from '@mui/icons-material/Image';
import styled$ from '../../../../utils/react/styled$';
import type { BuilderComponentPropsBase } from '../PageWidgetRenderer';
import type { BuilderFunction } from '../../builders/componentBuilder';

type ImageCoverage = 'contain' | 'cover';

export interface ImageProps {
  url?: string;
  mode: ImageCoverage;
  alt?: string;
}
export interface ImageWidgetProps extends BuilderComponentPropsBase<ImageProps> {
  type: 'image';
}

export const IMAGE_DEFAULT_PROPS: ImageProps = {
  // eslint-disable-next-line max-len
  url: 'https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=4138&q=80',

  mode: 'cover',
};

const StyledImage = styled$.img<{
  $mode: ImageCoverage,
}>(({
  $mode,
}) => `
  width: 100%;
  height: calc(100% - 1px);
  object-fit: ${$mode};
  overflow: hidden;
`);
// z-index: -1;

const ImageWidget: BuilderFunction<ImageWidgetProps, ImageProps> = ({
  data: {
    // eslint-disable-next-line max-len
    url = IMAGE_DEFAULT_PROPS.url,
    mode = IMAGE_DEFAULT_PROPS.mode,
    alt,
  } = IMAGE_DEFAULT_PROPS,
}) => {
  const src = useMemo(() => {
    switch (typeof url) {
      case 'string':
        return url;
      case 'object':
        // TODO: typing
        return (url as any)?.photoURL;
      default:
        return IMAGE_DEFAULT_PROPS.url;
    }
  }, [url,]);

  return <StyledImage
    $mode={mode}
    alt={alt}
    src={src}
  />;
};

ImageWidget.config = {
  type: 'image',
  displayName: 'Image',
  icon: <ImageIcon />,
  props: {
    url: {
      category: 'general',
      displayName: 'Image url',
      type: 'image',
      // eslint-disable-next-line max-len
      defaultValue: 'https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=4138&q=80',
    },
    mode: {
      category: 'general',
      displayName: 'Fitting',
      type: 'picker',
      defaultValue: 'cover',
      options: [
        {
          label: 'Cover',
          value: 'cover',
        },
        {
          label: 'Contain',
          value: 'contain',
        },
      ],
    },
    alt: {
      category: 'general',
      displayName: 'Alternate text',
      type: 'text',
    },
  },
};

export default ImageWidget;
