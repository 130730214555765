/* eslint-disable @typescript-eslint/no-explicit-any */

import { DEFAULT_CATEGORY, extractCategoryPresentation } from '../models/selectionModels/extractCategoryPresentation';
import { extractPropsFromConfig } from '../propertyEditors/utils/propertyUtils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Inspector from './Inspector';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import styled from '@emotion/styled';
import type { ComponentSelectionModel } from '../models/selectionModels';
import type { FormErrors } from 'components/forms/validatePropertyForm';

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-wrap: auto;
  max-width: 100%;
  width: 100%;
`;

interface CategorizedInspectorProps {
  errors?: FormErrors;
  fluid?: boolean;

  disabled?: boolean;
  /**
   * The selection model to inspect
  */
  selection: ComponentSelectionModel<object>;
  scroll?: boolean;

  onPressEnter?: () => void;
}

/**
 * Inspector that displays properties in tabs based their
 * defined categories.
 *
 * @param {CategoryInspectorProps} Props for the component
 * @return {JSX.Element}
 */
const CategorizedInspector = ({
  disabled,
  errors,
  selection,
  onPressEnter,
}: CategorizedInspectorProps) => {
  const categories = useMemo(() => {
    if (!selection) {
      return [];
    }
    const config = selection.config();
    const props = extractPropsFromConfig(config, selection.data());

    return props ? extractCategoryPresentation(props).map(
      (c) => c.title.toLowerCase()
    ) : [];
  }, [selection,]);

  const [tab, setTab,] = useState<string>(getLatestInspectorTab(categories));

  useEffect(() => {
    if (categories.length && !categories?.includes(tab)) {
      const lastTab = getLatestInspectorTab(categories);
      setTab(lastTab ?? categories[0]);
    }
  }, [categories, tab,]);

  const handleTabChange = useCallback((event: React.SyntheticEvent, value: string) => {
    setTab(value);
    storeLatestTab(value);
    event.stopPropagation();
  }, []);

  return <>
    {categories.length > 1 ?
      <StyledTabs value={tab}
        onChange={handleTabChange}
        variant="scrollable">
        {
          categories.map((category, index) => <Tab
            key={index}
            value={category ?? 'general'}
            label={category}
          />)
        }
      </StyledTabs> : null}
    <Inspector
      disabled={disabled}
      selection={selection}
      category={tab ?? categories[0]}
      onPressEnter={onPressEnter}
    />
  </>;
};

/**
 * Restore latest tab from local storage and return it if is in the list of categories.
 *
 * @param {string[]}  categories  List of categories
 * @return {string}               Latest category
 */
export const getLatestInspectorTab = (categories: string[] = []) => {
  const latestCategory = typeof localStorage === 'undefined' ?
    DEFAULT_CATEGORY :
    localStorage.getItem('sessionEditor.latestInspectorTab');
  if (latestCategory && categories?.includes(latestCategory)) {
    return latestCategory;
  }
  return categories[0] ?? DEFAULT_CATEGORY;
};

export const storeLatestTab = (category: string) => {
  if (typeof localStorage === 'undefined') {
    return;
  }
  localStorage.setItem('sessionEditor.latestInspectorTab', category);
};

export default CategorizedInspector;
