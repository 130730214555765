import { ClearAnswerButton } from './ClearAnswerButton';
import AnimateHeight from 'components/common/AnimateHeight';

interface ShowAnswerProps {
  /**
   * A component that shows the answer to the question.
   */
  answerPresentation?: JSX.Element | undefined | null;
  /**
   * Whether there is an answer to show.
   */
  hasAnswer: boolean;
  /**
   * Callback to clear the answer.
   */
  onClear: () => void;
}

/**
 * Show the answer to the question with a clear button.
 *
 * @param param0
 * @returns
 */
const ShowAnswer = ({
  answerPresentation = null,
  hasAnswer,
  onClear,
}: ShowAnswerProps) => {
  return <div>
    <AnimateHeight visible={hasAnswer && !!answerPresentation}>
      {answerPresentation}
    </AnimateHeight>
    <ClearAnswerButton
      hasAnswer={hasAnswer}
      onClear={onClear}
    />
  </div>;
};

export default ShowAnswer;
