import type { Canvas, CanvasId, CanvasParams } from '@shared/schema/src';

import { createUseBlocHook } from '../../@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import { deleteCanvas, updateCanvas } from './canvasApi';
import BlocDocument from '../../@mindhiveoy/bloc/BlocDocument';
import elementCache from 'utils/ElementCache';
import type { BlocErrorFunction } from '../../@mindhiveoy/bloc/types';

/**
 *
 */
export class CanvasBloc extends BlocDocument<Canvas, CanvasParams> {
  /**
   * @param {params} params
   * @param {ErrorFunction} onError
   */
  constructor(
    params: CanvasParams,
    onError?: BlocErrorFunction
  ) {
    const {
      spaceId, projectId, sessionId, canvasId,
    } = params;

    super({
      onError,
      params,
      documentPath:
        `spaces/${spaceId
        }/projects/${projectId
        }/sessions/${sessionId
        }/canvases/${canvasId}`,
    });

    this.registerUpdateListener((data) => {
      if (!data) {
        return;
      }
      elementCache.set({
        keyPath: {
          spaceId,
          projectId,
          sessionId,
          canvasId,
        },
        data,
      });
    });
  }

  /**
   * Update canvas data
   * @param {Partial<Canvas>}canvas
   * @param {string}canvasId
   * @return {Promise<WithId<Canvas>>}
   */
  public update = async (
    canvas: Partial<Canvas>,
    canvasId: string
  ) => {
    return this._set(
      () => updateCanvas({
        // TODO: Currently it is hack solution to make an update for the canvases
        ...this.params,
        canvasId,
        canvas,
      })
      ,
      this.onError
      // Optimistic update which causes problems now
      // (data) => data && {
      //   ...data,
      //   ...canvas,
      //   _id: this.docId,
      // } as WithId<Canvas>
    );
  };

  /**
   * Delete canvas
   * @param {CanvasId}canvasId
   * @return {Promise<void>}
   */
  public delete = async (
    canvasId: CanvasId
  ) => {
    return this._delete(
      async () => {
        await deleteCanvas({
          ...this.params,
          canvasId,
        });
      },
      this.onError);
  };
}
export const useCanvas = createUseBlocHook<Canvas, CanvasParams, CanvasBloc>(
  ({
    params,
    onError,
  }) => new CanvasBloc(params, onError)
);
