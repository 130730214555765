/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { AuthenticationType, useAuth } from '@mindhiveoy/react-auth';
import { COLOR_GOOGLE } from 'theme';
import { LayoutElement } from '.';
import { SignFormButton } from './SignFormButton';
import { TermsDialogs } from './TermsDialogs';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import { validateEmail } from 'utils/formValidators/validateEmail';
import { validateTermsOfServiceAndPrivacyPolicy } from 'utils/formValidators/validateTermsOfServiceAndPrivacyPolicy';
import Alert from 'components/info/Alert';
import Brand from 'components/common/Brand';
import Form from 'components/forms/Form';
import FormElement from 'components/forms/FormElement';
import Link from 'next/link';
import LoadingIndicator from 'components/common/LoadingIndicator';
import SignUpButton from './SignUpButton';
import config from 'config';
import passwordSchema from 'utils/passwordSchema';
import styled from '@emotion/styled';
import useForm, { MessageType } from 'ts-react-form-validator';
import type { FormValidationRules, ValidationMessage } from 'ts-react-form-validator';
import type { Invitation, ShortUserRoleId } from '@shared/schema/src';
import type { KeyboardEventHandler, PropsWithChildren } from 'react';
import type { SignInFormProps } from './SignInForm';
import type { WithId } from '@mindhiveoy/schema';

const TableOfContentsText = styled.div`
  font-weight: bold;
`;

interface TableOfContentItemProps {
  id: string | number;
}

export const TableOfContentItem = ({
  id,
  children,
}: PropsWithChildren<TableOfContentItemProps>) =>
  <Link href={`#${id}`}>
    <TableOfContentsText>{children}</TableOfContentsText>
  </Link>;

const StyledCheckBoxContainer = styled.div`
  display: flex;
`;

/**
 * Original value for margin-bottom was : 0.75rem
 */
const StyledFormLabel = styled(FormLabel)(({
  theme,
}) => `
   margin-bottom: ${theme.spacing(2)};
`);

const StyledLink = styled('a')(({
  theme,
}) => `
  color: ${theme.palette.primary.main};
  font-weight: bold;
  &:hover {
    cursor: pointer;
    color: ${theme.palette.primary.contrastText};
  }
`);

interface SignUpForm {
  displayName: string;
  email: string;
  password: string;
  password2: string;
  acceptTerms: boolean;
}

interface SignUpFormProps extends SignInFormProps {
  onSignedIn?: (invitation?: WithId<Invitation<ShortUserRoleId>>) => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const SignUpForm = ({
  authState,
  invitation,
  mode,
  planId,
  signUpFromTitlePage,
  onSignedIn,
  setMethod,
}: SignUpFormProps) => {
  const auth = useAuth();
  const router = useRouter();

  const {
    signUpWithEmail,
    signInWIthCredentials,
  } = authState ?? auth;

  const [error, setError,] = useState<string | undefined>(undefined);

  const [termsDialog, setTermsDialog,] = useState<'none' | 'privacy' | 'tos'>('none');

  const [registering, setRegistering,] = useState(false);

  const theme = useTheme();

  const {
    t,
  } = useTranslation();

  const requiredText = useCallback(
    (): string | any => t('required-field'),
    [t,]
  );

  const validatePassword = useCallback((value?: string) =>
    value &&
      (config.environment.target === 'development' ||
        passwordSchema.validate(value)
      ) ? true :
      {
        type: MessageType.ERROR,
        message: t('Password must be at least 8 characters long and contain at least one uppercase letter,  one lowercase letter and one digit.'),
      }, [t,]);

  const rules = useMemo((): FormValidationRules<SignUpForm> => {
    return {
      fields: {
        displayName: {
          required: true,
          requiredText,
        },
        email: {
          required: true,
          requiredText,
          validate: validateEmail(t) as any,
        },
        password: {
          required: true,
          requiredText,
          trim: true,
          validate: validatePassword,
        },
        password2: {
          required: true,
          requiredText,
          trim: true,
          validate: validatePassword,
        },
        acceptTerms: {
          required: true,
          requiredText,
          trim: false,
          validate: validateTermsOfServiceAndPrivacyPolicy as any,
        },
      },
      validateForm: (form): true | any => {
        const messages = {} as any;
        let isFormValid = form.isFormValid;

        let formMessage: ValidationMessage | undefined;

        if (form.filled.password && form.filled.password2 &&
          form.values.password !== form.values.password2) {
          formMessage = {
            type: MessageType.ERROR,
            message: t('Passwords do not match'),
          };
          messages.password = formMessage;
          messages.password2 = formMessage;
          isFormValid = false;
        }
        return {
          ...form,
          isFormValid,
          messages,
          formMessage,
        };
      },
    };
  }, [requiredText, t, validatePassword,]);

  const {
    form, events, messages,
    values: {
      displayName,
      email,
      password, password2,
      acceptTerms,
    }, setFieldValues,
  } = useForm<SignUpForm>(
    {
      email: invitation?.email ?? '',
      password: '',
      password2: '',
      displayName: invitation?.displayName ?? '',
      acceptTerms: false,
    },
    rules
  );

  const handleModalClose = useCallback(() => {
    setTermsDialog('none');
  }, []);

  const handleBackClick = useCallback((event: React.MouseEvent) => {
    setMethod && setMethod('sign-in');
    event.stopPropagation();
  }, [setMethod,]);

  const handleRegistration = useCallback(async (method: 'email' | 'google') => {
    try {
      setRegistering(true);

      switch (method) {
        case 'email':
          await signUpWithEmail(
            email,
            password,
            displayName,
            undefined,
            invitation?._id,
            planId as any
          );
          onSignedIn?.();
          break;

        case 'google':
          await signInWIthCredentials(
            AuthenticationType.GOOGLE
          );
          onSignedIn?.(invitation);

          return;

        default:
          throw new Error(`Unknown sign up method ${method}`);
      }

      // const plan = planId ?? router.query.planId as string | undefined;
      // if (plan) {
      //   // TODO: Move this logic into signUpWithEmail
      //   const spacesBloc = new SpacesBloc();
      //   const space = await spacesBloc.create(plan as any);
      //   const path = `/${space._id}/`;
      //   router.replace(path, path, {
      //     shallow: true,
      //   });
      //   return;
      // }

      if (signUpFromTitlePage) {
        router.push('/');
      }
    } catch (error: any) {
      console.log('Error', error);
      setError(error.message);
    } finally {
      setRegistering(false);
    }
  }, [displayName, email, invitation, signUpFromTitlePage, password, planId, router,
    signInWIthCredentials, onSignedIn, signUpWithEmail,]);

  const handleRegisterEmailClick = useCallback(async (event?: React.MouseEvent) => {
    if (!form.isFormValid) {
      return;
    }
    event?.stopPropagation();
    handleRegistration('email');
  }, [form.isFormValid, handleRegistration,]);

  const handleRegisterGoogleClick = useCallback(async (event?: React.MouseEvent) => {
    event?.stopPropagation();
    handleRegistration('google');
  }, [handleRegistration,]);

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    if (event.key === 'Enter') {
      handleRegisterEmailClick();
    }
    event.preventDefault();
    event.stopPropagation();
  }, [handleRegisterEmailClick,]);

  const handleAcceptTerms: React.MouseEventHandler = useCallback(() => {
    setFieldValues({
      acceptTerms: !acceptTerms,
    });
  }, [acceptTerms, setFieldValues,]);

  const handleOpenTermsOfService = useCallback((event: React.MouseEvent) => {
    setTermsDialog('tos');
    event.stopPropagation();
  }, []);

  const handleOpenPrivacyPolicy = useCallback((event: React.MouseEvent) => {
    setTermsDialog('privacy');
    event.stopPropagation();
  }, []);

  const signInStyles = useMemo(() => {
    return {
      border: `2px solid ${theme.palette.primary.main}`,
    };
  }, [theme,]);

  return <>
    <Form<SignUpForm> id="sign-in-form"
      style={signInStyles}
      messages={messages}>
      <Brand />

      <StyledCheckBoxContainer>
        <StyledFormLabel>
          {t('I accept')} <StyledLink onClick={handleOpenTermsOfService}>{t('Terms of Service')}</StyledLink>
          {` ${t('and')} `}
          <StyledLink onClick={handleOpenPrivacyPolicy}>{t('privacy-policy-genetive')}</StyledLink>
        </StyledFormLabel>
        <Checkbox
          id="acceptTerms"
          checked={acceptTerms}
          onClick={handleAcceptTerms}
          defaultChecked
        />
      </StyledCheckBoxContainer>
      <SignUpButton
        signUpMethod="google"
        tabIndex={0}
        variant="contained"
        onClick={handleRegisterGoogleClick}
        disabled={!acceptTerms}
        style={{
          padding: theme.spacing(2),
          marginBottom: theme.spacing(3),
          background: COLOR_GOOGLE,
          minWidth: '40%',
          width: 'fit-content',
          color: 'rgba(255,255,255,0.9)',
          textAlign: 'left',
        }}
      >
        <i className="fab fa-google" />
        Google
      </SignUpButton>

      <div>{t('or')}</div>

      <LayoutElement layoutId="displayName">
        <FormElement field="displayName">
          <TextField
            label={t('Display Name')}
            value={displayName ?? invitation?.displayName}
            id="displayName"
            {...events}
            onKeyUp={handleKeyUp} />
        </FormElement>
      </LayoutElement>

      <LayoutElement layoutId="email">
        <FormElement field="email">
          <TextField
            label={t('Email')}
            value={email}
            id="email"
            disabled={!!invitation?.email}
            {...events}
            onKeyUp={handleKeyUp} />
        </FormElement>
      </LayoutElement>

      <LayoutElement layoutId="password">
        <FormElement field="password">
          <TextField
            label={t('Password')}
            value={password}
            id="password"
            type="password"
            {...events}
            onKeyUp={handleKeyUp} />
        </FormElement>
      </LayoutElement>

      <LayoutElement layoutId="password2">
        <FormElement field="password2">
          <TextField
            label={t('Password again')}
            value={password2}
            id="password2"
            type="password"
            {...events}
            onKeyUp={handleKeyUp} />
        </FormElement>
      </LayoutElement>

      {error && <Alert visible={!!error}>
        {t('It seems this email is already registered Try to sign in to your account or contact the system Admin')}
      </Alert>}
      {registering ? <LoadingIndicator /> : <LayoutElement layoutId="button">
        <SignFormButton onClick={handleRegisterEmailClick}
          disabled={!form.isFormValid}
          variant='contained'
          color='secondary'>
          {t('Register')}
        </SignFormButton>
      </LayoutElement>}
      {mode !== 'sign-up' &&
        <Typography component={'span'}
          variant="body1">
          <Button onClick={handleBackClick}
            color="secondary"
            variant="text">{t('Back')}</Button>
        </Typography>
      }
    </Form>
    <TermsDialogs
      mode={termsDialog}
      onClose={handleModalClose}
    />
  </>;
};

export default SignUpForm;
