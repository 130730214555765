/* eslint-disable @typescript-eslint/no-explicit-any */
import { ListenerHelper } from './listenerHelper';
import type { ListenerFunction } from './listenerHelper';

/**
 * A set of similar listeners with targeting to some source. This can be used
 * for example to target change listeners to a specific area, for example one
 * container of the component.
 */
export class ListenerSetHelper<S extends string, F extends ListenerFunction> {
  private $listenerMap = new Map<S, ListenerHelper<F>>();

  /**
   * Add a new listener to the class
   * @param {S} source    The id of the source.
   * @param {F} listener  The handle to the listener to be added.
   */
  public add = (source: S, listener: F) => {
    const listeners = this.$listenerMap.get(source) ?? new ListenerHelper<F>();
    listeners.add(listener);
    this.$listenerMap.set(source, listeners);
  };

  /**
   * Remove a listener
   * @param {S} source    The id of the source.
   * @param {F} listener The handle to listener to be removed.
   */
  public remove = (source: S, listener: F) => {
    const listeners = this.$listenerMap.get(source);
    if (!listeners) {
      return;
    }
    listeners.remove(listener);

    if (listeners.isEmpty()) {
      this.$listenerMap.delete(source);
    } else {
      this.$listenerMap.set(source, listeners);
    }
  };

  /**
   * Fire an event for listeners to specific source.
   * @param {string} source The source id to indicate which listeners to be fired.
   * @param {any[]} args    Arguments for the listener event.
   */
  public fire = (source: S, ...args: any[]) => {
    const listeners = this.$listenerMap.get(source);
    if (!listeners) {
      return;
    }
    listeners.fire(...args);
  };
}
