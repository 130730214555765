import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import ClearIcon from '@mui/icons-material/Clear';
import React, { useCallback } from 'react';
import Tooltip from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import type { CheckerPropertyEditor } from '../PropertyEditorTypes';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const CheckerEditor: PropertyEditorFunction<boolean, CheckerPropertyEditor> = ({
  data,
  propertyName,
  disabled,
  propertyConfig: {
    displayName,
    defaultValue,
    nullify,
    nullifyInfo,
    testId = propertyName,
  },
  onSave,
}) => {
  const handleChange = useCallback((event: any) => {
    if (event.target.checked !== data && onSave) {
      onSave(propertyName, event.target.checked);
    }
    event?.stopPropagation();
  }, [data, onSave, propertyName,]);

  const handleNullify = useCallback((event: any) => {
    onSave(propertyName, null);
    event?.stopPropagation();
  }, [onSave, propertyName,]);

  const label = displayName ?? propertyName;

  const value = nullify ? data : data ?? defaultValue;

  return <Container >
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          checked={value}
          color={nullify ? value === undefined ? 'secondary' : 'primary' : 'primary'}
          data-testid={testId}
          name={label}
          onChange={handleChange}
        />
      }
      label={label} // TODO read the label from localization data
    />
    {nullify ? <Tooltip title={nullifyInfo}>

      <ClearIcon
        onClick={handleNullify}
      />
    </Tooltip> : null}
  </Container>;
};

CheckerEditor.config = {
  type: 'checker',
};

export default CheckerEditor;
