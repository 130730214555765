import { APP_DRAWER_WIDTH } from './AppDrawer';
import styled$ from 'utils/react/styled$';
import useScreenContext from './useScreenContext';
import type { PropsWithChildren } from 'react';

export const NAVIGATION_BAR_HEIGHT = 80;

const Main = styled$.main<{
  $drawerOpen: boolean;
}>(({
  $drawerOpen,
}) => `
  transition: margin-left margin-right 0.2s ease-in-out;
  margin-left: ${$drawerOpen ? APP_DRAWER_WIDTH : 0}px;
  position: relative;
`);

interface MainContentProps {
  style?: React.CSSProperties;
}

const MainContent = ({
  children,
  style,
}: PropsWithChildren<MainContentProps>) => {
  const {
    leftDrawerOpen,
  } = useScreenContext();

  return (
    <Main $drawerOpen={leftDrawerOpen}
      style={style}>
      {children}
    </Main>
  );
};

export default MainContent;
