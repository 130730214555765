/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FirebaseSchemaDocumentModel, FirestoreSchemaModel } from '@mindhiveoy/firebase-schema';

/**
 * Local cache for document models for fast accessing with the controller
 */
class DocumentModelCache {
  private documentModels = new Map<string, FirebaseSchemaDocumentModel<any, any>>();

  private schema?: FirestoreSchemaModel<any, any>;

  /**
   * Get a document model from the cache for the given collection path
   * @param schema
   * @param collectionPath
   * @returns
   */
  public get = (
    schema: FirestoreSchemaModel<any, any>,
    collectionPath: string
  ): FirebaseSchemaDocumentModel<any, any> => {
    if (!this.schema) {
      this.schema = schema;
    }
    if (this.schema !== schema) {
      throw new Error('Internal error: schema has changed at the runtime. Platform`s schema must be fixed.');
    }

    const reducedCollectionPath = this.reduceCollectionPath(collectionPath);

    const result = this.documentModels.get(reducedCollectionPath);
    if (result) {
      return result;
    }

    const docModel = this.getDocumentModel(reducedCollectionPath);
    if (!docModel) {
      throw new Error(`Internal error: no document found for path: ${collectionPath}`);
    }
    this.documentModels.set(reducedCollectionPath, docModel);
    return docModel;
  };

  /**
  * Reduce a document path to a collection path, all document id's are erased.
  * @param id
  * @returns
  */
  reduceCollectionPath = (id: string) => {
    return id
      .split('/')
      .filter((s, index) => index % 2 === 0)
      .join('/');
  };

  /**
   * Get a document model from schema for given collection path.
   * @param {string}  collectionPath  The collection path to look after.
   */
  getDocumentModel = (collectionPath: string) => {
    if (!this.schema) {
      throw new Error('Internal error: schema is not defined.');
    }
    let collections: any = this.schema.collections;

    const path = collectionPath.split('/');

    let doc: FirebaseSchemaDocumentModel<any, any> | undefined;

    for (const element of path) {
      const collection = collections[element];
      if (!collection) {
        throw new Error(`Internal error: no collection found for path: ${collectionPath
        } the collection does not exists in schema. (element = ${element})`);
      }
      doc = collection.doc;
      collections = doc!.collections;
    }
    if (!doc) {
      throw new Error(`Internal error: no document found for path: "${collectionPath}"`);
    }
    return doc;
  };
}
export const documentCache = new DocumentModelCache();
