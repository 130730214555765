/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */

import { ListenerHelper } from '@mindhiveoy/foundation';
import { startTransition } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import type { ComponentModel } from './models/ComponentModel';

export interface MementoItem {
  /**
   * Merge the memento item with the current item if possible
   * @param {boolean} memento   Returns true if the merge was successful
   */
  mergeIfYouCan(memento: MementoItem): unknown;
  /**
   * Undo the memento item
   * @returns true if the item was undone
   */
  undo: () => void;
  /**
   * Redo the memento item
   */
  redo: () => void;
}

export class MementoItemInstance implements MementoItem {
  constructor(
    public componentModel: ComponentModel<any>,
    public before: Record<string, any>,
    public after: Record<string, any>) {
  }

  redo = () => {
    this.componentModel.setData(this.after);
  };

  undo = () => {
    this.componentModel.setData(this.before);
  };

  mergeIfYouCan = (memento: MementoItem) => {
    if (memento instanceof MementoItemInstance && memento.componentModel === this.componentModel) {
      this.after = memento.after;
      return true;
    }
    return false;
  };
}

export type UndoState = {
  canUndo: boolean;
  canRedo: boolean;
};

// TODO Move to the foundation

export type MementoListener = (state: UndoState) => void;

/**
 * Memento pattern implementation to support undo and redo
 */
class Memento {
  private _listeners = new ListenerHelper<MementoListener>();

  private $history: MementoItem[] = [];

  private $pointer = 0;

  private _lastChangeId?: string;

  public get state(): UndoState {
    return {
      canUndo: this.$pointer !== 0,
      canRedo: this.$history.length - this.$pointer > 0,
    };
  }

  /**
   * Force change id history reset to force next change to be its own
   * undo step
   */
  public resetChangeId = () => {
    this._lastChangeId = undefined;
  };

  /**
   * Undo the last operation
   */
  public undo = () => {
    if (this.$pointer <= 0) {
      return;
    }
    this.$pointer--;
    const item = this.$history[this.$pointer];
    item.undo();
    this._fireChange();
  };

  /**
   * Redo's last undone operation
   */
  public redo = () => {
    if (this.$pointer >= this.$history.length) {
      return;
    }
    const item = this.$history[this.$pointer];
    item.redo();
    this.$pointer++;
    this._fireChange();
  };

  /**
   * Report a new change
   *
   * @param {string} id Unique id for the change context.
   * @param {MementoItem} memento Memento pattern's undo/redo -pair object
   */
  public change = (id: string, memento: MementoItem) => {
    if (this._lastChangeId === id) {
      const lastMemento = this.$history[this.$history.length - 1];
      if (lastMemento?.mergeIfYouCan(memento)) {
        return;
      }
    }
    this._lastChangeId = id;
    const historyRewritten = this.$pointer !== this.$history.length ?
      this.$history.slice(0, this.$pointer) : this.$history;
    historyRewritten.push(memento);
    this.$history = historyRewritten;
    this.$pointer++;
    this._fireChange();
  };

  /**
   * Indicates if the data model has changes
   * @return {boolean} True when changes exist
   */
  public hasChanged = () => {
    return this.$history.length > 0;
  };

  /**
   * Reset the whole undo/redo -history
   */
  public reset = () => {
    this.$history = [];
    this.$pointer = 0;
    this._fireChange();
  };

  /**
   * Subscribe to changes in the undo/redo -history
   * @param {MementoListener} listener Listener function
   * @return {*} Unsubscribe function
   */
  public subscribe = (listener: MementoListener) => {
    this._listeners.add(listener);
    return () => {
      this._listeners.remove(listener);
    };
  };

  private _fireChange = () => {
    const event = cloneDeep(this.state);
    startTransition(() => {
      this._listeners.fire(event);
    });
  };
}

export default Memento;
