import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { LexicalEditor } from 'lexical';

interface FocusPluginProps {
  onFocusChange: (focused: boolean, editor: LexicalEditor) => void;
}
/**
 * Focus plugin to track focus state of the editor
 */
const FocusPlugin = ({
  onFocusChange,
}: FocusPluginProps) => {
  const [editor,] = useLexicalComposerContext();

  useEffect(() => {
    // register various update listeners
    return mergeRegister(
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          onFocusChange(false, editor);
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          onFocusChange(true, editor);
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, onFocusChange,]);

  return null;
};

export default FocusPlugin;
