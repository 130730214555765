/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-unused-collection */
import { useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import type { NextQueryParams, PathParams } from '@shared/schema/src';

const paramObjectMap = new Map<string, NextQueryParams>();

const generateKey = (params: NextQueryParams) => {
  const keys = Object.keys(params).sort();
  return keys.map((key) => `${key}:${(params as any)[key]}`).join(',');
};

/**
 * Use path params in current path context. Params are filled
 * based on routers params and params given as input. The input
 * params will always override route params.
 *
 * @template P                        The path params used in context. Casting is not mandatory.
 * @param {NextQueryParams}   params  Params given explicitly.
 * @return {P}                        Combination of router paras and input params as an object that
 *                                    is guaranteed to remains as the same reference as long as params
 *                                    are equal.
 */
const usePathParams = <P extends Partial<PathParams> = PathParams>(
  params?: Partial<P>
): P => {
  const {
    query,
  } = useRouter();

  const currentKey = useRef<string>();

  const currentParams = useRef<Partial<PathParams>>();

  return useMemo(() => {
    // Input params will always override router params
    const result: Partial<PathParams> = {
      ...params,
    };

    // Fill params with router params where not set
    Object.entries(query).forEach(([key, value,]) => {
      if (!(result as any)[key]) {
        (result as any)[key] = value;
      }
    });

    const newKey = generateKey(result as any);

    if (currentKey.current !== newKey) {
      currentKey.current = newKey;

      const instance = paramObjectMap.get(newKey);
      if (instance) {
        currentParams.current = instance;
      } else {
        currentParams.current = result;
        paramObjectMap.set(newKey, result as any);
      }
    }
    // // Update params object only if params have changed
    // if (currentParams.current === null ||
    //   !_.isEqual(currentParams.current, result)) {
    //   currentParams.current = result;
    // }
    return currentParams.current as P;
  }, [params, query,]);
};

export default usePathParams;
