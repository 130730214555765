/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthenticationType } from '@mindhiveoy/react-auth';
import { COLOR_GOOGLE } from 'theme';
import { SignFormButton } from '../SignFormButton';
import { useCallback, useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import { validateEmail } from 'utils/formValidators/validateEmail';
import Alert from 'components/info/Alert';
import Brand from 'components/common/Brand';
import Button from '@mui/material/Button';
import Form from 'components/forms/Form';
import LoadingIndicator from 'components/common/LoadingIndicator';
import SignUpButton from '../SignUpButton';
import SimpleComponentForm from 'components/forms/SimpleComponentForm';
import Typography from '@mui/material/Typography';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import styled from '@emotion/styled';
import type { AuthErrorCode } from '@mindhiveoy/auth';
import type { BuilderConfig } from 'components/builder/builders/componentBuilder';
import type { CSSProperties, MouseEventHandler } from 'react';

const FormContainer = styled(Form<SignInForm>)`
  min-width: min(500px, calc(100vw - 32px));
`;

/**
 * Represents the data structure for the sign-in form.
 */
export interface SignInForm {
  /**
   * Email address
   */
  email: string;
  /**
   * Password
   */
  password: string;
}

export type SignInResponse = {
  status: 'success';
} | {
  status: 'error';
  message: string;
  errorCode: AuthErrorCode;
};

export interface SignInFormPureProps {
  /**
   * Title of the form
   */
  title: string;
  /**
   * Initial data for the form
   */
  initialData?: SignInForm;
  /**
   * If true, the user has no internet connection
   */
  offline?: boolean;
  /**
   * The user has clicked the "Forgot password" link
   */
  onForgotPasswordClick?: () => void;
  /**
   * The user has clicked the "Register" link
   */
  onRegisterClick?: () => void;
  /**
   * Callback to sign in with credentials. The callback should return a promise
   * that resolves to a SignInResponse object.
   *
   * @param type  Authentication type
   * @returns     Promise that resolves to a SignInResponse object
   */
  onSignInWithCredentials?: (type: AuthenticationType) => Promise<SignInResponse>;
  /**
   * Callback to sign in with email and password. The callback should return a promise
   * that resolves to a SignInResponse object.
   *
   * @param email     Email address
   * @param password  Password
   * @returns         Promise that resolves to a SignInResponse object
   */
  onSignInWithEmail?: (email: string, password: string) => Promise<SignInResponse>;
}

const emptyForm: SignInForm = {
  email: '',
  password: '',
};
/**
 * A pure sign in form view. This component does not handle any side effects, but
 * only renders the form and calls the provided callbacks. The view will take care
 * of the user input validation and the ui state to be consistent with the user input
 * in any case.
 *
 * @return {React.ReactElement} Login form
 */
const SignInFormPure = ({
  initialData,
  offline,
  title = 'Aavistus',
  onForgotPasswordClick,
  onRegisterClick,
  onSignInWithCredentials,
  onSignInWithEmail,
}: SignInFormPureProps) => {
  const [error, setError,] = useState<string | undefined>(undefined);
  const [signingIn, setSigningIn,] = useState(false);

  const [form, setForm,] = useState<SignInForm>(initialData ? cloneDeep(initialData) : emptyForm);

  const [isFormValid, setIsFormValid,] = useState(false);

  const theme = useTheme();

  const {
    t,
  } = useTranslation();

  const formConfig: BuilderConfig<SignInForm> = useMemo(() => ({
    type: 'object',
    props: {
      email: {
        type: 'text',
        propertyName: 'email',
        displayName: 'Email',
        required: true,
        autoFocus: true,
        testId: 'email',
        validate: validateEmail(t),
      },
      password: {
        type: 'text',
        testId: 'password',
        propertyName: 'password',
        displayName: 'Password',
        required: true,
        password: true,
        // validate: (value: string) => config.environment.target === 'development' ||
        //   passwordSchema.validate(value) || {
        //   type: 'error',
        //   message: t('Password must be at least 8 characters long and contain at least one uppercase letter,  one lowercase letter and one digit.'),
        // },
      },
    },
  }), [t,]);

  const extractErrorMessage = useCallback((errorCode: AuthErrorCode): string => {
    switch (errorCode) {
      case 'auth_wrong_password_or_email':
        return t('Wrong username or password');

      case 'auth_offline':
        return t('You are offline');

      case 'auth_user_disabled':
        return t('User is disabled');

      case 'auth_internal_error':
      default:
        return t('Internal error');
    }
  }, [t,]);

  const handleGoogleLoginClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      const execute = async () => {
        try {
          setSigningIn(true);
          const response = await onSignInWithCredentials?.(AuthenticationType.GOOGLE);

          if (response?.status === 'error') {
            setError(extractErrorMessage(response.errorCode));
          }
        } finally {
          setSigningIn(false);
        }
      };
      execute();
    }, [extractErrorMessage, onSignInWithCredentials,]);

  const handleEmailSignIn = useCallback(async () => {
    if (!isFormValid) {
      return;
    }
    try {
      setSigningIn(true);
      const response = await onSignInWithEmail?.(form.email, form.password);

      if (response?.status === 'error') {
        setError(extractErrorMessage(response.errorCode));
      }
    } finally {
      setSigningIn(false);
    }
  }, [extractErrorMessage, form.email, form.password, isFormValid, onSignInWithEmail,]);

  const handleEmailSignInClick: MouseEventHandler<any> = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    handleEmailSignIn();
  }, [handleEmailSignIn,]);

  const handleForgotPasswordClick: MouseEventHandler<any> = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    onForgotPasswordClick?.();
  }, [onForgotPasswordClick,]);

  const googleButtonStyle: CSSProperties = useMemo(() => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    background: COLOR_GOOGLE,
    minWidth: '40%',
    width: 'fit-content',
    color: 'rgba(255,255,255,0.9)',
    textAlign: 'left',
  }), [theme,]);

  const handleDataChange = useCallback(
    (data: SignInForm, isValid: boolean) => {
      setForm((oldData) => {
        // TODO: Make the model component to trigger only when the data is changed
        if (isEqual(oldData, data)) {
          return oldData;
        }
        return data;
      });
      setIsFormValid(isValid);
    }, []);

  const handleRegisterClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    onRegisterClick?.();
  }, [onRegisterClick,]);

  const alertStyle = useMemo(() => ({
    marginBottom: theme.spacing(2),
  }), [theme,]);

  return (
    <FormContainer
      id="sign-in-form"
      maxWidth
    >
      <Brand title={title} />
      {onSignInWithCredentials ? <>
        <SignUpButton
          signUpMethod="google"
          tabIndex={0}
          disabled={signingIn || offline}
          data-testid="google-sign-in"
          variant="contained"
          onClick={handleGoogleLoginClick}
          style={googleButtonStyle}
        >
          <i className="fab fa-google" />
          Google
        </SignUpButton>
        <div>{t('or')}</div>
      </> : null}

      {onSignInWithEmail ? <>
        {<SimpleComponentForm<SignInForm>
          config={formConfig}
          disabled={signingIn || offline}
          initialData={form}
          onDataChange={handleDataChange}
          onPressEnter={handleEmailSignIn}
        />}
        {
          offline ? <Alert visible={offline}
            style={alertStyle}>
            {t('You are offline')}
          </Alert> :
            error ?
              <Alert visible={!!error}
                style={alertStyle}>
                {error}
              </Alert> : null}
        {
          signingIn ? <LoadingIndicator noTitle /> :
            <SignFormButton
              data-testid='sign-in-button'
              onClick={handleEmailSignInClick}
              disabled={!isFormValid || offline}
              variant='contained'
              color='secondary'>
              {t('Log in')}
            </SignFormButton>
        }
      </> : null}

      {onForgotPasswordClick ? <SignFormButton
        data-testid='forgot-password'
        onClick={handleForgotPasswordClick}
        variant='text'
        color='secondary'>
        {t('Forgot password')}
      </SignFormButton> : null}

      {onRegisterClick ? <Typography component={'span'}
        variant="body1">
        {t('Not using variant yet', {
          variant: title,
        })}
        <Button onClick={handleRegisterClick}
          data-testid='register'
          color="secondary"
          variant="text"
        >
          {t('Sign up')}
        </Button>
      </Typography> : null}
    </FormContainer>
  );
};

export default SignInFormPure;
