/* eslint-disable @typescript-eslint/no-explicit-any */
import { AIMessage, AnswerMessage, DevOpsMessage, PanelMessage, registerNotificationTokenMessage } from '..';
import { BotMessage } from './bot';
import { CanvasMessage } from './canvas';
import { CommentMessage } from './comment';
import { InquiryMessage } from './inquiry';
import { InvitationMessage } from './invitation';
import { MediaMessage } from './media';
import { MemberMessage, UserMessage } from './admin';
import { MessageMessage } from './messages';
import { PaymentMessage } from './payments';
import { ProjectMessage } from './project';
import { SessionMessage } from './session';
import { SpaceMessage } from './space';
import { ModifyTemplateMessage } from './template';

/**
 * All administration related messages ie. admin of spaces, project, session, canvases and members
 */
export const ADMIN_BUNDLE = 'admin';

export type AdminMessage =
  CanvasMessage |
  InquiryMessage |
  InvitationMessage |
  MediaMessage |
  MemberMessage<any> |
  PanelMessage |
  PaymentMessage |
  ProjectMessage |
  SessionMessage |
  SpaceMessage |
  UserMessage |
  // DevOps
  ModifyTemplateMessage |
  DevOpsMessage |
  // Collaborate
  CommentMessage |
  AnswerMessage |
  MessageMessage |
  registerNotificationTokenMessage |
  // AI
  AIMessage |
  BotMessage;

export const DEV_OPS = 'devOps';
