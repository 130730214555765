import * as nanoid from 'nanoid';

/**
 * Generates a unique caller ID based on the given context.
 * @param context - The context array to check for existing caller IDs.
 * @returns The generated unique caller ID.
 */
const generateUniqueCallerId = (context: any[]) => {
  let callerId = nanoid.nanoid(16);
  while (context.find((s) => (s as any).callerId === callerId)) {
    callerId = nanoid.nanoid(16);
  }
  return callerId;
};

/**
 * Attaches a caller ID to the given subscribers array.
 * @param subscribers - The array of subscribers to attach the caller ID to.
 * @param callback - The callback function to attach the caller ID to.
 */
export const attachCallerId = (subscribers: any[], callback: any) => {
  const _callerId = generateUniqueCallerId(subscribers);
  (callback as any)._callerId = _callerId;
};

/**
 * Retrieves the caller ID from the given callback function.
 * @param callback - The callback function to retrieve the caller ID from.
 * @returns The caller ID of the callback function.
 */
const getCallerId = (callback: any) => {
  return (callback as any)._callerId;
};

/**
 * Checks if the given subscriber is the caller based on the provided caller ID.
 * @param subscriber - The subscriber to check.
 * @param caller - The caller ID to compare against.
 * @returns True if the subscriber is the caller, false otherwise.
 */
export const isCaller = (subscriber: any, caller: any) => {
  if (!caller) {
    return false;
  }
  if (Array.isArray(caller)) {
    for (const c of caller) {
      if (isCaller(subscriber, c)) {
        return true;
      }
    }
  }
  return getCallerId(subscriber) === caller;
};
