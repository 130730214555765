import type { TFunction } from 'next-i18next';

export const translateErrorCodeToHumanReadableError = (errorCode: string, t: TFunction): string => {
  switch (errorCode) {
    case 'auth/wrong-password':
      return t('auth/wrong-password');

    case 'auth/invalid-action-code':
      return t('auth/invalid-action-code');

    case 'auth/network-request-failed':
      return t('auth/network-request-failed');

    case 'auth/invalid-continue-uri':
    default:
      return t('Unexpected error occurred');
  }
};
