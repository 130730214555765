/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */
import { CanvasTab } from 'components/builder/configs/CanvasFormBuilderConfig';
import { ComponentFieldSelectionModel } from 'components/builder/models/selectionModels/ComponentFieldSelectionModel';
import { editorTypographyStyleCentered } from '../Matrix2DCanvasEditor';
import { useComponentDataContext } from 'utils/hooks/useComponentDataContext';
import { useComponentSelectionContext } from 'utils/hooks/useComponentSelectionContext';
import { useTranslation } from 'next-i18next';
import CategoryQuestionCanvasEditor from './CategoryQuestionCanvasEditor';
import Center from 'components/common/layout/Center';
import ContentContainer from '../../../../components/layout/ContentContainer';
import HTMLInplaceEditor from 'components/editing/HTMLInplaceEditor';
import React, { useCallback, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import type { BuilderConfig } from 'components/builder/builders/componentBuilder';
import type {
  CategoryQuestionCanvas as Canvas, CanvasId, CategoryItem, CategoryQuestionCanvas, Scale2DAnswer
} from '@shared/schema/src/spaces/projects/sessions/canvases';
import type { WithId } from '@mindhiveoy/schema';
import type { WithPathParamProps } from '@shared/schema/src/pathParams';

export interface Size {
  width: number;
  height: number;
}

export interface CategoryCanvasEditorProps extends WithPathParamProps {
  canvasId: CanvasId;
  canvas: CategoryQuestionCanvas;
  answer?: Scale2DAnswer;
}

const CategoryCanvasEditor = ({
  canvas,
}: CategoryCanvasEditorProps): JSX.Element => {
  const {
    model,
    updateProperty,
  } = useComponentDataContext<WithId<CategoryQuestionCanvas>>();

  const {
    setSelection,
  } = useComponentSelectionContext();

  const {
    t,
  } = useTranslation();

  const categoryItemConfig: BuilderConfig<CategoryItem> = useMemo(() => {
    return {
      type: 'category-item',
      displayName: t('category-item'),
      props: {
        name: {
          type: 'text',
          displayName: t('Name'),
          category: CanvasTab.GENERAL,
        },
        color: {
          type: 'color',
          displayName: t('color'),
          category: CanvasTab.GENERAL,
        },
        imageUrl: {
          type: 'image',
          displayName: t('image'),
          category: CanvasTab.GENERAL,
        },
      },
    };
  }, [t,]);

  const handleOptionClick = useCallback((
    _categoryId: string, _itemId: string, index: number
  ) => {
    setSelection(new ComponentFieldSelectionModel<CategoryItem, Canvas, any>(
      model as any, // TODO: fix type
      categoryItemConfig,
      `options[${index}]`
    ));
  }, [categoryItemConfig, model, setSelection,]);

  const handleCategoryClick = useCallback((categoryItem: CategoryItem) => {
    const index = canvas.categories.findIndex((c) => c.id === categoryItem.id);
    setSelection(new ComponentFieldSelectionModel<any, any, any>(
      model,
      categoryItemConfig,
      `categories[${index}]`
    ));
  }, [canvas.categories, categoryItemConfig, model, setSelection,]);

  // const handleCategoryNameEditorFocus = useCallback((propertyName: string) => {
  //   setSelection(new ComponentFieldSelectionModel<CategoryItem, Canvas, any>(
  //     model as any, // TODO: fix type
  //     categoryItemConfig,
  //     propertyName
  //   ));
  // }, [categoryItemConfig, model, setSelection,]);

  const handleCategoryNameRender = useCallback((category: any) => {
    return <>
      <HTMLInplaceEditor
        text={category.name}
        name={`categories.${category.id}.name`}
        // onFocus={handleCategoryNameEditorFocus}
        onChange={updateProperty as any}
      ></HTMLInplaceEditor>
    </>;
  }, [updateProperty,]);

  const handleDeleteCategory = useCallback((category: any) => {
    const categories = {
      ...canvas.categories,
    };
    delete categories[category];
    updateProperty(`categories`, categories);
  }, [canvas.categories, updateProperty,]);

  const handleCategoryNameChange = useCallback((categoryId: string, name: string) => {
    updateProperty(`categories.${categoryId}.name` as any, name);
  }, [updateProperty,]);

  return <ContentContainer>
    <Typography
      style={editorTypographyStyleCentered}
      component={'span'}
      variant="h2">
      <HTMLInplaceEditor
        text={canvas.name}
        name="name"
        onChange={updateProperty as any}
      ></HTMLInplaceEditor>
    </Typography>
    <Typography
      style={editorTypographyStyleCentered}
      component={'span'}
      variant="body1">
      <HTMLInplaceEditor
        text={canvas.desc}
        name="desc"
        onChange={updateProperty as any}
      ></HTMLInplaceEditor>
    </Typography>
    <Center direction="horizontal">
      <CategoryQuestionCanvasEditor
        canvas={canvas}
        onRenderCategoryName={handleCategoryNameRender}
        onCategoryNameChange={handleCategoryNameChange}
        onCategoryClick={handleCategoryClick as any}
        onDeleteCategory={handleDeleteCategory}
        onOptionClick={handleOptionClick as any} // TODO: Fix types
      />
    </Center>
  </ContentContainer>;
};

export default CategoryCanvasEditor;
