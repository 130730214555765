/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { removeBlocSystemFields } from '@shared/schema/src';
import isEqual from 'lodash/isEqual';
import type { DocumentConverter } from './databaseController/DocumentConverter';
import type { DocumentData } from '@mindhiveoy/firebase-schema';
import type { WithId } from '@mindhiveoy/schema';

/**
 * Abstract base class for bloc classes
 */
abstract class BlocBase<T extends DocumentData = any, P = any> {
  private _loadingCounter = 0;
  protected disabled = false;

  protected invalidState = false;
  /**
   *
   * @param {P} params
   */
  constructor(
    protected readonly params: P,
    protected convert?: DocumentConverter<T>,
    invalidState = false,
    disabled = false
  ) {
    this.disabled = disabled;
    this.invalidState = invalidState;
  }

  /**
   * Match if the given parameters match with ones used to create the bloc.
   *
   * @param {P}        params Params to compare with the current bloc params.
   * @return {boolean}        True when new params and old params are equivalent.
   */
  areParamsEqual = (params: P): boolean => {
    return isEqual(this.params, params);
  };
  /**
   * Dispose allocated resources of the bloc.
   */
  abstract dispose(): void;

  /**
   * Mark a new async operation active in bloc. There must always be a counter
   * pair call to `endLoading` for each startLoading -call.
   *
   * Please use this counter pair always with a try-finally -block inside an
   * async function.
   *
   * ```typescript
   * try {
   *   this.startLoading();
   *
   *   // async operations
   *
   * } finally {
   *   this.stopLoading();
   * }
   * ```
   *
   * Note that the one implementing the bloc -class must explicitly take care of
   * using these methods in code. Is is highly recommend to implement these to keep
   * the view side code more clean with no need to make the loading indicator logic
   * itself. Besides that, this implementation will handle multiple the logic with
   * the multiple simultaneous async calls also.
   *
   * @see BlocBase#endLoading
   */
  public startLoading = () => {
    this._loadingCounter++;
  };

  /**
   * End a loading operation.
   *
   * @see BlocBase#startLoading
   */
  public endLoading = () => {
    this._loadingCounter--;
  };

  /**
   * Indicates if there are any loading operations active on this block.
   */
  public get isLoading() {
    return this._loadingCounter > 0;
  }

  /**
   * Preprocess the document before using it locally.
   * @param backendDoc   Document received from the backend.
   * @returns            Document to be used locally.
   */
  public preprocessDoc = (backendDoc: WithId<T>): WithId<T> => {
    if (!backendDoc) {
      return backendDoc;
    }
    return removeBlocSystemFields(backendDoc as any) as WithId<T>;
  };

  public preprocessDocs = (docs: WithId<T>[]): WithId<T>[] => {
    if (!docs) {
      return docs;
    }
    return docs.map((doc) => this.preprocessDoc(doc));
  };
}

export default BlocBase;
