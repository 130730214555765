import { ADMIN_BUNDLE } from '@shared/schema/src/https/bundles';
import { createBundleFunctionFactory } from 'utils/firebase/bundles/createBundleFunctionFactory';
import type { CreateCommentMessage, SetCommentLabelsMessage, UpdateCommentMessage, VoteCommentMessage } from '@shared/schema/src';

const factory = createBundleFunctionFactory(ADMIN_BUNDLE);

export const createComment = factory<CreateCommentMessage>('create-comment');

export const updateComment = factory<UpdateCommentMessage>('update-comment');

export const voteComment = factory<VoteCommentMessage>('vote-comment');

export const setCommentLabels = factory<SetCommentLabelsMessage>('set-comment-labels');
