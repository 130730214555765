import { decode } from 'html-entities';
import LexicalInplaceEditor from './LexicalInplaceEditor';
import React, { useCallback, useMemo, useRef } from 'react';
import type { AllowedStyle } from './LexicalInplaceEditor';
import type { CSSProperties } from 'react';

// const NON_BREAKING_SPACE = '\u00A0';

// const INSERT_TEXT_HERE = `[${NON_BREAKING_SPACE}insert${NON_BREAKING_SPACE}text${NON_BREAKING_SPACE}here${NON_BREAKING_SPACE}]`;

export const ALL_STYLES_ALLOWED: AllowedStyle[] = [
  'bold',
  'italic',
  'underline',
  'link',
  'paragraph',
  'line-break',
  'header-1',
  'header-2',
  'header-3',
  'list',
  'ordered-list',
];

export const EMPHASIS_STYLES_ALLOWED: AllowedStyle[] = ['bold', 'italic', 'underline', 'line-break',];

export interface InplaceEditorProps<T extends Record<string, any> = Record<string, any>> {
  text?: string;
  placeHolder?: string | JSX.Element;
  textAlign?: 'left' | 'center' | 'right' | 'justify';
  // vertical?: boolean;
  writingMode?: 'horizontal' | 'vertical-rl' | 'vertical-lr';
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  allowedStyles?: AllowedStyle[];
  showToolbar?: boolean;
  centerToolbar?: boolean;
  dark?: boolean;
  fluid?: boolean;
  /**
   * Escape HTML characters from the output. Use when the expected output
   * should be a plain string.
   */
  // decodeHTML?: boolean;
  name: keyof T;
  saveOnEnterPress?: boolean;

  onBlur?: (name: keyof T, html: string,) => void;
  onChange: (name: keyof T, html: string,) => void;
  onFocus?: (name: keyof T) => void;
}

const HTMLInplaceEditor = <T extends Record<string, any> = Record<string, any>>({
  allowedStyles,
  centerToolbar,
  containerStyle,
  dark,
  fluid,
  name,
  placeHolder = '',
  saveOnEnterPress,
  showToolbar,
  style,
  text = '',
  textAlign = 'center',
  writingMode = 'horizontal',
  onBlur,
  onChange,
}: InplaceEditorProps<T>) => {
  const ref = useRef(text);

  const handleChange = useCallback((html: string | undefined) => {
    const current = ref.current;
    const text = decode(html);

    if (current !== text) {
      ref.current = text;
      onChange && onChange(name, text);
    }
  }, [name, onChange,]);

  const s = useMemo(() => {
    return {
      ...style,
      textAlign,
      ...fluid && {
        width: '100%',
        height: '100%',
      },
    };
  }, [fluid, style, textAlign,]);

  const handleBlur = useCallback(() => {
    onBlur?.(name, text);
  }, [name, onBlur, text,]);

  return <LexicalInplaceEditor
    allowedStyles={allowedStyles}
    centerToolbar={centerToolbar}
    containerStyle={containerStyle}
    dark={dark}
    forceEdit
    initialHtml={text}
    placeHolder={placeHolder}
    saveOnEnterPress={saveOnEnterPress}
    showToolbar={showToolbar}
    style={s}
    writingMode={writingMode}
    onBlur={handleBlur}
    onHtmlChange={handleChange}
  />;
};

export default HTMLInplaceEditor;
