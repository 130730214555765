/* eslint-disable @typescript-eslint/no-explicit-any */
import { animate, motion, useMotionValue } from 'framer-motion';
import {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import CategoryItem from './CategoryItem';
import type { CSSProperties } from 'react';
import type {
  CategoryContainerModel, CategoryDescriptor, CategoryState, Offsets, WithIdInfo
} from './CategoryContainerModel';
import type { RenderCategoryItemFunction } from './CategoryItem';

import { useTheme } from '@emotion/react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styled from '@emotion/styled';

const LabelRow = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

interface CategoryContainerProps<C extends CategoryDescriptor = CategoryDescriptor, T extends WithIdInfo = any> {
  edit?: boolean;
  model: CategoryContainerModel<C, T>;
  category: CategoryDescriptor;
  onRenderItem: RenderCategoryItemFunction<T>
  onClick?: (category: CategoryDescriptor) => void;
  onRenderCategoryName?: (category: CategoryDescriptor, index: number) => JSX.Element;
}

export const itemTransitionStyle = {
  type: 'ease',
  stiffness: 500,
  duration: 0.2,
};

const dropTargetStyle = {
  borderColor: 'rgba(255,255,255,0.8)',
};
const normalStyle = {
  borderColor: 'rgba(0,0,0,0.8)',
};

const CategoryContainer = ({
  edit,
  model,
  category,
  onClick,
  onRenderItem,
  onRenderCategoryName,
}: CategoryContainerProps) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const theme = useTheme();

  const canDrag = edit && !category.fixed;

  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const title = useRef<HTMLDivElement>(null);

  const [items, setItems,] = useState(model.items(category.id));
  const [offsets, setOffsets,] = useState<Offsets>({});
  const [minHeight, setMinHeight,] = useState<number>();

  const [state, setState,] = useState<CategoryState>({});
  const [dragging, setDragging,] = useState(false);

  useEffect(() => model.subscribeForCategoryStateChanges(
    category.id,
    (state) => {
      setState(state);
    }), [category.id, model,]);

  useEffect(() => model.subscribeForCategory(
    category.id,
    async (
      items,
      offsets,
      minHeight
    ) => {
      setItems(items as any);
      setOffsets(offsets);
      if (minHeight) {
        const height = (title.current?.offsetHeight ?? 48) + minHeight + 64;
        setMinHeight(height);
      } else {
        setMinHeight(undefined);
      }
    }), [category.id, model,]);

  useEffect(() => {
    model.setCategoryRef(category.id, ref, contentRef);
  }, [category.id, model,]);

  const handleDeleteContainer = useCallback(() => {
    model.deleteCategory(category.id);
  }, [category.id, model,]);

  const handleDragStart = useCallback(() => {
    setDragging(true);
    model.startCategoryDrag(category.id);
  }, [category.id, model,]);

  const handleDrag = useCallback(
    (event: MouseEvent | TouchEvent | PointerEvent) => {
      const {
        pageX,
        pageY,
      } = event as any;
      model.onCategoryDrag(category.id, pageX, pageY);
      event.stopPropagation();
    }, [category.id, model,]);

  const handleDragEnd = useCallback(async () => {
    setDragging(false);

    const promises = [];

    promises.push(animate(x, 0));
    promises.push(animate(y, 0));

    await Promise.all(promises);
  }, [x, y,]);

  const handleAnimationStart = useCallback(() => {
    if (!dragging) {
      model.setCategoryAnimating(category.id, true);
    }
  }, [category.id, dragging, model,]);

  const handleAnimationComplete = useCallback(() => {
    if (!dragging) {
      model.setCategoryAnimating(category.id, false);
    }
  }, [category.id, dragging, model,]);

  const categoryLabelStyle = useMemo(() => {
    const text = () => {
      try {
        theme.palette.getContrastText(category.color);
      } catch (e) {
        return 'black';
      }
    };
    const color = text();
    return {
      color,
    };
  }, [category.color, theme.palette,]);

  const handleClick = useCallback((event: any) => {
    if (onClick) {
      onClick(category);
    }
    event.stopPropagation();
  }, [category, onClick,]);

  const containerStyle: CSSProperties = useMemo(() => ({
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    minHeight: minHeight ? minHeight : 'auto',
    height: minHeight ? minHeight : 'auto',
    x,
    y,
    gap: 12,
    backgroundColor: category.color,
    padding: 16,
    minWidth: '32%',
    borderWidth: 4,
    borderStyle: 'solid',
  }), [category.color, minHeight, x, y,]);

  const label = useMemo(() => {
    if (onRenderCategoryName) {
      return onRenderCategoryName(category, 0);
    }
    return category.name;
  }, [category, onRenderCategoryName,]);

  return <motion.div
    ref={ref}
    layoutId={`cat_${category.id}`}
    layout="position"
    drag={canDrag}
    onDragStart={canDrag ? handleDragStart : undefined}
    onDrag={canDrag ? handleDrag : undefined}
    onDragEnd={canDrag ? handleDragEnd : undefined}
    onClick={handleClick}
    onAnimationStart={handleAnimationStart}
    onAnimationComplete={handleAnimationComplete}
    animate={state?.isDropTarget ? dropTargetStyle : normalStyle}
    style={containerStyle}
    transition={itemTransitionStyle}
  >
    <LabelRow ref={title}>
      {canDrag &&
        <motion.div onPointerDown={handleDragStart} >
          <DragIndicatorIcon
            onClick={handleDragStart}
          />
        </motion.div>}
      <span style={categoryLabelStyle}>{label}</span>
      {canDrag && <HighlightOffIcon onClick={handleDeleteContainer} />}
    </LabelRow>
    <div ref={contentRef}>1
      {
        items?.map((item) => {
          return <CategoryItem
            key={item.itemId}
            model={model}
            categoryId={category.id}
            id={item.itemId}
            data={item}
            offset={offsets?.[item.itemId]}
            onRender={onRenderItem}
          />;
        })
      }
    </div>
  </motion.div>;
};

export default CategoryContainer;
