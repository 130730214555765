import ReactPlayer from 'react-player';
import styled from '@emotion/styled';
import type { BuilderComponentPropsBase } from '../PageWidgetRenderer';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { URL } from '@mindhiveoy/schema';

export type HeroLayout = 'image-left' | 'image-right';

export interface TimeWindow {
  startTime?: number;
  endTime?: number;
}

export interface VideoProps {
  title?: string;
  muteAudio?: boolean;
  altText?: string;
  autoPlay?: boolean;
  loop?: boolean;
  timeWindow?: TimeWindow;
  url?: URL;
}

export const VIDEO_DEFAULT_PROPS: VideoProps = {
  autoPlay: true,
  loop: true,
};

export const VideoContainer = styled.div`
  display: flex; 
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

export interface VideoWidgetProps extends BuilderComponentPropsBase<VideoProps> {
  type: 'video';
}

const VideoWidget: BuilderFunction<VideoWidgetProps, VideoProps> = ({
  data: {
    // title = VIDEO_DEFAULT_PROPS.title,
    autoPlay = VIDEO_DEFAULT_PROPS.autoPlay,
    loop = VIDEO_DEFAULT_PROPS.loop,
    muteAudio = VIDEO_DEFAULT_PROPS.muteAudio,
    // altText = VIDEO_DEFAULT_PROPS.altText,
    // timeWindow = VIDEO_DEFAULT_PROPS.timeWindow,
    url = VIDEO_DEFAULT_PROPS.url,
  } = VIDEO_DEFAULT_PROPS,
}: VideoWidgetProps) => {
  return <VideoContainer >
    {
      url ? <ReactPlayer
        url={url}
        loop={!!loop}
        playing={!!autoPlay}
        muted={!!muteAudio}
        width='100%'
        height='100%'
      /> : 'no url provided'
    }
  </VideoContainer>;
};

VideoWidget.config = {
  type: 'video',
  displayName: 'Video',
  props: {
    // title: {
    //   category: 'general',
    //   type: 'text',
    // },
    autoPlay: {
      category: 'general',
      type: 'checker',
    },
    loop: {
      category: 'general',
      type: 'checker',
    },
    muteAudio: {
      category: 'general',
      type: 'checker',
    },
    // altText: {
    //   category: 'general',
    //   type: 'text',
    // },
    url: {
      category: 'general',
      type: 'text',
    },
  },
};

export default VideoWidget;
