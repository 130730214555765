/* eslint-disable @typescript-eslint/no-explicit-any */
import { $generateHtmlFromNodes } from '@lexical/html';
import { $getRoot, CLEAR_EDITOR_COMMAND } from 'lexical';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { AutoLinkNode } from '@lexical/link';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { EditorArea, InputArea, Placeholder } from './styles';
import { EditorRefPlugin } from './plugins/EditorRefPlugin';
import { HashtagNode } from '@lexical/hashtag';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ListItemNode, ListNode } from '@lexical/list';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { MentionNode } from './nodes/MentionNode';
import { ORDERED_LIST, UNORDERED_LIST } from '@lexical/markdown';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import AnimateHeight from 'components/common/AnimateHeight';
import FocusPlugin from './plugins/FocusPlugin';
import LexicalAutoLinkPlugin from './plugins/LinkPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import MentionsPlugin from './plugins/MentionsPlugin';
import React, { useCallback, useEffect, useState } from 'react';
import SetDataPlugin from './plugins/SetDataPlugin';
import SubmitPlugin from './plugins/SubmitPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import type { CSSProperties, MutableRefObject, PropsWithChildren } from 'react';
import type { EditorState, LexicalEditor, SerializedElementNode } from 'lexical';
import type { InitialConfigType } from '@lexical/react/LexicalComposer';
import type { MentionSuggestion } from './plugins/MentionsPlugin';
import type { Transformer } from '@lexical/markdown';

/**
 * Enum for the different editor modes.
 * RICH: Rich text editor with formatting options.
 * PLAIN: Plain text editor without formatting options.
 */
export enum TextEditorMode {
  RICH, PLAIN
}

/**
 * Enum for the different default plugins.
 */
export enum PresetEditorPlugin {
  ON_CHANGE, HISTORY, AUTO_FOCUS, LIST, CLEAR_EDITOR, TOOLBAR, SUBMIT,
  MARKDOWN_SHORTCUT, FOCUS, SET_DATA, MENTIONS, LINK, HASHTAG, EDITOR_REF
}

export const defaultPresetPlugins = [
  /**
   * OnChangePlugin is used to update the editor state when the editor content changes.
   * To be used together with onChange prop.
   */
  PresetEditorPlugin.ON_CHANGE,
  /**
   * HistoryPlugin is used to enable undo and redo functionality.
   */
  PresetEditorPlugin.HISTORY,
  /**
   * AutoFocusPlugin is used to automatically focus the editor when it is mounted.
   */
  PresetEditorPlugin.AUTO_FOCUS,
  /**
   * ListPlugin is used to enable list functionality.
   */
  PresetEditorPlugin.LIST,
  /**
   * ClearEditorPlugin is used to clear the editor content.
   */
  PresetEditorPlugin.CLEAR_EDITOR,
  /**
   * ToolbarPlugin is used to display a toolbar with formatting options.
   * If needed, you can build your own fully-customized toolbar by creating a separate Lexical plugin
   * and providing it as a child of the TextEditor component.
   */
  PresetEditorPlugin.TOOLBAR,
  /**
   * SubmitPlugin is used to submit the editor content on Ctrl-Enter / Cmd-Enter.
   */
  PresetEditorPlugin.SUBMIT,
  /**
   * MarkdownShortcutPlugin is used to enable markdown shortcuts, e.g. when you type **text** it will be converted to bold text,
   * or when you start typing 1. it will be converted to an ordered list.
   */
  PresetEditorPlugin.MARKDOWN_SHORTCUT,
  /**
   * FocusPlugin keeps track of focus state of the editor.
   */
  PresetEditorPlugin.FOCUS,
  /**
   * SetDataPlugin is used to set the editor content.
   */
  PresetEditorPlugin.SET_DATA,
  /**
   * MentionsPlugin is used to enable mentions functionality.
   */
  PresetEditorPlugin.MENTIONS,
  /**
   * LinkPlugin is used to enable link functionality.
   */
  PresetEditorPlugin.LINK,
  /**
   * HashtagPlugin is used to enable hashtag functionality.
   */
  PresetEditorPlugin.HASHTAG,
  /**
   * EditorRefPlugin is used to get a reference to the editor from outside the component.
   */
  PresetEditorPlugin.EDITOR_REF,
];

/**
 * Props for the TextEditor component.
 */
export interface TextEditorProps {
  /**
   * Initial configuration of the editor in the form of a Lexical InitialConfigType.
   */
  initialConfig?: InitialConfigType;
  /**
   * Mode of the editor. RICH or PLAIN. Default is RICH.
   */
  mode?: TextEditorMode;
  /**
   * Class name for the content editable element of the editor.
   * Default is 'editor-input'.
   */
  contentEditableClassName?: string;

  contentEditableStyle?: CSSProperties;
  /**
   * Default plugins are plugins that are already configured to work
   * under the hood of @mindhiveoy/react-text-editor. If you want to use a plugin that is not included in this enum,
   * you can provide it as a child to the TextEditor component.
   */
  presetPlugins?: PresetEditorPlugin[];
  /**
   * Shortcut transformers for the MarkdownShortcutPlugin.
   */
  markdownShortcutTransformers?: Transformer[];
  /**
   * Text that is displayed when the editor is empty.
   */
  placeholderText?: string;
  /**
   * Whether to show the submit button or not. Default is false.
   */
  showSubmitButton?: boolean;
  /**
   * If true, the editor content will be cleared after it is submitted.
   * Default is true.
   */
  clearAfterSubmit?: boolean;
  /**
   * Initial editor content in the form of a html string.
   */
  initialHtmlData?: string;
  /**
   * UI component for the editor area (things that are close to the editor input, but are not a part of it,
   * however, they can still access the editor state - e.g. "submit" button).
   */
  EditorAreaComponent?: any;
  /**
   * UI component for the input area.
   */
  InputAreaComponent?: any;
  /**
   * CSS class name for the TextEditor component.
   */
  className?: string;
  /**
   * Shrink the layout when not focused
   */
  shrink?: boolean;
  /**
   * Editor reference to manipulate the editor state from outside the editor.
   */
  editorRef?: MutableRefObject<LexicalEditor | null>;
  /**
   * Callback function that is called when the editor content changes.
   * To be used together with the OnChangePlugin.
   */
  onChange?: (editorState: EditorState, editor: LexicalEditor) => void;
  /**
   * Callback function that is called when the editor content changes. Returns the HTML string.
   * To be used together with the OnChangePlugin.
   */
  onHtmlChange?: (html: string) => void;
  /**
   * Callback function that is called when the editor content changes. Returns SerializedElementNode.
   * To be used together with the OnChangePlugin.
   */
  onJSONChange?: (json: SerializedElementNode) => void;
  /**
   * Callback function that is called when the editor gets focus.
   * To be used with FocusPlugin.
   */
  onFocus?: () => void;
  /**
   * Callback function that is called when the editor loses focus.
   * To be used with FocusPlugin.
   */
  onBlur?: () => void;
  /**
   * Function to lookup mentions.
   * To be used with the MentionsPlugin.
   */
  onLookupMentions?: (query: string) => MentionSuggestion[];
  /**
     * Callback function that is called when the editor content is submitted.
     * TODO: flatNodes typing
     */
  onSubmitHtml?: (html: string, json?: SerializedElementNode) => Promise<boolean>;
}

/**
 * Default theme for the text editor.
 */
const defaultTextEditorTheme = {
  placeholder: 'editor-placeholder',
  paragraph: 'editor-paragraph',
  text: {
    bold: 'editor-text-bold',
    italic: 'editor-text-italic',
    // overflowed: 'editor-text-overflowed',
    hashtag: 'editor-text-hashtag',
    underline: 'editor-text-underline',
    // strikethrough: 'editor-text-strikethrough',
    // underlineStrikethrough: 'editor-text-underlineStrikethrough',
    code: 'editor-text-code',
  },
  hashtag: 'editor-hashtag',
};

/**
 * Default namespace for the text editor.
 */
export const DEFAULT_EDITOR_NAMESPACE = 'default-react-text-editor';

/**
 * Default shortcut transformers for the MarkdownShortcutPlugin.
 */
const defaultMarkdownShortcutTransformers = [UNORDERED_LIST, ORDERED_LIST,];

/**
 * Default initial configuration for the text editor.
 */
export const defaultInitialEditorConfig = {
  theme: defaultTextEditorTheme,
  namespace: DEFAULT_EDITOR_NAMESPACE,
  onError: (error: Error) => {
    console.error(error);
  },
  nodes: [
    ListItemNode, ListNode, MentionNode, AutoLinkNode, HashtagNode,
  ],
};

const DEFAULT_TRUE_PROMISE = () => Promise.resolve(true);
/**
 * Generic text editor component that can be used to create rich text editors or plain text editors.
 * The editor is built on top of the Lexical editor framework.
 * The component can be widely adjusted and extended by using custom plugins.
 * At the same time, most of the common use cases are covered by the default plugins.
 * @param {TextEditorProps} props
 */
const TextEditor = ({
  children,
  className,
  clearAfterSubmit = true,
  // TODO: Do not use default class names. Lets stick with the pattern to keep the styles and component together.
  contentEditableClassName = 'editor-input',
  contentEditableStyle,
  EditorAreaComponent = EditorArea as any,
  initialConfig = defaultInitialEditorConfig,
  initialHtmlData,
  InputAreaComponent = InputArea as any,
  markdownShortcutTransformers = defaultMarkdownShortcutTransformers,
  mode = TextEditorMode.RICH,
  placeholderText = 'Start typing...',
  presetPlugins = defaultPresetPlugins,
  showSubmitButton = false,
  shrink,
  editorRef,
  onBlur,
  onChange,
  onFocus,
  onHtmlChange,
  onJSONChange,
  onLookupMentions,
  onSubmitHtml = DEFAULT_TRUE_PROMISE,
}: PropsWithChildren<TextEditorProps>) => {
  // Possibly use useRef for synchronous updates but no re-rendering effect
  const [hasFocus, setFocus,] = useState(false);

  /**
   * Check if the given preset plugin is in use.
   * TODO: make a better solution. Didn't think that there will be that many preset plugins.
   */
  const isPresetPluginInUse = useCallback((plugin: PresetEditorPlugin) => {
    return !!presetPlugins?.includes(plugin);
  }, [presetPlugins,]);

  /**
   * Convert the editor content to HTML and call the onSubmitHtml callback.
   * Clear the editor content if clearAfterSubmit is true and the onSubmitHtml callback returns true.
   */
  const onSubmit = useCallback(async (editor: LexicalEditor) => {
    try {
      let submitHtmlResult = true;
      try {
        let plainText = '';
        editor.getEditorState().read(() => {
          plainText = $getRoot().getTextContent();
        });

        if (plainText.length > 0) {
          const handleSubmit = async () => {
            return new Promise<void>((resolve) => {
              editor.update(async () => {
                const html = $generateHtmlFromNodes(editor, null);
                const json = editor.getEditorState().toJSON().root;
                submitHtmlResult = await onSubmitHtml(html, json);
                resolve();
              });
            });
          };

          await handleSubmit();
        } else {
          submitHtmlResult = false;
        }
      } catch (e) {
        submitHtmlResult = false;
        console.error(e);
      } finally {
        if (submitHtmlResult && clearAfterSubmit) {
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [clearAfterSubmit, onSubmitHtml,]);

  const onEditorContentChange = useCallback((editorState: EditorState, editor: LexicalEditor) => {
    if (onChange) {
      onChange(editorState, editor);
    }
    if (onHtmlChange || onJSONChange) {
      editorState.read(() => {
        if (onHtmlChange) {
          const htmlString = $generateHtmlFromNodes(editor, null);
          onHtmlChange(htmlString);
        }
        if (onJSONChange) {
          const editorJSONState = editorState.toJSON();
          onJSONChange(editorJSONState.root);
        }
      });
    }
  }, [onChange, onHtmlChange, onJSONChange,]);

  useEffect(() => {
    if (hasFocus) {
      onFocus?.();
      return;
    }
    onBlur?.();
  }, [onBlur, onFocus, hasFocus,]);

  return <LexicalComposer initialConfig={initialConfig}>
    <EditorAreaComponent className={className} >
      <InputAreaComponent
        $focused={hasFocus}
        onClick={() => setFocus(true)}
      >
        {mode === TextEditorMode.RICH ?
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className={contentEditableClassName}
                style={contentEditableStyle}
              />
            }
            placeholder={
              <Placeholder className={initialConfig?.theme?.placeholder}>
                {placeholderText}
              </Placeholder>
            }
            ErrorBoundary={LexicalErrorBoundary}
          /> :
          <PlainTextPlugin
            contentEditable={
              <ContentEditable className={contentEditableClassName}
                style={contentEditableStyle}
              />
            }
            placeholder={<Placeholder className={initialConfig?.theme?.placeholder}>{placeholderText}</Placeholder>}
            ErrorBoundary={LexicalErrorBoundary}
          />}
        {isPresetPluginInUse(PresetEditorPlugin.ON_CHANGE) ? <OnChangePlugin onChange={onEditorContentChange} /> : null}
        {isPresetPluginInUse(PresetEditorPlugin.SET_DATA) ? <SetDataPlugin htmlString={initialHtmlData} /> : null}
        {isPresetPluginInUse(PresetEditorPlugin.HISTORY) ? <HistoryPlugin /> : null}
        {isPresetPluginInUse(PresetEditorPlugin.AUTO_FOCUS) ? <AutoFocusPlugin defaultSelection='rootEnd' /> : null}
        {isPresetPluginInUse(PresetEditorPlugin.LIST) ? <ListPlugin /> : null}
        {isPresetPluginInUse(PresetEditorPlugin.MARKDOWN_SHORTCUT) ? <MarkdownShortcutPlugin transformers={markdownShortcutTransformers} /> : null}
        {isPresetPluginInUse(PresetEditorPlugin.CLEAR_EDITOR) ? <ClearEditorPlugin /> : null}
        {isPresetPluginInUse(PresetEditorPlugin.FOCUS) ? <FocusPlugin onFocusChange={setFocus} /> : null}
        {isPresetPluginInUse(PresetEditorPlugin.MENTIONS) ? <MentionsPlugin onLookupMentions={onLookupMentions} /> : null}
        {isPresetPluginInUse(PresetEditorPlugin.LINK) ? <LexicalAutoLinkPlugin /> : null}
        {isPresetPluginInUse(PresetEditorPlugin.HASHTAG) ? <HashtagPlugin /> : null}
        {isPresetPluginInUse(PresetEditorPlugin.EDITOR_REF) ? <EditorRefPlugin editorRef={editorRef ? editorRef : () => undefined} /> : null}
        {children}
      </InputAreaComponent>
      {isPresetPluginInUse(PresetEditorPlugin.TOOLBAR) ?
        <AnimateHeight visible={(shrink && hasFocus || !shrink)}>
          <ToolbarPlugin showSubmitButton={showSubmitButton}
            onSubmit={onSubmit} />
        </AnimateHeight> : null}
      {isPresetPluginInUse(PresetEditorPlugin.SUBMIT) ?
        <SubmitPlugin onSubmit={onSubmit} /> : null}
    </EditorAreaComponent>
  </LexicalComposer>;
};

export default TextEditor;
