
import { ClickAwayListener, useMediaQuery } from '@mui/material';

import { generatePathUrl } from 'utils/urls';
import { handleItemRender } from 'components/cards/cardUtils';
import { motion } from 'framer-motion';
import { useProject } from 'bloc/project/ProjectBloc';
import { useRouter } from 'next/router';
import { useSession } from 'bloc/session/SessionBloc';
import { useTheme } from '@emotion/react';
import CanvasViewBlock from './CanvasViewBlock';
import CardStrip from 'components/cards/CardStrip';
import Center from 'components/common/layout/Center';
import CommentForm from 'components/comments/CommentForm';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { CanvasStripInfo, Project, Session } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';
// import LoadingIndicator from 'components/common/LoadingIndicator';
import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import TinyCanvasNavigator from 'components/layout/TinyCanvasNavigator';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';

export const BOTTOM_BAR_SHIFT = 280;

const NavigationContainer = styled$(motion.div)<{ $isMobile: boolean; }>(({
  theme,
  $isMobile,
}) => `
  position: fixed;
  bottom: 0;
  background-color: ${theme.palette.background.default};
  backdrop-filter: blur(${theme.shape.smokeGlassBlur}px);
  padding: ${theme.spacing($isMobile ? 1 : 2)};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: height 0.5s ease-in-out;
  z-index: 1000;
  ${
  // Lift the bottom navigation in development mode on the top of Firebase Emulator's emulator mode box
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' && 'margin-bottom: 24px;'}
`);

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NavigationButtons = styled(motion.div)(({
  theme,
}) => `
  position: relative;
  margin-top: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
  max-width: 100vw;
  @media(max-width: 600px) {
    margin-top: 0;
  }
`);

const Navigation = styled.div`
  display: flex;
  align-items: row;
`;

const StyledButton = styled(motion.button)`
  border-radius: 50%;
  background-color: transparent;
  width: 56px;
  height: 56px;
  border: none;
  color: white;
  &:hover {
    background-color: #ffffff1F;
  }
`;

const BottomNavigation = () => {
  const bottomNaviRef = useRef<HTMLDivElement>(null);

  const [session, setSession,] = useState<WithId<Session>>();
  const [showCanvases, setShowCanvases,] = useState<boolean>(false);
  const [allowCommenting, ,] = useState<boolean>(true);
  const [project, setProject,] = useState<WithId<Project>>();

  const projectBloc = useProject();
  const sessionBloc = useSession();

  const theme = useTheme();

  const {
    query,
    push,
  } = useRouter();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => projectBloc.subscribe(
    setProject,
    (error) => console.error(error)
  ), [projectBloc,]);

  useEffect(() => sessionBloc.subscribe(
    ['canvasStrip',],
    setSession,
    (error) => console.error(error)
  ), [sessionBloc,]);

  const toggleNavigationOpen = useCallback(() => {
    setShowCanvases(!showCanvases);
  }, [showCanvases,]);

  // click away
  const handleCloseClick = useCallback(() => {
    setShowCanvases(false);
  }, []);

  const arrowStyle = useMemo(() => ({
    transform: `rotateZ(${showCanvases ? 0 : 180}deg)`,
    width: isMobile ? '30px' : '40px',
    height: isMobile ? '30px' : '40px',
  }), [showCanvases, isMobile,]);

  const {
    canvasStrip,
  } = useMemo(() => {
    const canvasStrip: CanvasStripInfo[] = session?.canvasStrip ?? [];

    if (!canvasStrip) {
      return {
        first: true,
        last: true,
      };
    }
    return {
      canvasStrip,
      first: canvasStrip[0]?._id === query?.canvasId,
      last: canvasStrip[canvasStrip.length - 1]?._id === query?.canvasId,
    };
  }, [session?.canvasStrip, query,]);

  const handlePreviousClick = useCallback(() => {
    if (!canvasStrip) {
      return;
    }
    const index = Math.max(0, canvasStrip.findIndex((q) => q._id === query.canvasId) - 1);

    const canvasId = canvasStrip[index]._id;
    // setActiveCanvasId(canvasId);
    const path = generatePathUrl({
      ...query,
      canvasId,
    }, 'canvasId');
    push(path, path, {
      shallow: true,
    });
  }, [push, query, canvasStrip,]);

  const handleNextClick = useCallback(() => {
    if (!canvasStrip) {
      return;
    }
    const index = Math.min(canvasStrip.length - 1, canvasStrip.findIndex((q) => q._id === query.canvasId) + 1);

    const canvasId = canvasStrip[index]._id;
    // setActiveCanvasId(canvasId);
    const path = generatePathUrl({
      ...query,
      canvasId,
    }, 'canvasId');
    push(path, path, {
      shallow: true,
    });
  }, [push, query, canvasStrip,]);

  // TODO: we'll use mock data to mark an answered question for now.
  // It requires more planning how to determine whether the question is answered
  // or not and some backend/bloc adjustments.
  const strip = useMemo(() => {
    const strip = session?.canvasStrip ?? [];
    return strip.map((c) => ({
      _id: c._id,
      title: c.name,
      image: c.media?.photoURL ?? theme.shape.imagePlaceholder,
      media: c.media,
      isAnswered: false, // i % 2, // TODO: mock data, replace
    }));
  }, [session?.canvasStrip, theme.shape.imagePlaceholder,]);

  const handleCardClick = useCallback((canvasId: string) => {
    const path = generatePathUrl({
      ...query,
      canvasId,
    }, 'canvasId'
    );
    push(path, path, {
      shallow: true,
    });
  }, [push, query,]);

  // const canvasBloc = useCanvas();

  // useEffect(() => canvasBloc.subscribe(
  //   (canvas) => {
  //     setCanvas(canvas);
  //     setLoading(!canvas);
  //   }), [canvasBloc,]);

  // TODO: quick fix 09.06.2023 in order not to flash the screen and rebuild everything when switching canvases
  // if (loading) {
  //   return <LoadingIndicator fullScreen />;
  // }
  // if (!loading && !canvas) {
  //   return null;
  // }

  const shift = session?.mode === 'live' ? 0 : BOTTOM_BAR_SHIFT;
  return <>
    {/* Bottom canvas drawer */}
    <ClickAwayListener onClickAway={handleCloseClick}>
      <NavigationContainer
        $isMobile={isMobile}
        key="bottom-navigation"
        ref={bottomNaviRef}
        layout
        // onAnimationComplete={handleAnimationComplete}
        initial={{
          y: shift,
        }}
        animate={{
          y: showCanvases ? 0 : shift,
        }}>
        <Content>
          {session?.mode !== 'live' && <TinyCanvasNavigator
            canvases={strip as any}
            hidden={showCanvases}
            selected={query?.canvasId as string}
            onClick={handleCardClick}
            onNextClick={handleNextClick}
            onPreviousClick={handlePreviousClick}
          />}

          {/* Comment input with rich text editor */}
          <CanvasViewBlock>
            {
              !showCanvases && allowCommenting /* && commentPath?.length === 0*/ &&
              <CommentForm
                isMobile={isMobile}
                nicks={project?.nicks}
              />
            }
          </CanvasViewBlock>

          {/* Bottom navigation */}
          <NavigationButtons>
            {session?.mode !== 'live' && <Center>
              <Navigation>
                <StyledButton
                  animate={{
                    y: [0, 5,],
                    opacity: [1, 0.9,],
                  }}
                  transition={{
                    repeat: Infinity,
                    repeatType: 'mirror',
                    duration: 0.6,
                  }}
                  onClick={toggleNavigationOpen}
                  title='list canvases'>
                  <ExpandMoreIcon style={arrowStyle} />
                </StyledButton>
              </Navigation>
            </Center>}
            {session?.mode !== 'live' &&
              <CardStrip
                isDrawerOpen={showCanvases}
                isBottomDrawer
                selected={query?.canvasId as string}
                cards={strip}
                reflection
                onCardClick={handleCardClick}
                onRenderItem={handleItemRender}
                onNextClick={handleNextClick}
                onPreviousClick={handlePreviousClick}
              />}
          </NavigationButtons>
        </Content>
      </NavigationContainer>
    </ClickAwayListener>
  </>;
};

export default BottomNavigation;
