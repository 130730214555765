import styled from '@emotion/styled';
import theme from 'theme';

export const AutoMargin = styled.div`
  ${theme.breakpoints.down('md')} {
    max-height: fit-content;
  }
  height: 100%;
  overflow: auto;
  margin-top: ${theme.spacing(2)};
`;
