/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

import { useComponentSelectionContext } from '../../../../../utils/hooks/useComponentSelectionContext';
import TextWidgetEditorPure from './TextWidgetEditorPure';
import type { BuilderFunction } from '../../../builders/componentBuilder';
import type { TextProps, TextWidgetProps } from '../../renderers/TextWidget';

const TextWidgetEditor: BuilderFunction<TextWidgetProps> = ({
  data,
}: TextWidgetProps) => {
  // TODO: Find out, why this is using selection like this
  const {
    selection,
  } = useComponentSelectionContext<TextProps>();

  const handleSave = useCallback((_name: string, value: string) => {
    selection?.updateProperty('text', value);
  }, [selection,]);

  return <TextWidgetEditorPure
    data={data}
    onSave={handleSave}
  />;
};

TextWidgetEditor.config = {
  type: 'text',
  displayName: 'Text',
};

export default TextWidgetEditor;
