
import { Comment, ProjectId } from '../../';
import { HttpMessage } from '../HttpMessage';
import { SpaceId, WithId } from '@mindhiveoy/schema';

/**
 * A short unique identifier for each AI prompt. This information
 * is being used to identify the AI prompt that is being used
 * in money transactions.
 */
export const enum AIPromptId {
  SUMMARIZE_COMMENTS = 'sum',
  ANALYZE_SENTIMENT_FOR_COMMENTS = 'sen',
  KEY_TAKEAWAYS_FOR_COMMENTS = 'key',
}

export type AIErrorCode =
  'prompt-too-long' |
  'invalid-response-format' |
  'no-response' |
  'unsupported-language';

export type AIMessage =
  SummarizeCommentsMessage |
  AnalysisSentimentForCommentsMessage |
  FutureWheelMessage |
  KeyTakeawaysMessage |
  WhatIsMissingMessage;

export interface FutureWheelRequest {
  spaceId: SpaceId;
  topic: string;
  outputLanguage?: string;
  topicCount: number;
  parentNodes?: WheelNode[];
  depth?: number;
}

export interface WheelNode {
  id: string;
  topic: string;
  depth: number;
  desc: string;
  parentId?: string;
}

export type FutureWheelMessage =
  HttpMessage<'future-wheel', FutureWheelRequest, FutureWheelResponse>;

export type FutureWheelResponse = WheelNode[];


export type SummarizeCommentsMessage =
  HttpMessage<'summarize-comments', SummarizeCommentsRequest, SummarizeCommentsResponse>;

export type AnalysisSentimentForCommentsMessage =
  HttpMessage<'analyze-sentiment-for-comments', AnalyzeSentimentForCommentsRequest, AnalyzeSentimentForCommentsResponse>;

export type KeyTakeawaysMessage =
  HttpMessage<'key-takeaways-for-comments', KeyTakeawaysForCommentsRequest, KeyTakeawaysForCommentsResponse>;

export type WhatIsMissingMessage =
  HttpMessage<'what-is-missing-in-comments', WhatIsMissingRequest, WhatIsMissingResponse>;

interface WithUserContext {
  spaceId: SpaceId;
  projectId?: ProjectId;
}

export interface WhatIsMissingRequest extends WithUserContext {
  comments: WithId<Comment>[];
  thesis: string;
  description: string;
  // sessionTitle: string;
  // sessionDesc: string;

  // projectName: string;
  // projectDesc: string;

  outputLanguage: string;
}

interface MissingTopic {
  title: string;
  description: string;
  tags: string[];
}

export interface WhatIsMissingResponse {
  theme: string;
  missingTopics: MissingTopic[];
}

export interface SummarizeCommentsRequest extends WithUserContext {
  comments: WithId<Comment>[];
  outputLanguage: string;
}

export interface SummarizeCommentsResponse {
  summary: string;
  tags: string[];
}

export interface AnalyzeSentimentForCommentsRequest extends WithUserContext {
  comments: WithId<Comment>[];
  outputLanguage: string;
}

export interface AnalyzeSentimentForCommentsResponse {
  summary: string;
  tone: string[];
  users: {
    [userId: string]: {
      summary: string;
      tone: string[];
    };
  }
}

export interface KeyTakeawaysForCommentsRequest extends WithUserContext {
  comments: WithId<Comment>[];
  outputLanguage: string;
}

interface Takeaway {
  title: string;
  summary: string;
  tags: string[];
}

export type KeyTakeawaysForCommentsResponse = Takeaway[];
