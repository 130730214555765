import { Item } from './Item';
import { ProcessedMessageType } from '@shared/schema/src';
import { Switch, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useAuth } from '@mindhiveoy/react-auth';
import { useCallback, useState } from 'react';
import { useClientMessages } from '../../../bloc/message/MessagesBloc';
import { useTranslation } from 'next-i18next';
import Feed from '../Feed';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import theme from 'theme';
import type { ProcessedMessageDoc, ReceivedMessage, SentMessage } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

const MessagesFeed = () => {
  const {
    user,
    role,
  } = useAuth();

  const {
    t,
  } = useTranslation();

  const messages = useClientMessages({
    uid: user?.uid,
  });

  const [items, setItems,] = useState<WithId<ProcessedMessageDoc>[]>([]);
  const [receivedMessages, setReceivedMessages,] = useState<WithId<ReceivedMessage>[]>([]);
  const [sentMessages, setSentMessages,] = useState<WithId<SentMessage>[]>([]);
  const [isSentMode, setIsSentMode,] = useState<boolean>(false);

  useEffect(() => messages.subscribe((items) => {
    setItems(!items?.length ? [] : items);
  }), [messages,]);

  useEffect(() => {
    if (!items?.length) {
      return;
    }
    const received = items.filter((item) => item.type === ProcessedMessageType.RECEIVED ||
      item.type === ProcessedMessageType.BOTH).map((item) => ({
      ...item.content,
    }));
    const sent = items.filter((item) => item.type === ProcessedMessageType.SENT ||
      item.type === ProcessedMessageType.BOTH).map((item) => ({
      ...item.content,
    }));
    setReceivedMessages(received as WithId<ReceivedMessage>[]);
    setSentMessages(sent as WithId<SentMessage>[]);
  }, [items,]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSentMode(event.target.checked);
  }, []);

  return <Feed>
    <SwitchArea>
      <Typography variant="body1">
        {t('received')}
      </Typography>
      <StyledSwitch
        checked={isSentMode}
        onChange={handleChange}
      />
      <Typography variant="body1">
        {t('sent')}
      </Typography>
    </SwitchArea>
    {
      isSentMode ? sentMessages?.length ?
        sentMessages.map((item) => <Item
          role={role!}
          key={item._id}
          item={item}
          sentMode={isSentMode}
        />) :
        <>{t('you-have-no-sent-messages')}
        </> : receivedMessages?.length ?
        receivedMessages.map((item) => <Item
          role={role!}
          key={item._id}
          item={item}
          sentMode={isSentMode}
        />) :
        <>{t('you-have-no-received-messages')}
        </>

    }</Feed>;
};

export const SwitchArea = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  `;

const StyledSwitch = styled(Switch)`
  margin: ${theme.spacing(1)};
  '& .MuiSwitch-track': {
    background-color: ${theme.palette.primary.main};
  }
  `;

export const StyleFeedItem = styled(motion.div)(({
  theme,
}) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  margin: ${theme.spacing(1)};
  padding: ${theme.spacing(2)};
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${theme.palette.background.innerPaper};
  cursor: pointer;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${theme.palette.primary.main};
    & .MuiAvatar-root {
      transform: scale(1.05);
    }
  }
`);

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${theme.spacing(1)};
`;

export const MessageArea = styled.div`
  // max-height: 120px;
  white-space: break-spaces;
  wrap: break-;
  text-overflow: ellipsis;
  margin-top: ${theme.spacing(1)};
`;

export const InfoItem = styled.div(({
  theme,
}) => `
  margin-left: ${theme.spacing(1)};
`);

export default MessagesFeed;
