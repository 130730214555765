import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

// import * as phone from 'google-libphonenumber';
import { CountryPicker } from 'components/common/CountryPicker';
import { useCallback, useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import type { KeyboardEventHandler } from 'react';
import type { PhoneNumberPropertyEditor } from '../../PropertyEditorTypes';
import type { PropertyEditorFunction } from '../../../builders/propertyEditorBuilder';

const PhoneNumberEditor: PropertyEditorFunction<string, PhoneNumberPropertyEditor> = ({
  data,
  propertyName,
  propertyConfig: {
    displayName,
    defaultValue,
    required = false,
    message,
    defaultCountryCode,
  },
  onSave,
}) => {
  const [value, setValue,] = useState<string>(data && data.length !== 0 ? data : defaultValue ?? ' ');
  const ref = useRef<HTMLInputElement>(null);
  const [editing, setEditing,] = useState(false);
  const [checkField, setCheckField,] = useState(false);
  const [countryCode, setCountryCode,] = useState(defaultCountryCode ?? 'FI');

  useEffect(() => {
    if (editing) {
      return;
    }
    if (data !== undefined && data !== value) {
      setValue(data);
      setCheckField(false);
    }
  }, [data, editing, value,]);

  // FIXME: The phone number editor is not working properly

  const handleSave = useCallback((
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value: string
  ) => {
    // const newValue = value?.trim();

    // if (newValue !== data && onSave) {
    //   onSave(propertyName, newValue);
    // }
  }, []);

  const lazeLoadPhoneNumberUtil = useCallback(async () => {
    return (await import('google-libphonenumber')).default as any;
  }, []);

  const handlePhoneNumberChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(async (event) => {
    const value = event.target.value;

    try {
      setValue(value);

      const phoneUtil = await lazeLoadPhoneNumberUtil();

      const parsed = phoneUtil.parse(value, defaultCountryCode ?? 'FI');
      const national = parsed.getNationalNumber();
      const extension = parsed.getExtensionOrDefault();
      const phoneNumber = extension + national;

      const countryCallingCode = parsed.getCountryCode() ?? defaultCountryCode;
      if (countryCallingCode) {
        const regionCodes = (phoneUtil as any).shortnumberinfo._getRegionCodesForCountryCode(countryCallingCode);
        const isoCountryCode = regionCodes[0];
        setCountryCode(isoCountryCode);
      }
      handleSave(phoneNumber);
    } catch (error) {
      console.error(error);
    }
    event?.stopPropagation();
  }, [defaultCountryCode, handleSave, lazeLoadPhoneNumberUtil,]);

  const handleFocus = useCallback((event: any) => {
    event.target.select();
    setEditing(true);
  }, []);

  const handleBlur = useCallback(() => {
    setEditing(false);
    setCheckField(true);
    handleSave(value);
  }, [handleSave, value,]);

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      handleSave(value);
    }
  }, [handleSave, value,]);

  const handleCountryChange = useCallback((value: any) => {
    if (value !== data && onSave) {
      onSave(propertyName, value);
    }
  }, [data, onSave, propertyName,]);

  return <>
    <FormControl fullWidth>
      <Stack direction="row"
        spacing={2}
        alignItems="center">
        <CountryPicker
          showDialCode
          showCountryCode
          value={countryCode}
          defaultCountry={defaultCountryCode}
          onChange={handleCountryChange}
        />

        <TextField
          ref={ref}
          variant="outlined"
          placeholder={propertyName}
          label={displayName ?? propertyName}
          id={propertyName}
          value={value}
          required={required}
          onFocus={handleFocus}
          onChange={handlePhoneNumberChange}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          fullWidth
          InputLabelProps={{
            style: {
              textTransform: 'capitalize',
            },
            shrink: value ? true : undefined, // undefined is needed for material ui to take control
          }}
        />
      </Stack>

    </FormControl>
    {required && !value && checkField &&
      <div style={{
        marginBottom: '1vh',
        color: '#E94926',
      }}>
        {message}
      </div>
    }
    {/* {user.email===value && <div style={{
    marginBottom: '1vh',
    color: '#E94926',
  }}>{additionalInfo}</div>} */}
  </>;
};

PhoneNumberEditor.config = {
  type: 'phone-number',
};

export default PhoneNumberEditor;
