import { useEffect, useState } from 'react';
import { useScrollAreaRef } from 'components/App';

const useScroll = () => {
  const [isScrolling, setIsScrolling,] = useState<boolean>(false);
  const ref = useScrollAreaRef();
  const scrollAreaRef = ref?.current;

  useEffect(() => {
    const isWindow = typeof window !== 'undefined';

    if (!isWindow) {
      return;
    }

    let timeout: any = null;
    const handleScroll = () => {
      setIsScrolling(true);
      if (timeout) { // if there is already a timeout in process cancel it
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        timeout = null;
        setIsScrolling(false);
      }, 400);

      !isScrolling && setIsScrolling(true);
    };
    scrollAreaRef?.addEventListener('scroll', handleScroll); // add event listener
    return () => {
      clearTimeout(timeout); // clean up
      scrollAreaRef?.removeEventListener('scroll', handleScroll); // clean up
    };
  }, [isScrolling, scrollAreaRef,]);

  return {
    isScrolling,
  };
};

export default useScroll;
