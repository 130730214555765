/* eslint-disable @next/next/no-img-element */

import InputLabel from '@mui/material/InputLabel';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { PropertyEditorFunction } from '../../../builders/propertyEditorBuilder';

import { CountryPicker } from '../../../../common/CountryPicker';
import { Typography } from '@mui/material';
import PropertyEditorControl from '../../PropertyEditorControl';
import type { CountryInfo } from '../../../../../utils/localization/countries';
import type { CountryPickerPropertyEditor } from '../../PropertyEditorTypes';

const CountryPickerEditor: PropertyEditorFunction<string, CountryPickerPropertyEditor<string>> = ({
  data,
  propertyName,
  propertyConfig: {
    displayName,
    defaultValue,
    section,
    showFlags,
    showDialCode,
    showCountryCode,
  },
  onSave,
}) => {
  const [value, setValue,] = useState(data ?? defaultValue);

  useEffect(() => {
    if (data && data !== value) {
      setValue(data);
    }
  }, [data, value,]);

  const handleChange = useCallback((value: CountryInfo | null) => {
    if (!value) {
      return;
    }
    const newValue = value.code;
    setValue(newValue);
    if (newValue !== data && onSave) {
      onSave(propertyName, newValue);
    }
  }, [data, onSave, propertyName,]);

  const id = useMemo(() => `editor-${propertyName}`,
    [propertyName,]
  );

  return <>
    {section &&
      <>
        <Typography component={'span'}
          variant="h4"
          id={`${id}-label`}>{section}
        </Typography>
        <br />
      </>
    }<PropertyEditorControl>
      <InputLabel id={`${id}-label`}>{displayName}</InputLabel>
      <CountryPicker
        name={propertyName}
        showCountryCode={showCountryCode}
        showDialCode={showDialCode}
        defaultCountry={value}
        showFlags={showFlags}
        onChange={handleChange}
      />
    </PropertyEditorControl>
  </>;
};

CountryPickerEditor.config = {
  type: 'country-picker',
  options: [],
};

export default CountryPickerEditor;
