import cloneDeep from 'lodash/cloneDeep';
import type { CachedDocumentSnapshot } from './DocumentReferenceSet';
import type { DocumentData } from '@mindhiveoy/firebase-schema';
import type CachedDocumentReference from './CachedDocumentReference';

/**
 * Create a new document snapshot.
 * @param doc
 * @returns
 */
export const createCachedDocumentSnapshot = <T extends DocumentData>(doc: CachedDocumentReference<T>): CachedDocumentSnapshot<T> => {
  const data = cloneDeep(doc.data);
  delete data?._id;
  return {
    id: doc.docId,
    data,
    exists: !!doc.data,
  };
};
