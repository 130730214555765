import { ALL_PLANS, PAID_PLANS, PlanId } from './plans';
import { Feature, feature } from '@mindhiveoy/auth';
import { ModuleId } from './modules';
import { UserRoleId } from './schema';

type f = (<PlanId, UserRoleId>(feature: Feature<PlanId, ModuleId, UserRoleId>) =>
  Feature<PlanId, ModuleId, UserRoleId>);

const f = (f: Feature<PlanId, ModuleId, UserRoleId>) =>
  feature(f) as Feature<PlanId, ModuleId, UserRoleId>;

export const appFeatureCategories = {};

const PLATFORM_ADMINISTRATION = 'Platform administration';
const SPACE_ADMINISTRATION = 'Space administration';
const PROJECT_ADMINISTRATION = 'Project administration';
const ANSWERING_AND_CONVERSATION = 'answering and conversation';
const BILLING_AND_PAYMENT = 'Billing and payment';

export const features = {
  'ai.use': f({
    name: 'Use AI',
    desc: '',
    public: true,
    roles: ['system', 'admin', 'facilitator', 'panelist',],
    plans: PAID_PLANS,
  }),
  'ai.bots': f({
    name: 'Use AI bots',
    desc: '',
    public: true,
    roles: ['system', 'admin', 'facilitator',],
    plans: PAID_PLANS,
  }),
  'media.search': f({
    name: 'Use Unsplash',
    desc: '',
    public: true,
    roles: ['system', 'admin', 'facilitator',],
    plans: ALL_PLANS,
  }),
  'dev.ops': f({
    name: 'Access to developer operations',
    desc: '',
    public: false,
    roles: ['system',],
    plans: ALL_PLANS,
  }),

  'admin.user': f({
    name: 'Organization User administration',
    desc: '',
    roles: ['system', 'admin', 'facilitator',],
    plans: ALL_PLANS,
  }),

  'user.edit': f({
    name: 'Edit user information',
    desc: 'Right to edit platform user information',
    roles: ['owner',],
    plans: ALL_PLANS,
  }),

  'payment.createCustomer': f({
    name: 'Create customer information',
    categoryId: BILLING_AND_PAYMENT,
    desc: '',
    roles: ['admin',],
    plans: PAID_PLANS,
  }),

  'payment.updateCustomer': f({
    name: 'Edit customer information',
    categoryId: BILLING_AND_PAYMENT,
    desc: '',
    roles: ['admin', 'owner',],
    plans: PAID_PLANS,
  }),

  'payment.selectPan': f({
    name: 'Select plan',
    desc: '',
    roles: ['admin', 'owner',],
    plans: ALL_PLANS,
  }),

  'payment.createSubscription': f({
    name: 'Create subscription',
    categoryId: BILLING_AND_PAYMENT,
    desc: '',
    roles: ['admin', 'owner',],
    plans: ALL_PLANS,
  }),

  'payment.createInvoice': f({
    name: 'Create invoice',
    categoryId: BILLING_AND_PAYMENT,
    desc: '',
    roles: ['admin', 'owner',],
    plans: PAID_PLANS,
  }),

  'payment.updateInvoice': f({
    name: 'Update existing invoice',
    categoryId: BILLING_AND_PAYMENT,
    desc: '',
    roles: ['admin', 'owner',],
    plans: PAID_PLANS,
  }),

  'payment.pay': f({
    name: 'Make payment',
    categoryId: BILLING_AND_PAYMENT,
    desc: '',
    roles: ['admin', 'owner',],
    plans: PAID_PLANS,
  }),

  'platform.invite': f({
    name: 'Invite a new system user to the platform',
    desc: '',
    categoryId: PLATFORM_ADMINISTRATION,
    public: false,
    plans: ALL_PLANS,
    roles: ['system',],
  }),

  'space.update': f({
    name: 'Update space info',
    desc: '',
    categoryId: SPACE_ADMINISTRATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['admin',],
  }),

  'space.invite': f({
    name: 'Invite new member to a space',
    desc: '',
    categoryId: SPACE_ADMINISTRATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['admin',],
  }),

  'live': f({
    name: 'Live mode',
    desc: 'In the live mode, canvases are controller in sync by the facilitator',
    categoryId: PROJECT_ADMINISTRATION,
    public: true,
    // TODO: set live as a module
    plans: ['enterprise'],
    roles: ['system', 'facilitator',],
  }),

  'live.modifyScreen': f({
    name: 'Modify screen',
    desc: 'Modify screen in live mode',
    categoryId: PROJECT_ADMINISTRATION,
    public: true,
    plans: PAID_PLANS,
    roles: ['system', 'facilitator',],
  }),

  'space.editRoles': f({
    name: 'Edit member roles',
    desc: '',
    categoryId: SPACE_ADMINISTRATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['system', 'admin',],
  }),

  'project.editRoles': f({
    name: 'Edit member roles',
    desc: '',
    categoryId: PROJECT_ADMINISTRATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['system', 'admin', 'facilitator',],
  }),

  'project.create': f({
    name: 'create new project',
    desc: '',
    categoryId: PROJECT_ADMINISTRATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['system', 'admin', 'member',],
  }),
  'project.invite': f({
    name: 'Invite new member to project',
    desc: '',
    categoryId: PROJECT_ADMINISTRATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['facilitator',],
  }),
  'project.update': f({
    name: 'Update project content',
    desc: '',
    categoryId: PROJECT_ADMINISTRATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['system', 'admin', 'facilitator', 'owner',],
  }),
  'project.edit.panel': f({
    name: 'Edit project panels',
    desc: '',
    categoryId: PROJECT_ADMINISTRATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['facilitator', 'owner', 'system'],
  }),
  'project.settings.update': f({
    name: 'Update project settings',
    desc: '',
    categoryId: PROJECT_ADMINISTRATION,
    public: false,
    plans: ALL_PLANS,
    roles: ['facilitator', 'owner', 'system'],
  }),
  'project.export': f({
    name: 'Export project data',
    desc: '',
    categoryId: PROJECT_ADMINISTRATION,
    public: true,
    plans: ['enterprise',],
    roles: ['facilitator', 'owner', 'system'],
  }),
  'session.create': f({
    name: 'create new session',
    desc: '',
    categoryId: PROJECT_ADMINISTRATION,
    public: false,
    plans: ALL_PLANS,
    roles: ['facilitator',],
  }),
  'session.update': f({
    name: 'Update session content',
    desc: '',
    categoryId: PROJECT_ADMINISTRATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['facilitator',],
  }),

  'session.delete': f({
    name: 'Delete query',
    desc: '',
    categoryId: PROJECT_ADMINISTRATION,
    public: true,
    plans: ['team', 'individual',],
    roles: ['facilitator',],
  }),
  'session.duplicate': f({
    name: 'Duplicate session',
    desc: '',
    categoryId: PROJECT_ADMINISTRATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['facilitator',],
  }),

  'canvas.answer': {
    name: 'Answer to a Canvas',
    desc: '',
    categoryId: ANSWERING_AND_CONVERSATION,
    public: false,
    plans: ALL_PLANS,
    roles: ['facilitator', 'panelist',],
  },
  'canvas.delete': {
    name: 'Delete a Canvas',
    desc: '',
    categoryId: ANSWERING_AND_CONVERSATION,
    public: false,
    plans: ALL_PLANS,
    roles: ['facilitator',],
  },

  'canvas.eraseAnswers': {
    name: 'Erase answers to a Canvas',
    desc: '',
    categoryId: ANSWERING_AND_CONVERSATION,
    public: false,
    plans: ALL_PLANS,
    roles: ['facilitator',],
  },

  'canvas.duplicate': {
    name: 'Duplicate a Canvas',
    desc: '',
    categoryId: ANSWERING_AND_CONVERSATION,
    public: false,
    plans: ALL_PLANS,
    roles: ['facilitator',],
  },

  'comment.create': f({
    name: 'Comment on a Canvas',
    desc: '',
    categoryId: ANSWERING_AND_CONVERSATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['facilitator', 'panelist',],
  }),
  'comment.vote': f({
    name: 'Vote for a comment',
    desc: '',
    categoryId: ANSWERING_AND_CONVERSATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['facilitator', 'panelist',],
  }),

  'comment.label': f({
    name: 'Set comment labeling',
    desc: '',
    categoryId: ANSWERING_AND_CONVERSATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['facilitator',],
  }),

  'comment.edit': f({
    name: 'Edit comment',
    desc: '',
    categoryId: ANSWERING_AND_CONVERSATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['owner',],
  }),

  'message.create': f({
    name: 'Create a new message',
    desc: '',
    categoryId: ANSWERING_AND_CONVERSATION,
    public: true,
    plans: ALL_PLANS,
    roles: ['system', 'admin', 'facilitator',],
  }),

  'canvas.create': f({
    name: 'to create a new Canvas for a query.',
    plans: ALL_PLANS,
    roles: ['facilitator',],
  }),
  'canvas.update': f({
    name: 'to modify a session Canvas.',
    plans: ALL_PLANS,
    roles: ['facilitator',],
  }),
  'canvas.export': f({
    name: 'to export Canvas data.',
    plans: ALL_PLANS,
    roles: ['facilitator',],
  }),

  'report.create': f({
    name: 'Create a new report',
    roles: ['facilitator',],
  }),
  'report.read': f({
    name: 'Read a report',
    roles: ['facilitator', 'panelist',],
  }),
  'report.update': f({
    name: 'Update a report',
    roles: ['facilitator',],
  }),
  'report.delete': f({
    name: 'Delete a report',
    roles: ['facilitator',],
  }),
  'page.read': f({
    name: 'Read a page',
    roles: ['anyone',],
  }),
  'page.update': f({
    name: 'Update a page content',
    roles: ['system',],
  }),
};

export type FeatureId = keyof typeof features;

export type AppFeatures = {
  [P in FeatureId]: Feature<PlanId, ModuleId, UserRoleId>;
};
