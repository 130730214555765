import { AIPromptId } from '../https';
import { FirestoreTimeStamp } from '../common';
import { UTCTime, UserId } from '@mindhiveoy/schema';

export type AccountReservation = {
  /**
   * The amount of money that is reserved from the account.
   */
  amount: CurrencyString;
  /**
   * When transaction is related to AI api-call, the number of
   * predicted tokens is saved here.
   */
  tokens?: number;
  /**
   * The date and time when the reservation was made.
   */
  created: UTCTime | FirestoreTimeStamp;
};

export type MoneyReservationMap = {
  [reservationId: string]: AccountReservation
}

export type SupportedCurrency = 'eur' | 'usd';
/**
 * Represents a user's account. The account is used to store money
 * for the user. The account is used to pay operations that require
 * money like AI operations.
 */
export interface Account {
  /**
   * The account balance in the currency of the account.
   */
  balance: CurrencyString;
  /**
   * The account's reserved balance in the currency of the account.
   */
  reservedBalance: CurrencyString;
  /**
   * The currency of the account. The default currency is EUR.
   */
  currency: SupportedCurrency;
  /**
   * The account's current money reservations. The reservations are made
   * when some space user is about to use some operation that will require
   * money. The reservation is removed when the operation is completed.
   *
   * When the reservation is made, the sum will be added to reservedBalance.
   * If the reservedBalance will be greater than the balance, the operation
   * will fail.
   */
  reservations: MoneyReservationMap;
}

export enum TransactionType {
  DEPOSIT = 'd',
  WITHDRAWAL = 'w',
  AI = 'a',
}
/**
 * A string presentation of the currency with 6 decimals
 * (The value is multiplied by 10^6)). The format is used
 * to make sure that the money operations will not have
 * precision errors.
 */
export type CurrencyString = string;

/**
 * Represents a user's account transaction.
 */
export interface AccountTransaction {
  /**
   * The date and time when the transaction occurred.
   */
  date: UTCTime | FirestoreTimeStamp;
  /**
   * The type of the transaction.
   */
  type: TransactionType
  /**
   * A brief description of the transaction.
   */
  desc?: string;
  /**
   * The amount of money involved in the transaction.
   * Use a positive number for deposits and a negative number for withdrawals.
   * The amount is expressed in the as balance * 10^6.
   */
  amount: CurrencyString;

  currency: SupportedCurrency;
  /**
   * When transaction is related to AI api-call, the number of tokens is saved here.
   */
  tokens?: number;

  promptId?: AIPromptId;

  predictedTokens?: number;

  stripeRef?: string;

  uid?: UserId;
}

export interface AccountTransactionDoc {
  content: AccountTransaction;
}
