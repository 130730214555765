import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import useScreenContext from 'components/layout/useScreenContext';

const ContactUsButton = () => {
  const {
    setContactUsFormOpen,
  } = useScreenContext();

  const {
    t,
  } = useTranslation();

  const handleContactClick = useCallback(() => {
    setContactUsFormOpen(true);
  }, [setContactUsFormOpen,]);

  return <>
    <Button
      key="close"
      variant="outlined"
      onClick={handleContactClick}
    >
      {t('Contact us')}
    </Button>

  </>;
};
export default ContactUsButton;
