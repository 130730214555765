import { Row } from '../../sharedComponents';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';

const Title = styled.div(({
  theme,
}) => `
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  color: ${theme.palette.text.lightgrey};
  margin-bottom:
`);
interface DialogHeaderProps {
  title: string;
  dialogOnClose?: () => void;
}

const DialogHeader = ({
  title, dialogOnClose,
}: DialogHeaderProps) => {
  const {
    t,
  } = useTranslation();

  return <Row $withMargin>
    <Title>{title}</Title>
    {dialogOnClose &&
      <Button
        variant='outlined'
        onClick={dialogOnClose} >{t('Close')}</Button>}
  </Row>;
};

export default DialogHeader;
