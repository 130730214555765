import { propertyEditorBuilder } from './builders/propertyEditorBuilder';
import AnalyzeOptionsEditor from './propertyEditors/stats/AnalyzeOptionsEditor';
import Axis1DEditor from './propertyEditors/axis/Axis1DPropertyEditor';
import Axis2DEditor from './propertyEditors/axis/Axis2DPropertyEditor';
import AxisColorPickerEditor from './propertyEditors/domain/canvas/AxisColorPickerEditor';
import AxisEditor from './propertyEditors/axis/AxisPropertyEditor';
import CanvasConfigEditor from './propertyEditors/domain/canvas/CanvasConfigEditor';
import CanvasPickerEditor from './propertyEditors/data/pickers/CanvasPickerEditor';
import CheckerEditor from './propertyEditors/primary/CheckerEditor';
import ColorPickerEditor from './propertyEditors/lookAndFeel/ColorPickerEditor';
import CornerColorPickerEditor from './propertyEditors/domain/canvas/CornerColorPickerEditor';
import CountryPickerEditor from './propertyEditors/domain/CountryPickerEditor.tsx';
import CurrencyEditor from './propertyEditors/primary/CurrencyEditor';
import DataModelPropertyEditor from './propertyEditors/data/DataModePropertyEditor';
import EraseDataPropertyEditorInstance from './propertyEditors/data/EraseDataPropertyEditor';
import HTMLEditor from './propertyEditors/primary/HTMLEditor';
import ImagePropertyEditor from './propertyEditors/lookAndFeel/ImagePropertyEditor';
import MediaPropertyEditor from './propertyEditors/lookAndFeel/MediaPropertyEditor';
import MessageAttachmentEditor from './propertyEditors/messaging/MessageAttachmentEditor';
import MessageRecipientsEditor from './propertyEditors/messaging/MessageRecipientsEditor';
import NumberEditor from './propertyEditors/primary/NumberEditor';
import PhoneNumberEditor from './propertyEditors/domain/CountryPickerEditor.tsx/PhoneNumberEditor';
import PickerEditor from './propertyEditors/primary/PickerEditor';
import QRSizeEditor from './propertyEditors/layout/QRSizePropertyEditor';
import SessionPickerEditor from './propertyEditors/data/pickers/SessionPickerEditor';
import StatCharacteristicEditor from './propertyEditors/stats/StatCharacteristicEditor';
import TextAlignEditor from './propertyEditors/layout/TextAlignPropertyEditor';
import TextEditor from './propertyEditors/primary/TextEditor';
import VerticalAlignEditor from './propertyEditors/layout/VerticalAlignPropertyEditor';

// TODO Move registration to webpack hook
/**
 * Primary types
 */
propertyEditorBuilder.register(CheckerEditor);
propertyEditorBuilder.register(HTMLEditor);
propertyEditorBuilder.register(NumberEditor);
propertyEditorBuilder.register(PickerEditor);
propertyEditorBuilder.register(TextEditor);
propertyEditorBuilder.register(CurrencyEditor);
/**
 * Domain related property editors
 */
propertyEditorBuilder.register(CountryPickerEditor);
propertyEditorBuilder.register(PhoneNumberEditor);
/**
 * Look and feel related property editors
 */
propertyEditorBuilder.register(ImagePropertyEditor);
propertyEditorBuilder.register(MediaPropertyEditor);
propertyEditorBuilder.register(ColorPickerEditor);
propertyEditorBuilder.register(CornerColorPickerEditor);
propertyEditorBuilder.register(AxisColorPickerEditor);
/**
 * Layout related property editors.
 */
propertyEditorBuilder.register(TextAlignEditor);
propertyEditorBuilder.register(VerticalAlignEditor);
propertyEditorBuilder.register(QRSizeEditor);
/**
 * Session and canvas related property editors
 */
propertyEditorBuilder.register(SessionPickerEditor);
propertyEditorBuilder.register(CanvasPickerEditor);
propertyEditorBuilder.register(AxisEditor);
propertyEditorBuilder.register(Axis1DEditor);
propertyEditorBuilder.register(Axis2DEditor);
propertyEditorBuilder.register(CanvasConfigEditor);
/**
 * Graph and data related property editors
 */
propertyEditorBuilder.register(DataModelPropertyEditor);
propertyEditorBuilder.register(EraseDataPropertyEditorInstance);
propertyEditorBuilder.register(StatCharacteristicEditor);
propertyEditorBuilder.register(AnalyzeOptionsEditor);
/**
 * Messaging-related property editors
 */
propertyEditorBuilder.register(MessageAttachmentEditor);
propertyEditorBuilder.register(MessageRecipientsEditor);
