
export const extractCoordsFromKey = (key: string): {
  x: number;
  y: number;
} => {
  const values = key.split('_');
  return {
    x: parseFloat(values[1]),
    y: parseFloat(values[0]),
  };
};
