/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import type { CSSProperties } from 'react';

import { mapVerticalAlign } from './mapVerticalAlign';
import { useCanvas } from '../../../../bloc/canvas/CanvasBloc';
import { useComponentSelectionContext } from '../../../../utils/hooks/useComponentSelectionContext';
import Center from '../../../common/layout/Center';
import FormControl from '@mui/material/FormControl';
import HTMLInplaceEditor from '../../../editing/HTMLInplaceEditor';
import MediaContainer from 'components/layout/MediaContainer';
import usePathParams from '../../../../utils/hooks/usePathParams';
import type { BuilderFunction } from '../../../../components/builder/builders/componentBuilder';
import type { Canvas, QuestionDescriptionWidget } from '@shared/schema/src';
import type { QuestionDescriptionWidgetProps } from '../renderers/QuestionDescriptionWidget';
import type { WithId } from '@mindhiveoy/schema';

const QuestionDescriptionWidgetEditor: BuilderFunction<QuestionDescriptionWidgetProps> = ({
  data,
  data: {
    background,
    sessionId,
    canvasId,
  },
}: QuestionDescriptionWidgetProps) => {
  if (!sessionId) {
    return <Center>
      Please pick session from inspector
    </Center>;
  }

  if (!canvasId) {
    return <Center>
      Please pick canvas from inspector
    </Center>;
  }
  return background ?
    <MediaContainer
      media={background}>
      <TextEditors data={data} />
    </MediaContainer > :
    <TextEditors data={data} />;
};

interface TextEditorsProps {
  data: QuestionDescriptionWidget;
}
const TextEditors = ({
  data: {
    questionTitle,
    questionDescription,
    content,
    textAlign,
    verticalAlign,
    sessionId,
    canvasId,
  },
}: TextEditorsProps) => {
  const {
    selection,
  } = useComponentSelectionContext<QuestionDescriptionWidget>();

  const handleSaveQuestionDescription = useCallback((name: keyof QuestionDescriptionWidget, value?: string) => {
    selection?.updateProperty(name, value);
  }, [selection,]);

  const {
    spaceId,
    projectId,
  } = usePathParams();

  const canvasParams = useMemo(() => {
    return {
      spaceId,
      projectId,
      sessionId,
      canvasId,
    };
  }, [canvasId, projectId, sessionId, spaceId,]);

  const [canvasFromBloc, setCanvas,] = useState<WithId<Canvas>>();
  const canvasBloc = useCanvas(canvasParams);

  useEffect(() => canvasBloc.subscribe(
    setCanvas,
    (error: any) => console.error(error)
  ), [canvasBloc,]);

  const verticalStyle = useMemo(() => {
    const result: CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: mapVerticalAlign(verticalAlign),
      position: 'relative',
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      overflowY: 'scroll',
      padding: 16, // TODO: theme based margins
    };
    return result;
  }, [verticalAlign,]);

  useEffect(() => {
    handleSaveQuestionDescription('questionTitle', canvasFromBloc?.name);
    handleSaveQuestionDescription('questionDescription', canvasFromBloc?.desc);
  }, [canvasFromBloc, handleSaveQuestionDescription,]);

  return <div style={verticalStyle}>
    <FormControl fullWidth
      style={{
        overflowY: 'scroll',
      }}>
      <SegmentEditor
        name="questionTitle"
        textAlign={textAlign}
        text={questionTitle ?? canvasFromBloc?.name}
        variant="h1"
        onChange={handleSaveQuestionDescription}
      />
      {
        canvasFromBloc?.desc &&
        <SegmentEditor
          name="questionDescription"
          textAlign={textAlign}
          text={questionDescription ?? canvasFromBloc?.desc}
          variant="body"
          onChange={handleSaveQuestionDescription}
        />
      }
      <SegmentEditor
        name="content"
        textAlign={textAlign}
        text={content}
        variant="body"
        onChange={handleSaveQuestionDescription}
      />
    </FormControl>
  </div>;
};

const SegmentEditor = ({
  name,
  textAlign,
  text,
  variant,
  onChange,
}: any) => {
  return <Typography
    component={'span'}
    variant={variant}>
    <HTMLInplaceEditor
      text={text ?? ''}
      name={name}
      textAlign={textAlign}
      onChange={onChange}
    ></HTMLInplaceEditor>
  </Typography>;
};

QuestionDescriptionWidgetEditor.config = {
  type: 'question-description',
  displayName: 'Question Description',
};

export default QuestionDescriptionWidgetEditor;
