import { debounce } from 'lodash';
import { pexelsVideoSearch, unsplashPhotosSearch } from 'bloc/media/MediaBloc';
import type { PexelsVideoSearchRequest, UnsplashPhotosSearchRequest } from '@shared/schema/src/https/media';
import type { UnsplashSearchResultResponse } from '@shared/schema/src/https/media/unsplashTypes';
import type { Videos } from '@shared/schema/src/https/media/pexelTypes';

const DEBOUNCE_RATE = 500;

/**
 * Search images from Unsplash
 *
 * @params {UnsplashPhotosSearchRequest} args - Search parameters
 * @params {Function} callback - Callback function to return results
 */
const _searchImages = debounce(async (
  args: UnsplashPhotosSearchRequest,
  callback: (results: UnsplashSearchResultResponse) => void) => {
  const result = await unsplashPhotosSearch(args);
  callback(result);
}, DEBOUNCE_RATE);

/**
 * Search videos from Pexels.
 *
 * @params {PexelsVideoSearchRequest} args - Search parameters
 * @params {Function} callback - Callback function to return results
 */
const _searchVideos = debounce(async (
  args: PexelsVideoSearchRequest,
  callback: (results: Videos) => void) => {
  const result = await pexelsVideoSearch(args);
  callback(result);
}, DEBOUNCE_RATE);

/**
 * Search images from Unsplash. Cancel previous search if it's still running.
 *
 * @params {UnsplashPhotosSearchRequest} args - Search parameters
 * @params {Function} callback - Callback function to return results
 */
export const searchImages = (args: UnsplashPhotosSearchRequest, callback: (results: UnsplashSearchResultResponse) => void) => {
  _searchVideos.cancel();
  _searchImages(args, callback);
};

/**
 * Search videos from Pexels. Cancel previous search if it's still running.
 *
 * @params {PexelsVideoSearchRequest} args - Search parameters
 * @params {Function} callback - Callback function to return results
 */
export const searchVideos = (args: PexelsVideoSearchRequest, callback: (results: Videos) => void) => {
  _searchImages.cancel();
  _searchVideos(args, callback);
};
