/* eslint-disable max-len */
export const HeroIcon = () => {
  return <svg width="1rem"
    height="1rem"
    viewBox="0 0 490 490"
    version="1.1">
    <g id="Hero"
      fill="#FFFFFF"
      fillRule="nonzero">
      <path d="M210,490 L17.5,490 C12.8594,490 8.4062,488.1562 5.125,484.875 C1.8438,481.5938 0,477.1406 0,472.5 L0,17.5 C0,12.8594 1.8438,8.4062 5.125,5.125 C8.4062,1.8438 12.8594,0 17.5,0 L210,0 C214.6406,0 219.0938,1.8438 222.375,5.125 C225.6562,8.4062 227.5,12.8594 227.5,17.5 L227.5,472.5 C227.5,477.1406 225.6562,481.5938 222.375,484.875 C219.0938,488.1562 214.6406,490 210,490 Z M35,455 L192.5,455 L192.5,35 L35,35 L35,455 Z"
        id="Shape"></path>
      <path d="M472.5,35 L280,35 C273.7461,35 267.969,31.6641 264.844,26.25 C261.719,20.8359 261.719,14.164 264.844,8.75 C267.969,3.336 273.7463,0 280,0 L472.5,0 C478.7539,0 484.531,3.3359 487.656,8.75 C490.781,14.1641 490.781,20.836 487.656,26.25 C484.531,31.664 478.7537,35 472.5,35 Z"
        id="Path"></path>
      <path d="M472.5,140 L280,140 C273.7461,140 267.969,136.6641 264.844,131.25 C261.719,125.8359 261.719,119.164 264.844,113.75 C267.969,108.336 273.7463,105 280,105 L472.5,105 C478.7539,105 484.531,108.3359 487.656,113.75 C490.781,119.1641 490.781,125.836 487.656,131.25 C484.531,136.664 478.7537,140 472.5,140 Z"
        id="Path"></path>
      <path d="M472.5,350 L280,350 C273.7461,350 267.969,346.6641 264.844,341.25 C261.719,335.8359 261.719,329.164 264.844,323.75 C267.969,318.336 273.7463,315 280,315 L472.5,315 C478.7539,315 484.531,318.3359 487.656,323.75 C490.781,329.1641 490.781,335.836 487.656,341.25 C484.531,346.664 478.7537,350 472.5,350 Z"
        id="Path"></path>
      <path d="M472.5,245 L280,245 C273.7461,245 267.969,241.6641 264.844,236.25 C261.719,230.8359 261.719,224.164 264.844,218.75 C267.969,213.336 273.7463,210 280,210 L472.5,210 C478.7539,210 484.531,213.3359 487.656,218.75 C490.781,224.1641 490.781,230.836 487.656,236.25 C484.531,241.664 478.7537,245 472.5,245 Z"
        id="Path"></path>
      <path d="M472.5,472.5 L280,472.5 C273.7461,472.5 267.969,469.1641 264.844,463.75 C261.719,458.3359 261.719,451.664 264.844,446.25 C267.969,440.836 273.7463,437.5 280,437.5 L472.5,437.5 C478.7539,437.5 484.531,440.8359 487.656,446.25 C490.781,451.6641 490.781,458.336 487.656,463.75 C484.531,469.164 478.7537,472.5 472.5,472.5 Z"
        id="Path"></path>
    </g>
  </svg>;
};
