import { useEffect, useState } from 'react';
import { useSession } from 'bloc/session/SessionBloc';
import CanvasGraphContainer from 'components/canvas/CanvasGraphContainer';
import LoadingIndicator from 'components/common/LoadingIndicator';
import canvasFormBuilderConfig from '../../configs/CanvasFormBuilderConfig';
import type { BuilderComponentPropsBase } from '../PageWidgetRenderer';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { CanvasProps } from '../renderers/CanvasWidget';
import type { Session } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export interface CanvasWidgetProps extends BuilderComponentPropsBase<CanvasProps> {
  type: 'canvas';
}

const CanvasWidgetGraph: BuilderFunction<CanvasWidgetProps> = () => {
  const [session, setSession,] = useState<WithId<Session>>();

  const sessionBloc = useSession();

  useEffect(() => sessionBloc.subscribe(setSession), [sessionBloc,]);

  if (!session) {
    return <LoadingIndicator />;
  }
  return <CanvasGraphContainer
    session={session}
  />;
};

CanvasWidgetGraph.config = canvasFormBuilderConfig;

export default CanvasWidgetGraph;
