/* eslint-disable indent */
import { AnimatePresence, motion } from 'framer-motion';
import { ContactOtherConfig, ContactSalesConfig, ContactSupportConfig } from './ContactUsFormUtils';
import { DialogHeader, StepTitle } from 'components/onboarding/OnboardingDialog';
import { InquiryType } from '@shared/schema/src';
import { Stack, Typography } from '@mui/material';
import { createInquiry } from 'bloc/inquiries/inquiryApi';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';
import CustomDialog from 'components/onboarding/CustomDialog';
import SimpleComponentForm from 'components/forms/SimpleComponentForm';
import styled from '@emotion/styled';
import theme from 'theme';
import useScreenContext from 'components/layout/useScreenContext';
import type { OtherInquiry, SalesInquiry, SupportInquiry } from '@shared/schema/src';

const DialogContent = styled(motion.div)`
  ${theme.breakpoints.down('md')} {
    width: 95vw;
    max-height: 90vh;
    padding: 0;
  }
  position: absolute;
  padding: ${theme.spacing(1, 3)};
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${theme.palette.background.default};
  border: 2px solid ${theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70vw;
  max-width: 700px;
  height: fit-content;
  min-height: 180px;
  max-height: 90vh;
`;

const StyledCloseIcon = styled.div`
position: absolute;
top: 16px;
right: 16px;
z-index: 1;
  &>.MuiSvgIcon-root {
    width: 32px;
    height: 32px;
  }
`;

const TabButtonContainer = styled.div`
${theme.breakpoints.down('sm')} {
  gap: ${theme.spacing(3)};
}
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${theme.spacing(3)};
  padding: ${theme.spacing(1, 3)};
`;

const TabButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing(0)};
  align-items: center;
  width: 20%;
  border: 1px solid transparent;
  cursor: default;
  :hover {
    border-bottom: 1px solid ${theme.palette.primary.main};
  }
  &.active {
    border-bottom: 1px solid ${theme.palette.primary.main};
  }
`;

const FormStyleButtonTitle = styled(Typography)`
  font-size: 1rem;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 680px;
  overflow: scroll;
  margin: auto;
  padding: ${theme.spacing(3, 0)};
`;

const FormTitle = styled(Typography)`
${theme.breakpoints.down('sm')} {
  font-size: 1rem;
}
  font-size: 1.125rem;
  font-weight: 400;
  text-align: center;
  width: 90%;
`;

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const ContactUsDialog = () => {
  const [direction,] = useState(1);
  const [formType, setFormType,] = useState<InquiryType>(InquiryType.SALES);
  const [activeLink, setActiveLink,] = useState('sales');

  const {
    t,
  } = useTranslation();

  const {
    contactUsFormOpen,
    setContactUsFormOpen,
  } = useScreenContext();

  const initialContactSalesData: SalesInquiry = {
    type: InquiryType.SALES,
    companyName: '',
    VAT: '',
    organizationType: '',
    name: '',
    email: '',
    estimatedUsers: '',
    estimatedFacilitators: '',
    message: '',
  };

  const initialContactSupportData: SupportInquiry = {
    type: InquiryType.SUPPORT,
    name: '',
    email: '',
    plan: '',
    role: '',
    message: '',
  };

  const initialContactOtherData: OtherInquiry = {
    type: InquiryType.OTHER,
    name: '',
    email: '',
    message: '',
  };

  const handleContactClose = useCallback(() => {
    setContactUsFormOpen(false);
    setFormType(InquiryType.SALES);
  }, [setContactUsFormOpen,]);

  const handleSave = useCallback(async (data: SalesInquiry | SupportInquiry | OtherInquiry) => {
    setContactUsFormOpen(false);
    // todo: do we need a bloc for this? probably not now
    await createInquiry({
      inquiry: data,
    });
    setFormType(InquiryType.SALES);
  }, [setContactUsFormOpen,]);

  useEffect(() => {
    if (Object.values(InquiryType).includes(formType)) {
      setActiveLink(formType.toString());
    } else {
      setActiveLink('sales');
    }
  }, [formType,]);

  const getFormContent = (form: InquiryType) => {
    switch (form) {
      case InquiryType.SALES:
        return <>
          <FormTitle>
            {t('Request a Quotation for the Enterprise plan')}
          </FormTitle>
          <SimpleComponentForm<SalesInquiry>
            config={ContactSalesConfig}
            initialData={initialContactSalesData}
            saveButtonText={t('Send')}
            onSave={handleSave}
          />
        </>;
      case InquiryType.SUPPORT:
        return <>
          <SimpleComponentForm<SupportInquiry>
            config={ContactSupportConfig}
            initialData={initialContactSupportData}
            saveButtonText={t('Send')}
            onSave={handleSave}
          /></>;
      case InquiryType.OTHER:
        return <>
          <SimpleComponentForm<OtherInquiry>
            config={ContactOtherConfig}
            initialData={initialContactOtherData}
            saveButtonText={t('Send')}
            onSave={handleSave}
          /></>;
      default:
        return null;
    }
  };

  return <CustomDialog
    open={contactUsFormOpen}

  >
    <AnimatePresence custom={direction}>
      <DialogContent
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        style={{
          zIndex: 1300,
        }}
        transition={{
          x: {
            type: 'spring',
            stiffness: 300,
            damping: 30,
          },
          opacity: {
            duration: 0.2,
          },
        }}
      >
        <StyledCloseIcon onClick={handleContactClose} >
          <CloseIcon />
        </StyledCloseIcon>

        <Stack gap={0}>
          <DialogHeader>
            <StepTitle>
              {t('Contact us')}
            </StepTitle>
          </DialogHeader>

          <TabButtonContainer>
            <TabButton onClick={() => {
              setFormType(InquiryType.SALES);
            }}
              className={activeLink == 'sales' ? 'active' : ''}>
              <FormStyleButtonTitle>
                {t('sales')}
              </FormStyleButtonTitle>
            </TabButton>

            <TabButton onClick={() => {
              setFormType(InquiryType.SUPPORT);
            }}
              className={activeLink == 'support' ? 'active' : ''}>
              <FormStyleButtonTitle>
                {t('support')}
              </FormStyleButtonTitle>
            </TabButton>

            <TabButton onClick={() => {
              setFormType(InquiryType.OTHER);
            }}
              className={activeLink == 'other' ? 'active' : ''}>
              <FormStyleButtonTitle>
                {t('other')}
              </FormStyleButtonTitle>
            </TabButton>
          </TabButtonContainer>
        </Stack>

        <FormContainer>{getFormContent(formType)}</FormContainer>

      </DialogContent>
    </AnimatePresence>
  </CustomDialog>;
};
export default ContactUsDialog;
