/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
import type { User } from '@shared/schema/src';
import type { UserId } from '@mindhiveoy/schema';
import type { UserRoles } from '@mindhiveoy/auth';

import { deleteUser, getAuth } from 'firebase/auth';
import { removeUser, updateUser } from './userApi';
import type { ShortUserRoleId } from '@shared/schema/src';

/**
 * UserAdmin interface to define the user administration API to be used when manipulating user information on
 * some specific context. The context is defined by the pattern hierarchy in user roles.
 */
export interface UserAdmin {
  // TODO Think if the module instance creation should also be a part of the API
  /**
   * Add an user to the context.
   *
   * @param {AppUser} user  User Information
   * @return {AppUser}      New user's information
   */
  addUser: (uid: UserId, user: Partial<User<ShortUserRoleId>> & Pick<User<ShortUserRoleId>, 'displayName' | 'email'>) => User<ShortUserRoleId>;
  /**
   * Update existing user's information.
   *
   * @param {UserId}  uid   user's id
   * @param {AppUser} user  User information for the new user
   * @return {AppUser}      Updated user info
   */
  updateUser: (uid: UserId, user: Partial<User<ShortUserRoleId>>) => User<ShortUserRoleId>;
  /**
  * Remove user from from user context.
  *
  * @param {UserId} uid    User's id
  */
  removeUser: (uid: UserId) => void;
  /**
   * Give a list of users in the context.
   *
   * @param {string} startFromUid Optional staring point on the list
   * @param {number} maxCount     Maximum number of users to be fetched at once.
   * @return {AppUser[]}          List of users
   */
  listUsers: (startFrom?: string, maxCount?: number) => User<ShortUserRoleId>[];

  /**
   * Gives an user roles descriptor for roles available in this context.
   *
   * @return {UserRoles} Roles available on this user admin context
   */
  listRoles: () => UserRoles<ShortUserRoleId>;
}

/**
 * User Administration Bloc.
 *
 * @template PlanId Platform specific plan identification type
 * @template ModuleId Platform specific module identification type
 * @template UserRoleId Platform specific user role identification type
 * @template AppUser Platform specific User type extended form mind-cloud generic
 */
export class UserAdminBloc {
  /**
     * Roles available at this pattern context
     */
  private roles!: UserRoles<ShortUserRoleId>;
  /**
     *
     * @param {UserRoles} roles       Platform's user roles
     * @param {string} contextPattern User role pattern found from platform's user roles. This will define the roles
     *                                available at the lever where the user administration is being made.
     */
  // eslint-disable-next-line @typescript-eslint/no-empty-function, require-jsdoc
  constructor(/* roles: UserRoles , private contextPattern: string*/) {

  }

  /**
   * Create a new user for platform.
   *
   * @param {AppUser} _user  User Information
  //  * @return {AppUser}      New user's information
   */
  public addUser = (_user: any): User<ShortUserRoleId> => {
    throw new Error('Not implemented yet.');
  };

  /**
     * Update existing user's information.
     *
     * @param {UserId}  uid   user's id
     * @param {AppUser} user  User information for the new user
    //  * @return {AppUser}      Updated user info
     */
  updateUser = async (uid: UserId, user: Partial<User<ShortUserRoleId>>): Promise<User<ShortUserRoleId>> => {
    return await updateUser({
      uid,
      user,
      contextId: 'platform',
    });
  };

  /**
     * Delete user from platform.
     *
     * @param {UserId} _uid    User's id
     * @param {User} _user    User's auth data?
     */
  removeUser = async (_uid: UserId) => {
    const auth = getAuth();
    const user = auth.currentUser;

    const status = await removeUser({
      uid: _uid,
    });
    await deleteUser(user!);
  };

  /**
 * Give a list of users in the context.
 *
 * @param {string} _startFromUid Optional staring point on the list
 * @param {number} _maxCount     Maximum number of users to be fetched at once.
//  * @return {AppUser[]}          List of users
 */
  listUsers = (_startFromUid?: string, _maxCount?: number): User<ShortUserRoleId>[] => {
    throw new Error('Not implemented yet.');
    // return [];
  };

  /**
 * Gives an user roles descriptor for roles available in this context.
 *
 * @return {UserRoles} Roles available on this user admin context
 */
  listRoles = (): UserRoles => this.roles;
}
