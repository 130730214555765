import {
  createContext, useContext, useEffect, useRef, useState
} from 'react';
import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Square = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: absolute;
`;

const Center = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

interface DimensionContext {
  top: number;
  left: number;
  width: number;
  height: number;
  minimum: number;
  maximum: number;
}

const DimensionContext = createContext<DimensionContext>({
  top: 0,
  left: 0,
  width: 0,
  height: 0,
  minimum: 0,
  maximum: 0,
});

export const useDimensionsContext = () => {
  const context = useContext(DimensionContext);
  if (!context) {
    throw new Error('Dimensions context is not available');
  }
  return context;
};

interface DimensionsProps {
  /**
   * The mode of the dimensions container.
   *
   * - `center`: will center the children in the container
   * - `fluid`: will render the children in the container
   * - `square`: will render the children in a square container
   *
   * @default fluid
   */
  mode?: 'center' | 'fluid' | 'square';
}

/**
 * Dimensions will provide the dimensions of the container to its children.
 * Children components can relay on that the dimensions will be available
 * when they are rendered and they are always up to date.
 *
 * @param {DimensionsProps} props The props of the component
 */
const Dimensions = ({
  mode = 'fluid',
  children,
}: PropsWithChildren<DimensionsProps>) => {
  const ref = useRef<HTMLDivElement>(null);

  const [value, setValue,] = useState<DimensionContext>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    minimum: 0,
    maximum: 0,
  });

  useEffect(() => {
    if (!ref.current || typeof window === 'undefined') {
      return;
    }

    const {
      top,
      left,
      width,
      height,
    } = ref.current.getBoundingClientRect();

    const minimum = Math.min(width, height);
    const maximum = Math.max(width, height);

    setValue({
      top,
      left,
      width,
      height,
      minimum,
      maximum,
    });

    // listen to element resize and move events
    const observer = new ResizeObserver((entries) => {
      const {
        top,
        left,
        width,
        height,
      } = entries[0].contentRect;

      const minimum = Math.min(width, height);
      const maximum = Math.max(width, height);

      setValue({
        top,
        left,
        width,
        height,
        minimum,
        maximum,
      });
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Container ref={ref}>
      <DimensionContext.Provider value={value}>
        {
          mode === 'center' ?
            <Center>
              {children}
            </Center> :
            mode === 'square' ? <Square
              style={{
                width: value.minimum,
                height: value.minimum,
              }}>
              {children}
            </Square> :
              // fluid
              children
        }
      </DimensionContext.Provider>
    </Container>
  );
};

export default Dimensions;
