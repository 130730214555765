/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */
import { useTranslation } from 'next-i18next';
import HTMLInplaceEditor from '../../../../components/editing/HTMLInplaceEditor';
import React, { useCallback } from 'react';
import Slider1D from 'components/canvas/components/Slider1D';
import SlidersLayout from 'components/canvas/canvasTypes/Matrix2DQuestionCanvas/layouts/SlidersLayout';
import type { AnalyzeOptions, Matrix2DQuestionCanvas } from '@shared/schema/src';
import type { Render2DTickFunction } from 'components/canvas/canvasTypes/Matrix2DQuestionCanvas/layouts/Matrix2DLayout';
import type { Size } from './index';
import type { WithId } from '@mindhiveoy/schema';

interface SlidersProps {
  analyzeOptions: AnalyzeOptions;
  canvas: WithId<Matrix2DQuestionCanvas>;
  graphBackgroundColor?: string;
  // editMode: EditorMode;
  size: Size;
  legend?: JSX.Element;
  onChange: (name: string, value: any) => void;
}

export const Sliders = ({
  analyzeOptions,
  canvas,
  // editMode,
  size,
  legend,
  onChange,
}: SlidersProps) => {
  return <EditSliders
    analyzeOptions={analyzeOptions}
    canvas={canvas}
    // editMode={editMode}
    size={size}
    legend={legend}
    onChange={onChange}
  />;
};

const EditSliders = ({
  // analyzeProperties,
  canvas,
  // size,
  // legend,
  onChange,
}: SlidersProps) => {
  const {
    t,
  } = useTranslation();

  const handleRenderTick: Render2DTickFunction = useCallback(({
    axis,
    item: {
      label,
    },
    index,
  }) => {
    const handleTick = (name: string, value: string) => {
      if (canvas.tickLabelsPresentation === 'mirrored') {
        const otherAxis = axis === 'x' ? 'y' : 'x';
        onChange(`axis.${otherAxis}.range[${index}].label`, value);
        onChange(`axis.${otherAxis}.scaleType`, 'custom');
      }

      onChange(name, value);
      onChange(`axis.${axis}.scaleType`, 'custom');
    };

    return <HTMLInplaceEditor
      text={label}
      name={`axis.${axis}.range[${index}].label`}
      placeHolder=""
      onChange={handleTick as any}
    />;
  }, [canvas.tickLabelsPresentation, onChange,]);

  const handleRenderXTick: Render2DTickFunction = useCallback((value) => {
    return handleRenderTick({
      ...value,
      axis: 'x',
      side: 'top',
    } as any);
  }, [handleRenderTick,]);

  const handleRenderYTick: Render2DTickFunction = useCallback((value) => {
    return handleRenderTick({
      ...value,
      axis: 'y',
    } as any);
  }, [handleRenderTick,]);

  return <SlidersLayout
    xLabel={<HTMLInplaceEditor
      text={canvas?.axis.x.mainLabel}
      name={'axis.x.mainLabel'}
      placeHolder={t('Axis label')}
      onChange={onChange}
    />}
    yLabel={
      <HTMLInplaceEditor
        text={canvas?.axis.y.mainLabel}
        name={'axis.y.mainLabel'}
        placeHolder={t('Axis label')}
        onChange={onChange} />
    }
    showAxisLabels={canvas?.showAxisLabels}
    showMinMaxLabels={canvas?.showMinMaxLabels}
    sliderX={
      <Slider1D
        disabled
        layoutId='sliderX'
        max={canvas?.axis?.max}
        mode={canvas?.mode}
        showMinMax={canvas?.showMinMaxLabels}
        minLabel={<HTMLInplaceEditor
          text={canvas?.axis.x.minLabel}
          name={'axis.x.minLabel}'}
          placeHolder={t('Min label')}
          onChange={onChange} />
        }
        maxLabel={
          <HTMLInplaceEditor
            text={canvas?.axis.x.maxLabel}
            name={'axis.x.maxLabel}'}
            placeHolder={t('Max label')}
            onChange={onChange} />
        }
        axis={canvas.axis.x}
        showTicks={canvas?.showTickLabels}
        onRenderTick={handleRenderXTick as any}
      />
    }
    sliderY={
      <Slider1D
        disabled
        layoutId='sliderY'
        max={canvas?.axis?.max}
        mode={canvas?.mode}
        showMinMax={canvas?.showMinMaxLabels}
        minLabel={
          <HTMLInplaceEditor
            text={canvas?.axis.y.minLabel}
            name={'axis.y.minLabel'}
            placeHolder={t('Min label')}
            onChange={onChange} />
        }
        maxLabel={
          <HTMLInplaceEditor
            text={canvas?.axis.y.maxLabel}
            name={'axis.y.maxLabel'}
            placeHolder={t('Max label')}
            onChange={onChange} />
        }
        axis={canvas.axis.y}
        // range={canvas?.axis?.y?.range}
        showTicks={canvas?.showTickLabels}
        onRenderTick={handleRenderYTick as any}
      />}

  />;
};

// const AnalyzeSliders = ({
//   analyzeOptions: analyzeProperties,
//   canvas,
//   size,

// }: SlidersProps) => {
//   const xMainLabel = canvas?.axis.x.mainLabel;
//   const yMainLabel = canvas?.axis.y.mainLabel;

//   const topRange = canvas.axis.x.range;
//   const leftRange = canvas.axis.y.range;

//   const statisticsColorsArrayY = useMemo(() => {
//     if (analyzeProperties && analyzeProperties.stats) {
//       const groupedArrY = Object.entries(analyzeProperties.stats).map(
//         ([key, coloredValue,]) => {
//           return [...Object.entries(canvas.statistics)
//             .filter((statValue) => statValue[0]
//               .slice(0, statValue[0].length - 1) === key)[Object.entries(canvas.statistics)
//               .filter((statValue) => statValue[0]
//                 .slice(0, statValue[0].length - 1) === key)
//               .findIndex((e) => e[0].slice(-1) === 'Y')], coloredValue,];
//         }
//       );
//       groupedArrY.forEach((statYValue, index, self) => {
//         if (self.find((elem) => elem[1] === statYValue[1])) {
//           const newStatValueY = statYValue.map((elem) => elem);
//           newStatValueY[2] = {
//             ...self.find((elem) => elem[1] === statYValue[1])[2], value: newStatValueY[2].value,
//             label: newStatValueY[2].label,
//           };
//           groupedArrY[index] = newStatValueY;
//         }
//       }
//       );
//       return groupedArrY;
//     }
//     return null;
//   }, [analyzeProperties, canvas.statistics,]);

//   /**
//    * frequenciesArray is just a collection from the database sorted from min to max aka [{label: '1', value: 1},{label: '3', value: 3}]
//    */
//   // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
//   const frequenciesArray = useMemo(
//     () => canvas.frequencies && Object.entries(canvas.frequencies).map(([key, value,]) => {
//       return {
//         label: value.toString(), value,
//       };
//     }).sort((a, b) => a.value > b.value ? 1 : -1)
//       .filter((value, index, self) => index === self
//         .findIndex((t) => t.label === value.label && t.value === value.value
//         )
//       ), [canvas?.frequencies,]
//   );

//   /**
//    * filledFrequenciesArray is frequenciesArray with added empty spaces aka [{label: '1', value: 1},{label: ' ', value: 2},{label: '3', value: 3}] -
//    * to make Frequencies axis on the left filled
//    */
//   const filledFrequenciesArray = useMemo(() => {
//     const arr = [];
//     if (frequenciesArray && frequenciesArray.length) {
//       for (let i = 1; i <= frequenciesArray[frequenciesArray.length - 1].value; i++) {
//         arr.push({
//           label: frequenciesArray.find((elem) => elem.value === i) ? frequenciesArray.find((elem) => elem.value === i).label : ' ', value: i,
//         });
//       }
//     }
//     return arr;
//   }, [frequenciesArray,]);

//   /**
//    * Those arrays are needed to make X and Y stats which are the same value the same color
//    */
//   const statisticsColorsArrayX = useMemo(() => {
//     if (analyzeProperties && analyzeProperties.analyzeValues) {
//       const groupedArrX = Object.entries(analyzeProperties.analyzeValues).map(
//         ([key, coloredValue,]) => {
//           // TODO: rewrite this to be more readable
//           return [...Object.entries(canvas.statistics)
//             .filter((statValue) => statValue[0].slice(0, statValue[0].length - 1) === key)
//           [
//             Object
//               .entries(canvas.statistics)
//               .filter(
//                 (statValue) => statValue[0].slice(0, statValue[0].length - 1) === key)
//               .findIndex(
//                 (e) => e[0].slice(-1) === 'X')], coloredValue,];
//         }
//       );
//       groupedArrX.forEach((statXValue, index, self) => {
//         if (self.find((elem) => elem[1] === statXValue[1])) {
//           const newStatValueX = statXValue.map((elem) => elem);
//           // check this
//           newStatValueX[2] = {
//             ...self.find((elem) => elem[1] === statXValue[1])[2], value: newStatValueX[2].value,
//             label: newStatValueX[2].label,
//           };
//           return newStatValueX;
//         } else {
//           return statXValue;
//         }
//       });
//       return groupedArrX;
//     }
//     return null;
//   }, [analyzeProperties, canvas.statistics,]);

//   /**
// * statisticsLegendColorsArray is makes the statistic-values which are chosen with the same colors to appear in the same line aka:
// * arrayToFillWithColors:  {value: true, color: 'darkblue', label: 'Average'}
// *                         {value: true, color: 'darkblue', label: '1st quartile'}
// *                         {value: false, color: 'darkblue', label: 'Median'}
// *                         {value: false, color: 'darkblue', label: '3rd quartile'}
// *                         {value: true, color: '#7f6000ff', label: 'Std. deviation'}
// *
// * and return arr: {value: true, color: 'darkblue', label: 'Average, 1st quartile'}
// *                 {value: true, color: '#7f6000ff', label: 'Std. deviation'}
// */
//   const statisticsLegendColorsArray = useCallback((arrayToFillWithColors: ColoredValue[]): ColoredValue[] => {
//     const arr = [] as ColoredValue[];
//     if (arrayToFillWithColors && arrayToFillWithColors.length !== 0) {
//       arrayToFillWithColors.filter((e) => e.value === true).forEach((el) => {
//         if (arr.find((e) => e.value === true && e.color === el.color)) {
//           arr[arr.findIndex((e) => e.value === true && e.color === el.color)] = {
//             ...arr.find((e) => e.value === true && e.color === el.color),
//             label: `${arr.find((e) => e.value === true && e.color === el.color).label}, ${el.label}`,
//           };
//         } else {
//           arr.push(el);
//         }
//       });
//     }
//     return arr;
//   }, []);

//   return <>
//     <div style={{
//       width: '100%',
//       display: 'flex',
//     }}>
//       <QuestionLayoutGrid style={{
//         margin: 'auto',
//         height: `${size.height + 100}px`,
//       }}>
//         <BottomLabeling key="bottom-label">
//           <XTickMarks
//             scaleType={canvas.axis.x.scaleType}
//             range={topRange}
//             padding={4}
//             scaleLength={canvas.axis.x.scaleLength}
//           />
//           <HorizontalAxisMinMaxLabel $showAxisLabels={canvas.showAxisLabels}>
//             {canvas.showMinMaxLabels && xMainLabel}
//           </HorizontalAxisMinMaxLabel>
//         </BottomLabeling>
//         <LeftLabeling key="left-label">
//           <VerticalAxisLabel $showMinMax={canvas.showMinMaxLabels}>
//             {canvas.showAxisLabels && <>{'Frequencies'}</>}
//           </VerticalAxisLabel>
//           {canvas.frequencies && <YTickMarks
//             scaleType={'numeric'}
//             range={filledFrequenciesArray}

//           />}
//         </LeftLabeling>
//         <Content>
//           <Chart
//             statisticsColorsArrayX={statisticsColorsArrayX}
//             statisticsColorsArrayY={statisticsColorsArrayY}
//             axisName='x'
//             size={size}
//             axis={canvas.axis.x}
//             canvas={canvas}
//             canvasMode={canvas.mode} />
//         </Content>
//       </QuestionLayoutGrid>

//       <QuestionLayoutGrid style={{
//         margin: 'auto',
//         height: `${size.height + 100}px`,
//       }}>
//         <BottomLabeling key="bottom-label">
//           <XTickMarks scaleType={canvas.axis.y.scaleType}
//             range={leftRange} />
//           <HorizontalAxisMinMaxLabel $showAxisLabels={canvas.showMinMaxLabels}>
//             {canvas.showMinMaxLabels && <>{yMainLabel}</>}
//           </HorizontalAxisMinMaxLabel>
//         </BottomLabeling>
//         <LeftLabeling key="left-label">
//           <VerticalAxisMinMaxLabel $showAxisLabels={canvas.showAxisLabels}>
//             {canvas.showAxisLabels && <>{'Frequencies'}</>}
//           </VerticalAxisMinMaxLabel>
//           {canvas.frequencies && <YTickMarks scaleType={'numeric'}
//             range={filledFrequenciesArray}
//             canvas={{
//               ...canvas, mode: 'discrete',
//             }} />}
//         </LeftLabeling>
//         <Content>
//           <Chart
//             statisticsColorsArrayX={statisticsColorsArrayX}
//             statisticsColorsArrayY={statisticsColorsArrayY}
//             axisName='y'
//             size={size}
//             axis={canvas.axis.y}
//             canvas={canvas}
//             canvasMode={canvas.mode} />
//         </Content>
//       </QuestionLayoutGrid>
//     </div>
//     <br />
//     {
//       analyzeProperties && analyzeProperties.analyzeValues &&
//       <div style={{
//         width: '100%',
//       }}>
//         <div style={{
//           width: '50%',
//           float: 'left',
//         }}>
//           {
//             statisticsLegendColorsArray(statisticsColorsArrayX.map((e) => e[2])).length !== 0 &&
//             <LegendFrame style={{
//               margin: 'auto',
//               width: 'fit-content',
//               paddingTop: '10px',
//             }}>
//               <Stats>
//                 {statisticsColorsArrayX && statisticsLegendColorsArray(statisticsColorsArrayX.map((e) => e[2])).map(
//                   (coloredValue, index) => {
//                     if (coloredValue.value) {
//                       return <div key={coloredValue.label + index.toString()}
//                         style={{
//                           display: 'flex',
//                           minHeight: '40px',
//                         }}>
//                         <div style={{
//                           width: '30px',
//                           minHeight: '40px',
//                         }}>
//                           <div style={{
//                             width: '20px',
//                             height: '15px',
//                             backgroundColor: coloredValue.color,
//                             //  margin: 'auto',
//                             marginLeft: '10px',
//                             marginTop: '5px',
//                           }} />
//                         </div>
//                         <div style={{
//                           minHeight: '40px',
//                           margin: 'auto',
//                           paddingLeft: '10px',
//                           flex: 1,
//                         }}>
//                           <p style={{
//                             margin: 'auto',
//                             textAlign: 'left',
//                           }}>
//                             {coloredValue.label}
//                           </p>
//                         </div>
//                       </div>;
//                     }
//                   }
//                 )}
//               </Stats>
//             </LegendFrame>
//           }
//         </div>
//         <div style={{
//           width: '50%',
//           float: 'right',
//           }}>'
//             {statisticsLegendColorsArray(statisticsColorsArrayX.map((e) => e[2])).length !== 0 && // TODO: Optimize this ...
//               <LegendFrame style={{
//                 margin: 'auto',
//                 width: 'fit-content',
//                 paddingTop: '10px',
//               }}>
//                 <Stats>
//                   {statisticsColorsArrayY && statisticsLegendColorsArray(statisticsColorsArrayY.map((e) => e[2])).map(
//                     (coloredValue, index) => {
//                       if (coloredValue.value) {
//                         return <div key={coloredValue.label + index.toString()}
//                           style={{
//                             display: 'flex',
//                             minHeight: '40px',
//                           }}>
//                           <div style={{
//                             width: '30px',
//                             minHeight: '40px',
//                           }}>
//                             <div style={{
//                               width: '20px',
//                               height: '15px',
//                               backgroundColor: coloredValue.color,
//                               //  margin: 'auto',
//                               marginLeft: '10px',
//                               marginTop: '5px',
//                             }} />
//                           </div>
//                           <div style={{
//                             minHeight: '40px',
//                             margin: 'auto',
//                             paddingLeft: '10px',
//                             flex: 1,
//                           }}>
//                             <p style={{
//                               margin: 'auto',
//                               textAlign: 'left',
//                             }}>
//                               {coloredValue.label}
//                             </p>
//                           </div>
//                         </div>;
//                       }
//                     }
//                   )}
//                 </Stats>
//               </LegendFrame>
//             }
//           </div>
//         </div>
//     }
//   </>;
// };
