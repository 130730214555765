import { EmptyLayoutElement } from './EmptyLayoutElement';
import { PropertyFieldPathProvider } from 'utils/hooks/usePropertyFieldPath';
import LayoutWidgetEditor from './LayoutWidgetEditor';
import React from 'react';
import type { BuilderComponentPropsBaseWithId, BuilderComponentType } from './PageWidgetRenderer';
import type { EditorMode } from 'components/session/SessionEditor/RestrictedSessionEditor/components/toolbar/ModeSwitch';
import type { SelectComponentWithIndexEvent } from './EmptyLayoutElement';

interface WidgetCellEditorProps {
  component: BuilderComponentPropsBaseWithId<object>;
  cellIndex: number;
  componentTypes?: BuilderComponentType[];
  editMode?: EditorMode;
  onClick: (index: number) => void;
  onDelete: (index: number) => void;
  onSelect: SelectComponentWithIndexEvent;
}
/**
 * Widget Cell Editor represents the editor for a single cell in the canvas layout.
 * It has a widget picker to select a widget for the cell, and a delete button to
 * remove the widget from the cell.
 *
 * @param param0
 * @returns
 */
export const WidgetCellEditor = ({
  editMode,
  component,
  componentTypes,
  cellIndex,
  onClick,
  onDelete,
  onSelect,
}: WidgetCellEditorProps) => {
  return <PropertyFieldPathProvider propertyFieldPath={`[${cellIndex}]`}>
    {component ?
      <LayoutWidgetEditor
        editMode={editMode as any}
        index={cellIndex}
        key={cellIndex.toString()}
        onClick={onClick}
        onDelete={onDelete}
        {...component}
      /> :
      <EmptyLayoutElement
        editMode={editMode as any}
        key={cellIndex.toString()}
        index={cellIndex}
        componentTypes={componentTypes as any}
        onSelect={onSelect}
      />
    }
  </PropertyFieldPathProvider>;
};
