/* eslint-disable @typescript-eslint/no-explicit-any */
import Typography from '@mui/material/Typography';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

import { getAxisRange } from './getAxisRange';
import CompositePropertyEditorControl from '../CompositePropertyEditorControl';
import type { Axis, TickLabelingType } from '@shared/schema/src';
import type { AxisPropertyEditor } from '../PropertyEditorTypes';
import type { BuilderConfigPropsFunction, PropertyConfig } from '../../builders/componentBuilder';

// Can be used in future (property responsible for the string value of the label)
// const config: PropertyConfig<Axis> = {
//   // label: {
//   //   type: 'text',
//   //   displayName: 'Axis Label',
//   //   defaultValue: '',
//   // },
//   // range: {

//   // },
// };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const axisConfig: BuilderConfigPropsFunction<any> = (component) => {
  // TODO Exact typing and support for axis in different contexes
  // const scaleLength = component.axis.scaleLength;
  return {
    scaleType: {
      type: 'picker',
      displayName: 'Scale type',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      defaultValue: component.mode === 'continuous' ? 'numeric' : 'plus-minus',
      options:
        component.mode === 'continuous' ?
          [
            {
              value: 'numeric',
              label: 'Numeric',
            },
            {
              value: 'percentage',
              label: 'Percentage',
            },
          ] :
          [
            {
              value: 'plus-minus',
              label: 'Plus-Minus',
            },
            {
              value: 'numeric',
              label: 'Numeric',
            },
            {
              value: 'percentage',
              label: 'Percentage',
            },
            {
              value: 'custom',
              label: 'Custom',
              hide: true,
            },
          ],
      order: 10,
      onPropertyChange: ({
        data,
        component,
        propertyValue,
        updateProperty,
        updateComponentProperty,
      }: any) => {
        const axis = data as Axis;

        const range = getAxisRange({
          scaleType: propertyValue as TickLabelingType,
          currentRange: data.x?.range,
          scaleLength: axis.scaleLength ?? component.axis?.scaleLength ?? 7,
          mode: component.mode,
          max: component.axis.x.max,
        });

        if (range) {
          // Custom labels are written to labels in axes directly
          updateProperty('range', range);

          if (component.tickLabelsPresentation === 'mirrored') {
            updateComponentProperty('axis.y.range', range);
          }
        }
      },
    },
  } as Partial<PropertyConfig<Axis>>;
};

export const AxisEditor: PropertyEditorFunction<Axis, AxisPropertyEditor> = ({
  data,
  component,
  propertyName,
  propertyNamePath,
  propertyConfig: {
    defaultValue,
    displayName,
    section,
  },
  onDelete,
  onSave,
}) => {
  const id = `editor-${propertyName}`;
  return <>
    {section &&
      <>
        <Typography component={'span'}
          variant="h4"
          id={`${id}-label`}>{section}
        </Typography>
        <br/>
      </>
    }

    {displayName &&
      <>
        <Typography component={'span'}
          variant="h5"
          id={`${id}-label`}>{displayName}
        </Typography>
        <br/>
      </>
    }

    <CompositePropertyEditorControl
      component={component}
      propertyName={propertyName}
      propertyNamePath={propertyNamePath}
      data={data ?? defaultValue}
      config={axisConfig}
      onDelete={onDelete}
      onSave={onSave}
    />
  </>;
};

AxisEditor.config = {
  type: 'axis',
  displayName: 'Axis',
};

export default AxisEditor;
