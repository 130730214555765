/* eslint-disable sonarjs/no-duplicate-string */
import { BOTTOM_BAR_SHIFT } from 'components/canvas/BottomNavigation';
import type { CSSProperties } from 'react';
import type { Layout } from '@shared/schema/src';

/**
 * Resolve css style for the layout
 * @param {Layout} layout   Layout type
 * @return {CSSProperties}  Style object
 */

export const resolveWidgetLayoutStyle = (
  layout: Layout = 'single',
  isEditorView = false,
  isMobile = false
): CSSProperties => {
  const style: CSSProperties = {
    position: 'relative',
    width: '100%',
    height: `calc(100% - ${BOTTOM_BAR_SHIFT + 160}px)`,
    margin: 'auto',
    overflow: 'hidden',
  };

  switch (layout) {
    case 'single':
      return style;

    case 'two-column':
      return {
        ...style,
        'display': 'grid',
        'gridTemplateColumns': '50% 50%',
        'gridTemplateRows': '100%',
        'gap': '0px 0px',
      };

    case 'three-column':
      return {
        ...style,
        'display': 'grid',
        'gridTemplateColumns': '33.3% 33.4% 33.3%',
        'gridTemplateRows': isEditorView ? '1fr' : 'minmax(min-content, max-content)',
        'gap': '0px 0px',
      };
    case 'matrix-2x2':
      return {
        ...style,
        'display': 'grid',
        'gridTemplateColumns': '50% 50%',
        'gridTemplateRows': isEditorView || !isMobile ? '50% 50%' : 'minmax(min-content, max-content)',
        'gap': '0px 0px',
      };

    case '1-grow':
      return {
        ...style,
        'display': 'grid',
        'gridTemplateColumns': 'auto 1fr',
        // 'gridTemplateRows': isEditorView || !isMobile ? '50% 50%' : 'minmax(min-content, max-content)',
        'gap': '0px 0px',
      };

    case 'grow-1':
      return {
        ...style,
        'display': 'grid',
        'gridTemplateColumns': '1fr auto',
        // 'gridTemplateRows': isEditorView || !isMobile ? '50% 50%' : 'minmax(min-content, max-content)',
        'gap': '0px 0px',
      };

    default:
      throw new Error(`Unsupported layout type: ${layout}`);
  }
};
