/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable valid-jsdoc */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ListenerHelper } from '../../../../utils/listener/listenerHelper';
import { startTransition } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import type { BuilderComponentPropsBase } from '../../widgets/PageWidgetRenderer';
import type { BuilderConfig } from '../../builders/componentBuilder';
import type { ComponentDataChange, ComponentModel } from '../ComponentModel';
import type { ComponentSelectionModel, SelectionDataChangeListenerFunction } from './index';
import type { DeletePropertyFunction, UpdatePropertyFunction } from 'components/builder/propertyEditors/PropertyEditorTypes';

/**
 * Selection model for a single components.
 *
 */
export class SingleComponentSelectionModel<D extends object, C extends BuilderComponentPropsBase<object>> implements ComponentSelectionModel<D> {
  private _config: BuilderConfig<D>;

  private _model: ComponentModel<D>;

  private _listeners = new ListenerHelper<SelectionDataChangeListenerFunction<any>>();

  private _data: D;

  private _state: 'loading' | 'active' = 'loading';
  private unsubscribe: () => void;
  /**
   *
   * @param {ComponentModel<D>}   model   Model related to selection
   * @param {BuilderConfig<D>}    config  Config of the component.
   */
  constructor(model: ComponentModel<D>, config: BuilderConfig<D>) {
    this._config = config;
    this._model = model;

    this._data = cloneDeep(model.component.data);

    this.unsubscribe = model.subscribeToDataChange(this.onDataChange);
  }

  /**
   * The current status of the selection.
   */
  public get state() {
    return this._state;
  }

  /**
   * Set the status of the selection.
   *
   * @param {('loading' | 'active')} status The new status of the selection.
   */
  public setState = (status: 'loading' | 'active') => {
    if (this._state !== status) {
      this._state = status;
      const _data = cloneDeep(this._data);

      startTransition(() => {
        this._listeners.fire(_data);
      });
    }
  };

  isRootLevel = () => true;

  /**
   * the type of the component. Type is being used to identify editable components in inspector.
   *
   * @return {string} The component type
   */
  public config = () => {
    return this._config;
  };
  /**
   *
   * @return {D} The current component data
   */
  public data = (): D => {
    return this._data;
  };

  public model = () => {
    return this._model;
  };

  public deleteProperty: DeletePropertyFunction<D> = (propertyName: any, ...args: any[]) => {
    return this._model.deleteProperty(propertyName as any); // TODO typing
  };

  public deleteGlobalProperty: DeletePropertyFunction<any> = (propertyName: any, ...args: any[]) => {
    return this._model.deleteProperty(propertyName as any); // TODO typing
  };

  /**
   * Update the property value of the selected component.
   *
   * @param {string} propertyName Name of the property being edited.
   * @param {any}    value        The new value of the property.
   * @return {any}            The new value of the property.
   */
  public updateProperty = (propertyName: any, value: any, ...args: any[]) => {
    return this._model.updateProperty(propertyName as any, value as any, ...args); // TODO typing
  };

  public updateGlobalProperty: UpdatePropertyFunction<any> = (propertyName: any, value: any, ...args) => {
    return this._model.updateProperty(propertyName, value, ...args);
  };

  private onDataChange = ({
    oldValue,
    newValue,
    sourcePropertyName,
  }: ComponentDataChange) => {
    if (oldValue === newValue) {
      return;
    }
    const data = newValue?.data ?? null;
    this._data = data;

    const _data = cloneDeep(data);
    startTransition(() => {
      this._listeners.fire(_data, sourcePropertyName);
    });
  };

  dispose = () => {
    this.unsubscribe();
    // this._model.removeDataChangeListener(this.onDataChange);
  };

  addSelectionDataChangeListener = (listener: SelectionDataChangeListenerFunction<any>) => {
    this._listeners.add(listener);
  };

  removeSelectionDataChangeListener = (listener: SelectionDataChangeListenerFunction<any>) => {
    this._listeners.remove(listener);
  };

  subscribeForDataChanges = (listener: SelectionDataChangeListenerFunction<D>) => {
    this._listeners.add(listener);

    listener(this._data);

    return () => {
      this._listeners.remove(listener);
    };
  };
}
