import { MessageType } from 'ts-react-form-validator';
import isEmail from 'validator/lib/isEmail';
import type { Email } from '@mindhiveoy/schema';
import type { TFunction } from 'next-i18next';

export const validateEmail = (t: TFunction) => (value: Email): true | {
  type: MessageType;
  message: string;
} => {
  if (!value) {
    return true;
  }
  return isEmail(value) || {
    type: MessageType.ERROR,
    message: t('email-is-invalid'),
  };
};
