/* eslint-disable require-jsdoc */
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND
} from 'lexical';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND
} from '@lexical/list';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { LexicalEditor } from 'lexical';

import { ToolbarButton } from './ToolbarButton';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import type { AllowedAttribute } from 'sanitize-html';

const LowPriority = 1;

const Toolbar = styled$.div<{
  $absolute?: boolean;
}>(({
  theme,
  $absolute,
}) => `
  ${$absolute ?
    `position: absolute;
    bottom: -2.5rem;` : ''}
  display: flex;
  justify-content: space-between;
  background: transparent;
  vertical-align: middle;
  flex-direction: row;
  width: 100%;
  :disabled {
    cursor: not-allowed;
  }
  :active {
    background-color: ${theme.palette.background.paper}
  }
`);

const LeftSideButtons = styled$.div<{
  $centerButtons?: boolean;
}>(({
  $centerButtons,
}) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${$centerButtons ? '100%' : 'auto'};
  justify-content: ${$centerButtons ? 'center' : 'flex-start'};
`);

const RightSideButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export interface ToolbarPluginProps {
  showSubmitButton?: boolean;
  absolute?: boolean;
  /**
   * When defined, the editor will only allow the specified styles
   */
  allowedStyles?: AllowedAttribute[];
  centerButtons?: boolean;
  onSubmit?: (editor: LexicalEditor) => void;
}

/**
 * Default toolbar plugin
 * TODO: make it more generic and customizable
 */
const ToolbarPlugin = ({
  absolute,
  allowedStyles,
  centerButtons,
  showSubmitButton = false,
  onSubmit,
}: ToolbarPluginProps) => {
  const [editor,] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  // const [canUndo, setCanUndo,] = useState(false);
  // const [canRedo, setCanRedo,] = useState(false);

  const [isBold, setIsBold,] = useState(false);
  const [isItalic, setIsItalic,] = useState(false);
  const [isUnderline, setIsUnderline,] = useState(false);
  // const [isStrikethrough, setIsStrikethrough,] = useState(false);
  const [blockType, setBlockType,] = useState('paragraph');
  const [isEditorEmpty, setIsEditorEmpty,] = useState(true);

  /**
   * Update the toolbar state when the editor state changes
   */
  const updateToolbar = useCallback(() => {
    const root = $getRoot();
    // const isEmpty = root?.getFirstChild()?.isEmpty() && root.getChildrenSize() === 1;
    const isEmpty = !root?.getFirstChild();

    setIsEditorEmpty(isEmpty);
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root' ?
          anchorNode :
          anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          setBlockType('paragraph');
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      // setIsStrikethrough(selection.hasFormat('strikethrough'));
    }
  }, [editor,]);

  useEffect(() => {
    // register various update listeners
    return mergeRegister(
      editor.registerUpdateListener(({
        editorState,
      }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar,]);

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, null as any);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, null as any);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, null as any);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, null as any);
    }
  };

  const preventDefault = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit?.(editor);
  }, [onSubmit, editor,]);

  // yes, this is using CSS styles for now as the UI code was taken from the Lexical playground
  // TODO: replace with styled components
  return (
    <Toolbar
      $absolute={absolute}
      onMouseDown={preventDefault}
      ref={toolbarRef}>
      <LeftSideButtons $centerButtons={centerButtons}>
        {!allowedStyles || allowedStyles.includes('bold') ? <ToolbarButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          }}
          ariaLabel="Format Bold"
          image="bold"
          active={isBold}
        /> : null}
        {!allowedStyles || allowedStyles.includes('italic') ? <ToolbarButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
          }}
          ariaLabel="Format Italics"
          image='italic'
          active={isItalic}
        /> : null}
        {!allowedStyles || allowedStyles.includes('underline') ? <ToolbarButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
          }}
          ariaLabel="Format Underline"
          image='underline'
          active={isUnderline}
        /> : null}
        {!allowedStyles || allowedStyles.includes('list') ? <ToolbarButton
          onClick={formatBulletList}
          ariaLabel="Bullet list"
          image='bullet-list'
          active={blockType === 'ul'}
        /> : null}
        {!allowedStyles || allowedStyles.includes('ordered-list') ?
          <ToolbarButton
            onClick={formatNumberedList}
            ariaLabel="Numbered list"
            image='numbered-list'
            active={blockType === 'ol'}
          /> : null}
      </LeftSideButtons>
      {showSubmitButton ?
        <RightSideButton>
          <ToolbarButton
            disabled={isEditorEmpty}
            onClick={handleSubmit}
            ariaLabel="Submit comment"
            image='send'
          />
        </RightSideButton> :
        null}
    </Toolbar >
  );
};

export default ToolbarPlugin;
