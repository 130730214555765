import type { PathParams } from '@shared/schema/src';

import setup from '../next-i18next.config';

const locales = new Set<string>(
  setup.i18n.locales
);

/**
 * Parse path from the browser and extract the params. This is being used to
 * avoid unnecessary re-renders when the pathParams are used in hooks.
 *
 * NOTE: This currently only works with the following path:
 *     /:spaceId/:projectId/:sessionId/:canvasId/:commentId
 *
 * @returns
 */
export const extractParamsFromLocation = (): PathParams => {
  if (typeof location === 'undefined') {
    return {} as PathParams;
  }
  // TODO: useRouter instead
  const path = location.pathname;
  const elements = path.split('/');
  const params: PathParams = {} as PathParams;

  const firstElement = elements[1];
  let i = locales.has(firstElement) ? 2 : 1;

  params.spaceId = elements[i++];
  params.projectId = elements[i++];
  params.sessionId = elements[i++];
  params.canvasId = elements[i++];

  if (elements.length > i) {
    params.commentId = elements.slice(i);
    if (params.commentId.length === 1) {
      params.commentId = params.commentId[0];
    }
  }
  return params;
};
