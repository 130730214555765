/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import type { PropsWithChildren } from 'react';

interface CanvasRenderingContextType {
  /**
   * Indicates if the component is being rendered in static context
   * without any interaction but with the same layout that individual
   * user may see
   */
  isStatic?: boolean;
  /**
   * Indicates if the component is being rendered in presentation context
   *
   * In presentation context, the component should not have any interaction.
   * Also graphs may have different rendering, ie. render pinned users independently
   * etc.
   */
  isPresentation?: boolean;
  /**
   * Indicates if the component is being rendered in interactive context
   */
  isInteractive?: boolean;
}

export const CANVAS_RENDER_MODE_DEFAULT: CanvasRenderingContextType = {
  isStatic: false,
  isPresentation: false,
  isInteractive: true,
};

export const CANVAS_RENDER_MODE_STATIC: CanvasRenderingContextType = {
  isStatic: true,
  isInteractive: false,
};

export const CANVAS_RENDER_MODE_PRESENTATION: CanvasRenderingContextType = {
  isStatic: true,
  isPresentation: true,
  isInteractive: false,
};

export const CanvasRenderingContext = React.createContext<CanvasRenderingContextType>(
  CANVAS_RENDER_MODE_DEFAULT
);

/**
 * Static rendering context hook indicates if the component is created in context, where
 * it should be static rendered ie. with no interaction or transitions. This is being
 * used with this project to render canvas miniatures in session editor.
 *
 * @return {boolean} true if the component is being rendered in static context
 */
export const useCanvasRenderingContext = () => {
  return useContext(CanvasRenderingContext);
};

interface StaticRenderingProviderProps {
  initialValue?: CanvasRenderingContextType;
}
const CanvasRenderingContextProvider = ({
  children,
  initialValue = {
    isStatic: false,
    isPresentation: false,
  },
}: PropsWithChildren<StaticRenderingProviderProps>
) =>
  <CanvasRenderingContext.Provider value={initialValue}>
    {children}
  </CanvasRenderingContext.Provider>;

export default CanvasRenderingContextProvider;
