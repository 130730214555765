import React, { useContext } from 'react';
import type { Member, ShortUserRoleId } from '@shared/schema/src';

export const MembershipContext = React.createContext<Member<ShortUserRoleId> | undefined>(undefined);

/**
 * A hook to get the current membership data
 * @returns
 */
const useMembership = <T extends Member<ShortUserRoleId>>(): T | undefined => {
  return useContext(MembershipContext) as T | undefined;
};

export default useMembership;
