/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-duplicated-branches */
import { Button as MaterialButton } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import styled$ from 'utils/react/styled$';
import type { MouseEventHandler, PropsWithChildren } from 'react';
import type { Theme } from '@emotion/react';

const getThemeColors = (color: string, theme: Theme): string => {
  switch (color) {
    case 'lightblue':
      return `
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText}`;
    case 'green':
      return `
      background-color: ${theme.palette.secondary.main};
      color: ${theme.palette.primary.contrastText};`;
    case 'darkGrey':
      return `
      background-color: ${theme.palette.background.comment};
      color: ${theme.palette.primary.contrastText}`;
    case 'lightGrey':
      return `
      background-color: ${theme.palette.action.disabledBackground};
      color: ${theme.palette.background.paper}`;
    case 'red':
      return `
      background-color: ${theme.palette.warning.dark};
      color: ${theme.palette.primary.contrastText}`;
    default:
      return `
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText}`;
  }
};

const getHoverColors = (color: string | undefined, theme: Theme): string => {
  switch (color) {
    case 'lightblue':
      return `
      background-color: ${theme.palette.primary.main}BF;
      color: ${theme.palette.primary.contrastText}`;
    case 'green':
      return `
      background-color: ${theme.palette.secondary.main}BF;`;
    case 'darkGrey':
      return `
      background-color: ${theme.palette.background.comment}BF;`;
    case 'lightGrey':
      return `
      background-color: ${theme.palette.action.disabledBackground}BF;`;
    case 'red':
      return `
      background-color: ${theme.palette.warning.dark}BF;`;
    default:
      return `
      background-color: ${theme.palette.primary.main}0D;
      color: ${theme.palette.primary.main}`;
  }
};

const StyledMaterialButton = styled$(MaterialButton)<{
  $variant: string;
  $bgColor?: string;
  $fullWidth?: boolean;
}>(({
  $variant,
  theme,
  $bgColor,
  $fullWidth,
}) => `
  font-weight: 600;
  padding: ${theme.spacing(1, 3)};
  width: ${$fullWidth ? '100%' : 'fit-content'};
  border-radius: ${theme.shape.borderRadius};
   &:hover {
    ${getHoverColors($bgColor, theme)}
    }
  ${$bgColor && ` 
    ${getThemeColors($bgColor, theme)}; 
  `}
  ${$variant === 'contained' && !$bgColor && `
    background-color: ${theme.palette.primary.main};
  `}
`);

export interface ButtonProps {
  /**
   * If `true`, the button will be disabled.
   */
  disabled?: boolean;
  /**
   * Element placed after the children.
   */
  endIcon?: React.ReactNode;
  /**
   * If `true`, the button will take up the full width of its container.
   */
  fullWidth?: boolean;
  /**
   * The URL to link to when the button is clicked.
   * If defined, an `a` element will be used as the root node.
   */
  href?: string;
  /**
   * The size of the button.
   * `small` is equivalent to the dense button styling.
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Element placed before the children.
   */
  startIcon?: React.ReactNode;
  /**
   * The variant to use.
   */
  variant?: 'text' | 'outlined' | 'contained';

  bgcolor?: 'lightblue' | 'green' | 'darkGrey' | 'lightGrey' | 'red';

  withoutMargin?: boolean;

  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const ButtonContainer = styled$.div<{
  $withoutMargin?: boolean;
  $fullWidth?: boolean;
}>(({
  $withoutMargin,
  $fullWidth,
}) => `
    margin: ${$withoutMargin ? 0 : 'auto'};
    text-align: 'center';
    width: ${$fullWidth ? '100%': 'fit-content'};

`);

const ButtonArea = styled$(motion.div)<{
  $fullWidth?: boolean;
}>(({
  $fullWidth,
}) => ` 
  display: 'inline-block',
  width: ${$fullWidth ? '100%': 'fit-content'};
`);

const hoverAnim = {
  scale: 1.05,
};
const tapAnim = {
  scale: 0.95,
};

/**
 * Primary UI component for user interaction
 * @return {ReturnType<Button>} components
 */
const Button = ({
  children,
  variant = 'outlined',
  bgcolor,
  withoutMargin,
  fullWidth,
  ...props
}: PropsWithChildren<ButtonProps>): JSX.Element => {
  return <ButtonContainer
    $fullWidth={fullWidth}
    $withoutMargin={withoutMargin}
  >
    <ButtonArea $fullWidth={fullWidth}
      whileHover={hoverAnim}
      whileTap={tapAnim}
    >
      <StyledMaterialButton
        $fullWidth={fullWidth}
        $bgColor={bgcolor}
        $variant={variant}
        {...props}>
        {children}
      </StyledMaterialButton>
    </ButtonArea>
  </ButtonContainer>;
};

export default Button;
