import type { BlocCacheUpdateOptions } from '../types';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { alterDocumentData } from '../../../utils/documentUtils';
import type { BlocCachedDocumentSnapshotListener, BlocDocumentIdChange, BlocErrorFunction, BlocUnsubscribeFunction } from '../types';
import type { CachedCollectionReference } from './CachedCollectionReference';
import type { DocumentData } from '@mindhiveoy/firebase-schema';
import type { WithId } from '@mindhiveoy/schema';

export type DocumentChangeListener<T extends DocumentData> = (
  data: WithId<T>,
) => void;

export interface DocumentReferenceListenerArgs<T extends DocumentData> {
  /**
   * Unique identifier for the document.
   */
  docId: string;
  /**
   * New data for the document
   */
  data: T | null;
  /**
   * Document data before change caused the event.
   */
  priorData: T | null;
  /**
   * True, when document id has been changed
   */
  docIdChanged?: boolean;
}

/**
 * Document reference change listener function.
 *
 * @param {DocumentReferenceListenerArgs} args
 * @param {string} args.docId           The id of the document.
 * @param {T} args.data                 New / current data of the document.
 * @param {T} args.priorData            Data's prior content before event triggering the listener.
 * @param {any} args.sender             The sender entity of the listener event.
 * @param {boolean} args.docIdChanged   Optional argument, that will be true in special case, where
 *                                      backend has forced the change of the of the document id.
 */
export type DocumentReferenceListener<T extends DocumentData> = (
  args: DocumentReferenceListenerArgs<T>
) => void;

/**
 *
 */
class CachedDocumentReference<T extends DocumentData> {
  // TODO: Remove from final version
  identifier = Math.floor(Math.random() * 1000);

  private $candidateDocId: string | null = null;

  private $data: T | null = null;

  private $refCount = 0;

  private $pending = true;

  private $isInitialized = false;

  /**
   *
   * @param {string} $docId
   * @param {boolean} candidate
   * @param {T | undefined} data
   */
  constructor(
    private collectionReference: CachedCollectionReference<T>,
    private $docId: string,
    data?: T | null,
    private candidate = false
  ) {
    this.$data = data ? data : null;
    this.$isInitialized = !!data;

    if (candidate) {
      this.$candidateDocId = this.$docId;
    }
  }
  /**
   * Increase reference count of the document
   */
  public addRef = () => {
    this.$refCount++;
  };
  /**
   * Decrease reference count of the document
   * @return {number} Current reference count.
   */
  public removeRef = (): number => {
    if (this.$refCount === 0) {
      console.error('Internal error, reference count bookkeeping is out of balance. Remove ref called when count is already zero.');
    }
    return --this.$refCount;
  };

  /**
   * Indicates if the document exists. If the document does not exists
   * it means that there is only an reference to a document that is not
   * available in the local database but may or may not exists
   * at the database.
   * @return {boolean} True if document exists, false otherwise
   */
  public get exists(): boolean {
    return !!(this.$data && this.$isInitialized);
  }
  /**
   *
   */
  public get referenceCount() {
    return this.$refCount;
  }

  /**
   * Indicates if the document is initialized. Document has data when it has been fetched
   * from the backend or when it has been created locally. If no data is available,
   * then the reference is just an reference to a possible document which may or may
   * not exist at the database.
   */
  public get isInitialized() {
    return this.$isInitialized;
  }
  /**
   * Change the document's id to a new one.
   *
   * NOTE: This method must only be called from DocumentReferenceSet -class. As
   *       Javascript does not have a concept of package level visibility, we must
   *       publish this method as public.
   * @param {string} toDocId
   */
  changeDocId(toDocId: string) {
    this.$docId = toDocId;
  }

  /**
   * Validate the document id against the candidate. If id's will not match, dds
   * @param {string}                docId
   * @param {BlocDocumentIdChange}  onDocIdChange
  */
  public validateDocumentId = (
    docId: string,
    onDocIdChange: BlocDocumentIdChange
  ) => {
    if (!this.$candidateDocId || docId === this.$candidateDocId) {
      this.$candidateDocId = null;
      return;
    }
    this.$candidateDocId = null;
    this.$docId = docId;
    onDocIdChange(docId);
  };

  /**
   * True, when the document is in the process of being created or not fetched yet
   * from the backend.
   */
  public get pending() {
    return this.$pending;
  }
  /**
   *
   */
  public get docId(): string {
    return this.$docId;
  }
  /**
   * Tell's if the document is in a candidate mode. In this mode, the document is in read only
   * mode until the backend has approved to candidate document id.
   *
   * @return {boolean} True if a candidate.
   */
  public isCandidate = (): boolean => {
    return !!this.$candidateDocId;
  };
  /**
   * Release resource before destruction.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public dispose = () => {

  };
  /**
   * Read the current local data for the document.
   */
  public get data() {
    return this.$data;
  }

  /**
   * The id of the document. You should always check the if of the
   * document using this method. The id may change because of the
   * optimistic updates when creating new documents.
   */
  public get id() {
    return this.$docId;
  }

  /**
   *
   * @param {T} data
   * @param {BlocCacheUpdateOptions} options
   */
  public setData = (
    data: T | null,
    options: BlocCacheUpdateOptions = {}
  ) => {
    this.$data = alterDocumentData(this.$data, data, options);
    this.$isInitialized = true;
    this.$pending = false;
  };

  /**
   * Delete document.
   * @param {BlocCacheUpdateOptions} options
   */
  public delete = () => {
    this.$data = null;
    this.$isInitialized = false;
    // TODO: Coverage with all test cases
  };
  /**
   *
   * @param {keyof T} fields
   * @param {BlocCachedDocumentSnapshotListener<T>} onSnapshot
   * @param {BlocErrorFunction=} onError
   *
   * @return {BlocUnSubscribeFunction}
   */
  public subscribe(
    fields: (keyof T)[],
    onSnapshot: BlocCachedDocumentSnapshotListener<T>,
    onError: BlocErrorFunction
  ): BlocUnsubscribeFunction;
  /**
   *
   * @param {BlocCachedDocumentSnapshotListener<T>} onSnapshot
   * @param {BlocErrorFunction=} onError
   *
   * @return {BlocUnSubscribeFunction}
   */
  public subscribe(
    OrSnapshot: BlocCachedDocumentSnapshotListener<T>,
    onError: BlocErrorFunction
  ): BlocUnsubscribeFunction;
  // eslint-disable-next-line require-jsdoc
  public subscribe(
    fieldsOrSnapshotListener: (keyof T)[] | BlocCachedDocumentSnapshotListener<any>,
    snapshotOrErrorListener: BlocCachedDocumentSnapshotListener<T> | BlocErrorFunction,
    onError?: BlocErrorFunction
  ): BlocUnsubscribeFunction {
    return this.collectionReference
      .onDocumentSnapshot(
        this.$docId,
        fieldsOrSnapshotListener as any,
        snapshotOrErrorListener as any,
        onError!
      );
  }
}

export default CachedDocumentReference;
