
type QueueCommand = () => Promise<void>;

/**
 * Represents a queue of commands that can be executed sequentially.
 */
export class CommandQueue {
  private queue: QueueCommand[] = [];
  private isExecuting = false;

  /**
   * Executes a command in FIFO-order.
   *
   * @param {QueueCommand}  command The command to execute.
   */
  async execute(command: QueueCommand): Promise<void> {
    this.queue.push(command);
    if (!this.isExecuting) {
      this.isExecuting = true;
      await this.processQueue();
    }
  }

  /**
   * Processes the command queue sequentially.
   */
  private async processQueue(): Promise<void> {
    while (this.queue.length > 0) {
      const currentCommand = this.queue.shift();
      if (currentCommand) {
        try {
          await currentCommand();
        } catch (error) {
          console.error(error);
        }
      }
    }
    this.isExecuting = false;
  }
}
