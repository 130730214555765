import { clearAnswer, setAnswer } from './answerApi';
import { createUseBlocHook } from '../../@mindhiveoy/react-bloc';
import BlocDocument from '../../@mindhiveoy/bloc/BlocDocument';
import type { Answer, AnswerParams } from '../../@shared/schema/src';
import type { BlocErrorFunction } from './../../@mindhiveoy/bloc/types';
import type { MemberId, WithId } from '@mindhiveoy/schema';

/**
 *
 */
export class AnswerBloc extends BlocDocument<Answer, AnswerParams> {
  /**
   * @param {AnswerParams} params Params to point the answer
   * @param {ErrorFunction} onError Error listener
   */
  constructor(
    params: AnswerParams,
    onError?: BlocErrorFunction
  ) {
    const {
      spaceId, projectId, sessionId, canvasId, answerId,
    } = params;

    super({
      documentPath:
        `spaces/${spaceId
        }/projects/${projectId
        }/sessions/${sessionId
        }/canvases/${canvasId
        }/answers/${answerId as string}`,
      params,
      onError,
    });
  }

  /**
   * Set a new answer for the given member.
   * @param {MemberId}  memberId    The member id
   * @param {Answer}    answer
   * @returns {Promise<WithId<Answer>>}
   */
  public set = async (
    memberId: MemberId,
    answer: Omit<Answer, 'created' | 'creatorId' | 'lastModified' | 'modifierId'>
  ) => {
    return this._set(
      async () => {
        const response = await setAnswer({
          spaceId: this.params.spaceId,
          projectId: this.params.projectId,
          sessionId: this.params.sessionId,
          canvasId: this.params.canvasId,
          answer: answer as Answer,
        });

        return {
          ...response,
          _id: memberId,
        };
      },
      this.onError,
      () => (
        {
          ...answer,
          _id: memberId,
        } as WithId<Answer>
      )
    );
  };

  /**
   * Erase member's current answer.
   *
   * @return {Promise<void>}
   */
  public erase = async () => {
    return this._delete(
      () => clearAnswer({
        ...this.params,
      }),
      this.onError
    );
  };
}

export const useAnswer = createUseBlocHook<Answer, AnswerParams, AnswerBloc>(
  ({
    params,
    onError,
  }) => new AnswerBloc(params, onError)
);
