import { BlocQuery } from '../../@mindhiveoy/bloc/BlocQuery';
import { createUseBlocHook } from '../../@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import { validateSessionProps } from '../../@shared/schema/src';
import type { BlocErrorFunction } from '../../@mindhiveoy/bloc/types';
import type { FeedItem } from '../../@shared/schema/src';
import type { ProjectParams } from '../../@shared/schema/src';

/**
 *
 */
export class FeedItemsBloc extends BlocQuery<FeedItem, ProjectParams> {
  /**
   *
   * @param {ProjectParams} params
   * @param {ErrorFunction} onError Error listener
   */
  constructor(
    params: ProjectParams,
    onError?: BlocErrorFunction
  ) {
    validateSessionProps(params, 'projectId');

    const {
      spaceId,
      projectId,
    } = params;

    super({
      collectionPath: `spaces/${spaceId}/projects/${projectId}/feed`,
      params,
      onError,
    });

    this.orderBy('created', 'desc');
  }
}

export const useFeedItems = createUseBlocHook<FeedItem, ProjectParams, FeedItemsBloc>(
  ({
    params,
    onError,
  }) => new FeedItemsBloc(params, onError)
);
