import isEqual from 'lodash/isEqual';
import { cleanChangeInfoFields } from './cleanChangeInfoFields';

/**
 * Smart update function that compares two values and returns the new value if
 * it is different from the current value.
 * 
 * NOTE: This function is useful when updating a value in a state and you want to
 * avoid unnecessary re-renders. The input is expected to by Mindcloud's document
 * with possible change info fields. The change info fields are removed before
 * comparison.
 * 
 * @param currentValue  Current value 
 * @param newValue      New value
 * @returns             New value if it is different from the current value
 */
export const smartUpdate = <T extends (Record<string, any> | null | undefined) = Record<string, any> | null | undefined>(currentValue: T, newValue: T) => {
  return isEqual(
    cleanChangeInfoFields(currentValue),
    cleanChangeInfoFields(newValue)
  ) ? currentValue : newValue;
};
