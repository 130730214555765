
export const LABEL_PADDING_REM = 0.5;

export const MIN_MAX_LABEL_FONT_SIZE_REM = 1.5;

export const TICK_FONT_SIZE_REM = 1;

export const AXIS_LABEL_FONT_SIZE_REM = 2;

export const MOBILE_THRESHOLD = 600;

export const DARK_LABEL_COLOR = 'rgba(0,0,0,0.3)';

const getResponsiveValue = (value: number): number => {
  if (typeof window === 'undefined') {
    return value;
  }
  const width = window.innerWidth;

  if (width < MOBILE_THRESHOLD) {
    return value / 2;
  }
  return value;
};

export const getTickFontSize = (): number => {
  return getResponsiveValue(TICK_FONT_SIZE_REM);
};

export const getMinMaxLabelFontSize = (): number => {
  return getResponsiveValue(MIN_MAX_LABEL_FONT_SIZE_REM);
};

export const getAxisLabelFontSize = (): number => {
  return getResponsiveValue(AXIS_LABEL_FONT_SIZE_REM);
};

export const getLabelPadding = (): number => {
  return getResponsiveValue(LABEL_PADDING_REM);
};
