import { getVariantDisplayName } from 'utils/variantUtils';
import { useAuth } from '@mindhiveoy/react-auth';
import React, { useCallback, useEffect } from 'react';
import SignInFormPure from './SignInFormPure';
import useNetworkStatus from 'utils/hooks/useNetworkStatus';
import type { AuthenticationType } from '@mindhiveoy/react-auth';
import type { Invitation, ShortUserRoleId } from '@shared/schema/src';
import type { SignInResponse } from './SignInFormPure';
import type { WithId } from '@mindhiveoy/schema';

interface SignInFormViewProps {
  invitation?: WithId<Invitation<ShortUserRoleId>>;
  onForgotPasswordClick?: () => void;
  onSignedIn?: (invitation?: WithId<Invitation<ShortUserRoleId>>) => void;
  onRegisterClick?: () => void;
}

export const SignInFormView = ({
  invitation,
  onForgotPasswordClick,
  onSignedIn,
  onRegisterClick,
}: SignInFormViewProps) => {
  const auth = useAuth();

  const {
    signInWIthCredentials,
    signInWithEmail,
  } = auth;

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  const handleSignInWithEmailClick = useCallback(
    async (email: string, _password: string): Promise<SignInResponse> => {
      try {
        await signInWithEmail(email, _password);

        onSignedIn?.(invitation);

        return {
          status: 'success',
        };
      } catch (error: any) {
        return {
          status: 'error',
          message: error?.toString() ?? 'Internal error',
          errorCode: error.errorCode ?? 'auth_internal_error',
        };
      }
    }, [invitation, onSignedIn, signInWithEmail,]);

  const handleSignInWIthCredentials = useCallback(
    async (authType: AuthenticationType): Promise<SignInResponse> => {
      try {
        await signInWIthCredentials(authType);

        onSignedIn?.();

        return {
          status: 'success',
        };
      } catch (error: any) {
        return {
          status: 'error',
          message: error ?? 'Internal error',
          errorCode: error.errorCode ?? 'auth_internal_error',
        };
      }
    }, [onSignedIn, signInWIthCredentials,]);

  const online = useNetworkStatus();

  return <SignInFormPure
    offline={!online}
    title={getVariantDisplayName()}
    onForgotPasswordClick={onForgotPasswordClick}
    onRegisterClick={onRegisterClick}
    onSignInWithCredentials={handleSignInWIthCredentials}
    onSignInWithEmail={handleSignInWithEmailClick}
  />;
};
