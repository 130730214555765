import { AXIS_LABEL_FONT_SIZE_REM, LABEL_PADDING_REM, MIN_MAX_LABEL_FONT_SIZE_REM, MOBILE_THRESHOLD } from '../labelUnits';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';

export const TopLabeling = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
`;

export const BottomLabeling = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
`;

export const LeftLabeling = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
`;

export const RightLabeling = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const VerticalAxisLabel = styled$.div<{
  $showMinMax?: boolean;
}>(({
  $showMinMax = false,
}) => `
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: ${$showMinMax ? 'uppercase' : 'none'};
  font-weight: ${$showMinMax ? 'bold' : 'none'};
  transform: rotate(180deg);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: ${AXIS_LABEL_FONT_SIZE_REM}rem;
  padding-top: ${LABEL_PADDING_REM}rem;
  padding-bottom: ${LABEL_PADDING_REM}rem;
  padding-right: ${LABEL_PADDING_REM}rem;
  @media(max-width: ${MOBILE_THRESHOLD}px) {
    padding-top: ${LABEL_PADDING_REM / 2}rem;
    padding-bottom: ${LABEL_PADDING_REM / 2}rem;
    font-size: ${AXIS_LABEL_FONT_SIZE_REM / 2}rem !important;
  }
`);

export const VerticalAxisMinMaxLabel = styled$.div<{
  $showAxisLabels?: boolean;
}>(({
  $showAxisLabels = false,
}) => `
  writing-mode: vertical-rl;
  display: block;
  text-align: center;
  font-size: ${MIN_MAX_LABEL_FONT_SIZE_REM}rem;
  color: ${$showAxisLabels ? 'rgba(230,230,230, 0.7)' : 'inherit'};
  transform: rotate(180deg);
  overflow: hidden;
  line-height: 0.5;
  text-overflow: ellipsis;
  padding: ${LABEL_PADDING_REM}rem;
  @media(max-width: ${MOBILE_THRESHOLD}px) {
    padding: ${LABEL_PADDING_REM / 2}rem;
    font-size: ${MIN_MAX_LABEL_FONT_SIZE_REM / 2}rem;
  }
`);

export const HorizontalAxisLabel = styled$(VerticalAxisLabel)`
  writing-mode: initial;
  transform: scale(1, 1);
  height: fit-content;
  font-size: ${AXIS_LABEL_FONT_SIZE_REM}rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${LABEL_PADDING_REM}rem;
  @media(max-width: ${MOBILE_THRESHOLD}px) {
    padding: ${LABEL_PADDING_REM / 2}rem;
    font-size: ${AXIS_LABEL_FONT_SIZE_REM / 2}rem;
  }
`;

export const HorizontalAxisMinMaxLabel = styled$(VerticalAxisMinMaxLabel)`
  writing-mode: initial;
  transform: scale(1, 1);
  height: fit-content;  
`;
