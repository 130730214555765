import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import theme from 'theme';
import type { ModalProps } from '@mui/material/Modal';
import type { SxProps } from '@mui/system';

interface GenericModalProps {
  modalProps?: ModalProps;
  modalActions?: JSX.Element[];
  headerText: string;
  boxOverrideSxStyles?: {
    mobile: SxProps<Theme>;
    desktop: SxProps<Theme>;
  }
  containerStyleOverrides?:React.CSSProperties;
  contentContainerStyleOverrides?:React.CSSProperties;
  headerContainerStyleOverrides?:React.CSSProperties;
}

const defaultModalProps: Partial<ModalProps> = {
  open: false,
  BackdropProps: {
    timeout: 500,
  },
  closeAfterTransition: true,
  BackdropComponent: Backdrop,
};

const boxDefaultStyleMobile: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '90%',
  width: '95%',
  maxWidth: '100%',
  minHeight: '15%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const boxDefaultStyleDesktop: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  width: 600,
  maxWidth: 900,
  minHeight: 200,
  maxHeight: 900,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const StyledContentContainer = styled(Grid)`
  display: flex;
  overflow: auto;
  max-height: 45vh;
`;

const StyledContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

const StyledHeaderContainer = styled(Grid)(({
  theme,
}) => `
  display: flex;
  margin: ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} 0px;
  font-weight: bold;
`);

const StyledModalActionsContainer = styled(Grid)(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${theme.spacing(1)};
  position: relative;
`);

const GenericModal = ({
  modalProps,
  boxOverrideSxStyles,
  headerText,
  containerStyleOverrides,
  contentContainerStyleOverrides,
  headerContainerStyleOverrides,
  modalActions,
}:GenericModalProps): JSX.Element => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const combinedModalProps = useMemo(() => ({
    ...defaultModalProps,
    ...modalProps,
  }), [modalProps,]);

  const boxDefaultStyleSx: SxProps<Theme> = useMemo(() => isMobile ? {
    ...boxDefaultStyleMobile,
    ...boxOverrideSxStyles?.mobile,
  } : {
    ...boxDefaultStyleDesktop,
    ...boxOverrideSxStyles?.desktop,
  }, [boxOverrideSxStyles?.desktop, boxOverrideSxStyles?.mobile, isMobile,]);

  return <Modal
    {...combinedModalProps as any}
  >
    <Fade in={combinedModalProps?.open}>
      <Box sx={{
        ...boxDefaultStyleSx,
      }}>
        <StyledContainer container
          direction="column"
          style={{
            ...containerStyleOverrides,
          }}>
          <StyledHeaderContainer item
            style={{
              ...headerContainerStyleOverrides,
            }}>
            {headerText}
          </StyledHeaderContainer>
          <StyledContentContainer item
            style={{
              ...contentContainerStyleOverrides,
            }}>
            {combinedModalProps.children}
          </StyledContentContainer>
          <StyledModalActionsContainer item>
            {modalActions}
          </StyledModalActionsContainer>
        </StyledContainer>
      </Box>
    </Fade>
  </Modal>;
};

export default React.memo(GenericModal);
