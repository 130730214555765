import type { LabelFunction, PickerOption } from '../PropertyEditorTypes';

/**
 * Render option's label
 *
 * @param {PickerOption<unknown>} option Option which label shall be rendered.
 * @return {string} Display label
 */
export const renderOption = (
  option: PickerOption<unknown>
): string => {
  return renderLabel(option.label, option.value);
};

export const renderLabel = (
  label: string | LabelFunction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): string => {
  switch (typeof label) {
    case 'string':
      return label;

    case 'function':
      return label({
        value,
      });
    default:
      return `Unexpected label type: ${label}`;
  }
};
