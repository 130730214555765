export const lessThan = (a: any, b: any): number => {
  const at = typeof a;
  const bt = typeof b;

  if (at !== bt || at === undefined || bt === undefined) {
    return 0;
  }
  switch (at) {
    case 'number':
      return a - b;
    case 'string':
      return a.localeCompare(b);
    default:
      throw new Error(`Unsupported type: ${at}`);
  }
};
