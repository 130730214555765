import styled from '@emotion/styled';
import type { Matrix2DMiniatureAnswer } from '@shared/schema/src';

const Canvas = styled.div(({
  theme,
}) => `
  width: 40px;
  height: 40px;
  background-color: ${theme.palette.background.graph};
  position: relative;
  border-radius: ${(theme.shape.borderRadius as number) / 3}px;
  @media(max-width: 600px) {
    width: 49px;
    height: 49px;
  }
`);

const XAxis = styled.div(({
  theme,
}) => `
  position: absolute;
  width: 100%;
  height: 1.5px;
  top: calc(50% - 0.75px);
  left: 0;
  background-color: ${theme.palette.background.comment};
`);

const YAxis = styled.div(({
  theme,
}) => `
  position: absolute;
  width: 1.5px;
  height: 100%;
  top: 0;
  left: calc(50% - 0.75px);
  background-color: ${theme.palette.background.comment};
`);

export interface Canvas2DMatrixProps {
  answer?: Matrix2DMiniatureAnswer;
}

// let SIZE = 40;
// const KNOB_SIZE = SIZE / 7;

const Canvas2DMatrixMiniature = () => {
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // SIZE = isMobile ? 49 : 40;

  // const axis = canvas?.axis as Axis2D;
  // const {
  //   x,
  //   y,
  // } = answer ?? {};

  // const {
  //   ax, ay,
  // } = useMemo(() => {
  //   if (!axis || x === undefined || y === undefined) {
  //     return {
  //       ax: null,
  //       ay: null,
  //     };
  //   }
  //   switch (axis?.x?.scaleType) {
  //     case 'percentage':
  //       return {
  //         ax: (SIZE - KNOB_SIZE - 2) * x / 100,
  //         ay: (SIZE - KNOB_SIZE - 2) * y / 100 + 2,
  //       };
  //     case 'numeric':
  //     case 'plus-minus':
  //     case 'custom':
  //       return {
  //         ax: (SIZE - KNOB_SIZE / 2) * (x - axis.x.min) / axis.scaleLength,
  //         ay: (SIZE - KNOB_SIZE / 2) * (y - axis.x.min) / axis.scaleLength + 2,
  //       };

  //     default:
  //       return {
  //         ax: (SIZE - KNOB_SIZE) * x / axis.scaleLength,
  //         ay: (SIZE - KNOB_SIZE) * y / axis.scaleLength,
  //       };
  //   }
  // }, [axis, x, y,]);

  // const valid = ax !== null && ay !== null && !(isNaN(ax) || isNaN(ay));
  // if (!valid) {
  //   return null;
  // }
  return <Canvas>
    <XAxis />
    <YAxis />
    {/* <Knob
      x={ax}
      y={SIZE - ay - KNOB_SIZE}
      width={KNOB_SIZE}
      height={KNOB_SIZE}
    /> */}
  </Canvas>;
};

export default Canvas2DMatrixMiniature;
