/* eslint-disable @typescript-eslint/no-explicit-any */

import * as firestore from 'firebase/firestore';
import { cleanUpObject } from '@mindhiveoy/foundation';
import type { DocumentConverter } from '../bloc/databaseController/DocumentConverter';
import type { DocumentData } from '@mindhiveoy/firebase-schema';

type FieldConverter = 'timestamp' | 'serialized';

export type TransformMap<T> = {
  [field in keyof T]: FieldConverter;
};
/**
 * Convert a document to a format suitable for use in other formats.
 */
export class FirestoreDocumentConverter<Front extends DocumentData, Backend = any> implements DocumentConverter<Front, Backend> {
  private transformations: [string, any][];
  /**
   * Create a converter that will convert the document to a format suitable for
   * use in other formats.
   *
   * @param {TransformMap<Front>} transformMap Map of fields to be converted.
   */
  constructor(transformMap: TransformMap<Front>) {
    this.transformations = Object.entries(transformMap);
  }

  /**
   * Convert a database document into a local format.
   * @param {Backend} backend The database document to be converted.
   * @return {Front} The converted document.
   */
  toFrontendFrom = (backend: Backend): Front => {
    if (!backend) {
      return undefined as unknown as Front; // TODO: Fix types for TS5
    }
    const result: any = {
      ...backend,
    };

    this.transformations.forEach(([field, converter,]) => {
      const value = result[field];
      try {
        if (!value) {
          return;
        }
        const type = typeof value;

        switch (converter) {
          case 'timestamp':
          case 'datetime':
            if (type === 'number') {
              return;
            }
            if (type === 'object') {
              if (value['_seconds'] && value['_nanoseconds']) {
                result[field] = (value._seconds + value._nanoseconds / 1000000) * 1000;
                return;
              }
              if (value['seconds'] && value['nanoseconds']) {
                result[field] = (value.seconds + value.nanoseconds / 1000000) * 1000;
                return;
              }
            }
            return;

          case 'serialized':
            if (type === 'string') {
              result[field] = JSON.parse(value);
              return;
            }
        };
      } catch (error) {
        console.error('Error parsing JSON', error, value);
      }
    });
    return result;
  };
  /**
   * Convert the local format into a database document.
   *
   * @param {Front} frontend
   * @return { Backend } The database document object.
   */
  toBackendFrom = (frontend: Front): Backend => {
    if (!frontend) {
      throw new Error('Document cannot be undefined');
    }

    const result: any = {
      ...frontend,
    };

    this.transformations.forEach(([field, converter,]) => {
      const value = result[field];
      if (!value) {
        return;
      }
      switch (converter) {
        case 'timestamp':
          result[field] = firestore.Timestamp.fromMillis(value);
          break;

        case 'serialized':
          result[field] = JSON.stringify(value);
          break;
      }
    });
    return cleanUpObject(result);
  };
}
