/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { coord2DFromKey } from '@shared/schema/src';
import { useComponentDataContext } from 'utils/hooks/useComponentDataContext';
import { useEffect, useMemo, useState } from 'react';
import type {
  AnalyzeOptions, Axis, Coord2D, Frequencies, Statistics
} from '@shared/schema/src';

interface GridProps {
  axis: Axis;
  frequencies?: Frequencies;
  canvasMode?: 'continuous' | 'discrete';
  max?: number;
  min?: number;
  statistics?: Statistics;
  axisName?: 'x' | 'y';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  statisticsColorsArrayX?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  statisticsColorsArrayY?: any[];
}

// TODO: Refactor this component to more readable code
const ChartPlot = ({
  axis,
  frequencies,
  canvasMode,
  max,
  min,
  statistics,
  axisName,
  // statisticsColorsArrayX,
  // statisticsColorsArrayY,
}: GridProps): JSX.Element => {
  const scaleX = max! - min!;
  const columnCount = canvasMode === 'discrete' ? axis?.range.length : (scaleX ?? 0) + 1;

  const {
    model,
  } = useComponentDataContext();

  const [analyserOptions, setAnalyserOptions,] = useState<AnalyzeOptions>(
    () => model.component.data.analyzeOptions
  );

  useEffect(() => model.subscribeToDataChange(({
    newValue,
  }) => setAnalyserOptions(newValue?.data?.analyzeOptions as any ?? null)), [model,]);

  const maxFrequencyValue = useMemo(() => frequencies ?
    Object
      .values(frequencies)
      .reduce(
        (current, value) => Math.max(current, value), 1) :
    1, [frequencies,]);

  const result = useMemo(() => {
    if (columnCount == 0) {
      return null;
    }

    const cellWidth = 100 / columnCount;
    const result = [];

    const width = 1000 / columnCount;
    const height = 1000 / maxFrequencyValue;

    for (let y = 0; y < maxFrequencyValue + 1; y++) {
      canvasMode === 'discrete' && result.push(<line
        key={`r_${y}`}
        x1={0}
        y1={(y + 0.5) * height}
        x2={1000}
        y2={(y + 0.5) * height}
        stroke={'lightgray'}
        strokeWidth={5}
      />);
    }
    for (let x = 0; x < columnCount + 1; x++) {
      canvasMode === 'discrete' && result.push(<line
        key={`c_${x}`}
        y1={0}
        x1={(x + 0.5) * width}
        y2={1000}
        x2={(x + 0.5) * width}
        stroke={'lightgray'}
        strokeWidth={5}
      />);
    }
    const points = [] as Coord2D[];

    // Drawing frequencies
    frequencies && Object.entries(frequencies).forEach(
      ([key, value,]) => {
        // coord2DFromKey
        let parsedKey;
        if (axisName === 'x') {
          parsedKey = coord2DFromKey(key).x;
        } else if (axisName === 'y') {
          parsedKey = coord2DFromKey(key).y;
        } else {
          parsedKey = parseFloat(key);
        }
        // TODO constant for graph space size
        const cx = (parsedKey - min!) * cellWidth * 1000 / 100;
        const cy = value * height;
        // Answers rectangles
        points.push({
          x: cx + width / 2, y: 1000 - cy + height / 2,
        });
      }
    );

    if (analyserOptions && canvasMode === 'discrete') {
      points.forEach((point, index) => {
        result.push(<rect
          key={index}
          rx={100 / columnCount}
          x={point.x - width / 2}
          y={point.y}
          height={1000 - point.y + height / 2}
          width={width}
          fill={'grey'}
          stroke={'grey'}
          strokeWidth={0}
        />);
      });
    } else {
      if (points.length >= 3) {
        convert(
          points
            .sort((a, b) => a.x > b.x ? 1 : -1))
          .forEach((point) => {
            result.push(<path
              d={
                `M ${point[0].toString()} ${point[1].toString()} C ${point[2].toString()} ${point[3].toString()
                }, ${point[4].toString()} ${point[5].toString()}, ${point[6].toString()} ${point[7].toString()}`}
              stroke='grey'
              strokeWidth={5}
              fill="transparent" />);
          });
      } else {
        if (points.length === 2) {
          result.push(<line
            key={`c_${points[0].x}`}
            y1={points[0].y}
            x1={points[0].x}
            y2={points[1].y}
            x2={points[1].x}
            stroke={'gray'}
            strokeWidth={5}
          />);
        }
      }
      points.forEach((point) => {
        result.push(<circle
          cx={point.x}
          cy={point.y}
          r={10}
          fill={'grey'}
        />);
      });
    }

    // Drawing stats
    analyserOptions && analyserOptions.stats && Object.entries(analyserOptions.stats).forEach(
      ([key, coloredValue,]) => {
        if (statistics && statistics.type === '1d') {
          if (
            Object
              .entries(statistics)
              .find(([pro,]) => pro === key) && coloredValue) {
            const value = Object.entries(statistics).find(([pro,]) => pro === key)?.[1];
            const ax = (value - min!) * cellWidth * 1000 / 100 + width / 2;
            const answerWidth = 10;
            result.push(<rect
              key={key}
              x={ax - answerWidth / 2}
              y={0}
              rx={2}
              height={1015}
              width={answerWidth}
              fill="blue"
            />);
          }
        } else {
          if (statistics && Object.entries(statistics).find(([pro,]) => pro === key + axisName!.toUpperCase()) && coloredValue) {
            const value = Object.entries(statistics).find(([pro,]) => pro === key + axisName!.toUpperCase())?.[1];
            const ax = (value - min!) * cellWidth * 1000 / 100 + width / 2;
            const answerWidth = 10;
            // const color = axisName === 'x' ? statisticsColorsArrayX.find((elem) => elem[0].slice(0, elem[0].length - 1) === key)[2].color :
            //   statisticsColorsArrayY.find((elem) => elem[0].slice(0, elem[0].length - 1) === key)[2].color;

            result.push(<rect
              key={key}
              x={ax - answerWidth / 2}
              y={0}
              rx={2}
              height={1015}
              width={answerWidth}
              fill="blue"
            />);
          }
        }
      }
    );

    return result;
  }, [analyserOptions, axisName, canvasMode, columnCount, frequencies, maxFrequencyValue, min, statistics,]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return result as any;
};

const gt = (array: any, index: any) => {
  const it = index % array.length;
  if (it >= 0) {
    return array[it];
  }
  return array[array.length + it];
};

// Function the make a curve from the array of points
const convert = (points: any, close = false, radio = 0.6) => {
  if (points.length < 3) {
    throw new Error('more than 2');
  }
  const mid = (p1: any, p2: any) => ({
    x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2,
  });

  const inc = (p: any, vector: any, head: any, radio: any) => {
    let {
      x, y,
    } = p;
    const {
      arc, div, len,
    } = vector;
    if (head) {
      const ll = len * div * radio;
      x -= ll;
      y -= ll * arc;
    } else {
      const ll = len * (1 - div) * radio;
      x += ll;
      y += ll * arc;
    }
    return {
      x, y,
    };
  };

  const midpoints = [];
  for (let i = 1; i <= points.length; i += 1) {
    midpoints.push(mid(gt(points, i - 1), gt(points, i)));
  }
  const vectors = [];
  for (let i = 0; i < midpoints.length; i += 1) {
    const arc = (gt(midpoints, i + 1).y - gt(midpoints, i).y) / (gt(midpoints, i + 1).x - gt(midpoints, i).x);
    const div = 0.5;
    const len = gt(midpoints, i + 1).x - gt(midpoints, i).x;
    vectors.push({
      arc, len, div,
    });
  }

  const params = [];
  const pLen = close ? points.length : points.length - 1;
  for (let i = 0; i < pLen; i += 1) {
    const c = gt(points, i);
    const n = gt(points, i + 1);
    if (close) {
      const v1 = gt(vectors, i - 1);
      const v2 = gt(vectors, i);
      const h1 = inc(c, v1, false, radio);
      const h2 = inc(n, v2, true, radio);
      params.push([c.x, c.y, h1.x, h1.y, h2.x, h2.y, n.x, n.y,]);
      continue;
    }
    if (i === 0) {
      const v = vectors[0];
      const h = inc(n, v, true, radio);
      params.push([c.x, c.y, c.x, c.y, h.x, h.y, n.x, n.y,]);
      continue;
    }
    if (i === pLen - 1) {
      const v = gt(vectors, i - 1);
      const h = inc(c, v, false, radio);
      params.push([c.x, c.y, h.x, h.y, n.x, n.y, n.x, n.y,]);
      continue;
    }
    const v1 = gt(vectors, i - 1);
    const v2 = gt(vectors, i);
    const h1 = inc(c, v1, false, radio);
    const h2 = inc(n, v2, true, radio);
    params.push([c.x, c.y, h1.x, h1.y, h2.x, h2.y, n.x, n.y,]);
  }
  return params;
};

export default ChartPlot;
