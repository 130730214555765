import { motion } from 'framer-motion';
import styled from '@emotion/styled';

const Feed = styled(motion.div)(({
  theme,
}) =>`
  max-height: 100%;
  overflow-y: auto;
  background-color: ${theme.palette.background.default};
  height: 100%;
  padding: ${theme.spacing(2)};
`);

export default Feed;
