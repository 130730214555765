
export interface DocumentPathDescriptor {
  collectionPath: string;
  docId: string;
}
/**
 * @param {string} documentPath The path to the document in Firestore document hierarchy.
 * @return {DocumentPathDescriptor} Parsed presentation of the document path
 * @throw {Error} If the document path is not valid Firestore document path.
 */
const parseDocumentPath = (documentPath: string): DocumentPathDescriptor => {
  if (!documentPath) {
    throw new Error(`Invalid document path: ${documentPath}`);
  }

  const elements = documentPath.split('/');
  if (elements.length % 2 === 1) {
    throw new Error(`Document path ${documentPath} points to an collection instead of a single document`);
  }

  const docId = elements.pop();

  const collectionPath = elements
    .reduce((previous, current) => previous ? `${previous}/${current}` : current);

  return {
    collectionPath,
    docId: docId || '',
  };
};

export default parseDocumentPath;
