/* eslint-disable sonarjs/no-duplicate-string */
import {
  createContext, useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import { extractParamsFromLocation } from '../../../../utils/extractParamsFromLocation';
import { generatePathUrl } from 'utils/urls';
import { noop } from 'lodash';
import { usePanelistScrollContext } from './PanelistScrollProvider';
import { useRouter } from 'next/router';
import { useScreenSliderContext } from '../components/ScreenSlider';
import { useSession } from 'bloc/session/SessionBloc';
import type { CanvasId, CanvasStripInfo, PathParams, Session } from '@shared/schema/src';
import type { PropsWithChildren } from 'react';
import type { ViewSlideDirection } from '@mindhiveoy/react/ViewSlider';
import type { WithId } from '@mindhiveoy/schema';

// export let slideDirection: 'left' | 'right' = 'right';

// const setSlideDirection = (direction: 'left' | 'right') => {
//   slideDirection = direction;
// };

export interface PanelistViewContextType {
  canvases: CanvasStripInfo[];
  session?: WithId<Session>;
  /**
   * Comments
  */
  sessionShowCommenting: boolean;
  sessionShowVoting: boolean;
  slideDirection: ViewSlideDirection;
  canvasId?: CanvasId;
  canvasIndex: number;
  showCanvases: boolean;
  nextCanvas: () => void;
  previousCanvas: () => void;
  goToCanvas: (index: number) => void;
  hideCanvases: () => void;
  toggleCanvases: () => void;
}

export const PanelistViewContext = createContext<PanelistViewContextType>({
  canvases: [],
  canvasId: undefined,
  canvasIndex: 0,
  showCanvases: false,
  session: undefined,
  sessionShowCommenting: false,
  sessionShowVoting: false,
  slideDirection: 'left-to-right',
  goToCanvas: noop,
  nextCanvas: noop,
  previousCanvas: noop,
  hideCanvases: noop,
  toggleCanvases: noop,
});

export const PanelistScreenProvider = ({
  children,
}: PropsWithChildren<any>) => {
  const [showCanvases, setShowCanvases,] = useState(false);
  const [canvasIndex, setCanvasIndex,] = useState(0);

  const [canvases, setCanvases,] = useState<CanvasStripInfo[]>([]);
  const [session, setSession,] = useState<WithId<Session>>();
  const [canvasId, setCanvasId,] = useState<string>();

  const [sessionShowCommenting, setSessionShowCommenting,] = useState(true);
  const [sessionShowVoting, setSessionShowVoting,] = useState(true);

  const [slideDirection, setSlideDirection,] = useState<ViewSlideDirection>('left-to-right');
  const sessionBloc = useSession();

  const {
    spaceId,
    projectId,
    sessionId,
    canvas,
  } = useScreenSliderContext();

  const pathParams = useMemo(() => ({
    spaceId,
    projectId,
    sessionId,
    canvasId: canvas._id,
  }), [canvas._id, projectId, sessionId, spaceId,]);

  const lastUrlPath = useRef<string>();

  const {
    scrollTo,
  } = usePanelistScrollContext();

  const {
    push,
  } = useRouter();

  const pushPath = useCallback((params: Partial<PathParams>) => {
    const pathParams = extractParamsFromLocation();

    const path = generatePathUrl({
      ...pathParams,
      ...params,
    }, 'canvasId');

    lastUrlPath.current = path;

    push(path, path, {
      shallow: true,
    });
  }, [push,]);

  useEffect(() => {
    if (pathParams?.canvasId === canvasId) {
      return;
    }
    const id = pathParams.canvasId;
    setCanvasIndex(canvases.findIndex((canvas) => canvas._id === id));
    setCanvasId(id);
  }, [pathParams.canvasId, canvases, canvasId,]);

  useEffect(() => sessionBloc.subscribe(
    (session?: WithId<Session>) => {
      setSession(session);
      const canvases = session?.canvasStrip;
      setCanvases(canvases ?? []);
      if (canvases) {
        setCanvasId((id) => {
          if (id) {
            const index = canvases.findIndex((canvas) => canvas._id === id);
            setCanvasIndex(index);
            return id;
          }
          setCanvasIndex(0);
          return canvases[0]._id;
        });
      }
    }), [sessionBloc,]);

  const toggleCanvases = useCallback(() => {
    setShowCanvases((showCanvases: boolean) => !showCanvases);
  }, []);

  const hideCanvases = useCallback(() => {
    setShowCanvases(false);
  }, []);

  const nextCanvas = useCallback(() => {
    let didChange = false;

    let canvasIndex = 0;
    setShowCanvases(false);

    setCanvasIndex((index: number) => {
      canvasIndex = Math.min(index + 1, canvases.length - 1);
      didChange = canvasIndex !== index;
      return canvasIndex;
    });
    if (didChange) {
      setSlideDirection('right-to-left');

      scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

      pushPath({
        canvasId: canvases[canvasIndex]._id,
      });
    }
  }, [canvases, pushPath, scrollTo,]);

  useEffect(() => {
    setSessionShowCommenting(session?.commenting ?? true);
    setSessionShowVoting(session?.voting ?? true);
  }, [session?.commenting, session?.voting, scrollTo,]);

  const previousCanvas = useCallback(() => {
    let didChange = false;
    let canvasIndex = 0;
    setShowCanvases(false);

    setCanvasIndex((index: number) => {
      canvasIndex = Math.max(index - 1, 0);
      didChange = canvasIndex !== index;
      return canvasIndex;
    });

    if (didChange) {
      setSlideDirection('left-to-right');

      scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

      pushPath({
        canvasId: canvases[canvasIndex]._id,
      } as any);
    }
  }, [canvases, pushPath, scrollTo,]);

  const goToCanvas = useCallback((index: number) => {
    let didChange = false;
    setShowCanvases(false);

    setCanvasIndex((current: number) => {
      if (index === current) {
        return index;
      }
      didChange = true;
      setSlideDirection(index > current ? 'right-to-left' : 'left-to-right');

      scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return index;
    });

    if (didChange) {
      pushPath({
        canvasId: canvases[index]._id,
      });
    }
  }, [canvases, pushPath, scrollTo,]);

  const value: PanelistViewContextType = useMemo(() => ({
    canvases,
    canvasIndex,
    canvasId,
    showCanvases,
    session,
    sessionShowCommenting,
    sessionShowVoting,
    slideDirection,
    goToCanvas,
    hideCanvases,
    nextCanvas,
    previousCanvas,
    toggleCanvases,
  }), [canvases, canvasIndex, canvasId, showCanvases, session,
    sessionShowCommenting, sessionShowVoting, slideDirection,
    goToCanvas, hideCanvases, nextCanvas, previousCanvas, toggleCanvases,
  ]);

  if (!session) {
    return null;
  }
  return <PanelistViewContext.Provider value={value}>
    {children}
  </PanelistViewContext.Provider>;
};

/**
 * The panelist view context hook for the panelist view. This hook provides the current state of
 * the panelist view.
 */
export const usePanelistViewContext = () => {
  return useContext(PanelistViewContext);
};
