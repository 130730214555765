import { numericRange, percentageRange, plusMinusRange } from './Axis2DPropertyEditor';
import type { MatrixMode, RangeItem, TickLabelingType } from '@shared/schema/src';

type Args = {
  currentRange: RangeItem[];
  max: number;
  mode: MatrixMode;
  scaleLength: number;
  scaleType: TickLabelingType;
};

export const getAxisRange = ({
  currentRange,
  max,
  mode,
  scaleLength,
  scaleType,
}: Args) => {
  let range;
  // eslint-disable-next-line sonarjs/no-nested-switch
  // TODO: Range check
  switch (scaleType) {
    case 'plus-minus': {
      range = plusMinusRange[scaleLength];
      break;
    }
    case 'numeric': {
      if (mode === 'discrete') {
        range = numericRange[scaleLength];
        break;
      }

      range = [];

      const step = 2 * max / (scaleLength - 1);

      for (let i = 0; i < scaleLength; i += 1) {
        range.push({
          value: i * step - max,
          label: `${(i * step - max).toFixed(1)}`,
        });
      }
      break;
    }

    case 'percentage': {
      range = percentageRange[scaleLength];
      break;
    }

    case 'custom': {
      range = currentRange ? currentRange.slice(0) : [];
      // extend range if needed to scale length
      if (range.length < scaleLength) {
        let value = (range[range.length - 1]?.value ?? 0) + 1;
        for (let i = range.length; i < scaleLength; i += 1) {
          range.push({
            value,
            label: `${value++}`,
          });
        }
      } else {
        range = range.slice(0, scaleLength);
      }
      break;
    }

    default:
  }
  return range;
};
