import { Box } from '@mui/material';
import { MediaCopyright } from 'components/forms/MediaFormField/MediaCopyright';
import { uploadPhoto } from 'bloc/admin/member/membersApi';
import { useAuth } from '@mindhiveoy/react-auth';
import FormControl from '@mui/material/FormControl';
import FormElement from '../../../forms/FormElement';
import ImageSelector from 'components/admin/MemberAdmin/ImageSelector';
import MediaContainer from 'components/layout/MediaContainer';
import MediaFormField from '../../../forms/MediaFormField';
import React, { useCallback, useEffect, useState } from 'react';
import _, { uniqueId } from 'lodash';
import usePathParams from 'utils/hooks/usePathParams';
import type { Media } from '@shared/schema/src/common';
import type { MediaPropertyEditor } from '../PropertyEditorTypes';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';
import type { URL } from '@mindhiveoy/schema';

const MediaPropertyEditorComponent: PropertyEditorFunction<Media, MediaPropertyEditor> = ({
  data,
  propertyConfig: {
    displayName,
    defaultValue,
    showPreview = true,
    landscape,
  },
  propertyName,
  component,
  onSave,
}) => {
  const [media, setMedia,] = useState(data ?? defaultValue);
  const [editing, setEditing,] = useState(false);

  // const {
  //   name, desc,
  // } = component[propertyName] ?? {};

  useEffect(() => {
    if (editing) {
      return;
    }
    if (data !== media) {
      setMedia(data);
    }
  }, [data, editing, media,]);

  const handleSave = useCallback((newValue: Media) => {
    if (onSave && !_.isEqual(data, newValue)) {
      onSave(propertyName, newValue);
      setMedia(newValue);
    }
  }, [data, onSave, propertyName,]);

  const handleChange = useCallback((newValue: Media) => {
    handleSave(newValue);
  }, [handleSave,]);

  const handleFocus = useCallback((event: any) => {
    event?.target?.select && event.target.select();
    setEditing(true);
  }, []);

  const handleBlur = useCallback(() => {
    setEditing(false);
    handleSave(media);
  }, [handleSave, media,]);

  // const [saving, setSaving,] = useState(false);

  const params = usePathParams();
  const {
    memberId,
  } = useAuth();

  const handleNewImage = useCallback(async (newImage: URL) => {
    // TODO: We need a cleaning system to erase assets that have no reference anymore. This should
    // be done in the backend, but we need to be able to trigger it from the frontend.
    // setSaving(true);
    // try {
    const response = await uploadPhoto({
      contextId: params!.projectId ? 'project' : 'space',
      params: {
        spaceId: params!.spaceId!,
        projectId: params!.projectId,
      },
      photoType: 'media',
      memberId: memberId!,
      imageData: newImage,
      assetPath: uniqueId('image_'), // TODO: Better system for image ids
    });

    if (response.status === 'ok') {
      onSave(propertyName, {
        type: 'photo',
        photoURL: response.imageUrl,
      });
    }
    // } finally {
    //   setSaving(false);
    // }
  }, [memberId, onSave, params, propertyName,]);

  return <FormControl fullWidth>
    {
      landscape ? <Box
        flexDirection="row"
        display="flex"
      >
        <Box flex={3}
          paddingRight={2}
          position="relative"
        >
          <MediaFormField
            title={displayName ?? propertyName}
            media={data}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        </Box>
        {
          component && showPreview &&
          <Box flex={6}>
            <FormElement>
              {
                data?.type === 'photo' ?
                  <ImageSelector
                    imageUrl={data?.photoURL}
                    onNewImage={handleNewImage}
                  /> :
                  <MediaContainer media={media} />
              }
            </FormElement>
          </Box>
        }
      </Box> :
        <>
          <MediaFormField
            title={displayName ?? propertyName}
            media={data}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
          />
          {component && showPreview &&
            <FormElement>
              {
                data?.type === 'photo' ?
                  <ImageSelector
                    canCrop={data?.source !== 'unsplash'}
                    imageUrl={data?.photoURL}
                    onNewImage={handleNewImage}
                  /> :
                  <MediaContainer media={media} />
              }
              <MediaCopyright media={data} />
            </FormElement>
          }
        </>
    }
  </FormControl>;
};

MediaPropertyEditorComponent.config = {
  type: 'media',
  showPreview: false,
};

export default MediaPropertyEditorComponent;
