import { PropertyFieldPathProvider } from 'utils/hooks/usePropertyFieldPath';
import { useComponentDataContext } from 'utils/hooks/useComponentDataContext';
import { useEffect, useState } from 'react';
import CanvasEditor from 'components/builder/widgets/CanvasEditor';
import type { BuilderComponentType } from 'components/builder/widgets/BuilderComponentTypes';
import type { Layout } from '@shared/schema/src';

export const templateComponentTypes: BuilderComponentType[] = [
  'image',
  'statistics',
  'graph-2d',
  'free-text',
  // 'question-description',
  'qr-code',
  'comment-feed',
  'featured-comments',
  'canvas-placeholder',
  'grid-layout',
  'hash-tag-cloud',
  'label-cloud',
];

export const ContentArea = () => {
  const [layout, setLayout,] = useState<Layout>('1-grow');

  const {
    model,
  } = useComponentDataContext();

  useEffect(() => model.subscribeToDataChange(({
    newValue,
  }) => setLayout(newValue?.data.layout)
  ), [model,]);

  return <PropertyFieldPathProvider propertyFieldPath='elements'>
    <CanvasEditor
      layout={layout}
      componentTypes={templateComponentTypes} />
  </PropertyFieldPathProvider>;
};
