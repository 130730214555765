import {
  createContext, useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import type { PropsWithChildren } from 'react';

interface ParallaxContext {
  titlePos: number;
  titleSourceRef: React.RefObject<HTMLDivElement>;
  titleTargetRef: React.RefObject<HTMLDivElement>;
}

export const ParallaxContext = createContext<ParallaxContext>({
  titlePos: 0,
  titleSourceRef: null as any,
  titleTargetRef: null as any,
});

export const useParallaxContext = () => {
  return useContext(ParallaxContext);
};

export const ParallaxContextProvider = ({
  children,
}: PropsWithChildren<any>) => {
  const [titlePos, setTitlePos,] = useState(0);
  const titleSourceRef = useRef<HTMLDivElement | null>(null);
  const titleTargetRef = useRef<HTMLDivElement | null>(null);

  const recalculateTitleDimensions = useCallback(() => {
    if (titleSourceRef.current) {
      const dimensions = titleSourceRef.current.getBoundingClientRect();
      setTitlePos(dimensions.top);
    }
  }, [setTitlePos, titleSourceRef,]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    recalculateTitleDimensions();
    // When window size or titleRef element's size or position changes,
    // we need to recalculate the title position and height
    window.addEventListener('resize', recalculateTitleDimensions);
    return () => {
      window.removeEventListener('resize', recalculateTitleDimensions);
    };
  }, [recalculateTitleDimensions,]);

  const value: ParallaxContext = useMemo(() => ({
    titlePos,
    titleSourceRef,
    titleTargetRef,
  }), [titlePos, titleSourceRef, titleTargetRef,]);

  return (
    <ParallaxContext.Provider value={value}>
      {children}
    </ParallaxContext.Provider>
  );
};
