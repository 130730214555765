import { useEffect, useState } from 'react';

/**
 * A React hook that provides the online/offline status of the browser's network connection.
 *
 * This hook uses the `navigator.onLine` property to get the initial connection status and
 * then listens to 'online' and 'offline' events to update the status accordingly.
 *
 * @returns {boolean} The current network status: `true` if online, `false` if offline.
 */
function useNetworkStatus() {
  // State to hold the online status, initialized with the current browser status. If the
  // function is called in a non-browser environment, the status is assumed to be online.
  const [isOnline, setIsOnline,] = useState(
    typeof navigator !== 'undefined' ? navigator.onLine : true
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const goOnline = () => setIsOnline(true);
    const goOffline = () => setIsOnline(false);

    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  return isOnline;
}

export default useNetworkStatus;
