/**
 * All possible inquiry (contact us message) types
 */
export enum InquiryType {
  OTHER = 'other',
  SUPPORT = 'support',
  SALES = 'sales',
  NONE = '',
}

/**
 * Base for all inquiries
 */
export interface BaseInquiry {
  type: InquiryType;
  name: string;
  email: string;
  message: string;
}

/**
 * Inquiry type OTHER
 */
export interface OtherInquiry extends BaseInquiry {
  type: InquiryType.OTHER;
}

/**
 * Inquiry type SUPPORT
 */
export interface SupportInquiry extends BaseInquiry {
  type: InquiryType.SUPPORT;
  plan: string;
  role: string;
}

/**
 * Inquiry type SALES
 */
export interface SalesInquiry extends BaseInquiry {
  type: InquiryType.SALES;
  companyName: string;
  VAT: string;
  organizationType: string;
  estimatedUsers: string;
  estimatedFacilitators: string;
}

/**
 * Super type that includes all inquiry types.
 * Used in the contact us form.
 * The specific type can be differentiated using the "type" parameter.
 */
export type Inquiry = OtherInquiry | SupportInquiry | SalesInquiry;

export interface InquiryDoc {
  content: Inquiry;
}
