import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

import { useCallback } from 'react';
import CompositePropertyEditorControl from '../CompositePropertyEditorControl';
import PropertyEditorLegend from '../PropertyEditorLegend';
import type { PropertyConfig } from 'components/builder/builders/componentBuilder';
import type { PropertySaveFunction, StatCharacteristicPropertyEditor } from '../PropertyEditorTypes';
import type { StatCharacteristics } from '@shared/schema/src';

export const statCharacteristicConfig: PropertyConfig<StatCharacteristics> = {
  mean: {
    type: 'checker',
    displayName: 'Mean',
    defaultValue: false,
  },
  average: {
    type: 'checker',
    displayName: 'Average',
    defaultValue: false,
  },
  q1: {
    type: 'checker',
    displayName: 'Q1',
    defaultValue: false,
  },
  median: {
    type: 'checker',
    displayName: 'Median',
    defaultValue: false,
  },
  q3: {
    type: 'checker',
    displayName: 'Q3',
    defaultValue: false,
  },
  stdDeviation: {
    type: 'checker',
    displayName: 'Standard Deviation',
    defaultValue: false,
  },
  variance: {
    type: 'checker',
    displayName: 'Variance',
    defaultValue: false,
  },
};

export const StatCharacteristicEditor: PropertyEditorFunction<StatCharacteristics, StatCharacteristicPropertyEditor> = ({
  data,
  component,
  propertyName,
  propertyNamePath,
  propertyConfig: {
    defaultValue,
  },
  onSave,
}) => {
  const handleSave: PropertySaveFunction = useCallback((
    propertyName: string,
    propertyValue: StatCharacteristics
  ) => {
    // Make a copy of propertyValue where are only fields with true value
    const cleaned = Object
      .entries(propertyValue)
      .reduce((acc, [key, value,]) => {
        if (value) {
          (acc as any)[key] = value;
        }
        return acc;
      }, {} as StatCharacteristics);

    // If non of statistical characteristics are selected, save undefined
    let defined = false;
    Object
      .values(cleaned)
      .forEach((value) => defined = defined || value);

    onSave(propertyName, defined ? propertyValue : null);
  }, [onSave,]);

  return <PropertyEditorLegend label="Characteristics">
    <CompositePropertyEditorControl
      component={component}
      propertyName={propertyName}
      propertyNamePath={propertyNamePath}
      data={data ?? defaultValue ?? {}}
      config={statCharacteristicConfig}
      onSave={handleSave}
    />
  </PropertyEditorLegend>;
};

StatCharacteristicEditor.config = {
  type: 'characteristics',
  displayName: 'Characteristics',
};

export default StatCharacteristicEditor;
