import { keyframes } from '@emotion/react';
import Center from './layout/Center';
import React from 'react';
import styled from '@emotion/styled';

const RING_DIAMETER = 50;

const RING_WIDTH = 4;

const FLARE_DIAMETER = 8;

const Fullscreen = styled.div`
  position: absolute;
  width: 100vw;
  height: 100dvh;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const rotate = keyframes`
  0% {
    transform: rotateZ(0deg);
  } 
  100% {
    transform: rotateZ(360deg);
  } 
`;

const Container = styled.div`
  position: relative;
  animation: ${rotate} 2s linear infinite;  
`;

const Reflection = styled.div`
  position: relative;
  mask: linear-gradient(to bottom, transparent, rgba(0,0,0,0), rgba(255,255,255,0.3));
  transform: scaleY(-1);
  margin-top: -16px;
  padding: 32px;
`;

const Flare = styled.div`
  position: absolute;
  width: ${FLARE_DIAMETER}px;
  height: ${FLARE_DIAMETER}px;
  z-index: 2;
  border-radius: 50%;
  background: rgba(250,250,255);
  shape-outside: circle(50%);
  bottom: ${-(FLARE_DIAMETER - RING_WIDTH) / 2}px;
  left: ${(RING_DIAMETER - FLARE_DIAMETER) / 2}px;
  box-shadow: 0 0 10px rgba(250,250,255),
  0 0 20px rgba(250,250,255),
  0 0 30px rgba(250,250,255),
  0 0 40px rgba(250,250,255),
  0 0 50px rgba(250,250,255),
  0 0 60px rgba(250,250,255),
  0 0 70px rgba(250,250,255);
`;

const Circle = styled.div`
  position: relative; 
  border-radius: 50%; 
  border: 5px solid transparent;
  background: linear-gradient(180deg,rgb(16,32,32, 0), rgb(160,230,255,0.6)) border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;  
  width: ${RING_DIAMETER}px;
  height: ${RING_DIAMETER}px;
`;

const LoadingTitle = styled.h2`
  margin-top: -50px;
`;

const Indicator = ({
  loaderTitle,
  noTitle,
}: any) =>
  <Center>
    <Container>
      <Circle />
      <Flare />
    </Container>
    <Reflection>
      <Container>
        <Circle />
        <Flare />
      </Container>
    </Reflection>
    {
      noTitle ?
        null :
        <LoadingTitle>{loaderTitle}</LoadingTitle>
    }
  </Center>;

const TinyIndicator = () =>
  <div style={{
    transform: 'scale(0.75)',
  }}>
    <Container>
      <Circle />
      <Flare />
    </Container>
  </div>;

interface LoadingIndicatorProps {
  fullScreen?: boolean;
  loaderTitle?: string;
  tiny?: boolean;
  noTitle?: boolean;
}

const LoadingIndicator = ({
  fullScreen,
  noTitle,
  loaderTitle = noTitle ? undefined : 'Loading...',
  tiny,
}: LoadingIndicatorProps
) =>
  fullScreen ?
    <Fullscreen>
      <Indicator loaderTitle={loaderTitle} />
    </Fullscreen> :

    tiny ?
      <TinyIndicator /> :
      <Center>
        <Indicator noTitle={noTitle}
          loaderTitle={loaderTitle} />
      </Center>;

export default LoadingIndicator;
