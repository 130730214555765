/* eslint-disable @next/next/no-img-element */
/* eslint-disable sonarjs/no-duplicate-string */
import { FieldSet } from 'components/forms/FieldSet';
import { Legend } from '../Legend';
import { MediaContentDialogDialog } from './MediaContentDialog';
import { TwitterPicker } from 'react-color';
import { capitalizeFirstLetter } from 'utils/common';
import { extractMediaAuthorInfoFromUnsplash } from './MediaCopyright/UnsplashCopyright';
import { randomUnsplashPhoto } from 'bloc/media/MediaBloc';
import { setObjectField } from '@mindhiveoy/foundation';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormElement from '../FormElement';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import usePathParams from 'utils/hooks/usePathParams';
import type { FocusEventHandler } from 'react';
import type { Media, MediaType } from '@shared/schema/src/common';

type Form = Pick<Media, 'photoURL' | 'videoURL'>;

const TwitterPickerHack = TwitterPicker as any;

const colorSet = [
  '#F78DA7', '#EB144C', '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC',
  '#0693E3', '#9900EF', '#8B572A', '#111111', '#666666', '#ABB8C3', '#EEEEEE',
];

export interface MediaFormFieldProps {
  media?: Media;
  title?: string;
  onChange?: (media: Media) => void;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
}

const MediaFormField = ({
  media,
  title,
  onChange,
}: MediaFormFieldProps): JSX.Element => {
  const {
    t,
  } = useTranslation();

  const theme = useTheme();
  // const [fetching, setFetching,] = useState<boolean>(false);
  const [mediaType, setMediaType,] = useState<MediaType>(media?.type ?? 'no background');

  const [showUnsplashDialog, setShowUnsplashDialog,] = useState<boolean>(false);

  const {
    spaceId,
    projectId,
    sessionId,
    canvasId,
  } = usePathParams();

  // TODO: Tidy up this code
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  const directoryPath = useMemo(() => canvasId ? `${spaceId}/${projectId}/${sessionId}/${canvasId}/elementBackground` :
    sessionId ? `${spaceId}/${projectId}/${sessionId}/elementBackground` :
      projectId ? `${spaceId}/${projectId}/elementBackground` :
        `${spaceId}/elementBackground`, [canvasId, projectId, sessionId, spaceId,]);

  const {
    photoURL,
    videoURL,
  } = media ?? {};

  const handleChangeMediaType = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const type = (event.target as HTMLInputElement).value as any;
    setMediaType(type);
    if (type !== 'no background') {
      onChange && onChange({
        ...media,
        type,
      });
    } else {
      onChange && onChange({
        ...media,
        type: 'no background',
        color: ' ',
        photoURL: ' ',
      });
    }
  }, [media, onChange,]);

  const handleSetBackgroundColor = useCallback((color: any) => {
    onChange && onChange({
      ...media,
      type: 'color',
      color: color.hex,
    });
  }, [media, onChange,]);

  const handleRandomImageClick = useCallback(async () => {
    try {
      const photo = await randomUnsplashPhoto({
        spaceId,
        projectId,
      });

      const authorInfo = extractMediaAuthorInfoFromUnsplash(photo);
      const newMedia: Media = {
        ...media,
        ...authorInfo,
        photoURL: photo.urls.regular,
        type: 'photo',
      };
      delete newMedia.color;

      onChange && onChange(newMedia);
    } catch (error) {
      // setFetching(false);
      console.error(error);
    }
  }, [media, onChange, projectId, spaceId,]);

  const handleValueChange = useCallback((event: any) => {
    const value = event.target.value.trim();
    const key = event.currentTarget.name ?? event.currentTarget.id;
    if (!key) {
      throw new Error(t('you-must-defined-the-form-field-key-as-an-id-or-name-attribute-when-you-do-not-explicitly-define-the-field-for-handlechangeevent'));
    }

    const newMedia = setObjectField<Media>({
      ...media,
    }, key, value);

    // TODO: Detect common sources for authority
    if (key === 'photoURL' || key === 'videoURL') {
      delete newMedia.author;
      delete newMedia.authorUrl;
      delete newMedia.source;
      delete newMedia.sourceUrl;
    }
    onChange?.(newMedia as any);
  }, [media, onChange, t,]);

  const handleCheckerChange = useCallback((event: any) => {
    const value = event.target.checked;
    const key = event.currentTarget.name ?? event.currentTarget.id;
    if (!key) {
      throw new Error(t('you-must-defined-the-form-field-key-as-an-id-or-name-attribute-when-you-do-not-explicitly-define-the-field-for-handlechangeevent'));
    }

    const newMedia = setObjectField<Media>({
      ...media,
    }, key, value);

    onChange?.(newMedia as any);
  }, [media, onChange, t,]);

  useEffect(() => {
    setMediaType(media?.type ?? 'no background');
  }, [media,]);

  const handleMediaSelect = useCallback(async (item: Partial<Media>) => {
    const newMedia = {
      ...media,
      ...item,
    } as Media;

    onChange && onChange(newMedia);
    setShowUnsplashDialog(false);
  }, [media, onChange,]);

  const handleUnsplashDialogClose = useCallback(() => {
    setShowUnsplashDialog(false);
  }, []);

  const handleSearchUnsplashClick = useCallback(() => {
    setShowUnsplashDialog(true);
  }, []);

  return <>
    <FieldSet>
      <Legend>
        {capitalizeFirstLetter(title)}
      </Legend>
      <RadioGroup
        aria-label={t('media-type')}
        name="mediaType"
        value={mediaType}
        onChange={handleChangeMediaType}
      >
        <FormControlLabel value="photo"
          control={<Radio />}
          label={t('image')} />
        <FormControlLabel value="video"
          control={<Radio />}
          label={t('video')} />
        <FormControlLabel value="color"
          control={<Radio />}
          label={t('color')} />
        {
          mediaType === 'color' &&
          <TwitterPickerHack colors={colorSet}
            onChangeComplete={handleSetBackgroundColor} />
        }
        <FormControlLabel value="no background"
          control={<Radio />}
          label={t('No background')} />
      </RadioGroup>
    </FieldSet>

    {
      (mediaType === 'photo' || mediaType === 'video') &&
      <>
        {mediaType === 'photo' ?
          <FormElement<Form> field="photoURL">
            <TextField
              label={t('photo-url')}
              placeholder={t('photo-url')}
              value={photoURL}
              id="photoURL"
              name="photoURL"
              onChange={handleValueChange}
              onBlur={handleValueChange}
            />
          </FormElement> : null}

        <FieldSet>
          <Legend>{t('Media gallery')}</Legend>
          <Box marginTop={1}>
            <FormElement withBottomMargin>
              <Button
                onClick={handleSearchUnsplashClick}
                variant="contained">{t('Search media content')}...</Button>
            </FormElement>
            {mediaType === 'photo' ? <FormElement >
              <Button
                variant="outlined"
                onClick={handleRandomImageClick}>{t('random-image')}
              </Button>
            </FormElement> : null}
          </Box>
        </FieldSet>
        <MediaContentDialogDialog
          open={showUnsplashDialog}
          onSelect={handleMediaSelect}
          onCancel={handleUnsplashDialogClose}
        />
      </>}
    {
      mediaType === 'video' ?
        <>
          <FormElement<Form> field="videoURL">
            <TextField
              placeholder={t('video-url')}
              label={t('video-url')}
              value={videoURL}
              id="videoURL"
              name="videoURL"
              onChange={handleValueChange}
              onBlur={handleValueChange}
            />
          </FormElement>
          <FieldSet>
            <Legend>{t('Video settings')}</Legend>
            <FormElement field="videoConfig.loop" >
              <FormControlLabel
                control={<Checkbox
                  name='videoConfig.loop'
                  checked={!!media?.videoConfig?.loop}
                  onChange={handleCheckerChange}
                  style={{
                    marginRight: theme.spacing(1),
                  }}
                />}
                label={t('Loop')} />
            </FormElement>
            <FormElement field="videoConfig.muted">
              <FormControlLabel
                control={<Checkbox
                  name='videoConfig.muted'
                  checked={!!media?.videoConfig?.muted}
                  onChange={handleCheckerChange}
                  style={{
                    marginRight: theme.spacing(1),
                  }}
                />}
                label={t('Mute')} />
            </FormElement>
            {/* <FormElement field="videoConfig.autoPlay">
              <FormControlLabel
                control={<Checkbox
                  name='videoConfig.autoPlay'
                  checked={!!media?.videoConfig?.autoPlay}
                  onChange={handleCheckerChange}
                  style={{
                    marginRight: theme.spacing(1),
                  }}
                />}
                label={t('AutoPlay')} />
            </FormElement> */}
          </FieldSet>
        </> : null}
  </>;
};

export default MediaFormField;
