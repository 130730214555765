import { removeStorageValue } from '../../../../utils/firebase/localStorageUtils';

export const cleanOutLocalStorage = (status: string) => {
  switch (status) {
    case 'signed-in':
    case 'anonymous':
      removeStorageValue('authState');
      break;
    default:
      break;
  }
};
