/* eslint-disable require-jsdoc */
/* eslint-disable @typescript-eslint/no-explicit-any */
import PropTypes from 'prop-types';
import React from 'react';
import autoSizeInput from 'autosize-input';
import styled from '@emotion/styled';
import type { CSSProperties } from 'react';

const {
  Component,
} = React;

const commonPropTypes = {
  finishEdit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]),
  value: PropTypes.string,
};

const commonDefaultProps = {};

const moveCursorToEnd = (el: HTMLInputElement | HTMLSpanElement | null) => {
  if (el && el.tagName.toLowerCase().match(/input|textarea/)) {
    el.focus();
    if ((el as HTMLInputElement).setSelectionRange) {
      const input = el as HTMLInputElement;
      const len = input.value.length * 2;
      input.setSelectionRange(len, len);
    } else {
      (el as any).value = (el as any).value;
    }
    el.scrollTop = 999999;
  } else if (document.createRange) {
    const range = document.createRange();
    range.selectNodeContents(el as any);
    range.collapse(false);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
};

interface EditorProps {
  name: string;
  value: string;
  style?: CSSProperties | undefined;
  finishEdit: (value: string) => void;
}

interface SingleLineState {
  value: string;
}

class SingleLine extends Component<EditorProps, SingleLineState> {
  private _delayedFocus: any;

  private content: HTMLInputElement | null = null;

  /**
   *
   * @param {EditorProps} props
   */
  constructor(props: EditorProps) {
    super(props);
    const {
      value = '',
    } = props;
    this.state = {
      value,
    };
    this.onBlur = this.onBlur.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.autoSize();
  }

  componentDidUpdate() {
    this.autoSize();
  }

  componentWillUnmount() {
    if (this._delayedFocus) {
      window.clearTimeout(this._delayedFocus);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({
    value,
  }: EditorProps) {
    if (this.state.value !== value) {
      this.setState({
        value,
      });
    }
  }

  autoSize() {
    try {
      autoSizeInput(this.content);
    } catch (error) {
      console.error(error);
    }
  }

  focus() {
    this._delayedFocus = window.setTimeout(() => {
      moveCursorToEnd(this.content);
      this.content?.focus();
    }, 110);
  }

  blur = () => {
    this.content?.blur();
  };

  onKeyUp = (event: React.KeyboardEvent) => {
    if (event.keyCode === 27 || event.keyCode === 13) {
      this.blur();
    }
  };

  onBlur = () => {
    this.props.finishEdit(this.state.value);
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      value: event.target.value,
    });
  };

  private handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    // TODO Make this be based on prop
    event.target.setSelectionRange(0, event.target.value.length);
  };

  render() {
    const {
      // value needs to be pulled out of the props
      // eslint-disable-next-line unused-imports/no-unused-vars
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      name, style,
      // value: _value,
      ...rest
    } = this.props;
    return (
      <input
        {...rest}
        type='text'
        ref={((ref: any) => this.content = ref) as any} // TODO Study the correctness of this one
        name={name}
        autoComplete='off'
        value={this.state.value}
        style={style}
        onFocus={this.handleFocus}
        onBlur={this.onBlur}
        onKeyUp={this.onKeyUp}
        onChange={this.onChange}
      />
    );
  }
}

Object.assign(SingleLine, {
  propTypes: commonPropTypes,
  defaultProps: commonDefaultProps,
});

class Multiline extends Component<EditorProps> {
  private content: HTMLSpanElement | null = null;

  private _wasClicked = false;

  focus = () => {
    this._wasClicked = true;
    this.content?.focus();
  };

  blur = () => {
    this.content?.blur();
  };

  selectAll = () => {
    if (document && typeof document.execCommand === 'function') {
      // Mimic input behavior when navigating to element with TAB key.
      setTimeout(() => {
        if (!this._wasClicked) {
          document.execCommand('selectAll', false);
        }
      }, 50);
    }
  };

  onFocus = () => {
    this.selectAll();
    moveCursorToEnd(this.content);
  };

  onClick = () => {
    this._wasClicked = true;
    this.focus();
  };

  onKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 27 || e.keyCode === 13) {
      e.preventDefault();
      this.blur();
    }
  };

  onBlur = (e: any) => {
    this._wasClicked = false;
    this.props.finishEdit(e.target.innerText);
  };

  ensureEmptyContent() {
    if (!this.props.value && this.content) {
      this.content.innerHTML = '';
    }
  }

  render() {
    const {
      // eslint-disable-next-line unused-imports/no-unused-vars
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      value, style, finishEdit: _finishEdit, ...rest
    } = this.props;
    return (
      <span
        ref={(el: any) => this.content = el}
        contentEditable='true'
        style={style}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onClick={this.onClick}
        onKeyDown={this.onKeyDown}
        dangerouslySetInnerHTML={{
          __html: value ?? null,
        }}
        {...rest}
      >
      </span>
    );
  }
}

const InputField = styled.div`
  z-index: 10;
`;

const hoverStyle: CSSProperties = {
  borderRadius: 6,
  borderWidth: 0.5,
  borderStyle: 'dashed',
  borderColor: 'lightgray',
};

const defaultStyle = {
  'minWidth': 100,
  'backgroundColor': 'transparent',
  'justifyContent': 'center',
  'minHeight': 32,
  'borderRadius': 6,
  'borderWidth': 0.5,
  'borderStyle': 'dashed',
  'borderColor': '#444',
  'textAlign': 'center',
  '&:hover': hoverStyle,
  'flexGrow': 1,
} as CSSProperties;

export type EditDoneFunction = (name: string, newValue: string) => void;

export interface ReactPencilProps {
  error?: string;
  multiline?: boolean;
  name: string,
  style?: CSSProperties;
  value?: string;
  textAlign?: 'left' | 'center' | 'right';
  pencil?: boolean;
  orientation?: 'default' | 'vertical-west' | 'vertical-east';
  onEditDone: EditDoneFunction,
}

class InplaceEditor extends Component<ReactPencilProps> {
  private editable: SingleLine | Multiline | null = null;

  focus = () => {
    this.editable?.focus();
  };

  finishEdit = (newValue = '') => {
    const {
      value, name, multiline,
    } = this.props;
    newValue = newValue.trim();

    if (newValue !== value) {
      this.props.onEditDone(name, newValue);
    }
    if (multiline) {
      (this.editable as Multiline)?.ensureEmptyContent();
    }
  };

  renderPencilButton = () => {
    return (
      <button className='pencil-button'
        onClick={() => this.focus()}>
        <i className='pencil-icon'></i>
      </button>
    );
  };

  renderError = (error: string) => {
    return <div className='error-msg'>{error}</div>;
  };

  render() {
    const {
      multiline,
      pencil,
      error,
      orientation,
      textAlign = 'center',
      style = defaultStyle,
      ...rest
    } = this.props;
    const Component = multiline ? Multiline : SingleLine;

    // TODO @emotion
    switch (orientation) {
      case 'vertical-east':

        return <>
          <InputField style={{
            'transform': 'scale(-1)',
            'writingMode': 'vertical-rl',
            'justifyContent': 'center',
            'minWidth': 32,
            'maxHeight': 120,
            'minHeight': 60,
            '&:hover': hoverStyle,
            textAlign,
            'flexGrow': 1,

          } as any}>
            <Component ref={(el) => this.editable = el}
              {...(rest as any)} // TODO add types
              finishEdit={this.finishEdit} />
            {pencil ? this.renderPencilButton() : null}
          </InputField>
          {error ? this.renderError(error) : null}
        </>;

      case 'vertical-west':

        return <>
          <InputField style={{
            // 'transform': 'scale(-1)',
            'writingMode': 'vertical-rl',
            'justifyContent': 'center',
            'minWidth': 32,
            'maxHeight': 120,
            'minHeight': 60,
            '&:hover': hoverStyle,
            textAlign,
            'flexGrow': 1,

          } as any}>
            <Component ref={(el) => this.editable = el}
              {...(rest as any)}
              finishEdit={this.finishEdit} />
            {pencil ? this.renderPencilButton() : null}
          </InputField>
          {error ? this.renderError(error) : null}
        </>;

      default:
        return (
          <>
            <InputField style={{
              ...style,
            }}>
              <Component ref={(el) => this.editable = el}
                {...(rest as any)}
                finishEdit={this.finishEdit} />
              {pencil ? this.renderPencilButton() : null}
            </InputField>
            {error ? this.renderError(error) : null}
          </>
        );
    }
  }
}

export default InplaceEditor;
