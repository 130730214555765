/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import type { CommentViewContextType } from 'components/session/SessionEditor/RestrictedSessionEditor/SessionEditor/modes/facilitate/model/CommentViewModel';

const dummyFun: any = () => { };

export const commentViewContextDummy: CommentViewContextType = {
  addSorter: dummyFun,
  addFilter: dummyFun,
  removeFilter: dummyFun,
  updateFilter: dummyFun,
  subscribe: dummyFun,
  subscribeForFilters: dummyFun,
  commentModel: null,
  clearFilters: dummyFun,
} as unknown as CommentViewContextType;

export const CommentViewContext = React.createContext<CommentViewContextType>({} as any);

/**
 * CommentViewContext will introduce the comment view context to the component tree.
 * The key features are:
 *
 * * Filter and sort comments based on rules defined.
 * * Subscribe to changes in the comment view.
 * * Subscribe to changes in the filters.
 *
 * @returns       The comment view context
 */
export const useCommentViewContext = () => {
  const context = useContext(CommentViewContext);
  return context ?? commentViewContextDummy;
};
