import type { Axis, Axis2D } from '@shared/schema/src';
import type { Size } from '.';

export const roundDragX = (size: Size, axis: Axis2D, value: number): number => {
  return Math.round(value / (size.width / axis.x.range.length));
};
export const roundDragY = (size: Size, axis: Axis2D, value: number): number => {
  return Math.round(value / (size.height / axis.y.range.length));
};

export const floorDragX = (size: Size, axis: Axis2D, value: number): number => {
  return Math.floor(value / (size.width / axis.x.range.length));
};

export const floorToRange = (width: number, rangeLength: number, value: number) => {
  return Math.floor(value / (width / rangeLength));
};

export const floorDragY = (size: Size, axis: Axis2D, value: number): number => {
  return Math.floor(value / (size.height / axis.y.range.length));
};

export const limitToBounds = (value: number, axis: Axis): number => {
  // TODO exception cases with empty range etc.
  const range = axis.range;

  if (value < 0) {
    return 0;
  }
  if (value >= range.length) {
    return range.length - 1;
  }
  return value;
};
