
import { $generateHtmlFromNodes } from '@lexical/html';
import { $generateNodesFromDOM } from '@lexical/html';
import { $insertNodes } from 'lexical';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { EditorBlurPlugin } from './plugins/EditorBlurPlugin';
import { EditorPastePlugin } from './plugins/EditorPastePlugin';
import { EditorRefPlugin } from '@mindhiveoy/react-text-editor/plugins/EditorRefPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { KeyCommandPlugin } from './plugins/KeyCommandPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { defaultInitialEditorConfig } from '@mindhiveoy/react-text-editor';
import { extractAllowedTags } from './extractAllowedTags';
import Center from 'components/common/layout/Center';
import FocusPlugin from '@mindhiveoy/react-text-editor/plugins/FocusPlugin';
import InplaceToolbar from './InplaceToolbar';
import LexicalAutoLinkPlugin from '@mindhiveoy/react-text-editor/plugins/LinkPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import SetDataPlugin from '@mindhiveoy/react-text-editor/plugins/SetDataPlugin';
import sanitizeHtml from 'sanitize-html';
import styled$ from 'utils/react/styled$';
import type { CSSProperties } from 'react';
import type { EditorState, EditorThemeClasses, LexicalEditor } from 'lexical';

export const FieldSet = styled$.fieldset<{
  $dark?: boolean;
}>(({
  $dark,
  theme,
}) => `
  border-radius: ${theme.shape.borderRadius / 2}px;
  border: 1.5px dashed ${$dark ? 'rgba(0,0,0,0.7)' : theme.palette.grey[500]};
  padding: 0 ${theme.spacing(0.5)};
  margin: 0;
  max-width: 100%;
  position: relative;
`);

export const Legend = styled$.legend<{
  $dark?: boolean;
}>(({
  theme,
  $dark,
}) => `
  margin-left: 6px;
  padding: 0 4px;
  font-size: 0.75rem;
  font-face: ${theme.typography.fontFamily};
  font-weight: 500;
  line-height: 1;
  overflow: hidden;
  color: ${$dark ? 'rgba(0,0,0,0.7)' : theme.palette.grey[500]};
`);

const theme: EditorThemeClasses = {
  paragraph: 'inplace-paragraph',

  heading: {
    h1: 'inplace-heading-h1',
    h2: 'inplace-heading-h2',
    h3: 'inplace-heading-h3',
  },
  list: {
    nested: {
      listitem: 'inplace-nested-listitem',
    },
    ol: 'inplace-list-ol',
    ul: 'inplace-list-ul',
    listitem: 'inplace-listItem',
    listitemChecked: 'inplace-listItemChecked',
    listitemUnchecked: 'inplace-listItemUnchecked',
  },
};

const initialConfig = {
  ...defaultInitialEditorConfig,
  editable: true,
  theme,
};

const extractHtml = (editor: LexicalEditor) => {
  if (!editor) {
    return;
  }
  try {
    return $generateHtmlFromNodes(editor, null);
  } catch (e) {
    console.error('error', e);
    return '';
  }
};

const cleanHTML = (html: string, allowedTags: string[]): string => {
  return sanitizeHtml(html, {
    allowedTags,
    allowedAttributes: {
      a: ['href',],
    },
  });
};

export type AllowedStyle = 'bold' |
  'italic' |
  'underline' |
  'link' |
  'paragraph' |
  'line-break' |
  'list' |
  'ordered-list' |
  'header-1' |
  'header-2' |
  'header-3';

interface LexicalInplaceEditorProps {
  allowedStyles?: AllowedStyle[];
  autoFocus?: boolean;
  border?: 'none' | 'dashed' | 'solid';
  centerToolbar?: boolean;
  containerStyle?: CSSProperties;
  dark?: boolean;
  fixedToolbar?: boolean;
  forceEdit?: boolean;
  initialHtml?: string;
  placeHolder?: string | JSX.Element;
  saveOnEnterPress?: boolean;
  showToolbar?: boolean;
  style?: CSSProperties;
  writingMode?: 'horizontal' | 'vertical-rl' | 'vertical-lr';
  onBlur?: () => void;
  onHtmlChange?: (html: string | undefined) => void;
}

const LexicalInplaceEditor = ({
  autoFocus,
  allowedStyles = [],
  dark,
  fixedToolbar,
  initialHtml,
  placeHolder,
  saveOnEnterPress,
  showToolbar,
  centerToolbar,
  style,
  border = 'dashed',
  containerStyle,
  writingMode = 'horizontal',
  forceEdit,
  onBlur,
  onHtmlChange,
}: LexicalInplaceEditorProps) => {
  const value = useRef<EditorState>();

  const [edit, setEdit,] = useState<boolean>(value.current !== null || !!forceEdit);
  const [html, setHtml,] = useState<string | undefined>(initialHtml);

  const loading = useRef<boolean>(true);

  const allowedTags = useMemo(() => extractAllowedTags(allowedStyles), [allowedStyles,]);
  const [showToolbarState, setShowToolbarState,] = useState<boolean>(false);

  const [focused, setFocused,] = useState<boolean>(false);

  const editorRef = useRef<LexicalEditor | null>(null);
  const toolbarTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onChange = useCallback((newValue: EditorState, editor: LexicalEditor) => {
    value.current = newValue;

    if (onHtmlChange && !loading.current) {
      newValue.read(() => {
        let html = extractHtml(editor);
        html = html ? cleanHTML(html, allowedTags) : undefined;
        if (html === initialHtml) {
          return;
        }
        onHtmlChange(html);
      });
    }
  }, [allowedTags, initialHtml, onHtmlChange,]);

  useEffect(() => {
    if (initialHtml !== undefined && !focused) {
      let initial = initialHtml;
      if (allowedStyles?.includes('paragraph')) {
        // To support old text editors that used to store text without <p> tags when
        // paragraph style was allowed.
        initial = initial.trim();
        if (!initial.startsWith('<p')) {
          initial = `<p>${initial}</p>`;
        }
      }
      setHtml((html) => {
        if (html === initial) {
          return html;
        }
        return initial;
      });
    }
  }, [initialHtml, edit, allowedStyles, focused,]);

  const handleFocused = useCallback((isFocused: boolean, editor: LexicalEditor) => {
    setFocused(isFocused);
    if (isFocused) {
      setEdit(true);
      setShowToolbarState(true);
    } else {
      // Delay hiding the toolbar
      toolbarTimeoutRef.current = setTimeout(() => {
        setShowToolbarState(false);
        setEdit(false);
        if (onBlur) {
          onBlur();
        }
      }, 300); // 300ms delay, adjust as needed
    }
  }, [onBlur,]);

  const handleStopEditing = useCallback((editor: LexicalEditor) => {
    setEdit(false);

    if (onHtmlChange && value.current) {
      value.current.read(() => {
        let html = extractHtml(editor);
        html = html ? cleanHTML(html, allowedTags) : undefined;
        onHtmlChange(html);
      });
    }

    let a: string | undefined = extractHtml(editor)?.trim();
    a = a ? cleanHTML(a, allowedTags) : undefined;

    if (a === '' || a === '<p></p>' || a === '<p><br/></p>') {
      a = undefined;
    }
    setHtml(a);
  }, [allowedTags, onHtmlChange,]);

  const handlePaste = useCallback((html: string, editor: LexicalEditor) => {
    // In the browser you can use the native DOMParser API to parse the HTML string.
    try {
      const cleaned = cleanHTML(html, allowedTags);
      const parser = new DOMParser();
      const dom = parser.parseFromString(cleaned, 'text/html');

      // Once you have the DOM instance it's easy to generate LexicalNodes.
      const nodes = $generateNodesFromDOM(editor, dom);

      // Insert them at a selection.
      $insertNodes(nodes);
    } catch (e) {
      console.error('error', e);
    }
  }, [allowedTags,]);

  const handleEditClick = useCallback(() => {
    setEdit(true);
  }, []);

  const editStyle = useMemo(() => {
    const result: CSSProperties = {
      ...style,
      outline: 'none',
      padding: 0,
      margin: 0,
      position: 'relative',
      // backgroundColor: 'green',
    };
    if (writingMode !== 'horizontal') {
      result.writingMode = writingMode;
    }
    return result;
  }, [style, writingMode,]);

  const cStyle: CSSProperties = useMemo(() => ({
    ...containerStyle,
    width: containerStyle?.width ?? 'fit-content',
    minWidth: containerStyle?.minWidth ?? '3rem',
  }), [containerStyle,]);

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    const onFocus = () => {
      handleFocused(true, editorRef.current as LexicalEditor);
    };

    const _onBlur = () => {
      setFocused(false);
      handleStopEditing(editorRef.current as LexicalEditor);
      onBlur?.();
    };

    const element = editorRef.current?.getRootElement();

    element?.addEventListener('focus', onFocus);
    element?.addEventListener('blur', _onBlur);

    return () => {
      element?.removeEventListener('focus', onFocus);
      element?.removeEventListener('blur', _onBlur);
    };
  }, [handleFocused, handleStopEditing, onBlur,]);

  useEffect(() => {
    loading.current = false;

    return () => {
      if (toolbarTimeoutRef.current) {
        clearTimeout(toolbarTimeoutRef.current);
      }
    };
  }, []);

  const placeholderStyle = useMemo(() => ({
    ...style,
    minWidth: 'min(200px, 90%)',
  }), [style,]);

  return (
    edit || forceEdit ?
      <LexicalComposer initialConfig={initialConfig}>
        <RichTextPlugin
          contentEditable={
            <Center>
              {border !== 'none' ?
                <FieldSet style={cStyle}
                  $dark={dark}>
                  {placeHolder ? <Legend $dark={dark}>{placeHolder}</Legend> : null}
                  <ContentEditable className="inplace-editor"
                    style={editStyle} />
                  <InplaceToolbar
                    allowedStyles={allowedStyles}
                    centerToolbar={centerToolbar}
                    fixedToolbar={fixedToolbar}
                    showToolbar={showToolbar}
                    showToolbarState={showToolbarState}
                  />
                </FieldSet> :
                <>
                  <ContentEditable className="inplace-editor"
                    style={editStyle} />
                  <InplaceToolbar
                    allowedStyles={allowedStyles}
                    centerToolbar={centerToolbar}
                    fixedToolbar={fixedToolbar}
                    showToolbar={showToolbar}
                    showToolbarState={showToolbarState}
                  />
                </>
              }
            </Center>
          }
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <SetDataPlugin
          allowedStyles={allowedStyles}
          htmlString={html}
        />
        <OnChangePlugin onChange={onChange} />
        <KeyCommandPlugin
          saveOnEnterPress={saveOnEnterPress}
          onStopEditing={handleStopEditing}
        />
        <FocusPlugin onFocusChange={handleFocused} />
        {autoFocus ? <AutoFocusPlugin /> : ''}
        <HistoryPlugin />
        <EditorBlurPlugin onBlur={handleStopEditing} />
        <EditorPastePlugin
          allowedStyles={allowedStyles}
          onPaste={handlePaste} />
        <LexicalAutoLinkPlugin />
        <EditorRefPlugin editorRef={editorRef} />
      </LexicalComposer> :

      html ? <div
        style={style}
        onClick={handleEditClick}
        dangerouslySetInnerHTML={{
          __html: html ?? '',
        }}
      /> :
        <div
          style={placeholderStyle}
          onClick={handleEditClick}>
          {placeHolder}
        </div>
  );
};

export default LexicalInplaceEditor;
