import { useAuth } from '@mindhiveoy/react-auth';
import { useConstant } from './useConstant';
import type { BlocErrorFunction } from '@mindhiveoy/bloc/types';

const useFirestoreErrorHandler = () => {
  const {
    acquireAuthentication,
  } = useAuth();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useConstant<BlocErrorFunction>(() => (error: any) => {
    if (error.code === 'permission-denied') {
      acquireAuthentication(true);
      return;
    }
    // TODO: Sentry
    console.error(error);
  });
};

export default useFirestoreErrorHandler;
