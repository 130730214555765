import { platformEventSystem } from './PlatformEventSystem';
import React, { useContext } from 'react';
import type { PlatformEventContextType } from './PlatformEventSystem';

/**
 * The platform event context for the whole application's component tree. Use this context to listen and
 * broadcast events that are available in the whole application location independently.
 */
export const PlatformEventContext = React.createContext<PlatformEventContextType>(platformEventSystem);

/**
 * A hook to get the access to platform event system to listen and send global
 * messages.
 *
 * Platform event system is a global event system that allows to broadcast and
 * listen for events in the whole application location independently. This is
 * useful to decouple components that are not directly related to each other and
 * are not under in the same component branch.
 *
 * @return {PlatformEventContextType} The context value for PlatformEventContext
 */
const usePlatformEventContext = () => {
  return useContext(PlatformEventContext);
};

export default usePlatformEventContext;
