/* eslint-disable @next/next/no-img-element */
import { COLOR_FACEBOOK, COLOR_GOOGLE, COLOR_LINKEDIN } from 'theme';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MaterialButton from '@mui/material/Button';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import type { ButtonProps } from '@mui/material/Button';
import type { CSSProperties, MouseEventHandler, PropsWithChildren } from 'react';

const getSignUpButtonColor = (signUpMethod: string): '#2D6798' | '#4285F4' | '#1877F2' | '#ffffff' => {
  switch (signUpMethod) {
    case 'linkedin':
      return COLOR_LINKEDIN;
    case 'google':
      return COLOR_GOOGLE;
    case 'facebook':
      return COLOR_FACEBOOK;
    default:
      return '#ffffff';
  }
};

const SignUpButtonIconContainer = styled.div<{ signUpMethod: string; }>(({
  signUpMethod,
}) => `
  width: 48px;
  height: calc(100% - 4px);
  background-color: ${signUpMethod === 'google' ? '#ffffff' : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
`);

const getSignUpButtonIcon = (signUpMethod: string): JSX.Element => {
  switch (signUpMethod) {
    case 'linkedin':
      return <SignUpButtonIconContainer signUpMethod={signUpMethod}>
        <LinkedInIcon fontSize='large' />
      </SignUpButtonIconContainer>;
    case 'google':
      return <SignUpButtonIconContainer signUpMethod={signUpMethod}>
        <img src="/images/logos/google_icon.svg"
          alt="Sign up with Google"
        />
      </SignUpButtonIconContainer>;
    case 'facebook':
      return <SignUpButtonIconContainer signUpMethod={signUpMethod}>
        <FacebookIcon fontSize='large' />
      </SignUpButtonIconContainer>;
    default:
      return <></>;
  }
};

const StyledButton = styled(MaterialButton)(({
  theme,
}) => `
  && {
    font-family: 'Poppins','Nunito', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    text-transform: none;
    color: #fff;
    height: 48px;
    border-radius: ${theme.shape.borderRadius}px;
    overflow: hidden;
  };
  & .MuiButton-startIcon {
    position: absolute;
    left: 6px;
    top: 2px;
    height: 100%;
  };
`);

export interface SignUpButtonProps extends Pick<
  ButtonProps,
  'disabled' |
  'size' |
  'tabIndex' |
  'style' |
  'variant' |
  'onClick'
> {
  /**
   * If `true`, the button will be disabled.
   */
  // disabled?: boolean;
  /**
   * Element placed after the children.
   */
  endIcon?: React.ReactNode;
  /**
   * If `true`, the button will take up the full width of its container.
   */
  fullWidth?: boolean;
  /**
   * The URL to link to when the button is clicked.
   * If defined, an `a` element will be used as the root node.
   */
  href?: string;
  /**
   * The size of the button.
   * `small` is equivalent to the dense button styling.
   */
  // size?: 'small' | 'medium' | 'large';
  /**
   * Element placed before the children.
   */
  startIcon?: React.ReactNode;
  /**
   * The variant to use.
   */
  // variant?: 'text' | 'outlined' | 'contained';

  signUpMethod: 'linkedin' | 'google' | 'facebook';

  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

/**
 * Primary UI component for user interaction
 * @return {ReturnType<Button>} components
 */
const SignUpButton = ({
  children,
  signUpMethod,
  disabled,
  style,
  ...props
}: PropsWithChildren<SignUpButtonProps>) => {
  const backgroundColor = getSignUpButtonColor(signUpMethod);

  const _style: CSSProperties = useMemo(() => ({
    ...style,
    backgroundColor: disabled ? '#e0e0e0' : backgroundColor,
  }), [style, backgroundColor, disabled,]);

  return <StyledButton
    {...props}
    disabled={disabled}
    style={_style}
    startIcon={getSignUpButtonIcon(signUpMethod)}
  >
    {children}
  </StyledButton>;
};

export default SignUpButton;
