import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { useCallback, useEffect, useState } from 'react';

import { useCanvases } from '../../../../../bloc/canvas/CanvasesBloc';
import { useRouter } from 'next/router';
import type { SessionParams } from '@shared/schema/src/pathParams';

import PropertyEditorControl from '../../PropertyEditorControl';
import Select from '@mui/material/Select';
import type { Canvas } from '@shared/schema/src';
import type { CanvasPickerPropertyEditor } from '../../PropertyEditorTypes';
import type { PropertyEditorFunction } from '../../../builders/propertyEditorBuilder';
import type { SelectChangeEvent } from '@mui/material/Select';
import type { WithId } from '@mindhiveoy/schema';

const CanvasPickerEditor: PropertyEditorFunction<string, CanvasPickerPropertyEditor> = (
  props) => {
  if (!props.component.sessionId) {
    return null;
  }
  return <Inner {...props}/>;
};

const Inner: PropertyEditorFunction<string, CanvasPickerPropertyEditor> = (
  {
    data,
    component: {
      sessionId,
    },
    propertyName,
    propertyConfig,
    onSave,
  }
) => {
  const {
    query,
  } = useRouter();

  const params = {
    ...query,
    sessionId,
  } as SessionParams;

  const [value, setValue,] = useState(data ?? propertyConfig.defaultValue);
  const canvasesBloc = useCanvases(params);

  useEffect(() => {
    if (data && data !== value) {
      setValue(data);
    }
  }, [data, value,]);

  const [canvases, setCanvases,] = useState<WithId<Canvas>[]>([] as WithId<Canvas>[]);

  useEffect(() => canvasesBloc.subscribe((options) => {
    setCanvases(options.filter((canvas) => canvas.type !== 'layout' && canvas.type !== '1d' && canvas.type !== 'text'));
  }), [canvasesBloc,]);

  const handleChange = useCallback((event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (newValue !== data && onSave) {
      onSave(propertyName, newValue);
    }
  }, [data, onSave, propertyName,]);

  const id = `editor-${ propertyName}`;

  return <>
    { !canvases.length ? <>
      <br/>
      The selected session has no questions yet.
    </> : <PropertyEditorControl>
      <InputLabel id={`${id}-label`}>{propertyConfig.displayName ?? propertyName}</InputLabel>
      <Select
        labelId={`${id }-label`}
        id={`${id }-helper`}
        value={value}
        label={propertyName}
        onChange={handleChange}
      >
        { canvases.map(({
          _id, name,
        }) =>
          <MenuItem
            key={_id}
            value={_id}>{ name }
          </MenuItem>
        )}
      </Select>
    </PropertyEditorControl>}
  </>;
};

CanvasPickerEditor.config = {
  type: 'canvas-picker',
  displayName: 'Canvas',
};

Inner.config = CanvasPickerEditor.config;

export default CanvasPickerEditor;
