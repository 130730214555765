import { NoSsr } from '@mui/material';
import { unitValueToPixels } from 'utils/css';
import { useMemo, useRef } from 'react';
import Center from './Center';
import Dimensions, { useDimensionsContext } from './Dimensions';
import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';
import type { UnitValue } from 'utils/css';

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto auto auto;
  gap: 0px;
  position: absolute;
`;

const Cell = styled.div`
  display: flex;
  position: relative;
`;

export type Margins = {
  top?: UnitValue;
  left?: UnitValue;
  right?: UnitValue;
  bottom?: UnitValue;
};

interface SquareProps {
  margin?: Margins,
  cells?: {
    top?: JSX.Element;
    left?: JSX.Element;
    bottom?: JSX.Element;
    right?: JSX.Element;
    leftUpperCorner?: JSX.Element;
    rightUpperCorner?: JSX.Element;
    rightBottomCorner?: JSX.Element;
    leftBottomCorner?: JSX.Element;
  };
}

/**
 * SquareLayout will render a square in the center of the
 * parent area size. You may specify the margins in any unit
 * and the square will be centered in the remaining space with
 * a maximum size available.
 * @param props
 * @returns
 */
const SquareLayout = (props: PropsWithChildren<SquareProps>) => {
  return <NoSsr>
    <Dimensions>
      <Instance {...props} />
    </Dimensions>
  </NoSsr>;
};

const Instance = ({
  children,
  margin = {},
  cells = {},
}: PropsWithChildren<SquareProps>) => {
  const square = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    width,
    height,
  } = useDimensionsContext();

  const {
    topHeight,
    leftWidth,
    rightWidth,
    bottomHeight,
    squareSide,
  } = useMemo(() => {
    const {
      top,
      left,
      right,
      bottom,
    } = margin;

    const resolveSquareWidth = () => {
      if (width < height) {
        return width - leftWidth - rightWidth;
      }
      return height - topHeight - bottomHeight;
    };
    const topHeight = top ? unitValueToPixels(top, height) : 0;
    const leftWidth = left ? unitValueToPixels(left, width) : 0;
    const rightWidth = right ? unitValueToPixels(right, width) : 0;
    const bottomHeight = bottom ? unitValueToPixels(bottom, height) : 0;

    const squareSide = resolveSquareWidth();

    return {
      topHeight,
      leftWidth,
      rightWidth,
      bottomHeight,
      squareSide,
    };
  }, [height, margin, width,]);

  return <Center>
    <Grid ref={containerRef}
      style={{
        width: leftWidth + squareSide + rightWidth,
        height: topHeight + squareSide + bottomHeight,
      }}
    >
      <Cell style={{
        width: leftWidth,
        height: topHeight,
      }}>
        {cells.leftUpperCorner}
      </Cell>
      <Cell style={{
        width: squareSide,
        height: topHeight,
      }}>
        {cells.top}
      </Cell>
      <Cell style={{
        width: rightWidth,
        height: topHeight,
      }}>
        {cells.rightUpperCorner}
      </Cell>
      <Cell style={{
        width: leftWidth,
        height: squareSide,
      }}>
        {cells.left}
      </Cell>
      <Cell
        style={{
          height: squareSide,
          width: squareSide,
        }}
        ref={square}>
        {children}
      </Cell>
      <Cell style={{
        height: squareSide,
        width: rightWidth,
      }}>
        {cells.right}
      </Cell>
      <Cell style={{
        width: leftWidth,
        height: bottomHeight,
      }}>
        {cells.leftBottomCorner}
      </Cell>
      <Cell
        style={{
          height: bottomHeight,
          width: squareSide,
        }}
      >
        {cells.bottom}
      </Cell>
      <Cell style={{
        height: bottomHeight,
        width: rightWidth,
      }}>
        {cells.rightBottomCorner}
      </Cell>
    </Grid>
  </Center>;
};

export default SquareLayout;
