/* eslint-disable @typescript-eslint/no-explicit-any */
import { ListenerHelper } from '../../../utils/listener/listenerHelper';
import { v4 } from 'uuid';
import type { BuilderComponentId, BuilderComponentPropsBase } from '../widgets/PageWidgetRenderer';

export interface ChildModelDescriptor<C extends (BuilderComponentPropsBase<object> | BuilderComponentPropsBase<object>[])> {
  model: AbstractComponentModel<C>;
  fields: string[];
}
/**
 * Trigger listeners to be able to update the date up-to-date before saving the actual model.
 */
export type ValidateDataBeforeSaveListener<C extends (BuilderComponentPropsBase<object> | BuilderComponentPropsBase<object>[])> = (
  data: C
) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ParentModelDisposedListener<C extends AbstractComponentModel<any> = AbstractComponentModel<any>> = (parentModel: C) => void;

export type UpstreamPropertyChangeListener = (
  propertyName: string, value: unknown
) => void;

/**
 * Abstract base class defining the shared interface between different component models.
 */
export abstract class AbstractComponentModel<C extends (BuilderComponentPropsBase<any> | BuilderComponentPropsBase<any>[])> {
  protected readonly validateBeforeSaveListeners = new ListenerHelper<ValidateDataBeforeSaveListener<C>>();

  protected readonly childModelDescriptors: ChildModelDescriptor<C>[] = [];

  protected readonly onParentModelDisposeListeners = new ListenerHelper();
  protected readonly onUpstreamPropertyChangeListener = new ListenerHelper();

  protected invalidated = false;
  protected readonly parentModel?: AbstractComponentModel<C>;
  protected readonly fields?: (keyof C)[];
  protected readonly childModelFieldMap = new Map<string, AbstractComponentModel<C>>();

  protected $state: 'default' | 'resetting' | 'loading' = 'default';
  /**
   * Constructor
   * @param {C} childModelDescriptor  Optional child model descriptor. If descriptor is defined, will this model
   *                                  take control over listed fields in the parent model.
   */
  constructor(
    childModelDescriptor?: ChildModelDescriptor<C>
  ) {
    if (childModelDescriptor) {
      const {
        model: parentModel,
        fields,
      } = childModelDescriptor;
      this.parentModel = parentModel;
      // TODO: Validate that fields do not overlap with an another child model.
      parentModel.registerChildModel(this, fields);
    }
  }
  /**
   * Read the current state of the model.
   */
  public get state() {
    return this.$state;
  }

  /**
   * Set the current state of the model.
   *
   * @param {string} state New state of the model.
   */
  public set state(state: 'default' | 'resetting' | 'loading') {
    this.$state = state;
    if (this.parentModel) {
      this.parentModel.state = state;
    }
  }

  /**
   * Notify model to treat is data as changed.
   */
  public invalidate = () => {
    if (this.state === 'resetting') {
      return;
    }
    this.invalidated = true;
  };
  /**
   * Return the current component data.
   *
   * @returns {C | undefined} The current component data.
   */
  public abstract get component(): C | undefined;
  /**
   * Set the current component data.
   *
   * @argument {C | undefined} data The new data value
   */
  public abstract set component(data: C | undefined);

  public abstract isEmpty(): boolean;

  /**
   * Export model to a string
   * @return {string} String presentation of the data
   */
  public exportData = (): string => {
    return JSON.stringify(this.component);
  };
  /**
   * Generate an id for an component. Not that it is on caller's
   * responsibility to verify the uniqueness of the id within the model.
   *
   * @return {BuilderComponentId} An unique id within the model.
   */
  public generateBuilderComponentId = (): BuilderComponentId => {
    return v4();
  };
  /**
   * Validate model to be up-to-date. This should be called before saving the model, that different sub
   * systems may update their state.
   */
  abstract prepare(): void;
  /**
   * Subscribe to listen component model validation request
   * @param {ValidateDataBeforeSaveListener} listener Listener function
   */
  public addValidateBeforeSaveListener = (listener: ValidateDataBeforeSaveListener<C>) => {
    this.validateBeforeSaveListeners.add(listener);
  };
  /**
   * Unsubscribe from listening component model validation requests.
   *
   * @param {ValidateDataBeforeSaveListener} listener Listener function
   */
  public removeValidateBeforeSaveListener = (listener: ValidateDataBeforeSaveListener<C>) => {
    this.validateBeforeSaveListeners.remove(listener);
  };
  /**
   * Called when model is being disposed.
   */
  public dispose = () => {
    this.onParentModelDisposeListeners.fire(this);
    this.parentModel?.disposeChild(this);
  };
  /**
   * Add parent model dispose listener. Use this on child models to clean out the state when
   * parent model is being disposed.
   * @param {ParentModelDisposedListener} listener Listener
   */
  public addParentModelDisposeListener = (listener: ParentModelDisposedListener) => {
    this.onParentModelDisposeListeners.add(listener);
  };
  /**
   * Remove parent model dispose listener. Use this on child models to clean out the state when
   * parent model is being disposed.
   * @param {ParentModelDisposedListener} listener Listener
   */
  public removeParentModelDisposeListener = (listener: ParentModelDisposedListener) => {
    this.onParentModelDisposeListeners.remove(listener);
  };
  /**
   * Add parent model dispose listener. Use this on child models to clean out the state when
   * parent model is being disposed.
   * @param {ParentModelDisposedListener} listener Listener
   */
  public addUpstreamPropertyChangeListener = (listener: UpstreamPropertyChangeListener) => {
    this.onUpstreamPropertyChangeListener.add(listener);
  };
  /**
   * Remove parent model dispose listener. Use this on child models to clean out the state when
   * parent model is being disposed.
   * @param {ParentModelDisposedListener} listener Listener
   */
  public removeUpstreamPropertyChangeListener = (listener: UpstreamPropertyChangeListener) => {
    this.onUpstreamPropertyChangeListener.remove(listener);
  };
  /**
   * Called by upstream model to trigger to inform child component of change.
   * // TODO: Protect this from outside calls
   * @param {string} propertyName
   * @param {any} value
   */
  public upstreamPropertyUpdate = (propertyName: string, value: any) => {
    this.onUpstreamPropertyChangeListener.fire(propertyName, value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private registerChildModel = (
    model: AbstractComponentModel<C>,
    fields: Array<string>
  ) => {
    this.childModelDescriptors.push({
      model,
      fields,
    });

    fields.forEach((field: string) => {
      if (this.childModelFieldMap.has(field)) {
        throw new Error(`Field ${field} already registered for a child model. Only one child model per field is allowed.`);
      }
      this.childModelFieldMap.set(field, model);
    });
  };

  /**
   * Dispose child from parent model
   * @param {AbstractComponentModel<C>} child The child model.
   */
  private disposeChild = (child: AbstractComponentModel<C>) => {
    this.childModelFieldMap.forEach((model, field) => {
      if (model === child) {
        this.childModelFieldMap.delete(field);
      }
    });
  };
}
