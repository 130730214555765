import type { CategoryQuestionCanvas as CategoryQuestionCanvasEditor } from '@shared/schema/src';

export const parseCanvasData = (canvas: CategoryQuestionCanvasEditor): {
  categories: any;
  items: any;
} => {
  const categories = canvas.categories ?
    canvas.categories.map((value) => ({
      ...value,
    } as any)) : // TODO: Check if this is correct
    [];

  if (!categories.find((c) => c.id === '_')) {
    categories.push({
      id: '_',
      label: 'Uncategorized',
      color: '#E0E0E0',
      fixed: true,
    } as any);
  }

  const items = canvas.options ?
    Object.entries(canvas.options)
      .map((id, value) => ({
        ...value as any,
        itemId: id,
      } as any)) : [];

  return {
    categories,
    items,
  };
};
