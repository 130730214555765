import { toFixed } from './toFixed';
import type { MatrixMode, RangeItem, TickLabelingType } from '@shared/schema/src';

/**
 * Render a value to be shown inside the knob.
 *
 * @param value         The value to render.
 * @param mode          The mode of the slider.
 * @param labelType     The type of label to render.
 * @param range         The range of the slider.
 * @param valueAsIndex  Whether the value is an index in the range.
 * @returns             The rendered value.
 */
const renderKnobValue = (
  value: number | undefined | null,
  mode: MatrixMode,
  labelType: TickLabelingType,
  range: RangeItem[],
  valueAsIndex = false
): string | undefined => {
  if (value === undefined || value === null) {
    return undefined;
  }

  switch (mode) {
    case 'discrete': {
      const axisValue = valueAsIndex ?
        range?.[value] :
        range?.find((r) => r.value === value);
      return axisValue?.label;
    }
    case 'continuous': {
      return renderContinuousValue(value, labelType, range);
    }
    default: {
      throw new Error(`Unsupported mode: ${mode}`);
    }
  }
};

/**
 * Render a continuous value.
 *
 * @param value       The value to render.
 * @param labelType   The type of label to render.
 * @param range       The range of the slider.
 * @returns           The rendered value.
 */
const renderContinuousValue = (value: number,
  labelType: TickLabelingType,
  range: RangeItem[]
) => {
  switch (labelType) {
    case 'percentage': {
      const min = range[0].value;
      const max = range[range.length - 1].value;
      const length = max - min;

      const rangeValue = (value - min) / length + min;
      const result = toFixed(rangeValue, null);
      if (result === null) {
        return undefined;
      }
      return `${(100 * rangeValue).toFixed(1)}%`;
    }
    case 'numeric': {
      return toFixed(value, null) ?? undefined;
    }
    default: {
      throw new Error(`Unsupported label type: ${labelType}`);
    }
  }
};

export default renderKnobValue;
