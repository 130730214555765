import { Skeleton } from '@mui/material';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import FormMessage from './messages/FormMessage';
import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import styled$ from 'utils/react/styled$';
import type { CSSProperties, PropsWithChildren } from 'react';
import type { MessageField, MessageFields } from 'ts-react-form-validator';

// TODO change min-width to use clamp
// TODO find a place for default within the theme
const StyledPaper = styled$(motion.div)<{
  $fluid?: boolean;
  $maximizeWidth: boolean;
  $mobileStyling?: boolean;
  $noPadding?: boolean;
}>(({
  theme,
  $fluid,
  $maximizeWidth,
  $mobileStyling,
  $noPadding,
}) => css`  
  position: relative;
  align-items: center;
  display: flex;
  border-radius: ${$mobileStyling ? 0 : theme.shape.borderRadius}px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: ${$noPadding ? 0 : theme.spacing(2)};
  background-color: ${theme.palette.background.paper}; 
  backdrop-filter: blur(${theme.shape.smokeGlassBlur}px);
  ${$maximizeWidth || $mobileStyling && 'width: 100%;'}
  ${!$maximizeWidth && `min-width: min(100%, ${theme.shape.maxEditorAreaWidth}px, calc(100vw - ${theme.spacing(2)}));`}
  ${$fluid ? 'height: 100%;' : `max-width: calc(${theme.shape.maxEditorAreaWidth}px, 100%);`}
`);

export interface FormContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messages?: MessageFields<any>;
}
export const FormContext = React.createContext<FormContext>(
  {}
);

/**
 * Represents the props for the Form component.
 *
 * @template T - The type of the form data.
 */
/**
 * Props for the Form component.
 */
export interface FormProps<T> {
  /**
   * The class name for the component. This makes the component styleable
   * with EmotionJs.
   */
  className?: string;

  /**
   * The number of fields in the form.
   */
  fieldCount?: number;

  /**
   * Determines if the form should take up the full width of its container.
   */
  fluid?: boolean;

  /**
   * The unique identifier for the form.
   */
  id: string;

  /**
   * Determines if the form is in a loading state.
   */
  loading?: boolean;

  /**
   * Determines the maximum width of the form.
   */
  maxWidth?: boolean;

  /**
   * The message to be displayed in the form. Can be a boolean value to show/hide
   * a default message or a custom `MessageField` object.
   */
  message?: true | MessageField;

  /**
   * The messages to be displayed in the form. Each message is associated with a
   * specific field in the form.
   */
  messages?: MessageFields<T>;

  /**
   * Determines if the form should have mobile-specific styling.
   */
  mobileStyling?: boolean;

  /**
   * Determines if the form should have padding or not.
   */
  noPadding?: boolean;

  /**
   * Additional styles to be applied to the form.
   */
  style?: CSSProperties;

  /**
   * The title of the form.
   */
  title?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Form = <T,>({
  children,
  className,
  fluid,
  id,
  loading = false,
  maxWidth = false,
  message,
  messages,
  mobileStyling,
  noPadding,
  style,
  title,
}: PropsWithChildren<FormProps<T>>): JSX.Element => {
  const context = useMemo(() => ({
    messages,
  }), [messages,]);

  return <FormContext.Provider value={context}>
    <StyledPaper
      className={className}
      $mobileStyling={mobileStyling}
      layoutRoot
      key={id}
      $fluid={fluid}
      $maximizeWidth={maxWidth}
      style={style}
      $noPadding={noPadding}
    >
      {title ? <Typography component={'span'}
        key="title"
        variant="h1">{title}</Typography> : null}
      {message ? <FormMessage key="message"
        formMessage={message} /> : null}
      {loading ? <Skeleton key="skeleton"
        animation="wave" /> : children}
    </StyledPaper>
  </FormContext.Provider>;
};

export default Form;
