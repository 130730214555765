/* eslint-disable max-len */
import styled from '@emotion/styled';

/**
 * Background video component to be used in the media components
 * as a background video. The video will be displayed in the background
 * of the media component.
 */
export const BackgroundVideo = styled.video`
  position: absolute;
  width: 100vw;
  height: 100dvh;
  pointer-events: none;
  object-fit: cover;
  z-index: -1;
`;

/**
 * Background player component to be used in the media components
 */
export const BackgroundPlayer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100dvh;
  pointer-events: none;
  object-fit: cover;
  z-index: -1;
`;
