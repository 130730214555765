import { BillingPeriod } from './payments';

export interface UnitPrices {
  perFacilitator?: number;
  perUser?: number;
}
export type PlanPrices = {
  [period in BillingPeriod]: UnitPrices;
}

/**
 * Descriptor of a single plan
 */
export interface PlanDescriptor {
  name: string;
  pricing: 'free' | 'per-seat' | 'custom';
  prices?: {
    monthly: {
      base?: number;
      perFacilitator?: number;
      extraUsers?: number;
    },
    annually: {
      base?: number;
      perFacilitator?: number;
      extraUsers?: number;
    }
  },
  usersIncluded: {
    minFacilitators: number;
    maxFacilitators: number;
    extraF?: boolean;
    users: number,
    maxUsers: number;
    openUsers?: number;
  },
  stripeProductId?: string;
}

/**
 * Internal plan id's
 */
export type PlanId = 'free' | 'individual' | 'team' | 'enterprise';

/**
 * Plan descriptors type
 */
export type PlanDescriptors = {
  [planId in PlanId]: PlanDescriptor
}

export const USER_BUCKET_SIZE = 10;

/**
 * The actual applications plans for the platform.
 *
 * OBS! Please notice, that any changes in here must be reflected in the
 * Stripe products and plans as well for both Aavistus and xDelphi.
 */
export const appPlans: PlanDescriptors = {
  free: {
    name: 'Free',
    pricing: 'free',
    usersIncluded: {
      minFacilitators: 1,
      maxFacilitators: 1,
      users: 5,
      maxUsers: 5,
    },
  },
  individual: {
    name: 'Individual',
    pricing: 'per-seat',
    prices: {
      monthly: {
        base: 75,
        perFacilitator: 0,
        extraUsers: 20,
      },
      annually: {
        base: 750,
        perFacilitator: 750,
        extraUsers: 200,
      },
    },
    usersIncluded: {
      minFacilitators: 1,
      maxFacilitators: 1,
      extraF: true,
      users: 50,
      maxUsers: 1000,
    },
  },
  team: {
    name: 'Team',
    pricing: 'per-seat',
    prices: {
      monthly: {
        base: 250,
        perFacilitator: 125,
        extraUsers: 15,
      },
      annually: {
        base: 2500,
        perFacilitator: 1250,
        extraUsers: 150,
      },
    },
    usersIncluded: {
      minFacilitators: 2,
      maxFacilitators: 50,
      extraF: true,
      users: 100,
      maxUsers: 2500,
    },
  },
  enterprise: {
    name: 'Enterprise',
    pricing: 'custom',
    prices: {
      monthly: {
        base: 750,
        perFacilitator: 150,
        extraUsers: 10,
      },
      annually: {
        base: 7500,
        perFacilitator: 1500,
        extraUsers: 100,
      },
    },
    usersIncluded: {
      minFacilitators: 5,
      maxFacilitators: 200,
      extraF: true,
      users: 200,
      openUsers: 500,
      maxUsers: 10000,
    },
  },
};

export const DEFAULT_PLAN: PlanId = 'free';

export const ALL_PLANS: PlanId[] = ['team', 'individual', 'free', 'enterprise',];

export const PAID_PLANS: PlanId[] = ['team', 'individual', 'enterprise',];
