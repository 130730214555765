// / <reference types="@welldone-software/why-did-you-render" />
import React from 'react';

const REPORT_REACT_RENDERING = false;

if (REPORT_REACT_RENDERING && typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
