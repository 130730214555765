/* eslint-disable @typescript-eslint/no-explicit-any */

import { ErrorBoundary } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useCanvas } from '../../../../bloc/canvas/CanvasBloc';
import Answer2DMatrix from '../../../canvas/canvasTypes/Matrix2DQuestionCanvas/Answer2DMatrix';
import Center from 'components/common/layout/Center';
import styled from '@emotion/styled';
import usePathParams from '../../../../utils/hooks/usePathParams';
import type { BuilderComponentPropsBase } from '../BuilderComponentTypes';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { Canvas, Matrix2DQuestionCanvas } from '@shared/schema/src';
import type { Graph2DMatrixWidget } from '@shared/schema/src/spaces/projects/sessions/canvases';
import type { WithId } from '@mindhiveoy/schema';

const AutoMargin = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`;

export interface Graph2DWidgetProps extends BuilderComponentPropsBase<Graph2DMatrixWidget> {
  type: 'graph-2d';
}

const Graph2DWidget: BuilderFunction<Graph2DWidgetProps, Graph2DMatrixWidget> = ({
  data: {
    answer,
    canvasId,
    sessionId,
    snapshot,
    dataMode,
    characteristics,
  },
}: Graph2DWidgetProps) => {
  const params = usePathParams();
  const [height, setHeight,] = useState<number>(0);
  const [width, setWidth,] = useState<number>(0);

  const scale = 2.8;

  const [canvasFromBloc, setCanvas,] = useState<WithId<Canvas>>();
  const canvasBloc = useCanvas({
    ...params,
    sessionId,
    canvasId,
  });

  // Generate Size for the Graph child component, based on his parent props
  const generateSize = useCallback((height: number, width: number): void => {
    setHeight(height / scale);
    setWidth(width / scale);
  }, []);

  useEffect(() => canvasBloc.subscribe(
    setCanvas,
    (error) => console.error(error)
  ), [canvasBloc,]);

  useEffect(() => {
    const handleResize = () => {
      generateSize(height, width);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [generateSize, height, width,]);

  if (!(canvasFromBloc as any)?.axis) {
    return null;
  }
  return <AutoMargin ref={() => {
    setHeight(screen.height ?? 0);
    setWidth(screen.width ?? 0);
  }}>
    <Center>
      <ErrorBoundary>
        <Answer2DMatrix
          size={height < width ? {
            height: height / scale, width: height / scale,
          } :
            {
              height: width / scale, width: width / scale,
            }}
          characteristic={characteristics}
          canvas={dataMode === 'snapshot' && snapshot?.statistics ?
            {
              ...snapshot, statistics: {
                ...snapshot.statistics,
                averageX: answer?.x,
                averageY: answer?.y,
              },
            } as Matrix2DQuestionCanvas :
            canvasFromBloc as Matrix2DQuestionCanvas}
        />
      </ErrorBoundary>
    </Center>
  </AutoMargin>;
};

Graph2DWidget.config = {
  type: 'graph-2d',

  displayName: 'Graph 2D',
  props: {
    dataMode: {
      category: 'general',
      type: 'picker',
      displayName: 'Data mode',
      defaultValue: 'real-time',
      options: [
        {
          label: 'Real time',
          value: 'real-time',
        },
        {
          label: 'Snapshot',
          value: 'snapshot',
        },
      ],
    },
    sessionId: {
      category: 'general',
      defaultValue: '',
      type: 'session-picker',
      displayName: 'Session',
    },
    canvasId: {
      category: 'general',
      defaultValue: '',
      type: 'canvas-picker',
      displayName: 'Canvas',
    },
    characteristics: {
      type: 'picker',
      category: 'general',
      displayName: 'Characteristics',
      defaultValue: 'mean',
      options: [
        {
          value: 'mean',
          label: 'Mean',
        },
        {
          value: 'q1',
          label: '1. quartile',
        },
        {
          value: 'median',
          label: 'Median',
        },
        {
          value: 'q3',
          label: '3. quartile',
        },
      ],
    },
  },
};

export default Graph2DWidget;
