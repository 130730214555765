import React from 'react';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import type { CSSProperties, MouseEventHandler } from 'react';
import type { Media } from '@shared/schema/src/common';
import type { StyledComponent } from '@emotion/styled';

import { ColorMediaContainer } from './mediaTypes/ColorMediaContainer';
import { DefaultMediaContainer } from './mediaTypes/DefaultMediaContainer';
import { PhotoMediaContainer } from './mediaTypes/PhotoMediaContainer';
import { VideoMediaContainer } from './mediaTypes/VideoMediaContainer';
import { YouTubeMediaContainer } from './mediaTypes/YouTubeMediaContainer';
import { isYouTubeUrl } from 'utils/urls';
import { prepareYouTubeUrl } from './prepareYouTubeUrl';
import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import type { PropsWithChildren } from 'react';

export const MEDIA_BLUR_RADIUS = '2rem';

export const StyledCard = styled$.div<{
  $gestures?: boolean;
  $fullScreen?: boolean;
  $screenHeight?: boolean;
  $blur?: boolean;
  $noRadius?: boolean;
  $image: string;
}>(({
  theme,
  $fullScreen,
  $gestures,
  $blur,
  $noRadius,
  $image,
  $screenHeight = false,
}) => `
  padding: 0;
  display: flex;
  justify-content: center;
  max-height: 100%;
  height: ${$screenHeight ? '100dvh' : '100%'};
  width: 100%;
  max-width: 100%;
  z-index: -1;
  position: ${$fullScreen ? 'fixed' : 'relative'};
  background-size: cover;
  background-image: url("${$image}");
  background-repeat: no-repeat;  
  background-position: center center;
  overflow: hidden;
  ${$blur && `filter: blur(${MEDIA_BLUR_RADIUS});`}
  ${$fullScreen && 'min-height: 100dvh;'}
  ${$fullScreen && 'width: 100vw;'}
  ${$fullScreen || $noRadius ? '' :
    `border-radius: ${theme.shape.borderRadius}px;`}
  &:hover {
    ${$gestures ? theme.animations.hovers.card : ''}
    -moz-appearance: none; /* For Firefox */
    appearance: none; 
  };
  -moz-appearance: none; /* For Firefox */
  appearance: none; 
`);

export const VideoCard = styled$.div<{
  $blur?: boolean;
  $fullScreen?: boolean;
  $gestures?: boolean;
  $noRadius?: boolean;
}>(({
  $blur,
  $fullScreen,
  $gestures,
  $noRadius,
  theme,
}) => `
  position: ${$fullScreen ? 'fixed' : 'relative'}; 
  ${$blur ? `filter: blur(${MEDIA_BLUR_RADIUS});` : ''}
  ${$fullScreen ? 'min-height: 100dvh;' : ''}
  width: ${$fullScreen ? '100vw' : '100%'};
  ${$fullScreen ? 'top: 0; left: 0; right: 0; bottom: 0;' : ''}
  position: ${$fullScreen ? 'fixed' : 'relative'};
  width: 100%;
  height: ${$fullScreen ? '100dvw' : '100%'};
  flex-grow: 1;
  overflow: hidden;
  z-index: 0;
  border-radius: ${$fullScreen || $noRadius ? 0 : theme.shape.borderRadius}px; 
  -moz-appearance: none; /* For Firefox */
  appearance: none; 
  &:hover {
    ${$gestures ? theme.animations.hovers.card : ''}
  }
`);

// Any needed to make muted property to video. React is currently missing the attribute.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BackgroundVideo: StyledComponent<any,
  // eslint-disable-next-line @typescript-eslint/ban-types
  React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>, {}> = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;
  z-index: -1;
  -moz-appearance: none; /* For Firefox */
  appearance: none; 
`;

export interface MediaContainerProps {
  blur?: boolean;
  fullScreen?: boolean;
  screenHeight?: boolean;
  gestures?: boolean;
  media?: Media;
  noRadius?: boolean;
  style?: CSSProperties;
  onClick?: MouseEventHandler<unknown>;
}

export interface TypedMediaContainerProps extends MediaContainerProps {
  url: string | undefined | null;
}

/**
 * MediaContainer component is a container for media elements like photos, videos, colors, etc.
 * Its main purpose is to provide a unified interface for different media types. The component
 * automatically detects the media type and renders the appropriate container based on Media
 * object properties.
 *
 * @param {MediaContainerProps} props - MediaContainer component properties
 */
export const MediaContainer = (
  props: PropsWithChildren<MediaContainerProps>
) => {
  const theme = useTheme();

  const {
    media,
  } = props;

  const {
    type,
    url,
  } = useMemo(() => {
    switch (media?.type) {
      case 'photo':
        return {
          type: 'photo',
          url: media?.photoURL ?? theme.shape.imagePlaceholder,
        };
      case 'video': {
        // TODO: Add support for other video types supported by the ReactPlayer
        const isYoutube = isYouTubeUrl(media?.videoURL);
        if (isYoutube) {
          return {
            type: 'youtube',
            url: prepareYouTubeUrl(media?.videoURL),
          };
        }
        return {
          type: 'video',
          url: media?.videoURL,
        };
      }
      case 'color':
        return {
          type: 'color',
        };
      // eslint-disable-next-line sonarjs/no-duplicate-string
      case 'no background':
        return {
          type: 'no background',
          url: '',
        };
      default:
        return {
          type: 'unknown',
          url: '',
        };
    }
  }, [media?.photoURL, media?.type, media?.videoURL, theme.shape?.imagePlaceholder,]);

  switch (type) {
    case 'photo':
      return <PhotoMediaContainer
        {...props}
        url={url}
      />;

    case 'youtube':
      return <YouTubeMediaContainer
        {...props}
        url={url}
      />;

    case 'video':
      return <VideoMediaContainer
        {...props}
        url={url}
      />;

    case 'color':
      return <ColorMediaContainer
        {...props}
      />;

    case 'no background':
      return props.children;

    default:

      return <DefaultMediaContainer
        {...props}
      />;
  }
};

export default MediaContainer;
