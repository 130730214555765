import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { KeyboardEventHandler } from 'react';
import type { NumberPropertyEditor } from 'components/builder/propertyEditors/PropertyEditorTypes';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

const NumberEditor: PropertyEditorFunction<number, NumberPropertyEditor> = ({
  data,
  propertyName,
  disabled,
  propertyConfig: {
    displayName,
    defaultValue,
    precision,
    section,
    testId = propertyName,
  },
  onSave,
  onPressEnter,
}) => {
  const numberToString = useCallback((value: number): string => {
    if (!value && value !== 0) {
      return '';
    }
    if (!precision) {
      return value.toFixed(0);
    }
    return value.toFixed(precision);
  }, [precision,]);

  const stringToNumber = useCallback((value: string): number | undefined => {
    if (!value) {
      return undefined;
    }
    return precision && precision > 0 ? parseFloat(value) : parseInt(value);
  }, [precision,]);

  const [value, setValue,] = useState<string>(numberToString(data ?? defaultValue));
  const ref = useRef<HTMLInputElement>(null);
  const [editing, setEditing,] = useState(false);

  // TODO: input validation
  useEffect(() => {
    if (editing) {
      return;
    }
    const v = numberToString(data ?? defaultValue);
    if (value !== v) {
      setValue(v);
    }
  }, [data, defaultValue, editing, numberToString, value,]);

  const handleSave = useCallback((value: string) => {
    const newValue = value?.trim();

    const v = stringToNumber(newValue);
    if (Number.isNaN(v) || v === undefined) {
      return;
    }
    if (v !== data && onSave) {
      onSave(propertyName, v);
    }
  }, [data, onSave, propertyName, stringToNumber,]);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback((event) => {
    const value = event.target.value;

    setValue(value);
    handleSave(value);
    event?.stopPropagation();
  }, [handleSave,]);

  const handleFocus = useCallback((event: any) => {
    event.target.select();
    setEditing(true);
  }, []);

  const handleBlur = useCallback(() => {
    setEditing(false);
    handleSave(value);
  }, [handleSave, value,]);

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      handleSave(value);

      onPressEnter?.();
    }
  }, [handleSave, onPressEnter, value,]);

  return <>
    {section &&
      <>
        <Typography component={'span'}
          variant="h4"
          id={`${value}-label`}>{section}
        </Typography>
        <br />
      </>
    }
    <FormControl fullWidth
      disabled={disabled}>
      <TextField
        inputProps={{
          'data-testid': testId,
        }}
        disabled={disabled}
        ref={ref}
        variant="outlined"
        placeholder={propertyName}
        label={displayName ?? propertyName}
        id={propertyName}
        value={value}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        fullWidth
      />
    </FormControl>
  </>;
};

NumberEditor.config = {
  type: 'number',
};

export default NumberEditor;
