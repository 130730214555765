/* eslint-disable @typescript-eslint/no-explicit-any */
import { ListenerHelper } from 'utils/listener/listenerHelper';
import React, { startTransition, useContext } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import type { ComponentSelectionModel, SelectionDataChangeListenerFunction } from 'components/builder/models/selectionModels';

export type SelectionListenerFunction<D extends object = object> = (selection: ComponentSelectionModel<D> | null) => void;

export interface ComponentSelectionContext<D extends object = object> {
  /**
   * The current selection
   */
  selection: ComponentSelectionModel<D> | null;

  rootSelection: ComponentSelectionModel<D> | null;
  /**
   * set the new selection
   *
   * @param {ComponentSelectionModel} selection The new selection object
   */
  setSelection: (selection: ComponentSelectionModel<D> | null) => void;
  /**
   * Add a new listener for selection changes.
   *
   * @param {SelectionListenerFunction} listener A new listener
   */
  addSelectionListener: (listener: SelectionListenerFunction) => void;
  /**
   * Remove listener from selection changes
   *
   * @param {SelectionListenerFunction} listener The listener to be removed.
   */
  removeSelectionListener: (listener: SelectionListenerFunction) => void;

  /**
   * Add a new listener for changes on selected component's data.
   * @param {ComponentModelDataChangeListener} listener A new listener
   */
  addSelectionDataChangeListener: (listener: SelectionDataChangeListenerFunction<D>) => void;

  /**
   * Remove a listener from listening changes on selected component's data.
   * @param {ComponentModelDataChangeListener} listener The listener to be removed.
   */
  removeSelectionDataChangeListener: (listener: SelectionDataChangeListenerFunction<D>) => void;
}

/**
 * Selection context instance to be used to control active selections.
 */
export class SelectionContextInstance<D extends object = object> implements ComponentSelectionContext<D> {
  private $selectionChangeListeners = new ListenerHelper<SelectionListenerFunction>();
  private $selectionDataChangeListeners = new ListenerHelper<SelectionDataChangeListenerFunction<D>>();
  /**
   * The current selection
   */
  selection: ComponentSelectionModel<D> | null = null;

  rootSelection: ComponentSelectionModel<D> | null = null;
  /**
   * set the new selection
   *
   * @param {ComponentSelectionModel} newSelection The new selection object
   */
  setSelection = (newSelection: ComponentSelectionModel<D> | null) => {
    // TODO: Test cases
    if (this.selection && (!newSelection || newSelection.isRootLevel())) {
      this.removeSelectionDataChangeListener(this.$onDataChange);
      this.selection.dispose();
      this.rootSelection?.dispose();
    }
    this.selection = newSelection;
    if (this.selection && this.selection.isRootLevel()) {
      this.selection.addSelectionDataChangeListener(this.$onDataChange);
      this.rootSelection = newSelection;
    }
    const _newSelection = cloneDeep(newSelection);
    startTransition(() => {
      this.$selectionChangeListeners.fire(_newSelection);
    });
  };

  /**
   * Add a new listener
   *
   * @param {SelectionListenerFunction} listener A new listener
   */
  addSelectionListener = (listener: SelectionListenerFunction) => {
    this.$selectionChangeListeners.add(listener);
  };
  /**
   * Remove aa registered listener
   *
   * @param {SelectionListenerFunction} listener The listener to be removed.
   */
  removeSelectionListener = (listener: SelectionListenerFunction) => {
    this.$selectionChangeListeners.remove(listener);
  };

  /**
   * Add a new listener for changes on selected component's data.
   * @param {ComponentModelDataChangeListener} listener A new listener
   */
  addSelectionDataChangeListener = (listener: SelectionDataChangeListenerFunction<D>) => {
    this.$selectionDataChangeListeners.add(listener);
  };

  /**
   * Remove a listener from listening changes on selected component's data.
   * @param {ComponentModelDataChangeListener} listener The listener to be removed.
   */
  removeSelectionDataChangeListener = (listener: SelectionDataChangeListenerFunction<D>) => {
    this.$selectionDataChangeListeners.remove(listener);
  };

  /**
   * Event handler for data changes in active selection.
   * @param {any} data A new data
   */
  private $onDataChange: SelectionDataChangeListenerFunction<D> = (data) => {
    const _data = cloneDeep(data);
    startTransition(() => {
      this.$selectionDataChangeListeners.fire(_data);
    });
  };
}

export const ComponentSelectionContext = React.createContext<ComponentSelectionContext<any>>(null as any);

export const useComponentSelectionContext = <D extends object>() => {
  return useContext(ComponentSelectionContext) as ComponentSelectionContext<D>;
};
