import { BackgroundFade } from './BackgroundFade';
import { BackgroundImage } from './BackgroundImage';
import { BackgroundPlayer, BackgroundVideo } from './BackgroundVideo';
import { motion, useScroll, useTransform } from 'framer-motion';
import { prepareYouTubeUrl } from 'components/layout/MediaContainer/prepareYouTubeUrl';
import { useMediaQuery } from '@mui/material';
import { usePanelistScrollContext } from '../../providers/PanelistScrollProvider';
import { useParallaxContext } from '../../providers/ParallaxContext';
import ReactPlayer from 'react-player';
import styled from '@emotion/styled';
import theme from 'theme';
import type { CSSProperties } from 'react';
import type { Media } from '@shared/schema/src';

const Container = styled(motion.div)`
  position: absolute;
  height: 100dvh;
  transform: translateZ(0);
`;

interface NewMediaProps {
  media?: Media;
  noScroll?: boolean;
  useBackgroundOpacity?: boolean;
}
export const NewMedia = ({
  media,
  noScroll = false,
  useBackgroundOpacity = false,
}: NewMediaProps) => {
  const {
    scrollRef,
  } = usePanelistScrollContext();

  const {
    titlePos,
  } = useParallaxContext();

  const {
    scrollY,
  } = useScroll({
    container: scrollRef,
    layoutEffect: false,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const height = typeof window !== 'undefined' ? window.innerHeight : 400;
  const y = useTransform(scrollY, [0, height,], [0, height * 0.5,]);

  // if useBackgroundOpacity is true && isMobile is true, position is 500,
  // if only useBackgroundOpacity is true, position is 900, otherwise default position is 50
  const position = useBackgroundOpacity ? isMobile ? 600 : 900 : 50;

  const peak = useBackgroundOpacity ?
    100 * (titlePos + position) / height : // Adjust this value to start the gradient lower
    noScroll ?
      (height - 200) / height * 100 :
      100 * (titlePos - position) / height;

  return <Container
    data-testid="media-container"
    style={{
      y,
      z: 1,
    }}>
    <Content media={media} />
    <BackgroundFade
      $peak={peak}
      $useBackgroundOpacity={useBackgroundOpacity} />
  </Container>;
};

export const BackgroundColor = styled(motion.div)<{
  $color?: string;
}>(({
  theme,
  $color,
}) => `
  position: absolute;
  width: 100vw;
  height: 100dvh;
  background-color: ${$color ?? theme.palette.background.default};
`);

const youTubeStyle: CSSProperties = {
  objectFit: 'cover',
  width: '100%',
  height: '100%',
};

const Content = ({
  media,
}: NewMediaProps) => {
  switch (media?.type) {
    case 'photo':
      return <BackgroundImage $imageUrl={media.photoURL} />;

    case 'video': {
      const url = prepareYouTubeUrl(media?.videoURL);

      const config = media?.videoConfig;
      if (url) {
        return <BackgroundPlayer>
          <ReactPlayer
            playing
            loop={!!config?.loop}
            width="100%"
            height="100%"
            muted={!!config?.muted}
            style={youTubeStyle}
            url={url}
          />
        </BackgroundPlayer>;
      }
      return <BackgroundVideo
        loop={!!config?.loop}
        autoPlay
        width="100%"
        height={200}
        muted={!!config?.muted}
      >
        <source src={media?.videoURL}
          type="video/mp4" />
      </BackgroundVideo>;
    }

    case 'color':
      return <BackgroundColor style={{
        backgroundColor: media?.color,
      }} />;

    default:
      return null;
  }
};
