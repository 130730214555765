
import { HorizontalAxisLabel, TopLabeling } from '../MatrixLabels';
import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import Dimensions from 'components/common/layout/Dimensions';
import styled from '@emotion/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { CSSProperties, PropsWithChildren } from 'react';

const Content = styled.div(({
  theme,
}) => `
  grid-area: main;
  position: relative;
  align-content: center;
  padding: 0;
  margin: 0;
  width: 100%;  
  border-color: ${theme.palette.primary.main};
  border-radius: ${theme.shape.borderRadius}px;
  @media(max-width: 600px) {
    font-size: 14px;
  }
`);

/**
 * Container for both sliders
 */
const SlidersContainer = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  align-items: center;
  grid-template-areas:
    "top-label    "
    "main         "
    "bottom-label "
    "top-label2   "
    "main2        "
    "bottom-label2";
  @media(max-width: 600px) {
    font-size: 14px;
  }
`;

export const SliderContainer = styled.div(({
  theme,
}) => `
  position: relative;
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-template-areas:
    "top-label"
    "main       "
    "bottom-label";
  margin: ${theme.spacing(1, 0)};
  @media(max-width: 600px) {
    font-size: 14px;
  }
`);

interface SliderLayoutProps {
  axisLabel?: string | JSX.Element;
  gridPostFix?: string;
  showAxisLabels?: boolean;
  showMinMaxLabels?: boolean;
}

/**
 * A layout for a single slider with labels.
 *
 * @param {SliderLayoutProps} props
 * @return {JSX.Element}
 */
export const SliderLayout = ({
  axisLabel,
  showAxisLabels,
  showMinMaxLabels,
  gridPostFix = '',
  children,
}: PropsWithChildren<SliderLayoutProps>) => {
  const theme = useTheme();

  const contentStyle: CSSProperties = useMemo(() => ({
    gridArea: `main${gridPostFix}`,
    borderColor: 'transparent',
  }), [gridPostFix,]);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <>
    <TopLabeling
      style={{
        margin: 'auto',
        alignItems: 'center',
        verticalAlign: 'bottom',
        gridArea: `top-label${gridPostFix}`,
        paddingTop: theme.spacing(isMobile ? 2 : 3),
        paddingBottom: theme.spacing(1),
      }}
    >
      {showAxisLabels &&
        <HorizontalAxisLabel $showMinMax={showMinMaxLabels}>
          {axisLabel}
        </HorizontalAxisLabel>}
    </TopLabeling>

    <Content style={contentStyle}>
      <Dimensions>
        {children}
      </Dimensions>
    </Content >
  </>;
};

/**
 * A grid of component for 2d matrix question slider presentation.
 *
 *
 * |                 |     xLabel      |                 |
 * |----------------:|:---------------:|:----------------|
 * |       xMinLabel |      main       | xMaxLabel       |
 * |                 |     yLabel      |                 |
 * |       yMinLabel |     main2       | yMaxLabel       |
 */
interface SlidersGridProps {

  xLabel?: string | JSX.Element;
  yLabel?: string | JSX.Element;
  legend?: JSX.Element | null;
  /**
   * The slider component for the x axis.
   */
  sliderX: JSX.Element;
  /**
   * The slider component for the y axis.
   */
  sliderY: JSX.Element;

  showAxisLabels?: boolean;
  showMinMaxLabels?: boolean;
}

const SlidersLayout = ({
  xLabel,
  yLabel,
  legend = null,
  sliderX,
  sliderY,
  showAxisLabels,
  showMinMaxLabels,
}: SlidersGridProps): JSX.Element => {
  return (
    <SlidersContainer>
      <SliderLayout
        axisLabel={xLabel}
        showAxisLabels={showAxisLabels}
        showMinMaxLabels={showMinMaxLabels}
      >
        {sliderX}
      </SliderLayout>
      <SliderLayout
        axisLabel={yLabel}
        gridPostFix="2"
        showAxisLabels={showAxisLabels}
        showMinMaxLabels={showMinMaxLabels}
      >
        {sliderY}
      </SliderLayout>
      {legend}
    </SlidersContainer>
  );
};

export default SlidersLayout;
