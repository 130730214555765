/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */
import { AuthContext, AuthenticationStrategy } from '@mindhiveoy/react-auth';
import { getStorageValueWithExpiry } from 'utils/firebase/localStorageUtils';
import { resolveAuthStateChange } from './resolveAuthStateChange';
import { withRouter } from 'next/router';
import LoadingIndicator from 'components/common/LoadingIndicator';
import React, { useContext } from 'react';
import isEqual from 'lodash/isEqual';
import type {
  User as AavistusUser, FeatureId, ModuleId, PlanId, UserRoleId
} from '@shared/schema/src';
import type {
  AdvancedAuthState, AuthState, AuthStatus, AuthenticationPlatformAdapter, AuthenticationType
} from '@mindhiveoy/react-auth';
import type { NextRouter } from 'next/router';
import type { PhoneNumber, User, UserDisplayName } from '@mindhiveoy/schema';
import type { PropsWithChildren } from 'react';

export interface LoginProps<
  PlanId extends string,
  ModuleId extends string,
  FeatureId extends string,
  UserRoleId extends string,
  AppUser extends User<PlanId, ModuleId, UserRoleId>> {
  readonly authState: AuthState<PlanId, ModuleId, FeatureId, UserRoleId, AppUser>;
}

export type FinalizeFunction<
  PlanId extends string,
  ModuleId extends string,
  FeatureId extends string,
  UserRoleId extends string,
  AppUser extends User<PlanId, ModuleId, UserRoleId>> = (
    context: AuthState<PlanId, ModuleId, FeatureId, UserRoleId, AppUser>,
  ) => void;

export interface AuthProviderProps<
  PlanId extends string,
  ModuleId extends string,
  FeatureId extends string,
  UserRoleId extends string,
  AppUser extends User<PlanId, ModuleId, UserRoleId>,
> {
  /**
   * The sign in screen component used by the application. Sign In screen component should contain all
   * signing, registration and other operations like forgot password logic.
   */
  signIn: React.ReactElement<LoginProps<PlanId, ModuleId, FeatureId, UserRoleId, AppUser>>;

  /**
   * The platform specific adapter for authentication.
   */
  adapter: AuthenticationPlatformAdapter<PlanId, ModuleId, FeatureId, UserRoleId, AppUser>;

  /**
   * Authentication strategy being used in the app. The default value is AuthenticationStrategy.IMPLICIT
   * which will implicitly prompt for login, when ever rendering will enter to an component requiring the
   * authentication. If you wish authentication related parts of te ui just be omitted, when not authenticated,
   * please change this to AuthenticationStrategy.EXPLICIT.
   */
  strategy?: AuthenticationStrategy;

  /**
   * A reference to a global state to be used with maintain the app state in the whole application. Useful for
   * example with NextJs to state _app state for different pages.
   */
  state?: any;

  /**
   * Initial value for authInProgress. If we want to show a loading indicator, while the authentication is
   * in progress, this should be set to true. It is then updated to false when the user is authenticated and
   * the data is fetched from Firestore (or otherwise if user is anonymous).
   * Updating this value should be done from the adapter.
   */
  authInProgress?: boolean;

  onInitUserState?: () => AppUser;
}

export type SigningMethod = 'sign-in' | 'sign-up' | 'none';

export interface AuthenticationState<
  PlanId extends string,
  ModuleId extends string,
  FeatureId extends string,
  UserRoleId extends string,
  AppUser extends User<PlanId, ModuleId, UserRoleId>> extends AdvancedAuthState<PlanId, ModuleId, FeatureId, UserRoleId, AppUser> {
  signingMethod: SigningMethod;

  openSignInDialog: () => void;

  openSignUpDialog: (planId?: PlanId) => void;

  closeSignInDialog: () => void;
}

/**
 * Hook to get the authentication state from the context. This will include extra methods to
 * for handling the sign in and sign up dialogs. You can use it as replacement for the useAuth
 * hook, if you need to handle the sign in and sign up dialogs.
 *
 * @returns Authentication state
 */
export const useAuthentication = () => useContext(AuthContext) as AuthenticationState<PlanId, ModuleId, FeatureId, UserRoleId, AavistusUser<any>>;

export interface InternalState<
  PlanId extends string,
  ModuleId extends string,
  FeatureId extends string,
  UserRoleId extends string,
  AppUser extends User<PlanId, ModuleId, UserRoleId>>
  extends AuthenticationState<PlanId, ModuleId, FeatureId, UserRoleId, AppUser> {

  /**
   * @deprecated use status instead
   */
  authInProgress?: boolean;

  finalizeFunction?: FinalizeFunction<PlanId, ModuleId, FeatureId, UserRoleId, AppUser>;
}

// eslint-disable-next-line valid-jsdoc
/**
 * AuthProvider to attach the authentication support for the project. This must be a parent
 * component of any authentication or user role related components. All auth related components
 * must be used in child components of the component, where AuthProvider itself is being introduced.
 *
 */
class AuthProvider<
  PlanId extends string,
  ModuleId extends string,
  FeatureId extends string,
  UserRoleId extends string,
  AppUser extends User<PlanId, ModuleId, UserRoleId>,
> extends React.Component<
  PropsWithChildren<AuthProviderProps<PlanId, ModuleId, FeatureId, UserRoleId, AppUser>> & { router: NextRouter; },
  InternalState<PlanId, ModuleId, FeatureId, UserRoleId, AppUser>
> {
  private signInAcquired = false;

  constructor(props: AuthProviderProps<PlanId, ModuleId, FeatureId, UserRoleId, AppUser>) {
    super(props as any);

    this.state = props.state ?? {};

    // Read the auth status from the local storage. If not found, set it to 'initializing'.
    // The auth status is used to determine the current state of the authentication process.
    // This is needed to determine if the user is in the middle of the authentication process
    const status = getStorageValueWithExpiry<AuthStatus>('authStatus') ?? 'initializing';

    this.state = {
      ...this.state,
      status,
      authStrategy: props.strategy ?? AuthenticationStrategy.IMPLICIT,
      user: props.onInitUserState ? props.onInitUserState() : null,
      authInProgress: props.authInProgress ?? false,
      signingMethod: 'none',
      acquireAuthentication: this.acquireAuthentication,
      refreshWebToken: this.refreshWebToken,
      resetPassword: this.resetPassword,
      signInWIthCredentials: this.signInWIthCredentials,
      signInWithEmail: this.signInWithEmail,
      signUpWithEmail: this.signUpWithEmail,
      signOut: this.signOut,
      updateUserState: this.updateUserState,
      updateState: this.updateState,
      openSignInDialog: this.openSignInDialog,
      openSignUpDialog: this.openSignUpDialog,
      closeSignInDialog: this.closeSignInDialog,
    };
  }

  public componentDidMount() {
    const initialize = this.props.adapter?.onInitialize;

    if (initialize) {
      const doInitialize = async () => {
        const finalizeFunction = await initialize(this.state);

        this.setState({
          finalizeFunction,
        });
      };
      doInitialize();
    }
  }

  public componentWillUnmount() {
    this.state.finalizeFunction && this.state.finalizeFunction(this.state);
  }

  public render() {
    const {
      children = null,
      signIn, // TODO default signIn and SignUp -components?
    } = this.props;

    if (!signIn) {
      return <div>Internal error: The AuthProvider require loginComponent to be defined.</div>;
    }

    switch (this.state.status) {
      // return <LoadingIndicator
      // loaderTitle="Initializing..."
      // fullScreen />;
      case 'signing-out':
        return <LoadingIndicator
          loaderTitle="Signing out..."
          fullScreen />;

      case 'signing-in':
      case 'signing-up':
      case 'initializing':
      case 'error':
      default:
        if (!this.state.user && this.signInAcquired) {
          const SignIn = signIn as any;
          return <SignIn authState={this.state} />;
        }
        return <AuthContext.Provider value={this.state}>{children}</AuthContext.Provider>;
    }
  }

  /*
   * Following methods acts as bridges between react code and the adapter implementation.
   */
  private signInWithEmail = async (email: string, password: string) => {
    return this.props.adapter.signInWithEmail(this.state, email, password);
  };

  private signInWIthCredentials = async (authenticationType: AuthenticationType, credentials?: any) => {
    return this.props.adapter.signInWIthCredentials(this.state, authenticationType, credentials);
  };

  private signUpWithEmail = async (email: string, password: string, displayName: UserDisplayName, phoneNumber?: PhoneNumber) => {
    return this.props.adapter.signUpWithEmail(this.state, email, password, displayName, phoneNumber);
  };

  private signOut = async () => {
    return this.props.adapter.signOut(this.state);
  };

  private resetPassword = async () => {
    return this.props.adapter.resetPassword(this.state);
  };

  private refreshWebToken = async () => {
    return this.props.adapter.refreshWebToken(this.state);
  };

  private acquireAuthentication = (explicit?: boolean) => {
    switch (this.state.authStrategy) {
      case AuthenticationStrategy.EXPLICIT:
        if (explicit) {
          this.setState({
            authenticationActivated: true,
          });
        }
        return;

      case AuthenticationStrategy.IMPLICIT:
        this.signInAcquired = true;
        return;

      default:
        throw new Error(`Unsupported authentication strategy: ${this.state.authStrategy}`);
    }
  };

  private updateState = (newState: Partial<InternalState<PlanId, ModuleId, FeatureId, UserRoleId, AppUser>>):
    Partial<InternalState<PlanId, ModuleId, FeatureId, UserRoleId, AppUser>> => {
    const result = resolveAuthStateChange(this.state as any, newState as any);

    if (!isEqual(result, this.state)) {
      this.setState(result as unknown as any);
    }
    return this.state;
  };

  private updateUserState = (user: Partial<AppUser> | undefined) => {
    console.log('updateUserState:', {
      user,
      state: this.state,
    });

    this.updateState({
      user: user ? ({
        ...this.state.user,
        ...user,
      }) as any : null,
    });
  };

  private openSignInDialog = () => {
    this.setState({
      signingMethod: 'sign-in',
    });
  };

  private openSignUpDialog = (planId?: PlanId) => {
    this.setState({
      signingMethod: 'sign-up',
      planId,
    });

    // Make shallow routing to keep the query params in the url using NextJs and include the plan id to query params
    // for the sign up page.
    const query = {
      ...this.props.router.query, plan: planId,
    };
    this.props.router.push({
      pathname: '/sign-up',
      query,
    }, undefined, {
      shallow: true,
    });
  };

  private closeSignInDialog = () => {
    this.setState({
      signingMethod: 'none',
    });
  };
}

export default withRouter(AuthProvider);
