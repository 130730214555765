
import { motion } from 'framer-motion';
import { quadrantDisplayLabels } from 'components/session/SessionEditor/RestrictedSessionEditor/SessionEditor/modes/facilitate/model/filters/QuadrantFilter';
import { useCallback } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import type { Quadrant } from '@shared/schema/src';

export const COLOR_SELECTED_HOVER_PRIMARY = 'rgba(75,194,250,0.7)';
export const BUTTON_HOVER_GESTURE = {
  scale: 1.05,
};
export const BUTTON_TAP_GESTURE = {
  scale: 0.9,
};
export const BUTTON_CONTAINER_TAP_GESTURE = {
  backgroundColor: 'rgba(255, 255, 0, 0.2)',
};

interface QuadrantButtonProps {
  quadrant: Quadrant;
  selected: boolean;
  onToggle: (quadrant: Quadrant) => void;
}
export const QuadrantButton = ({
  quadrant,
  selected,
  onToggle,
}: QuadrantButtonProps) => {
  const theme = useTheme();

  const handleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    onToggle?.(quadrant);
  }, [quadrant, onToggle,]);

  return <QuadrantContainer
    whileHover={{
      backgroundColor: selected ? COLOR_SELECTED_HOVER_PRIMARY : theme.palette.background.innerPaper,
    }}
    whileTap={BUTTON_CONTAINER_TAP_GESTURE}
    style={{
      backgroundColor: selected ? theme.palette.primary.main : 'transparent',
    }}
    onClick={handleClick}
  >
    {/* Dummy and InnerElement are used to make button to be a square. */}
    <Dummy />
    <InnerElement
      whileHover={BUTTON_HOVER_GESTURE}
      whileTap={BUTTON_TAP_GESTURE}
    >
      {quadrantDisplayLabels[quadrant]}
    </InnerElement>
  </QuadrantContainer>;
};
const Dummy = styled.div`
  margin-top: calc(100% - 1rem);
`;
const QuadrantContainer = styled$(motion.div)`
  border: 1px solid white;
  align-items: center;
  justify-content: center;
  display: inline-block;
  position: relative;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
`;

const InnerElement = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: 1.5rem;
  color: white;
`;
