/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */
import { AnalyzeMatrix2D } from 'components/canvas/graphs/Matrix2DCanvasGraph/AnalyzeMatrix2D';
import { smartUpdate } from '@shared/schema/src/utils/optimistic/smartUpdate';
import { useAnswer } from '../../../../bloc/answer/AnswerBloc';
import { useAuth } from '@mindhiveoy/react-auth';
import { useCanvasRenderingContext } from 'utils/hooks/useCanvasRenderingContext';
import Center from 'components/common/layout/Center';
import LoadingIndicator from 'components/common/LoadingIndicator';
import Matrix2DInput from './Matrix2DInput';
import Matrix2DLayout from './layouts/Matrix2DLayout';
import React, { useCallback, useEffect, useState } from 'react';
import ShowAnswer from 'components/canvas/components/ShowAnswer';
import Slider1D from '../../components/Slider1D';
import SlidersLayout from './layouts/SlidersLayout';
import isUndefined from 'lodash/isUndefined';
import styled from '@emotion/styled';
import theme from '../../../../theme';
import usePathParams from 'utils/hooks/usePathParams';
import type {
  Answer, Coord2D, Matrix2DQuestionCanvas, Scale2DAnswer, Session
} from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export const DEFAULT_GRAPH2D_SIZE: Size = {
  width: 400,
  height: 400,
};

export type EmptyGraphCornerPosition = 'ruc' | 'rlc' | 'llc' | 'luc';

export const EmptyGraphCorner = styled.div<{ position: EmptyGraphCornerPosition; }>(({
  position,
}) => `
  grid-area: ${position};
  display: flex;
`);

export interface Size {
  width: number;
  height: number;
}

export interface Canvas2DMatrixProps {
  session?: WithId<Session>;
  canvas: WithId<Matrix2DQuestionCanvas>;
  size?: Size;
  graphOnly?: boolean;
  answer?: Scale2DAnswer;
  graphBackgroundColor?: string;
}

const Canvas2DMatrix = (props: Canvas2DMatrixProps) => {
  if (!props.canvas) {
    return <LoadingIndicator />;
  }
  return <Canvas2DMatrixInstance {...props} />;
};

const Canvas2DMatrixInstance = ({
  session,
  canvas,
  size,
  graphBackgroundColor = theme.palette.background.graph,
}: Canvas2DMatrixProps) => {
  const {
    user,
    authenticated,
    memberId,
  } = useAuth(); // TODO: Move these outside of the component to make these pure

  const {
    isInteractive,
    isPresentation,
  } = useCanvasRenderingContext();// TODO: Move these outside of the component to make these pure

  const answerId = authenticated ? memberId : user?.uid;

  const canvasId = canvas._id;

  const pathParams = usePathParams({
    canvasId,
  });
  const [answer, setAnswer,] = useState<Coord2D | null>(null);

  const answerBloc = useAnswer({
    ...pathParams,
    answerId,
  });

  useEffect(
    () => answerBloc?.subscribe(
      (answer?: WithId<Answer, string>) => {
        setAnswer((currentAnswer) => {
          const value = smartUpdate(currentAnswer, answer?.value as Coord2D);
          return value ? value as Coord2D : null;
        });
      }, (error) => console.error(error))
    , [answerBloc, answerId,]);

  const handleSetNewValue = useCallback(async (value: Coord2D) => {
    setAnswer(value as Coord2D);
    if (!(isUndefined(value?.x) || isUndefined(value?.y))) {
      try {
        await answerBloc.set(
          answerId as any,
          {
            type: 'scale2d',
            memberId: answerId as any,
            value,
          });
      } catch (e) {
        // TODO: Sentry
        console.error('Error setting new answer value: ', e);
      }
    }
  }, [answerBloc, answerId,]);

  const handleClearAnswer = useCallback(async () => {
    try {
      setAnswer(null);
      answerBloc.erase();
    } catch (e) {
      console.error('Error clearing answer: ', e);
    }
  }, [answerBloc,]);

  const handleXChange = useCallback((x: number) => {
    setAnswer((value: any) => {
      const newValue = {
        ...value,
        x,
      };
      if (newValue.x !== undefined && newValue.y !== undefined) {
        handleSetNewValue(newValue);
      }
      return newValue;
    });
  }, [handleSetNewValue,]);

  const handleYChange = useCallback((y: number) => {
    setAnswer((value: any) => {
      const newValue = {
        ...value,
        y,
      };
      if (newValue.x !== undefined && newValue.y !== undefined) {
        handleSetNewValue(newValue);
      }
      return newValue;
    });
  }, [handleSetNewValue,]);

  const max = canvas?.axis?.x?.max;

  if (isPresentation) {
    return <Center>
      <AnalyzeMatrix2D
        session={session}
        canvas={canvas as any}
      />
    </Center>;
  }
  return <>
    {canvas.interaction === 'sliders' && <SlidersLayout
      key="graph"
      xLabel={canvas?.axis?.x?.mainLabel}
      yLabel={canvas?.axis?.y?.mainLabel}
      showAxisLabels={canvas.showAxisLabels}
      showMinMaxLabels={canvas.showMinMaxLabels}
      sliderX={
        <Slider1D
          value={answer?.x}
          max={max}
          layoutId="slider-x"
          mode={canvas?.mode}
          axis={canvas.axis.x}
          minLabel={canvas?.axis?.x?.minLabel}
          maxLabel={canvas?.axis?.x?.maxLabel}
          showMinMax={canvas.showMinMaxLabels}
          showTicks={canvas.showTickLabels}
          firstSlider
          otherPartAnswered={answer?.y !== undefined}
          onChange={handleXChange}
        />
      }
      sliderY={
        <Slider1D
          value={answer?.y}
          max={max}
          layoutId="slider-y"
          mode={canvas?.mode}
          minLabel={canvas?.axis?.y?.minLabel}
          maxLabel={canvas?.axis?.y?.maxLabel}
          showMinMax={canvas.showMinMaxLabels}
          axis={canvas.axis.y}
          showTicks={canvas.showTickLabels}
          otherPartAnswered={answer?.x !== undefined}
          onChange={handleYChange}
        />}
    />}
    {canvas.interaction === 'graphic' &&
      <Matrix2DLayout
        key="graph"
        canvas={canvas as any}
      >
        <Matrix2DInput graphBackgroundColor={graphBackgroundColor}
          answer={answer as any}
          axis={canvas.axis}
          analyzeOptions={canvas.analyzeOptions}
          size={size}
          min={canvas.axis?.min}
          max={canvas.axis?.max}
          canvasMode={canvas.mode}
          canvasAnswerVisibility={canvas.config?.answerVisibility ?? 'inherited'}
          sessionAnswerVisibility={session?.config?.answerVisibility}
          onChange={handleSetNewValue}
        />
      </Matrix2DLayout>
    }

    {isInteractive && <ShowAnswer
      hasAnswer={isValidAnswer(answer)}
      onClear={handleClearAnswer}
    />}
  </>;
};

export const isValidAnswer = (answer?: Coord2D | null): boolean => {
  if (!answer) {
    return false;
  }
  return !(isUndefined(answer?.x) || isUndefined(answer?.y));
};

export default Canvas2DMatrix;
