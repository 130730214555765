import styled from '@emotion/styled';

export const FieldSet = styled.fieldset(({
  theme,
}) => `
  border-radius: ${theme.shape.borderRadius}px;
  border: 0.5px solid ${theme.palette.divider};
  margin-bottom: ${theme.spacing(2)};
  box-sizing: border-box;
`);
