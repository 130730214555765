
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { PropertyEditorFunction } from '../../../builders/propertyEditorBuilder';

import { COLOR_PALETTE } from '../../lookAndFeel/ColorPickerEditor';
import { CirclePicker } from 'react-color';
import { FieldSet } from 'components/forms/FieldSet';
import { Legend } from 'components/forms/Legend';
import { Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useTheme } from '@emotion/react';
import AnimateHeight from 'components/common/AnimateHeight';
import PropertyEditorControl from '../../PropertyEditorControl';
import cloneDeep from 'lodash/cloneDeep';
import styled$ from 'utils/react/styled$';
import type { AxisColorPickerPropertyEditor } from '../../PropertyEditorTypes';
import type { AxisColors } from '@shared/schema/src';
import type { ColorResult } from 'react-color';

const keys: (keyof AxisColors)[] = [
  'min', 'max',
];

const CirclePickerHack = CirclePicker as any;

const Grid = styled$.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const AxisColorPickerEditor: PropertyEditorFunction<AxisColors, AxisColorPickerPropertyEditor> = ({
  data,
  propertyName,
  propertyConfig: {
    displayName,
    defaultValue,
    section,
  },
  onSave,
}) => {
  const [value, setValue,] = useState<AxisColors>(data ?? defaultValue);

  const [target, setTarget,] = useState<keyof AxisColors>();

  const theme = useTheme();

  const [open, setOpen,] = useState(false);

  useEffect(() => {
    if (data && data !== value) {
      setValue(data);
    }
  }, [data, value,]);

  const handleChange = useCallback(
    (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
      if (!target) {
        return;
      }
      const newColor = color.hex;

      const newValue = cloneDeep(typeof value === 'string' ? {} : value) as AxisColors;
      newValue[target] = newColor;

      setValue(newValue);
      if (newColor !== target && onSave) {
        onSave(propertyName, newValue);
      }
      event.stopPropagation();
      setOpen(false);
      setTarget(undefined);
    }, [onSave, propertyName, target, value,]);

  const id = useMemo(() => `editor-${propertyName}`,
    [propertyName,]
  );

  const handleOpenPicker = useCallback((key: keyof AxisColors) => () => {
    if (target === key) {
      setOpen(false);
      setTarget(undefined);
      return;
    }
    setTarget(key);
    setOpen(true);
  }, [target,]);

  return <>
    {section &&
      <>
        <Typography component={'span'}
          variant="h4"
          id={`${id}-label`}>{section}
        </Typography>
        <br />
      </>
    }<PropertyEditorControl>
      <FieldSet>
        <Legend>{displayName}</Legend>
        <Grid >
          {
            keys.map((key) => {
              return <motion.button
                key={key}
                style={{
                  width: '100%',
                  marginTop: 0,
                  height: '3rem',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor: target === key ? '#fff' : theme.palette.background.default,
                  borderTopLeftRadius: key === 'min' ? theme?.shape?.borderRadius : undefined,
                  borderBottomLeftRadius: key === 'min' ? theme?.shape?.borderRadius : undefined,
                  borderTopRightRadius: key === 'max' ? theme?.shape?.borderRadius : undefined,
                  borderBottomRightRadius: key === 'max' ? theme?.shape?.borderRadius : undefined,
                }}
                animate={{
                  backgroundColor: value[key],
                }}
                onClick={handleOpenPicker(key)}
              />;
            })
          }
        </Grid>

        <AnimateHeight visible={open && !!target}>
          <motion.div
            key="picker"
            style={{
              paddingTop: 18,
              overflow: 'hidden',
            }}
            initial={{
              maxHeight: 0,
            }}
            animate={{
              maxHeight: 'inherit',
            }}
            exit={{
              maxHeight: 0,
            }}
          >
            <CirclePickerHack
              colors={COLOR_PALETTE}
              width="100%"
              color={target ? value[target] : undefined}
              onChangeComplete={handleChange}
            />
          </motion.div>

        </AnimateHeight>
      </FieldSet>
    </PropertyEditorControl>
  </>;
};

AxisColorPickerEditor.config = {
  type: 'axis-color-picker',
};

export default AxisColorPickerEditor;
