import { AddWidgetButton } from '../builder/widgets/CanvasEditor';
import { useComponentDataContext } from 'utils/hooks/useComponentDataContext';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import Canvas2DMatrixEditor from './editors/Matrix2DCanvasEditor';
import CanvasContentEditor from './editors/TextQuestionCanvas';
import CanvasCreator from '../session/SessionEditor/RestrictedSessionEditor/SessionEditor/modes/edit/CanvasCreator';
import CategoryCanvasEditor from './editors/CategoryCanvasEditor';
import LayoutCanvasEditor from './editors/LayoutCanvasEditor';
import React, { useCallback, useEffect, useState } from 'react';
import SliderCanvasEditor from './editors/SliderCanvasEditor';
import styled from '@emotion/styled';
import type { AnswerVisibility, Canvas, WithPathParamProps } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export type CanvasEditorProps = WithPathParamProps;

const StyledButton = styled(Button)(({
  theme,
}) => `
  width: 100%;
  height: 100%;
  align-self: flex-end;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgba(255,255,255,0.1);
  &:hover {
    background-color: rgba(255,255,255,0.1);
    > div {
    transition: all .3s ease-in-out;
      background-color: ${theme.palette.background.lightInnerPaper}80;
    }
  }
`);

const ButtonContent = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 10%;
  background-color: transparent;
  border-radius: ${theme.shape.borderRadius}px;
  gap: ${theme.spacing(1.5)};
  padding: ${theme.spacing(1, 3)};
  transition: all .3s ease-in-out;
`);

interface CanvasEditorContainerProps extends CanvasEditorProps {
  sessionAnswerVisibility?: AnswerVisibility;
}

const CanvasEditorContainer = ({
  params,
  sessionAnswerVisibility,
}: CanvasEditorContainerProps) => {
  const {
    model,
  } = useComponentDataContext();

  const {
    t,
  } = useTranslation();

  const [canvas, setCanvas,] = useState<WithId<Canvas>>(
    () => model.component.data as unknown as WithId<Canvas>
  );

  const [open, setOpen,] = useState(false);

  useEffect(() => model.subscribeToDataChange(({
    newValue,
  }) => setCanvas(newValue?.data as any ?? null)
  ), [model,]);

  const handleNewPageClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleNewPageCloseClick = useCallback(() => {
    setOpen(false);
  }, []);

  if (!canvas) {
    return null;
  }
  switch (canvas.type) {
    case '2d':
      return <Canvas2DMatrixEditor
        canvasId={canvas._id}
        params={params}
        canvas={canvas}
        sessionAnswerVisibility={sessionAnswerVisibility}
      />;

    case 'category':
      return <CategoryCanvasEditor
        canvasId={canvas._id}
        canvas={canvas}
        params={params}
      />;

    case '1d':
      return <SliderCanvasEditor
        canvasId={canvas._id}
        params={params}
        canvas={canvas}
      />;

    case 'text':
      return <CanvasContentEditor
        canvasId={canvas._id}
        canvas={canvas} />;

    case 'layout':
      return <LayoutCanvasEditor
        canvasId={canvas._id}
        params={params}
        canvas={canvas}
      />;

    default:
      return <>
        <StyledButton
          onClick={handleNewPageClick} >
          <ButtonContent>
            <AddWidgetButton />
            <span className='text'>{t('click-to-add-a-new-canvas')}</span>
          </ButtonContent>
        </StyledButton>
        <CanvasCreator open={open}
          onClose={handleNewPageCloseClick} />
      </>;
  }
};

export default CanvasEditorContainer;
