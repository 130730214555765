
import { ADMIN_BUNDLE } from '@shared/schema/src/https/bundles';
import { createBundleFunctionFactory } from 'utils/firebase/bundles/createBundleFunctionFactory';
import type { CreateProjectMessage, UpdateProjectMessage } from '@shared/schema/src';
import type { ModifyScreensMessage } from '@shared/schema/src/https/project/modifyScreens';
import type { UpdateProjectMemberAlterEgoMessage } from '@shared/schema/src/https/project/updateProjectMemberAlterEgo';

const factory = createBundleFunctionFactory(ADMIN_BUNDLE);

export const updateProject = factory<UpdateProjectMessage>(
  'update-project'
);

export const updateProjectMemberAlterEgo = factory<UpdateProjectMemberAlterEgoMessage>(
  'update-project-member-alter-ego'
);

export const createProject = factory<CreateProjectMessage>(
  'create-project'
);

export const modifyScreen = factory<ModifyScreensMessage>(
  'modify-screens'
);
