import { Fab } from '@mui/material';
import { startTransition, useCallback } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import styled from '@emotion/styled';

const StyledFab = styled(Fab)`
  position: fixed;
  bottom: 4em;
  right: 4em;
  z-index: 1000;
`;

export interface MessageFABProps {
  onClick?: () => void;
}

const MessageFAB = ({
  onClick,
}: MessageFABProps
) => {
  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      startTransition(() => {
        onClick?.();
      });
    }, [onClick,]
  );

  return <StyledFab size='large'
    onClick={handleClick}
    color="primary"
    aria-label="new-message"
    data-cc="c-message">
    <MailOutlineIcon fontSize='large' />
  </StyledFab>;
};
export default MessageFAB;
