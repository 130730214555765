import { BpIcon } from './BpIcon';
import styled from '@emotion/styled';

export const BpCheckedIcon = styled(BpIcon)({
  'backgroundColor': '#137cbd',
  'backgroundImage': 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});
