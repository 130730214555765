/* eslint-disable @typescript-eslint/no-explicit-any */

import { cloneDeep } from 'lodash';
import { extractPropsFromConfig, mapPropsToEntries, resolveComponentProps } from '../propertyEditors/utils/propertyUtils';
import { getObjectField, isDevelopmentMode } from '@mindhiveoy/foundation';
import { useCallback, useEffect, useRef, useState } from 'react';
import InspectorRow from './InspectorRow';
import React from 'react';
import propertyEditorBuilder from '../builders/propertyEditorBuilder';
import type { ComponentSelectionModel as SelectionModel } from '../models/selectionModels';

import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';
import type { FormErrors } from 'components/forms/validatePropertyForm';
import type { PropertyChangeDescriptor, PropertyDeleteFunction, PropertyEditor } from '../propertyEditors/PropertyEditorTypes';
import type { PropertySaveFunction } from '../propertyEditors/PropertyEditorTypes';

const StyledPaper = styled.div(({
  theme,
}) => `
  width: 100%;
  margin-top: 8px;
  padding: ${theme.spacing(2, 0)};  
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`);

interface InspectorProps<T extends object = any> {
  category?: string;
  disabled?: boolean;
  errors?: FormErrors;
  selection: SelectionModel<T>;
  onPressEnter?: () => void;
}

/**
 * Inspector will generate a custom editors for given selection context.
 *
 * @param {InspectorProps} props
 * @return {JSX.Element} Inspector component instance
 */
const Inspector = <T extends object = any>({
  category,
  disabled,
  errors,
  selection,
  onPressEnter,
}: InspectorProps<T>): JSX.Element => {
  const [data, setData,] = useState(() => selection?.data() ?? {});

  const focusedProperty = React.useRef<string | null>(null);

  const initialLoad = useRef(true);

  const handleDelete: PropertyDeleteFunction = useCallback((propertyName, cascadingChanges) => {
    if (!selection) {
      return;
    }
    const config = selection?.config();
    if (!config) {
      return;
    }

    const {
      absoluteCascades,
      relativeCascades,
    } = extractCascades(cascadingChanges);

    let newData = selection.deleteProperty(propertyName, ...relativeCascades);
    if (absoluteCascades.length > 0) {
      const propertyName = absoluteCascades.pop();
      const value = absoluteCascades.pop();
      newData = selection.deleteGlobalProperty(propertyName, value, ...absoluteCascades);
    }

    if (config) {
      const props = extractPropsFromConfig(config, newData);

      const prop = props?.[propertyName];
      if (prop?.onPropertyChange) {
        const propertyValue = getObjectField(newData, propertyName);
        prop.onPropertyChange({
          data: newData,
          component: newData,
          propertyName,
          propertyValue,
          updateProperty: (name: string, val: any) => selection.updateProperty(name, val),
          updateComponentProperty: (name: string, val: any) => selection.updateGlobalProperty(name, val),
        });
      }
    }
  }, [selection,]);

  const handleSave: PropertySaveFunction = useCallback((propertyName, value, cascadingChanges) => {
    if (!selection) {
      return;
    }
    const config = selection?.config();
    if (!config) {
      return;
    }

    const {
      absoluteCascades,
      relativeCascades,
    } = extractCascades(cascadingChanges);

    let newData = selection.updateProperty(propertyName, value, ...relativeCascades);
    if (absoluteCascades.length > 0) {
      const propertyName = absoluteCascades.pop();
      const value = absoluteCascades.pop();
      newData = selection.updateGlobalProperty(propertyName, value, ...absoluteCascades);
    }

    if (config) {
      const props = extractPropsFromConfig(config, newData);

      const prop = props?.[propertyName];
      if (prop?.onPropertyChange) {
        const propertyValue = getObjectField(newData, propertyName);
        prop.onPropertyChange({
          data: newData,
          component: newData,
          propertyName,
          propertyValue,
          updateProperty: (name: string, val: any) => selection.updateProperty(name, val),
          updateComponentProperty: (name: string, val: any) => selection.updateGlobalProperty(name, val),
        });
      }
    }
  }, [selection,]);

  useEffect(() => {
    initialLoad.current = true;
    const onSelectionDataChange = (data: any
      // sourcePropertyName?: string
    ) => {
      focusedProperty.current = null;
      // TODO Find out possible conflicts with data changes
      setData(data);
      // setSourcePropertyName(sourcePropertyName);
    };
    if (selection) {
      setData(selection.data());
      return selection.subscribeForDataChanges(onSelectionDataChange);
    }
  }, [selection,]);

  const handleFocus = useCallback((propertyName: string) => {
    focusedProperty.current = propertyName;
  }, []);

  const handleBlur = useCallback((propertyName: string) => {
    if (focusedProperty.current === propertyName) {
      focusedProperty.current = null;
    }
  }, []);

  // TODO: Change to use memo
  const renderEditors = useCallback(() => {
    const config = selection?.config();
    if (!config) {
      return isDevelopmentMode() ? 'Inspector is missing config setup.' : null;
    }
    const selectedData = Object.freeze(cloneDeep(selection.data()));

    const props = resolveComponentProps(config, selectedData, category); // TODO typing

    const editors: JSX.Element[] = [];

    const entries = mapPropsToEntries(props);

    let rowSum = 0;
    let parent: any;
    let rowElements: JSX.Element[] = [];

    for (const [propertyName, propertyConfig,] of entries) {
      if (!propertyConfig) {
        return null;
      }
      const type = (propertyConfig as PropertyEditor)?.type;
      const Editor = propertyEditorBuilder.get(type);
      if (!Editor || !selectedData) {
        editors.push(
          <InspectorRow key={propertyName} >
            <div key={propertyName}>
              Internal error: No editor found for type: <b>{type}</b> with property <b>{propertyName}</b>
            </div>
          </InspectorRow>
        );
        continue;
      }
      const xs = propertyConfig?.xs;
      if (xs) {
        if (!parent) {
          parent = <Grid container
            spacing={2}
            paddingLeft={2}
            paddingTop={1}
            // marginBottom={-1}
            key={`row_${propertyName}`} >
            {rowElements}
          </Grid>;
          editors.push(parent);
        }
        const propertyData = data ? (data as any)[propertyName] as any : undefined;
        // Autofocus can happen only once
        if (propertyData) {
          // TODO: Fix this
          propertyData.autoFocus = initialLoad && propertyConfig.autoFocus;
        }

        rowElements.push(
          <Grid
            key={propertyName}
            xs={xs}
            paddingLeft={rowSum === 0 ? 0 : 2}
            marginTop={1}
          >
            <Editor
              component={selectedData}
              disabled={disabled}
              propertyName={propertyName}
              propertyNamePath={propertyName}
              propertyConfig={propertyConfig as PropertyEditor}
              data={propertyData}
              onSave={handleSave}
              onDelete={handleDelete}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onPressEnter={onPressEnter}
            />
            {
              errors?.[propertyName] ? <div style={{
                color: 'red',
              }}>
                {errors[propertyName]}
              </div> : null
            }
          </Grid>);

        rowSum += xs;
        if (rowSum >= 12) {
          rowSum = 0;
          parent = undefined;
          rowElements = [];
        }
        continue;
      } else {
        rowSum = 0;
        parent = undefined;
        rowElements = [];
      }

      editors.push(
        <InspectorRow key={propertyName} >
          <Editor
            component={selectedData}
            disabled={disabled}
            propertyName={propertyName}
            propertyNamePath={propertyName}
            propertyConfig={propertyConfig as PropertyEditor}
            data={data ? (data as any)[propertyName] as any : undefined}
            onSave={handleSave}
            onDelete={handleDelete}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onPressEnter={onPressEnter}
          />
        </InspectorRow>
      );
    }
    initialLoad.current = false;
    return editors;
  }, [selection, category, disabled, data, handleSave, handleDelete, handleFocus, handleBlur, onPressEnter, errors,]);

  return <StyledPaper>
    {selection && renderEditors()}
  </StyledPaper>;
};

const extractCascades = (cascadingChanges?: PropertyChangeDescriptor[]) => {
  const relativeCascades = [];
  const absoluteCascades = [];
  for (const {
    propertyName,
    value,
    type,
  } of cascadingChanges ?? []) {
    switch (type) {
      case 'absolute':
        absoluteCascades.push(value, propertyName);
        break;
      case 'relative':
        relativeCascades.push(value, propertyName);
        break;
      default:
        throw new Error(`Unknown cascade type ${type}`);
    }
  }
  return {
    absoluteCascades,
    relativeCascades,
  };
};
export default Inspector;
