import { Button } from '@mui/material';
import { MessageArea, StyleFeedItem, TopRow } from '.';
import { MessageAttachmentType } from '@shared/schema/src';
import { StyledAvatar } from '../../comments/CommentItem';
import { extractDateToMilliseconds, renderMoment } from 'utils/timeUtils';
import { resolveEditorMode } from '../../../utils/urls';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';
import StatusChip from '../../../components/info/StatusChip';
import Typography from '@mui/material/Typography';
import type { ProcessedMessage, ReceivedMessage, SentMessage, ShortUserRoleId } from '@shared/schema/src';

interface ItemProps {
  item: ProcessedMessage;
  role: string;
  sentMode?: boolean;
}
export const Item = ({
  item, role, sentMode,
}: ItemProps) => {
  const router = useRouter();

  const {
    t,
  } = useTranslation();

  // TODO: :use the same item with Project and Notification feed
  const mode = resolveEditorMode(router?.query);

  const link = useMemo(() => {
    let result = `/${item?.path}`;
    // When facilitator is on dashboard and clicks an item from drawer,
    // we will assume that he or she wants to facilitate.
    if (mode && role === 'f') {
      result += `?facilitate`;
    }
    return result;
  }, [item?.path, mode, role,]);

  const [expanded, setExpanded,] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const getButtonText = useCallback(() => {
    switch (item.attachmentType) {
      case MessageAttachmentType.COMMENT:
        return t('open-the-comment');
      case MessageAttachmentType.CANVAS:
        return t('open-the-question');
      case MessageAttachmentType.SESSION:
        return t('open-in-the-session');
    }
  }, [item.attachmentType, t,]);

  const handleButtonClick = useCallback(() => {
    router.push(link, link, {
      shallow: true,
    });
  }, [link, router,]);

  return <StyleFeedItem onClick={handleExpand}>
    <TopRow>
      {!sentMode ?
        <StyledAvatar alt="Facilitator"
          src={(item as ReceivedMessage)?.sender?.avatar} /> :
        <StyledAvatar alt="Panelist"
          src={(item as SentMessage)?.recipients?.length ? (item as SentMessage)?.recipients[0]?.avatar : ''} />
      }
      {!sentMode ?
        <div style={{
          marginLeft: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Typography variant="subtitle2"
            component="span">
            @{(item as ReceivedMessage)?.sender?.nick}
          </Typography>
          <Typography variant="caption"
            component="span">
            {(item as ReceivedMessage)?.sender?.role === 'facilitator' ||
            ((item as ReceivedMessage)?.sender?.role as ShortUserRoleId) === 'f' && <StatusChip status='facilitator' />}
          </Typography>
        </div> : <div style={{
          marginLeft: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Typography variant="subtitle2"
            component="span">
            @{(item as SentMessage)?.recipientsString}
          </Typography>
        </div>
      }
      <div style={{
        marginLeft: 'auto',
      }}>
        <Typography variant="caption"
          component="span">
          {renderMoment(extractDateToMilliseconds(item.date))}
        </Typography>
      </div>
    </TopRow>
    <Typography variant="h6"
      component="h2"
      style={{
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: expanded ? 'unset' : 1,
        WebkitBoxOrient: 'vertical',
        margin: '0px',
        marginBlock: 'unset',
        marginInline: 'unset',
      }}>
      {item.subject}
    </Typography>
    <MessageArea>
      <Typography variant="body1"
        component="p"
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: expanded ? 'unset' : 3,
          WebkitBoxOrient: 'vertical',
          marginTop: '0px !important',
          marginBottom: '0px !important',
          marginBlock: 'unset',
          marginInline: 'unset',
        }}>
        <span dangerouslySetInnerHTML={{
          __html: item.message,
        }}></span>
      </Typography>
    </MessageArea>
    {expanded &&
    // Additional components or expanded content here
    <div style={{
      display: 'flex', justifyContent: 'center', marginTop: '16px',
    }}>
      <Button variant="outlined"
        color="primary"
        onClick={handleButtonClick}
      >
        {getButtonText()}
      </Button>
    </div>
    }
  </StyleFeedItem>;
};
