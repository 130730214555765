/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimatePresence, motion } from 'framer-motion';
import { useMediaQuery } from '@mui/material';
import Center from './layout/Center';
import Logo from './Logo';
import Typography from '@mui/material/Typography';
import theme from 'theme';
import type { PropsWithChildren } from 'react';

const variants = {
  hidden: {
    opacity: 0,
    scale: 0.8,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
};

const LayoutElement = ({
  layoutId,
  children,
}: PropsWithChildren<any>) =>
  <motion.div
    style={{
      width: '100%',
    }}
    variants={variants}
    layoutId={layoutId}
    initial="hidden"
    animate="visible"
    exit="hidden"
  >
    {children}
  </motion.div>;

export interface BrandProps {
  title?: string;
}

/**
 * @return {JSX.Element}
 */
const Brand = ({
  title,
}: BrandProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <LayoutElement layoutId="logo">
    <Center>
      <AnimatePresence>
        <Logo key="logo"
          size={isMobile ? 100 : 200} />
      </AnimatePresence>
      {title ? <Typography
        component={'span'}
        variant="h1">
        {title}
      </Typography> : null}
    </Center>
  </LayoutElement>;
};

export default Brand;
