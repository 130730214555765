/**
   * Prepare labels for saving by removing duplicates, lowercasing them all and
   * sorting them out.
   *
   * @param {string[]} labels - Labels to prepare
   * @return {string[]} - Prepared labels
   */
const prepareCommentLabels = (labels: string[] = []) => {
  let result = labels.map((label) => label.trim().toLocaleLowerCase());
  const unique = new Set();
  result = result.filter((label) => {
    const used = unique.has(label);
    unique.add(label);
    return !used;
  });
  result.sort((a, b) => a.localeCompare(b));
  return result;
};

export default prepareCommentLabels;
