/* eslint-disable @typescript-eslint/no-explicit-any */

import { Content } from './Content';
import MediaContainer from 'components/layout/MediaContainer';
import type { BuilderComponentPropsBase } from '../../BuilderComponentTypes';
import type { BuilderFunction } from '../../../builders/componentBuilder';
import type { FreeTextWidget } from '@shared/schema/src/spaces/projects/sessions/canvases';

export interface FreeTextWidgetProps extends BuilderComponentPropsBase<FreeTextWidget> {
  type: 'free-text';
}

const FreeTextWidgetComponent: BuilderFunction<FreeTextWidgetProps, FreeTextWidget> = ({
  data,
  data: {
    background,
  },
}: FreeTextWidgetProps) => {
  return background ?
    <MediaContainer
      media={background}>
      <Content data={data} />
    </MediaContainer > :
    <Content data={data} />;
};

FreeTextWidgetComponent.config = {
  type: 'free-text',

  displayName: 'Free text',
  props: {
    textAlign: {
      category: 'general',
      type: 'text-align',
      defaultValue: 'left',
      displayName: 'Text align',
    },
    verticalAlign: {
      category: 'general',
      type: 'vertical-align',
      defaultValue: 'middle',
      displayName: 'Vertical alignment',
    },
    background: {
      category: 'look',
      type: 'media',
      displayName: 'Background',
    },
  },
};

export default FreeTextWidgetComponent;
