/* eslint-disable no-unused-vars */

import { AnimatePresence, motion } from 'framer-motion';
import { Button, IconButton, Stepper, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';
import CustomDialog from './CustomDialog';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Onboarding1 from './Onboarding1';
import Onboarding2 from './Onboarding2';
import Onboarding3 from './Onboarding3';
import Onboarding4 from './Onboarding4';
import styled from '@emotion/styled';
import type { MouseEventHandler } from 'react';
/* import theme from 'theme'; */

interface OnboardingDialogProps {
  open?: boolean;
  onClose?: () => void;
}

export enum StepType {
  ONE = 0,
  TWO = 1,
  THREE = 2,
  FOUR = 3,
}

export const StyledDialog = styled(motion.div)`
  max-width: max-content;
  position: relative;
  margin: auto;
  .MuiPaper-root {
    margin:0;
    width: auto;
  }
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export const DialogContent = styled(motion.div)(({
  theme,
}) => `
  ${theme.breakpoints.down('sm')} {
    max-width: 95vw;
    height: 100vh;
    padding: 0;
  }
  position: absolute;
  padding: ${theme.spacing(5, 1)};
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${theme.palette.background.default};
  border: 2px solid ${theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  max-height: 1100px;
`);

const OnboardingContent = styled.div`
  height: auto;
`;

export const DialogHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  gap:10px;
`;

const DialogBottom = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledIconButton = styled(IconButton)(({
  theme,
}) => `
  color: ${theme.palette.primary.main};
  &>.MuiSvgIcon-root {
    width: 50px;
    height: 50px;
  }
`);

export const StepContent = styled.div(({
  theme,
}) => `
  ${theme.breakpoints.down('sm')} {
    max-width: 95vw;
  }
  width: 100%;
  height: 55vh; /* 60vh */
  max-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`);

export const StepTitle = styled(Typography)(({
  theme,
}) => `
  ${theme.breakpoints.down('sm')} {
    font-size: 1.5rem;
  }
  font-size: 1.75rem;
  font-weight: 500;
  text-align: center;
  width: 100%;
  user-select: none;
`);

export const StepText = styled(Typography)(({
  theme,
}) => `
  ${theme.breakpoints.down('sm')} {
    font-size: 1rem;
  }
  font-size: 1.2rem;
  text-align: center;
  max-width: 80%;
  user-select: none;
`);

export const StepComponentContainer = styled.div`
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin: auto;
  overflow: hidden;
  position: relative;
`;

export const StepTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

export const StepIcon = styled(IconButton)`
  &>.MuiSvgIcon-root {
    width: 40px;
    height: 40px;
  }
  user-select: none;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Circle = styled.div`
  width: 50px;
  height: 50px;
  background-color: #e1e1e173;
  border-radius: 50%;
  position: absolute;
  z-index: 100;
  transform: translate(-50%, -50%);
`;

const bounceTransition = {
  y: {
    duration: 0.75,
    yoyo: Infinity,
    ease: 'easeOut',
  },
};

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const OnboardingDialog = ({
  open = false,
  onClose,
}: OnboardingDialogProps) => {
  const {
    t,
  } = useTranslation();

  const [activeStep, setActiveStep,] = useState(0);
  const [direction, setDirection,] = useState(1);

  const handlePrior: MouseEventHandler = useCallback((event) => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
    setDirection(-1);
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const handleNext: MouseEventHandler = useCallback((event) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setDirection(1);
    event.stopPropagation();
    event.preventDefault();
  }, []);

  /* TODO: If the user skips the onboarding, it does not show up automatically again */
  const handleSkip = useCallback((event: React.MouseEvent) => {
    onClose?.();
    setActiveStep(0);
    event.stopPropagation();
    event.preventDefault();
  }, [onClose,]);

  const getStepContent = (step: StepType) => {
    switch (step) {
      case StepType.ONE:
        return <Onboarding1 />;
      case StepType.TWO:
        return <Onboarding2 />;
      case StepType.THREE:
        return <Onboarding3 />;
      case StepType.FOUR:
        return <Onboarding4 />;
      default:
        return null;
    }
  };

  return <CustomDialog
    open={open}
  >
    <AnimatePresence custom={direction}>
      <DialogContent
        key={`step_${activeStep}`}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: {
            type: 'spring',
            stiffness: 300,
            damping: 30,
          },
          opacity: {
            duration: 0.2,
          },
        }}
        drag="x"
        dragConstraints={{
          left: 0, right: 0,
        }}
        dragElastic={1}
        onDragEnd={(e, {
          offset, velocity,
        }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold) {
            setActiveStep((prevActiveStep) => Math.min(prevActiveStep + 1, 3));
            setDirection(1);
          } else {
            setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
            setDirection(-1);
          }
        }}
      >
        {/* Header*/}
        <DialogHeader>

          <StepTitle>
            {t('here-is-how-to-participate')}
          </StepTitle>
        </DialogHeader>

        {/* OnboardingSteps */}
        <OnboardingContent>
          <Stepper activeStep={activeStep} >
            {getStepContent(activeStep)}
          </Stepper>
        </OnboardingContent>

        {/* Bottom */}
        <DialogBottom>
          {activeStep === 3 ?
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              <motion.div
                transition={bounceTransition}
                animate={{
                  x: ['-4px', '4px',],
                  opacity: 1,
                }}>
                <StyledIconButton onClick={handlePrior}>
                  <KeyboardArrowLeft />
                </StyledIconButton>
              </motion.div>
              <Button variant='outlined'
                onClick={handleSkip}>
                {t('start-the-session')}
              </Button>
              <motion.div
                transition={bounceTransition}
                animate={{
                  x: ['4px', '-4px',],
                  opacity: 0,
                  cursor: 'default',
                }}>
                <StyledIconButton
                  disabled
                >
                  <KeyboardArrowRight />
                </StyledIconButton>
              </motion.div>
            </div> :
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              <motion.div
                transition={bounceTransition}
                animate={{
                  x: ['-4px', '4px',],
                  opacity: activeStep === 0 ? 0 : 1,
                }}>
                <StyledIconButton onClick={handlePrior}>
                  <KeyboardArrowLeft />
                </StyledIconButton>
              </motion.div>
              <Button
                variant='text'
                onClick={handleSkip}>
                {t('Close')}
              </Button>
              <motion.div
                transition={bounceTransition}
                animate={{
                  x: ['4px', '-4px',],
                }}>
                <StyledIconButton onClick={handleNext}>
                  <KeyboardArrowRight />
                </StyledIconButton>
              </motion.div>
            </div>
          }
        </DialogBottom>
      </DialogContent>
    </AnimatePresence>
  </CustomDialog>;
};
export default OnboardingDialog;
