import type React from 'react';

/**
 * Determines if the event is a command-enter keypress. Command enter on mac, ctrl enter on windows
 * will be used to submit the form in different contexts.
 *
 * @param {React.KeyboardEvent}  event  the keyboard event
 * @returns {boolean}                   true if the event is a command-enter keypress
 */
export const isSubmitCommand = (event: React.KeyboardEvent) => {
  return event.key === 'Enter' && (event.metaKey || event.ctrlKey);
};

export const isCancelCommand = (event: React.KeyboardEvent) => {
  return event.key === 'Escape';
};
