import { AddWidgetButton } from './CanvasEditor';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import React, { useCallback } from 'react';
import SelectComponentDialog from './SelectComponentDialog';
import styled from '@emotion/styled';
import type { BuilderComponentType } from './PageWidgetRenderer';
import type { EditorMode } from 'components/session/SessionEditor/RestrictedSessionEditor/components/toolbar/ModeSwitch';

export const EmptyIndicator = styled.div(({
  theme,
}) => `
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.1);
  padding: ${theme.spacing(1)};
  border-radius: ${theme.shape.borderRadius}px;
`);

const StyledButton = styled(Button)(({
  theme,
}) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`);

const NegativeSpace = styled.div(({
  theme,
}) => `
  width: 100%;
  height: 100%;
  padding: ${theme.spacing(1)};
`);

export type SelectComponentWithIndexEvent = (
  type: BuilderComponentType,
  index: number,
) => void;

interface EmptyLayoutElementProps {
  /**
   * The index of the component ie. layout area at the model
   */
  index: number;
  /**
   * A list of component types from where to choose the component to the layout area.
   */
  componentTypes: BuilderComponentType[];
  /**
   * Triggered when user choose the component type.
   */
  onSelect: SelectComponentWithIndexEvent;
  /**
   * Current editor mode.
   */
  editMode: EditorMode;
}
/**
 * Component to be rendered, when layout area do not have any component set.
 * This will offer a button and pop up menu, to pick component to the layout.
 * @param {EmptyLayoutElementProps} props
 * @return {JSX.Element}
 */
export const EmptyLayoutElement = ({
  editMode,
  index,
  componentTypes,
  onSelect,
}: EmptyLayoutElementProps) => {
  const {
    t,
  } = useTranslation();

  const [anchorEl, setAnchorEl,] = React.useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSetWidgetClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    event.stopPropagation();
  }, [anchorEl,]);

  const handleSelect = useCallback((type: BuilderComponentType) => {
    onSelect?.(type, index);
  }, [index, onSelect,]);

  return <NegativeSpace>
    {editMode !== 'analyze' && <>
      <EmptyIndicator key={index.toString()} >
        <StyledButton
          variant="text"
          onClick={handleSetWidgetClick}
        >
          <AddWidgetButton />
          {t('Set widget')}
        </StyledButton>
      </EmptyIndicator>
      <SelectComponentDialog
        open={!!anchorEl}
        componentTypes={componentTypes}
        placement={'top'}
        anchorEl={anchorEl}
        onSelect={handleSelect}
        onClose={handleClose} />
    </>
    }
  </NegativeSpace>;
};
