import { LayoutGroup, motion } from 'framer-motion';
import theme from 'theme';
import type { AnalyzeOptions, Axis2D, Coord2D, Statistics2D } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

interface ScatterPlot2DGraph2DGraphProps {
  color: string;
  analyzeOptions?: AnalyzeOptions;
  statistics?: Statistics2D;
  knobColor?: string;
  coords: WithId<Coord2D>[];
  axis: Axis2D;
}
const DOT_RADIUS = 20;

const offset = theme.shape.borderRadius + DOT_RADIUS / 2;

const drawingArea = 1000 - 2 * offset;

const transformX = (x: number, xMin: number, scaleX: number) => {
  return offset + drawingArea * (x - xMin) / scaleX;
};

const transformY = (y: number, yMin: number, scaleY: number) => {
  return offset + drawingArea - drawingArea * (y - yMin) / scaleY;
};

export const ScatterPlot2DGraph = ({
  coords = [],
  // color,
  // analyzeOptions,
  // statistics,
  axis,
}: ScatterPlot2DGraph2DGraphProps) => {
  // const theme = useTheme();

  const xMax = axis.x.max;
  const xMin = -xMax;
  const yMax = axis.y.max;
  const yMin = -yMax;
  const scaleX = xMax - xMin;
  const scaleY = yMax - yMin;

  const result = coords.map(({
    x, y, _id,
  }) => {
    const cx = transformX(x, xMin, scaleX);
    const cy = transformY(y, yMin, scaleY);
    // TODO: We need to deliver the answer's member id to be the key here
    //       to make animations indicate the correct answer.
    const key = `c${_id}`;
    return <motion.circle
      key={key}
      initial={{
        scale: 0,
        opacity: 0,
        x: cx,
        y: cy,
      }}
      animate={{
        scale: 1,
        opacity: 1,
        x: cx,
        y: cy,
      }}
      exit={{
        scale: 0,
        opacity: 0,
      }}
      transition={{
        duration: 0.5,
      }}
      layoutId={key}
      r={DOT_RADIUS}
      fill="rgba(32,142,255,0.7)" />; // TODO: Theme to have colors for plots
  });

  // Drawing stats
  // TODO: Make it draw
  // analyzeOptions && analyzeOptions.analyzeValues && Object.entries(analyzeOptions.analyzeValues).forEach(
  //   ([key, coloredValue,]) => {
  //     if (statistics && coloredValue.value) {
  //       const valueX = Object.entries(statistics).find(([pro,]) => pro === `${key}X`)[1];
  //       const valueY = Object.entries(statistics).find(([pro,]) => pro === `${key}Y`)[1];

  //       // TODO constant for graph space size
  //       const radius = 1000 * (MIN_RADIUS + (MAX_RADIUS - MIN_RADIUS)) / 7 / 2;

  //       const cx = transformX(valueX, xMin, scaleX);
  //       const cy = transformY(valueY, yMin, scaleY);

  //       // Answers circles
  //       result.push(<motion.circle
  //         key={key + coloredValue.label}
  //         cx={cx}
  //         cy={cy}
  //         r={radius}
  //         fill={coloredValue.color} />);
  //     }
  //   }
  // );
  return <LayoutGroup>{result}</LayoutGroup>;
};
