import { BLUR_COMMAND, COMMAND_PRIORITY_EDITOR } from 'lexical';
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { LexicalEditor } from 'lexical';

interface EditorBlurPluginProps {
  onBlur: (editor: LexicalEditor) => void;
}
export const EditorBlurPlugin = ({
  onBlur,
}: EditorBlurPluginProps) => {
  const [editor,] = useLexicalComposerContext();

  useEffect(() => {
    editor.registerCommand(
      BLUR_COMMAND,
      (payload, editor) => {
        onBlur(editor);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor, onBlur,]);

  return null;
};
