import { BpCheckedIcon } from './BpCheckedIcon';
import { BpIcon } from './BpIcon';
import Radio from '@mui/material/Radio';
import React from 'react';
import type { RadioProps } from '@mui/material/Radio';

// Inspired by blueprintjs
// eslint-disable-next-line require-jsdoc

export const BpRadio = (props: RadioProps) => {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props} />
  );
};
