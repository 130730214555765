/* eslint-disable @typescript-eslint/no-explicit-any */

import { Typography } from '@mui/material';
import { useMemo } from 'react';
import Center from 'components/common/layout/Center';
import QRCode from 'react-qr-code';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import styled$ from 'utils/react/styled$';
import type { BuilderComponentPropsBase } from '../BuilderComponentTypes';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { CSSProperties } from 'react';
import type { QRCodeWidget } from '@shared/schema/src/spaces/projects/sessions/canvases/widgets';

export interface QRCodeWidgetProps extends BuilderComponentPropsBase<QRCodeWidget> {
  type: 'qr-code';
}

const Container = styled$.div<{
  $textColor?: string;
}>(({
  $textColor,
}) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: space-between;
    color: ${$textColor ?? 'black'};
`);

const QRCodeWidgetComponent: BuilderFunction<QRCodeWidgetProps, QRCodeWidget> = ({
  data,
}: QRCodeWidgetProps) => {
  return <Content data={data} />;
};

interface ContentProps {
  data: QRCodeWidget;
}
const Content = ({
  data: {
    showURL,
    size,
    textColor,
    title,
    // verticalAlign,
    urlOverride,
  } = {} as any,
}: ContentProps) => {
  const qrStyle = useMemo(() => {
    return window.innerWidth > window.innerHeight ? {
      height: 'auto',
      maxWidth: `80%`,
      width: `80%`,
    } : {
      height: `calc(${size}*0.7)`, maxHeight: `calc(${size}*0.7)`, width: `auto`,
    };
  }, [size,]);

  const textStyle: CSSProperties = useMemo(() => ({
    color: textColor ?? 'black',
  }), [textColor,]);

  const url = useMemo(() => {
    if (urlOverride) {
      return urlOverride;
    }

    let url = window.location.href;
    const queryIndex = url.indexOf('?');
    if (queryIndex > -1) {
      url = url.substring(0, queryIndex);
    }
    return url;
  }, [urlOverride,]);

  return <Container>
    <Center>
      {
        showURL && <Typography
          style={textStyle}
          variant="body2">{urlOverride ?? window.location.href}</Typography>
      }
      <QRCode
        style={qrStyle}
        value={url}
        viewBox={`0 0 256 256`}
      />
      {title && <Typography
        style={textStyle}
        variant="h4">{title}</Typography>}
    </Center>
  </Container>;
};

QRCodeWidgetComponent.config = {
  type: 'qr-code',

  displayName: 'QR Code',
  icon: <QrCode2Icon />,
  props: {
    title: {
      category: 'general',
      type: 'text',
      defaultValue: '',
      displayName: 'Title',
    },
    showURL: {
      category: 'general',
      type: 'checker',
      defaultValue: false,
      displayName: 'Show URL',
    },
    urlOverride: {
      category: 'general',
      type: 'text',
      defaultValue: '',
      displayName: 'URL Override',
    },
    verticalAlign: {
      category: 'general',
      type: 'vertical-align',
      defaultValue: 'middle',
      displayName: 'Vertical alignment',
    },
    size: {
      category: 'general',
      type: 'qr-size',
      defaultValue: '50%',
      displayName: 'Size',
    },
    background: {
      category: 'look',
      type: 'media',
      displayName: 'Background',
    },
  },
};

export default QRCodeWidgetComponent;
