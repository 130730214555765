import { debouncedFunction } from './utils/debouncedFunction';
import { saveCanvasDraft, saveSessionDraft } from 'bloc/session/sessionApi';
import type { SaveCanvasDraftRequest } from '@shared/schema/src/https/session/saveCanvasDraft';
import type { SaveSessionDraftRequest } from '@shared/schema/src/https/session/saveSessionDraft';

const SAVE_DELAY = 3000;

/**
 * Saves the canvas draft to the backend.
 * @param args - The arguments for saving the canvas draft.
 * @param setSavingState - A function to set the saving state.
 */
export const saveCanvasDraftToBackend = async (
  args: SaveCanvasDraftRequest,
  setSavingState: (saving: boolean) => void
) => {
  setSavingState(true);
  try {
    // wait 3 seconds
    await saveCanvasDraft(args);
    setSavingState(false);
  } catch (e) {
    console.error(e);
    setSavingState(false);
  }
};

/**
 * Saves the session draft to the backend.
 * @param args - The arguments for saving the session draft.
 * @param setSavingState - A function to set the saving state.
 */
export const saveSessionDraftToBackend = async (
  args: SaveSessionDraftRequest,
  setSavingState: (saving: boolean) => void
) => {
  setSavingState(true);

  try {
    await saveSessionDraft(args);
    setSavingState(false);
  } catch (e) {
    console.error(e);
    setSavingState(false);
  }
};

/**
 * Debounced function to save the canvas draft to the backend.
 * @param args - The arguments for saving the canvas draft.
 * @param setSavingState - A function to set the saving state.
 * @returns A promise that resolves when the canvas draft is saved.
 */
export const saveCanvasToBackendDebounced = debouncedFunction(
  async (
    args: SaveCanvasDraftRequest,
    setSavingState: (saving: boolean) => void
  ) => {
    return saveCanvasDraftToBackend(args, setSavingState);
  },
  SAVE_DELAY
);

/**
 * Debounced function to save the session draft to the backend.
 * @param args - The arguments for saving the session draft.
 * @param setSavingState - A function to set the saving state.
 */
export const saveSessionToBackendDebounced = debouncedFunction(
  async (
    args: SaveSessionDraftRequest,
    setSavingState: (saving: boolean) => void
  ) => {
    saveSessionDraftToBackend(args, setSavingState);
  },
  SAVE_DELAY
);

/**
 * Cancels any ongoing backend calls.
 */
export const cancelBackendCalls = () => {
  try {
    if (saveCanvasToBackendDebounced) {
      saveCanvasToBackendDebounced.cancel();
    }
    if (saveSessionToBackendDebounced) {
      saveSessionToBackendDebounced.cancel();
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * Forces the backend calls for saving the canvas and session drafts.
 * @param canvasArgs - The arguments for saving the canvas draft.
 * @param sessionArgs - The arguments for saving the session draft.
 * @param setSavingState - A function to set the saving state.
 */
export const forceBackendCalls = (
  canvasArgs: SaveCanvasDraftRequest,
  sessionArgs: SaveSessionDraftRequest,
  setSavingState: (saving: boolean) => void
) => {
  try {
    if (saveCanvasToBackendDebounced) {
      saveCanvasToBackendDebounced.force(canvasArgs, setSavingState);
    }
  } catch (e) {
    console.error(e);
  }
  try {
    if (saveSessionToBackendDebounced) {
      saveSessionToBackendDebounced.force(sessionArgs, setSavingState);
    }
  } catch (e) {
    console.error(e);
  }
};
