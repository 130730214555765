import { AnimatePresence, motion } from 'framer-motion';
import ToolbarPlugin from '@mindhiveoy/react-text-editor/plugins/ToolbarPlugin';
import type { AllowedStyle } from '.';

const AnimatedToolbar = motion(ToolbarPlugin);

const ANIMATE_HIDDEN = {
  opacity: 0, y: -20,
};
const ANIMATE_VISIBLE = {
  opacity: 1, y: 0,
};

const ANIMATE_TRANSITION = {
  duration: 0.2,
};

type InplaceToolbarProps = {
  allowedStyles: AllowedStyle[];
  centerToolbar?: boolean;
  fixedToolbar?: boolean;
  showToolbar?: boolean;
  showToolbarState: boolean;
};

const InplaceToolbar = ({
  allowedStyles,
  centerToolbar,
  fixedToolbar,
  showToolbar,
  showToolbarState,
}: InplaceToolbarProps) =>
  <AnimatePresence>
    {(fixedToolbar || showToolbar && showToolbarState) &&
      <AnimatedToolbar
        initial={ANIMATE_HIDDEN}
        animate={ANIMATE_VISIBLE}
        exit={ANIMATE_HIDDEN}
        transition={ANIMATE_TRANSITION}
        allowedStyles={allowedStyles}
        centerButtons={centerToolbar}
      />
    }
  </AnimatePresence>;

export default InplaceToolbar;
