/* eslint-disable @typescript-eslint/no-explicit-any */
import Typography from '@mui/material/Typography';
import type { TypographyProps } from '@mui/material/Typography';

import type { BuilderComponentPropsBase } from '../PageWidgetRenderer';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { TextAlign } from '@shared/schema/src/common';

export interface TextProps extends Pick<TypographyProps, 'variant'> {
  text?: string;
  variant: any;
  textAlign?: TextAlign;
  id?: string;
  color?: string;
}
export interface TextWidgetProps extends BuilderComponentPropsBase<TextProps> {
  type: 'text';
}

export const TEXT_DEFAULT_PROPS: TextProps = {
  // eslint-disable-next-line max-len
  text: 'insert text here',
  variant: 'h1',
};

const TextWidget: BuilderFunction<TextWidgetProps, TextProps> = ({
  data: {
    text,
    textAlign = 'left',
    variant,
    color,
  } = TEXT_DEFAULT_PROPS,
}: TextWidgetProps) => {
  return <Typography
    component={'span'}
    style={{
      textAlign: textAlign as any,
      color,
    }}
    variant={variant as any}>
    { text }
  </Typography>;
};

TextWidget.config = {
  type: 'text',
  // dataType: 'String',

  displayName: 'Text',
  props: {
    text: {
      type: 'text',
    },
    textAlign: {
      type: 'text-align',
      defaultValue: 'left',
      displayName: 'Text align',
    },
    color: {
      type: 'color',
      displayName: 'Color',
    },
    variant: {
      type: 'picker',
      defaultValue: 'body1',
      options: [
        {
          value: 'h1',
          label: 'Header 1',
        },
        {
          value: 'h2',
          label: 'Header 2',
        },
        {
          value: 'h3',
          label: 'Header 3',
        },
        {
          value: 'body1',
          label: 'Body 1',
        },
      ],
    },
  },
};

export default TextWidget;
