import { CachedCollectionReference } from './CachedCollectionReference';
import { debugSwitch } from 'utils/debug/debug';
import normalizeDatabasePath from '../utils/normalizeDatabasePath';
import type { BlocErrorFunction, BlocQueryConstraint, BlocQuerySnapshotListener } from '../types';
import type { DatabaseControllerFactoryFunction } from '../databaseController/DatabaseController';
import type { DocumentData } from '@mindhiveoy/firebase-schema';
import type CachedDocumentReference from './CachedDocumentReference';

const DEBUG = debugSwitch(false);

export interface QueryArgs<T extends DocumentData = DocumentData> {
  collectionPath: string;
  query?: BlocQueryConstraint[];
  onSnapshot: BlocQuerySnapshotListener<T>;
  onError: BlocErrorFunction;
}
/**
 * The global cache for cached collections and document instances inside of them.
 */
export class BlocCache {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private $collectionMap = new Map<string, CachedCollectionReference<any>>();
  private $controllerFactory!: DatabaseControllerFactoryFunction;
  /**
   * Reset cache
   */
  public reset = () => {
    this.$collectionMap.forEach((collection) => collection.dispose());
    this.$collectionMap = new Map();
  };
  /**
   * Set the factory function for the cache.
   *
   * @param {DatabaseControllerFactoryFunction} fun
   */
  public set controllerFactory(fun: DatabaseControllerFactoryFunction) {
    this.$controllerFactory = fun;
  }
  /**
   *
   */
  public get controllerFactory(): DatabaseControllerFactoryFunction {
    return this.$controllerFactory;
  }
  /**
   * Get the the reference to specific collection identified by the collection path.
   * @param {string} collectionPath Normalized path to the collection.
   * @return {CachedCollectionReference<T> | undefined} The reference to the collection if exists, otherwise undefined.
   */
  public getCollectionReference = <T extends DocumentData>(
    collectionPath: string
  ): CachedCollectionReference<T> | undefined => {
    return this.$collectionMap.get(collectionPath) as unknown as CachedCollectionReference<T> | undefined;
  };

  /**
   *
   * @param {QueryArgs} args
   * @return {any} Unsubscribe function
   */
  public querySnapshot = <T extends DocumentData>({
    collectionPath,
    query,
    onSnapshot,
    onError,
  }: QueryArgs<T>
  ) => {
    const reference = this.$getCollectionReferenceInstance<T>(collectionPath);

    return reference.onQuerySnapshot(query, onSnapshot, onError);
  };
  /**
   * Add reference to a collection.
   *
   * @param {string}                          collectionPath Normalized collection path.
   * @param {CollectionReferenceListener<T>}  listener      The listener for the collection changes.
   * @return {CachedCollectionReference<T>}    Reference to the collection.
   */
  public addCollectionReference = <T extends DocumentData>(
    collectionPath: string
    // listener: CollectionReferenceListener<T>
  ): CachedCollectionReference<T> => {
    // item.addCollectionReferenceListener(listener);
    return this.$getCollectionReferenceInstance<T>(collectionPath);
  };

  /**
   * Remove reference to a collection.
   *
   * @param {string}  collectionPath Normalized collection path.
   * @return {number} Number of still active references.
   */
  public removeCollectionReference = <T extends DocumentData>(
    collectionPath: string
  ) => {
    const ref = this.getCollectionReference<T>(collectionPath);
    if (!ref) { // TODO: restrict the code to the development build.
      return;
    }
    const count = ref.removeRef();
    if (count <= 0) {
      this.$collectionMap.delete(collectionPath);
    }
    return count;
  };

  /**
   * Add a document reference to a document in specific collection.
   * @param {string}   collectionPath
   * @param {string}   docId
   * @param {T=}       initialData
   * @return {CachedDocumentReference<DocumentData>}
   */
  public addDocumentReference = <T extends DocumentData>(
    collectionPath: string,
    docId: string,
    initialData?: T
  ): {
    collectionReference: CachedCollectionReference<T>,
    documentReference: CachedDocumentReference<T>;
  } => {
    const collectionReference = this.$getCollectionReferenceInstance<T>(collectionPath);
    return {
      collectionReference,
      documentReference: collectionReference.addDocumentReference({
        docId,
        candidate: false,
        initialData,
      }) as any,
    };
  };
  /**
   *
   * @param {string}                        collectionPath
   * @param {string}                        docId
   * @param {DocumentReferenceListener<T>}  listener
   */
  public removeDocumentReference = (
    collectionPath: string,
    docId: string
  ) => {
    const ref = this.getCollectionReference(collectionPath);
    if (!ref) {
      throw new Error(
        `Tried to release document reference from collection ${collectionPath}, but the collection path does not exist.`
      );
    }
    ref.removeDocumentReference(docId);

    if (ref.referenceCount <= 0) {
      this.$collectionMap.delete(collectionPath);
    }
  };
  /**
   * Reveal, if cache contains a specified collection
   * @param {string} collectionPath
   * @return {boolean} True if the collection exists, otherwise false.
   */
  public has = (collectionPath: string) => {
    return !!this.getCollectionReference(collectionPath);
  };
  /**
   * Get CollectionCacheItem instance for the given collection path. If path does not
   * already exists, it will be created.
   *
   * @param {string}  $collectionPath The path to the collection.
   * @return {CachedCollectionReference<T>}  Item instance.
   */
  private $getCollectionReferenceInstance<T extends DocumentData>($collectionPath: string): CachedCollectionReference<T> {
    const collectionPath = normalizeDatabasePath($collectionPath);
    let ref = this.getCollectionReference<T>(collectionPath);
    if (!ref) {
      DEBUG && console.debug(`GlobalCollectionCache's%c create%c called for collection ${collectionPath}`, 'color: red', 'color: lightgray');
      const controller = this.createDatabaseController<T>(collectionPath);
      ref = new CachedCollectionReference<T>(
        collectionPath,
        controller,
        this
      );
      this.$collectionMap.set(collectionPath, ref);
    } else {
      DEBUG && console.debug(`GlobalCollectionCache's%c cache hit%c for collection ${collectionPath}`, 'color: green', 'color: lightgray');
    }
    ref.addRef();
    return ref as unknown as CachedCollectionReference<T>;
  }
  /**
   * Create an instance of the database controller using current factory method.
   * @param {string} collectionPath
   * @return {DatabaseController<T>}
   */
  private createDatabaseController = <T extends DocumentData>(
    collectionPath: string
  ) => {
    if (!this.$controllerFactory) {
      throw new Error('No controller factory provided.');
    }
    const controller = this.$controllerFactory<T>({
      collectionPath,
    });
    if (!controller) {
      throw new Error('Controller factory method did not return a controller instance.');
    }
    return controller;
  };
}

export const globalCache: BlocCache = new BlocCache();
