import type { AnimationDefinition } from 'framer-motion';
import type React from 'react';

/**
 * Generate animation definition for knob drop animation
 * @param canvasRef     The parent canvas, where the knob is being dropped
 * @param knobRef       The knob being dropped
 * @param destinationX  The x coordinate of the destination
 * @param destinationY  The y coordinate of the destination
 * @returns             Animation definition
 */
export const orchestrateKnobDropAnimation = (
  canvasRef: React.RefObject<any>,
  knobRef: React.RefObject<any>,
  destinationX?: number,
  destinationY?: number
): AnimationDefinition | null => {
  const canvas = canvasRef.current?.getBoundingClientRect();
  const knob = knobRef.current?.getBoundingClientRect();
  if (!knob || !canvas) {
    return null;
  }
  const x = destinationX ?? knob.left - canvas.left;
  const y = destinationY ?? knob.top - canvas.top;

  const deltaX = (canvas.left + x - knob.left) / 3;
  const deltaY = (canvas.top + y - knob.top) / 3;

  const originX = knob.left - canvas.left;
  const originY = knob.top - canvas.top;

  return {
    x: [originX, originX + deltaX, originX + deltaX * 2, x, x, x, x, x,],
    y: [originY, originY + deltaY, originY + deltaY * 2, y, y, y, y, y,],
    scale: [1, 1, 0.9, 0.8, 0.7, 1.1, 1,],
    opacity: 1,
    transition: {
      duration: 0.3,
      velocity: 5,
      bounce: 5,
      mode: 'easeOut',
      bounceStiffness: 600,
    },
  };
};
