/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { MAX_RADIUS, MIN_RADIUS } from './modes/DiscreteMatrixGridGraph';
import { answerX } from './modes/DiscreteMatrixGridGraph/answerX';
import { answerY } from './modes/DiscreteMatrixGridGraph/answerY';
import { extractCoordsFromKey } from './modes/DiscreteMatrixGridGraph/extractCoordsFromKey';
import { motion } from 'framer-motion';
import type { AnalyzeOptions, Axis2D, Frequencies, Statistics2D } from '@shared/schema/src';

interface FrequencyDot2DGraphProps {
  rowCount: number;
  columnCount: number;
  color: string;
  frequencies?: Frequencies;
  maxFrequencyValue: number;
  analyzeOptions?: AnalyzeOptions;
  statistics?: Statistics2D;
  knobColor?: string;
  minX: number;
  minY: number;
  scaleY: number;
  axis: Axis2D;
}
export const FrequencyDot2DGraph = ({
  rowCount,
  columnCount,
  knobColor,
  color,
  frequencies,
  maxFrequencyValue,
  // analyzeOptions,
  // statistics,
  axis,
  minX,
  minY,
  scaleY,
}: FrequencyDot2DGraphProps) => {
  if (!rowCount || !columnCount) {
    return null;
  }
  if (rowCount === 0 || columnCount == 0) {
    return null;
  }

  const cellWidth = 100 / columnCount;
  const cellHeight = 100 / rowCount;

  const result: JSX.Element[] = [];

  for (let y = 0; y < rowCount; y++) {
    result.push(<line
      key={`r_${y}`}
      x1={0}
      y1={y * 1000 / rowCount}
      x2={1000}
      y2={y * 1000 / rowCount}
      stroke={color}
      strokeWidth={5} />);
  }
  for (let x = 0; x < columnCount; x++) {
    result.push(<line
      key={`c_${x}`}
      y1={0}
      x1={x * 1000 / rowCount}
      y2={1000}
      x2={x * 1000 / rowCount}
      stroke={color}
      strokeWidth={5} />);
  }

  // Drawing frequencies
  frequencies && Object.entries(frequencies).forEach(
    ([key, value,]: [string, number]) => {
      const {
        x, y,
      } = extractCoordsFromKey(key);
      const sizeScaler = value / maxFrequencyValue;

      // TODO constant for graph space size
      const radius = 1000 * (MIN_RADIUS + (MAX_RADIUS - MIN_RADIUS) * sizeScaler) / 7 / 2;

      const cx = (answerX({
        x,
        canvasMode: 'discrete',
        minX,
        axis,
      })! + 0.5) * cellWidth * 1000 / 100;

      const cy = (answerY({
        y,
        canvasMode: 'discrete',
        minY,
        axis,
        scaleY,
      }) + 0.5) * cellHeight * 1000 / 100;

      // Answers circles
      result.push(<motion.circle
        key={key}
        cx={cx}
        cy={cy}
        fill={knobColor}
        initial={{
          r: 0,
        }}
        animate={{
          r: radius * 0.8,
        }}
        exit={{
          r: 0,
        }} />);
    }
  );

  // Drawing stats // TODO: make it work
  // analyzeOptions && analyzeOptions.analyzeValues && Object.entries(analyzeOptions.analyzeValues).forEach(
  //   ([key, coloredValue,]) => {
  //     if (statistics && coloredValue.value) {
  //       const valueX = Object.entries(statistics).find(([pro,]) => pro === `${key}X`)[1];
  //       const valueY = Object.entries(statistics).find(([pro,]) => pro === `${key}Y`)[1];

  //       // TODO constant for graph space size
  //       const radius = 1000 * (MIN_RADIUS + (MAX_RADIUS - MIN_RADIUS)) / 7 / 2;

  //       const cx = (answerX(valueX) + 0.5) * cellWidth * 1000 / 100;
  //       const cy = (answerY(valueY) + 0.5) * cellHeight * 1000 / 100;

  //       // Answers circles
  //       result.push(<motion.circle
  //         key={key + coloredValue.label}
  //         cx={cx}
  //         cy={cy}
  //         r={radius}
  //         fill={coloredValue.color} />);
  //     }
  //   }
  // );
  return <>{result}</>;
};
