/* eslint-disable @typescript-eslint/no-explicit-any */

import { usePresentationScreenContext } from 'components/screens/presentation/ScreenRenderer/PresentationScreenContext';
import { useTemplateContext } from 'components/screens/presentation/TemplateRenderer/TemplateContext';
import Center from 'components/common/layout/Center';
import DownloadIcon from '@mui/icons-material/Download';
import type { BuilderComponentPropsBase } from '../BuilderComponentTypes';
import type { BuilderFunction } from '../../builders/componentBuilder';

export interface CanvasPlaceHolderWidgetProps extends BuilderComponentPropsBase<any> {
  type: 'canvas-placeholder';
}

const CanvasPlaceHolderWidget: BuilderFunction<CanvasPlaceHolderWidgetProps, any> = () => {
  const templateContext = useTemplateContext();

  const presentation = usePresentationScreenContext();

  if (!presentation) {
    // Edit mode
    return <Center>
      <div style={{
        textAlign: 'center',
        margin: 'auto',
        maxWidth: 300,
      }}>
        <DownloadIcon
          style={{
            fontSize: 80,
          }} />
        <h1>Canvas placeholder</h1>
        <p>This template, will embed the content of the current canvas to this place.</p>
      </div>
    </Center>;
  }
  if (!templateContext) {
    return <div>No template canvas found.</div>;
  }
  return templateContext.canvasElement;
};

CanvasPlaceHolderWidget.config = {
  type: 'canvas-placeholder',

  displayName: 'Canvas placeholder',
  icon: <DownloadIcon />,
};

export default CanvasPlaceHolderWidget;
