import styled$ from 'utils/react/styled$';

export const BackgroundFade = styled$.div<{
  $peak: number;
  $useBackgroundOpacity?: boolean;
}>(({
  $peak,
  $useBackgroundOpacity,
  theme,
}) => `
  position: absolute;
  pointer-events: none;
  width: 100vw;
  height: calc(100dvh + 1px);
  background: ${$useBackgroundOpacity ?
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.0) ${$peak}%, ${theme.palette.background.default} 100%)` :
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.6) ${$peak}%, ${theme.palette.background.default} 100%)`};
`);
