/* eslint-disable @typescript-eslint/no-explicit-any */
import type { DocumentData } from '@mindhiveoy/firebase-schema';

import { DEBUG_BLOC_FRAMEWORK } from '.';
import { ListenerHelper } from '../../../utils/listener/listenerHelper';
import { generateQueryConstraintsKey } from './generateQueryConstraintsKey';
import { getObjectField } from '@mindhiveoy/foundation';
import { greaterThan } from './greaterThan';
import { immutable } from '../utils/immutable';
import { lessThan } from './lessThan';
import { matches } from './matches';
import { startTransition } from 'react';
import type {
  BlocDocumentChange,
  BlocQueryConstraint, BlocQuerySnapshotListener, CreateQuerySnapshotFunction,
  DatabaseControllerQuerySnapshotListener, LimitQueryConstraint, OrderByQueryConstraint, WhereQueryConstraint
} from '../types';
import type CachedDocumentReference from '../cache/CachedDocumentReference';
import type DatabaseController from '../databaseController/DatabaseController';
import type DocumentReferenceSet from '../cache/DocumentReferenceSet';

interface QueryInstance<T extends DocumentData> {
  /**
   * Set of document references showing the set of fetched documents.
   */

  docs: CachedDocumentReference<T>[];

  listeners: ListenerHelper<BlocQuerySnapshotListener<T>>;

  pending?: boolean;

  where: WhereQueryConstraint[];

  order: OrderByQueryConstraint[];

  limit: LimitQueryConstraint;

  queryHash?: string;

  unsubscribe: () => void;

  updateDocs: DatabaseControllerQuerySnapshotListener<T>;
}

/**
 * querySnapshot call arguments.
 */
type QuerySnapshotArgs<T extends DocumentData> = {
  /**
   * Query defining the constraints used to fetch documents from the database.
   * When left to empty, the default collection query will be used.
   */
  query?: BlocQueryConstraint[],
  /**
   * The actual database call used to open the snapshot listener.
   */
  create: CreateQuerySnapshotFunction<T>,
  /**
   * The snapshot listener function that will trigger each time query data will change.
   */
  onSnapshot: BlocQuerySnapshotListener<T>;
};

/**
 * Query set to maintain book keeping of queries
 * targeting the same collection in the database.
 *
 */
class BlocQuerySet<T extends DocumentData> {
  /**
   *
   */
  dispose = () => {
    this.$queryInstances.clear();
  };
  /**
   * Each query instance targeting to this collection.
   */
  protected $queryInstances = new Map<string, QueryInstance<T>>();
  /**
   * Constructor.
   *
   * @param {DocumentReferenceSet<T>} documentReferences Collection's document reference set.
   */
  constructor(
    private documentReferences: DocumentReferenceSet<T>
  ) {
  }
  /**
   * Validate queries against the changed documents.
   * @param {CachedDocumentReference<T>[]} changedDocuments
   */
  public validate = (
    changedDocuments: CachedDocumentReference<T>[]
  ) => {
    this.$queryInstances.forEach((instance) => {
      const {
        docs, where, order,
        // limit, TODO: limit
      } = instance;

      // TODO: Identify when query has not changed
      // let filtered = changedDocuments.filter((doc) => this.match(doc, where));

      const filtered = this.resolveDocs(changedDocuments, docs, where);

      // TODO: filter against documentSet for delete operations
      const sorted = this.sort(filtered, order);

      // TODO: Should this check just filtered and sort if needed?
      const changed = !instance.docs ||
        this.haveDocsChanged(docs, sorted, changedDocuments);

      if (changed) {
        instance.docs = sorted.slice(0);

        if (!DEBUG_BLOC_FRAMEWORK) {
          startTransition(() => {
            instance.listeners?.fire(instance.docs);
          });
          return;
        }
        instance.listeners?.fire(instance.docs);
      }
    });
  };

  public fetchDoc = async (docId: string,
    controller: DatabaseController<T>
  ) => {
    const {
      docRef,
    } = this.documentReferences.get(docId, {
      method: 'set',
    });
    const doc = await controller.fetchDoc(docId);

    if (doc?.data()) {
      docRef?.setData(immutable(doc.data()));
    }
    return docRef;
  };
  /**
   * Open a snapshot listener for a specific query.
   *
   * @param {QuerySnapshotArgs}                 args             Object defining the input arguments.
   * @param {Query}                             args.query       Query defining the constraints used to fetch
   *                                                             documents from the database.
   * @param {CreateQuerySnapshotFunction}       args.create      The actual database call used to open the snapshot listener.
   * @param {DatabaseControllerQuerySnapshotListener} args.onSnapshot  The snapshot listener function that will trigger each time
   *                                                             query data will change.
   * @return {BlocUnSubscribeFunction}                               Unsubscribe function for unsubscribing from onSnapshot listener.
   */
  public onSnapshot = (args: QuerySnapshotArgs<T>): () => void => {
    const {
      query = [],
      onSnapshot,
    } = args;

    const key = generateQueryConstraintsKey(query);

    let instance = this.$queryInstances.get(key);
    if (!instance) {
      instance = this.createQueryInstance(key, args);
    } else {
      instance.listeners.add(onSnapshot);
      /*
       * Instant snapshot call will be triggered only when the initial snapshot call has been executed, otherwise
       * the listener will just be registered. In that case, the initial call will be executed as a normal trigger
       * among other listeners when data is available.
       */
      if (!instance.pending) {
        onSnapshot(instance.docs);
      }
    }

    const order = query.filter((c) => c.type === 'orderBy') as OrderByQueryConstraint[];
    if (order) {
      instance.order = order;
    }
    const where = query.filter((c) => c.type === 'where') as WhereQueryConstraint[];
    if (where) {
      instance.where = where;
    }
    // TODO: Limit

    return () => {
      instance?.listeners.remove(onSnapshot);
      if (instance?.listeners.length === 0) {
        instance?.unsubscribe();
        this.$queryInstances.delete(key);
      }
    };
  };
  /**
   * Create a new query instance for set of queries targeting the same collection with equal output.
   * @param {string}                                key         The query instance key
   * @param {QuerySnapshotArgs<T>}                  args        Query arguments.
   * @return {QueryInstance<T>}
   */
  private createQueryInstance = (
    key: string,
    {
      query = [],
      create,
      onSnapshot,
    }: QuerySnapshotArgs<T>): QueryInstance<T> => {
    const listeners = new ListenerHelper<BlocQuerySnapshotListener<T>>();
    // First onSnapshot call will be triggered when documents are available.
    listeners.add(onSnapshot);

    const instance = {
      pending: false,
      listeners,
      where: [],
      order: [],
    } as Partial<QueryInstance<T>>;

    const updateDocs: DatabaseControllerQuerySnapshotListener<T> = (
      docs, docChanges
    ) => {
      try {
        const d = new Map();

        this.resolveChangedDocuments(docChanges, d);
        // TODO sorting

        const cachedDoc = docs.map((doc) => {
          const {
            docRef,
          } = this.documentReferences.get(doc.id, {
            method: 'set',
            initialData: immutable(doc.data()),
          });
          // d.set(doc.id, docRef);
          const d2 = d.get(doc.id);
          if (d2) {
            docRef?.setData(immutable(d2.data));
          }
          return docRef;
        });

        this.$queryInstances.set(key, instance as any);

        this.validate(cachedDoc as any);
      } finally {
        instance.pending = false;
      }
    };
    // Query instance must be included to the set before creating the actual
    // query as the updateDocs may be called immediately (basically in unit tests.)
    instance.updateDocs = updateDocs;
    this.$queryInstances.set(key, instance as any);

    const unsubscribe = create(query, updateDocs);
    instance.unsubscribe = unsubscribe;

    return instance as QueryInstance<T>;
  };

  /**
   *
   * @param {CachedDocumentReference<T>[]}filtered
   * @param {OrderByQueryConstraint[]} order
   * @return {CachedDocumentReference<T>[]}
   */
  private sort = (
    filtered: CachedDocumentReference<T>[] = [],
    order: OrderByQueryConstraint[]
  ) => {
    filtered.sort((a, b) => {
      for (const o of order) {
        const fieldPath = o.fieldPath.split('.');
        const af = getObjectField(a.data!, fieldPath);
        const bf = getObjectField(b.data!, fieldPath);

        if (af === bf) {
          continue;
        }
        // TODO: string comparison
        if (o.directionStr === 'asc') {
          return lessThan(af, bf);
        }
        return greaterThan(af, bf);
      }
      return 0;
    });
    return filtered;
  };
  /**
   *
   * @param {CachedDocumentReference<T>}  doc
   * @param {WhereQueryConstraint[]}      where
   * @return {boolean}
   */
  private match = (
    doc: CachedDocumentReference<T>,
    where: WhereQueryConstraint[] = []
  ): boolean => {
    if (doc.pending) {
      return false;
    }
    const data = doc.data;
    if (!data) {
      return false;
    }
    for (const rule of where) {
      const value = getObjectField(data, rule.fieldPath.split('.'));
      if (!matches(value, where)) {
        return false;
      }
    }
    return true;
  };

  /**
   *
   * @param {CachedDocumentReference<T>[]}  filtered
   * @param {CachedDocumentReference<T>[]}  docs
   * @param {WhereQueryConstraint[]}        where
   * @return {CachedDocumentReference<T>[]}
   */
  private resolveDocs = (
    filtered: CachedDocumentReference<T>[],
    docs: CachedDocumentReference<T>[],
    where: WhereQueryConstraint[]
  ): CachedDocumentReference<T>[] => {
    const resultMap = new Map<string, CachedDocumentReference<T>>();

    filtered?.forEach((doc): void => {
      if (this.documentReferences.exists(doc.id)) {
        resultMap.set(doc.id, doc);
      }
    });
    docs?.forEach((doc) => {
      if (!this.documentReferences.exists(doc.id) || resultMap.has(doc.id)) {
        return;
      }
      if (this.match(doc, where)) {
        resultMap.set(doc.id, doc);
      }
    });

    let result = Array.from(resultMap.values());
    result = result.filter((doc) => this.match(doc, where));
    return result;
  };

  /**
   *
   * @param {BlocDocumentChange<T>[]}docChanges
   * @param {Map<any, any>}d
   */
  private resolveChangedDocuments = (
    docChanges: BlocDocumentChange<T>[],
    d: Map<any, any>
  ) => {
    docChanges.forEach((change) => {
      const docId = change.doc.id;

      switch (change.type) {
        case 'added':
        case 'modified': {
          const {
            docRef,
          } = this.documentReferences.get(docId, {
            method: 'set',
            initialData: immutable(change.doc.data()),
          });
          docRef?.setData(immutable(change.doc.data()));

          change.type === 'added' && this.documentReferences.addRef(docId);
          d.set(docId, docRef);
          break;
        }
        case 'removed': {
          this.documentReferences.unRef(docId);
          d.delete(change.doc.id);
          break;
        }
        default:
          throw new Error(`Unknown document change type: ${change.type}`);
      }
    });
  };

  /**
   * Compare documents arrays for differences.
   * @param {CachedDocumentReference<T>[]}  a First array of documents.
   * @param {CachedDocumentReference<T>[]}  b Secondary array of documents.
   * @returns {boolean} True if the arrays are different.
   */
  haveDocsChanged(
    a: CachedDocumentReference<T>[],
    b: CachedDocumentReference<T>[],
    changedDocRefs: CachedDocumentReference<T>[] = []
  ): boolean {
    if (changedDocRefs.length === 0) {
      return false;
    }
    if (b.length !== a.length) {
      return true;
    }
    for (let i = 0; i < b.length; i++) {
      if (b[i] !== a[i] || !!changedDocRefs.find((d) => d.id === a[i].id)) {
        return true;
      }
    }
    return false;
  }
}

export default BlocQuerySet;
