/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LayoutGroup, motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import CategoryContainer from './CategoryContainer';
import type { CategoryContainerModel, CategoryDescriptor, WithIdInfo } from './CategoryContainerModel';
import type { RenderCategoryItemFunction } from './CategoryItem';

// eslint-disable-next-line no-unused-vars
export type RenderCategoriesFunction<C extends CategoryDescriptor, T extends WithIdInfo = any> =
  (categories: C[], props: CategorizeProps) => JSX.Element;

export interface CategorizeProps<C extends CategoryDescriptor = CategoryDescriptor, T extends WithIdInfo = any> {
  model: CategoryContainerModel<C, T>;
  edit?: boolean;
  onRenderItem?: RenderCategoryItemFunction<T>;
  onRenderCategories?: RenderCategoriesFunction<C, T> | JSX.Element;
}
const Categorize = (props: CategorizeProps) => {
  const {
    model,
    edit,
    onRenderItem,
    onRenderCategories = null,
  } = props;

  const [categories, setCategories,] = useState(model.categories);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    model.setViewPortRef(ref);
    return model.subscribe((categories) => {
      setCategories(categories);
    });
  }, [model,]);

  const content = useMemo(() => {
    if (typeof onRenderCategories === 'function' && onRenderCategories) {
      return onRenderCategories(categories, props);
    }
    return onRenderCategories as JSX.Element;
  }, [categories, onRenderCategories, props,]);

  return content ?? <motion.div
    ref={ref}
    layout
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: 16,
      margin: 16,
      flexBasis: '30%',
    }}>
    <LayoutGroup id="categorize">
      {
        categories.map((category) => {
          return <CategoryContainer
            key={category.id}
            model={model}
            category={category}
            edit={edit}
            onRenderItem={onRenderItem as any}
          />;
        })
      }
    </LayoutGroup>
  </motion.div>;
};

export default Categorize;
