import { useCallback, useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import type { PropertyEditorFunction } from 'components/builder/builders/propertyEditorBuilder';
import type { QRSize } from '@shared/schema/src';
import type { QRSizePropertyEditor } from '../PropertyEditorTypes';

const QRSizeEditor: PropertyEditorFunction<QRSize, QRSizePropertyEditor>= ({
  data,
  propertyName,
  propertyConfig: {
    displayName,
  },
  onSave,
}) => {
  const [value, setValue,] = useState<QRSize>();

  useEffect(() => {
    setValue(data);
  },[data,]);

  const handleChange = useCallback((event: any, value: any) => {
    if (value !== data && onSave) {
      onSave(propertyName, value);
    }
    setValue(value);
    event?.stopPropagation();
  }, [data, onSave, propertyName,]);

  const label = displayName ?? propertyName;

  return <FormControl fullWidth>
    <FormLabel
      component="legend"
    >
      { label }
    </FormLabel>
    <ToggleButtonGroup
      value={value}
      exclusive
      id={propertyName}
      onChange={handleChange}
    >
      <ToggleButton value="100%">
        100%
      </ToggleButton>
      <ToggleButton value="75%">
        75%
      </ToggleButton>
      <ToggleButton value="50%">
        50%
      </ToggleButton>
      <ToggleButton value="25%">
        25%
      </ToggleButton>
    </ToggleButtonGroup>
  </FormControl>;
};

QRSizeEditor.config = {
  type: 'qr-size',
  defaultValue: '50%',
};

export default QRSizeEditor;
