/**
   * Extract the deleted properties compared to the initial data.
   * @param {T} initialData  The initial data to compare against.
   * @returns {string[]}    The list of deleted properties as strings in dot notation to point out to the property.
   */
export function extractDeletedProperties(data: any, initialData: any): string[] {
  const result: string[] = [];

  const traverse = (data: any, initialData: any, path: string) => {
    if (initialData !== undefined &&
      (data === undefined || data === null || typeof data === 'string' && data.trim() === '')) {
      result.push(path);
      return;
    }
    if (typeof initialData === 'object') {
      if (Array.isArray(initialData)) {
        initialData.forEach((item, index) => {
          traverse(data ? data[index] : undefined, item, `${path}[${index}]`);
        });
      } else {
        Object.keys(initialData).forEach((key) => {
          traverse(data ? data[key] : undefined, initialData[key], path ? `${path}.${key}` : key);
        });
      }
    }
  };

  traverse(data, initialData, '');
  return result;
}
