import { CanvasId, CanvasStripInfo, SessionConfig, WithInteractionConfig } from './canvases';
import { ProjectAuthenticationStrategy } from '../project';
import { WithMedia } from '../../../common';
import { ResourceMetadata } from '../ResourceMetadata';

export type SessionId = string;

export type SessionStatus = 'draft' | 'active' | 'closed';

export enum AnswerVisibility {

  /**
   * Show only own answers
   * 
   * TODO: Figure out, does this make any sense?
   */
  OWN = 'own-only',
  /**
   * Show own answer and other's answers
   */
  HIDDEN = 'hidden',
  /**
   * Show other's answer, when the user has made his or her own answer.
   */
  AFTER_ANSWERING = 'after-answer',

  /**
   * Show answers without any pre conditions.
   */
  VISIBLE = 'visible'
}

export const enum SessionVisibility {
  PRIVATE = 'p',
  /**
   * Facilitators only
   */
  FACILITATORS = 'f',
  /**
   * All members including facilitators and panelists
   */
  MEMBERS = 'm',
  /**
   * Everyone can see
   */
  EVERYONE = 'e'
}

export interface TagDescriptor {
  tag: string;
  count: number;
}

export type SessionStatistics = TagStatistics | LabelStatistics;

export interface TagStatistics {
  type: 'tags';
  tags: TagDescriptor[];
}

export interface LabelStatistics {
  type: 'labels';
  labels: TagDescriptor[];
}

export interface Session extends WithMedia, WithInteractionConfig<SessionConfig> {
  name: string;

  desc?: string;

  status: SessionStatus;

  /**
   * The session type. The default value is 'self-paced'
   */
  mode?: 'live' | 'self-paced';

  /**
   * When the session is in 'live' mode, will be used to determine
   * which canvas is active.
   */
  activeCanvasId?: CanvasId;

  metadata?: ResourceMetadata;

  statusText?: string;

  // sessionVisibility: SessionVisibility;
  /**
   * A sequence to create a unique id for the new Canvas. This
   * is a just a counter that will be updated inside of transactions
   * to keep up numbers to raise.
   * 
   * @deprecated Use just unique id for the canvas
   */
  canvasSequence?: number;
  /**
   * Number of Canvases in the session
   */
  canvasCount: number;
  /**
   * Authentication strategy used in the project. The default value is 'restricted'
   */
  authStrategy: ProjectAuthenticationStrategy;
  /**
   * Strip of canvases in order. This information is being used to make it possible to
   * jump to next and prior canvases in a row and to show the range of canvases in the
   * bottom navigation.
   */
  canvasStrip: CanvasStripInfo[];
  /**
   * True when the session itself or one of its canvases has a draft. The draft means a altered version of the
   * document that has not been published yet. The draft is not visible to other users besides facilitators.
   */
  hasDraft?: boolean;
  /**
   * This is the new home for the fields. We will move all the fields from the session
   * under this field gradually. The content field is a new pattern to define the content
   * to minimize the Firestore exceptiomtion rules.
   */
  content?: {
    /**
     * The nature of the session. The default value is 'collaboration'
     * 
     * - collaboration: The session is a collaboration session where facilitators and participants can work together
     * - presentation:  The session is a presentation session where facilitators can present the content to the participants
     * - report:        The session is a report session where facilitators can present the content to the participants and other stakeholders.
     * - note:          The session is a note session where facilitators can take notes.
     */
    nature?: 'collaboration' | 'presentation' | 'report' | 'note',

    visibility?: SessionVisibility;
  };
}
