/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */

export const capitalizeFirstLetter = (string?: string): string => {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/* eslint-disable @typescript-eslint/ban-types */

export const areEqualShallow = (a: object, b: object): boolean => {
  if (!a && b || !b && a) {
    return false;
  }

  for (const key in a) {
    if ((a as any)[key] !== (b as any)[key]) {
      return false;
    }
  }
  return true;
};

export const areEqual = (a: object, b: object): boolean => {
  if (!a && b || !b && a) {
    return false;
  }

  for (const key in a) {
    if (a.hasOwnProperty(key)) {
      const aType = typeof (a as any)[key];
      const bType = typeof (b as any)[key];
      if (aType !== bType) {
        return false;
      }
      if (aType === 'object') {
        if (!areEqual((a as any)[key], (b as any)[key])) {
          return false;
        }
        continue;
      }
      if ((a as any)[key] !== (b as any)[key]) {
        return false;
      }
    }
  }
  return true;
};

/**
 * Limit text to given length and add postFix if the text is longer than length.
 * @param {string}  text          Text to limit
 * @param {number}  length        Max length
 * @param {string}  defaultValue  Default value if text is undefined
 * @param {string=} postFix       Postfix to add if text is longer than length
 * @return {string}               Limited text
 */
export const limitText = (text: string, length: number, defaultValue: string, postFix?: string): string => {
  let result = text ?? defaultValue;

  if (result.length > length) {
    result = result.substring(0, length);
    if (postFix) {
      result += postFix;
    }
  }
  return result;
};
