import { noop } from 'lodash';
import { useContext } from 'react';
import React from 'react';

export type ScreenContextMode = 'standard' | 'superuser';

export interface ScreenContext {

  /**
   * The `screenMode` variable controls the access level and visibility for the system user within the app.
   * It determines whether the system user operates with extended capabilities across the entire platform
   * or with the same level of access as a regular user.
   *
   * Possible values:
   * - 'superuser': Grants the system user full access to all users' data, spaces, and projects,
   *   allowing comprehensive oversight and interaction capabilities. This mode is intended for use cases
   *   where system-level management or troubleshooting is required.
   *
   * - 'standard': Restricts the system user's access to mirror that of a standard user, limiting visibility
   *   and interaction to only those spaces, projects, and data that a regular user would have access to.
   *   This mode is useful for ensuring that the system user can experience the application as a regular user
   *   would, without the distraction or potential confusion of having access to all users' information.
   *
   * Usage:
   * The `screenMode` variable can be toggled by the system user as needed, depending on the task at hand
   * or the desired level of access. This allows for flexible switching between roles, facilitating both
   * comprehensive system management and focused user-level interaction.
   */
  screenMode: ScreenContextMode;

  toggleScreenMode: () => void;

  leftDrawerOpen: boolean;
  setLeftDrawerOpen: (value: boolean) => void;

  rightDrawerOpen: boolean;
  setRightDrawerOpen: (value: boolean) => void;

  contactUsFormOpen: boolean;
  setContactUsFormOpen: (value: boolean) => void;
}

export const ScreenContext = React.createContext<ScreenContext>({
  contactUsFormOpen: false,
  leftDrawerOpen: false,
  rightDrawerOpen: false,
  screenMode: 'standard',
  toggleScreenMode: noop,
  setLeftDrawerOpen: noop,
  setRightDrawerOpen: noop,
  setContactUsFormOpen: noop,
});

const useScreenContext = () => useContext(ScreenContext);

export default useScreenContext;
