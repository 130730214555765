/* eslint-disable @typescript-eslint/no-explicit-any */
import FormControl from '@mui/material/FormControl';

import React, { useCallback, useEffect, useState } from 'react';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';
import type { TextAlign } from '@shared/schema/src/common';

import FormLabel from '@mui/material/FormLabel';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import type { TextAlignPropertyEditor } from '../PropertyEditorTypes';

const TextAlignEditor: PropertyEditorFunction<TextAlign, TextAlignPropertyEditor>= ({
  data,
  propertyName,
  propertyConfig: {
    displayName,
  },
  onSave,
}) => {
  const [value, setValue,] = useState<TextAlign>();

  useEffect(() => {
    setValue(data);
  },[data,]);

  const handleChange = useCallback((event: any, value: any) => {
    if (value !== data && onSave) {
      onSave(propertyName, value);
    }
    setValue(value);
    event?.stopPropagation();
  }, [data, onSave, propertyName,]);

  const label = displayName ?? propertyName;

  return <FormControl fullWidth>
    <FormLabel
      component="legend"
    >
      { label }
    </FormLabel>
    <ToggleButtonGroup
      value={value}
      exclusive
      id={propertyName}
      onChange={handleChange}
      aria-label={displayName}
    >
      <ToggleButton value="left"
        aria-label="left aligned">
        <FormatAlignLeftIcon />
      </ToggleButton>
      <ToggleButton value="center"
        aria-label="centered">
        <FormatAlignCenterIcon />
      </ToggleButton>
      <ToggleButton value="right"
        aria-label="right aligned">
        <FormatAlignRightIcon />
      </ToggleButton>
      <ToggleButton value="justify"
        aria-label="justify" >
        <FormatAlignJustifyIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  </FormControl>;
};

TextAlignEditor.config = {
  type: 'text-align',
};

export default TextAlignEditor;
