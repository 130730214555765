/* eslint-disable indent */
// TODO: Eliminate the need for this css file mess
import '../@mindhiveoy/react-text-editor/styles/editorStyles.css';
import '../_dev/wdyr';
import '../public/styles/global.css';
import './calendar.css';
import './cookieconsent.css';
import './dropdown_menu_items.css';
import './invitation_screen.css';
import './links.css';
import './live.css';
import './statistics.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'whatwg-fetch';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { appWithTranslation } from 'next-i18next';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect } from 'react';
import type { Theme as EmotionTheme } from '@emotion/react';

import type { AppProps, NextWebVitalsMetric } from 'next/app';

// TODO: Lazy loading of components and property editors. This will have an 300-500 kb impact on the first load.
import '../components/builder/registerComponents';
import '../components/builder/registerPropertyEditors';
import { AuthenticationStrategy } from '@mindhiveoy/react-auth';
import { bootstrapRoles } from '@mindhiveoy/auth';
import { registerFirebaseDatabaseController } from '../@mindhiveoy/firestore-bloc/FirestoreDatabaseController';
import { schema } from '../@shared/schema/src/schema';
import AuthProvider from '../components/auth/AuthProvider';
import CookieConsent from '../components/cookies/CookieConsent';

import Head from 'next/head';
import PlatformEventContextProvider from 'components/platformEvents/PlatformEventContextProvider';

import config from 'config';
import createFirebaseAuthPlatformAdapter from '../components/auth/firebasePlatformAdapter/utils/createFirebaseAuthPlatformAdapter';
import dynamic from 'next/dynamic';
import theme from 'theme';

const SignIn = dynamic(() => import('components/auth/SignIn'));
const NotificationComponent = dynamic(() => import('components/notification'));
const CookieFAB = dynamic(() => import('components/cookies/CookieFAB'));
const SignInOverlay = dynamic(() => import('components/auth/SignIn/SignInOverlay'));

// Register database controller for mindcloud's bloc framework
registerFirebaseDatabaseController(schema);

bootstrapRoles(schema.roles);

const firebaseAuthPlatformAdapter = createFirebaseAuthPlatformAdapter();

// TODO Setup Sentry
// config.environment.target.match(/^(staging|production)$/) && Sentry.init({
//   dsn: config.sentry.dsn,
// });

const App = ({
  Component,
  pageProps,
  router,
}: AppProps): JSX.Element => {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  // for listening the cookie consent selection.
  // Update with analytics when provided, then check the given consent with
  // {consent && <> <script async ..........
  // check from Savostamo if needed

  const key = router.route;

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta httpEquiv='X-UA-Compatible'
          content='IE=edge' />
        <link rel="manifest"
          href="/manifest.json"></link>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <MaterialThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme as EmotionTheme}>
          <CssBaseline />
          <PlatformEventContextProvider>
            <AuthProvider
              adapter={firebaseAuthPlatformAdapter as any}
              signIn={SignIn as unknown as never}
              strategy={AuthenticationStrategy.EXPLICIT}
            >
              <Component key={key}
                {...pageProps}
              />
              <CookieFAB key="cookie-fab" />
              <CookieConsent key="cookie-consent" />
              <NotificationComponent key="notification" />
              <SignInOverlay key="sign-overlay" />
            </AuthProvider>
          </PlatformEventContextProvider>
        </EmotionThemeProvider>
      </MaterialThemeProvider>
    </>
  );
};

// eslint-disable-next-line require-jsdoc
export function reportWebVitals(metric: NextWebVitalsMetric): void {
  if (config.environment.target === 'development') {
    console.debug(metric);
  }
  // TODO Report vitals
}

export default appWithTranslation(App);
