import { AnalyzeMatrix2D } from './AnalyzeMatrix2D';
import { useComponentDataContext } from 'utils/hooks/useComponentDataContext';
import { useEffect, useState } from 'react';
import LoadingIndicator from 'components/common/LoadingIndicator';
import type { AnalyzeOptions, Matrix2DQuestionCanvas, Session } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

interface Canvas2DMatrixGraphProps {
  canvas: WithId<Matrix2DQuestionCanvas>;
  session?: WithId<Session>;
  graphBackgroundColor?: string;
}
const Matrix2DCanvasGraph = ({
  canvas,
  session,
}: Canvas2DMatrixGraphProps): JSX.Element => {
  const {
    model,
  } = useComponentDataContext<WithId<Matrix2DQuestionCanvas>>();

  const [analyzeOptions, setAnalyzeOptions,] = useState<AnalyzeOptions>(
    () => model.component?.data?.analyzeOptions as any
  );

  useEffect(() => model.subscribeToDataChange(({
    newValue,
  }) => setAnalyzeOptions(newValue?.data?.analyzeOptions as any ?? null)), [model,]);

  return canvas ?
    <AnalyzeMatrix2D
      canvas={canvas}
      session={session}
      analyzeOptions={analyzeOptions}
    /> :
    <LoadingIndicator />;
};

export default Matrix2DCanvasGraph;
