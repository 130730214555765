import { pathHierarchy } from './getKeyPathString';
import type { CacheKeyPath } from '.';

/**
 * Validate the key path to not have any missing values at the start or in the middle of the
 * path hierarchy.
 *
 * @param keyPath
 */
export const validateKeyPath = (keyPath: CacheKeyPath) => {
  // Seek for the last field that is defined, then check that all fields before that
  // in pathHierarchy are also defined. If not throw an error.
  let index = pathHierarchy.length - 1;

  while (index >= 0) {
    const field = pathHierarchy[index];
    if (keyPath[field]) {
      break;
    }
    index--;
  }
  if (index < 0) {
    throw new Error(`The keypath is empty.`);
  }

  for (let i = index - 1; i >= 0; i--) {
    const field = pathHierarchy[i];
    if (!keyPath[field]) {
      throw new Error(`Missing value for ${field} in the key path`);
    }
  }
};
