import { $generateNodesFromDOM } from '@lexical/html';
import { $getRoot } from 'lexical';
import { useEffect, useRef } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { AllowedStyle } from 'components/editing/LexicalInplaceEditor';

interface SetDataPlugin {
  htmlString?: string;
  /**
   * Allowed styles to be used in the editor
   */
  allowedStyles?: AllowedStyle[];
}
/**
 * SetData plugin to set the editor content from a HTML string
 */
const SetDataPlugin = ({
  allowedStyles,
  htmlString,
}: SetDataPlugin) => {
  const [editor,] = useLexicalComposerContext();

  const currentHtml = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (!htmlString || htmlString === currentHtml.current) {
      return;
    }
    currentHtml.current = htmlString;

    editor.update(() => {
      if (!currentHtml.current) {
        return;
      }
      // In the browser you can use the native DOMParser API to parse the HTML string.
      const parser = new DOMParser();
      let html = currentHtml.current;
      // When editor is used without a paragraph, we need to wrap the content in a paragraph
      // to make Lexical Editor work correctly
      if (allowedStyles &&
        !allowedStyles.includes('paragraph') &&
        !html.startsWith('<p') &&
        !html.startsWith('<div') &&
        !html.startsWith('<h')
      ) {
        html = `<p>${html}</p>`;
      }
      const dom = parser.parseFromString(html, 'text/html');

      // Once you have the DOM instance it's easy to generate LexicalNodes.
      const nodes = $generateNodesFromDOM(editor, dom);

      const root = $getRoot();
      root.clear();
      nodes.forEach((n) => root.append(n));
    }, {
      tag: 'collaboration',
    });
  }, [allowedStyles, editor, htmlString,]);

  return null;
};

export default SetDataPlugin;
