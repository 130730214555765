import FormControl from '@mui/material/FormControl';

import { Button, Typography } from '@mui/material';
import { css, keyframes } from '@emotion/react';
import { useAnswers } from 'bloc/answer/AnswersBloc';
import { useCanvas } from 'bloc/canvas/CanvasBloc';
import { useRouter } from 'next/router';
import FormLabel from '@mui/material/FormLabel';
import GeneralDialog from 'components/info/GeneralDialog';
import PropertyEditorLegend from '../PropertyEditorLegend';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled$ from 'utils/react/styled$';
import type { Canvas, CanvasParams, StatCharacteristics } from '@shared/schema/src';
import type { EraseDataPropertyEditor } from '../PropertyEditorTypes';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

const wave = keyframes`
  0% { 
    background-position: 0% 0%;
    scale: 1; 
  }
  50% { 
    background-position: 50% 0%; 
    scale: 1.05; 
  }
  100% { 
    background-position: 100% 0%; 
    scale: 1; 
  }
`;

// const rotate = keyframes`
//   0% {
//     transform: rotateZ(0deg);
//   }
//   100% {
//     transform: rotateZ(360deg);
//   }
// `;

// animation: ${$erasing && `${wave} 2s linear infinite`};
// const StyledButton = styled$(Button)<{
//   $erasing: boolean
// }>(({
//   $erasing,
// }) => `
//   animation: ${rotate} 2s linear infinite !important;
//   // background-image: ${$erasing && 'linear-gradient(90deg,#ff8000,#eb0f46,#ff8000,#eb0f46)'};
//   // background-size: 300% 100%;
//   // background-color: red;
// `);

export const StyledButton = styled$(Button)<{
  $erasing: boolean
}>(({
  $erasing,
  theme,
}) => css`
  ${$erasing ? `animation: ${wave} 2s linear infinite !important;` : ''}
  background-image: ${$erasing ? 'linear-gradient(90deg,#ff8000,#eb0f46,#ff8000,#eb0f46)' : 'none'};
  background-size: 300% 100%;
  background-color: red;
  color: ${theme.palette.common.white};
`);

const EraseDataPropertyEditorInstance:
  PropertyEditorFunction<StatCharacteristics, EraseDataPropertyEditor> = () => {
    const {
      query,
    } = useRouter();

    const [canvas, setCanvas,] = React.useState<Canvas>();

    const canvasBloc = useCanvas(query as CanvasParams);

    const answersBlock = useAnswers(query);

    const [open, setOpen,] = useState(false);
    const [erasing, setErasing,] = useState(false);

    useEffect(() => canvasBloc.subscribe(
      ['statistics',],
      setCanvas,
      (error) => console.error(error)
    ), [canvasBloc, query?.canvasId,]);

    const handlePromptEraseAnswers = useCallback(() => {
      setOpen(true);
    }, []);

    const handleEraseData = useCallback(async () => {
      setOpen(false);
      try {
        setErasing(true);
        await answersBlock.eraseAllAnswers();
      } finally {
        setErasing(false);
      }
    }, [answersBlock,]);

    const handleCancelDialog = useCallback(() => {
      setOpen(false);
    }, []);

    const message = useMemo(() => {
      switch (canvas?.statistics?.count) {
        case 0:
          return <span>No answers</span>;
        case 1:
          return <span>There is <b>one</b> question answer</span>;
        default:
          return <span>There are <b>{canvas?.statistics?.count}</b> question answers</span>;
      }
    }, [canvas?.statistics?.count,]);

    if (!canvas?.statistics?.count) {
      return null;
    }
    return <>
      <FormControl fullWidth>
        <PropertyEditorLegend label="User data" >
          <FormLabel
            component="legend"
          >
          </FormLabel>
          <Typography>
            {message}
          </Typography>
          <StyledButton
            $erasing={erasing}
            fullWidth
            disabled={!canvas?.statistics?.count || erasing}
            onClick={handlePromptEraseAnswers}>{erasing ? 'Erasing data..,' : 'Erase data'}</StyledButton>

          {/*
          TODO: Erasing of comments
          */}
        </PropertyEditorLegend>

      </FormControl>
      <GeneralDialog
        open={open}
        title="Erase user data"
        description='This operation can not be reverted. Are you sure you want to erase all user data for this question?'
        acceptText='Erase'
        cancelText='cancel'
        acceptButtonStyle={{
          backgroundColor: 'red',
        }}
        cancelColor='inherit'
        onCancel={handleCancelDialog}
        onAccept={handleEraseData}
      />
    </>;
  };

EraseDataPropertyEditorInstance.config = {
  type: 'erase-data',
  displayName: 'Data',
};

export default EraseDataPropertyEditorInstance;
