import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { firebaseApp } from 'schema';
import { processInvitation } from 'bloc/invitation/initiationApi';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import App from 'components/App';
import DefaultScreenLayout from 'components/layout/DefaultScreenLayout';
import SignIn from 'components/auth/SignIn';
import type { Invitation, ShortUserRoleId } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

/**
 * Full window size Sign in screen. The screen will identify if the invitationId is provided in the query string and will
 * fetch the invitation data from the database. The invitation data will be passed to the SignIn component.
 *
 * Use this screen to allow users to sign in to the application in pages that require authentication.
 */
export const SignInScreen = () => {
  const {
    query: {
      invitationId,
    },
  } = useRouter();

  const [invitation, setInvitation,] = useState<WithId<Invitation<ShortUserRoleId>>>();

  useEffect(() => {
    const fetch = async () => {
      if (!invitationId) {
        return;
      }

      try {
        const firestore = getFirestore(firebaseApp());

        const invitationSnapshot = await getDoc(
          doc(firestore, 'invitations', invitationId as string)
        );

        if (invitationSnapshot.exists()) {
          setInvitation({
            ...invitationSnapshot.data(),
            _id: invitationSnapshot.id,
          } as any);
        }
      } catch (error) {
        console.error('Failed to fetch invitation', error);
      }
    };
    fetch();
  }, [invitationId,]);

  const handleSignIn = useCallback(async (invitation?: WithId<Invitation<ShortUserRoleId>>) => {
    if (!invitation) {
      return;
    }

    try {
      await processInvitation({
        invitationToken: invitation._id,
        userAccepted: true,
      });
    } catch (error) {
      console.error('Failed to process invitation', error);
    }
  }, []);

  return (
    <App>
      <DefaultScreenLayout>
        <SignIn invitation={invitation}
          onSignedIn={handleSignIn}
        />
      </DefaultScreenLayout>
    </App>);
};
