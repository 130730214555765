import { ADMIN_BUNDLE } from '@shared/schema/src/https/bundles';
import { createBundleFunctionFactory } from 'utils/firebase/bundles/createBundleFunctionFactory';
import type { CreateSessionMessage, UpdateSessionMessage } from '@shared/schema/src';
import type { PublishSessionDraftMessage } from '@shared/schema/src/https/session/publishSessionChanges';
import type { RevertSessionDraftMessage } from '@shared/schema/src/https/session/revertSessionChanges';
import type { SaveCanvasDraftMessage } from '@shared/schema/src/https/session/saveCanvasDraft';
import type { SaveSessionDraftMessage } from '@shared/schema/src/https/session/saveSessionDraft';

const factory = createBundleFunctionFactory(ADMIN_BUNDLE);

export const updateSession = factory<UpdateSessionMessage>('update-session');

export const createSession = factory<CreateSessionMessage>('create-session');

export const saveSessionDraft = factory<SaveSessionDraftMessage>('save-session-draft');

export const saveCanvasDraft = factory<SaveCanvasDraftMessage>('save-canvas-draft');

export const publishSessionDraft = factory<PublishSessionDraftMessage>('publish-session-draft');

export const revertSessionDraft = factory<RevertSessionDraftMessage>('revert-session-draft');
