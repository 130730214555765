/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ComponentFieldSelectionModel } from '../models/selectionModels/ComponentFieldSelectionModel';
import { WidgetCellEditor } from './WidgetCellEditor';
import { resolveEditorMode } from 'utils/urls';
import { resolveFieldPaths } from './resolveFieldPaths';
import { resolveWidgetLayoutStyle } from './resolveWidgetLayoutStyle';
import { useComponentDataContext } from 'utils/hooks/useComponentDataContext';
import { useComponentSelectionContext } from 'utils/hooks/useComponentSelectionContext';
import { useRouter } from 'next/router';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import React, {
  useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import componentBuilder from '../builders/componentBuilder';
import styled from '@emotion/styled';
import usePropertyFieldPath from 'utils/hooks/usePropertyFieldPath';
import type { BuilderComponentPropsBaseWithId, BuilderComponentType } from './PageWidgetRenderer';
import type { CSSProperties } from 'react';
import type { Layout } from '@shared/schema/src';

const Editor = styled.div`
  position: relative;
  display: flex;
  width: 100%; 
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  overflow: visible;
  z-index: 0;
`;

export type AddPosition = 'before' | 'after';

export interface WidgetBounds {
  left: number;
  top: number;
  height: number;
  width: number;
}

export type SaveFunction = (id: string, propertyName: string, value: any) => void;

export const AddWidgetButton = styled(ControlPointIcon)`
  background-color: white;
  border-radius: 50%;
`;

const ComponentEditorSaveContext = React.createContext<SaveFunction>(() => { });

export interface CanvasEditorProps {
  layout: Layout;
  /**
   * Optional filter of types to be shown on the editor. This filter only restricts components
   * that can be added in editor. It still can contain some elements that are registered to
   * component builder.
   */
  componentTypes?: BuilderComponentType[];
}

const layoutElementCountMap: {
  readonly [type in Layout]: number;
} = {
  'single': 1,
  'two-column': 2,
  'three-column': 3,
  'matrix-2x2': 4,
  '1-grow': 2,
  'grow-1': 2,
};

const CanvasEditor = ({
  layout,
  componentTypes,
}: CanvasEditorProps) => {
  const {
    query,
  } = useRouter();
  const editMode = resolveEditorMode(query);

  const {
    model,
    getProperty,
    updateProperty,
  } = useComponentDataContext();

  const {
    propertyFieldPath,
  } = usePropertyFieldPath();

  const getElements = useCallback(() => {
    if (!model) {
      return [];
    }
    // const fieldPath = resolveFieldPaths(propertyFieldPath);
    const properties = getProperty(propertyFieldPath) ?? [];
    const count = layoutElementCountMap[layout];

    const elements: BuilderComponentPropsBaseWithId[] = [];

    for (let i = 0; i < count; i++) {
      const element = properties[i] ?? null;
      elements.push(element);
    }
    return elements;
  }, [getProperty, layout, model, propertyFieldPath,]);

  const [elements, setElements,] = useState<BuilderComponentPropsBaseWithId[]>(getElements());
  const {
    setSelection,
  } = useComponentSelectionContext();

  useEffect(() => model?.subscribeToDataChange(() => {
    setElements(getElements());
  }), [getElements, model,]);

  const handleClick = useCallback((index: number) => {
    const componentPath = resolveFieldPaths(propertyFieldPath, `[${index}]`);
    const component = model.getPropertyValue(componentPath);
    if (!component) {
      return;
    }
    const config = componentBuilder.getConfig(component.type);

    setSelection(new ComponentFieldSelectionModel(
      model,
      config as any,
      resolveFieldPaths(componentPath, 'data')
    ));
  }, [model, propertyFieldPath, setSelection,]);

  const handleDelete = useCallback((index: number) => {
    const fieldPath = resolveFieldPaths(propertyFieldPath, `[${index}]`);
    updateProperty(fieldPath, null);
    setSelection(null);
  }, [propertyFieldPath, setSelection, updateProperty,]);

  const handleSetClick = useCallback(
    (componentType: BuilderComponentType, index: number) => {
      if (index < 0) {
        return;
      }
      const componentPath = resolveFieldPaths(propertyFieldPath, `[${index}]`);

      const config = componentBuilder.getConfig(componentType);

      updateProperty(componentPath, {
        type: componentType,
        data: {},
      });

      setSelection(new ComponentFieldSelectionModel(
        model,
        config as any,
        resolveFieldPaths(componentPath, 'data')
      ));
    }, [model, propertyFieldPath, setSelection, updateProperty,]);

  const style: CSSProperties = useMemo(() => {
    return resolveWidgetLayoutStyle(layout, true);
  }, [layout,]);

  const editorStyle: CSSProperties = useMemo(() => ({
    ...style,
    height: '100%',
  }), [style,]);

  return <Editor
    key="component-editor"
    style={editorStyle}
  >
    {
      elements.map((props, index) => {
        return <WidgetCellEditor
          key={index}
          component={props}
          cellIndex={index}
          componentTypes={componentTypes}
          editMode={editMode}
          onClick={handleClick}
          onDelete={handleDelete}
          onSelect={handleSetClick}
        />;
      })
    }
  </Editor>;
};

export const useComponentEditorSaveContext = () => {
  return useContext(ComponentEditorSaveContext);
};

export default CanvasEditor;
