import { motion } from 'framer-motion';
import { useRef } from 'react';
import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

const Container = styled(motion.div)`
  overflow-y: hidden;
  width: 100%;
`;

const variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  collapsed: {
    opacity: 0,
    height: 0,
  },
};

interface AnimateHeightProps {
  visible: boolean;
}
const transition = {
  ease: 'easeInOut',
  duration: 0.3,
};
/**
 * Animates the height of the children when visible is toggled.
 *
 * @param param0
 * @returns
 */
const AnimateHeight = ({
  children,
  visible,
}: PropsWithChildren<AnimateHeightProps>) => {
  const ref = useRef(null);

  return (
    <Container
      initial={visible ? 'open' : 'collapsed'}
      animate={visible ? 'open' : 'collapsed'}
      inherit={false}
      variants={variants}
      transition={transition}
    >
      <div
        ref={ref}>
        {children}
      </div>
    </Container>
  );
};

export default AnimateHeight;
