import { createContext, useContext } from 'react';

interface HTMLEditorContextType {
  fixedToolbar?: boolean;
}

const HTMLEditorContext = createContext<HTMLEditorContextType>({
  fixedToolbar: false,
});

const useHTMLEditorContext = () => useContext(HTMLEditorContext);

const HTMLEditorProvider = HTMLEditorContext.Provider;

export {
  HTMLEditorContext,
  HTMLEditorProvider,
  useHTMLEditorContext
};
