import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';

export const EditorArea = styled(motion.div)(({
  theme,
}) => `
  border-width: 1px;
  border-style: solid;
  width: 100%;
  overflow: hidden;
  border-radius: ${theme.shape.borderRadius}px;
  transition: height 0.3s ease-in-out;
  // hide horizontal scrollbar
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`);

export const InputArea = styled$(motion.div)<{
  $focused: boolean;
}>(({
  theme,
  $focused,
}) => `
  padding-top: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(1)};
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(2)};
  width: 100%;
  min-height: 1.5rem;
  max-height: 15vh;
  overflow: auto;
  cursor: text;
  position: relative;
  margin-bottom: ${theme.spacing(2)};
  transition: height 0.3s ease-in-out;
  @media (max-width: 390px) {
    max-height: ${$focused ? '20vh' : '10px'};
  }
`);

export const Placeholder = styled.div(({
  theme,
}) => `
  overflow: auto;
  position: absolute;
  text-overflow: ellipsis;
  top: calc(${theme.spacing(1)});
  left: 1em;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
  width: 100%;
  height: 100%;
`);
