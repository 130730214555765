import type { AllowedStyle } from '.';

const tagMap: Record<AllowedStyle, string[]> = {
  'bold': ['b',],
  'italic': ['i',],
  'underline': ['u',],
  'link': ['a',],
  'paragraph': ['p',],
  'line-break': ['br',],
  'list': ['ul', 'li',],
  'ordered-list': ['ol', 'li',],
  'header-1': ['h1',],
  'header-2': ['h2',],
  'header-3': ['h3',],
};

export const extractAllowedTags = (allowedStyles: AllowedStyle[] = []): string[] => {
  if (!allowedStyles) {
    return [];
  }
  const tagSet = new Set<string>();

  allowedStyles.forEach((style) => {
    const tags = tagMap[style];
    if (!tags) {
      throw new Error(`Unknown style ${style}`);
    }
    tags.forEach((tag) => {
      tagSet.add(tag);
    });
  });

  return Array.from(tagSet);
};
