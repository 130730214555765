/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable valid-jsdoc */
import { AnimatePresence, motion } from 'framer-motion';
import { mapPropsToEntries } from './utils/propertyUtils';
import { setObjectField } from '@mindhiveoy/foundation';
import { useCallback, useMemo } from 'react';
import FormControl from '@mui/material/FormControl';
import InspectorRow from '../inspector/InspectorRow';
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import propertyEditorBuilder from '../builders/propertyEditorBuilder';
import type { BuilderConfigPropsFunction, PropertyConfigs } from '../builders/componentBuilder';
import type { PropertyChangeDescriptor, PropertyEditor, PropertyEditorProps } from './PropertyEditorTypes';
import type { PropertyChangeFunction } from './PropertyEditorTypes';

export interface CompositePropertyEditorControlProps extends Omit<PropertyEditorProps, 'propertyConfig'> {
  /**
   * The component to edit as a part of the whole data.
   */
  component: any,
  /**
   * The config for the property editor of the component.
   */
  config: Partial<PropertyConfigs<any>> | BuilderConfigPropsFunction<any>;
}

const exitStyle = {
  scaleY: 0,
};
/**
 * Editor making it possible to add property editors for a single object in inspector
 *
 * @param param0
 * @returns
 */
const CompositePropertyEditorControl = ({
  component,
  config,
  data,
  propertyNamePath,
  propertyName,
  onSave,
  ...props
}: CompositePropertyEditorControlProps) => {
  const {
    properties, entries,
  } = useMemo(() => {
    const properties = typeof config === 'function' ? config(component, data) : config; // TODO typing
    const entries = mapPropsToEntries(properties);

    return {
      properties,
      entries,
    };
  }, [component, config, data,]);

  const handleDelete = useCallback(() => {
    throw new Error('not implemented yet.');
  }, []);

  const handleSave = useCallback((
    childPropertyName: any,
    value: any,
    priorCascades: PropertyChangeDescriptor[] = []
  ) => {
    let newData = data ? cloneDeep(data) : undefined;

    const onPropertyChange: PropertyChangeFunction<any> = properties[childPropertyName]?.onPropertyChange;

    const globalCascadingChanges = priorCascades;

    const relativeCascadingChanges: PropertyChangeDescriptor[] = [];

    if (onPropertyChange) {
      onPropertyChange({
        propertyValue: value as any,
        data,
        component,
        propertyName: childPropertyName,
        preventPropertyChange: () => {
          console.error('not implemented yet.');
        },
        updateProperty: (propertyName: any, value: any) => {
          relativeCascadingChanges.push({
            propertyName,
            value,
            type: 'relative',
          });
        },

        updateComponentProperty: (propertyName: any, value: any) => {
          globalCascadingChanges.push({
            propertyName,
            value,
            type: 'absolute',
          });
        },
      });
    }
    if (value === null || value === undefined) {
      delete newData[childPropertyName];
    } else {
      newData[childPropertyName] = cloneDeep(value);
    }
    // Relative cascading changes are applied to the new data before saving the whole object
    // to parent component.
    relativeCascadingChanges.forEach(({
      propertyName,
      value,
    }) => {
      newData = setObjectField(newData, propertyName, value);
    });

    // const newValue = getObjectField(newData, propertyNamePath);
    onSave && onSave(propertyName, newData, globalCascadingChanges);
  }, [component, data, onSave, properties, propertyName,]);

  return <motion.div
    exit={exitStyle}
  >
    <FormControl
      fullWidth
      {...(props as any)} // TODO: Validate these props
    >
      <AnimatePresence initial={false}>
        {
          entries.map(([
            childPropertyName,
            propertyConfig,
          ], index) => {
            if (!propertyConfig) {
              return null;
            }
            const type = (propertyConfig as PropertyEditor).type;
            const Editor = propertyEditorBuilder.get(type);
            if (!Editor /* || !component.data*/) {
              return <div key={index}>Error: No editor found for type: <b>{type}</b></div>;
            }

            if (!data) {
              return null;
            }
            return <InspectorRow
              key={`${propertyNamePath}.${childPropertyName}`}
            >
              <Editor
                component={component}
                propertyNamePath={`${propertyNamePath}.${childPropertyName}`}
                propertyName={childPropertyName}
                propertyConfig={propertyConfig as PropertyEditor}
                data={(data as any)[childPropertyName] as any}
                onDelete={handleDelete}
                onSave={handleSave}
              />
            </InspectorRow>;
          })}
      </AnimatePresence>
    </FormControl>
  </motion.div>;
};

export default CompositePropertyEditorControl;
