import { MediaCopyright } from '.';
import React from 'react';
import styled from '@emotion/styled';
import type { Media } from '@shared/schema/src';
import type { Video } from '@shared/schema/src/https/media/pexelTypes';

export const Link = styled.a`
  text-decoration: none;
  font-weight: bold;
  color: white;
`;

interface PexelCopyrightProps {
  item: Video;
}
export const PexelCopyright = ({
  item,
}: PexelCopyrightProps) => {
  return <>
    <MediaCopyright media={extractMediaAuthorInfoFromPexel(item)} />
  </>;
};

export const extractMediaAuthorInfoFromPexel = (item: Video): Partial<Media> => ({
  type: 'video',
  author: item.user.name,
  authorUrl: item?.user?.url,
  source: 'Pexels',
  sourceUrl: item.url,
});
