import { BackgroundVideo, StyledCard, VideoCard } from '..';
import { useAuth } from '@mindhiveoy/react-auth';
import { useEffect, useRef } from 'react';
import { useTheme } from '@emotion/react';
import React from 'react';
import type { PropsWithChildren } from 'react';
import type { ShortUserRoleId, User } from '@shared/schema/src';
import type { TypedMediaContainerProps } from '..';

/**
 * Renders a video media container component with optional video playback functionality.
 *
 * @param {PropsWithChildren<TypedMediaContainerProps>} props - The component props.
 * @param {boolean} props.blur - Whether the video container should be blurred.
 * @param {ReactNode} props.children - The child components to render inside the video container.
 * @param {boolean} props.fullScreen - Whether the video container should be displayed in full screen.
 * @param {boolean} props.gestures - Whether the video container should support gestures.
 * @param {CSSProperties} props.style - The inline styles to apply to the video container.
 * @param {Media} props.media - The media object containing video information.
 * @param {boolean} props.noRadius - Whether the video container should have rounded corners.
 * @param {string} props.url - The URL of the video to play.
 * @param {() => void} props.onClick - The function to call when the video container is clicked.
 * @return {JSX.Element} The rendered video media container component.
 */
export const VideoMediaContainer = ({
  blur,
  children,
  fullScreen,
  gestures,
  style,
  media,
  noRadius,
  url,
  onClick,
}: PropsWithChildren<TypedMediaContainerProps>) => {
  const theme = useTheme();

  const videoRef = useRef<HTMLVideoElement>(null);

  const {
    user: authUser,
  } = useAuth();

  useEffect(() => {
    if (typeof window === 'undefined' || !authUser || !media || !videoRef) {
      return;
    }

    // TODO: add freezeVideoPlayback to mindcloud's useAuth typings so we don't need this anymore
    const user = authUser as User<ShortUserRoleId>;
    // if preference is either unset or true, we play the video
    const videoPlayback = !user?.extra?.freezeVideoPlayback;
    // TODO handle video sid
    const onContentLoaded = async () => {
      try {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        if (videoPlayback) {
          // Check if the video is still in the document before attempting to play
          if (document.contains(videoElement)) {
            if (videoElement.readyState !== undefined) {
              await videoElement.play();
            } else {
              videoElement.load();
            }
          }
        } else {
          if (videoElement.readyState !== undefined && document.contains(videoElement)) {
            await videoElement.play();
            videoElement.pause(); // Hack to show the still image from the video
          }
        }
      } catch (error) {
        console.error('An error during video playback: ', error);
      }
    };

    // Use canplaythrough to ensure the video is ready before attempting playback
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('canplaythrough', onContentLoaded);
    }

    // Cleanup on component unmount
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('canplaythrough', onContentLoaded);
      }
    };
  }, [authUser, media, videoRef,]);

  return url ?
    <VideoCard
      $fullScreen={fullScreen}
      $blur={blur}
      style={style}
      $gestures={gestures}
      onClick={onClick}
    >
      <BackgroundVideo
        ref={videoRef}
        loop
        autoPlay
        width={'100%'}
        height={200}
        muted
        url={url}
      >
        <source src={url}
          type="video/mp4" />
      </BackgroundVideo>
      {children}
    </VideoCard> :
    <StyledCard
      $fullScreen={fullScreen}
      $blur={blur}
      $noRadius={noRadius}
      style={{
        ...style,
      }}
      onClick={onClick}
      $gestures={gestures}
      $image={theme.shape.imagePlaceholder}
    >
      {children}
    </StyledCard>;
};
