/* eslint-disable sonarjs/no-duplicate-string */

import { PropertyFieldPathProvider } from 'utils/hooks/usePropertyFieldPath';
import { useTranslation } from 'next-i18next';
import CanvasEditor from '../../../../components/builder/widgets/CanvasEditor';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import React from 'react';
import type { BuilderComponentType } from '../../../../components/builder/widgets/BuilderComponentTypes';
import type { CanvasId, LayoutCanvas, WithPathParamProps } from '../../../../@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export interface LayoutCanvasEditorProps extends WithPathParamProps {
  canvasId: CanvasId;
  canvas: WithId<LayoutCanvas>;
}

const componentTypes: BuilderComponentType[] = [
  'image',
  'statistics',
  'graph-2d',
  'free-text',
  // 'question-description',
  'qr-code',
  'video',
  // 'talking-head',
];

const LayoutCanvasEditor = ({
  canvas,
}: LayoutCanvasEditorProps) => {
  const {
    t,
  } = useTranslation();

  return canvas ?
    <PropertyFieldPathProvider propertyFieldPath='elements'>
      <CanvasEditor
        layout={canvas.layout}
        componentTypes={componentTypes}
      />
    </PropertyFieldPathProvider> :
    <LoadingIndicator loaderTitle={t('loading-canvas')} />;
};

export default LayoutCanvasEditor;
