import { DrawerListIcon } from '.';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import styled$ from 'utils/react/styled$';
import type { UserProfileDrawerMenuItemDescriptor, UserProfileDrawerMenuItemId } from '.';

const MenuItemRow = styled$(ListItem)<{
  $withIndentation?: boolean;
}>(({
  theme,
  $withIndentation,
}) => `
  padding: 0;
  margin-left: ${$withIndentation ? theme.spacing(3) : 0};
  cursor: pointer;
`);

const Text = styled$(ListItemText)`
  display: flex;
  .MuiTypography-root {
    margin: 0;
  }
`;

interface UserProfileDrawerMenuItemProps {
  item: UserProfileDrawerMenuItemDescriptor;
  selected: boolean;
  withIndentation?: boolean;
  onClick: (id?: UserProfileDrawerMenuItemId) => void;
}

const UserProfileDrawerMenuItem = ({
  item: {
    id,
    label,
    icon,
  },
  // withIndentation,
  selected,
  onClick,
}: UserProfileDrawerMenuItemProps) => {
  const {
    t,
  } = useTranslation();

  const handleClick = useCallback(
    () => onClick?.(id),
    [id, onClick,]
  );

  return <MenuItemRow
    selected={selected}
    key={id}
    onClick={handleClick}>
    <DrawerListIcon >
      {icon}
    </DrawerListIcon>
    <Text primary={t(label)} />
  </MenuItemRow>;
};

export default UserProfileDrawerMenuItem;
