/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthenticationType } from '@mindhiveoy/react-auth';
import {
  FacebookAuthProvider, getAuth,
  getRedirectResult, onAuthStateChanged, onIdTokenChanged, signInAnonymously, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect,
  signOut
} from 'firebase/auth';
import { NotificationPermission } from '../../../utils/firebase/notifications';
import { _signUpUserWithEmail } from 'bloc/admin/userApi';
import { cleanOutLocalStorage } from './utils/cleanOutLocalStorage';
import { delay } from 'utils/timeUtils';
import { doc, onSnapshot } from 'firebase/firestore';
import { firebaseApp } from 'schema';
import { isBrowser } from '@mindhiveoy/foundation';
import { raiseAsAuthError } from './utils/raiseAsAuthError';
import { removeStorageValue, setStorageValueWithExpiry } from '../../../utils/firebase/localStorageUtils';
import { schema } from '@shared/schema/src/schema';
import { sendPlatformMessageWithFirebaseUserInfo } from './utils/sendPlatformMessageWithFirebaseUserInfo';
import { sendPlatformMessageWithPlatformUserInfo } from './utils/sendPlatformMessageWithPlatformUserInfo';
import RoleManager from '../../../utils/firebase/roleManager';
import resolveAuthStatus from './utils/resolveAuthStatus';
import runtimePlans from '@shared/schema/src/runtimePlans';
import type { AdvancedAuthState, AuthStatus } from '@mindhiveoy/react-auth/dist/AuthContext';
import type { Auth, GoogleAuthProvider } from 'firebase/auth';
import type { AuthenticationPlatformAdapter } from '@mindhiveoy/react-auth';
import type { DocumentSnapshot, Firestore } from 'firebase/firestore';
import type { Email, PhoneNumber, UserDisplayName, UserToken } from '@mindhiveoy/schema';
import type { FeatureId, ModuleId, PlanId, UserRoleId } from '@shared/schema/src';
import type { AuthError as FirebaseAuthError } from 'firebase/auth';
import type { FirebaseError } from '@firebase/app';
import type { User as FirebaseUser } from 'firebase/auth';
import type { PlatformEventContextType } from 'components/platformEvents/PlatformEventSystem';
import type { ShortUserRoleId } from '@shared/schema/src/schema';
import type { User } from '@shared/schema/src';
import type { UserDocumentReference } from 'schema/users';

export interface AuthContext extends AdvancedAuthState<any, any, any, any, User<ShortUserRoleId>> { }

const DEBUG = false;

export interface AuthProviderFactory {
  newGoogleAuthProvider(): GoogleAuthProvider;
  newFacebookAuthProvider(): FacebookAuthProvider;
}

// type AuthContext = InternalState<PlanId, ModuleId, FeatureId, ShortUserRoleId, User<ShortUserRoleId>>;

// TODO Move to mind-cloud
/**
 * Firebase authentication adapter for mindcloud. This adapter is used to connect mindcloud to firebase authentication.
 *
 */
export class FirebaseAuthPlatformAdapter implements AuthenticationPlatformAdapter<PlanId, ModuleId, FeatureId, ShortUserRoleId, User<ShortUserRoleId>> {
  public readonly id = 'firebase';

  public readonly supportedAuthTypes = [
    AuthenticationType.EMAIL,
    AuthenticationType.GOOGLE,
  ];

  private _unsubscribeUser?: () => void;

  /**
   *
   * @param auth
   * @param firestore
   * @param platformEventSystem
   * @param authProviderFactory
   */
  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private platformEventSystem: PlatformEventContextType,
    private authProviderFactory: AuthProviderFactory
  ) { }

  private onInitializeCalled = false;

  public onInitialize = async (
    context: any
  ) => {
    if (this.onInitializeCalled) {
      return () => { };
    }

    this.onInitializeCalled = true;

    const auth = this.auth;
    const unsubscribe = this._unsubscribeUser;

    const handleAuthStageChange = async (user: FirebaseUser | null) => {
      // TODO https://firebase.google.com/docs/auth/admin/custom-claims
      unsubscribe?.();

      if (user) {
        // The token must be fetched before reading the user data from the Firestore
        if (isBrowser()) {
          await this.resolveUserDataFromToken(user, context);
          this.requestNotificationPermissions(user);
        }

        const firestore = this.firestore;

        const userRef = doc(firestore, `users/${user.uid}`) as UserDocumentReference;

        let tries = 0;

        const onSnapshotError = (error: FirebaseError) => {
          if (error.code === 'permission-denied' && user !== null) {
            setTimeout(() =>
              this._unsubscribeUser = subscribeToUser(), 500
            );
          };
        };

        const subscribeToUser = () => {
          if (tries > 4) {
            return;
          }
          tries++;
          this._unsubscribeUser?.();

          return onSnapshot(
            userRef,
            this.onUserSnapshot(user, context),
            onSnapshotError
          );
        };

        this._unsubscribeUser = subscribeToUser();
        return;
      }
      if (user === null) {
        try {
          // context.updateUserState(user as any); // TODO: Typing
          // Open sessions will require the user to have a uid even, when they are not authenticated
          context.updateState({
            authInProgress: false,
            // status,
          });
          await signInAnonymously(auth);
        } catch (error) {
          console.error(error);
        } finally {
          // if no user is logged in, we also set authInProgress to false to prevent infinite loading
          context.updateState({
            authInProgress: false,
          });
        }
      }
    };

    const handleAuthError = (error: Error) => {
      console.log(error);
      // in case of error we still set authInProgress to false to prevent infinite loading
      context.updateState({
        authInProgress: false,
      });
    };

    const observationTimeout = DEBUG ? 60 * 60 * 1000 : 3000;
    let initialCallHandled = false;

    /**
     * Sign in with anonymous user if no user is logged in.
     * @returns
     */
    const initializeAuth = async () => {
      let userCredentials = null;

      try {
        console.log('Attempting to get redirect result...');
        userCredentials = await getRedirectResult(this.auth);
        console.log('Redirect result:', userCredentials);
      } catch (error: any) {
        console.error('Error getting redirect result:', error);
      }

      if (userCredentials) {
        const user = userCredentials.user;

        if (user) {
          console.log('User obtained from redirect:', user);
          return Promise.resolve(user);
        }
      } else {
        return new Promise<FirebaseUser | null>(async (resolve, reject) => {
          let handled = false;
          let unsubscribeAuthChange: (() => void) | undefined = undefined;

          const authStateChangeHandler = (user: FirebaseUser | null) => {
            if (initialCallHandled) {
              return;
            }

            sendPlatformMessageWithFirebaseUserInfo(context, user);

            if (user) {
              initialCallHandled = true;
              handled = true;
              unsubscribeAuthChange?.();
              resolve(user);
            }
          };

          // Start observing authentication state
          unsubscribeAuthChange = onAuthStateChanged(auth, authStateChangeHandler);

          // Wait for the observation timeout
          await delay(observationTimeout);

          // End up the thread if the user is already handled
          if (handled) {
            return;
          }

          // Fallback to anonymous sign-in after timeout
          unsubscribeAuthChange?.();
          const unsubscribe = onAuthStateChanged(auth, authStateChangeHandler); // Unsubscribe

          try {
            if (!auth) {
              throw new Error('Auth is not initialized. Probably the app is offline.');
            }
            const userCredential = await signInAnonymously(auth);

            unsubscribe?.();

            if (initialCallHandled) {
              return;
            }
            resolve(userCredential.user);
          } catch (error) {
            reject(error);
          }
        });
      }
    };

    const user = await initializeAuth();

    const status = resolveAuthStatus({
      uid: user?.uid,
      email: user?.email,
    });

    context.updateState({
      user,
      status,
    });

    cleanOutLocalStorage(status);

    const unsubscribeAuthChange = onAuthStateChanged(
      auth,
      handleAuthStageChange,
      handleAuthError
    );

    const unsubscribeTokenChange = onIdTokenChanged(auth, async (user) => {
      if (!user) {
        return;
      }
      try {
        const accessToken = await user.getIdToken(true);
        const result = await user.getIdTokenResult();
        const token = result.claims as unknown as UserToken<PlanId, UserRoleId>;

        context.updateState({
          token,
          accessToken,
        });
      } catch (error: any) {
        // eslint-disable-next-line no-constant-condition
        if (error.code = 'permission-denied') {
          console.debug('Permission denied');
          return;
        }
        console.error(error);
      }
    });

    return () => {
      unsubscribeAuthChange && unsubscribeAuthChange();
      unsubscribeTokenChange && unsubscribeTokenChange();
      this._unsubscribeUser && this._unsubscribeUser();
    };
  };

  public refreshWebToken = async (context: AuthContext) => {
    const auth = getAuth();

    const user = auth.currentUser;

    if (user) {
      const accessToken = await user.getIdToken(true);

      const result = await user.getIdTokenResult();

      const token = result.claims as unknown as UserToken<PlanId, UserRoleId>;

      const status = resolveAuthStatus({
        uid: user.uid,
        email: user.email,
      });

      this.storeAuthState(context, status);

      context.updateState({
        token,
        accessToken,
        status,
      });
    }
  };

  private savedStatuses: AuthStatus[] = ['signing-in', 'signing-up',];

  /**
   * Set the auth state to the local storage.
   *
   * @param context     The auth context
   * @param state
   */
  private storeAuthState = (context: AuthContext, state: AuthStatus) => {
    if (!this.savedStatuses.includes(state)) {
      setStorageValueWithExpiry<AuthStatus>('authState', state, 1000 * 60 * 60 * 24);
    } else {
      removeStorageValue('authState');
    }
  };

  public signInWithEmail = async (
    context: AuthContext,
    email: string,
    password: string
  ) => {
    const auth = this.auth;
    try {
      this.storeAuthState(context, 'signing-in');

      context.updateState({
        status: 'signing-in',
      });

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const appUser: Partial<User<ShortUserRoleId>> = {
        uid: user.uid,
        displayName: user.displayName!,
        email: user.email!,
      };

      console.log(`Operation type: ${userCredential?.operationType}.`);

      context.updateUserState(appUser);
    } catch (error: any) {
      this.raiseAuthError(context, error);
    }
  };

  public signInWIthCredentials = async (
    context: AuthContext,
    authenticationType: AuthenticationType
  ) => {
    const auth = getAuth(firebaseApp());

    this.storeAuthState(context, 'signing-in');

    context.updateState({
      status: 'signing-in',
    });

    switch (authenticationType) {
      case 'google':
        try {
          const provider = this.authProviderFactory.newGoogleAuthProvider();
          provider.setCustomParameters({
            prompt: 'select_account',
          });

          await signInWithPopup(auth, provider);
        } catch (error: any) {
          this.raiseAuthError(context, error);
        }
        break;

      case 'facebook':
        try {
          const provider = new FacebookAuthProvider();
          // provider.setCustomParameters({ prompt: 'select_account' });
          signInWithRedirect(auth, provider);

          // const user = result.user;
        } catch (error: any) {
          this.raiseAuthError(context, error);
        }
        break;

      default:
        throw new Error(`Unsupported authentication type: ${authenticationType}`);
    }
  };

  public signUpWithEmail = async (
    context: AuthContext,
    email: Email,
    password: string,
    displayName: UserDisplayName,
    phoneNumber?: PhoneNumber
  ) => {
    try {
      await _signUpUserWithEmail({
        email,
        password,
        displayName,
        phoneNumber,
      });

      await this.signInWithEmail(context, email, password);
    } catch (error: any) {
      this.raiseAuthError(context, error);
    }
  };

  public signUp = async () => {
    throw new Error('not implemented yet');
  };

  public signOut = async (context: AuthContext) => {
    this.storeAuthState(context, 'signing-out');
    context.updateState({
      status: 'signing-out',
    });

    RoleManager.getInstance(schema.roleContext).clearMemberships();

    const auth = getAuth(firebaseApp());
    await signOut(auth);
  };

  public resetPassword = async () => {
    throw new Error('not implemented yet');
  };

  /**
   * Handle the user's data change in the Firestore.
   * @param user
   * @param context
   * @returns
   */
  private onUserSnapshot = (user: FirebaseUser, context: any) => async (snapshot: DocumentSnapshot) => {
    try {
      if (user.isAnonymous) {
        this.storeAuthState(context, 'anonymous');

        context.updateState({
          user: {
            ...user,
            role: 'g',
            extra: {
              showOnboardingFlow: true,
            },
            email: null,
          },
          isAnonymous: true,
          authenticated: false,
          authInProgress: false,
          role: 'g',
          status: 'anonymous',
          authenticationActivated: false,
        });
        sendPlatformMessageWithFirebaseUserInfo(context, user);
        return;
      }
      if (snapshot.exists()) {
        const _user = snapshot.data();

        this.storeAuthState(context, 'signed-in');

        const appUser: Partial<User<ShortUserRoleId>> = {
          email: _user.email,
          photoURL: _user.photoURL,
          customerId: _user.customerId,
          displayName: _user.displayName,
          extra: _user.extra ?? {
            showOnboardingFlow: true,
          },
          ownedSpaces: _user.ownedSpaces ?? [],
          plan: _user.plan,
          uid: _user.uid,
          id: _user.uid,
          role: _user.role,
          isAnonymous: false,
        };
        RoleManager.getInstance(schema.roleContext).setMemberships(_user.memberships ?? {});

        // Token will be refreshed when ever user data will change. This will
        // verify that that claims will work on situations where member access roles
        // are being changed such as registration.
        const accessToken = await user?.getIdToken(true);
        const result = await user?.getIdTokenResult();

        const token = result.claims as unknown as UserToken<PlanId, UserRoleId>;
        const aUser = appUser as any;
        // TODO: Typing
        aUser.role = token.role;
        aUser.memberships = token.memberships;
        aUser.token = token;
        aUser.accessToken = accessToken;
        // TODO: Sustainable change checking with not need to update the code base
        aUser.runtimePlan = (runtimePlans as any)?.[(user as any).plan ?? 'free'];
        try {
          const u = context.user;
          if (u?.email !== appUser.email ||
            context.user?.photoURL !== appUser.photoURL ||
            u?.runtimePlan !== aUser.runtimePlan ||
            u?.ownedSpaces !== aUser.ownedSpaces ||
            u?.plan !== aUser.plan ||
            u?.role !== aUser.role ||
            u?.customerId !== aUser.customerId
          ) {
            context.updateState({
              user: appUser,
              status: 'signed-in',
            });
          }

          sendPlatformMessageWithPlatformUserInfo(context, _user as any);
        } catch (error: any) {
          if (error.code === 'permission-denied') {
            console.debug('Permission denied');
            return;
          }
          // TODO:: Sentry
          console.error(error);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      // we only set authInProgress to false when we have full user data
      context.updateState({
        authInProgress: false,
      });
    }
  };
  /**
   *
   * @param user
   */
  private requestNotificationPermissions(user: FirebaseUser) {
    try {
      const notificationInstance = NotificationPermission.getInstance();
      notificationInstance.requestPermission(user);
    } catch (error) {
      console.error(error);
    }
  }
  /**
  *
  * @param user
  * @param context
  */
  private async resolveUserDataFromToken(user: FirebaseUser, context: any) {
    try {
      const accessToken = await user.getIdToken(true);
      const result = await user.getIdTokenResult();

      const token = result.claims as unknown as UserToken<PlanId, UserRoleId>;

      context.updateState({
        authenticationActivated: false,
        role: token.role,
        token,
        user: {
          ...token,
          uid: user.uid,
        },
        accessToken,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Update the auth ui state to error and throw the error.
   *
   * @param context
   * @param error
   */
  private raiseAuthError = (context: AuthContext, error: FirebaseAuthError) => {
    context.updateState({
      status: 'error',
    });
    raiseAsAuthError(error);
  };
}
