import { ADMIN_BUNDLE } from '@shared/schema/src/https/bundles';
import { createBundleFunctionFactory } from 'utils/firebase/bundles/createBundleFunctionFactory';
import type {
  CreateCanvasMessage,
  DeleteCanvasDataMessage, DeleteCanvasMessage, DuplicateCanvasMessage,
  SetCanvasOrderMessage, UpdateCanvasMessage
} from '@shared/schema/src';

const factory = createBundleFunctionFactory(ADMIN_BUNDLE);

export const createCanvas = factory<CreateCanvasMessage>(
  'create-canvas'
);

export const updateCanvas = factory<UpdateCanvasMessage>(
  'update-canvas'
);

export const deleteCanvasData = factory<DeleteCanvasDataMessage>(
  'delete-canvas-data'
);

export const deleteCanvas = factory<DeleteCanvasMessage>(
  'delete-canvas'
);

export const setCanvasOrder = factory<SetCanvasOrderMessage>(
  'set-canvas-order'
);

export const duplicateCanvas = factory<DuplicateCanvasMessage>(
  'duplicate-canvas'
);
