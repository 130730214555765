/* eslint-disable no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAxisRange } from '../propertyEditors/axis/getAxisRange';
import cloneDeep from 'lodash/cloneDeep';
import type { BuilderConfig, BuilderConfigPropsFunction, PropertyConfigs } from '../builders/componentBuilder';
import type { Canvas, LayoutCanvas, Matrix2DQuestionCanvas, RangeItem } from '@shared/schema/src';
import type { PropertyChangeContext } from '../propertyEditors/PropertyEditorTypes';

export const enum CanvasTab {
  LABELS = 'labels',
  CONTENT = 'content',
  DATA = 'data',
  GENERAL = 'general',
  INTERACTION = 'interaction',
  LAYOUT = 'layout',
  LOOK = 'look',
  STATS = 'stats',
  // PUBLISH = 'publish',
}

const configBase: Partial<PropertyConfigs<Canvas>> = {
  'name': {
    type: 'text',
    category: CanvasTab.GENERAL,
  },
  'desc': {
    type: 'html',
    category: CanvasTab.GENERAL,
    defaultValue: '<p><br/></p>',
  },
  'config': {
    type: 'canvas-config',
    category: CanvasTab.INTERACTION,
  },
  // 'commenting': {
  //   type: 'checker',
  //   displayName: 'Commenting',
  //   defaultValue: true,
  //   nullify: true,
  //   nullifyInfo: 'Use session default',
  //   category: CanvasTab.INTERACTION,
  // },
  // 'voting': {
  //   type: 'checker',
  //   displayName: 'Voting',
  //   defaultValue: true,
  //   nullify: true,
  //   nullifyInfo: 'Use session default',
  //   category: CanvasTab.INTERACTION,
  // },
  // 'answerVisibility': {
  //   type: 'picker',
  //   displayName: 'Answer visibility',
  //   defaultValue: 'visible',
  //   category: CanvasTab.INTERACTION,
  //   options: [
  //     {
  //       value: 'own-only',
  //       label: 'Own only',
  //     },
  //     {
  //       value: 'after-answer',
  //       label: 'After answering',
  //     },
  //     {
  //       value: 'visible',
  //       label: 'Visible',
  //     },
  //   ],
  // },
  'media': {
    type: 'media',
    label: 'Background',
    category: CanvasTab.LOOK,
  },
};

const layoutConfig: Partial<PropertyConfigs<LayoutCanvas>> = {
  layout: {
    type: 'picker',
    displayName: 'Layout',
    defaultValue: 'single',
    category: CanvasTab.GENERAL,
    order: 110,
    options: [
      {
        label: 'Single',
        value: 'single',
      },
      {
        label: 'Two columns',
        value: 'two-column',
      },
      {
        label: 'Four cells',
        value: 'matrix-2x2',
      },

    ],
  },
};

const common = {
  erase: {
    type: 'erase-data',
    order: 1000,
    displayName: 'Erase data',
    category: CanvasTab.DATA,
  },
};

const CanvasFormBuilderConfigProps: BuilderConfigPropsFunction<any> = (
  data
): Partial<PropertyConfigs<Canvas>> => {
  if (!data?.type) {
    return {};
  }

  switch (data.type) {
    case '2d': {
      return {
        ...configBase,
        ...common,

        ...data.mode === 'continuous' &&
        data.axis.x?.scaleType === 'numeric' && {
          max: {
            type: 'number',
            defaultValue: 1,
            displayName: 'Maximum value',
            category: CanvasTab.DATA,
            order: 210,
            onPropertyChange: ({
              component,
              propertyValue,
              updateComponentProperty,
            }: PropertyChangeContext<Matrix2DQuestionCanvas>) => {
              // When the user changes the tickLabelsPresentation to 'single', we need to update the
              // tickLabels property to be an array of two values, one for each axis.
              updateComponentProperty('axis.min', -propertyValue);
              updateComponentProperty('axis.max', propertyValue);

              const range = generateRange(component.axis.scaleLength, propertyValue);

              updateComponentProperty('axis.x.min', -propertyValue);
              updateComponentProperty('axis.x.max', propertyValue);

              updateComponentProperty('axis.y.min', -propertyValue);
              updateComponentProperty('axis.x.max', propertyValue);

              updateComponentProperty('axis.x.range', range);
              updateComponentProperty('axis.y.range', range);
            },
          },
        },
        mode: {
          type: 'picker',
          displayName: 'Display data',
          defaultValue: 'discrete',
          // section: 'Display',
          options: [
            {
              value: 'discrete',
              label: 'Discrete',
            },
            {
              value: 'continuous',
              label: 'Continuous',
            },
          ],
          category: CanvasTab.DATA,
          order: 100,
          onPropertyChange: ({
            component,
            propertyValue,
            updateComponentProperty,
          }: any) => { // TODO: type this
            // eslint-disable-next-line sonarjs/no-nested-switch
            switch (component.type) {
              case '2d': {
                if (component.axis.x.scaleType === 'custom') {
                  return;
                }

                let scaleType = component.axis.x.scaleType;

                if (propertyValue === 'continuous' &&
                  !(scaleType in ['numeric', 'percentage',])) {
                  scaleType = 'numeric';
                  updateComponentProperty('axis.x.scaleType', scaleType);
                  updateComponentProperty('axis.scaleType', scaleType);
                }

                const range = getAxisRange({
                  scaleType,
                  scaleLength: component.axis.scaleLength,
                  max: component.axis.max,
                  mode: propertyValue,
                  currentRange: component.axis.x.range,
                });

                updateComponentProperty('axis.x.range', range);

                updateComponentProperty('axis.y.range', range);
                break;
              }

              case '1d': {
                if (component.axis.scaleType === 'custom') {
                  return;
                }

                const range = getAxisRange({
                  scaleType: component.axis.scaleType,
                  scaleLength: component.axis.scaleLength,
                  max: component.axis.max,
                  mode: propertyValue,
                  currentRange: component.axis.range,
                });

                updateComponentProperty('axis.range', range);

                break;
              }

              default:
                break;
            }
          },
        },
        interaction: {
          type: 'picker',
          defaultValue: 'graphic',
          displayName: 'Interaction',
          options: [
            {
              value: 'graphic',
              label: 'Matrix',
            },
            {
              value: 'sliders',
              label: 'Sliders',
            },
          ],
          category: CanvasTab.DATA,
          order: 110,

        },
        showAxisLabels: {
          type: 'checker',
          displayName: 'Show axis labels',
          defaultValue: true,
          category: CanvasTab.DATA,
          order: 300,
        },
        showMinMaxLabels: {
          type: 'checker',
          displayName: 'Show min-max labels',
          defaultValue: true,
          category: CanvasTab.DATA,
          order: 310,
        },
        showTickLabels: {
          type: 'checker',
          displayName: 'Show axis ticks',
          defaultValue: true,
          category: CanvasTab.DATA,
          order: 320,
        },
        ...data.showAxisLabels && {
          showAxisArrows: {
            type: 'checker',
            displayName: 'Show axis arrows',
            defaultValue: true,
            category: CanvasTab.DATA,
            order: 305,
          },
        },
        showCornerLabels: {
          type: 'checker',
          displayName: 'Show corner labels',
          defaultValue: false,
          category: CanvasTab.DATA,
          order: 330,
        },
        // ...data.showTickLabels && {
        //   tickLabelsPresentation: {
        //     type: 'picker',
        //     defaultValue: 'mirrored',
        //     displayName: 'Axis ticks',
        //     // legend: 'Axes',
        //     options: [
        //       {
        //         value: 'mirrored',
        //         label: 'Same for both axes',
        //       },
        //       {
        //         value: 'single',
        //         label: 'Independent for each axis',
        //       },
        //     ],
        //     order: 130,
        //     category: CanvasTab.DATA,
        //     onPropertyChange: ({
        //       data,
        //       propertyValue,
        //       updateProperty,
        //     }: PropertyChangeContext<Matrix2DQuestionCanvas>) => {
        //       // When the user changes the tickLabelsPresentation to 'single', we need to update the
        //       // tickLabels property to be an array of two values, one for each axis.
        //       if (propertyValue === 'mirrored') {
        //         updateProperty('axis.y.range', data.axis.x.range);
        //       }
        //     },
        //   },
        // },
        axis: {
          type: 'axis-2d',
          category: CanvasTab.DATA,
          order: 120,
          onPropertyChange: ({
            propertyValue,
            updateComponentProperty,
          }: PropertyChangeContext<Matrix2DQuestionCanvas>) => {
            // When the user changes the tickLabelsPresentation to 'single', we need to update the
            // tickLabels property to be an array of two values, one for each axis.
            // if (data.tickLabelsPresentation === 'mirrored') {
            updateComponentProperty('axis.y.range', cloneDeep(propertyValue.x.range));
            // }
          },
        },
        analyzeOptions: {
          type: 'analyze-options',
          category: CanvasTab.STATS,
          displayName: 'Statistics',
        },
      };
    }

    case '1d': {
      return {
        ...configBase,
        ...common,
        ...data.axis?.scaleType === 'numeric' &&
        {
          max: {
            type: 'number',
            defaultValue: 1,
            displayName: 'Maximum value',
            category: CanvasTab.DATA,
            order: 210,

          },
        },
        mode: {
          type: 'picker',
          displayName: 'Display data',
          defaultValue: 'discrete',
          section: 'Display',
          options: [
            {
              value: 'discrete',
              label: 'Discrete',
            },
            {
              value: 'continuous',
              label: 'Continuous',
            },
          ],
          category: CanvasTab.DATA,
          order: 1,
        },
        showAxisLabels: {
          type: 'checker',
          displayName: 'Show axis labels',
          defaultValue: true,
          section: 'Labels',
          order: 200,
          category: CanvasTab.DATA,
        },
        showMinMaxLabels: {
          type: 'checker',
          displayName: 'Show min-max labels',
          order: 210,
          defaultValue: true,
          category: CanvasTab.DATA,
        },
        showTickLabels: {
          type: 'checker',
          displayName: 'Show axis texts',
          order: 220,
          defaultValue: true,
          category: CanvasTab.DATA,
        },
        axis: {
          type: 'axis-1d',
          displayName: 'Axis',
          order: 100,
          category: CanvasTab.DATA,
        },
        analyzeOptions: {
          type: 'analyze-options',
          category: CanvasTab.STATS,
          displayName: 'Statistics',
        },
      };
    }

    case 'layout': {
      return {
        ...configBase,
        ...layoutConfig,
        ...common,
      };
    }

    case 'category': {
      return {
        ...configBase,
        ...common,
      };
    }
    default:
      return configBase;
  }
};

const generateRange = (scaleLength: number, max: number) => {
  const result: RangeItem[] = [];

  let value = -max;
  const step = max * 2 / (scaleLength - 1);

  for (let i = 0; i < scaleLength; i++) {
    result.push({
      value,
      label: value.toFixed(1),
    });
    value += step;
  }

  return result;
};

const canvasFormBuilderConfig: BuilderConfig<Canvas> = {
  type: 'canvas',

  displayName: 'Edit Canvas',

  // TODO: Localization
  props: CanvasFormBuilderConfigProps,
};

export default canvasFormBuilderConfig;
