import { DARK_LABEL_COLOR, MIN_MAX_LABEL_FONT_SIZE_REM, MOBILE_THRESHOLD } from 'components/canvas/canvasTypes/labelUnits';
import { SliderHelperLines } from './SliderHelperLines';
import { XTickMarks } from '../../canvasTypes/Matrix2DQuestionCanvas/ticks/XTickMarks';
import { answerToIndex } from '../../canvasTypes/Matrix2DQuestionCanvas/answer2DUtils';
import { floorToRange, limitToBounds } from '../../canvasTypes/Matrix2DQuestionCanvas/dragUtils';
import { indexToAnswer } from './utils/indexToAnswer';
import { motion, useAnimation } from 'framer-motion';
import { orchestrateKnobDropAnimation } from 'components/canvas/utils/orchestrateKnobAnimation';
import {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useCanvasRenderingContext } from 'utils/hooks/useCanvasRenderingContext';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import { vibrate } from 'utils/haptics';
import Center from 'components/common/layout/Center';
import Knob, { KNOB_DISABLED_OPACITY, KNOB_SIZE } from 'components/canvas/canvasTypes/common/Knob';
import ShadowKnob from '../../canvasTypes/Matrix2DQuestionCanvas/ShadowKnob';
import renderKnobValue from './utils/renderKnobValue';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import type { Axis, MatrixMode } from '@shared/schema/src';
import type { PropsWithChildren } from 'react';
import type { RenderTickFunction } from '../../canvasTypes/Matrix2DQuestionCanvas/layouts/Matrix2DLayout';
import type { ResolvedValues } from 'framer-motion';

const MinLabel = styled.div`
  font-size: ${MIN_MAX_LABEL_FONT_SIZE_REM}rem;
  color: ${DARK_LABEL_COLOR};
  font-weight: bold;
  text-align: left;
  position: absolute;
  top: 0;
  height: 100%;
  left: 1rem;
  overflow: hidden;
  max-width: 45%;
  text-overflow: ellipsis;
  @media(max-width: ${MOBILE_THRESHOLD}px) {
    font-size: ${MIN_MAX_LABEL_FONT_SIZE_REM / 1.5}rem;
  }
`;

const MaxLabel = styled.div`
font-size: ${MIN_MAX_LABEL_FONT_SIZE_REM}rem;
color: ${DARK_LABEL_COLOR};
  font-weight: bold;
  text-align: right;
  height: 100%;
  top: 0;
  position: absolute;
  right: 1rem;
  overflow: hidden;
  max-width: 45%;
  text-overflow: ellipsis;
    @media(max-width: ${MOBILE_THRESHOLD}px) {
    font-size: ${MIN_MAX_LABEL_FONT_SIZE_REM / 1.5}rem;
  }
`;

const SliderContainer = styled$.fieldset<{
  $showHint?: boolean;
}>(({
  theme,
  $showHint,
}) => `
  position: relative;
  display: flex;
  user-select: none;
  flex-direction: column;
  border-color: ${$showHint ? theme.palette.primary.main : 'transparent'};
  border-radius: ${theme.shape.borderRadius}px;
  transition: border-color 0.2s ease-in-out;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`);

const Legend = styled$.legend<{
  $showHint?: boolean;
}>(({
  theme,
  $showHint,
}) => `
  padding: ${theme.spacing(0, 1, 0, 1)};
  width: fit-content;
  margin-left: ${theme.spacing(1)};
  color: ${$showHint ? theme.palette.question.main : 'transparent'};
  transition: color 0.2s ease-in-out;
`);

const SliderArea = styled$(motion.div)<{
  $backgroundColor: string;
  $mode?: MatrixMode;
}>(({
  theme,
  $backgroundColor,
  $mode,
}) => `
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: ${$backgroundColor};
  height: ${KNOB_SIZE}px;
  border-radius: ${$mode === 'discrete' ? theme.shape.borderRadius : KNOB_SIZE}px;
  overflow: hidden;
`);

export interface Slider1DProps {
  value?: number;
  disabled?: boolean;
  valueLabelDisplay?: 'auto' | 'on' | 'off';
  // min?: number;
  max?: number;
  showTicks?: boolean;
  showMinMax?: boolean;
  minLabel?: JSX.Element | string;
  maxLabel?: JSX.Element | string;
  axis: Axis;
  step?: number;
  labels?: string[];
  values?: string[];
  layoutId: string;
  mode?: MatrixMode;
  // range: RangeItem[];
  // scaleType: TickLabelingType;
  // scaleLength: number;
  otherPartAnswered?: boolean;
  firstSlider?: boolean;
  onChange?: (value: number) => void;
  onRenderTick?: RenderTickFunction;
}

const Slider1D = (props: Slider1DProps) => {
  return <div style={{
    position: 'relative',
    width: '100%',
  }}>
    <Slider1DInstance {...props} />
  </div>;
};

const Slider1DInstance = ({
  value,
  children,
  disabled = false,
  mode = 'discrete',
  axis,
  showTicks,
  // range = [],
  firstSlider,
  otherPartAnswered,
  showMinMax,
  minLabel,
  maxLabel,
  onChange,
  onRenderTick,
}: PropsWithChildren<Slider1DProps>) => {
  const range = axis.range;
  const sliderAreaRef = useRef<HTMLDivElement | null>(null);
  const knobRef = useRef<HTMLDivElement>(null);
  const knobAnimation = useAnimation();
  const shadowKnobAnimation = useAnimation();
  const currentPos = useRef<number | null>(0);

  // const [initialValue,] = useState<number | undefined>(value);

  const {
    t,
  } = useTranslation();

  const {
    isPresentation,
  } = useCanvasRenderingContext();

  const oldValue = useRef<number | undefined>(undefined);

  const renderValue = useCallback(
    (value: number | undefined, index = false) => {
      return renderKnobValue(value, mode, axis.scaleType, range, index);
    }, [axis.scaleType, mode, range,]);

  const [_value, setValue,] = useState<number | undefined>(value);

  // Live value during the drag
  const [currentValue, setCurrentValue,] = useState<string | undefined>(renderValue(_value));
  const [dragging, setDragging,] = useState(false);

  const theme = useTheme();

  const graphBackgroundColor = theme.palette.background.graph;
  const color = 'lightgray';

  useEffect(() => {
    setCurrentValue(renderValue(_value));
  }, [_value, renderValue,]);

  const positionKnobByContinuousValue = useCallback((
    value: number | undefined
  ) => {
    const sliderAreaRect = sliderAreaRef.current?.getBoundingClientRect();
    const knobAreaRect = knobRef.current?.getBoundingClientRect();
    if (!sliderAreaRect || !knobAreaRect) {
      return 0;
    }
    const sliderWidth = sliderAreaRect.width - knobAreaRect.width;
    if (value === undefined) {
      return sliderWidth / 2;
    }
    let x = 0;

    const min = axis.min;
    const range = axis.max - min;

    x = sliderWidth * (value - min) / range;
    return x;
  }, [axis.max, axis.min,]);

  const handleDragStart = useCallback(
    () => {
      if (disabled || !knobRef.current) {
        return;
      }
      setDragging(true);
      const knobAreaRect = knobRef.current.getBoundingClientRect();

      shadowKnobAnimation.set({
        x: knobAreaRect.left,
        y: 0,
      });
      knobAnimation.set({
        opacity: 0.8,
      });
    }, [disabled, shadowKnobAnimation, knobAnimation,]
  );

  /**
   * Calculate the continuous value from the knob position relative to the slider area.
   */
  const calculateContinuousValueFromKnob = useCallback(() => {
    const sliderAreaRect = sliderAreaRef.current?.getBoundingClientRect();
    const knobRect = knobRef.current?.getBoundingClientRect();

    if (!sliderAreaRect || !knobRect) {
      return null;
    }
    const margin = 0;

    sliderAreaRect.width - margin * 2;
    sliderAreaRect.left + margin;

    const knobSize = knobRect.width;
    const knobExpand = (knobSize - KNOB_SIZE) / 2;

    const v = knobRect.left - sliderAreaRect?.left - knobExpand;
    const sliderWidth = sliderAreaRect.width - knobSize;

    const min = axis.min;
    const range = axis.max - min;
    const x = range * v / sliderWidth;

    let newAnswer = Math.round((axis.min + x) * 10.0) / 10.0;
    newAnswer = Math.min(axis.max, newAnswer);
    newAnswer = Math.max(axis.min, newAnswer);
    return newAnswer;
  }, [axis,]);

  const handleUpdate = useCallback(async (values: ResolvedValues) => {
    if (disabled || !dragging) {
      return;
    }
    if (values.x !== undefined && values.y !== undefined) {
      const sliderAreaRect = sliderAreaRef.current?.getBoundingClientRect();
      const knobRect = knobRef.current?.getBoundingClientRect();

      if (sliderAreaRect?.left === undefined || !knobRect) {
        return;
      }

      switch (mode) {
        case 'discrete': {
          /*
          * Animate the cue drop position if the cell has changed
          */
          const sliderWidth = sliderAreaRect.width;
          const v = knobRect.left - sliderAreaRect?.left + knobRect.width / 2;

          const newX = limitToBounds(
            floorToRange(sliderWidth, axis.range.length, v),
            axis);

          const x = newX * sliderWidth / range.length;

          if (currentPos.current !== x) {
            currentPos.current = x;
            await shadowKnobAnimation.start({
              x,
              opacity: 0.9,
            }, {
              duration: 0.3,
            });

            setCurrentValue((value) => {
              const newValue = renderValue(newX, true);

              if (newValue !== value) {
                return newValue;
              }
              return value;
            });
          }
          break;
        }
        case 'continuous': {
          // Continuous mode is self expiatory as the dragged knob is in the
          // precise position of the current value.
          const newAnswer = calculateContinuousValueFromKnob();
          if (newAnswer === null) {
            return;
          }
          setCurrentValue((value) => {
            const newValue = renderValue(newAnswer);

            if (newValue !== value) {
              return newValue;
            }
            return value;
          });
          break;
        }
        default:
          console.error(`Graph2D: Unidentified mode: ${mode}`);
      }
    }
  }, [disabled, dragging, mode, axis, range.length, shadowKnobAnimation, calculateContinuousValueFromKnob, renderValue,]);

  const modifyTarget = useCallback(() => {
    switch (mode) {
      default:
      case 'discrete': {
        const knobRect = knobRef.current?.getBoundingClientRect();
        const matrixRect = sliderAreaRef.current?.getBoundingClientRect();
        if (!knobRect || !matrixRect) {
          return 0;
        }
        const vx = knobRect.left - matrixRect.left + knobRect.width / 2;
        const rectWidth = matrixRect.width;

        const x = limitToBounds(floorToRange(rectWidth, axis.range.length, vx), axis);
        return rectWidth * x / range.length;
      }
      case 'continuous': {
        return positionKnobByContinuousValue(_value);
      }
    }
  }, [axis, mode, positionKnobByContinuousValue, range.length, _value,]);

  // useLayoutEffect(() => {
  //   if (!sliderAreaRef.current) {
  //     return;
  //   }
  //   const setPosition = () => {
  //     if (!knobRef?.current) {
  //       return;
  //     }

  //     const x = modifyTarget();
  //     knobAnimation.set({
  //       x,
  //     });
  //   };
  //   // listen to element resize and adjust the knob position
  //   const observer = new ResizeObserver(setPosition);
  //   observer.observe(sliderAreaRef.current);

  //   setPosition();

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [knobAnimation, modifyTarget,]);

  const handleDragEnd = useCallback(
    async () => {
      if (disabled) {
        return;
      }
      const matrixRect = sliderAreaRef.current?.getBoundingClientRect();
      const knobRect = knobRef.current?.getBoundingClientRect();

      if (!matrixRect || !knobRect) {
        return;
      }
      knobAnimation.stop();
      shadowKnobAnimation.stop();

      switch (mode) {
        default:
        case 'discrete': {
          const sliderWidth = matrixRect.width;

          const vx = knobRect.left - matrixRect.left + knobRect.width / 2;
          const newX = limitToBounds(
            floorToRange(matrixRect.width, axis.range.length, vx), axis
          );

          const newAnswer = indexToAnswer(axis, newX);
          try {
            const promises = [];

            const x = newX * sliderWidth / range.length;

            vibrate();

            const animation = orchestrateKnobDropAnimation(
              sliderAreaRef,
              knobRef,
              x,
              0
            );

            if (animation) {
              promises.push(knobAnimation.start(animation));

              promises.push(shadowKnobAnimation.set({
                x,
                opacity: 0,
              }));
            }
            await Promise.all(promises);
          } catch (e) {
            console.error('Error setting new answer value: ', e);
          } finally {
            setValue(newAnswer);

            onChange?.(newAnswer);
          }
          break;
        }
        case 'continuous': {
          const newAnswer = calculateContinuousValueFromKnob();

          if (newAnswer === null || newAnswer === undefined) {
            return;
          }
          try {
            const x = positionKnobByContinuousValue(newAnswer);

            vibrate();

            const animation = orchestrateKnobDropAnimation(
              sliderAreaRef,
              knobRef,
              x,
              0
            );

            if (animation) {
              await knobAnimation.start(animation);
            }
          } catch (e) {
            console.error('Error setting new answer value: ', e);
          } finally {
            setValue(newAnswer);
            onChange?.(newAnswer);
          }
          break;
        }
      }
      setDragging(false);
    }
    , [
      disabled, knobAnimation, shadowKnobAnimation, mode, axis, range.length,
      onChange, calculateContinuousValueFromKnob, positionKnobByContinuousValue,
    ]);

  useEffect(() => {
    if (dragging) {
      return;
    }
    if (!knobRef.current) {
      return;
    }

    let newValue = _value;
    if (value !== newValue) {
      setValue(value);
      newValue = value;
    }

    const hasOldValue = oldValue.current !== undefined;

    if (newValue === oldValue.current && newValue !== undefined) {
      return;
    }
    oldValue.current = newValue;

    setCurrentValue(renderValue(newValue));

    const knob = knobRef.current?.getBoundingClientRect();
    const slider = sliderAreaRef.current?.getBoundingClientRect();

    if (!slider || !knob) {
      return;
    }

    if (newValue !== undefined) {
      const v = (mode === 'discrete' ? answerToIndex(axis.scaleType, axis, newValue, true) : newValue)!;
      oldValue.current = v;

      switch (mode) {
        case 'discrete': {
          const sliderArea = slider.width;
          const values = {
            x: v * sliderArea / range.length,
            y: 0,
            opacity: 1,
          };
          if (hasOldValue) {
            knobAnimation.start(values);
            break;
          }
          knobAnimation.set(values);
          break;
        }

        case 'continuous': {
          const x = positionKnobByContinuousValue(newValue);
          const values = {
            x,
            y: 0,
            opacity: 1,
          };

          if (hasOldValue) {
            knobAnimation.start(values);
            break;
          }
          knobAnimation.set(values);
          break;
        }
        default:
          console.error(`Graph2D: Unidentified mode: ${mode}`);
      }
      return;
    }

    oldValue.current = undefined;

    // If the value is undefined, the knob is centered with half opacity and it is showing the cue animations
    switch (mode) {
      default:
      case 'discrete': {
        const values = {
          x: slider.width / 2 - (slider.width - 2) / range.length / 2 - 4,
          y: 0,
          opacity: KNOB_DISABLED_OPACITY,
        };
        if (hasOldValue) {
          knobAnimation.start(values);
          break;
        }
        knobAnimation.set(values);
        break;
      }
      case 'continuous': {
        const rectWidth = slider.width;
        const values = {
          x: rectWidth / 2 - knob.width / 2,
          y: 0,
          opacity: KNOB_DISABLED_OPACITY,
        };
        if (hasOldValue) {
          knobAnimation.start(values);
          break;
        }
        knobAnimation.set(values);
        break;
      }
    }
    currentPos.current = null;
  }, [value, _value, knobAnimation, range.length, mode, axis.min, axis.max, axis, dragging, positionKnobByContinuousValue, renderValue,]);

  const {
    hintText,
    showHint,
  } = useMemo(() => {
    if (_value !== undefined) {
      return {
        showHint: false,
        hintText: '&nbsp;',
      };
    }
    return {
      showHint: otherPartAnswered || firstSlider,
      hintText: otherPartAnswered ? t('next-give-your-opinion-here') : t('first-give-your-opinion-here'),
    };
  }, [firstSlider, otherPartAnswered, t, _value,]);

  return <SliderContainer
    $showHint={showHint}
  >
    <Legend $showHint={showHint}>
      <span>{hintText}</span>
    </Legend>
    <SliderArea
      layout
      $backgroundColor={graphBackgroundColor}
      $mode={mode}
      ref={sliderAreaRef}
    >
      <SliderHelperLines
        range={range}
        color={color}
        knobSize={KNOB_SIZE}
        mode={mode}
      />
      {showMinMax && <>
        <MinLabel><Center direction='vertical'>{minLabel}</Center></MinLabel>
        <MaxLabel><Center direction='vertical'>{maxLabel}</Center></MaxLabel>
      </>}
      <ShadowKnob
        width={mode === 'discrete' ? `calc(${100 / range.length}% - 6px)` : `${KNOB_SIZE}px`}
        height={`${KNOB_SIZE}px`}
        animate={shadowKnobAnimation}
        visible={dragging && mode === 'discrete'}
      />
      {!isPresentation ?
        <>
          {children}
          <Knob
            ref={knobRef}
            animate={knobAnimation}
            dragDimensions='x'
            width={mode === 'discrete' ? `calc(${100 / range.length}%)` : KNOB_SIZE}
            height={KNOB_SIZE}
            showDragCues={_value === undefined}
            active={_value !== undefined}
            mode={mode ?? 'discrete'}
            dragConstraints={sliderAreaRef}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragUpdate={handleUpdate}
          >
            {
              dragging ?
                <Center>
                  {currentValue}
                </Center> : null
            }
          </Knob>
        </> : null

      }
    </SliderArea >
    {
      showTicks && <XTickMarks
        knobSize={KNOB_SIZE}
        side='bottom'
        mode={mode}
        range={range}
        onRenderTick={onRenderTick}
      />
    }
  </SliderContainer>;
};

export default Slider1D;
