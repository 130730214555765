/**
 * definitions for common types of the payment subsystem
 */

import { PlanId, SpaceId } from '..';
import { UTCTime, UserId } from '@mindhiveoy/schema';

export type CustomerId = string;
export type InvoiceId = string;
export type PaymentId = string;

// export type BillingPeriod = 'monthly' | 'yearly';

/**
 * type of payment reference string
 */
export declare type PaymentReferenceToken = string;

/**
 * states of payment inside payment flow
 */
export enum PaymentState {

  /**
   * payment was created but not fixed permanently
   */
  CREATED = 'created',
  /**
     * payment was crated and passed process of initiation on payment system side
     * so actually has paired object on payment system DB as well as id
     */
  INITIALIZED = 'initialized',
  /**
  * payment was scheduled
  */
  SCHEDULED = 'scheduled',
  /**
   * payment was authorized
   */
  AUTHORIZED = 'authorized',
  /**
   * payment was settled (if applicable)
   */
  SETTLED = 'settled',
  /**
   * payment was confirmed
   */
  CONFIRMED = 'confirmed',
  /**
   * payment was cancelled by user
   */
  CANCELLED = 'cancelled',
  /**
   * payment was refused by payment system
   */
  REFUSED = 'refused',
  /**
   * payment was refunded
   */
  REFUNDED = 'refunded'
}

/**
 * payment method type
 */
export enum PaymentMethodType {
  /**
   * payment method type card
   */
  CARD = 'card',
  /**
   * payment method type bank (online)
   */
  BANK = 'bank',
  /**
   * payment method mobile
   */
  MOBILE = 'mobile',
  /**
   * payment method type invoice
   */
  INVOICE = 'invoice'
}

/**
 * payment actions
 */
export enum PaymentAction {
  /**
   * charge
   */
  CHARGE = 'charge',
  /**
   * cancel
   */
  CANCEL = 'cancel',
  /**
   * get state
   */
  GET_STATE = 'get_state',
}

/**
 * payment action result code
 */
export enum PaymentMessageResult {
  /**
   * success
   */
  SUCCESS = 'success',
  /**
   * general failure
   */
  FAILURE = 'failure',
}

/**
 * pricing models
 */
export enum PricingModel {
  /**
   * per seats
   */
  PER_SEAT = 'per-seat',
}

/**
 * types of subscription
 */
export type BillingPeriod =
  'none' |
  'monthly' |
  'annually';

/**
 * status of subscription
 * it can be active, expired and more
 */
export enum SubscriptionStatus {
  /**
   * subscription is active
   */
  ACTIVE = 'active',
  /**
   * subscription is expired
   */
  EXPIRED = 'expired',
  /**
   * inactive by some reason
   */
  INACTIVE = 'inactive',
  /**
   * undefined (for printing out in error cases)
   */
  UNDEFINED = 'undefined',
}

/**
 * invoice region standard
 */
export enum InvoiceRegion {
  /**
   * EU
   */
  EU = 'eu',
  /**
   * USA
   */
  US = 'us',
  /**
   * Australia
   */
  AU = 'au',
}

/**
 * number of time units per millisecond
 * all date time values presented in such units
 * (actually it is millisecond)
 */
const PaymentTimeUnitsPerMillisecond = 1;

/**
 * convert time value from milliseconds to payment&billing time units
 * @param {UTCTime} millis amount of milliseconds
 * @return {UTCTime} number of time units
 */
export const millisToPaymentTimeUnits = (millis: UTCTime): UTCTime => {
  return millis * PaymentTimeUnitsPerMillisecond;
};

/**
 * convert  payment&billing time units to milliseconds
 * @param {UTCTime} timeUnits amount of time units
 * @return {UTCTime} number of milliseconds
 */
export const paymentTimeUnitsToMillis = (timeUnits: UTCTime): UTCTime => {
  return timeUnits / PaymentTimeUnitsPerMillisecond;
};

export interface StripeSubscriptionSessionMetadata {
  planId: PlanId;
  billingPeriod: BillingPeriod;
  customerId: CustomerId;
  uid: UserId;
  facilitators?: number;
  users?: number;
  spaceId: SpaceId;
}

export interface StripePaymentSessionMetadata {
  customerId: CustomerId;
  uid: UserId;
  spaceId: SpaceId;
  reference?: string;
}
