import { BlocQuery } from '../../@mindhiveoy/bloc/BlocQuery';
import { createCanvas, duplicateCanvas, setCanvasOrder } from './canvasApi';
import { createUseBlocHook } from '../../@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import type { BlocErrorFunction } from '../../@mindhiveoy/bloc/types';
import type { Canvas, CanvasId, SessionParams } from '../../@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

/**
 *
 */
export class CanvasesBloc extends BlocQuery<Canvas, SessionParams> {
  /**
   *
   * @param {SessionParams}   params  Session id.
   * @param {ErrorFunction} onError Error listener
   */
  constructor(
    params: SessionParams,
    onError?: BlocErrorFunction
  ) {
    const {
      spaceId, projectId, sessionId,
    } = params;

    super({
      params,
      collectionPath:
        `spaces/${spaceId
        }/projects/${projectId
        }/sessions/${sessionId
        }/canvases`,
      onError,
    });

    this.orderBy('orderNo');
  }

  /**
   * Create a new project.
   *
   * @param {Partial<Canvas>}    canvas   Canvas base data.
   * @param {canvasId}           after      The Canvas id of the Canvas where after the new Canvas will be created.
   * @return {CreateSessionResponse}            A data object containing the id of the created project
   *                                          and project's initial data.
   */
  public createCanvas = async (
    canvas: Partial<Canvas>,
    after?: CanvasId
  ): Promise<WithId<Canvas>> => {
    // TODO: Optimistic create?
    if (!canvas?.type) {
      throw new Error('Canvas type must be defined.');
    }
    return this._create(
      async () => {
        return await createCanvas({
          ...this.params,
          canvas: canvas as Canvas,
          after,
        });
      },
      this.onError
    );
  };

  /**
   *
   * @param {CanvasId} canvasId
   * @return {Promise<WithId<Canvas>>}
   */
  public duplicate = async (
    canvasId: CanvasId
  ): Promise<WithId<Canvas>> => {
    return this._create(
      async () => {
        return await duplicateCanvas({
          ...this.params,
          canvasId,
          after: canvasId,
        });
      },
      this.onError
    );
  };

  /**
   * Update Canvas order by giving the right order as an array of Canvas ids
   *
   * @param {canvasId[]}  order       canvasId's in preferred order.
  */
  public setOrder = async (
    order: CanvasId[]
  ) => {
    // TODO: Batch operation support for cache
    await setCanvasOrder({
      spaceId: this.params.spaceId,
      projectId: this.params.projectId,
      sessionId: this.params.sessionId,
      order,
    });
  };
}

export const useCanvases = createUseBlocHook<Canvas, SessionParams, CanvasesBloc>(
  ({
    params,
    onError,
  }) => new CanvasesBloc(params, onError)
);
