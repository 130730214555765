import { PhotoContainer } from './PhotoMediaContainer';
import { VideoCard } from '..';
import { useCallback, useMemo, useRef } from 'react';
import { useTheme } from '@emotion/react';
import React from 'react';
import ReactPlayer from 'react-player';
import styled$ from 'utils/react/styled$';
import type { CSSProperties, PropsWithChildren } from 'react';
import type { TypedMediaContainerProps } from '..';

const VIDEO_RATIO = 16 / 9;

const Cover = styled$.div<{
  $width: number;
  $height: number;
}>(({
  $width,
  $height,
}) => `
  position: relative;
  width: ${$width}px;
  height: ${$height}px;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-appearance: none; /* For Firefox */
  appearance: none; 
`);

/**
 * Renders a YouTube video on the given container.
 *
 * @return {object} An object with width and height dimensions.
 */
export const YouTubeMediaContainer = ({
  blur,
  children,
  fullScreen,
  gestures,
  noRadius,
  style,
  url,
  media,
}: PropsWithChildren<TypedMediaContainerProps>) => {
  const theme = useTheme();
  const youtubeRef = useRef<HTMLDivElement>(null);

  /**
   * Resolves the dimensions based on fullscreen mode and window availability.
   *
   * @return {object} An object containing width and height dimensions.
   */
  const resolveContainerDimensions = () => {
    if (fullScreen && typeof window !== 'undefined') {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }
    return {
      width: youtubeRef.current?.clientWidth ?? 640,
      height: youtubeRef.current?.clientHeight ?? 360,
    };
  };

  /**
   * Resolve the dimensions of a video based on the dimensions of its container.
   *
   * @return {object} An object containing the width, height, left, and top properties
   *                  for positioning the video within its container.
   */
  const resolveVideoDimensions = () => {
    const {
      width,
      height,
    } = resolveContainerDimensions();

    const parentRatio = width / height;

    if (parentRatio > VIDEO_RATIO) {
      // Parent is wider than video ratio, scale height
      const scaledHeight = width / VIDEO_RATIO;
      return {
        width,
        height: scaledHeight,
        left: 0,
        top: (height - scaledHeight) / 2, // Center vertically
      };
    }

    // Parent is taller than video ratio, scale width
    const scaledWidth = height * VIDEO_RATIO;
    return {
      width: scaledWidth,
      height,
      top: 0,
      left: (width - scaledWidth) / 2, // Center horizontally
    };
  };

  const {
    width, height,
    left, top,
  } = resolveVideoDimensions();

  const [, forceUpdate,] = React.useState(0);

  // Make sure that the ReactPlayer has the correct dimensions when the window is resized
  React.useEffect(() => {
    const handleResize = () => forceUpdate((x) => x + 1);
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    // Prevent the click event from bubbling up to the parent container
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const _style: CSSProperties = useMemo(() => ({
    position: 'absolute',
    top,
    left,
  }), [top, left,]);

  const {
    loop,
    muted,
    config,
  } = useMemo(() => {
    const videoConfig = media?.videoConfig;
    const loop = videoConfig?.loop !== undefined ? videoConfig?.loop : true;
    const autoplay = true;
    const muted = videoConfig?.muted !== undefined ? videoConfig?.muted : true;
    const start = videoConfig?.start !== undefined ? videoConfig?.start : 0;
    const end = videoConfig?.end !== undefined ? videoConfig?.end : 0;
    return {
      autoplay,
      muted,
      loop,
      config: {
        youtube: {
          playerVars: {
            playsinline: 1,
            controls: 0,
            disablekb: 1,
            modestbranding: 0,
            rel: 0,
            fs: 0,
            muted: muted ? 1 : 0,
            autoplay: autoplay ? 1 : 0,
            showinfo: 0,
            loop: loop ? 1 : 0,
            start,
            end,
            volume: 0.02,
          },
        },
      },
    };
  }, [media?.videoConfig,]);

  return url ?
    <VideoCard
      $fullScreen={fullScreen}
      $noRadius={noRadius}
      ref={youtubeRef}
      $blur={blur}
      style={style}
      $gestures={gestures}
    >
      <Cover
        $width={width}
        $height={height}
        onClick={handleClick}
      >
        <ReactPlayer
          data-testid='youtube-player'
          className='react-player'
          loop={!!loop}
          playing
          width={width}
          height={height}
          playbackRate={1}
          volume={0.8}
          muted={!!muted}
          config={config}
          url={url}
          style={_style}
        />
      </Cover>
      {children}
    </VideoCard> :
    <PhotoContainer
      $fullScreen={fullScreen}
      $blur={blur}
      style={style}
      $image={theme.shape.imagePlaceholder}
    >
      {children}
    </PhotoContainer>;
};
