/* eslint-disable indent */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from 'next-i18next';
import PropertyEditorLegend from 'components/builder/propertyEditors/PropertyEditorLegend';
import styled from '@emotion/styled';
import theme from 'theme';
import type { Statistics, Statistics1D, Statistics2D } from '@shared/schema/src';

export interface StatInfoProps {
  statistics?: Statistics;
}

const Stats = styled.div`
  position: relative;  
  display: grid;
  grid-auto-columns: fr; 
  gap: 0px 0px; 
  grid-template-columns: min-content auto;
  grid-template-rows: repeat(auto-fill, 1fr); 
  grid-gap: ${theme.spacing(1)};
  width: 100%;
  max-width: 100%;
  `;

/**
 * @param {StatInfoProps} Props
 * @return {JSX.Element}
 */
const StatInfo = ({
  statistics,
}: StatInfoProps) => {
  const {
    t,
  } = useTranslation();

  switch (statistics?.type) {
    case '1d':
      return <>
        <Stats1dInfo statistics={statistics} />
        {
          statistics?.average?.toFixed() === NaN.toFixed() &&
          <>
            <br />
            {t('no-answers-for-this-question-yet')}
          </>
        }
      </>;

    case '2d':
      return <>
        <Stats2dInfo statistics={statistics} />
        {
          statistics?.averageX?.toFixed() === NaN.toFixed() &&
          <>
            <br />
            {t('no-answers-for-this-question-yet')}
          </>
        }
      </>;

    default:
      return <PropertyEditorLegend label={t('statistics')}>
        <Stats>
          <Stat label={t('answers')}
            x={null}
            asterix
            decimals={0} />
          <Stat label={t('Average')}
            asterix
            x={null} />
          <Stat label={t('1st quartile')}
            asterix
            x={null} />
          <Stat label={t('Median')}
            asterix
            x={null} />
          <Stat label={t('3rd quartile')}
            asterix
            x={null} />
          <Stat label={t('Std deviation')}
            asterix
            x={null} />
        </Stats>
        <div className="special-class-for-statistics">*{t('a-minimum-of-two-answers-is-required-to-calculate-the-statistics')}</div>
      </PropertyEditorLegend>;
  }
};

const Stats1dInfo = ({
  statistics,
}: {
  statistics: Statistics1D
}) => {
  const {
    t,
  } = useTranslation();

  return <>
    <PropertyEditorLegend label={t('statistics')}>
      {statistics?.count > 1 &&
        <Stats>
          <Stat label={t('answers')}
            x={statistics.count}
            decimals={0} />
          <Stat label={t('Average')}
            x={statistics.average} />
          <Stat label={t('1st quartile')}
            x={statistics.q1} />
          <Stat label={t('Median')}
            x={statistics.median} />
          <Stat label={t('3rd quartile')}
            x={statistics.q3} />
          <Stat label={t('Std deviation')}
            x={statistics?.stdDeviation} />
        </Stats>
      }
      {statistics?.count < 2 &&
        <><Stats>
          <Stat label={t('answers')}
            x={null}
            decimals={0} />
          <Stat label={t('Average')}
            x={null} />
          <Stat label={t('1st quartile')}
            x={null} />
          <Stat label={t('Median')}
            x={null} />
          <Stat label={t('3rd quartile')}
            x={null} />
          <Stat label={t('Std deviation')}
            x={null} />
        </Stats> <div className="special-class-for-statistics">*{t('a-minimum-of-two-answers-is-required-to-calculate-the-statistics')}</div></>
      }
    </PropertyEditorLegend>
  </>;
};

const Stats2dInfo = ({
  statistics,
}: {
  statistics: Statistics2D
}) => {
  const {
    t,
  } = useTranslation();

  return <PropertyEditorLegend label={t('statistics')}>
    {statistics?.count > 1 &&
      <Stats>
        <Stat label={t('answers')}
          x={statistics.count}
          decimals={0} />
        <Stat2D label={t('Average')}
          x={statistics.averageX}
          y={statistics.averageY} />
        <Stat2D label={t('1st quartile')}
          x={statistics.q1X}
          y={statistics.q1Y} />
        <Stat2D label={t('Median')}
          x={statistics.medianX}
          y={statistics.medianY} />
        <Stat2D label={t('3rd quartile')}
          x={statistics.q3X}
          y={statistics.q3Y} />
        <Stat2D label={t('Std deviation')}
          x={statistics?.stdDeviationX}
          y={statistics?.stdDeviationY} />
      </Stats>
    }
    {statistics?.count < 2 && <>
      <Stats>
        <Stat label={t('answers')}
          decimals={0} />
        <Stat2D label={t('Average')} />
        <Stat2D label={t('1st quartile')} />
        <Stat2D label={t('Median')} />
        <Stat2D label={t('3rd quartile')} />
        <Stat2D label={t('Std deviation')} />
      </Stats>
      <div className="special-class-for-statistics">*{t('a-minimum-of-two-answers-is-required-to-calculate-the-statistics')}</div>
    </>
    }
  </PropertyEditorLegend>;
};

const Label = styled.label`
  white-space: nowrap;
  color: ${theme.palette.text.disabled};
`;

const DataSpan = styled.span`
  padding-left: ${theme.spacing(1)};
  white-space: nowrap;
`;

const renderValue = (value: number, decimals: number, unit: string) => {
  if (value === null || value === undefined) {
    return '-';
  }
  const result = decimals ? value.toFixed(decimals) : value;
  if (unit) {
    return `${result} ${unit}`;
  }
  return result;
};

const Data = ({
  x,
  decimals = 2,
  unit,
}: Pick<StatProps, 'x' | 'decimals' | 'unit'>) => {
  return <DataSpan>
    {x && x >= 0 && decimals !== 0 && <>&nbsp;&nbsp;</>}
    {x ? renderValue(x, decimals, unit!) : null}
  </DataSpan>;
};

const Data2d = ({
  x,
  y,
  decimals = 2,
  unit,
}: Pick<Stat2DProps, 'x' | 'y' | 'decimals' | 'unit'>) => {
  if ((x === null || x === undefined) && (y === null || y === undefined)) {
    return <DataSpan>-</DataSpan>;
  }
  return <DataSpan>
    {renderValue(x!, decimals, unit!)},&nbsp;
    {renderValue(y!, decimals, unit!)}
  </DataSpan>;
};

export interface StatProps {
  label: string;
  x?: number | null;
  unit?: string;
  decimals?: number;
  asterix?: boolean;
}
const Stat = ({
  decimals,
  label,
  unit,
  x,
  asterix,
}: StatProps) => <>
    <Label>{label}{asterix && '*'}</Label>
    <Data
      x={x}
      unit={unit}
      decimals={decimals}
    />
  </>;

export interface Stat2DProps extends StatProps {
  y?: number;
}

const Stat2D = ({
  label,
  x, y,
  decimals,
  unit,
}: Stat2DProps) => <>
    <Label>{label}</Label>
    <Data2d
      x={x}
      y={y}
      decimals={decimals}
      unit={unit}
    />
  </>;

export default StatInfo;
