import { UnsplashCopyright, extractMediaAuthorInfoFromUnsplash } from './MediaCopyright/UnsplashCopyright';
import { motion } from 'framer-motion';
import { trackUnsplashDownload } from 'bloc/media/MediaBloc';
import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';
import Stack from '@mui/material/Stack';
import usePathParams from 'utils/hooks/usePathParams';
import type { Media } from '@shared/schema/src';
import type { UnsplashPhotoResponse } from '@shared/schema/src/https/media/unsplashTypes';

interface UnsplashImageProps {
  item: UnsplashPhotoResponse;
  onClick?: (item: Partial<Media>) => void;
}
export const UnsplashImage = ({
  item,
  onClick,
}: UnsplashImageProps) => {
  const theme = useTheme();

  const {
    spaceId,
    projectId,
  } = usePathParams();

  const handleImageSelect = useCallback(async (event: React.MouseEvent) => {
    onClick?.({
      ...extractMediaAuthorInfoFromUnsplash(item),
      type: 'photo',
      photoURL: item.urls.regular,
    });

    await trackUnsplashDownload({
      spaceId,
      projectId,
      photoId: item.id,
    });

    event.preventDefault();
  }, [item, onClick, projectId, spaceId,]);

  return <Stack
    key={item.id}
    onClick={handleImageSelect}
    position="relative"
  >
    <motion.img
      whileHover={{
        borderColor: 'rgba(255,255,255,1)',
      }}
      alt={item.alt_description}
      src={item.urls.thumb}
      loading="lazy"
      style={{
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0,0)',
        borderRadius: theme.shape.borderRadius,
      }} />
    <UnsplashCopyright item={item} />
  </Stack>;
};
