import { extractComponentPresentation } from './extractComponentPresentation';
import { getPrivacyPolicyFile, getTermsAndConditionFile } from 'utils/firebase/fireStorageUtils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import GenericModal from 'components/common/GenericModal';

const textCache: any = {
  tos: null,
  privacy: null,
};

const getTermsContent = async (mode: string): Promise<{
  title: string;
  content: string;
}> => {
  switch (mode) {
    case 'tos': {
      if (textCache.tos) {
        return {
          title: 'Terms of Service',
          content: textCache.tos,
        };
      }
      const content = await getTermsAndConditionFile();

      textCache.tos = content;

      return {
        title: 'Terms of Service',
        content: content ?? '',
      };
    }

    case 'privacy': {
      if (textCache.privacy) {
        return {
          title: 'Privacy Policy',
          content: textCache.privacy,
        };
      }

      const content = await getPrivacyPolicyFile();

      textCache.privacy = content;

      return {
        title: 'Privacy Policy',
        content: content ?? '',
      };
    }

    default:
      throw new Error(`Unexpected mode: ${mode}`);
  }
};

interface TermsDialogsProps {
  mode: 'tos' | 'privacy' | 'none';
  onClose: () => void;
}

/**
 * Dialogs for showing the platform terms.
 *
 * @param {TermsDialogsProps} props Props
 * @return {React.ReactElement} Terms dialogs
 */
export const TermsDialogs = ({
  mode = 'none',
  onClose,
}: TermsDialogsProps) => {
  const [title, setTitle,] = useState<string>();

  const {
    t,
  } = useTranslation();

  const [termsAndConditions, setTermsAndConditions,] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const fetch = async () => {
      if (mode === 'none') {
        return null;
      }

      const {
        title,
        content,
      } = await getTermsContent(mode);

      setTitle(title);

      const resultingView = extractComponentPresentation(content);

      setTermsAndConditions(
        <div style={{
          width: '100%',
          position: 'relative',
        }}>{resultingView}</div>
      );
    };
    fetch();
  }, [mode,]);

  const handleClose = useCallback((event: React.MouseEvent) => {
    // Make sure that the not show prior text for a moment before reading the new content
    setTermsAndConditions(null);
    onClose?.();
    event.stopPropagation();
  }, [onClose,]);

  return <GenericModal
    modalProps={{
      open: mode !== 'none',
      children: termsAndConditions as any,
      onClose: handleClose,
    }}
    headerText={t(title) as any}
    modalActions={[
      <Button
        key="close"
        variant='contained'
        style={{
          padding: '1rem',
          flex: 1,
        }}
        onClick={handleClose}
      >
        {t('Close')}
      </Button>,
    ]} />;
};
