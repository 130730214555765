import { BackgroundVideo, VideoCard } from '..';
import { useAuth } from '@mindhiveoy/react-auth';
import { useEffect, useRef } from 'react';
import { window } from 'global';
import React from 'react';
import type { MediaContainerProps } from '..';
import type { PropsWithChildren } from 'react';
import type { ShortUserRoleId, User } from '@shared/schema/src';

export const DefaultMediaContainer = ({
  blur, children, fullScreen, gestures, media, style, onClick,
}: PropsWithChildren<MediaContainerProps>
) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const {
    user: authUser,
  } = useAuth();

  useEffect(() => {
    if (typeof window === 'undefined' || !authUser || !media || !videoRef) {
      return;
    }

    // TODO: add freezeVideoPlayback to mindcloud's useAuth typings so we don't need this anymore
    const user = authUser as User<ShortUserRoleId>;

    // if preference is either unset or true, we play the video
    const videoPlayback = !user?.extra?.freezeVideoPlayback;
    // TODO handle video sid
    const onContentLoaded = async () => {
      try {
        if (videoRef?.current) {
          if (videoPlayback) {
            if (videoRef.current.readyState !== undefined) {
              await videoRef?.current?.play();
            } else {
              // if state is undefined, we need to force the video refresh because of buggy player, otherwise it might not show at all
              videoRef?.current?.load();
            }
            return;
          }
          if (videoRef.current.readyState !== undefined) {
            // hack for buggy video player to show the still image from the video, without playing it first it'll be empty
            await videoRef?.current?.play();
            videoRef?.current?.pause();
          }
        }
      } catch (error) {
        // TODO: sentry
        console.error('An error during video playback: ', error);
      }
    };

    window.addEventListener('DOMContentLoaded', onContentLoaded);

    if (document.readyState === 'complete') {
      onContentLoaded();
    }
    return () => typeof window !== 'undefined' &&
      window.removeEventListener('DOMContentLoaded', onContentLoaded);
  }, [authUser, videoRef, media,]);

  return <VideoCard
    $fullScreen={fullScreen}
    $blur={blur}
    style={style}
    $gestures={gestures}
    onClick={onClick}
  >
    <BackgroundVideo
      loop
      autoPlay
      width={'100%'}
      height={200}
      muted
      url={videoRef}
    >
      <source
        src="/videos/smoke.mp4"
        type="video/mp4" />
    </BackgroundVideo>
    {children}
  </VideoCard>;
};
