/* eslint-disable @typescript-eslint/no-explicit-any */
import { createUseBlocHook } from '@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import { modifyScreen, updateProject, updateProjectMemberAlterEgo } from './projectApi';
import { validateSessionProps } from '@shared/schema/src';
import BlocDocument from '@mindhiveoy/bloc/BlocDocument';
import elementCache from 'utils/ElementCache';
import type { BlocErrorFunction } from '@mindhiveoy/bloc/types';
import type { MemberId, WithId } from '@mindhiveoy/schema';
import type { Project, ProjectParams, Screen, ScreenId } from '@shared/schema/src';

/**
 *
 */
export class ProjectBloc extends BlocDocument<Project, ProjectParams> {
  /**
   * @param {ProjectParams} params
   * @param {ErrorFunction} onError Error listener
   */
  constructor(
    params: ProjectParams,
    onError?: BlocErrorFunction
  ) {
    const {
      spaceId,
      projectId,
    } = params;

    let invalidState = false;
    try {
      validateSessionProps(params, 'projectId');
    } catch (e) {
      // Fail gracefully
      invalidState = true;
    }
    super({
      documentPath: `spaces/${spaceId}/projects/${projectId}`,
      params,
      invalidState,
      onError,
    });

    this.registerUpdateListener((data) => {
      if (!data) {
        return;
      }
      elementCache.set({
        keyPath: {
          spaceId,
          projectId,
        },
        data,
      });
    });
  }

  /**
   *
   * @param {Partial<Project>} project
   * @return {Promise<WithId<Project>>}
   */
  public update = async (project: Partial<Project>,
    deleted: string[]) => {
    return this._set(
      async () => {
        return await updateProject({
          ...this.params,
          project,
          deleted,
        });
      },
      this.onError,
      (data) => data && {
        ...data,
        ...project,
        _id: this.docId,
      }
    );
  };

  /**
   * Update member's alter ego. Should be used for debugging purposes only.
   * @param   {MemberId}  memberId
   * @return {Promise<WithId<Member>>}
   */
  public updateMemberAlterEgo = async (memberId: MemberId) => {
    return await updateProjectMemberAlterEgo({
      ...this.params,
      memberId,
    });
  };

  /**
   * Create a new screen for the project.
   *
   * @param {projectId} project      Project data
   * @return {WithId<Project>} A data object containing the id of the created project and project's initial data.
   */
  public createScreen = async (
    screen: Partial<Screen> = {}
  ) => {
    if (this.data?.screens && Object.keys(this.data.screens).length >= 8) {
      throw new Error('Max screen count reached');
    }

    try {
      const response = await modifyScreen({
        spaceId: this.params.spaceId,
        projectId: this.params.projectId,
        screen,
        method: 'create',
      });

      return this._set(
        (async () => {
          const screens = this.data?.screens || {} as any;
          if (response) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            screens[response.screen!._id!] = response!.screen;
          }
          return {
            ...this.data,
            screens,
            _id: this.docId,
          };
        }) as any, // TODO: Fix this
        this.onError,
        undefined
      );
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };

  /**
   * Update screen in the project.
   *
   * @param {projectId} project      Project data
   * @return {WithId<Project>} A data object containing the id of the created project and project's initial data.
   */
  public updateScreen = async (
    screenId: ScreenId,
    screen: Partial<Screen> = {}
  ) => {
    const response = await modifyScreen({
      spaceId: this.params.spaceId,
      projectId: this.params.projectId,
      screenId,
      screen: {
        ...this.data?.screens?.[screenId],
        ...screen,
      },
      method: 'replace',
    });

    return this._set(
      (async () => {
        const screens = this.data?.screens || {};

        const screen = response as WithId<Screen>;
        screens[screen._id] = screen;

        return {
          ...this.data,
          screens,
          _id: this.docId,
        };
      }) as any, // TODO: Fix this),
      this.onError,
      (() => {
        const data = {
          ...this.data,
          _id: this.docId,
        };
        const screens = data.screens || {};

        const oldScreen = screens[screenId];

        screens[screenId] = {
          ...oldScreen,
          ...screen,
        };
        data.screens = screens;
        return data;
      }) as any,
      {
        merge: true,
      }
    );
  };

  /**
   * Update screen in the project.
   *
   * @param {projectId} project      Project data
   * @return {WithId<Project>} A data object containing the id of the created project and project's initial data.
   */
  public deleteScreen = async (
    screenId: ScreenId
  ) => {
    await modifyScreen({
      spaceId: this.params.spaceId,
      projectId: this.params.projectId,
      screenId,
      method: 'delete',
    });

    return this._set(
      (async () => {
        const screens = this.data?.screens || {};

        delete screens[screenId];

        return {
          ...this.data,
          screens,
          _id: this.docId,
        };
      }) as any,
      this.onError,
      (() => {
        const data = {
          ...this.data,
          _id: this.docId,
        };
        if (data) {
          const screens = data.screens || {};
          delete screens[screenId];
          if (Object.keys(screens).length === 0) {
            delete data.screens;
            return data;
          }
          return {
            ...data,
            screens,
          };
        }
        return undefined;
      }) as any, // TODO: Fix this
      {
        merge: true,
      }
    );
  };
}

export const useProject = createUseBlocHook<Project, ProjectParams, ProjectBloc>(
  ({
    params,
    onError,
  }) =>
    new ProjectBloc(
      params,
      onError
    )
);
