/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentDataContext } from '../../utils/hooks/useComponentDataContext';
import { ComponentModel } from 'components/builder/models/ComponentModel';
import { useRef } from 'react';
import React from 'react';
import type { ComponentArrayModel } from 'components/builder/models/ComponentArrayModel';
import type { DataContextProviderProps } from '../../utils/hooks/useComponentDataContext';
import type { PropsWithChildren } from 'react';

export const ComponentDataContextProvider = <D extends Record<string, any>>({
  children, model,
}: PropsWithChildren<DataContextProviderProps<D>>
) => {
  const value = useRef(extractContext<D>(model));
  if (value.current.model !== model) {
    value.current = extractContext<D>(model);
  }
  return <ComponentDataContext.Provider value={value.current}>
    {children}
  </ComponentDataContext.Provider>;
};

const extractContext =
  <D extends Record<string, any>>(model: ComponentArrayModel | ComponentModel<D>
  ): ComponentDataContext<D> => {
    return {
      model,
      updateProperty: model instanceof ComponentModel && model.updateProperty as any,
      getProperty: model instanceof ComponentModel && model.getPropertyValue as any,
      deleteProperty: model instanceof ComponentModel && model.deleteProperty as any, // TODO typing
    } as ComponentDataContext<D>;
  };
