import React from 'react';
import styled$ from 'utils/react/styled$';
import type { MediaContainerProps } from '..';
import type { PropsWithChildren } from 'react';

const Background = styled$.div<{
  $backgroundColor?: string;
  $fullScreen?: boolean;
}>(({
  $backgroundColor,
  $fullScreen,
}) => `
  width: 100%;
  height: 100%;
  position: ${$fullScreen ? 'fixed' : 'relative'};
  ${$fullScreen ? 'top: 0; left: 0; right: 0; bottom: 0;' : ''}
  background-color: ${$backgroundColor};
`);

export const ColorMediaContainer = ({
  children,
  fullScreen,
  media,
  style,
}: PropsWithChildren<MediaContainerProps>) => {
  return (
    <Background
      $fullScreen={fullScreen}
      $backgroundColor={media?.color}
      style={style}
    >
      {children}
    </Background>
  );
};
