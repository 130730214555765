import pica from 'pica';

/**
 * Options for resizing an image.
 */
type ResizeOptions = {
  /** The desired width of the resized image. */
  width: number;
  /** The desired height of the resized image. */
  height: number;
};

/**
 * Resizes an image given as a Data URL to the size specified in the options
 * and returns the resized image as a new Data URL. This function works in a
 * browser environment and provides smooth resizing by averaging pixels when
 * scaling down the image.
 *
 * @param imageDataUrl - The original image as a Data URL.
 * @param options - An object containing the desired width and height.
 * @returns A Promise that resolves to the resized image as a new Data URL.
 * @throws An Error if there's a problem creating the 2D context or loading the image.
 *
 * @example
 * const originalDataUrl = "data:image/jpeg;base64,...";
 * const resizedDataUrl = await resizeImageDataUrl(originalDataUrl, {
 *   width: 200,
 *   height: 200,
 * });
 * console.log("Resized image data URL:", resizedDataUrl);
 */
async function resizeImageDataUrl(
  imageDataUrl: string,
  options: ResizeOptions
): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageDataUrl;

    img.onload = async () => {
      const sourceCanvas = document.createElement('canvas');
      const destCanvas = document.createElement('canvas');
      const ctx = sourceCanvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Failed to create 2D context'));
        return;
      }

      sourceCanvas.width = img.width;
      sourceCanvas.height = img.height;
      destCanvas.width = options.width;
      destCanvas.height = options.height;

      // Draw the original image on the source canvas
      ctx.drawImage(img, 0, 0);

      // Use pica to resize the image
      await pica().resize(sourceCanvas, destCanvas, {
        unsharpAmount: 80,
        unsharpRadius: 0.6,
        unsharpThreshold: 2,
      });

      // Convert the resized image back to a Data URL
      const resizedDataUrl = destCanvas.toDataURL('image/jpeg');
      resolve(resizedDataUrl);
    };

    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };
  });
}

export const imageToDataUrl = async (
  url: string
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;

    img.onload = async () => {
      const sourceCanvas = document.createElement('canvas');
      const ctx = sourceCanvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Failed to create 2D context'));
        return;
      }

      sourceCanvas.width = img.width;
      sourceCanvas.height = img.height;

      // Draw the original image on the source canvas
      ctx.drawImage(img, 0, 0);

      // Convert the resized image back to a Data URL
      const dataUrl = sourceCanvas.toDataURL('image/jpeg');
      resolve(dataUrl);
    };

    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };
  });
};

export default resizeImageDataUrl;
