import { DrawerListIcon } from '.';
import { motion } from 'framer-motion';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import type { DrawerMenuItemDescriptor, DrawerMenuItemId } from '.';

interface DrawerMenuItemProps {
  item: DrawerMenuItemDescriptor;
  selected: boolean;
  onClick: (id?: DrawerMenuItemId) => void;
}

const DrawerMenuItem = ({
  item: {
    id,
    label,
    icon,
  },
  selected,
  onClick,
}: DrawerMenuItemProps) => {
  const {
    t,
  } = useTranslation();

  const handleClick: React.MouseEventHandler = useCallback(
    (event) => {
      onClick?.(id);
      event.stopPropagation();
    },
    [id, onClick,]
  );

  return <motion.div layoutId={`p_${id}`} >
    <ListItem selected={selected}
      button
      key={id}
      onClick={handleClick}>
      <DrawerListIcon >
        {icon}
      </DrawerListIcon>
      <motion.div>
        <ListItemText primary={t(label)} />
      </motion.div>
    </ListItem>
  </motion.div>;
};

export default DrawerMenuItem;
