import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import BoldIcon from '@mui/icons-material/FormatBold';
import BulletListIcon from '@mui/icons-material/FormatListBulleted';
import ItalicIcon from '@mui/icons-material/FormatItalic';
import NumberedListIcon from '@mui/icons-material/FormatListNumbered';
import RedoIcon from '@mui/icons-material/Redo';
import SendIcon from '@mui/icons-material/Send';
import StrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import UnderlineIcon from '@mui/icons-material/FormatUnderlined';
import UndoIcon from '@mui/icons-material/Undo';
import type { ToolbarButtonProps } from './ToolbarButton';

export const ToolbarIcon = ({
  active, image,
}: Pick<ToolbarButtonProps, 'image' | 'active'>) => {
  const theme = useTheme();
  const style = useMemo(() => ({
    color: active ? 'white' : 'inherit',
    borderRadius: 4,
    padding: 4,
    width: 32,
    height: 32,
    backgroundColor: active ? theme.palette.background.lightInnerPaper : 'transparent',
  }), [active, theme,]);

  switch (image) {
    case 'undo':
      return <UndoIcon style={style} />;
    case 'redo':
      return <RedoIcon style={style} />;
    case 'send':
      return <SendIcon style={style} />;
    case 'bold':
      return <BoldIcon style={style} />;
    case 'italic':
      return <ItalicIcon style={style} />;
    case 'underline':
      return <UnderlineIcon style={style} />;
    case 'strikethrough':
      return <StrikethroughIcon style={style} />;
    case 'bullet-list':
      return <BulletListIcon style={style} />;
    case 'numbered-list':
      return <NumberedListIcon style={style} />;
    default:
      return null;
  }
};
