import { extractTimeUnit } from 'utils/timeUtils';
import type { TimeUnit } from 'utils/timeUtils';

export type CallbackFunction = () => void;

/**
 * Timer to handle comment time related events to maintain the display
 * the relative comment time compared to present.
 */
export class CommentTimer {
  private interval: any = null;

  private currentTimeUnit: TimeUnit | null = null;
  /**
   * Dispose timer if active
   */
  public dispose = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  /**
   * Start timer with given interval.
   * @param {number}      interval Time interval in milliseconds.
   * @param {CallbackFunction}  callback Callback function to be called in interval.
   */
  private _start = (interval: number, callback: CallbackFunction) => {
    this.dispose();
    this.interval = setInterval(callback, interval);
  };

  /**
   * Start time based on given creation time. Timer will be disposed and restarted
   * if the time unit changes. The callback function will be called in suitable
   * interval based on duration from the creation time.
   *
   * @param {number}            creationTime Creation time in milliseconds.
   * @param {CallbackFunction}  callback     Callback function to be called in interval.
   */
  public start = (creationTime: number, callback: CallbackFunction) => {
    this.currentTimeUnit = extractTimeUnit(creationTime);
    const interval = this._getInterval(this.currentTimeUnit);
    if (interval) {
      this._start(interval, () => {
        const timeUnit = extractTimeUnit(creationTime);
        if (timeUnit !== this.currentTimeUnit) {
          this.dispose();
          this.start(creationTime, callback);
          return;
        }
        callback();
      });
    }
    callback();
  };

  /**
   * Get interval in milliseconds based on time unit.
   * @param   {string} timeUnit  Time unit
   * @returns {number}           Interval in milliseconds
   */
  private _getInterval = (timeUnit: TimeUnit) => {
    switch (timeUnit) {
      case 'seconds':
        return 1000;
      case 'minutes':
        return 60 * 1000;
      case 'hours':
        return 60 * 60 * 1000;
      case 'days':
        return 0;
    }
  };
}
