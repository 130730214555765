import { useCallback, useMemo, useState } from 'react';

import { Button, Menu, MenuItem } from '@mui/material';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Link from 'next/link';
import setup from '../../next-i18next.config';

const locales = setup.i18n.locales
  .map((locale) => locale.toString())
  .sort(
    (a, b) => a.localeCompare(b)
  );

const LocaleSelector = () => {
  const router = useRouter();

  const {
    i18n,
  } = useTranslation() as any;

  const [locale, setLocale,] = useState(i18n?.language);

  const [anchorEl, setAnchorEl,] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    setLocale(i18n?.language);
  }, [i18n?.language,]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setLocale(locale);
    event.preventDefault();
  }, [locale,]);

  // const handleLocaleClick = useCallback((locale: string) => {
  //   i18n.changeLanguage(locale);
  //   handleClose();
  // }, [handleClose, i18n,]);

  const asPath = useMemo(() => {
    // Trim possible anchor from the link to prevent scrolling to position after switching locale
    const result = router.asPath;
    const index = result.indexOf('#');
    if (index < 0) {
      return result;
    }
    return result.substring(0, index);
  }, [router.asPath,]);

  return (
    <div>
      <Button
        id="locale-selector-button"
        aria-controls={open ? 'locale-selector' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {locale}
        <ExpandMoreIcon />
      </Button>
      <Menu
        aria-labelledby="locale-selector-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {
          locales.map((locale) =>
            <Link
              key={locale}
              href={`/${asPath}`}
              locale={locale}
              onClick={handleClose}
              style={{
                textDecoration: 'none',
              }}
            >
              <MenuItem>{locale}</MenuItem>
            </Link>
          )
        }
      </Menu>
    </div>

  );
};

export default LocaleSelector;
