import { firebaseApp } from 'schema';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

/**
 * Function fetches latest termsAndConditions from the storage from the termsAndConditions folder.
 * @return {string | undefined}
 */
export const getTermsAndConditionFile = async (): Promise<string | undefined> => {
  const storage = getStorage(firebaseApp());
  const storageRef = ref(storage, 'termsAndConditions/aavistus_terms_cond.txt'); // File extension can be other if wanted
  try {
    const url = await getDownloadURL(storageRef);
    return await (await fetch(url)).text();
  } catch (e) {
    return undefined;
  }
};

/**
 * Function fetches latest termsAndConditions from the storage from the termsAndConditions folder.
 * @return {string | undefined}
 */
export const getPrivacyPolicyFile = async (): Promise<string | undefined> => {
  const storage = getStorage(firebaseApp());

  const storageRef = ref(storage, 'privacyPolicies/aavistus_privacy_policy.txt'); // File extension can be other if wanted

  try {
    const url = await getDownloadURL(storageRef);
    return await (await fetch(url)).text();
  } catch (e) {
    return undefined;
  }
};
