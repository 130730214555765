/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { BuilderComponentProps, BuilderComponentPropsBase, BuilderComponentType } from '../widgets/PageWidgetRenderer';
import type { FC, FunctionComponent, ReactNode } from 'react';
import type { PropertyEditor } from '../propertyEditors/PropertyEditorTypes';

export type PropertyConfigs<T> = {
  [name in keyof T]: PropertyEditor;
};

interface RegisterArgs {
  /**
   * React component function used to render the content at runtime.
   * If editor component is not been given, this will be used at
   * editing mode too.
   **/
  renderComponent: BuilderFunction;
  /**
   * Optional separate editor component used for more advanced features,
   * like in-place-editing and other adjustments. It is the developers
   * responsibility to make the look and feel of renderer and editor to
   * match. Rendering component is encouraged to not have a editing state
   * to minimize the overhead on runtime. Instead it is better to do a separate
   * editor component.
   */
  editorComponent?: BuilderFunction;
  /**
  * Optional separate graph component used.
  */
  graphComponent?: BuilderFunction;
}

/**
 * Function that returns a partial of the property configs
 * @param component The data of the actual component. When used with composite property editor, this will
 *                  be the data of the field of from the whole data being edited as composite part.
 * @param data      The data of the whole component.
 */
export type BuilderConfigPropsFunction<T extends Record<string, any>, D = any> =
  (component: T, data: D) => PropertyConfigs<T>;

export type PropertyConfig<T extends Record<string, any>> = PropertyConfigs<T> | BuilderConfigPropsFunction<T>;

export type BuilderConfig<T extends Record<string, any> = Record<string, any>, D = any> = {
  /**
   * Type of the builder
   */
  type: string;
  /**
   * Data type identifier. Should match the Typescript type name.
   */
  displayName?: string;

  icon?: React.ReactNode;

  props?: PropertyConfigs<Partial<T>> | BuilderConfigPropsFunction<Partial<T>, D>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export interface BuilderFunction<P extends Record<string, any> = any, T extends Record<string, any> = Record<string, any>> extends FunctionComponent<P> {
  config: BuilderConfig<T>;
}

/**
 * Component builder will provide an access to React components available
 * with dynamic building.
 *
 */
class ComponentBuilder {
  private editors = new Map<string, FC<any>>();
  private graphs = new Map<string, FC<any>>();
  private renderers = new Map<string, FC<any>>();

  private configs = new Map<string, BuilderConfig<any>>();
  /**
   * Get a component rendered function for a given type.
   * @param {BuilderComponentType}   type  Registered component type
   * @return {BuilderFunction}             React rendering function.
   */
  public getRenderer = (type: BuilderComponentType) => {
    return this.renderers.get(type) as BuilderFunction<any>;
  };

  /**
   * Get the editor component function for a given type. If there is registered
   * editor type for type, the render component will be returned.
   *
   * @param {BuilderComponentType}   type   Registered component type
   * @return {BuilderFunction}              React rendering function.
   */
  public getEditor = (type: BuilderComponentType) => {
    return this.editors.get(type) as BuilderFunction<any> ?? null;
  };

  /**
   * Get the graph component function for a given type. If there is registered
   * graph type for type, a null value will be returned.
   *
   * @param {BuilderComponentType}   type   Registered component type
   * @return {BuilderFunction}              React rendering function.
   */
  public getGraph = (type: BuilderComponentType) => {
    return this.graphs.get(type) as BuilderFunction<any> ?? null;
  };

  /**
   * Render a runtime component for given type and data
   * @param {BuilderComponentProps}   props Component properties
   * @return{ReactNode}                     React component instance
   */
  public render = (props: BuilderComponentPropsBase<any>,
    defaultComponent?: JSX.Element
  ): ReactNode => {
    if (!props?.type) {
      return null;
    }
    const Component = this.getRenderer(props.type) as any;
    if (!Component) {
      return defaultComponent ?? null;
    }
    return <Component {...props} />;
  };

  /**
   * Render a editor component for given type and data
   * @param {BuilderComponentProps}   props Component properties
   * @return{ReactNode}                     React component instance
   */
  public renderEditor = ({
    type, data, ...props
  }: BuilderComponentPropsBase<any>): ReactNode => {
    const Component = this.getEditor(type) as any;
    if (!Component) {
      return null;
    }
    // return <Component {...data}/>;
    return <Component data={data}
      {...props} />;
  };

  /**
   * List configurations for registered components
   *
   * @param {BuilderComponentType[]} componentTypes Optional filter to pick only types interested.
   * @return {BuilderConfig[]}
   */
  public getConfigs = (componentTypes?: BuilderComponentType[]) => {
    let configs = Array.from(this.configs.values());
    if (componentTypes) {
      configs = configs.filter((c) => componentTypes?.includes(c.type));
    }
    return configs;
  };

  /**
   * Get configuration for an component type
   * @param {string} componentType  Component type
   * @return {BuilderConfig}        The component configuration for asked type.
   */
  public getConfig = (componentType: BuilderComponentType) => {
    return this.configs.get(componentType);
  };
  /**
   * Register a component to the builder
   *
   */
  public register = <P extends BuilderComponentProps>({
    renderComponent,
    editorComponent,
    graphComponent,
  }: RegisterArgs
  ) => {
    const config = renderComponent.config;
    if (!config) {
      throw new Error(`Component ${renderComponent.name} needs to have a static config defined to be able to register.`);
    }
    const {
      type,
    } = config;
    if (!type) {
      throw new Error(`ComponentBuilder requires unique type name to be defined for all registered components. (${renderComponent.name})`);
    }
    if (this.renderers.has(type)) {
      throw new Error(`Type ${type} of the ${renderComponent.name} is already registered.`);
    }
    this.configs.set(type, config);
    this.renderers.set(type, renderComponent);
    this.editors.set(type, editorComponent ?? renderComponent);
    if (graphComponent) {
      this.graphs.set(type, graphComponent);
    }
  };
}

export const componentBuilder = new ComponentBuilder();

export const createFunctionComponent = (config: BuilderConfig<any>): BuilderFunction => {
  return {
    config,
  } as BuilderFunction;
};

export default componentBuilder;
