import type { BlocQueryConstraint } from '../types';

/**
 * Generate unique key specific for given constraints. The key is used to
 * compare queries. Key is generated based on the given constraints
 * and sorted alphabetically. Constraints with `orderBy` are omitted.
 *
 * @param {BlocQueryConstraint[]} constraints The query constraints
 * @return {string}  Unique key for the query constraints.
 */
export const generateQueryConstraintsKey = (
  constraints: BlocQueryConstraint[] = []
) => {
  const keys = constraints
    .filter((c) => c.type && c.type !== 'orderBy')
    .map((c) => {
      const type = c.type;

      switch (type) {
        case 'where':
          return `w${c.fieldPath}${c.opStr}${c.value.toString()}`;
        case 'limit':
          return `l${c.limit}`;
        // case 'orderBy':
        //   return `o${c.fieldPath}${c.directionStr === 'desc' ? '_d' : ''}`;
        default:
          throw new Error(`Unidentified constraint type: ${type}`);
      }
    });
  if (keys.length === 0) {
    return '_';
  }
  keys.sort((a, b) => a.localeCompare(b));

  return keys.join('_');
};
