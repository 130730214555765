import { ADMIN_BUNDLE } from '@shared/schema/src/https/bundles';
import { createBundleFunctionFactory } from 'utils/firebase/bundles/createBundleFunctionFactory';
import type { RemoveUserMessage, SignUpUserWithEmailMessage } from '@shared/schema/src';
import type { SendVerificationEmailMessage } from '@shared/schema/src/https/admin/user/SendVerificationEmail';
import type { UpdateUserMessage } from '@shared/schema/src/https/admin/user/UpdateUser';
import type { VerifyEmailMessage } from '@shared/schema/src/https/admin/user/VerifyEmail';

const factory = createBundleFunctionFactory(ADMIN_BUNDLE);

export const updateUser = factory<UpdateUserMessage>('update-user');

export const removeUser = factory<RemoveUserMessage>('remove-user');

export const sendVerificationEmail = factory<SendVerificationEmailMessage>('send-verification-email');

export const verifyEmail = factory<VerifyEmailMessage>('verify-email');

export const _signUpUserWithEmail = factory<SignUpUserWithEmailMessage>('sign-up-user-with-email');
