/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { HeroIcon } from './HeroIcon';
import { hasAnyFeatures } from '@mindhiveoy/auth';
import { motion } from 'framer-motion';
import { useAuth } from '@mindhiveoy/react-auth';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { v4 } from 'uuid';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import Grid from '@mui/material/Grid';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import QrCodeIcon from '@mui/icons-material/QrCode';
import TagIcon from '@mui/icons-material/Tag';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TimelineIcon from '@mui/icons-material/Timeline';
import Typography from '@mui/material/Typography';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import styled from '@emotion/styled';
import theme from 'theme';
import type { Canvas, FeatureId } from '@shared/schema/src';

interface Configuration<T> {
  type: string;
  displayName: string;
  icon: JSX.Element;
  data: Partial<T>;
  comingSoon?: boolean;
  withFeatures?: FeatureId[];
}

interface ConfigurationGroup {
  title: string;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configurations: Configuration<any>[];
}

const Label = styled.span(({
  theme,
}) => `
  margin-top: ${theme.spacing(1)};
  color: ${theme.palette.primary.contrastText};
`);

// eslint-disable-next-line require-jsdoc
function w<T>(d: T): T {
  return d as T;
}

export interface ComponentItemProps {
  onPick: (type: string, data: Partial<Canvas>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: Configuration<any>;
}

const Container = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  width: 100%;
`;

const SectionHeader = styled(Typography)(({
  theme,
}) => `
  margin-bottom: ${theme.spacing(2)};
`);

const ComponentSection = styled.div(({
  theme,
}) => `
  padding: ${theme.spacing(1)}; 
  width: 100%;
`);

const Item = styled(motion.div)(({
  theme,
}) => `
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 8px;
  margin-bottom: 8px;
  box-sizing: border-box;
  padding: ${theme.spacing(1)};  
  background-color: ${theme.palette.background.innerPaper};
  border-radius: ${theme.shape.borderRadius}px;  
`);

/**
 * @param {ComponentItemProps} Props
 * @return {JSX.Element}
 */
const ComponentItem = ({
  onPick,
  config,
}: ComponentItemProps) => {
  const {
    t,
  } = useTranslation();

  const handleClick = useCallback((event: any) => {
    onPick && onPick(config.type, config.data);
    event.stopPropagation();
  }, [config.data, config.type, onPick,]);

  const implemented = !config.comingSoon;

  // TODO: useMemo
  return <Grid item
    key={`${config.type}_${config.displayName}`}
    xs={3}
    md={2}>
    <Item
      onClick={implemented ? handleClick : undefined}
      whileHover={{
        scale: implemented ? 1.05 : 1,
      }}
      style={{
        opacity: implemented ? 1 : 0.4,
        border: implemented ? `1px solid ${theme.palette.action.disabledBackground}` : 'none',
      }}
    >
      <Label>{config.icon}</Label>
      <Label>
        {t(config.displayName)}
      </Label>
    </Item>
  </Grid>;
};

export interface CanvasPickerProps {
  onPick: (type: string, data: Partial<Canvas>) => void;
}

/**
 * @param {CanvasPickerProps} Props
 * @return {JSX.Element}
 * onPick = (type: string) => void;
 */
const CanvasPicker = ({
  onPick,
}: CanvasPickerProps) => {
  const {
    runtimePlan,
    role,
  } = useAuth();

  const {
    t,
  } = useTranslation();

  const configurations: ConfigurationGroup[] = useMemo(() => {
    return [
      {
        id: 'q',
        title: t('interactive'),
        configurations: [
          w<Configuration<Canvas>>({
            type: 'canvas',
            icon: <Grid4x4Icon />,
            displayName: t('scale-2d'),
            data: {
              type: '2d',
            },
          }),
          w<Configuration<Canvas>>({
            type: 'canvas',
            icon: <LinearScaleIcon />,
            displayName: t('scale-1d'),
            data: {
              type: '1d',
            },
          }),
          w<Configuration<Canvas>>({
            type: 'canvas',
            icon: <TextSnippetIcon />,
            displayName: t('free-text'),
            data: {
              type: 'text',
            },
          }),
          w<Configuration<Canvas>>({
            type: 'canvas',
            icon: <ViewWeekIcon />,
            displayName: t('categorize'),
            comingSoon: true,
            data: {
              type: 'category',
            },
          }),

          w<Configuration<Canvas>>({
            type: 'canvas',
            icon: <TimelineIcon />,
            displayName: t('time-series'),
            comingSoon: true,
            data: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              type: 'timeserie' as any, // TODO: Support for time series
            },
          }),
        ],
      }, {
        id: 'ch',
        title: t('chart'),
        configurations: [
          w<Configuration<Canvas>>({
            type: 'layout',
            icon: <HeroIcon />,
            displayName: t('plain-canvas'),
            data: {
              type: 'layout',
              layout: 'single',
            },
          }),
          w<Configuration<Canvas>>({
            type: 'answer-graph',
            icon: <BubbleChartIcon />,
            displayName: t('answer-graph'),
            comingSoon: true,
            data: {
              type: 'layout',
              layout: 'single',
              elements: `[{"id":"${v4()}", "type":"graph-2d", "data": {"dataMode":"real-time", "characteristics":"mean"}}]`,
            },
          }),
          w<Configuration<Canvas>>({
            type: 'qr-code',
            icon: <QrCodeIcon />,
            displayName: t('qr-code'),
            data: {
              type: 'layout',
              layout: 'single',
              elements: `[{"id":"${v4()}", "type":"qr-code", "data": {"textAlign":"left","verticalAlign":"middle","size":"50%"}}]`,
            },
          }),
          w<Configuration<Canvas>>({
            type: 'tag',
            icon: <TagIcon />,
            displayName: t('tag-cloud'),
            data: {},
            comingSoon: true,
          }),
        ],
      }, {
        id: 'c',
        title: t('content'),
        configurations: [
          w<Configuration<Canvas>>({
            type: 'hero',
            icon: <HeroIcon />,
            displayName: t('hero'),
            data: {},
            comingSoon: true,
          }),
          w<Configuration<Canvas>>({
            type: 'hero',
            icon: <ViewColumnIcon />,
            displayName: t('columns'),
            data: {},
            comingSoon: true,
          }),
          w<Configuration<Canvas>>({
            type: 'carousel',
            icon: <ViewCarouselIcon />,
            displayName: t('carousel'),
            data: {},
            comingSoon: true,
          }),
          // w<Configuration<Canvas>>({
          //   type: 'comment-feed',
          //   icon: <DynamicFeedIcon />,
          //   displayName: 'Comment feed',
          //   data: {},
          //   withFeatures: ['live',],
          // }),
          // w<Configuration<Canvas>>({
          //   type: 'talking-head',
          //   icon: <RecordVoiceOverIcon />,
          //   displayName: 'AI Person',
          //   data: {},
          //   withFeatures: ['live',],
          // }),
        ],
      },
    ];
  }, [t,]);

  const sections = useMemo(() => {
    const sections: ConfigurationGroup[] = [];

    configurations.forEach((section) => {
      const s: Configuration<any>[] = [];

      section.configurations.forEach((config) => {
        if (!config.withFeatures || hasAnyFeatures(config.withFeatures, runtimePlan as any, role as any)) {
          s.push(config);
        }
      });
      if (s.length > 0) {
        sections.push({
          ...section,
          configurations: s,
        });
      }
    });

    return sections;
  }, [configurations, role, runtimePlan,]);

  return <Container>
    {sections.map((section) => <ComponentSection key={section.id}>

      <SectionHeader variant="h5">{section.title}</SectionHeader>
      <Grid container>{
        section.configurations.map((config, i) => <ComponentItem
          key={`${config.type}_${i}`}
          config={config}
          onPick={onPick}
        />)
      }</Grid>
    </ComponentSection>)
    }
  </Container>;
};

export default CanvasPicker;
