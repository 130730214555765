/* eslint-disable @typescript-eslint/no-explicit-any */
import { CategoryContainerModel, NEW_ITEM } from 'components/canvas/canvasTypes/CategorizeQuestionCanvas/CategoryContainerModel';
import { LayoutGroup, Reorder, motion } from 'framer-motion';
import { darken } from '@mui/material';
import { parseCanvasData } from './parseCanvasData';
import { useCallback, useEffect, useState } from 'react';
import { useComponentDataContext } from 'utils/hooks/useComponentDataContext';
import { useTheme } from '@emotion/react';
import Categorize from 'components/canvas/canvasTypes/CategorizeQuestionCanvas/Categorize';
import CategoryContainer from 'components/canvas/canvasTypes/CategorizeQuestionCanvas/CategoryContainer';
import HTMLInplaceEditor from 'components/editing/HTMLInplaceEditor';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import type { CategorizeProps } from 'components/canvas/canvasTypes/CategorizeQuestionCanvas/Categorize';
import type { CategoryDescriptor } from 'components/canvas/canvasTypes/CategorizeQuestionCanvas/CategoryContainerModel';
import type { CategoryItem, CategoryQuestionCanvas, CategoryQuestionCanvas as CategoryQuestionCanvasEditor } from '@shared/schema/src';
import type { RenderCategoryItemFunction } from 'components/canvas/canvasTypes/CategorizeQuestionCanvas/CategoryItem';
import type { WithId } from '@mindhiveoy/schema';

const CategorizeGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  flex-direction: row;
  align-items: stretch;
  margin: 1rem;
  position: relative;
  width: 100%;
  grid-auto-rows: auto;
  grid-column-gap: 16px;
  grid-template-columns: 200px auto 1.5rem;
  grid-template-rows: auto 1rem;
  grid-template-areas: "items matrix";
`;

export const Row = styled(Reorder.Item)`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const Item = styled$(motion.div)<{
  $backgroundColor: string;
  $color: string;
}>(({
  theme,
  $backgroundColor,
  $color,
}) => `
  padding: ${theme.spacing(1.5)};
  border-radius: ${theme.spacing(2)};
  background-color: ${$backgroundColor};
  color: ${$color};
  margin-bottom: ${theme.spacing(1)};
  ::last-child {
    margin-bottom: 0;
  }
`);

export interface CanvasCategorizeProps {
  canvas: CategoryQuestionCanvasEditor;

  edit?: boolean;

  onRenderCategoryName?: (category: CategoryItem, index: number) => JSX.Element;

  onCategoryNameChange?: (categoryId: string, name: string) => void;
  onCategoryClick?: (category: CategoryItem) => void;
  onOptionClick?: (categoryId: string, itemId: string) => void;
  onDeleteOption?: (category: string, index: number) => void;
  onDeleteCategory?: (category: string, index: number) => void;
}

const CategoryQuestionCanvasEditorComponent = ({
  canvas,
  onOptionClick,
  onCategoryClick,
  onCategoryNameChange,
}: CanvasCategorizeProps): JSX.Element => {
  const {
    model: componentModel,
  } = useComponentDataContext<WithId<CategoryQuestionCanvas>>();

  const [model,] = useState(() => {
    const {
      categories,
      items,
    } = parseCanvasData(canvas);

    return new CategoryContainerModel<CategoryDescriptor, any>( // TODO: fix type
      categories,
      items
    );
  });

  const theme = useTheme();

  useEffect(() => componentModel.subscribeToDataChange(({
    newValue,
  }) => {
    const categories = newValue?.data.categories ?? [];
    model.updateCategories(categories);
  }), [componentModel, model,]);

  const handleOptionClick = useCallback((categoryId: string, itemId: string) => (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onOptionClick?.(categoryId, itemId);
  }, [onOptionClick,]);

  const handleRenderItem: RenderCategoryItemFunction<any> = useCallback(
    ({
      data: {
        name,
      },
      itemId,
      categoryId,
      isDragged,
      draggingTarget,
    }) => {
      const isNewItemEditor = itemId === NEW_ITEM;

      const category = model.getCategory(isDragged ? draggingTarget : categoryId);

      const categoryColor = category?.color ?? '#aaa';

      const backgroundColor = darken(categoryColor, isNewItemEditor ? 0.5 : 0.1);

      const color = theme.palette.getContrastText(backgroundColor);
      return (
        <Item
          $backgroundColor={backgroundColor}
          $color={color}
          transition={{
            duration: 0.5,
          }}
          onClick={handleOptionClick(categoryId, itemId)}
        >
          <span>{name}</span>
        </Item>
      );
    }, [handleOptionClick, model, theme.palette,]);

  const handleChange = useCallback((name: string, label: string) => {
    onCategoryNameChange?.(name, label);
  }, [onCategoryNameChange,]);

  const handleRenderCategoryName = useCallback((category: CategoryDescriptor) => {
    return <HTMLInplaceEditor
      text={category.name}
      name={category.id}
      onChange={handleChange as any}
    />;
  }, [handleChange,]);

  const handleRenderCategories = useCallback(
    (categories: CategoryDescriptor[], props: CategorizeProps) => {
      const cats = categories.slice(0);
      const index = cats.findIndex((c) => c.id === '_');

      const pickCategory = cats.splice(index, 1)[0];

      return <CategorizeGrid>
        <div
          style={{
            gridArea: 'items',
          }}
        >
          <CategoryContainer
            model={props.model}
            category={pickCategory}
            onRenderItem={handleRenderItem}
            onRenderCategoryName={handleRenderCategoryName}
          />
        </div>
        <motion.div
          style={{
            gridArea: 'matrix',
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: 16,
            flexBasis: '30%',
          }}
        >
          {
            cats.map((c) =>
              <CategoryContainer
                key={c.id}
                edit
                model={props.model}
                category={c}
                onClick={onCategoryClick as any} // TODO: fix typings
                onRenderItem={handleRenderItem}
                onRenderCategoryName={handleRenderCategoryName}
              />)
          }
        </motion.div>
      </CategorizeGrid>;
    }, [handleRenderCategoryName, handleRenderItem, onCategoryClick,]);

  return <LayoutGroup id="draggable-table">
    <Categorize
      model={model}
      edit
      onRenderItem={handleRenderItem}
      onRenderCategories={handleRenderCategories}
    />
  </LayoutGroup>;
};

export default CategoryQuestionCanvasEditorComponent;
