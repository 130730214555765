import dayjs from 'dayjs';
import type { FirestoreTimeStamp } from '@shared/schema/src/common';
import type { UTCTime } from '@mindhiveoy/schema';

export const getDateFromMilliseconds = (ms: number): string => {
  return dayjs(ms).format('DD.MM.YYYY');
};

export const getTimeFromMilliseconds = (ms: number): string => {
  return dayjs(ms).format('hh.mm');
};

export type TimeUnit = 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months';
/**
 * Extract time unit from given time to this moment
 * @param fromMoment {number} Time in milliseconds
 * @returns  {TimeUnit} Time unit
 */
export const extractTimeUnit = (fromMoment: number): TimeUnit => {
  const now = new Date().getTime();

  const delta = now - fromMoment;

  if (delta < 60 * 1000) {
    return 'seconds';
  }
  if (delta < 60 * 60 * 1000) {
    return 'minutes';
  }
  if (delta < 60 * 60 * 60 * 1000) {
    return 'hours';
  }

  const days = Math.round(delta / (1000 * 60 * 60 * 24));
  if (days < 7) {
    return 'days';
  }
  if (days < 31) {
    return 'weeks';
  }

  return 'months';
};

export const renderMoment = (_when: number): string => {
  const now = new Date().getTime();

  const delta = now - _when;

  if (delta < 60 * 1000) {
    const seconds = Math.round(delta / 1000);
    if (seconds === 0) {
      return 'now';
    }
    return `${seconds} seconds ago`;
  }
  if (delta < 60 * 60 * 1000) {
    const minutes = Math.round(delta / (1000 * 60));
    return `${minutes} minutes ago`;
  }
  if (delta < 60 * 60 * 60 * 1000) {
    const hours = Math.round(delta / (1000 * 60 * 60));
    return `${hours} hours ago`;
  }

  const days = Math.round(delta / (1000 * 60 * 60 * 24));
  if (days < 7) {
    return days === 1 ? 'day ago' : `${days} days ago`;
  }

  if (days < 31) {
    const weeks = Math.round(days / 7);
    return weeks === 1 ? 'week ago' : `${weeks} weeks ago`;
  }

  return dayjs(_when).format('D.M.YYYY');
};

export const toDateString = (timeStamp: number | FirestoreTimeStamp): string => {
  return new Date(extractDateToMilliseconds(timeStamp)).toDateString();
};

export const extractDateToMilliseconds = (timeStamp: UTCTime | number | FirestoreTimeStamp): number => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const t = timeStamp as any;
  // TODO verify that the created is Firestore timestamp
  if (t?.toDate) {
    return t?.toDate().getTime();
  }

  // If Firestore timestamp
  if (t?._nanoseconds) {
    return t.seconds * 1000 + t.minutes * 60 + t._nanoseconds / 1000;
  }
  return timeStamp as number;
};

/**
 * Delay for a number of milliseconds
 * @param ms
 * @returns
 */
export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
