import { MessageType } from 'ts-react-form-validator';
import type { FormObject, MessageFields } from 'ts-react-form-validator';

interface FieldMessageProps<T> {
  messages?: MessageFields<T>;
  field: keyof T;
}

/**
 * Component showing a message for a specific field in form. If no message is
 * delivered, component will return null.
 *
 * @param {MessageField}  formMessage  Form message to show.
 * @return {FieldMessage} If message was found, component will be returned otherwise null.
 */
export const FieldMessage = <T extends FormObject>({
  messages,
  field,
}: FieldMessageProps<T>
) => {
  const fieldMessage = messages && messages[field];
  if (!fieldMessage) {
    return null;
  }

  return <div style={{
    color: fieldMessage.type === MessageType.ERROR ? 'red' : 'black',
  }}>
    {fieldMessage.message}
  </div>;
};

export default FieldMessage;
