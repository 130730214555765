/* eslint-disable @typescript-eslint/no-explicit-any */
import Typography from '@mui/material/Typography';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

import { type Axis, type TickLabelingType, defaultSliderColors } from '@shared/schema/src';
import { getAxisRange } from './getAxisRange';
import { useTranslation } from 'next-i18next';
import CompositePropertyEditorControl from '../CompositePropertyEditorControl';
import type { Axis1DPropertyEditor } from '../PropertyEditorTypes';
import type { BuilderConfigPropsFunction } from '../../builders/componentBuilder';

interface Axis1DVirtual {
  scaleLength: number; // TODO Evaluate, is it so bad if this will be saved to Firestore
}

const Axis1DEditor: PropertyEditorFunction<Axis, Axis1DPropertyEditor> = ({
  data,
  component,
  propertyName,
  propertyNamePath,
  propertyConfig: {
    defaultValue,
    displayName,
  },
  onSave,
  onDelete,
}) => {
  // const [value, setValue,] = useState(data ?? defaultValue);

  const id = `editor-${propertyName}`;

  const {
    t,
  } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const axis1DConfig: BuilderConfigPropsFunction<Partial<Axis>> = (component, data) => {
    // TODO Exact typing and support for axis in different contexes
    const mode = data?.mode ?? 'discrete';

    const colors = {
      type: 'axis-color-picker',
      displayName: 'Graph coloring',
      order: 15,
      defaultValue: defaultSliderColors,
    };

    switch (mode) {
      case 'discrete':
        return {
          scaleType: {
            type: 'picker',
            displayName: t('scale-type'),
            // eslint-disable-next-line sonarjs/no-duplicate-string
            defaultValue: 'plus-minus',
            options: [
              {
                value: 'plus-minus',
                label: t('Plus-Minus'),
              },
              {
                value: 'numeric',
                label: t('numeric'),
              },
              {
                value: 'percentage',
                label: t('percentage'),
              },
              // Custom mode can not be set explicitly, it is set when custom labels are set from the Editor UI
              {
                value: 'custom',
                label: t('Custom'),
                hide: true,
              },
            ],
            order: 10,
            onPropertyChange: ({
              component,
              data,
              propertyValue,
              updateProperty,
            }: any) => {
              const axis = data as Axis & Axis1DVirtual;

              // TODO Validation
              const range = getAxisRange({
                scaleType: propertyValue as TickLabelingType,
                currentRange: data.range,
                scaleLength: axis.scaleLength ?? 7,
                mode: component.mode,
                max: component.axis.max,
              });

              if (range) {
                // Custom labels are written to labels in axes directly
                updateProperty('range', range);
              }
            },
          },
          scaleLength: {
            type: 'picker',
            displayName: t('number-of-intervals'),
            defaultValue: 7,
            options: [
              {
                value: 2,
                label: '2',
              },
              {
                value: 3,
                label: '3',
              },
              {
                value: 4,
                label: '4',
              },
              {
                value: 5,
                label: '5',
              },

              {
                value: 6,
                label: '6',
              },
              {
                value: 7,
                label: '7',
              },

              {
                value: 8,
                label: '8',
              },
              {
                value: 9,
                label: '9',
              },
              {
                value: 10,
                label: '10',
              },
              {
                value: 11,
                label: '11',
              },

            ],
            order: 11,

            onPropertyChange: ({
              component,
              data,
              propertyValue,
              updateProperty,
            }: any) => {
              const axis = data as Axis;

              const {
                scaleType = 'plus-minus',
              } = axis;

              // TODO Validation
              const range = getAxisRange({
                scaleType,
                currentRange: data.range,
                scaleLength: propertyValue ?? 7,
                mode: component.mode,
                max: component.axis.max,
              });

              if (range) {
                // Custom labels are written to labels in axes directly
                updateProperty('range', range);
              }
            },
          },
          colors,
        };
      case 'continuous':
        return {
          scaleType: {
            type: 'picker',
            displayName: t('scale-type'),
            // eslint-disable-next-line sonarjs/no-duplicate-string
            defaultValue: 'numeric',
            options: [
              // Plus-minus and custom are somewhat cumbersome for continuous scale
              {
                value: 'numeric',
                label: t('numeric'),
              },
              {
                value: 'percentage',
                label: t('percentage'),
              },
            ],
            order: 10,
            onPropertyChange: ({
              component,
              data,
              propertyValue,
              updateProperty,
            }: any) => {
              const axis = data as Axis & Axis1DVirtual;

              const {
                scaleLength = 7,
              } = axis;

              // TODO Validation
              const range = getAxisRange({
                scaleType: propertyValue,
                currentRange: data.range,
                scaleLength,
                mode: component.mode,
                max: component.axis.x.max,
              });

              if (range) {
                // Custom labels are written to labels in axes directly
                updateProperty('range', range);
              }
            },
          },
          scaleLength: {
            type: 'picker',
            displayName: t('number-of-ticks'),
            defaultValue: 2,
            options: [
              {
                value: 2,
                label: '2',
              },
              {
                value: 3,
                label: '3',
              },
              {
                value: 4,
                label: '4',
              },
              {
                value: 5,
                label: '5',
              },

              {
                value: 6,
                label: '6',
              },
              // {
              //   value: 7,
              //   label: '7',
              // },

              // {
              //   value: 8,
              //   label: '8',
              // },
              // {
              //   value: 9,
              //   label: '9',
              // },
              // {
              //   value: 10,
              //   label: '10',
              // },
              // {
              //   value: 11,
              //   label: '11',
              // },

            ],
            order: 11,

            onPropertyChange: ({
              component,
              data,
              propertyValue,
              updateProperty,
            }: any) => {
              const axis = data as Axis;

              const {
                scaleType = 'plus-minus',
              } = axis;

              // TODO Validation
              const range = getAxisRange({
                scaleType,
                currentRange: data.range,
                scaleLength: propertyValue ?? 7,
                mode: component.mode,
                max: component.axis.x.max,
              });

              if (range) {
                updateProperty('range', range);
              }
            },
          },

          ...component.scaleType === 'numeric' && {
            // min: {
            //   type: 'number',
            //   displayName: 'Minimum value',
            //   defaultValue: -3,
            //   order: 12,
            // },
            max: {
              type: 'number',
              displayName: 'Minimum value',
              defaultValue: -3,
              order: 12,
            },
          },
          colors,
        };

      default:
        throw new Error(`Unexpected Canvas mode: ${mode}`);
    }
  };

  return <>
    <Typography component={'span'}
      variant="h4"
      id={`${id}-label`}>{displayName}</Typography>

    <CompositePropertyEditorControl
      component={component}
      propertyName={propertyName}
      propertyNamePath={propertyNamePath}
      data={data ?? defaultValue}
      config={axis1DConfig}
      onSave={onSave}
      onDelete={onDelete}
    />
  </>;
};

Axis1DEditor.config = {
  type: 'axis-1d',
  displayName: 'Axis',
};

export default Axis1DEditor;
