
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { PropertyEditorFunction } from '../../../builders/propertyEditorBuilder';

import { COLOR_PALETTE } from '../../lookAndFeel/ColorPickerEditor';
import { CirclePicker } from 'react-color';
import { FieldSet } from 'components/forms/FieldSet';
import { Legend } from 'components/forms/Legend';
import { Typography } from '@mui/material';
import { defaultSliderColors } from '@shared/schema/src';
import { motion } from 'framer-motion';
import { useTheme } from '@emotion/react';
import AnimateHeight from 'components/common/AnimateHeight';
import PropertyEditorControl from '../../PropertyEditorControl';
import cloneDeep from 'lodash/cloneDeep';
import styled$ from 'utils/react/styled$';
import type { ColorResult } from 'react-color';
import type { CornerColorPickerPropertyEditor } from '../../PropertyEditorTypes';
import type { CornerColors } from '@shared/schema/src';

const keys: (keyof CornerColors)[] = [
  'luc', 'ruc', 'llc', 'rlc',
];

const CirclePickerHack = CirclePicker as any;

const Grid = styled$.div<{
  $mode: 'slider' | '2d',
}>(({
  $mode,
}) => `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: ${$mode === 'slider' ? 1 : 0}rem 0 ;
`);

const CornerColorPickerEditor: PropertyEditorFunction<CornerColors, CornerColorPickerPropertyEditor> = ({
  data,
  propertyName,
  propertyConfig: {
    displayName,
    defaultValue = defaultSliderColors,
    section,
    mode,
  },
  onSave,
}) => {
  const [value, setValue,] = useState<CornerColors>(data ?? defaultValue);

  const [target, setTarget,] = useState<keyof CornerColors>();

  const theme = useTheme();

  const [open, setOpen,] = useState(false);

  useEffect(() => {
    if (data && data !== value) {
      setValue(data);
    }
  }, [data, value,]);

  const handleChange = useCallback(
    (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
      if (!target) {
        return;
      }

      const newColor = color.hex;

      const newValue = cloneDeep(typeof value === 'string' ? {} : value);
      newValue[target] = newColor;

      setValue(newValue);
      if (newColor !== target && onSave) {
        onSave(propertyName, newValue);
      }
      event.stopPropagation();
      setOpen(false);
      setTarget(undefined);
    }, [onSave, propertyName, target, value,]);

  const id = useMemo(() => `editor-${propertyName}`,
    [propertyName,]
  );

  const handleOpenPicker = useCallback((key: keyof CornerColors) => () => {
    if (target === key) {
      setOpen(false);
      setTarget(undefined);
      return;
    }
    setTarget(key);
    setOpen(true);
  }, [target,]);

  return <>
    {section &&
      <>
        <Typography component={'span'}
          variant="h4"
          id={`${id}-label`}>{section}
        </Typography>
        <br />
      </>
    }<PropertyEditorControl>
      <FieldSet>
        <Legend>{displayName}</Legend>
        <Grid $mode={mode}>
          {
            keys.map((key) => {
              return <motion.button
                key={key}
                style={{
                  width: '100%',
                  marginTop: 0,
                  height: '3rem',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor: target === key ? '#fff' : theme.palette.background.default,
                  ...cornerStyles(key, mode, theme),
                }}
                animate={{
                  backgroundColor: value[key],
                }}
                onClick={handleOpenPicker(key)}
              />;
            })
          }
        </Grid>

        <AnimateHeight visible={open && !!target}>
          <motion.div
            key="picker"
            style={{
              paddingTop: 18,
              overflow: 'hidden',
            }}
            initial={{
              maxHeight: 0,
            }}
            animate={{
              maxHeight: 'inherit',
            }}
            exit={{
              maxHeight: 0,
            }}
          >
            <CirclePickerHack
              colors={COLOR_PALETTE}
              width="100%"
              color={target ? value[target] : undefined}
              onChangeComplete={handleChange}
            />
          </motion.div>

        </AnimateHeight>
      </FieldSet>
    </PropertyEditorControl>
  </>;
};

const cornerStyles = (key: keyof CornerColors, mode: 'slider' | '2d', theme: any) => {
  switch (mode) {
    case 'slider':

      return {
        borderTopLeftRadius: ['luc', 'llc',].includes(key) ? theme?.shape?.borderRadius : undefined,
        borderBottomLeftRadius: ['luc', 'llc',].includes(key) ? theme?.shape?.borderRadius : undefined,
        borderTopRightRadius: ['ruc', 'rlc',].includes(key) ? theme?.shape?.borderRadius : undefined,
        borderBottomRightRadius: ['ruc', 'rlc',].includes(key) ? theme?.shape?.borderRadius : undefined,
      };
    case '2d':
      return {
        borderTopLeftRadius: key === 'luc' ? theme?.shape?.borderRadius : undefined,
        borderTopRightRadius: key === 'ruc' ? theme?.shape?.borderRadius : undefined,
        borderBottomLeftRadius: key === 'llc' ? theme?.shape?.borderRadius : undefined,
        borderBottomRightRadius: key === 'rlc' ? theme?.shape?.borderRadius : undefined,
      };
  }
  return undefined;
};

CornerColorPickerEditor.config = {
  type: 'corner-color-picker',
};

export default CornerColorPickerEditor;
