import { AnimatePresence } from 'framer-motion';
import { generatePathUrl } from 'utils/urls';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSessionDraft } from '../../../../providers/DraftSessionDataContextProvider/useSessionDraft';
import { useTranslation } from 'next-i18next';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CanvasPicker from '../../../../../../canvas/CanvasPicker';
import Form from 'components/forms/Form';
import LoadingIndicator from 'components/common/LoadingIndicator';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import usePathParams from 'utils/hooks/usePathParams';
import type { Canvas } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

const StyledBackdrop = styled(Backdrop)(({
  theme,
}) => `
    z-index: 10000;
    padding: ${theme.spacing(8)};
  `);

export interface CanvasCreatorProps {
  open: boolean;
  onClose: () => void;
}

/**
   * @param {SessionEditorToolbarProps} Props
   * @return {JSX.Element}
   */
const CanvasCreator = ({
  open,
  onClose,
}: CanvasCreatorProps) => {
  const {
    query,
    replace,
  } = useRouter();

  const {
    t,
  } = useTranslation();

  const [canvases, setCanvases,] = useState<WithId<Canvas>[]>([]);
  const [creating, setCreating,] = useState(false);

  const pathParams = usePathParams();

  const {
    createCanvas,
    subscribeToCanvases,
  } = useSessionDraft();

  useEffect(() => subscribeToCanvases(setCanvases), [subscribeToCanvases,]);

  const handlePick = useCallback(async (componentType: string, canvas: Partial<Canvas>) => {
    setCreating(true);
    const response = await createCanvas(
      canvas,
      pathParams.canvasId
    );

    const canvasId = response._id;

    const path = generatePathUrl({
      ...query,
      canvasId,
    }, 'canvasId', 'edit');

    setCreating(false);
    // Updating the Path in the Left-Up corner
    if (response) {
      const options = canvases;
      options.push(response);
    }
    replace(path, path, {
      shallow: true,
    });
    onClose();
  }, [createCanvas, pathParams.canvasId, query, replace, onClose, canvases,]);

  return <>
    {creating ? <StyledBackdrop
      key="backdrop"
      open
    >
      <LoadingIndicator fullScreen
        loaderTitle={t('creating-canvas')}
      />
    </StyledBackdrop> : null
    } <AnimatePresence>
      {open &&
        <StyledBackdrop
          key="backdrop"
          open={!creating}
          onClick={onClose}
        >
          <Form id="new-page" >
            <Typography component={'span'}
              variant="h1">{t('new-canvas')}</Typography>
            <CanvasPicker onPick={handlePick} />
            <Button>{t('Cancel')}</Button>
          </Form>
        </StyledBackdrop>
      }
    </AnimatePresence>
  </>;
};

export default CanvasCreator;
