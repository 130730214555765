import {
  Image, StepComponentContainer, StepContent, StepIcon, StepText, StepTextContainer
} from './OnboardingDialog';
import { useTranslation } from 'next-i18next';
import CommentIcon from '@mui/icons-material/Comment';

const Onboarding3 = () => {
  const {
    t,
  } = useTranslation();

  return <StepContent>
    <StepComponentContainer>
      <Image src='/images/onboarding/nestedcomment.svg' />
    </StepComponentContainer>

    <StepTextContainer>
      <StepIcon>
        <CommentIcon />
      </StepIcon>
      <StepText>
        {t('start-a-conversation-by-commenting-on-others-comments')}.
      </StepText>
    </StepTextContainer>
  </StepContent>;
};
export default Onboarding3;
