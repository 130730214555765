import styled$ from '../../utils/react/styled$';

const BackgroundFadeTop = styled$.div<{
  $disableScroll?: boolean;
  $fadeExtended?: boolean;
  $midwayThreshold?: number;
  $height?: string;
}>(({
  $disableScroll,
  $fadeExtended = true,
  $midwayThreshold,
  $height,
}) => `
  position: absolute;
  margin: 0;
  background: rgb(2,0,36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 0, 0.7) 0%,
    rgba(2, 0, 0, ${$fadeExtended ? 0.6 : 0.4}) ${$midwayThreshold ? $midwayThreshold : '60'}%,
    rgba(2, 0, 0, 0) 100%
  ); 
  height: ${$height ? $height : $fadeExtended ? '250px' : '200px'};
  width: 100%;
  overflow: ${$disableScroll ? 'hidden' : 'visible'}; // Change 'auto' to 'visible'
  pointer-events: none;
`);

export default BackgroundFadeTop;
