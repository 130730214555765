/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */
import { DEFAULT_GRAPH2D_SIZE } from '../../canvasTypes/Matrix2DQuestionCanvas';
import { Sliders } from './Sliders';
import { Typography } from '@mui/material';
import { useComponentDataContext } from '../../../../utils/hooks/useComponentDataContext';
import { useTranslation } from 'next-i18next';
import EditMatrix2D from './EditMatrix2D';
import HTMLInplaceEditor, { ALL_STYLES_ALLOWED, EMPHASIS_STYLES_ALLOWED } from '../../../../components/editing/HTMLInplaceEditor';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import React, { useEffect, useState } from 'react';
import StatisticLegend from 'components/canvas/components/StatisticLegend';
import styled from '@emotion/styled';
import theme from 'theme';
import type {
  AnalyzeOptions,
  AnswerVisibility,
  CanvasId, Matrix2DQuestionCanvas, Scale2DAnswer, WithPathParamProps
} from '@shared/schema/src';
import type { CSSProperties } from 'react';
import type { WithId } from '@mindhiveoy/schema';

export const editorTypographyStyleCentered: CSSProperties = {
  width: '100%',
  wordWrap: 'break-word',
};

export const editorTypographyStyleLeft: CSSProperties = {
  width: '100%',
  wordWrap: 'break-word',
  textAlign: 'left',
};

export const Content = styled.div`
  grid-area: main;
  aspect-ratio: 1/1;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`;

export const SlidersFrame = styled.div<{
  $isEditing?: boolean;
}>(({
  theme,
  $isEditing,
}) => `
  margin-top: ${theme.spacing($isEditing ? 5.75 : 3)};
  margin-bottom: ${theme.spacing($isEditing ? 0 : 3)};
  height: min-content;
  min-height: 350px;
  min-width: 750px;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${theme.palette.background.innerPaper};
  overflow: visible;
  border-radius: 12px;
  padding: 8px;
  gap: ${theme.spacing(5)};
  @media(max-width: 600px) {
    gap: ${theme.spacing(0)};
    min-width: 0;
    width: 100%;
  }
`);

export const LegendFrame = styled.div(() => `
  height: min-content;
  min-height: 40px;
  min-width: 100px;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  border-radius: 12px;
  border: solid #4e4d4f 2px;
  padding-right: 12px;
  gap: ${5};
`);

export const Stats = styled.div(({
  theme,
}) => `
  grid-gap: ${theme.spacing(1)};
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  `);

export interface Size {
  width: number;
  height: number;
}

interface Canvas2DMatrixProps extends WithPathParamProps {
  canvasId: CanvasId;
  canvas: WithId<Matrix2DQuestionCanvas>;
  size?: Size;
  answer?: Scale2DAnswer;
  graphBackgroundColor?: string;
  sessionAnswerVisibility?: AnswerVisibility;
}

const Canvas2DMatrixEditor = ({
  canvas,
  size = DEFAULT_GRAPH2D_SIZE,
  sessionAnswerVisibility,
}: Canvas2DMatrixProps) => {
  const {
    t,
  } = useTranslation();

  const {
    model,
    updateProperty,
  } = useComponentDataContext<WithId<Matrix2DQuestionCanvas>>();

  const [analyzeOptions, setAnalyzeOptions,] = useState<AnalyzeOptions>(
    () => model.component.data.analyzeOptions!
  );

  useEffect(() => model.subscribeToDataChange(({
    newValue,
  }) =>
    setAnalyzeOptions(newValue?.data?.analyzeOptions as any ?? null)), [model,]
  );

  // TODO: Localization
  return !canvas ?
    <LoadingIndicator /> :
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    }}>
      <Typography
        style={editorTypographyStyleLeft}
        component={'span'}
        variant="h2">
        <HTMLInplaceEditor
          text={canvas.name}
          name="name"
          placeHolder={t('Name')}
          textAlign='left'
          containerStyle={{
            padding: '0.5rem',
            width: '100%',
          }}
          allowedStyles={EMPHASIS_STYLES_ALLOWED}
          onChange={updateProperty as any}
          showToolbar
        ></HTMLInplaceEditor>
      </Typography>
      <Typography
        style={editorTypographyStyleLeft}
        component={'span'}
        variant="body1">
        <HTMLInplaceEditor
          text={canvas.desc}
          name="desc"
          containerStyle={{
            padding: '0.5rem',
            width: '100%',
          }}
          placeHolder={t('Description')}
          textAlign='left'
          allowedStyles={ALL_STYLES_ALLOWED}
          onChange={updateProperty as any}
          showToolbar
        ></HTMLInplaceEditor>
      </Typography>
      <div
        style={{
          height: '60dvh',
          width: '100%',
          position: 'relative',
        }}>
        {canvas.interaction === 'sliders' ?
          <Sliders
            analyzeOptions={analyzeOptions}
            canvas={canvas}
            size={size}
            onChange={updateProperty as any}
          /> :
          <EditMatrix2D
            analyzeOptions={analyzeOptions}
            canvas={canvas}
            size={size}
            sessionAnswerVisibility={sessionAnswerVisibility}
            onChange={updateProperty as any}
          />}
        <div style={{
          width: 'min(800px, 90%)',
          marginTop: theme.spacing(2),
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingBottom: theme.spacing(2),
        }}>
          <StatisticLegend
            statistics={canvas.statistics}
            analyzeOptions={analyzeOptions}
          />
        </div>
      </div>

    </div >;
};

export default Canvas2DMatrixEditor;
