import type { BlocCacheUpdateOptions } from '@mindhiveoy/bloc';
import type { DocumentData } from '@mindhiveoy/firebase-schema';

/**
*
* @param {T} currentDoc
* @param {T} newDoc
* @param {Set<T>} ignoreFields
* @return {boolean}
*/
export const hasDocumentChanged = <T extends DocumentData>(
  currentDoc: T | null,
  newDoc: T | null,
  ignoreFields?: Set<keyof T>
): boolean => {
  if (!currentDoc && newDoc || currentDoc && !newDoc) {
    return true;
  }
  if (!currentDoc && !newDoc) {
    return false;
  }

  const keys = new Set<keyof T>([
    ...currentDoc ? Object.keys(currentDoc) : [],
    ...newDoc ? Object.keys(newDoc) : [],
  ]);

  for (const key of Array.from(keys)) {
    if (ignoreFields?.has(key)) {
      continue;
    }
    if (currentDoc?.[key] !== newDoc?.[key]) {
      return true;
    }
  }
  return false;
};

/**
 * Alter document data based on possible options.
 *
 * @param {T} current The current document data.
 * @param {Partial<T>} data New data
 * @param {BlocCacheUpdateOptions} options
 * @return {T | undefined} Altered document data.
 */
export const alterDocumentData = <T extends DocumentData>(
  current: T | null,
  data: Partial<T> | null,
  options?: BlocCacheUpdateOptions
): T | null => {
  if (!data) {
    return data as unknown as T;
  }
  if (!options?.merge) {
    return data as T;
  }
  return {
    ...current,
    ...data,
  } as T;
};
