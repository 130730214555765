/* eslint-disable sonarjs/no-duplicate-string */
import {
  Image, StepComponentContainer, StepContent, StepIcon, StepText, StepTextContainer
} from './OnboardingDialog';
import { useTranslation } from 'next-i18next';
import SwipeIcon from '@mui/icons-material/Swipe';

const Onboarding1 = () => {
  const {
    t,
  } = useTranslation();

  return <StepContent>

    <StepComponentContainer>
      <Image src='/images/onboarding/2dgraph.svg'
        style={{
          height: '80%',
        }} />
    </StepComponentContainer>

    <StepTextContainer>
      <StepIcon>
        <SwipeIcon />
      </StepIcon>
      <StepText>
        {t('answer-the-quantitative-question-first')}.
      </StepText>
    </StepTextContainer>
  </StepContent>;
};
export default Onboarding1;
