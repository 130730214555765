import { MEDIA_BLUR_RADIUS } from '..';
import { useTheme } from '@emotion/react';
import React from 'react';
import styled$ from 'utils/react/styled$';
import type { PropsWithChildren } from 'react';
import type { TypedMediaContainerProps } from '..';

export const PhotoContainer = styled$.div<{
  $fullScreen?: boolean;
  $blur?: boolean;
  $image: string;
}>(({
  $fullScreen,
  $blur,
  $image,
}) => `
  justify-content: center;
  max-height: 100%;
  height: 100%;
  width: ${$fullScreen ? '100vw' : '100%'};  
  max-width: 100%;
  position: ${$fullScreen ? 'fixed' : 'relative'};
  ${$fullScreen ?
    'top: 0; left: 0; right: 0; bottom: 0;' : ''}
  background-size: cover;
  background-image: url("${$image}");
  background-repeat: no-repeat;  
  background-position: center center;
  overflow: hidden;
  ${$blur && `filter: blur(${MEDIA_BLUR_RADIUS});`}
  ${$fullScreen && 'min-height: 100dvh;'}  
`);

export const PhotoMediaContainer = ({
  blur,
  children,
  fullScreen,
  style,
  url,
}: PropsWithChildren<TypedMediaContainerProps>) => {
  const theme = useTheme();

  return (
    <PhotoContainer
      $fullScreen={fullScreen}
      $blur={blur}
      $image={url ?? theme.shape.imagePlaceholder}
      style={style}
    >
      {children}
    </PhotoContainer>
  );
};
