/* eslint-disable sonarjs/no-duplicate-string */
import isEmail from 'validator/lib/isEmail';
import type { BuilderConfig } from 'components/builder/builders/componentBuilder';
import type { OtherInquiry, SalesInquiry, SupportInquiry } from '@shared/schema/src';

export const ContactSalesConfig: BuilderConfig<SalesInquiry> = {

  displayName: 'Message',

  type: 'contact-sales',

  props: {
    companyName: {
      displayName: 'Company name',
      defaultValue: '',
      type: 'text',
      required: true,
      message: '* Name is required',
    },
    VAT: {
      displayName: 'VAT',
      defaultValue: '',
      type: 'text',
      required: true,
      message: '* The VAT number is required',
    },
    organizationType: {
      type: 'checker',
      propertyName: 'organizationType',
      defaultValue: false,
      displayName: 'Educational organization',
    },
    name: {
      displayName: 'Contact person',
      defaultValue: '',
      type: 'text',
      required: true,
      message: '* Contact person is required',
    },
    email: {
      displayName: 'Email',
      defaultValue: '',
      type: 'text',
      required: true,
      message: '* Email is required',
      validate: (value: any) => {
        if (!value) {
          return 'Email address is required';
        }
        return isEmail(value) || 'Invalid email address';
      },
    },
    estimatedUsers: {
      type: 'picker',
      displayName: 'Estimated number of users',
      defaultValue: 'visible',
      /* category: CanvasTab.INTERACTION, */
      options: [
        {
          value: '1-10',
          label: '1-10',
        },
        {
          value: '11-50',
          label: '11-50',
        },
        {
          value: '51-100',
          label: '51-100',
        },
        {
          value: '100+',
          label: '100+',
        },
      ],
    },
    estimatedFacilitators: {
      type: 'picker',
      displayName: 'Estimated number of facilitators',
      defaultValue: 'visible',
      /* category: CanvasTab.INTERACTION, */
      options: [
        {
          value: '1-5',
          label: '1-5',
        },
        {
          value: '6-10',
          label: '6-10',
        },
        {
          value: '10+',
          label: '10+',
        },
      ],
    },
    message: {
      displayName: 'Comments',
      defaultValue: '',
      type: 'html',
    },
  },
};

export const ContactSupportConfig: BuilderConfig<SupportInquiry> = {

  displayName: 'Message',

  type: 'contact-support',

  props: {
    name: {
      displayName: 'Name',
      defaultValue: '',
      type: 'text',
      required: true,
      message: '* The name is required',
    },
    email: {
      displayName: 'Email',
      defaultValue: '',
      type: 'text',
      required: true,
      message: '* Email is required',
      validate: (value: any) => {
        if (!value) {
          return 'Email address is required';
        }
        return isEmail(value) || 'Invalid email address';
      },
    },
    plan: {
      type: 'picker',
      displayName: 'Plan',
      defaultValue: 'visible',
      /* category: CanvasTab.INTERACTION, */
      options: [
        {
          value: 'free',
          label: 'Free',
        },
        {
          value: 'individual',
          label: 'Individual',
        },
        {
          value: 'team',
          label: 'Team',
        },
        {
          value: 'enterprise',
          label: 'Enterprise',
        },
      ],
    },
    role: {
      type: 'picker',
      displayName: 'Role',
      defaultValue: 'visible',
      /* category: CanvasTab.INTERACTION, */
      options: [
        {
          value: 'facilitator',
          label: 'Facillitator',
        },
        {
          value: 'panelist',
          label: 'Panelist',
        },
      ],
    },
    message: {
      displayName: 'How can we help you?',
      defaultValue: '',
      type: 'html',
      required: true,
      message: '* Message is required',
    },
  },
};

export const ContactOtherConfig: BuilderConfig<OtherInquiry> = {

  displayName: 'Message',

  type: 'contact-other',

  props: {
    name: {
      displayName: 'Name',
      defaultValue: '',
      type: 'text',
      required: true,
      message: '* Name is required',
    },
    email: {
      displayName: 'Email',
      defaultValue: '',
      type: 'text',
      required: true,
      message: '* Email is required',
      validate: (value: any) => {
        if (!value) {
          return 'Email address is required';
        }
        return isEmail(value) || 'Invalid email address';
      },
    },
    message: {
      displayName: 'Message',
      defaultValue: '',
      type: 'html',
      required: true,
      message: '* Message is required',
    },
  },
};
