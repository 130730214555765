import cloneDeep from 'lodash/cloneDeep';

/**
 * Make the object deeply immutable.
 *
 * TODO: Move to Mindcloud's foundation library.
 *
 * @param {T} data  Object to make immutable
 * @returns         An immutable object
 */
export const immutable = (data: any): any => {
  if (data === undefined || data === null) {
    return Object.freeze(data);
  }

  const result: any = cloneDeep(data);
  Object.entries(data).forEach(([key, value,]) => {
    result[key] = typeof value === 'object' ? immutable(value) : value;
  });
  return Object.freeze(result);
};
