
interface LogoProps {
  size: number;
  background?: boolean;
}

const Logo = ({
  size,
  background,
}: LogoProps) => {
  return background ?
    <svg width={size}
      height={size}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="512"
        height="512"
        fill="#1F1F1F"/>
      <rect x="73"
        y="94"
        width="379.5"
        height="77"
        rx="2"
        fill="#3DA8FA"/>
      <rect x="73"
        y="209.5"
        width="303.6"
        height="77"
        rx="2"
        fill="#9ED4FD"/>
      <rect x="262.75"
        y="325"
        width="189.75"
        height="77"
        rx="2"
        fill="#D8EEFE"/>
      <ellipse cx="186.85"
        cy="363.5"
        rx="37.95"
        ry="38.5"
        fill="#E1341E"/>
    </svg> :
    <svg width={size}
      height={size}
      viewBox="0 0 480 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="50"
        y="102"
        width="379.5"
        height="77"
        rx="2"
        fill="#3DA8FA"/>
      <rect x="50"
        y="217.5"
        width="303.6"
        height="77"
        rx="2"
        fill="#9ED4FD"/>
      <rect x="239.75"
        y="333"
        width="189.75"
        height="77"
        rx="2"
        fill="#D8EEFE"/>
      <ellipse cx="163.85"
        cy="371.5"
        rx="37.95"
        ry="38.5"
        fill="#E1341E"/>
    </svg>
  ;
};

export default Logo;
