/* eslint-disable @typescript-eslint/no-explicit-any */
import { convertCommentNodes } from './utils/commentNodeUtils';
import { flattenArray } from '@mindhiveoy/react-text-editor/utils';
import { motion } from 'framer-motion';
import { useAuth } from '@mindhiveoy/react-auth';
import { useComments } from 'bloc/comment/CommentsBloc';
import { useTranslation } from 'next-i18next';
import MentionLookupService from './utils/mentionsLookup';
import React, { useCallback, useEffect } from 'react';
import TextEditor, { defaultInitialEditorConfig } from '@mindhiveoy/react-text-editor/TextEditor';
import sanitizeHtml from 'sanitize-html';
import styled$ from 'utils/react/styled$';
import useCommentPath from '../../utils/hooks/useCommentPath';
import useMembership from 'components/auth/useMembership';
import usePlatformEventContext from 'components/platformEvents/usePlatformEventContext';
import type {
  Comment, CommentId, ProjectMember, ProjectMemberInfo, ShortUserRoleId
} from '@shared/schema/src';
import type { SerializedElementNode } from 'lexical';

export const COMMENT_TEXT_EDITOR_NAMESPACE = 'commentTextEditor';

const StyledForm = styled$(motion.div)(({
  $fluid,
}: any) => `
  width: 100%;
  transition: margin-left 0.2s ease-in-out;
  ${$fluid ? '' : 'max-width: 1136px;'}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`);

interface CommentFormProps {
  nicks?: ProjectMemberInfo[];
  isMobile?: boolean;
  /**
   * Optional parent comment id. If not provided, the parent comment will be derived from the url
   */
  parentId?: CommentId;
  onEditorFocus?: (focused: boolean) => void;
  onNewComment?: (commentId: string) => void;
}

/**
 * Comment entry form
 */
const CommentForm = ({
  nicks = [],
  parentId,
  onNewComment,
}: CommentFormProps): JSX.Element => {
  const {
    t,
  } = useTranslation();

  const commentsBloc = useComments();

  const {
    memberId,
  } = useAuth();

  const {
    commentId,
  } = useCommentPath();

  useEffect(() => {
    const mentionsLookupService = MentionLookupService.getInstance();
    mentionsLookupService.setMentionsListFromProjectMemberInfo(nicks);
  }, [nicks,]);

  const {
    sendPlatformEvent,
  } = usePlatformEventContext();

  const membership = useMembership<ProjectMember<ShortUserRoleId>>();

  /**
   * Create the new comment
   */
  const handleAddComment = useCallback(async (htmlText: string, json: SerializedElementNode) => {
    try {
      const flatJsonContent = flattenArray([json,]);
      const analyzedComment = convertCommentNodes(flatJsonContent);

      const text = sanitizeHtml(htmlText, {
        allowedTags: ['b', 'i', 'u', 'a', 'p', 'br',
          'ul', 'li', 'h1', 'h2', 'h3',],
        allowedAttributes: {
          a: ['href',],
        },
      });

      const newComment: Partial<Comment> = {
        text,
        parentId: parentId ?? commentId,
        links: analyzedComment.links,
        mentions: analyzedComment.mentions,
        hashTags: analyzedComment.hashTags,
        avatar: membership?.avatar,
        nick: membership?.nick,
        role: membership?.role as 'f' | 'p' | 'b' | undefined,
      };
      if (!memberId) {
        throw new Error('No member id');
      }
      // analyzeComment(rawContent, newComment, nicks);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = await commentsBloc.createComment(newComment as any, memberId);
      onNewComment && onNewComment(response._id);
      sendPlatformEvent({
        type: 'comment-created',
        commentId: response._id, // TODO: Resolve why the commend id is undefined here
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }, [commentId, commentsBloc, memberId, membership?.avatar, membership?.nick,
    membership?.role, onNewComment, parentId, sendPlatformEvent,]);

  return (
    <StyledForm $fluid>
      <TextEditor
        initialConfig={{
          ...defaultInitialEditorConfig,
          namespace: COMMENT_TEXT_EDITOR_NAMESPACE,
        }}
        onLookupMentions={MentionLookupService.getInstance().searchMentions}
        onSubmitHtml={handleAddComment as any}
        placeholderText={t('write-a-comment')}
        showSubmitButton
      />
    </StyledForm>
  );
};

export default CommentForm;
