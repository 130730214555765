import { AnimatePresence, motion } from 'framer-motion';
import { CommentBloc } from 'bloc/comment/CommentBloc';
import { LabelChip } from './LabelChip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import NewLabelEditor from './LabelEditor';
import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import theme from '../../../theme';
import usePathParams from 'utils/hooks/usePathParams';
import type { Comment } from '../../../@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export const chipVariants: any = {
  initial: {
    opener: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  highlight: {
    scale: [1, 1.1, 1,], // Slight pulse animation
    color: [
      theme.palette.secondary, '#ffffff',
      theme.palette.secondary,
    ], // Highlight color transition
    transition: {
      duration: 0.6,
    }, // Duration of the animation
  },
};

const Container = styled(motion.div)(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(0.5)};
  margin-top: ${theme.spacing(1)};
  align-items: center;
`);

interface LabelRowProps {
  comment: WithId<Comment>;
  layoutIdPrefix?: string;
  editing?: boolean;
  onLabelEditorClose: () => void;
}

export const LabelRow = ({
  comment,
  editing,
  layoutIdPrefix = '',
  onLabelEditorClose,
}: LabelRowProps) => {
  const [labels, setLabels,] = useState<string[]>(comment.labels ?? []);
  const [highlightedLabel, setHighlightedLabel,] = useState<string | null>(null);

  const params = usePathParams();

  useEffect(() => {
    setLabels(comment.labels ?? []);
  }, [comment.labels,]);

  const handleChipDelete = useCallback(async (label: string) => {
    const newLabels = labels.filter((l) => l !== label);
    setLabels(newLabels);
    const commentBloc = new CommentBloc(
      {
        ...params,
        commentId: comment._id,
      },
      comment._id
    );
    const newComment = await commentBloc.setCommentLabels(newLabels);
    setLabels(newComment?.labels ?? []);
  }, [comment._id, labels, params,]);

  const handleClickAway = useCallback(() => {
    if (!editing) {
      return;
    }
    onLabelEditorClose();
  }, [editing, onLabelEditorClose,]);

  // Update to handle when the label already exists
  const handleAlreadyExists = useCallback((label: string) => {
    setHighlightedLabel(label.trim().toLowerCase());
    setTimeout(() => setHighlightedLabel(null), 500); // Reset after 1 second
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Container layout
        layoutRoot >
        <AnimatePresence initial={false}>
          {comment.labels?.length ?
            comment.labels.map((label) =>
              <motion.div
                key={label}
                variants={chipVariants}
                initial="initial"
                animate={label === highlightedLabel ? 'highlight' : 'animate'}
                exit="exit"
              >
                <LabelChip
                  highlighted={label === highlightedLabel}
                  editing={editing}
                  label={label}
                  layoutIdPrefix={layoutIdPrefix + comment._id}
                  onDelete={editing ? handleChipDelete : undefined}
                />
              </motion.div>
            ) :
            // Placeholder to make height animation fluent
            <br />
          }
          {editing ?
            <NewLabelEditor
              key="--label-editor--"
              comment={comment}
              onClose={onLabelEditorClose}
              onLabelAlreadyExists={handleAlreadyExists}
            /> :
            null}
        </AnimatePresence>
      </Container>
    </ClickAwayListener>
  );
};
