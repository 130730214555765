import { MessageType } from 'ts-react-form-validator';

export const validateTermsOfServiceAndPrivacyPolicy = (value: boolean): true | {
  type: MessageType;
  message: string;
} => {
  if (value === undefined || value === null) {
    return true;
  }

  return !!value || {
    type: MessageType.ERROR,
    message: 'Terms and Service and Privacy Policy needs to be accepted.',
  };
};
