import { countriesEN } from './countriesEN';
import { countriesFI } from './countriesFI';

export interface CountryInfo {
  /**
   * The official country code.
   */
  code: string;
  /**
   * The localized country name.
   */
  label: string;
  /**
   * The dial code for phone numbers.
   */
  phone: string;
}

const countryCodes: {
  [countryCode: string]: readonly CountryInfo[];
} = {
  en: countriesEN,
  fi: countriesFI,
};

const getCountryCodes = (locale = 'en'): readonly CountryInfo[] => {
  const foundLocale = locale in countryCodes ? locale : 'en';
  return countryCodes[foundLocale];
};

export default getCountryCodes;
