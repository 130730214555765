import { ADMIN_BUNDLE } from '@shared/schema/src/https/bundles';
import { createBundleFunctionFactory } from 'utils/firebase/bundles/createBundleFunctionFactory';
import type { ClearAnswerMessage, EraseAnswerDataMessage, RecalculateMetricsMessage, SetAnswerMessage } from '../../@shared/schema/src';

const factory = createBundleFunctionFactory(ADMIN_BUNDLE);

export const setAnswer = factory<SetAnswerMessage>(
  'set-answer'
);

export const clearAnswer = factory<ClearAnswerMessage>(
  'clear-answer'
);

export const eraseAnswerData = factory<EraseAnswerDataMessage>(
  'erase-answer-data'
);

export const recalculateMetrics = factory<RecalculateMetricsMessage>(
  'recalculate-metrics'
);
