import Button from '@mui/material/Button';
import styled from '@emotion/styled';

export const SignFormButton = styled(Button)(({
  theme,
}) => `
  width: 100%;
  padding: ${theme.spacing(2)};
  align-self: flex-end;
  align-items: center;
  text-align: center;
  pointer-events: auto;
`);
