/* eslint-disable require-jsdoc */
import { firebaseApp } from 'schema';
import { getAuth } from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';
import { registerNotificationToken } from 'bloc/notifications/notificationsApi';
import config from 'config';
import type { User as FirebaseUser } from 'firebase/auth';

const app = firebaseApp();

/**
 * Singleton class to manipulate the notification permissions
 */
export class NotificationPermission {
  static instance: NotificationPermission;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() { }

  private _registeringToken = false;

  /**
   * Get instance of the singleton class
   * @return {NotificationPermission} instance (instantiated only once, in further calls returns the same instance)
   */
  public static getInstance(): NotificationPermission {
    if (!NotificationPermission.instance) {
      NotificationPermission.instance = new NotificationPermission();
    }
    return NotificationPermission.instance;
  }

  /**
   * Public method to request user permission to send notifications,
   * and to also update the token to the Firestore document
   * @param {FirebaseUser} user optional parameter, Firebase user
   */
  public requestPermission = async (user?: FirebaseUser) => {
    const auth = getAuth(app);

    if (user && !user.isAnonymous || !auth.currentUser?.isAnonymous) {
      try {
        const messaging = getMessaging(app);
        const token = await getToken(messaging, {
          vapidKey: config.messaging.vapidKey,
        });

        if (!token) {
          console.error('No push token available.');
          return;
        }
        if (!this._registeringToken) {
          this._registeringToken = true;
          await registerNotificationToken({
            token,
          });
        }
      } catch (error) {
        console.error('An error occurred while registering notification token', error); // TODO Sentry
      } finally {
        this._registeringToken = false;
      }
    }
  };
}
