import { useComponentDataContext } from '../../../../utils/hooks/useComponentDataContext';
import Canvas2DMatrix from '../../../canvas/canvasTypes/Matrix2DQuestionCanvas';
import Center from '../../../common/layout/Center';
import React, { useEffect, useState } from 'react';
import canvasFormBuilderConfig from '../../../../components/builder/configs/CanvasFormBuilderConfig';
import type { BuilderComponentPropsBase } from '../BuilderComponentTypes';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { Canvas, CanvasId, Matrix2DQuestionCanvas, SessionId } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export interface CanvasProps {
  sessionId?: SessionId;
  canvasId?: CanvasId;
}
export interface CanvasWidgetProps extends BuilderComponentPropsBase<CanvasProps> {
  type: 'canvas',
}

const CanvasGraphWidget: BuilderFunction<CanvasWidgetProps> = (
) => {
  const [canvas, setCanvas,] = useState<WithId<Canvas>>();

  const {
    model,
  } = useComponentDataContext<Canvas>();

  useEffect(() => model.subscribeToDataChange(
    ({
      newValue,
    }) => setCanvas(newValue?.data)
  ), [model,]);

  return <Center>
    <Canvas2DMatrix
      graphOnly
      canvas={canvas as WithId<Matrix2DQuestionCanvas>}
    />
  </Center>;
};

CanvasGraphWidget.config = canvasFormBuilderConfig;

export default CanvasGraphWidget;
