import { useTheme } from '@emotion/react';
import FormLabel from '@mui/material/FormLabel';
import type { PropsWithChildren } from 'react';

interface PropertyEditorLegendProps {
  label?: string;
}

const PropertyEditorLegend = ({
  label,
  children,
}: PropsWithChildren<PropertyEditorLegendProps>) => {
  const theme = useTheme();

  return <fieldset style={{
    borderStyle: 'solid',
    borderWidth: '0.5px',
    width: '100%',
    margin: 0,
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
  }}>
    <FormLabel
      component="legend"
      style={{
        fontSize: '0.8rem',
        paddingLeft: 3,
        paddingRight: 3,
        paddingBottom: 0,
        margin: 0,
      }}
    >
      {label}
    </FormLabel>
    {children}
  </fieldset>;
};

export default PropertyEditorLegend;
