/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @next/next/no-img-element */
import 'cropperjs/dist/cropper.css';
import { ImageCropper } from './ImageCropper';
import { MediaCopyright } from 'components/forms/MediaFormField/MediaCopyright';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import CropIcon from '@mui/icons-material/Crop';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GeneralDialog from 'components/info/GeneralDialog';
import Stack from '@mui/material/Stack';
import resizeImageDataUrl, { imageToDataUrl } from './resizeImageDataUrl';
import styled$ from 'utils/react/styled$';
import type { CSSProperties } from 'react';
import type { Media } from '@shared/schema/src';

const Image = styled$(motion.img)<{
  $avatar?: boolean;
}>(({
  $avatar,
  theme,
}) => `
  width: 100%;
  height: ${$avatar ? '100%' : 'auto'};
  max-height: ${$avatar ? '100%' : 'min(200px, 100%)'};
  border-radius: ${$avatar ? '50%' : `${theme.shape.borderRadius}px`};
  background-color: ${$avatar ? '#ccc' : 'transparent'};  
  margin-bottom: ${theme.spacing(2)};
`);

interface ImageCropperProps {
  imageUrl?: string;
  authorInfo?: Partial<Media>;
  style?: CSSProperties;
  canCrop?: boolean;
  /**
   * Spawn a crop dialog on uploading a new image.
   */
  cropOnUpload?: boolean;
  // avatar?: boolean;
  disabled?: boolean;
  expectedDimensions?: {
    width: number;
    height: number;
  };
  autoResize?: {
    width: number;
    height: number;
  };
  presentationMode?: 'default' | 'avatar';
  onNewImage?: (imageUrl: string) => void;
}

const ImageSelector = ({
  authorInfo,
  // disabled,
  imageUrl,
  cropOnUpload,
  canCrop = true,
  style,
  autoResize,
  presentationMode = 'default',
  onNewImage,
}: ImageCropperProps) => {
  const theme = useTheme();

  const {
    t,
  } = useTranslation();

  const fileRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const uploadImageRef = useRef<string | null>(null);

  const [src, setSrc,] = useState(imageUrl);
  const [originalSrc, setOriginalSrc,] = useState(imageUrl);
  const isCropped = useRef(false);

  const [openCrop, setOpenCrop,] = useState(false);
  const [, setValidImage,] = useState(false);

  const cropData = useRef<Cropper.Data | null>(null);

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach(async (file: any) => {
      // eslint-disable-next-line compat/compat
      const reader = new FileReader(); // TODO: hide import feature for browsers not supporting it.

      reader.onabort = () => console.log('Reading of a file aborted.');
      reader.onerror = () => console.log('Failed to read a file failed.');
      reader.onload = async () => {
        const file = URL.createObjectURL(acceptedFiles[0]);

        if (autoResize) {
          const resized = await resizeImageDataUrl(file, autoResize);
          onNewImage?.(resized);
          // return;
        }
        // Convert file to a data URL.

        const dataUrl = await imageToDataUrl(file);

        if (cropOnUpload) {
          uploadImageRef.current = dataUrl;
          setOpenCrop(true);
        } else {
          setOriginalSrc(file);
          setSrc(file);
          onNewImage?.(dataUrl);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, [autoResize, cropOnUpload, onNewImage,]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg',],
      'image/png': ['.png',],
    },
  });

  const s: CSSProperties = useMemo(() => ({
    ...style,
    // aspectRatio: '1/1',
    objectFit: 'contain',
    position: 'relative',
    ...isDragActive && {
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      backgroundColor: 'rgba(0, 0, 190, 0.1)',
      borderRadius: 16,
    },
    transition: 'all 0.3s ease',
  }), [isDragActive, style, theme,]);

  const handleCropOpen = useCallback((event: React.MouseEvent) => {
    setOpenCrop(true);
    event.stopPropagation();
  }, []);

  const handleCropClose = useCallback(() => {
    setOpenCrop(false);
    uploadImageRef.current = null;
  }, []);

  const handleCrop = useCallback((imageSrc: string, data: any) => {
    setOpenCrop(false);
    setSrc(imageSrc);
    onNewImage?.(imageSrc);
    cropData.current = data;
    isCropped.current = true;
    uploadImageRef.current = null;
  }, [onNewImage,]);

  const handleImageLoad = useCallback((event: any) => {
    const {
      naturalWidth,
      naturalHeight,
    } = event.target;

    setValidImage(
      presentationMode === 'avatar' ?
        naturalWidth === naturalHeight : true);
  }, [presentationMode,]);

  useEffect(() => {
    const fetchRemote = async () => {
      // Load remote image as blob
      try {
        if (!imageUrl) {
          return;
        }
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = URL.createObjectURL(blob);
        setOriginalSrc(file);
        setSrc(file);
      } catch (error) {
        console.error(error);
      }
    };
    if (imageUrl?.toLocaleLowerCase()?.startsWith('http')) {
      fetchRemote();
      return;
    }
    setOriginalSrc(imageUrl);
    setSrc(imageUrl);
  }, [imageUrl,]);

  return <>
    <div style={{
      maxHeight: 'min(100%, 300px)',
      height: '100%',
      width: '100%',
      position: 'relative',
      ...s,
    }}>
      <div {...getRootProps()}
        style={{
          height: '100%',
        }}>
        <Image
          ref={imageRef}
          $avatar={presentationMode === 'avatar'}
          style={{
            height: '100%',
            minHeight: 140,
            maxHeight: 'min(100%, 300px)',
          }}
          src={src}
          alt=""
          onLoad={handleImageLoad}
        />
        <input ref={fileRef}
          {...getInputProps()} />
      </div>

      <div style={{
        position: 'absolute',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <FileUploadIcon style={{
          fontSize: 80,
          padding: 8,
          opacity: 0.4,
          backgroundColor: 'rgba(255,255,255,0.2)',
          borderRadius: theme.shape.borderRadius,
        }} />
      </div>
      <div style={{
        position: 'absolute',
        right: presentationMode === 'avatar' ? 0 : 16,
        top: presentationMode === 'avatar' ? 0 : 16,
      }}>
        {canCrop && !!src?.trim()?.length ? <CropIcon
          aria-label={t('Crop')}
          onClick={handleCropOpen}
          style={{
            color: theme.palette.primary.main,
          }}

        /> : null}
      </div>

    </div>
    {authorInfo ? <Stack
      justifyContent='right' >
      <MediaCopyright media={authorInfo} />
    </Stack> : null}
    {/* <br /> */}
    <GeneralDialog
      open={openCrop}
      title="Crop Image"
      maxWidth='md'
    >
      <ImageCropper
        src={uploadImageRef.current ?? originalSrc}
        cropData={cropData.current!}
        onCrop={handleCrop}
        onCancel={handleCropClose}
      />
    </GeneralDialog>
  </>;
};

export default ImageSelector;
