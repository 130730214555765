/* eslint-disable @next/next/no-img-element */
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import getCountryCodes from 'utils/localization/countries';
import type { AutocompleteValue } from '@mui/material';
import type { CountryInfo } from 'utils/localization/countries';

export interface CountryPickerProps {
  name?: string;
  label?: string;
  /**
   * Country code
   */
  value?: string;
  showFlags?: boolean;
  showDialCode?: boolean;
  showCountryCode?: boolean;
  defaultCountry?: string;
  onChange?: (value: CountryInfo | null) => void;
}

// For some reason i18next typings are fucking this up, need to cast
const DamnYouTS = TextField as any;

export const CountryPicker = ({
  defaultCountry,
  name,
  label,
  showDialCode,
  showFlags,
  showCountryCode,
  value,
  onChange,
}: CountryPickerProps) => {
  const {
    i18n,
    t,
  } = useTranslation();

  const {
    countries,
    defaultValue,
  } = useMemo(() => {
    const currentLanguage = (i18n as any).language;

    const countries = getCountryCodes(currentLanguage);
    const defaultValue = defaultCountry ?
      countries.find((country) => country.code === defaultCountry) : undefined;
    return {
      countries,
      defaultValue,
    };
  }, [defaultCountry, i18n,]);

  const [_value, setValue,] = useState(() => {
    if (value) {
      return countries.find((country) => country.code === value);
    }
    return defaultValue;
  });

  useEffect(() => {
    setValue(countries.find((country) => country.code === value));
  }, [countries, value,]);

  const handleChange = useCallback((event: React.SyntheticEvent, value: AutocompleteValue<any, any, any, any>) => {
    onChange?.(value);
    setValue(value);
    // event.stopPropagation();
    // event.preventDefault();
  }, [onChange,]);

  return <Autocomplete
    id={name}
    sx={{
      width: 'min(100%, 300px)',
    }}
    options={countries ?? []}
    autoHighlight
    defaultValue={defaultValue}
    value={_value}
    onChange={handleChange}
    getOptionLabel={(option) => option.label}
    isOptionEqualToValue={(option, value) => option.code === value?.code}
    renderOption={(props, option) => <Box
      component="li"
      sx={{
        '& > img': {
          mr: 2, flexShrink: 0,
        },
      }}
      {...props}>
      {showFlags ? <img
        loading="lazy"
        width="20"
        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
        alt={option.label} /> : null}
      {option.label} {showCountryCode ? `(${option.code})` : ''} {showDialCode ? `+${option.phone}` : ''}
    </Box>}
    renderInput={(params) => {
      return <DamnYouTS
        {...params}
        placeholder={label ? t('Country') : undefined}
        label={label ? t('Country') : undefined}
      />;
    }}
  />;
};
