import { isDevelopmentMode } from '@mindhiveoy/foundation';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable valid-jsdoc */
/* eslint-disable require-jsdoc */

export type ListenerFunction = (...args: any) => void;

// TODO Move the mindcloud foundation
/**
 * Helper class for implementing the listener pattern logic for classes.
 */
export class ListenerHelper<F extends ListenerFunction> {
  private $listeners: F[] = [];

  /**
   * Add a new listener to the class
   * @param {F} listener The handle to the listener to be added.
   */
  public add = (listener: F) => {
    if (!this.$listeners.includes(listener)) {
      this.$listeners.push(listener);
    }
  };

  /**
   * Remove a listener
   * @param {F} listener The handle to listener to be removed.
   */
  public remove = (listener: F) => {
    const index = this.$listeners.findIndex((l) => l === listener);
    if (index >= 0) {
      this.$listeners.splice(index, 1);
    }
  };

  /**
   * Fire an event for using callback function.
   * @param args
   */
  public fireWithCallback = (callback: (listener: F) => any[]) => {
    const listeners = this.$listeners;

    listeners.forEach((listener) => {
      try {
        const args = callback(listener);

        listener(...args);
      } catch (error) {
        // TODO Sentry
        if (isDevelopmentMode()) {
          alert(error);
        }
        console.error(error);
      }
    });
  };

  /**
   * Fire an event for listeners.
   * @param args
   */
  public fire = ((...args: any[]) => {
    let listeners = this.$listeners;

    if (this.filter) {
      listeners = this
        .$listeners
        .filter((value, index, array) => {
          return this.filter?.(args, value, index, array);
        });
    }

    listeners.forEach((listener) => {
      try {
        listener(...args);
      } catch (error) {
        // TODO Sentry
        if (isDevelopmentMode()) {
          alert(error);
        }
        console.error(error);
      }
    });
  }) as unknown as F;

  /**
   * Set filter to listener firing system.
   *
   * @param {any} filter Filter function
   */
  public setFilter = (filter: (
    args: any[],
    value: F,
    index: number,
    array: F[],
  ) => boolean) => {
    this.filter = filter;
  };
  /**
   * Indicates if the the adapter has no listeners registered.
   *
   * @returns {true} True when adapter has no listeners registered.
   */
  public isEmpty = () => {
    return this.$listeners.length === 0;
  };
  /**
   * Get the number of currently registered listeners.
   * @returns {number} The number of listeners.
   */
  public get length() {
    return this.$listeners.length;
  }
  /**
   * Erase all all listeners registered.
   */
  public reset = () => {
    this.$listeners = [];
  };

  private filter?: (
    args: any[],
    value: F,
    index: number,
    array: F[],
  ) => boolean = undefined;
}
