/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */
import * as React from 'react';
import { isDevelopmentMode } from '@mindhiveoy/foundation';
import axios from 'axios';
import includes from 'lodash/includes';
import styled from '@emotion/styled';

const StackLine = styled.div`
  white-space: nowrap;
  color: white;
`;

const CodeLink = styled.a`
  text-decoration: none;
`;

export interface ErrorBoundaryProps {
  /**
   * Human readable name for error boundary. User this to help debugging where the exception happened.
   */
  name?: string;

  /**
   * Error handler
   *
   * @param {Error}             error         Error object
   * @param {() => JSX.Element} renderDefault Default render function
   */
  onError?: (error: Error, renderDefault: () => JSX.Element) => JSX.Element;
}

interface StackLine {
  inProject?: boolean;
  text: string;
  filePath: string;
}
interface State {
  error?: Error;
  info?: React.ErrorInfo;
  image?: string;
  componentStack?: StackLine[];
}
/**
 * Error boundary to restrict crash on ui to restricted area.
 */
export default class ErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  State
> {
  /**
   * Highlight parts of code that should meet the developer eye.
   * @param {string} line   Call stack row path to be rendered.
   * @return {JSX.Element[]} Rendered path.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderProjectLine(line: string): any {
    const result: JSX.Element[] = [];
    const text = line.trim();

    let key = 0;

    const renderChunk = (text: any, color: any) => {
      result.push(
        <span
          style={{
            color,
            textDecoration: 'none',
          }}
          key={(++key).toString()}>
          {text}
        </span>
      );
    };

    const writeChunk = (didRender: boolean, marker: any, color: any) => {
      if (didRender) {
        return true;
      }
      if (!text) {
        return false;
      }
      const index = text.indexOf(marker);
      if (index === -1) {
        // renderChunk(text, color);
        // text = '';
        return false;
      }
      // index += marker.length;
      // const chunk = text.substring(0, index);
      // text = text.substring(index);
      renderChunk(text, color);
      return true;
    };
    let didRender = writeChunk(false, 'webpack-internal:///./components', 'orange');
    didRender = writeChunk(didRender, 'webpack-internal:///./pages', 'blue');
    didRender = writeChunk(didRender, 'webpack-internal:///./utils', 'yellow');
    writeChunk(didRender, 'webpack-internal:///./node_modules', 'darkgray');
    if (!didRender) {
      renderChunk(text, 'white');
    }
    return result;
  }
  public state: State = {};

  /**
   * Prepare stack lines to emphasize files in active project.
   * @param {React.ErrorInfo} info
   * @return {StackLine[]}
   */
  private generateStackLines = (info: React.ErrorInfo): StackLine[] => {
    if (!info.componentStack) {
      return [];
    }

    const extractFilePath = (line: string): string => {
      const prefix = 'webpack-internal:///.';
      const index = line.indexOf(prefix);
      if (index < 0) {
        return '';
      }
      const filePath = line.substring(index + prefix.length);
      const endIndex = filePath.indexOf(')');
      return filePath.substring(0, endIndex);
    };

    const lines = info.componentStack.split('\n');

    return lines.map((line) => {
      const inProject = includes(line, '.ts');
      return {
        inProject,
        filePath: process.env.PUBLIC_SOURCE_PATH + extractFilePath(line),
        text: inProject ? this.renderProjectLine(line) : line,
      };
    });
  };

  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({
      error,
      info,
      componentStack: this.generateStackLines(info),
    });
    this.fetchImage();
  }

  private loading = false;

  /**
   * More entertaining error messages for poor coder.
   */
  private fetchImage = async () => {
    if (this.loading) {
      return;
    }

    this.loading = true;

    const limit = 200;
    const offset = Math.floor(Math.random() * 200);
    const url = `https://api.giphy.com/v1/gifs/search?api_key=18d6CLzSjuhR627XyyhzHAeaGpyD3Jwc&q=no+no+no&limit=${limit}&offset=${offset}&rating=g&lang=en`;
    const result = await axios.get(url);

    const images = result.data.data;

    const index = Math.floor(Math.random() * (images.length - 1));
    const image = `https://giphy.com/embed/${images[index].id}`;// images[index].images.downsized.url;

    this.setState({
      image,
    });
  };
  /**
   *
   * @return {JSX.Element} Default error component
   */
  private defaultRender = () => {
    const {
      componentStack,
      error,
      image,
    } = this.state;

    return (
      <div style={{
        padding: 16,
        backgroundColor: '#250b0499',
        zIndex: 100000,
      }}>
        {isDevelopmentMode() &&
          <iframe
            style={{
              float: 'right',
              marginLeft: 16,
              marginBottom: 16,
              width: 400,
            }}
            src={image ?? 'https://giphy.com/embed/d2ZcfODrNWlA5Gg0'}
            width="480"
            height="360"
            frameBorder="0"
            className="giphy-embed"
            allowFullScreen>
          </iframe>
        }
        <h1>
          Unexpected error condition
        </h1>
        <pre>{error?.message}</pre>
        <h2>Stack trace</h2>
        <p>Use links to navigate to vscode.</p>
        <div>{componentStack &&
          componentStack.map((line, index) => {
            if (line.inProject) {
              return <div key={index}
                style={{
                  color: 'green',
                }}>
                {/* <CodeLink href={`vscode://file/${line.filePath}`}> */}
                <CodeLink href={`vscode://file/${line.filePath}`}>
                  <StackLine
                  >{line.text}
                  </StackLine>
                </CodeLink>
              </div>;
            }
            return <div key={index}
              style={{
                color: 'darkgray',
              }}>
              {line.text}
            </div>;
          })}</div>
      </div>
    );
  };

  public render(): React.ReactNode {
    const {
      children,
      onError,
    } = this.props;
    const {
      error,
    } = this.state;

    if (error) {
      // You can render any custom fallback UI
      if (onError) {
        return onError(error, this.defaultRender);
      }
      return this.defaultRender();
    }
    return children;
  }
}
