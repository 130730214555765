import { useCanvas } from 'bloc/canvas/CanvasBloc';
import { useComponentDataContext } from 'utils/hooks/useComponentDataContext';
import { useSessionDraft } from 'components/session/SessionEditor/providers/DraftSessionDataContextProvider/useSessionDraft';
import Matrix2DCanvasGraph from './graphs/Matrix2DCanvasGraph';
import React, { useEffect, useState } from 'react';
import SliderCanvasGraph from './graphs/SliderCanvasGraph';
import usePathParams from 'utils/hooks/usePathParams';
import type { Canvas, Session, WithPathParamProps } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export type CanvasEditorProps = WithPathParamProps;

// const StyledButton = styled(Button)(({
//   theme,
// }) => `
//   width: 100%;
//   height: 100%;
//   align-self: flex-end;
//   flex-direction: column;
//   align-items: center;
//   text-align: center;
//   background-color: rgba(255,255,255,0.1);
//   &:hover {
//     background-color: rgba(255,255,255,0.1);
//     > div {
//     transition: all .3s ease-in-out;
//       background-color: ${theme.palette.background.lightInnerPaper}80;
//     }
//   }
// `);

// const ButtonContent = styled.div(({
//   theme,
// }) => `
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: fit-content;
//   height: 10%;
//   background-color: transparent;
//   border-radius: ${theme.shape.borderRadius}px;
//   gap: ${theme.spacing(1.5)};
//   padding: ${theme.spacing(1, 3)};
//   transition: all .3s ease-in-out;
// `);

interface CanvasGraphContainerProps {
  session?: WithId<Session>;
}

const CanvasGraphContainer = ({
  session,
}: CanvasGraphContainerProps) => {
  const {
    model,
  } = useComponentDataContext();

  const {
    activeCanvasId,
  } = useSessionDraft();

  const pathParams = usePathParams();

  const canvasBloc = useCanvas({
    ...pathParams,
    ...activeCanvasId && {
      canvasId: activeCanvasId,
    },
  });

  const [canvas, setCanvas,] = useState<WithId<Canvas>>();

  useEffect(() => canvasBloc.subscribe(setCanvas), [canvasBloc, model,]);

  if (!canvas) {
    return null;
  }
  switch (canvas.type) {
    case '1d':
      return <SliderCanvasGraph
        canvas={canvas}
      />;
    case '2d':
      return <Matrix2DCanvasGraph
        session={session}
        canvas={canvas}
      />;

    case 'category':
    case 'text':
    case 'layout':
    default:
      return <div />;
  }
};

export default CanvasGraphContainer;
