/* eslint-disable @typescript-eslint/no-explicit-any */
import FormControl from '@mui/material/FormControl';

import { BpRadio } from './BpRadio';
import { Column } from '../../../cards/CardStrip';
import { renderOption } from '../utils/labelUtils';
import Center from '../../../common/layout/Center';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import PhotoIcon from '@mui/icons-material/Photo';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useCallback, useState } from 'react';
import UpdateIcon from '@mui/icons-material/Update';
import styled from '@emotion/styled';
import type { DataMode } from '@shared/schema/src';
import type { DataModePropertyEditor } from '../PropertyEditorTypes';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

const Frame = styled.div`
  left: 10px;
  flex: 1;
  box-sizing: border-box;
  width: 131px;
  height: 81px;
  overflow: visible;
  border-radius: 12px;
  border: 1px solid #5bc2fa;
  padding-bottom: 6px;
  margin: 10px;
  display:flex;
  justify-content: center;
  flex-direction: column;
`;

const AlignRight = styled.div`
  padding-left: 9px;
`;

const ICONS = [
  <PhotoIcon key="snapshot"
    fontSize='large'/>,
  <UpdateIcon key="live"
    fontSize='large'/>,
];

const options = [{
  label: 'Live',
  value: 'live',
}, {
  label: 'Snapshot',
  value: 'snapshot',
},];

const DataModePropertyEditorComponent:
  PropertyEditorFunction<DataMode, DataModePropertyEditor> = ({
    data,
    propertyName,
    propertyConfig: {
      displayName,
      defaultValue,
    },
    onSave,
  }) => {
    const [value, setValue,] = useState(data ?? defaultValue);

    const handleChange = useCallback((event: any, value: any) => {
      if (value !== data && onSave) {
        onSave(propertyName, value);
      }
      setValue(value);
      event?.stopPropagation();
    }, [data, onSave, propertyName,]);

    const label = displayName ?? propertyName;

    return <FormControl fullWidth>
      <FormLabel
        component="legend"
      >
        { label }
      </FormLabel>
      <RadioGroup
        row
        aria-label="Data mode"
        name={propertyName}
        value={value}
        onChange={handleChange}
      >{
          options.map((option, index) =>
            <Column
              key={option.value}
            >
              <Frame style={{
                // TODO: Default theme based coloring
                background: value === option.value ? '#50516f' : '',
              }}>
                <AlignRight>
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<BpRadio />}
                    label={renderOption(option)} />
                </AlignRight>
                <Center>
                  {ICONS[index] ?? null}
                </Center>
              </Frame>
            </Column>
          )
        }
      </RadioGroup>
    </FormControl>;
  };

DataModePropertyEditorComponent.config = {
  type: 'data-mode',
  displayName: 'Data mode',
  defaultValue: 'real-time',
  options: [
    {
      label: 'Real time',
      value: 'real-time',
    },
    {
      label: 'Snapshot',
      value: 'snapshot',
    },
  ],
};

export default DataModePropertyEditorComponent;
