import { NewMedia } from './NewMedia';
import { PanelistScrollProvider } from '../providers/PanelistScrollProvider';
import { ParallaxContextProvider } from '../providers/ParallaxContext';
import { useMemo, useRef, useState } from 'react';
import LoadingIndicator from 'components/common/LoadingIndicator';
import ViewSlider, { useViewSliderContext } from '@mindhiveoy/react/ViewSlider';
import type { CSSProperties, PropsWithChildren } from 'react';
import type {
  Canvas, CanvasId, ProjectId, Session, SessionId, SpaceId
} from '@shared/schema/src';
import type { ViewSlideDirection, ViewSliderModel } from '@mindhiveoy/react/ViewSlider';
import type { WithId } from '@mindhiveoy/schema';

// /**
//  * Experimenting with distilling swipe offset and velocity into a single variable, so the
//  * less distance a user has swiped, the more velocity they need to register as a swipe.
//  * Should accommodate longer swipes and short flicks without having binary checks on
//  * just distance thresholds and velocity > 0.
//  */
// const swipeConfidenceThreshold = 100;

// const swipePower = (offset: number, velocity: number) => {
//   return Math.abs(offset) * velocity;
// };

interface ScreenSliderProps {
  canvas: WithId<Canvas>;
  canvasId: CanvasId;
  session: WithId<Session>;
  spaceId: SpaceId;
  projectId: ProjectId;
  sessionId: SessionId;
  slideDirection: React.MutableRefObject<ViewSlideDirection>;
  commentingAvailable: boolean;
  showNavigation: boolean;
  useBackgroundOpacity?: boolean;
}

export interface ScreenSliderModel extends ViewSliderModel {
  canvas: WithId<Canvas>;
  canvasId: CanvasId;
  session: WithId<Session>;
  spaceId: SpaceId;
  projectId: ProjectId;
  sessionId: SessionId;
  commentingAvailable: boolean;
  showNavigation: boolean;
  updateCanvas: (canvas: WithId<Canvas>) => void;
}

const screenSliderStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
};

/**
 * Generates a ScreenSlider component with the given props.
 *
 * @param {PropsWithChildren<ScreenSliderProps>} canvas - The canvas object.
 * @param {PropsWithChildren<ScreenSliderProps>} session - The session object.
 * @param {PropsWithChildren<ScreenSliderProps>} spaceId - The space ID.
 * @param {PropsWithChildren<ScreenSliderProps>} projectId - The project ID.
 * @param {PropsWithChildren<ScreenSliderProps>} sessionId - The session ID.
 * @param {PropsWithChildren<ScreenSliderProps>} children - The children components.
 * @param {PropsWithChildren<ScreenSliderProps>} slideDirection - The direction of the slide.
 * @param {PropsWithChildren<ScreenSliderProps>} commentingAvailable - Indicates if commenting is available.
 * @param {PropsWithChildren<ScreenSliderProps>} showNavigation - Indicates if navigation should be shown.
 * @return {JSX.Element} The ScreenSlider component.
 */
const ScreenSlider = ({
  canvas,
  canvasId,
  session,
  spaceId,
  projectId,
  sessionId,
  children,
  slideDirection,
  commentingAvailable,
  showNavigation,
  useBackgroundOpacity,
}: PropsWithChildren<ScreenSliderProps>) => {
  const [_canvas, updateCanvas,] = useState<WithId<Canvas>>(canvas ?? {
    _id: canvasId,
  });

  const model = useMemo(() => ({
    _id: canvasId,
    canvas: _canvas,
    session,
    spaceId,
    projectId,
    sessionId,
    canvasId,
    commentingAvailable,
    showNavigation,
    useBackgroundOpacity,
    updateCanvas,
  }), [canvasId, _canvas, session, spaceId, projectId, sessionId, commentingAvailable, showNavigation, useBackgroundOpacity,]);

  const style = useMemo(() => ({
    ...screenSliderStyle,
  }), []);

  const _spareSlideDirection = useRef<ViewSlideDirection>('left-to-right');

  if (!canvas) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <ViewSlider
      model={model}
      direction={slideDirection ?? _spareSlideDirection}
      style={style}>
      <PanelistScrollProvider>
        <ParallaxContextProvider>
          <NewMedia
            useBackgroundOpacity={useBackgroundOpacity}
            media={canvas.media}
            noScroll={canvas.type === 'layout'}
          />
          {children}
        </ParallaxContextProvider>
      </PanelistScrollProvider>
    </ViewSlider>
  );
};

export const useScreenSliderContext = () => {
  const data = useViewSliderContext<ScreenSliderModel>();
  return data?.model ?? {};
};

/**
 * Custom hook to check if the title and description are not just empty tags.
 */
export const useBackgroundOpacity = (title: string | undefined, description: string | undefined) => {
  const stripHtml = (html: string) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const isEmptyContent = (content: string | undefined) => {
    if (!content) return true;
    const strippedContent = stripHtml(content);
    return strippedContent.trim().length === 0;
  };

  return {
    isTitleEmpty: isEmptyContent(title),
    isDescriptionEmpty: isEmptyContent(description),
  };
};

export default ScreenSlider;
