/* eslint-disable @typescript-eslint/no-explicit-any */
import { createUseBlocHook } from '../../@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import { resolveCommentId } from './utils/resolveCommentId';
import { setCommentLabels, updateComment, voteComment } from './commentApi';
import { validateSessionProps } from '../../@shared/schema/src';
import BlocDocument from '../../@mindhiveoy/bloc/BlocDocument';
import alterVoteForComment from '../../@shared/schema/src/utils/comment/alterVoteForComment';
import prepareCommentLabels from '@shared/schema/src/utils/comment/prepareCommentLabels';
import type { BlocErrorFunction } from '@mindhiveoy/bloc';
import type {
  Comment,
  CommentId,
  CommentParams,
  VoteNature
} from '../../@shared/schema/src';
import type { MemberId, WithId } from '@mindhiveoy/schema';

type ExtendedCommentParams = CommentParams & {
  disabled?: boolean,
};

/**
 *
 */
export class CommentBloc extends BlocDocument<WithId<Comment>, ExtendedCommentParams> {
  /**
   * @param {CommentParams} params Comment params
   * @param {ErrorFunction} onError Error listener
   */
  constructor(
    params: ExtendedCommentParams,
    private commentId?: CommentId,
    onError?: BlocErrorFunction
  ) {
    if (!params.disabled) {
      validateSessionProps(params, 'canvasId');
    }
    const {
      spaceId, projectId, sessionId, canvasId,
    } = params;
    const id = commentId ?
      Array.isArray(commentId) ? commentId.at(-1) : commentId :
      undefined;

    const documentPath =
      `spaces/${spaceId
      }/projects/${projectId
      }/sessions/${sessionId
      }/canvases/${canvasId
      }/comments/${id}`;

    super({
      documentPath,
      params,
      invalidState: !id,
      onError,
      disabled: params.disabled,
    });
  }

  /**
   * Update a single comment
   * @param {Partial<Comment>}          comment
   * @return {Promise<WithId<Comment>>}
   */
  public updateComment = async (
    comment: Partial<Comment>
  ) => {
    delete (comment as any)['children'];

    return this._set(
      async () => {
        try {
          const response = await updateComment({
            ...this.params,
            comment: comment as Comment,
            commentId: this.id as CommentId,
          }); // TODO: Update to latest Message type

          return response ? response :
            {
              ...comment,
              _id: this.docId,
            } as WithId<Comment>;
        } catch (e) {
          console.error(e);
          throw e;
        }
      },
      (error: Error) => {
        console.error(error);
        alert(error);
        // TODO: Implementation
      },
      () => {
        return {
          ...comment,
          _id: this.docId,
        } as WithId<Comment>;
      }
      ,
      {
        merge: true,
      }
    );
  };

  /**
   * Toggle member's vote for the comment
   * @param {MemberId}    memberId
   * @param {VoteNature}  vote
   * @return {Promise<WithId<Comment>>}
   */
  public toggleVote = async (
    memberId: MemberId,
    vote: VoteNature
  ) => {
    if (!this.commentId) {
      return;
    }
    return this._set(
      () => voteComment({
        ...this.params,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        commentId: this.commentId!,
        vote,
      }),
      this.onError,
      ((data: any) => {
        if (!data) {
          // Can not return an immediate value as the document is not in th cache yet.
          return;
        }
        const likes = alterVoteForComment(data, memberId, vote);
        return {
          ...data,
          ...likes,
          _id: this.id,
        };
      }) as any, // TODO: Fix type
      {
        merge: true,
      }
    );
  };

  /**
   * Set the labeling for the comment
   * @param {string[]}    newLabels
   * @return {Promise<WithId<Comment>>}
   */
  public setCommentLabels = async (
    newLabels: string[]
  ) => {
    if (!this.commentId) {
      return;
    }
    const labels = prepareCommentLabels(newLabels);

    return this._set(
      async () => {
        const result = await setCommentLabels({
          ...this.params,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          commentId: this.commentId!,
          labels,
        }); // TODO: Update to latest Message type
        return {
          ...result as unknown as Comment,
          _id: this.id,
        };
      },
      this.onError,
      (data) => {
        if (!data) {
          // Can not return an immediate value as the document is not in th cache yet.
          return;
        }
        return {
          ...data,
          labels,
          _id: this.id,
        };
      },
      {
        merge: true,
      }
    );
  };
}

export const useComment = createUseBlocHook<
  WithId<Comment>,
  ExtendedCommentParams,
  CommentBloc
>(
  ({
    params,
    onError,
  }) => {
    const commentId = resolveCommentId(params.commentId);

    return new CommentBloc(
      params,
      commentId,
      onError
    );
  }
);
