import { MessageType } from 'ts-react-form-validator';
import styled from '@emotion/styled';
import type { MessageField } from 'ts-react-form-validator';

interface FormMessageProps {
  /**
   * Form message to show
   */
  formMessage?: true | MessageField;
}

const StyledMessage = styled.div<{ color: string }>(({
  color,
}) => `
  color: ${color};
`);

/**
 * Component showing a general message for entire form. If no message is
 * delivered, component will return null.
 *
 * @return {FormMessage} If message was found, component will be returned otherwise null.
 */
const FormMessage = ({
  formMessage,
}: FormMessageProps) => {
  if (!formMessage || formMessage === true) {
    return null;
  }
  const {
    type, message,
  } = formMessage;

  return <StyledMessage
    color={
      type === MessageType.ERROR ? 'red' : 'black'
    }>
    {message}
  </StyledMessage>;
};

export default FormMessage;
