import { isDevelopmentMode } from '@mindhiveoy/foundation';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable valid-jsdoc */
/* eslint-disable require-jsdoc */

export type StatefulListenerFunction = <T = any>(state: T,...args: any) => void;

interface ListenerItem<F extends StatefulListenerFunction, T = any> {
  state: T;
  listener: F;
}
/**
 * Helper class for implementing the listener pattern logic for classes.
 */
export class StatefulListener<F extends StatefulListenerFunction, S> {
  private $listeners = new Map<F, ListenerItem<F, S>>();

  /**
   * Add a new listener to the class
   * @param {F} listener The handle to the listener to be added.
   */
  public add = (listener: F, state: S) => {
    if (!this.$listeners.has(listener)) {
      this.$listeners.set(listener, {
        state,
        listener,
      });
    }
  };

  /**
   * Add a new listener to the class
   * @param {F} listener The handle to the listener to be added.
   */
  public replace = (listener: F, state: S) => {
    this.$listeners.set(listener, {
      state,
      listener,
    });
  };

  /**
   * Remove a listener
   * @param {F} listener The handle to listener to be removed.
   */
  public remove = (listener: F) => {
    this.$listeners.delete(listener);
  };

  /**
   * Fire an event for using callback function.
   * @param args
   */
  public fire = (...args: any) => {
    this.$listeners.forEach((item) => {
      try {
        item.listener(item.state,...args);
      } catch (error) {
        if (isDevelopmentMode()) {
          alert(error);
        }
        console.error(error);
      }
    });
  };

  /**
   * Fire a single function from listeners with its state.
   * @param {F} fun
   * @param args
   */
  public fireFunction = (fun: F, ...args: any) => {
    const item = this.$listeners.get(fun);
    if (!item) {
      throw new Error('Function is not registered.');
    }
    try {
      item.listener(item.state,...args);
    } catch (error) {
      if (isDevelopmentMode()) {
        alert(error);
      }
      console.error(error);
    }
  };

  /**
   * Indicates if the the adapter has no listeners registered.
   *
   * @returns {true} True when adapter has no listeners registered.
   */
  public isEmpty = () => {
    return this.$listeners.size === 0;
  };
  /**
   * Get the number of currently registered listeners.
   * @returns {number} The number of listeners.
   */
  public get length() {
    return this.$listeners.size;
  }
  /**
   * Erase all all listeners registered.
   */
  public reset = () => {
    this.$listeners.clear();
  };
}
