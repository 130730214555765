/* eslint-disable @typescript-eslint/no-explicit-any */

import { usePanelistViewContext } from 'components/canvas/panelistView/providers/PanelistScreenProvider';
import CanvasEditorContainer from 'components/canvas/CanvasEditorContainer';
import canvasFormBuilderConfig from '../../configs/CanvasFormBuilderConfig';
import type { BuilderComponentPropsBase } from '../PageWidgetRenderer';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { CanvasProps } from '../renderers/CanvasWidget';
import type { SessionConfig } from '@shared/schema/src';

export interface CanvasWidgetProps extends BuilderComponentPropsBase<CanvasProps> {
  type: 'canvas';
}

const CanvasWidgetEditor: BuilderFunction<CanvasWidgetProps> = () => {
  const {
    session,
  } = usePanelistViewContext();

  return <CanvasEditorContainer
    sessionAnswerVisibility={(session?.config as SessionConfig)?.answerVisibility}
  />;
};

CanvasWidgetEditor.config = canvasFormBuilderConfig;

export default CanvasWidgetEditor;
