/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@mui/material';
import { DialogBase } from 'components/billingAndInvoices/components/sharedComponents';
import { motion } from 'framer-motion';
import { useCallback } from 'react';
import DialogHeader from 'components/billingAndInvoices/components/dialogs/component/DialogHeader';
import styled from '@emotion/styled';
import type { Breakpoint } from '@mui/material';
import type { CSSProperties, PropsWithChildren } from 'react';

export const DialogContentContainer = styled(motion.div)(({
  theme,
}) => `
  position: relative;
  padding: ${theme.spacing(3)};
  background-color: ${theme.palette.background.default};
`);

const Description = styled.div(({
  theme,
}) => `
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 1.9;
  color: ${theme.palette.text.lightgrey};
`);

const FooterRow = styled.div(({
  theme,
}) => `
  display: flex;
  justify-content: flex-end;
  gap: 3%;
  margin-top: ${theme.spacing(2)};
  padding: ${theme.spacing(0, 1)};
`);

// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
// const CancelButton = styled(Button)`
//   color: silver;
//   border: 1px solid silver;
// `;

interface ConfirmationDialogProps {
  open: boolean;
  title?: string;
  description?: string | JSX.Element,
  acceptText?: string;
  cancelText?: string;
  maxWidth?: Breakpoint;
  size?: true | 'full' | 'medium' | 'auto';
  acceptColor?: 'primary' | 'secondary' | 'error' | 'info' | 'inherit' | 'success' | 'warning';
  acceptButtonStyle?: CSSProperties;
  cancelColor?: 'primary' | 'secondary' | 'error' | 'info' | 'inherit' | 'success' | 'warning';
  acceptDisabled?: boolean;

  layoutId?: string;
  padding?: number;
  onAccept?: () => void;
  onCancel?: () => void;
}

const GeneralDialog = ({
  open,
  title,
  description,
  acceptText,
  cancelText,
  maxWidth,
  acceptColor,
  acceptButtonStyle,
  children,
  acceptDisabled = false,
  padding,
  layoutId,
  size,
  onAccept,
  onCancel,
}: PropsWithChildren<ConfirmationDialogProps>) => {
  const handleCancel = useCallback((event: React.MouseEvent) => {
    onCancel?.();
    event.stopPropagation();
  }, [onCancel,]);

  const handleAccept = useCallback((event: React.MouseEvent) => {
    onAccept?.();
    event.stopPropagation();
  }, [onAccept,]);

  return (
    <DialogBase
      open={open}
      fullWidth
      $padding={padding}
      $size={size}
      maxWidth={size ? '100dvw' as any : maxWidth ?? 'xs'}
    >
      {children ??
        <DialogContentContainer
          layoutId={layoutId}
        >
          {title ? <DialogHeader title={title} /> : null}
          <Description>
            {description}
          </Description>
          {(onAccept || onCancel) && <FooterRow>
            {onCancel ? <Button
              onClick={handleCancel}
              variant='text'
            >
              {cancelText}
            </Button> : null}
            {onAccept ? <Button
              disabled={acceptDisabled}
              color={acceptColor}
              style={acceptButtonStyle}
              onClick={handleAccept}
              variant='contained'
            >
              {acceptText}
            </Button> : null}
          </FooterRow>}
        </DialogContentContainer>}
    </DialogBase>
  );
};

export default GeneralDialog;
