/* eslint-disable require-jsdoc */
import { LayoutGroup } from 'framer-motion';
import ChartPlot from './ChartPlot';
import React, { useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import theme from 'theme';
import type { Axis, Matrix2DQuestionCanvas, SliderQuestionCanvas } from '@shared/schema/src';
import type { CSSProperties } from 'react';
import type { Size } from 'components/canvas/editors/CategoryCanvasEditor';

interface Props {
  answer?: number;
  disabled?: boolean;
  canvas?: SliderQuestionCanvas | Matrix2DQuestionCanvas;
  knobColor?: string;
  graphOnly?: boolean;
  canvasMode?: 'continuous' | 'discrete';
  graphBackgroundColor?: string;
  size: Size;
  axis: Axis;
  axisName?: 'x' | 'y';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  statisticsColorsArrayX?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  statisticsColorsArrayY?: any;
}

export const StyledSvg = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
`;

const Chart1D = ({
  graphBackgroundColor = theme.palette.background.graph,
  axis = {
    scaleType: 'numeric',
    min: -1,
    max: 1,
    scaleLength: 3,
    mainLabel: 'x',
    minLabel: '-1',
    maxLabel: '1',
    range: [{
      label: '-1',
      value: -1,
    }, {
      label: '0',
      value: 0,
    }, {
      label: '1',
      value: 1,
    },],
  },
  canvas,
  canvasMode,
  // answer,
  axisName,
  statisticsColorsArrayX,
  statisticsColorsArrayY,
}: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  // const [componentSize, setComponentSize,] = useState<Size>({
  //   ...size,
  // });

  const {
    // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
    mode = 'continuous',
    min = -1, // TODO: document the default limit values
    max = 1,
  } = canvas ?? {};

  const minX = canvasMode === 'continuous' ? typeof min === 'string' ? parseFloat(min) : axis.scaleType === 'percentage' ? 0 : min :
    axis.range[0].value ?? 1;
  const maxX = canvasMode === 'continuous' ? typeof max === 'string' ? parseFloat(max) : axis.scaleType === 'percentage' ? 100 : max :
    axis.range[axis.range.length - 1].value ?? 1;

  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  // const checkAnswerVisibility = useCallback((
  // ): boolean => {
  //   switch (canvas?.answerVisibility) {
  //     case AnswerVisibility.OWN:
  //       return false;
  //     case AnswerVisibility.AFTER_ANSWERING:
  //       return !!answer;
  //     default:
  //       return true;
  //   }
  // }, [answer, canvas?.answerVisibility,]);

  const areaStyle: CSSProperties = useMemo(() => {
    return {
      width: '100%',
      height: '100%',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      padding: theme.spacing(1),
    };
  }, []);

  return <div ref={ref}
    style={areaStyle}>
    <LayoutGroup>
      <StyledSvg
        width="100%"
        height="100%"
        viewBox="0 0 1000 1000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="0"
          y="0"
          width="1000"
          height="1000"
          fill={graphBackgroundColor} />
        <ChartPlot
          statisticsColorsArrayX={statisticsColorsArrayX}
          statisticsColorsArrayY={statisticsColorsArrayY}
          axis={axis}
          min={minX}
          max={maxX}
          canvasMode={canvasMode}
          axisName={axisName}
          frequencies={canvas?.frequencies}
          statistics={canvas?.statistics} />
      </StyledSvg>
    </LayoutGroup>
  </div>;
};

export default Chart1D;
