import type { Canvas, CanvasId, DraftCanvasDocument, Session } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export const deserializeCanvas = (canvas: any, _id: CanvasId): WithId<Canvas> | undefined => {
  if (!canvas) {
    return undefined;
  }
  const result: any = {
    ...canvas,
    _id,
  };
  if (typeof canvas.elements === 'string') {
    result.elements = JSON.parse(canvas.elements);
  }
  return result as WithId<Canvas>;
};

/**
 * Deserializes a canvas draft from the backend.
 *
 * @param {any}       draft   - The draft object.
 * @param {CanvasId}  _id     - The canvas ID.
 * @returns                   The deserialized canvas draft.
 */
export const deserializeCanvasDraft = (draft: any, _id: CanvasId): DraftCanvasDocument | undefined => {
  if (!draft) {
    return undefined;
  }
  const canvas = {
    ...draft.content,
    _id,
  };

  if (typeof canvas.elements === 'string') {
    canvas.elements = JSON.parse(canvas.elements);
  }
  return {
    ...draft,
    content: canvas,
  } as DraftCanvasDocument;
};

/**
 * Deserializes a session from the backend.
 * @param {string}          sessionId - The session ID.
 * @param {WithId<Session>} session   - The session object.
 * @returns                             The deserialized session.
 */
export const deserializeSession = (
  sessionId: string,
  session: any): WithId<Session> => {
  return {
    ...session,
    canvasStrip: typeof session.canvasStrip === 'string' ?
      JSON.parse(session.canvasStrip) : session.canvasStrip ?? [],
    _id: sessionId,
  };
};
