
export enum RegisterResponseStatus {
  OK = 'ok',
  ERROR = 'failed',
}

export interface AuthError {
  type: string;
  message: string;
}

// Needed to make web build to work. Can be removed when module have other things besides types
export const DUMMY = 'daa';
