/* eslint-disable @typescript-eslint/no-explicit-any */
import type { BuilderConfig, PropertyConfigs } from '../../builders/componentBuilder';
import type { PropertyEditor, PropertyEditorBase } from '../PropertyEditorTypes';

type PropertyEditorEntry = [string, PropertyEditorBase<any>];

type SortFunction = (a: PropertyEditorEntry, b: PropertyEditorEntry) => number;

export const sortPropertyEditors: SortFunction = ([, a,], [, b,]) => {
  return (a.order ?? 0) - (b.order ?? 0);
};

export const extractPropsFromConfig = <T extends Record<string, any>>(
  config: BuilderConfig<T>,
  data: T
): PropertyConfigs<T> | null => {
  if (!config) {
    return null;
  }
  let props = config.props;
  if (typeof props === 'function') {
    props = props(data, data);
  }
  return props as PropertyConfigs<T>;
};

export const resolveComponentProps = <T extends Record<string, any>,>(
  config: BuilderConfig<T>,
  data: T,
  category?: string
): Partial<PropertyConfigs<T>> => {
  const props = extractPropsFromConfig(config, data);

  if (category && props) {
    const result: Partial<PropertyConfigs<T>> = {};

    Object.entries(props).forEach(([key, value,]) => {
      if ((value?.category ?? 'general') === category) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (result as any)[key] = value;
      }
    });
    return result;
  }
  return props as Partial<PropertyConfigs<T>>;
};

export const mapPropsToEntries = (props: Partial<PropertyConfigs<Record<string, any>>>)
  : [string, PropertyEditor][] => {
  if (!props) {
    return [];
  }
  const result = Object.entries(props);
  result.sort(sortPropertyEditors);
  return result;
};
