/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from '@emotion/styled';
import type { CSSProperties, PropsWithChildren } from 'react';

const Both = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Horizontal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Vertical = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export interface CenterProps {
  style?: CSSProperties;
  /**
   * Define the centering direction. Default value is both.
   */
  direction?: 'horizontal' | 'vertical' | 'both';
}

/**
 * Center child elements into to the middle of parent relative area.
 *
 * @param {CenterProps} props
 * @return {JSX.Element}
 */
const Center = ({
  children,
  style,
  direction = 'both',
}: PropsWithChildren<CenterProps>
): JSX.Element => {
  switch (direction) {
    case 'both':
      return <Both
        style={style}>
        {children}
      </Both>;

    case 'horizontal':
      return <Horizontal
        style={style}>
        {children}
      </Horizontal>;

    case 'vertical':
      return <Vertical
        style={style}>
        {children}
      </Vertical>;
  }
};

export default Center;
