import { InfoItem, MessageArea, StyleFeedItem, TopRow } from '.';
import { StyledAvatar } from '../../comments/CommentItem';
import type { NotificationDoc } from '@shared/schema/src';

import { StyledLink } from 'components/layout/Breadcrumbs';
import { extractDateToMilliseconds, renderMoment } from '../../../utils/timeUtils';
import { limitText } from 'utils/common';
import { resolveEditorMode } from '../../../utils/urls';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import React from 'react';
import ReadMore from 'components/common/ReadMore';
import Typography from '@mui/material/Typography';
import elementCache from 'utils/ElementCache';
import type { WithId } from '@mindhiveoy/schema';

interface ItemProps {
  item: WithId<NotificationDoc>;
  role: string;
}
export const Item = ({
  item, role,
}: ItemProps) => {
  const {
    query,
  } = useRouter();

  // TODO: :use the same item with Project and Notification feed
  const mode = resolveEditorMode(query);
  // const sessionName = item?.sessionId ? getLocalCacheItem('sessions', `${item.sessionId}`, item.sessionId as string) : '';
  // const canvasName = item?.canvasId ? getLocalCacheItem('canvases', `${item.canvasId}`, item.canvasId as string).slice(0, 25) : '';

  const [sessionName, setSessionName,] = useState<string>('');
  const [canvasName, setCanvasName,] = useState<string>('');

  useEffect(
    () => {
      const fetch = async () => {
        // limitText(getLocalCacheItem('sessions', `${item.sessionId}`, item.sessionId as string), 20, '', '...')

        if (!item.spaceId || !item.projectId || !item.sessionId) {
          return 'not known';
        }

        const data = await elementCache.get({
          keyPath: {
            spaceId: item.spaceId,
            projectId: item.projectId,
            sessionId: item.sessionId,
          },
          defaultValue: {
            name: '...loading',
          },
        });
        setSessionName(limitText(data?.name, 20, '', '...'));
      };
      fetch();
    },
    [item.projectId, item.sessionId, item.spaceId,]);

  useEffect(() => {
    const fetch = async () => {
      // limitText(getLocalCacheItem('canvases', item.canvasId!, item.canvasId as string), 20, '', '...')

      if (!item.spaceId || !item.projectId || !item.sessionId || !item.canvasId) {
        return 'not known';
      }

      const data = await elementCache.get({
        keyPath: {
          spaceId: item.spaceId,
          projectId: item.projectId,
          sessionId: item.sessionId,
          canvasId: item.canvasId,
        },
        defaultValue: {
          name: '...loading',
        },
      });
      setCanvasName(limitText(data?.name, 20, '', '...'));
    };
    fetch();
  }, [item.canvasId, item.projectId, item.sessionId, item.spaceId,]);

  const link = useMemo(() => {
    let result = `/${item.path}`;
    // When facilitator is on dashboard and clicks an item from drawer,
    // we will assume that he or she wants to facilitate.
    if (mode && role === 'f') {
      result += `?facilitate`;
    }
    return result;
  }, [item.path, mode, role,]);

  return <StyleFeedItem >
    <StyledLink href={link}>
      <div style={{
        position: 'relative',
      }}>
        <TopRow>
          {item.avatar && <StyledAvatar src={item.avatar}
            alt={item.mentioner} />}
          <div>
            {item.mentioner && <InfoItem>@{item.mentioner}</InfoItem>}
            <InfoItem>
              {renderMoment(extractDateToMilliseconds(item.created))}
            </InfoItem>
          </div>
        </TopRow>
        <div>
          <Typography component={'span'}
            style={{
              whiteSpace: 'break-spaces',
            }}
            variant="h6">{item.title}</Typography>
          <MessageArea>
            <ReadMore text={item.message}
              length={200} />

          </MessageArea>
        </div>
      </div>
    </StyledLink>
    {/* <Divider /> */}
    <div>
      <StyledLink href={`/${item.spaceId}/${item.projectId}/${item.sessionId}/`}>
        {sessionName.length < 20 ? sessionName : sessionName.slice(0, 20).concat('...')}
      </StyledLink>
      <br />
      <StyledLink href={`/${item.spaceId}/${item.projectId}/${item.sessionId}/${item.canvasId}/`}>
        {canvasName.length < 20 ? canvasName : canvasName.slice(0, 20).concat('...')}
      </StyledLink>
    </div>
  </StyleFeedItem>;
};
