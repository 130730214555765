
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { PropertyEditorFunction } from '../../../builders/propertyEditorBuilder';

import { AnswerVisibility } from '@shared/schema/src';
import { FieldSet } from 'components/forms/FieldSet';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Legend } from 'components/forms/Legend';
import { useTranslation } from 'react-i18next';
import PropertyEditorControl from '../../PropertyEditorControl';
import type { CanvasConfig, CanvasConfigBase } from '@shared/schema/src';
import type { CanvasConfigPropertyEditor } from '../../PropertyEditorTypes';
import type { SelectChangeEvent } from '@mui/material/Select/SelectInput';

const keys: (keyof CanvasConfigBase)[] = [
  'commenting',
  'voting',
  'answerVisibility',
  // 'showStats',
];

const values: {
  [key in keyof CanvasConfigBase]: (CanvasConfigBase[key])[];
} = {
  commenting: [true, false, 'inherited',],
  voting: [true, false, 'inherited',],
  answerVisibility: [AnswerVisibility.HIDDEN, AnswerVisibility.AFTER_ANSWERING, AnswerVisibility.VISIBLE, 'inherited',],
  showStats: [true, false, 'inherited',],
  showAnswer: [true, false, 'inherited',],
};

const defaultValues: {
  [key in keyof CanvasConfigBase]: CanvasConfigBase[key];
} = {
  commenting: 'inherited',
  voting: 'inherited',
  answerVisibility: 'inherited',
  showStats: 'inherited',
  showAnswer: 'inherited',
};

const CanvasConfigEditor: PropertyEditorFunction<CanvasConfig, CanvasConfigPropertyEditor> = ({
  data,
  propertyName,
  propertyConfig: {
    displayName,
    defaultValue,
  },
  onSave,
}) => {
  const [value, setValue,] = useState<CanvasConfig>(data ?? defaultValue ?? {});

  const {
    t,
  } = useTranslation();

  useEffect(() => {
    if (data && data !== value) {
      setValue(data);
    }
  }, [data, value,]);

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const key = event.target.name as keyof CanvasConfigBase;

      let optionValue: boolean | string = event.target.value;
      if (optionValue === 'true') {
        optionValue = true;
      } else if (optionValue === 'false') {
        optionValue = false;
      }

      const newValue = {
        ...value,
        [key]: optionValue,
      };
      setValue(newValue);

      if (onSave) {
        onSave(propertyName, newValue);
      }
      event.stopPropagation();
    }, [onSave, propertyName, value,]);

  const {
    labels,
    optionLabels,
  } = useMemo(() => {
    const labels: {
      [key in keyof CanvasConfigBase]: string;
    } = {
      commenting: t('Commenting'),
      voting: t('Voting'),
      answerVisibility: t('Answer visibility'),
      showStats: t('Show statistics'),
      showAnswer: t('Show answer'),
    };

    const optionLabels: {
      [key: string]: string;
    } = {
      'inherited': t('Inherited from session'),
      'true': t('on'),
      'false': t('Off'),
      'hidden': t('Hidden'),
      'after-answering': t('After answering'),
      'visible': t('Visible'),
    };

    return {
      labels,
      optionLabels,
    };
  }, [t,]);

  return <>

    <PropertyEditorControl>
      <FieldSet>
        <Legend>{displayName}</Legend>

        {keys.map((key) => <FormControl key={key}
          fullWidth>
          <InputLabel id={`${propertyName}-${key}-label`}>{labels[key]}</InputLabel>
          <Select
            id={`${propertyName}-${key}`}
            label={labels[key]}
            name={key}
            value={value[key] !== undefined ? value[key].toString() : defaultValues[key].toString()}
            onChange={handleChange}
          >
            {
              values[key].map((val) => <MenuItem
                key={val.toString()}
                value={val.toString()}>{(optionLabels as any)[val as any]}</MenuItem>)
            }
          </Select>
        </FormControl>)}

      </FieldSet>
    </PropertyEditorControl>
  </>;
};

CanvasConfigEditor.config = {
  type: 'canvas-config',
};

export default CanvasConfigEditor;
