/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/material';
import { ComponentDataContextProvider } from 'components/common/ComponentDataContextProvider';
import { ComponentModel } from 'components/builder/models/ComponentModel';
import { ComponentSelectionContextProvider } from 'components/common/ComponentSelectionContextProvider';
import { FormContent } from './FormContent';
import { HTMLEditorProvider } from 'components/builder/propertyEditors/primary/HTMLEditor/HTMLEditorContext';
import { preprocessFormData } from './preprocessFormData';
import {
  useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState
} from 'react';
import { validatePropertyForm } from '../validatePropertyForm';
import Button from '@mui/material/Button';
import ContextButton from 'components/builder/ContextButton';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import styled from '@emotion/styled';
import type { BuilderConfig } from 'components/builder/builders/componentBuilder';

const BUTTON_BAR_HEIGHT = 64;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  position: relative;
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
/**
 * A sticky row at the Bottom of the Container holding the save and cancel buttons.
 */
const Sticky = styled.div(({
  theme,
}) => `
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: ${theme.palette.background.paper};
`);

const BottomPadding = styled.div`
  height: ${BUTTON_BAR_HEIGHT}px;
  width: 100%;
`;

export type ComponentFormDataChangedFunction<T extends Record<string, any>> = (data: T, isValid: boolean, deleted: string[]) => void;

export interface SimpleComponentFormProps<T extends Record<string, any> = any> {
  bgcolor?: string;
  config: BuilderConfig<T>;

  disabled?: boolean;

  initialData?: T;
  saveButtonText?: string;
  cancelText?: string;
  fullScreenSaving?: boolean;
  /**
   * Whether the form should be full width.
   */
  fluid?: boolean;

  scroll?: boolean;

  fixedToolbar?: boolean;
  /**
   * Triggered when the user clicks the save button. If the event is not defined, no save
   * button will be shown.
   *
   * @param {T} data The form data to be saved.
   * @param {string[]>} deleted An array map deleted properties when compared to initial data.
   * @returns {Promise<void>} A promise that resolves when the save operation is complete.
   */
  onSave?: (data: T, deleted: string[]) => Promise<void>;
  /**
   * Triggered when the user clicks the cancel button.
   */
  onCancel?: () => void;
  /**
   * On data change event handler. This is triggered when the user changes the form data.
   * Use this event handler to use the form with more control over its logic instead
   * of save <button className=""></button>
   * @param {T}             data    The form data.
   * @param {boolean}       isValid Whether the form data is valid after running validators defined in configuration.
   * @param {Array<string>} deleted An array map deleted properties when compared to initial data.
   */
  onDataChange?: ComponentFormDataChangedFunction<T>;
  /**
   * Triggered when the user presses the enter key in a form input.
   */
  onPressEnter?: () => void;
}

/**
 * SimpleComponentForm is a wrapper around ComponentForm that can be used
 * to define the user input form using property editors for object fields.
 *
 * @param {SimpleComponentFormProps} Props - The props for the SimpleComponentForm.
 * @return {JSX.Element} - The JSX element representing the SimpleComponentForm.
 */
const SimpleComponentForm = <T extends Record<string, any> = any>({
  bgcolor,
  cancelText,
  config,
  disabled,
  fluid,
  fixedToolbar,
  fullScreenSaving,
  initialData = {} as T,
  saveButtonText,
  scroll,
  onCancel,
  onDataChange,
  onPressEnter,
  onSave,
}: SimpleComponentFormProps<T>) => {
  const [saving, setSaving,] = useState(false);

  const [savedData, setSavedData,] = useState<T>(cloneDeep(initialData));

  const containerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLDivElement>(null);

  const [model, setModel,] = useState<ComponentModel<any>>(
    new ComponentModel({
      type: config.type,
      data: cloneDeep(savedData),
    })
  );

  const handleSave = useCallback(async () => {
    if (!onSave || !model) {
      return;
    }
    try {
      setSaving(true);

      const newData = preprocessFormData(model.component.data);
      const deleted = model.extractDeletedProperties(savedData);

      await onSave?.(newData, deleted);

      setSavedData(newData);
    } finally {
      setSaving(false);
    }
  }, [model, onSave, savedData,]);

  const handleClose = useCallback(() => {
    onCancel?.();
  }, [onCancel,]);

  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current) {
      return;
    }
    // Update the savedData and model when the component is initialized
    isInitialized.current = true;
    if (initialData) {
      const data = preprocessFormData(initialData);
      let hasChanged = false;

      setSavedData((oldData) => {
        hasChanged = !isEqual(oldData, data);
        if (hasChanged) {
          setModel(new ComponentModel({
            type: config.type,
            data,
          }));
        }
        if (hasChanged) {
          return cloneDeep(data);
        }
        return oldData;
      });
      return;
    }
    const emptyData = {} as T;

    setSavedData(emptyData);
    setModel(new ComponentModel({
      type: config.type,
      data: emptyData,
    }));
  }, [config, initialData,]);

  useEffect(() => {
    if (!onDataChange || !model) {
      return;
    }

    return model.subscribeToDataChange(({
      oldValue,
      newValue,
    }) => {
      if (isEqual(oldValue, newValue)) {
        return;
      }
      const deleted = model.extractDeletedProperties(savedData);

      const data = newValue?.data;
      const {
        isValid,
      } = validatePropertyForm(config, data);

      onDataChange(data, isValid, deleted);
    });
  }, [config, model, onDataChange, savedData,]);

  useLayoutEffect(() => {
    if (!config || !model) {
      return;
    }
    const propertyNames = config.props ? Object.keys(config.props) : [];

    const focus = propertyNames.find((name) => {
      const prop = (config as any).props![name];
      return prop?.autoFocus;
    });

    if (focus) {
      // Find a HTML element with the given id and focus it.
      const element = document.getElementById(focus);
      if (element) {
        element.focus();
      }
    }
  }, [config, model,]);

  const htmlContext = useMemo(() => ({
    fixedToolbar,
  }), [fixedToolbar,]);

  // Check if the form area is not fitting into the parent container's available space. In this case, the
  // form will be scrollable and the bottom padding will be added to the container to make the save and cancel
  // buttons sticky and the last form field visible on the top of the sticky area.
  const isFormAreaScrollable = useCallback(() => {
    if (containerRef.current && formRef.current) {
      return containerRef.current.clientHeight - BUTTON_BAR_HEIGHT < formRef.current.clientHeight;
    }
    return false;
  }, [containerRef, formRef,]);

  const [scrollable, setScrollable,] = useState(isFormAreaScrollable());

  /**
   * Listen for Container size changes and update the scroll state.
   */
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      setScrollable(isFormAreaScrollable());
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [containerRef, isFormAreaScrollable,]);

  if (!model) {
    return null;
  }

  return (
    <ComponentDataContextProvider model={model}>
      <ComponentSelectionContextProvider>
        <Container ref={containerRef}>
          <FormContainer ref={formRef}>
            <HTMLEditorProvider value={htmlContext}>
              <FormContent
                config={config}
                disabled={disabled}
                scroll={scroll}
                fluid={fluid}
                onPressEnter={onPressEnter}
              />
            </HTMLEditorProvider>
            {scrollable ? <BottomPadding /> : null}
          </FormContainer>
          <Sticky>
            {onSave || cancelText ?
              <Box display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                width="100%"
                padding={1}
                bgcolor={bgcolor || 'background.paper'}
              >
                {
                  cancelText ?
                    <Button data-testid="cancel-button"
                      onClick={handleClose}
                      variant="text">
                      {cancelText}
                    </Button> : null
                }

                {onSave ?
                  <ContextButton
                    saving={saving}
                    style="inline"
                    label={saveButtonText}
                    config={config}
                    initialData={initialData}
                    onSave={handleSave}
                    fullScreenSaving={fullScreenSaving}
                  /> : null
                }
              </Box> : null}
          </Sticky>
        </Container>
      </ComponentSelectionContextProvider>
    </ComponentDataContextProvider>
  );
};

export default SimpleComponentForm;
