import { ADMIN_BUNDLE } from '@shared/schema/src/https/bundles';
import { createBundleFunctionFactory } from 'utils/firebase/bundles/createBundleFunctionFactory';
import type { ContextId } from '../../../schema/index';
import type {
  DeleteMemberMessage,
  InviteMembersMessage,
  UpdateMemberLoginInfoMessage,
  UpdateMemberMessage,
  UpdateMemberPinStatusMessage,
  UpdateMemberRolesMessage,
  UpdateMemberStatusesMessage,
  UploadMemberPhotoMessage
} from '@shared/schema/src/https/admin/member';
import type { Member } from '@shared/schema/src';
import type { ShortUserRoleId } from '@shared/schema/src/schema';
import type { WithId } from '@mindhiveoy/schema';

const factory = createBundleFunctionFactory(ADMIN_BUNDLE);

export const updateMember = factory<UpdateMemberMessage<ShortUserRoleId, WithId<Member>>>(
  'update-member'
);

export const updateMemberRole = factory<UpdateMemberRolesMessage<ContextId>>(
  'update-member-roles'
);

export const updateMemberStatus = factory<UpdateMemberStatusesMessage<ContextId>>(
  'update-member-statuses'
);

export const updateMemberPinStatus = factory<UpdateMemberPinStatusMessage<ContextId>>(
  'update-member-pin-status'
);

export const inviteMembers = factory<InviteMembersMessage<ShortUserRoleId, ContextId>>(
  'invite-members'
);

export const deleteMember = factory<DeleteMemberMessage>(
  'delete-member'
);

export const uploadPhoto = factory<UploadMemberPhotoMessage>(
  'upload-photo'
);

export const updateMemberLoginInfo = factory<UpdateMemberLoginInfoMessage>(
  'update-member-login-info'
);
