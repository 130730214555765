import { SingleComponentSelectionModel } from 'components/builder/models/selectionModels/SingleComponentSelectionModel';
import { useComponentDataContext } from 'utils/hooks/useComponentDataContext';
import { useEffect, useMemo, useState } from 'react';
import { validatePropertyForm } from '../validatePropertyForm';
import CategorizedInspector from 'components/builder/inspector/CategorizedInspector';
import type { BuilderConfig } from 'components/builder/builders/componentBuilder';
import type { CSSProperties } from 'react';
import type { FormErrors } from '../validatePropertyForm';

interface FormContentProps {
  config: BuilderConfig<any>;
  disabled?: boolean;
  fluid?: boolean;
  scroll?: boolean;
  onFormValidityChanged?: (valid: boolean) => void;
  onPressEnter?: () => void;
}

export const FormContent = ({
  config,
  disabled,
  fluid,
  scroll,
  onFormValidityChanged,
  onPressEnter,
}: FormContentProps) => {
  const {
    model,
  } = useComponentDataContext();

  const selectionModel = useMemo(() => {
    return new SingleComponentSelectionModel(model, config);
  }, [config, model,]);

  const [errors, setErrors,] = useState<FormErrors>();

  useEffect(() => {
    return model.subscribeToDataChange((event) => {
      const {
        errors,
        isValid,
      } = validatePropertyForm(config, event.newValue?.data);

      setErrors(errors);

      if (onFormValidityChanged) {
        onFormValidityChanged(isValid);
      }
    }
    );
  }, [config, model, onFormValidityChanged,]);

  const formStyle: CSSProperties = useMemo(() => ({
    overflowY: scroll ? 'auto' : 'hidden',
    width: '100%',
  }), [scroll,]);

  return <div style={formStyle}>
    <CategorizedInspector
      disabled={disabled}
      errors={errors}
      fluid={fluid}
      selection={selectionModel}
      onPressEnter={onPressEnter}
    />
  </div>;
};
