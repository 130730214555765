import { APP_DRAWER_WIDTH } from './AppDrawer';
import { Avatar, Button, useMediaQuery } from '@mui/material';
import { NotificationPermission } from 'utils/firebase/notifications';
import { Show, useAuth } from '@mindhiveoy/react-auth';
import { SigningButtons } from 'components/auth/SignIn/SigningButtons';
import { expandShortRoleId } from '@mindhiveoy/auth';
import { isAnonymousUser } from 'components/auth/utils/isAnonymousUser';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import AccountCircle from '@mui/icons-material/AccountCircle';
import BackgroundFadeTop from 'components/styling/BackgroundFadeTop';
import Breadcrumbs from './Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import LocaleSelector from './LocaleSelector';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useCallback, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import theme from 'theme';
import useScreenContext from './useScreenContext';
import type { CSSProperties } from 'react';

const RoleText = styled(Typography)(({
  theme,
}) => `
  margin: 0;
  color: ${theme.palette.secondary.main};
`);

const NameContainer = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  @media (max-width: 599) {
    max-width: 140px;
  }
`;

const UserContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex:1;
  gap: 8px;
  @media (max-width: 599px) {
    flex-direction: column;
    gap: 0;
  }  
`;

const StyledAppBar = styled.div(({
  theme,
}) => `
  background-color: transparent;
  box-shadow: none;
  color: ${theme.palette.primary.contrastText};
  position: fixed; !important;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 100;
  background-image: none;
  @media(max-width: 599px) {
    background-color: ${theme.palette.background.default}E6;
  }
`);

const StyleToolbar = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(1, 2)};
`);

const LeftSide = styled.div`
  justify-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const RightSide = styled.div(({
  theme,
}) => `
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${theme.spacing(1)}px;
  z-index: 100;
  @media(max-width: 599px) {
    width: 100%;
  }
`);

const StyledIconButton = styled(IconButton)(({
  theme,
}) => `
  margin-right: ${theme.spacing(2)};
  padding: ${theme.spacing(1)};
`);

const PageTitle = styled(Typography)(({
  theme,
}) => `
  margin-right: ${theme.spacing(1)};
  margin-left: 30px;
`);

const ControlArea = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${theme.spacing(2)}; 
`);

const ActionArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  @media(max-width: 600px) {
    width: 100%;
  }
`;

const fadeTopStyle: CSSProperties = {
  zIndex: -1,
};

const anchorStyle: any = {
  vertical: 'top',
  horizontal: 'right',
};

const avatarStyle: CSSProperties = {
  width: 24,
  height: 24,
};

export interface WithNavigationControls {
  controls?: (JSX.Element | null)[] | null;
}
export interface NavbarProps extends WithNavigationControls {
  title?: string;
  hideBreadcrumb?: boolean;
  hideFade?: boolean;
}

/**
 * The main navigation bar for the application.
 */
const Navbar = ({
  title = '',
  controls = null,
  hideFade,
  hideBreadcrumb,
}: NavbarProps) => {
  const {
    user,
    role,
  } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userAsAny = user as any;

  const {
    basePath,
    push,
  } = useRouter();

  const {
    t,
  } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [userMenuEl, setUserMenuEl,] = React.useState(null);
  const userMenuOpen = Boolean(userMenuEl);

  const {
    leftDrawerOpen,
    rightDrawerOpen,
    screenMode,
    setLeftDrawerOpen,
    setRightDrawerOpen,
    toggleScreenMode,
  } = useScreenContext();

  const handleClose = useCallback(() => {
    setUserMenuEl(null);
  }, []);

  const handleMenuClick = useCallback(() => {
    setLeftDrawerOpen(true);
    NotificationPermission.getInstance().requestPermission();
  }, [setLeftDrawerOpen,]);

  const handleUserProfileClick = useCallback(() => {
    handleClose();
    setRightDrawerOpen(true);
  }, [handleClose, setRightDrawerOpen,]);

  const handleAccountClick = useCallback(() => {
    if (rightDrawerOpen) {
      const path = '/account';
      push(path, path, {
        shallow: true,
      });
      return;
    }
    handleClose();
    handleUserProfileClick();
  }, [handleClose, handleUserProfileClick, push, rightDrawerOpen,]);

  const menuButtonStyle: CSSProperties = useMemo(() => ({
    maxWidth: leftDrawerOpen ? 0 : 100,
    transform: `scale(${leftDrawerOpen ? 0 : 1})`,
    overflow: 'hidden',
    transition: 'all 0.2s ease-in-out',
  }), [leftDrawerOpen,]);

  const toolbarStyle: CSSProperties = useMemo(() => ({
    marginLeft: !isMobile ? leftDrawerOpen ? APP_DRAWER_WIDTH : 0 : undefined,
    transition: 'margin-left 0.2s ease-in-out',
    marginRight: !isMobile ? rightDrawerOpen ? APP_DRAWER_WIDTH : 0 : undefined,
  }), [isMobile, leftDrawerOpen, rightDrawerOpen,]);

  const landingPage = useMemo(() => {
    return basePath === '';
  }, [basePath,]);

  return (
    <StyledAppBar id="navbar" >
      {hideFade ? null : <>
        <BackgroundFadeTop
          id="navbar-fade-top"
          $midwayThreshold={40}
          $fadeExtended
          style={fadeTopStyle}
          $height='120px'
        />
      </>}
      <StyleToolbar
        id="navbar-toolbar"
        style={toolbarStyle}>
        <LeftSide id="left-side" >
          <Show whenAuthenticated>
            <StyledIconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              style={menuButtonStyle}
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </StyledIconButton>
          </Show>
          {title ?
            <PageTitle variant="h6">
              {title}
            </PageTitle> : null}
          {hideBreadcrumb ? null : <Breadcrumbs isAnonymous={isAnonymousUser(user)} />}
        </LeftSide>

        <RightSide id="right-side" >
          <ActionArea id="action-area" >
            {controls ? <ControlArea id="control-area" >
              {controls}
            </ControlArea> : null}
            <Show whenAuthenticated
              orRender={<div style={{
                height: 48,
              }} />}
            >
              <LocaleSelector />
            </Show>
            <Show withRoles={['system', 'admin',]}>
              <Button variant={screenMode === 'standard' ? 'text' : 'contained'}
                onClick={toggleScreenMode}>{screenMode}</Button>
              &nbsp;
            </Show>
            {!isMobile && <UserContainer>
              <NameContainer>
                {user?.displayName ?? userAsAny?.name}
              </NameContainer>
              {role && !(role === 'guest' || role === 'anonymous') &&
                <RoleText variant='body1'>
                  as&nbsp;<b>{role ? expandShortRoleId(role) : t('participant')}</b>
                </RoleText>}
            </UserContainer>}
            {user && !(user.email === null || user.email === undefined) ?
              <IconButton
                disabled={!user?.displayName && !userAsAny?.name}
                aria-label={t('account-of-current-user')}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleAccountClick}
                color="inherit"
              >
                {
                  user?.photoURL ?
                    <Avatar alt={user.displayName}
                      sx={avatarStyle}
                      src={user.photoURL} /> :
                    <AccountCircle />
                }
              </IconButton> :
              isMobile && !landingPage ? null : <SigningButtons />
            }
            <Show whenIdentifiedUser>
              <Menu
                id="menu-appbar"
                anchorEl={userMenuEl}
                anchorOrigin={anchorStyle}
                keepMounted
                transformOrigin={anchorStyle}
                open={userMenuOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={handleUserProfileClick}>{t('Open User Profile')}</MenuItem>
              </Menu>
            </Show>
          </ActionArea>
        </RightSide>
      </StyleToolbar>
    </StyledAppBar>
  );
};

export default Navbar;
