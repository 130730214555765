/**
 * # WEB DEVELOPMENT
 *
 * @copyright Mindhive Oy 2021-2024
 */

// import { AppConfig } from '@shared/schema/src';

const config = {
  environment: {
    variant: 'aavistus',
    target: 'staging',
    build: '$BUILD_NUMBER',
    version: '$VERSION_NUMBER',
    sha1: '',
    branch: '',
    tag: '',
    functionsRegion: 'europe-north1',
    webHostAddress: 'https://staging.aavistus.app',
    disableEmulator: true,
  },
  firebase: {
    apiKey: 'AIzaSyAzgMbnOvmHFoA9oQt7GbgoQkEaWOoY1Mg',
    authDomain: 'aavistus-staging.firebaseapp.com',
    projectId: 'aavistus-staging',
    storageBucket: 'aavistus-staging.appspot.com',
    messagingSenderId: '559608672426',
    appId: '1:559608672426:web:50f008546ad9bcc26ee0d3',
    measurementId: 'G-8TLWFV5V2V',
  },
  // this is auto-populated by Github Actions
  payment: {
    stripe: {
      key: 'pk_test_51Kl9PBIkvsenKopc2TezI6upTdJuXHNn0qLk5PjX5fCxhClSL1sXejs700ZHELyMICYAybyLgD0ocwwJWU8vt95a00uJBVc2cU',
    },
  },
  google: {
    credentials: {
      android: '',
      ios: '',
    },
    apiKeys: {
      map: '',
      distanceMatrix: '',
    },
  },
  facebook: {},
  sentry: {
    dsn: 'https://0e4b2d415c9a4c07a7b13221205763ee@o426384.ingest.sentry.io/5948794',
  },
  messaging: {
    vapidKey: 'BGi_fZRbbvQyj_zdQPzT5vEUPt1wBmffyX7KnD6bXzFXjQgXhCvRJ1-4LaxAnj25ho2UQn7OPq2dSSDUH9R93DQ',
  },

};

export default config;
