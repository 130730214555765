import { LayoutElement } from '.';
import Brand from 'components/common/Brand';
import Form from 'components/forms/Form';
import React, { useCallback } from 'react';
import ResetPasswordPageComponent from './ResetPasswordPageComponent';
import type { SignInFormMode, SignInFormType } from '.';

interface ResetPasswordFormProps {
  setMethod?: (method: SignInFormMode) => void;
}
export const ResetPasswordForm = ({
  setMethod,
}: ResetPasswordFormProps) => {
  const handleBackClick = useCallback(() => {
    setMethod?.('sign-in');
  }, [setMethod,]);

  return <Form<SignInFormType>
    // messages={messages}
    maxWidth
    // style={signInStyles}
    id="reset-password-form">
    <Brand />
    <LayoutElement layoutId="email">
      <ResetPasswordPageComponent onBackClick={handleBackClick} />
    </LayoutElement>
  </Form>;
};
