/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */
import { AnimatePresence } from 'framer-motion';
import type { Matrix2DQuestionCanvas, Scale2DAnswer, StatCharacteristics } from '@shared/schema/src';

import Matrix2DInput from './Matrix2DInput';
import Matrix2DLayout from './layouts/Matrix2DLayout';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import type { Size } from '.';

const AutoMargin = styled.div`
  margin: auto;
`;

// const Content = styled.div<{ size: Size }>(({
//   size = {
//     width: 200,
//     height: 200,
//   },
// }) => `
//   grid-area: main;
//   position: relative;
//   max-width: ${size.width}px;
//   max-height: ${size.height}px;
// `);

export interface Answer2DMatrixProps {
  canvas: Matrix2DQuestionCanvas;
  size?: Size;
  graphOnly?: boolean;
  answer?: Scale2DAnswer;
  graphBackgroundColor?: string;
  characteristic?: StatCharacteristics;
}

const Answer2DMatrix = ({
  canvas,
  // graphOnly,
  characteristic: characteristics,
  // size,
  // graphBackgroundColor = theme.palette.background.graph,
}: Answer2DMatrixProps) => {
  // const {
  //   t,
  // } = useTranslation();

  // const sz = useMemo(
  //   () => size ?? {
  //     height: 220, width: 220,
  //   },
  //   [size,]
  // );

  // TODO: Resolve, what this should be doing, now it will most likely return median always
  const answer = resolveAnswer(canvas, characteristics as any);

  // const xMainLabel = canvas?.axis.x.mainLabel;
  // const yMainLabel = canvas?.axis.y.mainLabel;

  // const topLabel = canvas?.axis.x.maxLabel;
  // const leftLabel = canvas?.axis.y.minLabel;
  // const bottomLabel = canvas?.axis.x.minLabel;
  // const rightLabel = canvas?.axis.y.maxLabel;

  // const range = canvas?.axis.x.range;

  useEffect(() => {
    const handleResize = () => {
      // setSz(size ?? {height: 220, width: 220});
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!canvas) {
    return null;
  }

  return <AutoMargin>
    <AnimatePresence>
      <Matrix2DLayout key="graph"
        canvas={canvas as any}
      >
        {/* <TopLabeling key="top-label">
          {
            canvas.showAxisLabels && xMainLabel
          }
          {canvas.showMinMaxLabels &&
            <>
              {
                answer?.x?.toFixed() === NaN.toFixed() ?
                  t('no-answers-for-this-question-yet') : topLabel
              }
            </>
          }
          {canvas.showTickLabels && <XTickMarks
            range={range}
            mode={canvas.mode}
          />}
        </TopLabeling>
        <BottomLabeling key="bottom-label">
          {canvas.showTickLabels && <XTickMarks
            range={range}
            mode={canvas.mode}
          />}
          {canvas.showMinMaxLabels && <span>{bottomLabel}</span>}
        </BottomLabeling>
        <LeftLabeling key="left-label">

          {canvas.showAxisLabels &&
            <div style={{
              display: 'flex',
              overflow: 'hidden',
              width: '1.5em',
              alignItems: 'center',
            }}>

              <div style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                whiteSpace: 'nowrap',
                transform: 'translate(1.1em,0) rotate(90deg)',
                transformOrigin: '0 0',
              }}>
                <div style={{
                  position: 'absolute',
                  transform: 'rotate(180deg)',
                  transformOrigin: 'center',
                }}>{yMainLabel}</div></div>

            </div>}
          {canvas.showMinMaxLabels &&
            <div style={{
              display: 'flex',
              overflow: 'hidden',
              width: '1.5em',
              alignItems: 'center',
            }}>

              <div style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                whiteSpace: 'nowrap',
                transform: 'translate(1.1em,0) rotate(90deg)',
                transformOrigin: '0 0',
              }}>
                <div style={{
                  position: 'absolute',
                  transform: 'rotate(180deg)',
                  transformOrigin: 'center',
                }}>{leftLabel}</div></div>

            </div>}
          {canvas.showTickLabels && <YTickMarks
            mode={canvas.mode}
            range={range}
          />}
        </LeftLabeling>
        <RightLabeling key="right-label">
          {
            canvas.showTickLabels && canvas.tickLabelsPresentation === 'mirrored' &&
            <YTickMarks range={range}
              mode={canvas.mode} />
          }
          {canvas.showMinMaxLabels &&
            <div style={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              bottom: '0px',
              width: '1.5em',
            }}>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                whiteSpace: 'nowrap',
                transform: 'translate(1.1em,0) rotate(90deg)',
                transformOrigin: '0 0',
              }}>
                <div style={{
                  position: 'absolute',
                  transform: 'rotate(180deg)',
                  transformOrigin: 'center',
                }
                }>{rightLabel}</div>
              </div>
            </div>
          }
        </RightLabeling>
        <Content key="content"
          size={sz}> */}
        <Matrix2DInput
          answer={answer as any}
          axis={canvas.axis}
          analyzeOptions={canvas.analyzeOptions}
          // size={size}
          //  Answer graph mode
          // canvas={canvas.mode === 'discrete' ? {
          //   ...canvas, mode: 'continuous',
          //   max: canvas.axis.x.range[canvas.axis.x.range.length - 1].value,
          //   min: canvas.axis.x.range[0].value,
          // } : canvas}
          disabled
          max={canvas.axis.x.max}
          min={canvas.axis.x.min}
          canvasMode={canvas.mode}
          canvasAnswerVisibility={canvas.config?.answerVisibility ?? 'inherited'}
        />
        {/* </Content> */}
      </Matrix2DLayout>
    </AnimatePresence>
  </AutoMargin>;
};

const resolveAnswer = (canvas: Matrix2DQuestionCanvas, characteristic: StatCharacteristics) => {
  if (canvas?.statistics?.type !== '2d') {
    return null;
  }

  // TODO: This is logic is totally wrong, but it's a temporary fix

  if (characteristic?.mean) {
    return {
      x: canvas?.statistics?.averageX,
      y: canvas?.statistics?.averageY,
    };
  }
  if (characteristic?.q1) {
    return {
      x: canvas?.statistics?.q1X,
      y: canvas?.statistics?.q1Y,
    };
  }

  if (characteristic?.q3) {
    return {
      x: canvas?.statistics?.q3X,
      y: canvas?.statistics?.q3Y,
    };
  }

  if (characteristic?.median) {
    return {
      x: canvas?.statistics?.medianX,
      y: canvas?.statistics?.medianY,
    };
  }
  return null;
};

export default Answer2DMatrix;
