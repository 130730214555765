import { componentBuilder } from './builders/componentBuilder';
import CanvasGraphWidget from './widgets/renderers/CanvasWidget';
import CanvasPlaceHolderWidget from './widgets/renderers/CanvasPlaceHolderWidget';
import CanvasWidgetEditor from './widgets/editors/CanvasWidgetEditor';
import CanvasWidgetGraph from './widgets/graphs/CanvasWidgetGraph';
import CommentFeedWidget from './widgets/renderers/CommentFeedWidget';
import FreeTextWidget from './widgets/renderers/FreeTextWidget';
import FreeTextWidgetEditor from './widgets/editors/FreeTextWidgetEditor';
import Graph2DWidget from './widgets/renderers/Graph2DWidget';
import Graph2DWidgetEditor from './widgets/editors/Graph2DWidgetEditor';
import GridLayoutWidget from './widgets/renderers/GridLayoutWidget';
import GridLayoutWidgetEditor from './widgets/editors/GridLayoutWidgetEditor';
import HeroWidget from './widgets/renderers/HeroWidget';
import HeroWidgetEditor from './widgets/editors/HeroWidgetEditor';
import ImageWidget from './widgets/renderers/ImageWidget';
import QRCodeWidget from './widgets/renderers/QRCodeWidget';
import QuestionDescriptionWidget from './widgets/renderers/QuestionDescriptionWidget';
import QuestionDescriptionWidgetEditor from './widgets/editors/QuestionDescriptionWidgetEditor';
import StatisticsWidget from './widgets/renderers/StatisticsWidget';
import StatisticsWidgetEditor from './widgets/editors/StatisticsWidgetEditor';
import TextWidget from './widgets/renderers/TextWidget';
import TextWidgetEditor from './widgets/editors/TextWidgetEditor';
import VideoWidget from './widgets/renderers/VideoWidget';

// TODO Hook register function to module loading to automat this part
componentBuilder.register({
  renderComponent: TextWidget,
  editorComponent: TextWidgetEditor,
});
componentBuilder.register({
  renderComponent: ImageWidget,
});
componentBuilder.register({
  renderComponent: HeroWidget,
  editorComponent: HeroWidgetEditor,
});
componentBuilder.register({
  renderComponent: VideoWidget,
});
componentBuilder.register({
  renderComponent: CanvasGraphWidget,
  editorComponent: CanvasWidgetEditor,
  graphComponent: CanvasWidgetGraph,
});
componentBuilder.register({
  renderComponent: FreeTextWidget,
  editorComponent: FreeTextWidgetEditor,
});
componentBuilder.register({
  renderComponent: QRCodeWidget,
  // editorComponent: QRCodeWidgetEditor,
});
componentBuilder.register({
  renderComponent: QuestionDescriptionWidget,
  editorComponent: QuestionDescriptionWidgetEditor,
});
componentBuilder.register({
  renderComponent: Graph2DWidget,
  editorComponent: Graph2DWidgetEditor,
});
componentBuilder.register({
  renderComponent: StatisticsWidget,
  editorComponent: StatisticsWidgetEditor,
});
// componentBuilder.register({
//   renderComponent: CarouselWidget,
//   editorComponent: CarouselWidgetEditor,
// });
// componentBuilder.register({
//   renderComponent: FooterWidget,
//   editorComponent: FooterWidgetEditor,
// });
// componentBuilder.register({
//   renderComponent: FooterNavWidget,
//   editorComponent: FooterNavWidgetEditor,
// });
// componentBuilder.register({
//   renderComponent: PlansWidget,
//   editorComponent: PlansWidgetEditor,
// });

componentBuilder.register({
  renderComponent: GridLayoutWidget,
  editorComponent: GridLayoutWidgetEditor,
});
/**
 * Live
 */
componentBuilder.register({
  renderComponent: CommentFeedWidget,
});
componentBuilder.register({
  renderComponent: CanvasPlaceHolderWidget,
});

// componentBuilder.register({
//   renderComponent: TalkingHeadWidget,
// });
