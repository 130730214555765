import type { WhereQueryConstraint } from '../types';

export const whereMatch = (value: any, where: WhereQueryConstraint): boolean => {
  switch (where.opStr) {
    case '!=':
      return value !== where.value;
    case '<':
      return value < where.value;
    case '>':
      return value > where.value;
    case '==':
      return value === where.value;
    case '<=':
      return value <= where.value;
    case '>=':
      return value >= where.value;
    case 'array-contains':
      if (!Array.isArray(value)) {
        return false;
      }

      if (Array.isArray(where.value)) {
        return value.every((value) => (where.value as any)?.includes(value));
      }
      return value?.includes(where.value);
    case 'array-contains-any':
      if (Array.isArray(value) && Array.isArray(where.value)) {
        return where.value.some((val) => value.includes(val));
      }
      return false;
    case 'in':
      if (!Array.isArray(where.value)) {
        throw new Error('where in can required an array as value.');
      }
      return where.value?.includes(value);
    case 'not-in':
      if (!Array.isArray(where.value)) {
        throw new Error('where in can required an array as value.');
      }
      return !where.value?.includes(value);

    default:
      throw new Error(`Unsupported where operator: ${where.opStr}.`);
  }
};
