import { TableOfContentItem } from '../SignUpForm';

/**
 * Extract plain text to a component presentation with headers and anchors
 *
 * @param {string}  content   Plain text
 * @return {JSX.Element[]}   Component presentation
 */
export const extractComponentPresentation = (content: string) => {
  const resultingView = [];
  const viewLines = content.split('\n');
  let currentParagraph = '';
  let key = 0; // unique key for each Text / Title / TableOfContentsText widget
  let previousChapter = 0;
  let tableOfContents = true; // defines if renderer should display a table of contents header or a chapter header
  for (const line of viewLines) {
    const words = line.trim().split(/[. ]+/)[0];
    if (isNumber(words[0])) {
      // line is a header
      const currentChapter = Number(words);
      if (tableOfContents && currentChapter < previousChapter) {
        tableOfContents = false; // turn off ToC mode when there is a chapter smaller than the previous one
      }
      previousChapter = currentChapter;
      if (currentParagraph !== '') {
        const isPeriodCharNext = words[1] === '.';
        resultingView.push(<a key={`a_${key}`}
          id={(++key).toString()} />);
        resultingView.push(
          isPeriodCharNext ?
            <h3 key={key}>{line}</h3> :
            <h2 key={key}>{line}</h2>
        );
      }
      currentParagraph = '';
      ++key;
      if (tableOfContents) {
        resultingView.push(
          <TableOfContentItem id={key}>{line}</TableOfContentItem>
        );
      } else {
        resultingView.push(
          <h2 key={key}>{line}</h2>
        );
      }
      continue;
    }
    if (key++ === 0) {
      resultingView.push(<h1 key={key}>{line}</h1>);
      continue;
    }

    resultingView.push(
      <p key={key}>{line}</p>
    );
  }
  return resultingView;
};
const isNumber = (str: string): boolean => {
  const n = Number(str);
  return !isNaN(n);
};
