import { useCallback, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

/* eslint-disable max-len */
import { createContext, useContext } from 'react';
import { noop } from 'lodash';
import { useScreenSliderContext } from '../components/ScreenSlider';

const Container = styled.div<{
  $isAnimating?: boolean;
}>(({
  theme, $isAnimating,
}) => `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;  
  max-height: 100%;
  overflow-y: ${$isAnimating ? 'hidden' : 'auto'};
  box-sizing: border-box;
  scroll-snap-type: y mandatory;
  background-color: ${theme.palette.background.default};
  transition: opacity 0.3s ease-in-out;

  ${$isAnimating ? `
    scrollbar-width: none; /* For Firefox */
    &::-webkit-scrollbar {
      display: none; /* For Chrome, Safari, and Opera */
    }
  ` : ''}
`);

export interface PanelistScrollOptions extends ScrollToOptions {
  /**
   * Callback to be called when the scroll ends or has reached the end.
   * @returns   {void}
   */
  onScrollEnd?: () => void;
}

export interface panelistScrollContextType {
  scrollRef: React.RefObject<HTMLDivElement>;
  scrollTo: (options: PanelistScrollOptions) => void;
  subscribeForScroll: (callback: () => void) => () => void;
  subscribeForScrollEnd: (callback: () => void) => () => void;
}

const PanelistScrollContext = createContext<panelistScrollContextType>({
  scrollRef: null as any,
  scrollTo: noop as any,
  subscribeForScroll: noop as any,
  subscribeForScrollEnd: noop as any,
});

/**
 * Generates a panelist scroll provider with the ability to handle scrolling behavior.
 *
 * @param {PropsWithChildren<any>} children - The children elements to be rendered within the provider.
 * @return {JSX.Element} The PanelistScrollProvider component.
 */
export const PanelistScrollProvider = ({
  children,
}: PropsWithChildren<any>) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollEndListeners = useRef<(() => void)[]>([]);
  const scrollListeners = useRef<(() => void)[]>([]);

  // TODO: only used inside handleScrollEnd, maybe move it there and
  //       check this debounce
  const handleScrollEnd = useCallback(() =>
    scrollEndListeners.current.forEach((listener) => listener()), []
  );

  const handleScroll = useCallback(() => {
    handleScrollEnd();
    scrollListeners.current.forEach((listener) => listener());
  }, [handleScrollEnd,]);

  const scrollTo = useCallback((options: PanelistScrollOptions) => {
    if (!scrollRef.current) {
      return;
    }
    const {
      onScrollEnd,
    } = options;

    if (onScrollEnd) {
      // if scroll position is already at the end, trigger event immediately
      if (options.top === scrollRef.current.scrollTop) {
        onScrollEnd();
        return;
      }
      options.behavior = 'smooth';

      let listener: undefined | (() => void) = undefined;
      let clearTimer: undefined | NodeJS.Timeout = undefined;

      const cleanOut = () => {
        listener && scrollRef.current?.removeEventListener('scrollend', listener);
        listener = undefined;

        clearTimer && clearTimeout(clearTimer);
        clearTimer = undefined;
      };

      listener = () => {
        onScrollEnd();
        cleanOut();
      };

      // trigger event in 2 s if no scroll event
      clearTimer = setTimeout(() => {
        listener?.();
        cleanOut();
      }, 2000);

      scrollRef.current?.addEventListener('scrollend', listener);
    }
    scrollRef.current?.scrollTo(options);
  }, []);

  const value: panelistScrollContextType = useMemo(() => {
    return {
      scrollRef,
      scrollTo,
      subscribeForScroll: (callback: () => void) => {
        scrollListeners.current.push(callback);
        return () => {
          scrollListeners.current = scrollListeners.current.filter((listener) => listener !== callback);
        };
      },
      subscribeForScrollEnd: (callback: () => void) => {
        scrollEndListeners.current.push(callback);
        return () => {
          scrollEndListeners.current = scrollEndListeners.current.filter((listener) => listener !== callback);
        };
      },
    };
  }, [scrollTo,]);

  const model = useScreenSliderContext();

  return (
    <Container
      ref={scrollRef}
      onScroll={handleScroll}
      data-testid="PanelistScrollProvider"
      $isAnimating={model?._isAnimating}
    >
      <PanelistScrollContext.Provider value={value}>
        {children}
      </PanelistScrollContext.Provider>
    </Container>
  );
};

export const usePanelistScrollContext = () => {
  return useContext(PanelistScrollContext);
};
