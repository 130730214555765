export const greaterThan = (a: any, b: any): number => {
  const at = typeof a;
  const bt = typeof b;

  if (at !== bt) {
    return 0;
  }
  switch (at) {
    case 'number':
      return b - a;
    case 'string':
      return b.localeCompare(a);

    default:
      throw new Error(`Unsupported type: ${JSON.stringify(at)}`);
  }
};
