
const path = require('path');
const NextI18Next = require('next-i18next').default;

// DATE-LOCALES-START
require('dayjs/locale/de');
require('dayjs/locale/es');
require('dayjs/locale/fi');
require('dayjs/locale/fr');
require('dayjs/locale/pt');
require('dayjs/locale/sv');
// DATE-LOCALES-END

module.exports = {
  // https://www.i18next.com/overview/configuration-options#logging
  debug: false, // process.env.NODE_ENV === 'development',
  i18n: {
    defaultLocale: 'en',
    locales: ['es', 'fr', 'de', 'pt', 'fi', 'sv', 'en'],
    // localeDetection: true,
  },

  reloadOnPrerender: process.env.NODE_ENV === 'development',

  ns: ['common', 'dashboard',], // the namespaces needs to be listed here, to make sure they got preloaded
  localePath: typeof window === 'undefined' ? path.resolve('./public/locales') : '/public/locales',

  /**
   * @link https://github.com/i18next/next-i18next#6-advanced-configuration
   */
  // saveMissing: false,
  // strictMode: true,
  // serializeConfig: false,
  // react: { useSuspense: false }
};
