import { answerValueToIndexX } from '../../../../canvasTypes/Matrix2DQuestionCanvas/answer2DUtils';

export const answerX = ({
  canvasMode, axis, x, minX,
}: any) => {
  switch (canvasMode) {
    case 'discrete':
      return answerValueToIndexX(axis, x);
    case 'continuous':
      return x - minX;
  }
};
