/* eslint-disable indent */
import type { MiniatureAnswer } from '@shared/schema/src';

import { useTranslation } from 'next-i18next';
import Canvas2DMatrixMiniature from './miniatures/Matrix2DQuestionCanvas';
import React from 'react';

export interface CanvasProps {
  answer: MiniatureAnswer;
  // canvas?: WithId<Canvas>;

}
const CanvasMiniatureElement = ({
  answer,
  // canvas,
}: CanvasProps) => {
  const {
    t,
  } = useTranslation();

  if (!answer) {
    return null;
  }
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (answer.type) {
    case '2d':
      return <Canvas2DMatrixMiniature
      // answer={answer}
      // canvas={canvas as WithId<Matrix2DQuestionCanvas>}
      />;

    // TODO: 1d miniature
    // case '1d':
    //   return <div>Not implemented yet.</div>;

    // case 'text':
    //   return <div>{Canvas.content}</div>;

    default:
      return <div>{t('unidentified-type')}</div>;
  }
};

export default CanvasMiniatureElement;
