import { AnimatePresence, motion } from 'framer-motion';
import { Chevron } from '../../common/Chevron';
import { Link, useMediaQuery, useTheme } from '@mui/material';
import { capitalizeFirstLetter } from 'utils/common';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import DrawerMenuItem from './DrawerMenuItem';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import FeedViews from './FeedViews';
import HomeIcon from '@mui/icons-material/Home';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Logo from 'components/common/Logo';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React from 'react';
import config from 'config';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import useScreenContext from '../useScreenContext';

export const APP_DRAWER_WIDTH = 300;
export const CARD_DRAWER_WIDTH = 200;

const LeftDrawer = styled$(Drawer)<{
  $width: number;
}>(({
  theme,
  $width,
}) => `
    flex-shrink: 0;

    & .MuiDrawer-paper {
      width: ${$width}px;
      box-sizing: border-box;
      background-color: ${theme.palette.background.default};
    }
  `);

const DrawerHeader = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-top: ${theme.spacing(2)};
  width: 100%;
  justify-content: space-between;
  max-height: 47px;
`);

const DrawerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const MenuDivider = styled(Divider)(({
  theme,
}) => `
  padding: ${theme.spacing(1, 0)};
  background-color: transparent;
`);

const IconContainer = styled(motion.div)`
  display: flex;
  align-items: center;
`;

export const DrawerListIcon = styled(motion.div)(({
  theme,
}) => `
  margin-right: ${theme.spacing(2)}
`);

const StyledBack = styled(Link)(({
  theme,
}) => `
  text-decoration: none;
  padding: ${theme.spacing(2, 2, 2, 2)};
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: ${theme.spacing(2)};
`);

const StyledLink = styled(Link)(({
  theme,
}) => `
  text-decoration: none;
  padding: ${theme.spacing(2, 2, 2, 2)};
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: ${theme.spacing(2)};
`);

const StyledA = styled.span(({
  theme,
}) => `
  color: ${theme.palette.primary.contrastText};
  font-size: ${theme.typography.h6.fontSize};
  font-face: ${theme.typography.h6.fontFace};
`);

export type DrawerMenuItemId = 'project-feed' | 'notifications' | 'bookmarks' | 'messages';

export interface DrawerMenuItemDescriptor {
  label: string;
  id: DrawerMenuItemId;
  icon: JSX.Element;
}

/**
 * items accessible from everywhere
 */
const basicItems: DrawerMenuItemDescriptor[] = [
  {
    id: 'notifications',
    label: 'Notifications',
    icon: <NotificationsIcon />,
  },
  {
    id: 'messages',
    label: 'Messages',
    icon: <MailOutlineIcon />,
  },
];

const projectItems: DrawerMenuItemDescriptor[] = [
  {
    id: 'project-feed',
    label: 'Project feed',
    icon: <DynamicFeedIcon />,
  },
  ...basicItems,
  // {
  //   id: 'bookmarks',
  //   label: 'Bookmarks',
  //   icon: <BookmarkIcon/>,
  // },
];

const spaceItems: DrawerMenuItemDescriptor[] = [
  ...basicItems,
  // {
  //   id: 'bookmarks',
  //   label: 'Bookmarks',
  //   icon: <BookmarkIcon/>,
  // },
];

const Caption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LogoSpan = styled.span(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: ${theme.spacing(0, 2)};
  gap: ${theme.spacing(1)};
`);

const AppDrawer = () => {
  const {
    leftDrawerOpen,
    setLeftDrawerOpen,
  } = useScreenContext();

  const theme = useTheme();

  const {
    t,
  } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    query: {
      spaceId,
      projectId,
    },
    pathname,
  } = useRouter();

  const [selected, setSelected,] = useState<DrawerMenuItemId | undefined>();

  const handleDrawerClose = useCallback(() => {
    setLeftDrawerOpen(false);
  }, [setLeftDrawerOpen,]);

  const items = useMemo(() => {
    if (projectId) {
      return projectItems;
    }
    if (spaceId) {
      return spaceItems;
    }
    return basicItems;
  }, [spaceId, projectId,]);

  const selectedItem = items.find((i) => i.id === selected);

  const handleClearSelection = useCallback((event: any) => {
    setSelected(undefined);
    event.stopPropagation();
  }, []);

  return <LeftDrawer
    BackdropProps={{
      invisible: true,
    }}
    style={{
      zIndex: 1300,
    }}
    variant='persistent'
    $width={APP_DRAWER_WIDTH}
    anchor="left"
    open={leftDrawerOpen}
    PaperProps={{
      elevation: 0,
    }}
  >
    <DrawerHeader>
      <IconContainer key="x"
        // layoutId={`x_${selected}`}
        initial={{
          opacity: 0,
          rotateZ: -90,
        }}
        animate={{
          opacity: 1,
          rotateZ: 0,
        }}
        exit={{
          opacity: 0,
          rotateZ: 90,
        }}
      >
        <CloseIcon
          onClick={handleDrawerClose}
          style={{
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
          }} />
      </IconContainer>

      <AnimatePresence>{selected ?
        <motion.div key="caption"
          // layoutId={selectedItem?.label}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <Caption >
            <DrawerListIcon
            // layoutId={`i_${selected}`}
            >
              {selectedItem?.icon}
            </DrawerListIcon>
            <motion.div
            // layoutId={selectedItem?.label}
            >
              <ListItemText primary={selectedItem?.label} />
            </motion.div>
          </Caption>
        </motion.div> :
        <motion.div key="brand"
          initial={{
            opacity: 0,
          }}
          animate={{
            left: 0,
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <ListItemText primary={
            <LogoSpan><Logo size={32} /> {capitalizeFirstLetter(t(config.environment.variant))}</LogoSpan>}
          />
        </motion.div>}
      </AnimatePresence>
      <AnimatePresence>

        {selected && <Chevron style={{
          marginLeft: theme.spacing(1),
        }}
        $open={leftDrawerOpen}
        onClick={handleClearSelection} />
        }

      </AnimatePresence>
    </DrawerHeader>

    {
      isMobile && !selected && pathname !== '/' && <>
        <MenuDivider />
        <StyledBack href='./'>
          <ArrowBackIcon
            style={{
              color: theme.palette.primary.contrastText,
            }} />
          <StyledA>
            {t('Back')}
          </StyledA>
        </StyledBack>
        <StyledLink href='/' >
          <HomeIcon style={{
            color: theme.palette.primary.contrastText,
          }} />
          <StyledA>
            {t('home')}
          </StyledA>
        </StyledLink>
      </>
    }

    <MenuDivider />

    {items.length ? <>
      <DrawerContainer>
        <List>
          {items.map((item) => <DrawerMenuItem
            key={item.id}
            item={item}
            onClick={setSelected}
            selected={selected === item.id}
          />)
          }
        </List>
        <AnimatePresence>
          <FeedViews key={selected}
            selected={selected} />
        </AnimatePresence>
        <Divider />
      </DrawerContainer>
    </> : <></>
    }
  </LeftDrawer>;
};

export default AppDrawer;
