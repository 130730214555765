/* eslint-disable no-case-declarations */
import { createTheme } from '@mui/material/styles';
import { getAavistusThemeOptions } from './variants/aavistus';
import { getXdelphiThemeOptions } from './variants/xdelphi';
import config from 'config';
import type { Theme as MaterialTheme, PaletteOptions, ThemeOptions } from '@mui/material/styles';
import type { Media } from '@shared/schema/src/common';
import type { Shape } from '@mui/system';
import type { TypeBackground, TypeText } from '@mui/material/styles/createPalette';

// Theme interfaces and default theme valid for both xDelphi and Aavistus

export type AavistusTheme = MaterialTheme & AavistusThemeExtensions;

export interface AavistusPalette extends PaletteOptions {

  background: Partial<TypeBackground> & {
    /**
     * The background color of papers on the top of a background paper
     */
    innerPaper: string;
    defaultTransparent: string;
    paper60: string;
    paper70: string;
    comment: string;
    graph: string;
    lightInnerPaper: string;
    paperDropdown: string;
  };
  text: Partial<TypeText> & {
    lightgrey: string;
  },
  /**
   * Color's used in platforms own question inputs
   */
  question: {
    main: string;
  }
}

export interface AavistusThemeExtensions {
  animations: {
    hovers: {
      /**
       * Hover on card defining some basic concept on the UI like
       * project, session or canvas.
       */
      card?: string;
    }
  },
  palette: AavistusPalette;
  shape: Shape & {
    smokeGlassBlur: number;
    imagePlaceholder: string;
    maxEditorAreaWidth: number;
  },
  constants: {
    headerHeight: string;
    bottomHeight: string
  }
}
/**
 * Cast Emotion's Theme type to Material theme
 */
declare module '@emotion/react' {
  export interface Theme extends AavistusTheme {
    custom: string; // Needed to make
  }
}

/**
 * Add a custom breakpoint for very large screens
 */
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
  }
}

export const COLOR_APPLE = 'rgba(0, 0, 0, 1)';
export const COLOR_GOOGLE = '#4285F4';
export const COLOR_FACEBOOK = '#1877F2';
export const COLOR_LINKEDIN = '#2D6798';

// TODO: Move to be a part of theme
export const chipColors = {
  draft: {
    backgroundColor: '#ffc938',
    color: '#614a00',
  },
  active: {
    backgroundColor: '#6bf36b',
    color: '#004600',
  },
  closed: {
    backgroundColor: '#a3cae2',
    color: '#00008B',
  },
  facilitator: {
    backgroundColor: '#ffa638',
    color: '#4F3003',
  },
  panelist: {
    backgroundColor: 'transparent',
    color: '#4Bc2fA',
  },
  live: {
    backgroundColor: '#5fff28',
    color: '#003300',
  },
};

export type ChipType = keyof typeof chipColors;

// TODO Create a theme with this tool: https://bareynol.github.io/mui-theme-creator/

export type AavistusOptions = ThemeOptions & AavistusThemeExtensions;

const ITEM_HOVER_STYLE = 'transform: scale(1.03);' +
  'box-shadow: 0px 10px 13px -7px #000000, 11px 15px 15px 3px rgba(0,0,0,0);';

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 2000,
  },
};
let theme = createTheme(
  {
    ...breakpoints,
  } as ThemeOptions) as AavistusTheme;

const themeOptions: AavistusOptions = {
  animations: {
    hovers: {
      card: ITEM_HOVER_STYLE,
    },
  },
  breakpoints,
  shape: {
    borderRadius: 12,
    smokeGlassBlur: 8,
    imagePlaceholder: '/images/ImagePlaceholder.png',
    maxEditorAreaWidth: 1200,
  },
  constants: {
    headerHeight: '80px',
    bottomHeight: '72px',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          -webkit-font-smoothing: auto;
          margin: 0;
        }
        body {
          margin: 0;
        }
        `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        colorSecondary: {
          color: '#D1D2D7',
        },
      },
    },
  },
  typography: {
    allVariants: {
      color: '#f2f2f2',
    },
    fontFamily: [
      'Poppins',
      'Lato',
      'Nunito',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '2.4rem',
      marginBottom: '1rem',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: 1.2,
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.1rem',
      },
    },
    h2: {
      fontSize: '2.1rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.9rem',
      },
    },
    h3: {
      fontSize: '1.8rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.7rem',
      },
    },
    h4: {
      fontSize: '1.5rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.25rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.1rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      fontWeight: 400,
    },
    body1: {
      marginTop: '0.75rem',
      marginBottom: '1rem',
      fontWeight: 300,
    },
    body2: {
      fontSize: '0.75rem',
      marginTop: '0.75rem',
      marginBottom: '0.75rem',
      fontWeight: 300,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#0779B6',
      contrastText: '#fff',
      light: '#5BC2FA',
    },
    secondary: {
      main: '#00a15b',
    },
    action: {
      disabledBackground: '#C2C2C2',
      disabled: '#ffffff80',
    },
    error: {
      main: '#FF5F5F',
    },
    background: {
      // default: '#101018',
      default: '#202030',
      defaultTransparent: 'rgba(32,32,48,0.7)',
      // paper: 'rgba(40,40,40,0.85)',
      paper: 'rgba(50,50,50,0.85)',
      paper60: 'rgba(50,50,50,0.6)',
      paper70: 'rgba(50,50,50,0.75)',
      comment: '#55595B',
      innerPaper: '#4f4f6f',
      graph: '#C4C4C4',
      lightInnerPaper: '#50516f',
      paperDropdown: 'rgb(50,50,50)',
    },
    text: {
      primary: 'rgba(230,232,255,0.8)',
      secondary: '#E5E5E5',
      lightgrey: '#D3D3D3',
    },
    warning: {
      main: '#C64C06',
      dark: '#a72505',
    },
    question: {
      main: '#4Bc2fA',
    },
  },
};

export const EMPTY_MEDIA_RECORD: Media = {
  type: 'photo',
  photoURL: themeOptions.shape.imagePlaceholder,
  tags: [],
};

switch (config.environment.variant) {
  // Note that we use shallow merge here, so nested objects will be replaced instead of merged
  case 'aavistus':
    const aavistusThemeOptions = getAavistusThemeOptions(theme);
    theme = createTheme({
      ...themeOptions, ...aavistusThemeOptions,
    }) as AavistusTheme;
    break;
  case 'xdelphi':
    const xdelphiThemeOptions = getXdelphiThemeOptions(theme);
    theme = createTheme({
      ...themeOptions, ...xdelphiThemeOptions,
    }) as AavistusTheme;
    break;
  default:
    theme = createTheme(themeOptions) as AavistusTheme;
}

export default theme;
