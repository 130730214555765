/* eslint-disable @typescript-eslint/no-explicit-any */

import { resolveCellCount } from '../editors/resolveCellCount';
import { resolveElements } from '../editors/resolveElements';
import { useMemo } from 'react';
import GridViewIcon from '@mui/icons-material/GridView';
import componentBuilder from '../../builders/componentBuilder';
import type { BuilderComponentPropsBase, BuilderComponentPropsBaseWithId } from '../BuilderComponentTypes';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { GridLayoutWidget } from '@shared/schema/src/spaces/projects/sessions/canvases';

export interface GridWidgetProps extends BuilderComponentPropsBase<GridLayoutWidget> {
  type: 'grid-layout';
}

const GridLayoutWidgetImplementation: BuilderFunction<GridWidgetProps, any> = ({
  data: {
    columnTemplate,
    rowTemplate,
    maxWidth,
    backgroundColor,
    cells = [],
  },
}) => {
  const {
    style,
    widgets,
  } = useMemo(() => {
    const columns = resolveElements(columnTemplate);
    const rows = resolveElements(rowTemplate);

    const rowCount = resolveCellCount(rows);
    const columnCount = resolveCellCount(columns);
    const count = columnCount * rowCount;

    const widgets: BuilderComponentPropsBaseWithId[] = [];
    for (let i = 0; i < count; i++) {
      const element = cells?.[i] ?? null;
      widgets.push(element as any);
    }
    return {
      widgets,
      style: {
        display: 'grid',
        gridTemplateColumns: columnTemplate,
        gridTemplateRows: rowTemplate,
        height: '100%',
        width: '100%',
        // border: '1px solid black',
        backgroundColor,
        maxWidth,
        minWidth: 300,
      },
    };
  }, [backgroundColor, cells, columnTemplate, maxWidth, rowTemplate,]);

  return <div style={style}>{
    widgets.map((widget, index) => {
      if (!widget) {
        return null;
      }
      const Renderer = componentBuilder.getRenderer(widget.type);
      return <div key={index}>
        <Renderer {...widget} />
      </div>;
    })
  }</div>;
};

GridLayoutWidgetImplementation.config = {
  type: 'grid-layout',
  icon: <GridViewIcon />,

  displayName: 'Grid layout',
  props: {
    columnTemplate: {
      type: 'text',
      displayName: 'Column template',
      defaultValue: 'auto 1fr',
      category: 'general',
    },
    rowTemplate: {
      type: 'text',
      displayName: 'Row template',
      defaultValue: '',
      category: 'general',
    },
    gap: {
      type: 'text',
      displayName: 'Gap',
      defaultValue: '0px',
      category: 'general',
    },
    maxWidth: {
      type: 'text',
      displayName: 'Max width',
      defaultValue: 'auto',
      category: 'general',
    },
    maxHeight: {
      type: 'text',
      displayName: 'Max height',
      defaultValue: 'auto',
      category: 'general',
    },
    backgroundColor: {
      type: 'color',
      displayName: 'Background color',
      defaultValue: 'transparent',
      category: 'general',
    },

  },
};

export default GridLayoutWidgetImplementation;
