import { forwardRef, useCallback, useMemo } from 'react';
import { knobDragTransition, knobHoverStyle, knobTapStyle, knobTransition } from './knobStyle';
import { motion } from 'framer-motion';
import MoveKnobDragCue from './MoveKnobDragCue';
import styled$ from 'utils/react/styled$';
import type {
  AnimationControls,
  DraggableProps, MotionStyle, PanInfo, ResolvedValues, TargetAndTransition, VariantLabels
} from 'framer-motion';
import type { MatrixMode } from '@shared/schema/src';
import type { PropsWithChildren } from 'react';

export const KNOB_MARGIN = 4;
export const KNOB_SIZE = 68;
export const KNOB_DISABLED_OPACITY = 0.5;

const Box = styled$.div<{
  $disabled?: boolean;
  $mode: MatrixMode;
}>(({
  theme, $disabled, $mode,
}) => `
  position: relative;
  width: calc(100% - ${KNOB_MARGIN * 2}px);
  height: calc(100% - ${KNOB_MARGIN * 2}px);
  margin: ${KNOB_MARGIN}px;
  cursor: ${!$disabled && 'grab'};
  background-color: ${theme.palette.question.main};
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: ${$mode === 'discrete' ? `${theme.shape.borderRadius * 0.75}px` : '50%'};
`);

interface KnobProps {
  /**
   * Defines if the knob is active and is defining the answer given by the user.
   */
  active?: boolean;
  animate?: AnimationControls | TargetAndTransition | VariantLabels | boolean;
  dragConstraints: DraggableProps['dragConstraints'];
  dragDimensions?: 'x' | 'y' | 'xy';
  disabled?: boolean;
  height: number | string;
  mode: MatrixMode;
  /**
   * Defines if the drag cues should be shown.
   */
  showDragCues?: boolean;
  width: number | string;

  onDragStart?: (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void;
  onDragUpdate?: (values: ResolvedValues) => void;
  onDragEnd?: (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void;
}

/**
 * Knob component that is used in slider's to show and edit the user input.
 *
 * The knob will be displayed as a circle or a rounded box depending on the
 * mode. The knob can be dragged to change the value of the slider.
 */
const Knob = forwardRef<HTMLDivElement, PropsWithChildren<KnobProps>>(({
  active,
  animate,
  children,
  disabled,
  dragConstraints,
  dragDimensions = 'xy',
  height,
  mode,
  showDragCues,
  width,
  onDragStart,
  onDragUpdate,
  onDragEnd,
}, knobRef: any) => {
  const knobStyle = useMemo(() => {
    return {
      width,
      height,
      opacity: active ? 1 : KNOB_DISABLED_OPACITY,
      position: 'absolute',
      zIndex: 10000,
    } as MotionStyle;
  }, [active, height, width,]);

  const handleDragStart = useCallback((
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    if (disabled || !knobRef?.current) {
      return;
    }
    onDragStart?.(event, info);
  }, [disabled, knobRef, onDragStart,]);

  const handleUpdate = useCallback((values: ResolvedValues) => {
    if (disabled) {
      return;
    }
    onDragUpdate?.(values);
  }, [disabled, onDragUpdate,]);

  const handleDragEnd = useCallback((event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    if (disabled) {
      return;
    }

    onDragEnd?.(event, info);

    event.stopPropagation();
  }, [disabled, onDragEnd,]);

  const dragMode = useMemo(() => {
    if (disabled) {
      return false;
    }
    switch (dragDimensions) {
      case 'x':
      case 'y':
        return dragDimensions;
      default:
        return true;
    }
  }, [disabled, dragDimensions,]);

  return <motion.div
    key="knob"
    layoutId="knob"
    ref={knobRef}
    animate={animate}
    dragElastic={0}
    whileHover={!disabled ? knobHoverStyle : undefined}
    whileTap={!disabled ? knobTapStyle : undefined}
    style={knobStyle}
    drag={dragMode}
    dragConstraints={dragConstraints}
    dragTransition={knobDragTransition}
    transition={!disabled ? knobTransition : undefined}
    onDragStart={!disabled ? handleDragStart : undefined}
    onDragEnd={!disabled ? handleDragEnd : undefined}
    onUpdate={!disabled ? handleUpdate : undefined}
  >
    <Box $disabled={disabled}
      $mode={mode}
    >
      {children}
      <MoveKnobDragCue
        axis={dragDimensions}
        visible={!!showDragCues}
        width={width}
        height={height}
      />
    </Box>
  </motion.div>;
});

Knob.displayName = 'Knob';

export default Knob;
