/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unused-imports/no-unused-vars */

/**
 * HttpMessage is the base for all Firebase Cloud Functions HTTP messages.
 */
export interface HttpMessage<t, Request, Response> {
  type: t;
  payload: Request;
  response: Response;
}

export type HttpRequestErrorType =
  'unauthenticated' |
  'unauthorized' |
  'network' |
  'not-found' |
  'internal' |
  'invalid-argument' |
  'failed-precondition' |
  'out-of-range' |
  'unimplemented' |
  'unavailable' |
  'cancelled';

/**
 * Bundle error is a custom error class that is used to indicate
 * errors explicitly raised by the backend in the bundle functions.
 */
export class BundleError<T = string> extends Error {
  /**
   * Bundle error is a custom error class that is used to indicate that
   * there was an error
   * @param {T}  code     Error code. You may use generic types to specify the type of the code.
   * @param {*}  message  Error message
   */
  constructor(
    public readonly code: T | HttpRequestErrorType,
    message: string
  ) {
    super(message);
    this.name = 'BundleError';
  }
}

/**
 * Casting helper to handle BundleError messages
 * @param {*} error     Error object in the catch block
 * @param {*} callback  Callback function that will be called if the error
 *                      is a BundleError.
 * @return {boolean}    Returns true when a callback was called.
 */
export const withBundleError = <T extends string>(error: any,
  callback: (error: BundleError<T>) => void
) => {
  if (error?.name === 'BundleError') {
    callback(error as BundleError<T>);
    return true;
  }
  return false;
};

export type HttpMessageResponse<Response>
  = SucceedHttpMessage<Response> | FailHttpMessage;

export interface SucceedHttpMessage<Response> {
  status: 'ok';
  response: Response;
}

export interface FailHttpMessage {
  status: 'error';
  code: string;
  error: string;
}
