import type { CommentParams } from '@shared/schema/src';

export const resolveSessionPath = (params: CommentParams): {
  spaceId: string;
  projectId: string;
  sessionId: string;
  canvasId: string;
  parentId: string;
} => {
  const {
    spaceId, projectId, sessionId, canvasId, commentId,
  } = params;

  const parentId = commentId ?
    Array.isArray(commentId) ? commentId[commentId.length - 1] : commentId :
    '_';

  return {
    parentId,
    projectId,
    sessionId,
    canvasId,
    spaceId,
  };
};
