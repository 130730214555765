import { CommentIconButton, CountText, IconContainer } from './index';
import { motion, useAnimation } from 'framer-motion';
import React, { useCallback } from 'react';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import type { Likes } from '../../../@shared/schema/src';

const hover = {
  scale: 1.05,
};
interface VotingProps {
  disabled?: boolean;
  likes: Likes;
  onVoteDown: () => void;
  onVoteUp: () => void;
}
const transition = {
  duration: 0.2,
};
const scale = 1.2;
export const Voting = ({
  disabled = false,
  likes,
  onVoteDown,
  onVoteUp,
}: VotingProps) => {
  const animateUp = useAnimation();
  const animateDown = useAnimation();

  const handleDownClick = useCallback(async (event: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    animateDown.start({
      y: [10, 0,],
      scale,
      transition,
    });
    onVoteDown?.();
    event.stopPropagation();
  }, [animateDown, disabled, onVoteDown,]);

  const handleUpClick = useCallback(async (event: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    animateUp.start({
      y: [-10, 0,],
      scale,
      transition,
    });
    onVoteUp?.();
    event.stopPropagation();
  }, [animateUp, disabled, onVoteUp,]);

  return <IconContainer>
    <motion.span
      onClick={handleUpClick}
      whileHover={hover}
      animate={{
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <CommentIconButton>
        <motion.div animate={animateUp}>
          <ThumbUpIcon fontSize='small' />
        </motion.div>
        <CountText>{likes?.up ?? 0}</CountText>
      </CommentIconButton>
    </motion.span>
    <motion.span onClick={handleDownClick}
      whileHover={hover}
    >
      <CommentIconButton>
        <motion.div animate={animateDown}>
          <ThumbDownIcon fontSize='small' />
        </motion.div>
        <CountText>{likes?.down ?? 0}</CountText>
      </CommentIconButton>
    </motion.span>
  </IconContainer>;
};
