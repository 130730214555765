import { CommentFeed3D } from './CommentFeed3D';
import { CommentThreadFeed } from './CommentThreadFeed';
import type { Canvas } from '@shared/schema/src';
import type { CommentFeedProps } from '.';
import type { WithId } from '@mindhiveoy/schema';

interface CommentFeedPresentationProps {
  canvas?: WithId<Canvas>;
  data: CommentFeedProps;
}

export const CommentFeedPresentation = ({
  canvas,
  data,
}: CommentFeedPresentationProps) => {
  switch (data?.mode) {
    case '3d':
      return <CommentFeed3D />;

    case 'feed':
    default:
      return <CommentThreadFeed canvas={canvas} />;
  }
};
