import MenuItem from '@mui/material/MenuItem';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

import { Typography } from '@mui/material';
import { renderLabel } from '../utils/labelUtils';
import PropertyEditorControl from '../PropertyEditorControl';
import Select from '@mui/material/Select';
import styled from '@emotion/styled';
import theme from 'theme';
import type { PickerPropertyEditor } from '../PropertyEditorTypes';
import type { SelectChangeEvent } from '@mui/material/Select';

const StyledLabel = styled.label`
  color: ${theme.palette.grey[500]};
  padding-left: ${theme.spacing(1)};
  margin-left: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(-0.5)};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
`;

const PickerEditor: PropertyEditorFunction<string, PickerPropertyEditor<string>> = ({
  data,
  propertyName,
  disabled,
  propertyConfig: {
    displayName,
    defaultValue,
    options,
    section,
    testId = propertyName,
  },
  onSave,
}) => {
  const [value, setValue,] = useState(data ?? defaultValue);

  useEffect(() => {
    if (data && data !== value) {
      setValue(data);
    }
  }, [data, value,]);

  const visibleOptions = useMemo(
    () => options
      // Hidden options are only shown if they are the current value
      .filter((option) => !option.hide || option.value === data)
      .map(({
        value, label,
      }) => ({
        value,
        label: renderLabel(label, value),
      })),
    [options, data,]
  );

  const handleChange = useCallback((event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (newValue !== data && onSave) {
      onSave(propertyName, newValue);
    }
    event.stopPropagation();
    event.preventDefault();
  }, [data, onSave, propertyName,]);

  const id = useMemo(() => `editor-${propertyName}`,
    [propertyName,]
  );

  return <>
    {section &&
      <>
        <Typography component={'span'}
          variant="h4"
          id={`${id}-label`}>{section}
        </Typography>
        <br />
      </>
    }<PropertyEditorControl>

      <StyledLabel id={`${id}-label`}>{displayName}</StyledLabel>
      <Select
        labelId={`${id}-label`}
        id={`${id}-helper`}
        value={value}
        label={displayName}
        disabled={disabled}
        onChange={handleChange}
        data-testid={testId}
        style={{
          margin: 0,
        }}
        MenuProps={{
          sx: {
            zIndex: 99999,
          },
          PaperProps: {
            sx: {
              backgroundColor: theme.palette.background.paperDropdown,
            },
          },
        }}
      >
        {visibleOptions.map(({
          value, label,
        }, index) =>
          <MenuItem
            key={index}
            // style={{
            //   paddingTop: 0,
            //   paddingBottom: 0,
            // }}
            value={value}>
            {label}
          </MenuItem>
        )}
      </Select>
    </PropertyEditorControl>
  </>;
};

PickerEditor.config = {
  type: 'picker',
  options: [],
};

export default PickerEditor;
