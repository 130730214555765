import type { Comment, LikeVote, VoteNature } from '../..';
import type { MemberId } from '@mindhiveoy/schema';

/**
 * Alter member vote for a single comment.
 * @param {Comment<unknown>} comment   A comment to be altered
 * @param {MemberId}         memberId  The member's id whom is altering the vote.
 * @param {VoteNature}       vote      The members vote nature
 * @return  {Pick<Comment, 'likes' | 'votes'>}
 *                                     The current or updated likes and votes will be returned.
 */
const alterVoteForComment = (
  comment: Comment<unknown>,
  memberId: MemberId,
  vote: VoteNature
): false | Pick<Comment, 'likes' | 'votes'> => {
  // TODO Move vote logic to shared
  const memberVoteIndex = comment.votes?.findIndex((v) => v.id === memberId);

  let likeVote: LikeVote | undefined;

  const votes = comment.votes ?? [];
  const likes = comment.likes ?? {
    down: 0,
    up: 0,
  };

  if (memberVoteIndex !== undefined && memberVoteIndex >= 0) {
    likeVote = votes[memberVoteIndex];
    if (likeVote.vote === vote[0]) {
      if (likeVote.vote === 'd') {
        likes.down--;
      } else {
        likes.up--;
      }

      votes.splice(memberVoteIndex, 1);
      return {
        likes,
        votes,
      };
    }
    if (likeVote.vote === 'd') {
      likes.down--;
    } else {
      likes.up--;
    }
    votes.splice(memberVoteIndex, 1);
  }
  likes[vote]++;

  votes.push({
    id: memberId,
    vote: vote === 'down' ? 'd' : 'u',
  });

  return {
    likes,
    votes,
  };
};

export default alterVoteForComment;
