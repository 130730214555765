import { startWithCapital } from '@mindhiveoy/foundation';
import { useCallback, useMemo } from 'react';
import CropDinIcon from '@mui/icons-material/CropDin';
import styled from '@emotion/styled';
import type { BuilderComponentType } from './PageWidgetRenderer';
import type { SelectComponentEvent } from './SelectComponentDialog';

interface SelectComponentButtonProps {
  /**
   * The builder component type
   */
  type: BuilderComponentType;
  /**
   * Icon to be displayed for the component type.
   */
  icon?: React.ReactNode;
  /**
   * Name of the component type displayed for the end user.
   */
  displayName: string;
  /**
   * Triggered when user choose the component type.
   */
  onSelect: SelectComponentEvent;
}

const SelectButton = styled.div`
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  width: 20%;
  max-width: 20%;
  text-align: center;
  position: relative;

  span {
    padding: 12px;
    font-size: 0.75rem;
  }
`;

/**
 * A presentation of a single selectable component type in component selector.
 * @param {SelectComponentButtonProps} props
 * @return  {JSX.Element}
 */
const SelectComponentButton = ({
  displayName,
  type,
  icon,
  onSelect,
}: SelectComponentButtonProps) => {
  const text = useMemo(() => {
    return displayName ?? startWithCapital(type);
  }, [displayName, type,]);

  const handleSelectClick = useCallback((event: React.MouseEvent) => {
    onSelect?.(type);
    event.stopPropagation();
  }, [onSelect, type,]);

  return (
    <SelectButton
      onClick={handleSelectClick}
    >
      {icon ?? <CropDinIcon />}
      <br />
      <span>{text}</span>
    </SelectButton>
  );
};

export default SelectComponentButton;
