import { UserRoleContextId } from '..';
import type { CommonMessage, Media } from '../common';
import type {
  Email, MemberId, PhoneNumber, UTCTime, UserDisplayName
} from '@mindhiveoy/schema';
import type { ProjectParams, SpaceParams } from './../pathParams';

export const DEFAULT_INVITATION_EXPIRATION_TIME = 60 * 60 * 24 * 5; // 5 days

export interface MemberInvitation<ShortUserRoleId> {
  /**
   * User email used for the invitation.
   */
  email: Email;
  /**
   * Media info used in different phases to decorate the flow.
   */
  media?: Media;
  /**
   * User's name
   */
  displayName?: UserDisplayName;
  /**
   * User's phone number
   */
  phoneNumber?: PhoneNumber;
  /**
   * Allowed role for user after accepting the invitation
   */
  role: ShortUserRoleId;
  /**
   * Server time stamp to define the creation time of the invitation. Platform
   * may erase the invitation by its own rules in a certain time based on this
   * info.
   */
  created?: UTCTime;
  /**
   * Indicates if the user exists already with given email address.
   */
  isPlatformUser?: boolean;
  /**
   * Message content to be sent via email and/or notification.
   */
  message: CommonMessage;
}

/**
 * Different statuses for the invitation
 */
export enum InvitationStatus {
  /**
   * User is successfully invited to the system
   */
  INVITED = 'invited',
  /**
   * User has accepted the invitation
   */
  ACCEPTED = 'accepted',
  /**
   * User has denied the invitation
   */
  DENIED = 'denied',
  /**
   * Error has happened when sending the invitation to the user
   */
  ERROR = 'error',
  /**
   * User has been invited to the platform but user has explicitly rejected the invitation.
   */
  REJECTED = 'r'
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any

interface InvitationBase<ShortUserRoleId extends string, Params, ContextId = UserRoleContextId> extends MemberInvitation<ShortUserRoleId> {
  /**
   * Context id for users custom claims
   */
  contextId: ContextId;
  /**
   * Path where to route user after the invitation flow
   */
  params: Params;

  platformName: string;

  /**
   * Possible memberId if the user already exists inside the organization
   */
  memberId?: MemberId;

  /**
   * Status of the invitation is initialized as "invited".
   * When user accepts it becomes "accepted" or if user denies the invitation becomes "declined".
   */
  status: InvitationStatus;
  /**
   * The creation time of the invitation as UTC Time in milliseconds.
   */
  created: UTCTime
  /**
   * The duration in millisecond from creation time, for what the invitation is valid. The default value
   * is 7 days.
   */
  expires?: number;
  /**
   * If error occurs when sending the invitation this field is utilized
   */
  error?: Error;
}

export type PlatformInvitation<ShortUserRoleId extends string> = InvitationBase<ShortUserRoleId, unknown, 'platform'>

export interface SpaceInvitation<ShortUserRoleId extends string> extends InvitationBase<ShortUserRoleId, SpaceParams, 'space'> {

  /**
   * Space name
   */
  spaceName: string;

}
export interface ProjectInvitation<ShortUserRoleId extends string> extends InvitationBase<ShortUserRoleId, ProjectParams, 'project'> {

  /**
 * Space name
 */
  spaceName: string;

  /**
 * Project name
 */
  projectName: string;

}

export type Invitation<ShortUserRoleId extends string> =
  PlatformInvitation<ShortUserRoleId> |
  SpaceInvitation<ShortUserRoleId> |
  ProjectInvitation<ShortUserRoleId>;
