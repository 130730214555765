/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @next/next/no-img-element */
import { uniqueId } from 'lodash';
import { uploadPhoto } from 'bloc/admin/member/membersApi';
import { useAuth } from '@mindhiveoy/react-auth';
import { useTranslation } from 'next-i18next';
import type { ImagePropertyEditor } from '../PropertyEditorTypes';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';
import type { URL } from '@mindhiveoy/schema';

import { MediaContentDialogDialog } from 'components/forms/MediaFormField/MediaContentDialog';
import { extractMediaAuthorInfoFromUnsplash } from 'components/forms/MediaFormField/MediaCopyright/UnsplashCopyright';
import { randomUnsplashPhoto } from 'bloc/media/MediaBloc';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormElement from '../../../forms/FormElement';
import ImageSelector from 'components/admin/MemberAdmin/ImageSelector';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import usePathParams from 'utils/hooks/usePathParams';
import type { KeyboardEventHandler } from 'react';
import type { Media } from '@shared/schema/src';

const ImagePropertyEditorComponent: PropertyEditorFunction<Media, ImagePropertyEditor> = ({
  data,
  propertyConfig: {
    displayName,
    defaultValue,
    // showPreview = true,
  },
  propertyName,
  onSave,
}) => {
  const media = data ?? defaultValue;

  const [image, setImage,] = useState<URL | undefined>(data?.photoURL);
  const ref = useRef<HTMLInputElement>(null);
  const [editing, setEditing,] = useState(false);

  const {
    t,
  } = useTranslation();

  const params = usePathParams();
  const {
    memberId,
  } = useAuth();
  // const [saving, setSaving,] = useState(false);

  useEffect(() => {
    if (editing) {
      return;
    }
    if (data && data.photoURL !== image) {
      setImage(data.photoURL);
    }
  }, [data, editing, image,]);

  const handleSave = useCallback(() => {
    const newValue = image?.trim();

    if (newValue !== data?.photoURL && onSave) {
      const media = {
        ...data,
        type: 'photo',
        photoURL: newValue,
      } as Media;
      delete media.author;
      delete media.authorUrl;
      delete media.source;
      delete media.sourceUrl;

      onSave(propertyName, media);
    }
  }, [data, image, onSave, propertyName,]);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback((event) => {
    const value = event.target.value;
    setImage(value);
    event?.stopPropagation();
  }, []);

  const handleFocus = useCallback((event: any) => {
    event.target.select();
    setEditing(true);
  }, []);

  const handleBlur = useCallback(() => {
    setEditing(false);
    handleSave();
  }, [handleSave,]);

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      handleSave();
    }
  }, [handleSave,]);

  const handleRandomImageClick = useCallback(async () => {
    try {
      const photo = await randomUnsplashPhoto({
        spaceId: params!.spaceId,
        projectId: params!.projectId,
      });
      const authorInfo = extractMediaAuthorInfoFromUnsplash(photo);
      const newMedia: Media = {
        ...media,
        ...authorInfo,
        photoURL: photo.urls.regular,
        type: 'photo',
      };

      onSave(propertyName, newMedia);
    } catch (error) {
      console.error(error);
    }
  }, [media, onSave, params, propertyName,]);

  const handleNewImage = useCallback(async (newImage: URL) => {
    // TODO: We need a cleaning system to erase assets that have no reference anymore. This should
    // be done in the backend, but we need to be able to trigger it from the frontend.
    // setSaving(true);
    // try {
    const response = await uploadPhoto({
      contextId: 'project',
      params: {
        projectId: params!.projectId,
        spaceId: params!.spaceId!,
      },
      photoType: 'media',
      memberId: memberId!,
      imageData: newImage,
      assetPath: uniqueId('image_'), // TODO: Better system for image ids
    });

    if (response.status === 'ok') {
      onSave(propertyName, response.imageUrl);
    }
    // } finally {
    //   setSaving(false);
    // }
  }, [memberId, onSave, params, propertyName,]);

  const [showUnsplashDialog, setShowUnsplashDialog,] = useState<boolean>(false);

  const handleMediaSelect = useCallback(async (item: Partial<Media>) => {
    const newMedia = {
      ...media,
      ...item,
    } as Media;

    onSave(propertyName, newMedia);

    setShowUnsplashDialog(false);
  }, [media, onSave, propertyName,]);

  const handleUnsplashDialogClose = useCallback(() => {
    setShowUnsplashDialog(false);
  }, []);

  const handleSearchUnsplashClick = useCallback(() => {
    setShowUnsplashDialog(true);
  }, []);

  return <FormControl fullWidth>
    <FormElement withBottomMargin>
      <TextField
        ref={ref}
        variant="outlined"
        placeholder={propertyName}
        label={displayName ?? propertyName}
        id={propertyName}
        value={image}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        fullWidth
        InputLabelProps={{
          shrink: image ? true : undefined, // undefined is needed for material ui to take control
        }}
      />
    </FormElement>
    <FormElement withBottomMargin>
      <Button
        onClick={handleSearchUnsplashClick}
        variant="contained">{t('Search media content')}...</Button>
    </FormElement>
    <FormElement withBottomMargin>
      <Button
        variant="outlined"
        onClick={handleRandomImageClick}>{t('random-image')}
      </Button>
    </FormElement>
    <ImageSelector
      authorInfo={data}
      imageUrl={image!}
      disabled={false}
      onNewImage={handleNewImage}
    />
    <MediaContentDialogDialog
      mode="photo"
      open={showUnsplashDialog}
      onSelect={handleMediaSelect}
      onCancel={handleUnsplashDialogClose}
    />
  </FormControl>;
};

ImagePropertyEditorComponent.config = {
  type: 'image',
  showPreview: false,
};

export default ImagePropertyEditorComponent;
