import { AutoMargin } from './AutoMargin';
import { Html } from '../../../../common/Html';
import { mapVerticalAlign } from '../../editors/mapVerticalAlign';
import { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import theme from 'theme';
import type { CSSProperties } from 'react';
import type { FreeTextWidget } from '@shared/schema/src/spaces/projects/sessions/canvases';

interface ContentProps {
  data: FreeTextWidget;
}
export const Content = ({
  data: {
    body, title, subTitle, textAlign, verticalAlign,
  },
}: ContentProps) => {
  const verticalStyle = useMemo(() => {
    const result: CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: mapVerticalAlign(verticalAlign),
      position: 'relative',
      width: '100%',
      height: '100%',
      padding: `${theme.spacing(2)}`,
    };
    return result;
  }, [verticalAlign,]);

  const style = useMemo(() => {
    const result: CSSProperties = {
      textAlign: textAlign as any,
      // TODO: mapping
    };
    return result;
  }, [textAlign,]);

  return <AutoMargin>
    <div style={verticalStyle}>
      <Typography
        component={'span'}
        style={style}
        variant="h1"
      >
        <Html>{title}</Html>
      </Typography>
      <Typography
        component={'span'}
        style={style}
        variant="caption"
      >
        <Html>{subTitle}</Html>
      </Typography>
      <Typography
        component={'span'}
        style={style}
        variant="body1"
      >
        <Html>{body}</Html>
      </Typography>
    </div>
  </AutoMargin>;
};
