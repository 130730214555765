import { whereMatch } from './whereMatch';
import type { WhereQueryConstraint } from '../types';

export const matches = (value: any, where: WhereQueryConstraint[]): boolean => {
  for (const rule of where) {
    if (!whereMatch(value, rule)) {
      return false;
    }
  }
  return true;
};
