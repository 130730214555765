import AnalyzeGraph2D from './AnalyzeGraph2D';
import Matrix2DLayout from 'components/canvas/canvasTypes/Matrix2DQuestionCanvas/layouts/Matrix2DLayout';
import React from 'react';
import type { AnalyzeOptions, Matrix2DQuestionCanvas, Session } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

interface AnalyzeMatrixProps {
  canvas: WithId<Matrix2DQuestionCanvas>;
  session?: WithId<Session>;
  analyzeOptions?: AnalyzeOptions;
}
export const AnalyzeMatrix2D = ({
  analyzeOptions,
  session,
  canvas,
}: AnalyzeMatrixProps) => {
  return <Matrix2DLayout canvas={canvas} >
    <AnalyzeGraph2D
      analyzeOptions={analyzeOptions}
      session={session}
      canvas={canvas}
    />
  </Matrix2DLayout>;
};
