
import { motion } from 'framer-motion';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from '@mui/icons-material/HighlightOff';
import type { BuilderComponentPropsBaseWithId } from './PageWidgetRenderer';
import type { Variants } from 'framer-motion';

import React, { useCallback, useState } from 'react';
import componentBuilder from '../builders/componentBuilder';
import styled from '@emotion/styled';
import type { EditorMode } from 'components/session/SessionEditor/RestrictedSessionEditor/components/toolbar/ModeSwitch';

const WidgetContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;  
  margin: 0 !important;
  padding: 0 !important;
  list-style: none;
  overflow-y: auto;
`;

const StyledControlsTop = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: none;
  pointer-events: none;
  border-style: dashed;
  border-width: 1px;
  margin: -1px;
`;

const StyledControlsBottom = styled(motion.div)`
  position: absolute;
  width: 100%;
  bottom: -12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: none;
  pointer-events: none;
  justify-content: center;
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const ReplaceWidgetTopButton = styled(ControlPointIcon)(
  ({
    theme,
  }) => `
  // background-color: ${theme.palette.background.default};
  // box-shadow: 0px 0px 16px 8px #E8FFF9;
  cursor: pointer;
  pointer-events: all;
  border-radius: 50%;
  margin-top: -12px;
`);

const DeleteWidgetButton = styled(DeleteIcon)(
  ({
    theme,
  }) => `
  // background-color: ${theme.palette.background.default};
  // box-shadow: 0px 0px 16px 8px #E8FFF9;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
  border-bottom-left-radius: 25%;
`);

const widgetVariants: Variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
  exit: {
    opacity: 0,
    rotateX: 90,
    transition: {
      duration: 0.2,
    },
  },
};

interface WidgetProps extends BuilderComponentPropsBaseWithId<object> {
  editMode: EditorMode;
  index: number;
  onClick: (index: number) => void;
  onDelete: (index: number) => void;
}

/**
 * Widget will define a single container element used as a building block on the site.
 * @param {WidgetProps} props Daa
 * @return {JSX.Element} Widget
 */
const LayoutWidgetEditor = ({
  editMode,
  index,
  onClick,
  onDelete,
  ...props
}: WidgetProps) => {
  const {
    id,
  } = props;

  const [hover, setHover,] = useState<string | undefined>();

  const handleClick = useCallback((event: React.MouseEvent) => {
    onClick && onClick(index);
    event.stopPropagation();
  }, [onClick, index,]);

  const handleHoverStart = useCallback(() => {
    setHover(id);
  }, [id,]);

  const handleHoverEnd = useCallback(() => {
    setHover(undefined);
  }, []);

  const handleDeleteClick = useCallback((event: React.MouseEvent) => {
    onDelete && onDelete(index);
    event.stopPropagation();
  }, [onDelete, index,]);

  return <WidgetContainer
    layoutId={id}
    key={id}
    variants={widgetVariants}
    initial="hidden"
    animate="visible"
    exit="exit"
    onClick={handleClick}
    onHoverStart={handleHoverStart}
    onHoverEnd={handleHoverEnd}
    onBlur={handleHoverEnd}
  >
    {editMode !== 'analyze' && <>
      <StyledControlsTop
        key={`wct_${id}`}
        style={{
          opacity: hover === id ? 1 : 0,
        }}
        transition={{
          duration: 0.3,
        }}
        initial="hidden"
      >
        <DeleteWidgetButton onClick={handleDeleteClick} />
      </StyledControlsTop>

      <StyledControlsBottom
        key={`wcb_${id}`}
        style={{
          opacity: hover === id ? 1 : 0,
        }}
        transition={{
          duration: 0.3,
        }}
        exit="hidden"
      >
      </StyledControlsBottom>
    </>
    }
    <StyledContent key={`wcc_${id}`} >
      {componentBuilder.renderEditor(props)}
    </StyledContent>
  </WidgetContainer>;
};

export default LayoutWidgetEditor;
