import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { useComponentDataContext } from 'utils/hooks/useComponentDataContext';
import { useComponentSelectionContext } from 'utils/hooks/useComponentSelectionContext';
import { useTranslation } from 'next-i18next';
import { validatePropertyForm } from 'components/forms/validatePropertyForm';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import LoadingIndicator from 'components/common/LoadingIndicator';
import React from 'react';
import isEqual from 'lodash/isEqual';
import styled from '@emotion/styled';
import type { BuilderConfig } from '../builders/componentBuilder';

const SaveMenu = styled(Fab)(({
  theme,
}) => `
  position: fixed;
  bottom: ${theme.spacing(2)};
  right: ${theme.spacing(2)};
  z-index: 10000;
  padding: ${theme.spacing(2, 4)};
  &.Mui-disabled {
    background-color: ${theme.palette.background.comment}80;
    color: ${theme.palette.primary.contrastText};
  }
`);

export type ContextButtonProps = ContextButtonFloatProps | ContextButtonInlineProps;

export interface ContextButtonFloatProps {
  saving?: boolean;
  label?: string;
  style?: 'floating';
  initialData?: any;
  fullScreenSaving?: boolean;
  onSave: (onSaved: () => void) => void;
}

export interface ContextButtonInlineProps {
  saving?: boolean;
  label?: string;
  initialData?: any;
  fullScreenSaving?: boolean;
  style: 'inline';
  config: BuilderConfig<any>;
  onSave: (onSaved: () => void) => void;
}

const ContextButton = (props: ContextButtonProps) => {
  const {
    label,
    saving,
    style = 'floating',
    initialData,
    onSave,
  } = props;

  const config = 'config' in props ? props.config : undefined;

  const {
    t,
  } = useTranslation();

  const {
    model,
  } = useComponentDataContext();

  const {
    selection,
  } = useComponentSelectionContext();

  const [modified, setModified,] = useState(false);
  const [isValid, setIsValid,] = useState(false);

  const handleSave = useCallback(() => {
    if (selection?.state === 'loading') {
      return;
    }
    const whenReady = () => {
      setModified(false);
    };
    onSave && onSave(whenReady);
  }, [onSave, selection?.state,]);

  useEffect(() =>
    model?.subscribeToDataChange(
      (event) => {
        switch (style) {
          case 'floating':
            setModified(selection?.state !== 'loading' && !!model?.isModified());

            break;
          case 'inline': {
            if (!config) {
              return;
            }
            const result = validatePropertyForm(config, event.newValue?.data);
            if ((isValid || initialData) && isEqual(initialData, event.newValue?.data)) {
              setIsValid(false);
              break;
            }
            setIsValid(result.isValid);
            break;
          }
          default:
        }
      }
    ), [config, handleSave, initialData, isValid, model, selection?.state, style,]
  );

  useEffect(() => {
    /*
     * Support command + save and ctrl + save to save the changes
     */
    const onKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        handleSave();
        event.stopPropagation();
        event.preventDefault();
      }
    };
    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [handleSave,]);

  return <AnimatePresence>
    {
      (style === 'inline' || modified) && <motion.div
        key="save-button"
        style={style === 'floating' ? {
          position: 'fixed',
        } : undefined}
        exit={style === 'floating' ? {
          scale: 0,
          opacity: 0,
          transition: {
            duration: .7,
          },
        } : undefined}
        initial={style === 'floating' ? {
          scale: 0,
          opacity: 0,
        } : undefined}
        animate={style === 'floating' ? {
          scale: 1,
          opacity: 1,
          bottom: 0,
          transition: {
            duration: .7,
          },
        } : undefined}
      >
        {
          style === 'floating' ?
            <SaveMenu
              disabled={saving}
              data-testid="context-button"
              onClick={handleSave}
              color="secondary"
              variant="extended"
              aria-label={t('save-changes')}
            >
              <ButtonContent
                label={label}
                saving={saving} />
            </SaveMenu> :
            <Button
              data-testid="save-button"
              variant="contained"
              style={{
                pointerEvents: 'auto',
              }}
              disabled={saving || !isValid}
              onClick={handleSave}
            >
              <ButtonContent
                label={label}
                saving={saving}
                fullScreenSaving={props?.fullScreenSaving}
              />
            </Button>
        }
      </motion.div>
    }
  </AnimatePresence>;
};

interface ButtonContentProps {
  label?: string;
  saving?: boolean;
  fullScreenSaving?: boolean;
}

const ButtonContent = ({
  label,
  saving,
  // fullScreenSaving = false,
}: ButtonContentProps) => {
  const {
    t,
  } = useTranslation();

  return saving ? <>
    {t('saving')}...
    <LoadingIndicator tiny />
  </> :
    <>
      {label ?? t('Save')}
    </>;
};

export default ContextButton;
