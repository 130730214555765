import { coord2DFromKey } from '../..';
import type { Frequencies, Statistics2D } from '../../spaces/projects/sessions/canvases/canvas';

export const generateDiscreteStatistics2D = (frequencies: Frequencies): Statistics2D => {
  if (Object.keys(frequencies).length === 0) {
    return {
      type: '2d',
      deriveCount: 0,
      count: 0,
      averageX: 0,
      averageY: 0,
      medianX: 0,
      medianY: 0,
      q1X: 0,
      q1Y: 0,
      q3X: 0,
      q3Y: 0,
      stdDeviationX: 0,
      stdDeviationY: 0,
      variationX: 0,
      variationY: 0,
    };
  }
  const matrix: number[][] = [];

  let sumX = 0;
  let sumY = 0;
  let count = 0;
  const deriveCount = 0;
  const arrayX: number[] = [];
  const arrayY: number[] = [];

  Object.entries(frequencies).forEach(([key, frequency,]) => {
    const {
      x, y,
    } = coord2DFromKey(key);

    for (let i = 0; i < frequency; i++) {
      arrayX.push(x);
      arrayY.push(y);
    }
    const row = matrix[y] ?? [];
    row[x] = frequency;
    count += frequency;
    sumX += frequency * x;
    sumY += frequency * y;
    matrix[y] = row;
  });

  arrayX.sort((a, b) => {
    return a - b;
  });
  arrayY.sort((a, b) => {
    return a - b;
  });

  // Average, aka mean
  const averageX = sumX / count;
  const averageY = sumY / count;

  // Quantile
  const quantile = (arr: number[], q: number) => {
    const pos = (arr.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (arr[base + 1] !== undefined) {
      return arr[base] + rest * (arr[base + 1] - arr[base]);
    } else {
      return arr[base];
    }
  };
  const q1X = quantile(arrayX, .25);
  const q1Y = quantile(arrayY, .25);
  const q3X = quantile(arrayX, .75);
  const q3Y = quantile(arrayY, .75);

  // Median
  const medianX = quantile(arrayX, .50);
  const medianY = quantile(arrayY, .50);

  // Standard Deviation
  const std = (mean: number, arr: number[]) => {
    const diffArr = arr.map((a) => (a - mean) ** 2);
    return Math.sqrt(diffArr.reduce((a, b) => a + b, 0) / (arr.length - 1));
  };
  const stdDeviationX = Object.keys(frequencies).length <= 1 ? 0 : std(averageX, arrayX);
  const stdDeviationY = Object.keys(frequencies).length <= 1 ? 0 : std(averageY, arrayY);

  // Variance
  const calculateVariance = (values: number[]): number => {
    const average = values.reduce((sum, current) => sum + current) / values.length;
    const squareDiffs = values.map((value: number): number => {
      const diff = value - average;
      return diff * diff;
    });

    return squareDiffs.reduce((sum, current) => sum + current) / squareDiffs.length;
  };

  const variationX = calculateVariance(arrayX);
  const variationY = calculateVariance(arrayY);

  return {
    type: '2d',
    deriveCount,
    count,
    averageX,
    averageY,
    medianX,
    medianY,
    q1X,
    q1Y,
    q3X,
    q3Y,
    stdDeviationX,
    stdDeviationY,
    variationX,
    variationY,
  };
};
