/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { isBrowser, motion, useAnimation } from 'framer-motion';
import {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import CardStripItem from './CardStripItem';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import theme from 'theme';
import type { Media } from '@shared/schema/src';
import type { MutableRefObject } from 'react';
import type { Transition } from 'framer-motion';

// border radius needs to be a bit bigger than in theme because
// of the scaling
export const selectedStyle = {
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: theme.palette.primary.main,
  transform: 'scale(1.04)',
  zIndex: 100,
  borderRadius: '14px',
};

export const Card = styled$(motion.div)<{
  $cardWidth: number;
  $cardHeight: number;
}>(({
  $cardWidth,
  $cardHeight,
  theme,
}) => `
  position: relative;
  display: flex;
  flex-direction: column;
  // margin: 16px;
  max-width: ${$cardWidth}px;
  width: ${$cardWidth}px;
  height: ${$cardHeight}px;
  border-radius: ${theme.shape.borderRadius}px;
  justify-content: flex-end;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;
`);

export const Reflection = styled(motion.div)(({
  theme,
}) => `
  border-radius: ${theme.shape.borderRadius}px;
  --mask-height: 80px;
  margin: 4px;
  padding: 0;
  max-height: var(--mask-height);
  
  filter: blur(2px);
  /* scroll bar width, for use in mask calculations */
  --scrollbar-width: 0px;

  /* mask fade distance, for use in mask calculations */

  /* If content exceeds height of container, overflow! */
  overflow-y: hidden;

  /* Our height limit */
  height: var(--mask-height);

  /* The CSS mask */

  /* The content mask is a linear gradient from top to bottom */
  --mask-image-content: linear-gradient(
    to top,
    transparent,
    black var(--mask-height),
    black calc(100% - var(--mask-height)),
    transparent
  );

  /* Here we scale the content gradient to the width of the container 
  minus the scrollbar width. The height is the full container height */
  /* --mask-size-content: calc(100% - var(--scrollbar-width)) 100%; */
  --mask-size-content: 100%;

  /* The scrollbar mask is a black pixel */
  --mask-image-scrollbar: linear-gradient(black, black);

  /* The width of our black pixel is the width of the scrollbar.
  The height is the full container height */
  --mask-size-scrollbar: var(--scrollbar-width) 100%;

  /* Apply the mask image and mask size variables */
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

  /* Position the content gradient in the top left, and the 
  scroll gradient in the top right */
  mask-position: 0 0, 100% 0;

  /* We don't repeat our mask images */
  mask-repeat: no-repeat, no-repeat;
`);

export const Column = styled(motion.div)`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const BackgroundFade = styled.div`
  position: absolute;
  width: 100%;
  height: 60%;
  bottom: 0;
  pointer-events: none;
  left: 0;
  /* z-index: 1; */
  background-color: black;
  /* background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 100%); */
  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0) 100%);
`;

export const TextArea = styled$.div(({
  theme,
}) => `
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${theme.spacing(0, 2)};
`);

// custom styling if used in canvas
export const Title = styled$.h2<{
  $canvasStrip?: boolean;
}>(({
  $canvasStrip,
}) => `
  font-size: ${$canvasStrip ? '0.8rem' : '1.2rem'};
  display: block;
  word-wrap: none;
  max-height: 3.4rem;
  color: rgba(255,255,255,0.90);
  line-height: ${$canvasStrip ? '1.1rem' : '1.5rem'};
  margin: 0;
  width: ${$canvasStrip ? '100%' : 'calc(100% - 16px)'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  user-select: none;
`);

export const SubTitle = styled.h3<{
  $canvasStrip?: boolean;
}>(({
  $canvasStrip,
}) => `
  font-size: 0.75rem;
  font-weight: 200;
  line-height: 0.8rem;
  color: rgba(255,255,255,0.90);
  width: calc(100% - 16px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: ${$canvasStrip ? '12px' : '4px'};
  user-select: none;
;`);

export const selectedScale = 1.10;

export interface CardData {
  _id: string;
  title: string;
  subTitle?: string;
  image?: string;
  media?: Media;
}

const Strip = styled$(motion.div)<{
  // $cardHeight?: number
  $isBottomDrawer?: boolean;
}>(({
  theme,
  // $cardHeight = 200,
  $isBottomDrawer,
}) => `
  position: relative;
  display: flex;
  gap: ${theme.spacing(2)};
  flex-direction: row;
  align-items: flex-start;
  padding-top: ${theme.spacing($isBottomDrawer ? 4 : 2)};
  overflow-x: auto;
  max-width: 100%;
  box-sizing: border-box;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`);
// padding-top: ${$isBottomDrawer ? '22px' : $cardHeight < 350 ? '24px' : '55px'};

const StyledButton = styled$(motion.button)<{
  $offsetTop: number,
  $leftPosition?: boolean;
}>(({
  $offsetTop,
  $leftPosition,
}) => `
  border-radius: 50%;
  background-color: transparent;
  border: none;
  color: white;
  position: absolute;
  top: ${$offsetTop / 2.8}px;
  left: auto;
  ${$leftPosition ? 'left: 0;' : 'right: 0;'}
  cursor: pointer;
  z-index: 601;
  &:hover {
    background-color: #ffffff1F;
  }
`);

const ScrollRightArrow = styled$(ChevronRight)<{
  $arrowSize?: number,
  $arrowHeight?: number,
}>(({
  $arrowSize,
}) => `
  position: relative;
  font-size:${$arrowSize}px;
  color: #fff;
  opacity: 1;
  filter: drop-shadow(-2px 0 1px rgba(0, 0, 0, 0.4));
  z-index: 610;
  cursor: pointer;
`);

const ScrollLeftArrow = styled$(ChevronLeft)<{
  $arrowSize?: number,
  $arrowHeight?: number,
}>(({
  $arrowSize,
}) => `
  position: relative;
  font-size:${$arrowSize}px;
  color: #fff;
  opacity: 1;
  filter: drop-shadow(2px 0 1px rgba(0, 0, 0, 0.4));
  z-index: 610;
  cursor: pointer;
`);

const Container = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
`;

type CardClickEvent = (cardId: string) => void;

export type CardStripMode = 'selection' | 'plain';

export interface RenderItemEventArgs<T extends CardData> {
  card: T;
  cardHeight?: number;
  cardWidth?: number;
  menu: true;
  mode?: CardStripMode;
  reflection?: boolean;
  selected?: boolean;
  showAnswerIcon?: boolean;
  onClick?: CardClickEvent;
  onEdit?: CardClickEvent;
  onArchive?: CardClickEvent;
  onDelete?: CardClickEvent;
}
export type RenderItemEvent = <T extends CardData>(
  args: RenderItemEventArgs<T>
) => JSX.Element;

export interface CardStripeProps<T extends CardData> {
  /**
   * An array of components
   */
  cards: T[];
  /**
   * The width of card in pixels
   */
  cardWidth?: number;
  /**
   * The height of card in pixels
   */
  cardHeight?: number;
  /**
   * The id string of selected card.
   */
  selected?: string;
  /**
   * Show the reflection of the card below
   */
  reflection?: boolean;
  /**
   * Mode of the card strip. Default mode is 'selection'.
   *
   * selection   Cards can be selected and focused to the middle of the component area.
   * plain       Cards are just simply listed with no selection logic.
   */
  mode?: CardStripMode;
  /**
  * The size of arrows in pixels
  */
  arrowSize?: number;

  hideArrows?: boolean;
  /**
   * The height of arrows in pixels
   */
  arrowHeight?: number;

  /**
   * True if the strip should center the viewable card. Default is false.
   */
  centerOnDefault?: boolean;
  isBottomDrawer?: boolean;
  isDrawerOpen?: boolean;
  menu?: boolean;
  onRenderItem?: RenderItemEvent;
  onCardClick?: CardClickEvent;
  onNewProjectClick?: CardClickEvent;
  onSettingsClick?: CardClickEvent;
  onArchiveClick?: CardClickEvent;
  onDeleteClick?: CardClickEvent;
  onClick?: CardClickEvent;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
}

const calculateHorizontalPanning = <T extends CardData>({
  cards,
  cardWidth = 200,
  mode,
  ref,
}: Pick<CardStripeProps<T>, 'cardWidth' | 'cards' | 'mode'> & {
  ref: MutableRefObject<HTMLDivElement | null>;
}) => {
  if (!isBrowser) {
    return 0;
  }
  const stripWidth = cards.length /* + 1*/ * cardWidth; // If the create new card is still will be needed

  const width = ref.current?.clientWidth ?? window.innerWidth;

  if (width < stripWidth) {
    return mode === 'selection' ? (width - cardWidth) / 2 : 0;
  }
  return mode === 'selection' ? (width - cardWidth) / 2 : (width - stripWidth) / 2;
};

/**
 * Scroll direction for manual scrolling
 */
enum ScrollDirection {
  Left, Right
}
/**
 * The percentage of the screen to scroll when using manual scrolling
 */
const SCROLL_PERCENTAGE = 25;

/**
 * A horizontal strip of cards that will scroll to center the selected card.
 *
 * @param {CardStripeProps} props
 * @return {JSX.Element} Card strip component
 */
const CardStrip = <T extends CardData>({
  menu,
  centerOnDefault = false,
  cards,
  cardWidth = 256,
  cardHeight = 160,
  mode = 'selection',
  reflection,
  selected,
  arrowHeight = 250,
  arrowSize = 60,
  isBottomDrawer,
  isDrawerOpen,
  hideArrows,
  onCardClick,
  // onNewProjectClick,
  onSettingsClick,
  onArchiveClick,
  onDeleteClick,
  onRenderItem,
  onPreviousClick,
  onNextClick,
}: CardStripeProps<T>) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [selection, setSelection,] = useState(selected);
  const [canScroll, setCanScroll,] = useState<boolean>(false);
  const [maxScrollAmount, setMaxScrollAmount,] = useState<number>(0);
  const [currentScrollAmount, setCurrentScrollAmount,] = useState<number>(0);

  const [horizontalPanning, setHorizontalPanning,] = useState(calculateHorizontalPanning({
    cards,
    ref,
    cardWidth,
    mode,
  }));

  const controlsRight = useAnimation();
  const controlsLeft = useAnimation();

  /**
   * Returns a divider factor based on screen size
   * for the landing page strip cards to scroll
   * to a more centered position
   */
  const getScreenSize = useCallback((): number => {
    const isWindow = typeof window !== 'undefined';
    if (!isWindow) {
      return 30;
    }
    const {
      innerWidth: width,
    } = window;
    if (width <= 600) {
      return 30;
    }
    if (width >= 601 && width <= 1200) {
      return 4.5;
    }
    return 3.5;
  }, []);

  const screenSizeFactor = getScreenSize();

  const handleClick = useCallback((id: string) => {
    setSelection(id);
    onCardClick && onCardClick(id);
  }, [onCardClick,]);

  const handleEditClick = useCallback((id: string) => {
    setSelection(id);
    onSettingsClick && onSettingsClick(id);
  }, [onSettingsClick,]);

  const handleDeleteClick = useCallback((id: string) => {
    setSelection(id);
    onDeleteClick && onDeleteClick(id);
  }, [onDeleteClick,]);

  const handleArchiveClick = useCallback((id: string) => {
    setSelection(id);
    onArchiveClick && onArchiveClick(id);
  }, [onArchiveClick,]);

  useEffect(() => {
    setHorizontalPanning(calculateHorizontalPanning({
      cards,
      cardWidth,
      ref,
      mode,
    }));
  }, [cards, cardWidth, ref, mode,]);

  useEffect(() => {
    if (mode === 'selection') {
      setSelection(selected);
    }
  }, [cards, mode, selected,]);

  const scrollToSelection = useCallback(() => {
    if (mode === 'plain') {
      return;
    }

    if (centerOnDefault && !selection) {
      const left = cards.length * cardWidth / (screenSizeFactor ?? 1);
      ref.current?.scrollTo({
        left,
        behavior: 'smooth',
      });
    }
    const index = cards.findIndex((c) => c._id === selection);
    if (index >= 0) {
      const left = index * cardWidth; // TODO variable

      ref.current?.scrollTo({
        left,
        behavior: 'smooth',
      });
    }
  }, [cardWidth, cards, mode, selection, centerOnDefault, screenSizeFactor,]);

  useEffect(() => {
    scrollToSelection();
  }, [cards, scrollToSelection, selection,]);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    const onWindowResize = () => {
      setHorizontalPanning(calculateHorizontalPanning({
        cards,
        cardWidth,
        ref,
        mode,
      }));
      scrollToSelection();
    };
    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  }, [cardWidth, cards, mode, scrollToSelection,]);

  useEffect(() => {
    if (ref.current) {
      const isScrollable = ref.current.scrollWidth > ref.current.clientWidth;
      setCanScroll(isScrollable);
      setMaxScrollAmount(isScrollable ? ref.current.scrollWidth - ref.current.clientWidth : ref.current.scrollWidth);
    }
  }, [ref.current?.scrollWidth, ref.current?.clientWidth,]);

  const handleScroll = useCallback(() => {
    setCurrentScrollAmount(ref.current?.scrollLeft!);
  }, []);

  const handleManualScroll = useCallback((direction: ScrollDirection) => {
    if (canScroll && ref.current) {
      const percentage = SCROLL_PERCENTAGE * (direction === ScrollDirection.Left ? -1 : 1) / 100;
      const left = ref.current?.scrollLeft + maxScrollAmount * percentage;
      ref.current?.scrollTo({
        left,
        behavior: 'smooth',
      });
    }
  }, [canScroll, maxScrollAmount,]);

  // handlers for scroll arrows. If no function
  // is provided as a prop, we use the default manual scrolling
  const handlePreviousOnClick = useCallback(() => {
    onPreviousClick ? onPreviousClick() : handleManualScroll(ScrollDirection.Left);
  }, [onPreviousClick, handleManualScroll,]);

  const handleNextOnClick = useCallback(() => {
    onNextClick ? onNextClick() : handleManualScroll(ScrollDirection.Right);
  }, [onNextClick, handleManualScroll,]);

  /* Show scroll arrow animation when drawer is opened */
  useEffect(() => {
    controlsRight.start({
      x: [10, 0,],
      opacity: [1, 0.9,],
    });
    controlsLeft.start({
      x: [0, 10,],
      opacity: [1, 0.9,],
    });
  }, [controlsLeft, controlsRight, isDrawerOpen,]);

  const transition: Transition = useMemo(() => ({
    repeat: isBottomDrawer ? 4 : Infinity,
    repeatType: 'mirror',
  }), [isBottomDrawer,]);

  return <Container>
    {canScroll && currentScrollAmount > 0 && !hideArrows &&
      <StyledButton
        $leftPosition
        $offsetTop={ref.current?.offsetHeight!}
        animate={controlsLeft}
        transition={{
          repeat: isBottomDrawer ? 4 : Infinity,
          repeatType: 'mirror',
        }}>
        <ScrollLeftArrow
          $arrowSize={arrowSize}
          $arrowHeight={arrowHeight}
          onClick={handlePreviousOnClick}
        />
      </StyledButton>
    }
    <Strip
      $isBottomDrawer={isBottomDrawer}
      ref={ref}
      onScroll={handleScroll}
      style={{
        paddingLeft: horizontalPanning,
        paddingRight: mode === 'selection' ? horizontalPanning : 0,
      }}>

      {cards && onRenderItem ?
        cards.map((card) => onRenderItem({
          card,
          cardHeight,
          cardWidth,
          menu: true,
          mode,
          reflection,
          selected: mode === 'selection' && card._id === selection,
          onClick: handleClick,
          onEdit: handleEditClick,
          onDelete: handleDeleteClick,
          onArchive: handleArchiveClick,
        })) :
        cards.map((card) => {
          return <CardStripItem
            key={card._id}
            cardWidth={cardWidth}
            cardHeight={cardHeight}
            card={card}
            mode={mode}
            selected={mode === 'selection' && card._id === selection}
            reflection={reflection}
            onClick={handleClick}
            onEdit={handleEditClick}
            onDelete={handleDeleteClick}
            onArchive={handleArchiveClick}
            menu={menu}
          />;
        }
        )}
    </Strip>
    {canScroll && currentScrollAmount < maxScrollAmount && !hideArrows &&
      <StyledButton
        $offsetTop={ref.current?.offsetHeight!}
        animate={controlsRight}
        transition={transition}>
        <ScrollRightArrow
          $arrowSize={arrowSize}
          $arrowHeight={arrowHeight}
          onClick={handleNextOnClick}
        />
      </StyledButton>

    }
  </Container>;
};

export default CardStrip;
