/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */
import { SliderContainer, SliderLayout } from 'components/canvas/canvasTypes/Matrix2DQuestionCanvas/layouts/SlidersLayout';
import { editorTypographyStyleCentered } from '../Matrix2DCanvasEditor';
import { resolveEditorMode } from 'utils/urls';
import { useComponentDataContext } from '../../../../utils/hooks/useComponentDataContext';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Center from '../../../common/layout/Center';
import HTMLInplaceEditor, { ALL_STYLES_ALLOWED, EMPHASIS_STYLES_ALLOWED } from '../../../editing/HTMLInplaceEditor';
import LoadingIndicator from '../../../common/LoadingIndicator';
import React, { useCallback, useEffect, useState } from 'react';
import Slider1D from 'components/canvas/components/Slider1D';
import StatisticLegend from 'components/canvas/components/StatisticLegend';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import type {
  AnalyzeOptions, CanvasId, Scale1DAnswer, SliderQuestionCanvas, WithPathParamProps
} from '../../../../@shared/schema/src';
import type { Render2DTickFunction } from 'components/canvas/canvasTypes/Matrix2DQuestionCanvas/layouts/Matrix2DLayout';
import type { Size } from '../Matrix2DCanvasEditor';
import type { WithId } from '@mindhiveoy/schema';

export const SlidersFrame = styled.div<{
  $isEditing?: boolean;
}>(({
  theme,
  $isEditing,
}) => `
  margin-top: ${theme.spacing($isEditing ? 8.75 : 3)};
  margin-bottom: ${theme.spacing($isEditing ? 0 : 3)};
  height: min-content;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--Bluegrey, #50516f);
  overflow: visible;
  border-radius: 12px;
  padding: 8px;
  gap: ${theme.spacing(5)};
  @media(max-width: 600px) {
    gap: ${theme.spacing(0)};
  }
`);

export interface SliderCanvasEditorProps extends WithPathParamProps {
  canvasId: CanvasId;
  canvas: WithId<SliderQuestionCanvas>;
  size?: Size;
  answer?: Scale1DAnswer;
}

const SliderCanvasEditor = ({
  canvas,
}: SliderCanvasEditorProps): JSX.Element => {
  const {
    t,
  } = useTranslation();

  const {
    updateProperty,
    model,
  } = useComponentDataContext<WithId<SliderQuestionCanvas>>();
  const {
    query,
  } = useRouter();

  const editMode = resolveEditorMode(query);

  const [analyzeOptions, setAnalyzeOptions,] = useState<AnalyzeOptions>(
    () => model.component.data.analyzeOptions!
  );

  useEffect(() => model.subscribeToDataChange(({
    newValue,
  }) => setAnalyzeOptions(newValue?.data?.analyzeOptions as any ?? null)), [model,]);

  const handleRenderTick: Render2DTickFunction = useCallback(({
    item: {
      label,
    },
    index,
  }) => {
    const handleTick = (name: string, value: string) => {
      updateProperty(name as any, value);
      updateProperty(`axis.scaleType` as any, 'custom');
    };

    return <HTMLInplaceEditor
      text={label}
      name={`axis.range[${index}].label`}
      placeHolder=""
      onChange={handleTick as any}
    />;
  }, [updateProperty,]);

  return !canvas ?
    <LoadingIndicator /> :
    <>
      <Typography
        style={editorTypographyStyleCentered}
        component={'span'}
        variant="h2">
        <HTMLInplaceEditor
          text={canvas.name}
          name="name"
          placeHolder="Name"
          allowedStyles={EMPHASIS_STYLES_ALLOWED}
          onChange={updateProperty as any}
        ></HTMLInplaceEditor>
      </Typography>
      <Typography
        style={editorTypographyStyleCentered}
        component={'span'}
        variant="body1">
        <HTMLInplaceEditor
          text={canvas.desc}
          name="desc"
          placeHolder="Description"
          allowedStyles={ALL_STYLES_ALLOWED}
          onChange={updateProperty as any}
          showToolbar
          centerToolbar
        ></HTMLInplaceEditor>
      </Typography>
      <Center direction="horizontal" >
        <SlidersFrame $isEditing={!!editMode}>
          <SliderContainer>
            <SliderLayout
              showMinMaxLabels={canvas.showMinMaxLabels}
              showAxisLabels={canvas.showAxisLabels}
              axisLabel={<HTMLInplaceEditor
                text={canvas?.axis?.mainLabel}
                name="axis.mainLabel"
                onChange={updateProperty as any}
              />}
            >
              <Slider1D
                disabled
                layoutId="slider-1d"
                axis={canvas.axis}
                // value={answer}
                showMinMax={canvas.showMinMaxLabels}
                minLabel={<HTMLInplaceEditor
                  text={canvas.axis?.minLabel}
                  name="axis.minLabel"
                  placeHolder={t('Min label')}
                  onChange={updateProperty as any}
                />}
                maxLabel={<HTMLInplaceEditor
                  text={canvas.axis?.maxLabel}
                  name="axis.maxLabel"
                  placeHolder={t('Max label')}
                  onChange={updateProperty as any}
                />}
                valueLabelDisplay="auto"
                step={1}
                showTicks={canvas.showTickLabels}
                mode={canvas.mode}
                onRenderTick={handleRenderTick as any}
              />
            </SliderLayout>
          </SliderContainer>
          <div style={{
            width: '100%',
          }}>
            <StatisticLegend
              statistics={canvas.statistics}
              analyzeOptions={analyzeOptions}
            />
          </div>
        </SlidersFrame>

      </Center>
    </>;
  // :
  // // TODO: Resolve why editor has other modes besides edit ???
  // <>
  //   <QuestionLayoutGrid>
  //     <BottomLabeling key="bottom-label">
  //       <XTickMarks scaleLength={range.length}
  //         padding={4}
  //         scaleType={canvas.axis.scaleType}
  //         range={range} />
  //       <HorizontalAxisLabel $showMinMax={canvas.showMinMaxLabels}>
  //         {canvas.showMinMaxLabels && <>{ mainLabel }</>}
  //       </HorizontalAxisLabel>
  //     </BottomLabeling>
  //     <LeftLabeling key="left-label">
  //       <VerticalAxisLabel $showMinMax={canvas.showMinMaxLabels}>
  //         {canvas.showAxisLabels && <>{ 'Frequencies' }</>}
  //       </VerticalAxisLabel>
  //       {/* {canvas.frequencies && <YTickMarks scaleType={'numeric'}
  //         range={filledFrequenciesArray}
  //         canvas={{
  //           ...canvas, mode: 'discrete',
  //         }}/>
  //       } */}
  //     </LeftLabeling>
  //     <Content>
  //       <Chart
  //         size={size}
  //         axis={canvas.axis}
  //         canvas={canvas}
  //         canvasMode={canvas.mode}/>
  //     </Content>
  //   </QuestionLayoutGrid>
  //   <div style={{
  //     display: 'flex',
  //   }}>
  //     { analyzeProperties && analyzeProperties.analyzeValues &&
  //      statisticsLegendColorsArray(Object.entries(analyzeProperties.analyzeValues).map((e)=> e[1])).length !== 0 &&
  //        <LegendFrame style={{
  //          paddingTop: '10px',
  //        }}>
  //          <Stats>
  //            {statisticsLegendColorsArray(Object.entries(analyzeProperties.analyzeValues).map((e)=> e[1])).map(
  //              (coloredValue, index) => {
  //                if (coloredValue.value) {
  //                  return <div key={coloredValue.label + index.toString()}
  //                    style={{
  //                      display: 'flex',
  //                      minHeight: '40px',
  //                    }}>
  //                    <div style={{
  //                      width: '30px',
  //                      minHeight: '40px',
  //                    }}>
  //                      <div style={{
  //                        width: '20px',
  //                        height: '15px',
  //                        backgroundColor: coloredValue.color,
  //                        //  margin: 'auto',
  //                        marginLeft: '10px',
  //                        marginTop: '5px',
  //                      }}/>
  //                    </div>
  //                    <div style={{
  //                      minHeight: '40px',
  //                      margin: 'auto',
  //                      paddingLeft: '10px',
  //                      flex: 1,
  //                    }}>
  //                      <p style={{
  //                        margin: 'auto',
  //                        textAlign: 'left',
  //                      }}>
  //                        {coloredValue.label}
  //                      </p>
  //                    </div>
  //                  </div>;
  //                }
  //              }
  //            )}
  //          </Stats>
  //        </LegendFrame>
  //     }
  //   </div>
  // </>
};

export default SliderCanvasEditor;
