/* eslint-disable @typescript-eslint/no-explicit-any */

import { DEBUG_BLOC_FRAMEWORK } from '../BlocQuery';
import { ListenerHelper } from '../../../utils/listener/listenerHelper';
import { StatefulListener } from '../../../utils/listener/StatefulListener';
import { alterDocumentData, hasDocumentChanged } from '../../../utils/documentUtils';
import { createCachedDocumentSnapshot } from './createCachedDocumentSnapshot';
import { startTransition } from 'react';
import CachedDocumentReference from './CachedDocumentReference';
import cloneDeep from 'lodash/cloneDeep';
import type { BlocCacheUpdateOptions } from '../types';
import type { BlocCachedDocumentSnapshotListener, CreateDocumentSnapshotFunction, DatabaseControllerDocumentSnapshotListener } from '../types';
import type { CachedCollectionReference, DataChangeCallbackFunction } from './CachedCollectionReference';
import type { DocumentData } from '@mindhiveoy/firebase-schema';
import type { DocumentReferenceListener } from './CachedDocumentReference';
import type { DocumentSnapshotLike } from '../databaseController/DocumentSnapshotLike';

interface GetOptions<T extends DocumentData> {
  /**
   * The method to be used for the document reference. Default is 'get'.
   *
   * get    - Get the document reference.
   * create - Create the document reference if it does not exist.
   * set    - Set the document reference no matter if it exists or not.
   */
  method?: 'get' | 'create' | 'set';
  /**
   *
   */
  candidate?: boolean;

  initialData?: T | null;

  onDataChange?: DataChangeCallbackFunction<T>;

}

/**
 * querySnapshot call arguments.
 */
export type DocumentSnapshotArgs<T extends DocumentData> = {
  /**
   * The id of the document to be listened.
   */
  docId: string;
  /**
   * Fields to be triggering the onSnapshot event.
   */
  fields?: (keyof T)[];
  /**
   * The actual database call used to open the snapshot listener.
   */
  create: CreateDocumentSnapshotFunction<T>,
  /**
   * The snapshot listener function that will trigger each time document's
   * data has been change.
   */
  onSnapshot: BlocCachedDocumentSnapshotListener<T>;
};

export type CachedDocumentSnapshot<D> = {
  id: string;
  data: D | null;
  exists: boolean;
};

export type StatefulBlocDocumentSnapshotListenerFunction<T, D extends DocumentData> =
  (state: T, doc: CachedDocumentSnapshot<D>) => void;

interface ListenerState<T extends DocumentData> {
  fields?: (keyof T)[];
  data?: T | null;
}
interface DocumentInstance<T extends DocumentData> {
  /**
   * Set of document references showing the set of fetched documents.
   */
  doc: CachedDocumentReference<T>;

  listeners?: StatefulListener<
    // TODO: Check typing to work with TS5
    any, //  StatefulBlocDocumentSnapshotListenerFunction<ListenerState<T>, T>,
    ListenerState<T>
  >;

  pending?: boolean;

  unsubscribe?: () => void;

  updateDoc?: (doc: DocumentSnapshotLike<T>) => void;
}
/**
 *
 */
class DocumentReferenceSet<T extends DocumentData> {
  /**
   * Indicates if the document exists in set has been initialized.
   * @param {string} docId
   * @return {boolean} True if the document exists in cache.
   */
  isDocumentCached = (docId: string): boolean => {
    const instance = this.$documentInstances.get(docId);
    if (!instance) {
      return false;
    }
    return instance.doc.isInitialized;
  };

  private $documentInstances = new Map<string, DocumentInstance<T>>();

  private listeners = new ListenerHelper<DocumentReferenceListener<T>>();

  private $ignoreFields: Set<keyof T>;
  /**
   * Create a new DocumentReferenceSet instance.
   *
   * @param {BlocQuerySet<T>} querySet Query set.
   * @param {any}             ignoreFields Fields to ignore on change comparison.
   */
  constructor(
    private collectionReference: CachedCollectionReference<T>,
    private ignoreFields: Array<keyof T> = []
  ) {
    this.$ignoreFields = new Set(ignoreFields);
  }
  /**
   *
   */
  public dispose = () => {
    this.$documentInstances.forEach((doc) => {
      doc?.unsubscribe?.();
    });
    this.listeners.reset();
  };

  // TODO: Remove from final version
  // identifier = Math.floor(Math.random() * 1000);
  /**
   * Change document reference's id in the document reference set
   * from from oldId to newId.
   * @param {string}  fromDocId
   * @param {string}  toDocId
   */
  changeDocumentId(fromDocId: string, toDocId: string) {
    const instance = this.$documentInstances.get(fromDocId);
    if (instance) {
      this.$documentInstances.delete(fromDocId);
      this.$documentInstances.set(toDocId, instance);
      instance.doc.changeDocId(toDocId);
    }
  }
  /**
   * Add reference for a document
   *
   * @param {string} docId
   */
  addRef = (docId: string) => {
    const instance = this.$documentInstances.get(docId);
    if (!instance) {
      throw new Error(`Instance not found for ${docId}.`);
    }
    return instance.doc.addRef();
  };
  /**
   * Remove reference for a document
   *
   * @param {string} docId
   */
  unRef = (docId: string) => {
    const instance = this.$documentInstances.get(docId);
    if (!instance) {
      throw new Error(`Instance not found for ${docId}.`);
    }
    const count = instance.doc.removeRef();
    if (count <= 0) {
      instance.unsubscribe && instance.unsubscribe();
      this.$documentInstances.delete(docId);
    }
  };
  /**
   * Open a snapshot listener for a specific query.
   *
   * @param {DocumentSnapshotArgs}                 args             Object defining the input arguments.
   * @param {Query}                             args.query       Query defining the constraints used to fetch
   *                                                             documents from the database.
   * @param {CreateQuerySnapshotFunction}       args.create      The actual database call used to open the snapshot listener.
   * @param {BlocQuerySnapshotListenerFunction} args.onSnapshot  The snapshot listener function that will trigger each time
   *                                                             query data will change.
   * @return {UnSubscribeFunction}                               Unsubscribe function for unsubscribing from onSnapshot listener.
   */
  public onSnapshot = (args: DocumentSnapshotArgs<T>): () => void => {
    const {
      docId,
      fields,
      onSnapshot,
    } = args;

    let instance = this.$documentInstances.get(docId);

    /**
     * Filter firing of onSnapshot listeners based on their interest
     * on fields that have changed.
     *
     * @param {ListenerState<T>} state
     * @param {CachedDocumentReference<T>} doc
     */
    const onFilterSnapshot = (
      state: ListenerState<T>,
      doc: CachedDocumentSnapshot<T>
    ) => {
      const newData = doc.data;
      const priorData = state.data;
      state.data = newData;

      if (!newData || !priorData) {
        if (newData !== undefined && newData === priorData) {
          return;
        }
        onSnapshot(doc);
        return;
      }
      if (!state.fields) {
        onSnapshot(doc);
        return;
      }
      let changed = false;

      if (fields) {
        // TODO: Make context cache to speed up comparison
        for (const field of fields) {
          if (priorData[field] !== newData[field]) {
            changed = true;
            break;
          }
        }
      }
      if (changed) {
        onSnapshot(doc);
      }
    };

    if (!instance) {
      instance = this.createDocumentInstance(docId);
    }
    if (!instance.listeners) {
      this.listenDocument(args, instance, onFilterSnapshot);
    } else {
      instance.listeners.add(onFilterSnapshot, {
        fields,
      });
    }

    const doc = createCachedDocumentSnapshot(instance.doc);
    // Initial trigger on subscribe
    // TODO: this should trigger only when there is live data from backend
    onSnapshot(doc);

    return () => {
      // instance.listeners.remove(onSnapshot);

      instance?.listeners?.remove(onFilterSnapshot);
      if (instance && instance.listeners && instance.listeners.length === 0) {
        instance.unsubscribe?.();
        instance.unsubscribe = undefined;
        instance.listeners = undefined;

        const ref = this.$documentInstances.get(docId);

        if (ref && ref.doc.referenceCount <= 0) {
          this.$documentInstances.delete(docId);
        }
      }
    };
  };

  /**
   * Checks, if the document reference with the given id is in the set.
   *
   * @param {string} docId    The unique id of the document.
   * @return {boolean} True, when the document reference exists, otherwise false.
   */
  public has = (
    docId: string
  ): boolean => {
    return this.$documentInstances.has(docId);
  };

  /**
   * Checks, if the document with the given id is in the set and has data.
   * If the reference for the document exists, this will still return false
   * if the data is undefined.
   *
   * @param {string} docId    The unique id of the document.
   * @return {boolean} True, when the document exists, otherwise false.
   */
  public exists = (
    docId: string
  ): boolean => {
    const instance = this.$documentInstances.get(docId);
    return !!(instance && instance.doc.exists);
  };

  /**
   * Get a document reference for a document id.
   * @param {string} docId         The unique id of the document.
   * @param {GetOptions} options   Possible options.
   * @return {CachedDocumentReference<T> | undefined} The reference if exists, otherwise undefined.
   */
  public get = (
    docId: string,
    options: GetOptions<T> = {}
  ): {
    docRef: CachedDocumentReference<T> | undefined;
    /**
     * The local reference created for the document.
     */
    created: boolean;

    dataChanged?: boolean;
  } => {
    let instance = this.$documentInstances.get(docId);
    let created = false;

    const method = options?.method ?? 'get';

    let dataChanged = false;

    if (!instance && method !== 'get') {
      instance = this.createDocumentInstance(
        docId,
        options?.initialData
      );
      created = true;
      dataChanged = true;
    } else {
      if (method === 'set' || !options?.initialData && method === 'create') {
        const oldData = instance?.doc.data as T | null;
        const newData = options?.initialData as T ?? null;

        instance?.doc.setData(newData);

        if (hasDocumentChanged(oldData, newData, this.$ignoreFields)) {
          dataChanged = true;

          options?.onDataChange?.({
            docId,
            oldData: cloneDeep(oldData),
            newData: cloneDeep(newData),
            dispatchChangeEvent: () => {
              if (!instance?.doc) {
                return;
              }
              const snapshot = createCachedDocumentSnapshot(instance.doc);
              instance?.listeners?.fire(snapshot);
            },
          });
        }
      }
    }

    return {
      docRef: instance?.doc,
      created,
      dataChanged,
    };
  };

  // /**
  //  * Get a document reference for a document id, if the reference exists.
  //  * @param {string}          docId     The unique id of the document.
  //  * @param {T | undefined}   data      Possible options.
  // //  * @param {GetOptions}      options   Possible options.
  //  * @return {CachedDocumentReference<T> | undefined} The reference if exists, otherwise undefined.
  //  */
  // public set = (
  //   docId: string,
  //   data: T | undefined
  //   // options?: Omit<GetOptions<T>, 'initialData'>
  // ): CachedDocumentReference<T> | undefined => {
  //   let instance = this.$documentInstances.get(docId);

  //   const priorData = instance?.doc?.data;

  //   if (!instance) {
  //     instance = this.createDocumentInstance(docId, data);
  //   }

  //   instance.doc.setData(data);

  //   instance.listeners?.fire(instance.doc);

  //   // TODO: Do we use these listeners?
  //   this.listeners.fire({
  //     docId,
  //     priorData,
  //     data,
  //   });
  //   this.collectionReference.validate([instance.doc,]);

  //   return instance.doc;
  // };
  /**
   * Map documents into an array.
   *
   * @param {any} predicate
   * @return {CachedDocumentReference<T>[]}
   */
  public map = (
    predicate: (
      doc: CachedDocumentReference<T>,
      index: number
    ) => CachedDocumentReference<T>
  ): CachedDocumentReference<T>[] => {
    return Array
      .from(this.$documentInstances.values())
      .map(
        (instance, index) => predicate(instance.doc, index)
      );
  };
  /**
   *
   * @param {string} docId                        The unique id of the document.
   */
  public delete = (
    docId: string
  ) => {
    const instance = this.$documentInstances.get(docId);
    if (!instance) {
      return;
    }
    instance.doc.delete();

    const doc = cloneDeep(instance.doc);

    if (!DEBUG_BLOC_FRAMEWORK) {
      startTransition(() => {
        instance.listeners?.fire(doc);
      });
      return;
    }
    instance.listeners?.fire(doc);
  };
  /**
   *
   * @param {string} docId                        The unique id of the document.
   * @param {Partial<T>}  documentData
   * @param {CacheCollectionUpdateOptions}  options
   * @return {[boolean, T, CachedDocumentReference<T>, T]}   A tuple, where the first value will indicate, if document's data did change
   *                          and the second will return the altered data.
   */
  public setDocumentData = (
    docId: string,
    documentData: Partial<T> | null,
    options?: BlocCacheUpdateOptions
  ): [boolean, T | null, CachedDocumentReference<T>, T | null] => {
    const {
      docRef,
    } = this.get(docId, {
      ...options,
      method: 'get',
    });

    const currentData = docRef?.data ?? null;

    const data = alterDocumentData<T>(
      currentData,
      documentData,
      options
    );

    if (currentData !== null && !hasDocumentChanged(currentData, data, this.$ignoreFields)) {
      return [false, data, docRef as CachedDocumentReference<T>, currentData,];
    }

    docRef?.setData(data, options);

    const instance = this.$documentInstances.get(docId);

    if (!instance) {
      throw new Error(`Instance not found for document: ${docId}.`);
    }
    // TODO: fire only if instance is interested on changed fields.
    const snapshot = createCachedDocumentSnapshot(instance.doc);
    if (!DEBUG_BLOC_FRAMEWORK) {
      startTransition(() => {
        instance?.listeners?.fire(snapshot);
      });
    } else {
      instance?.listeners?.fire(snapshot);
    }

    return [true, data, docRef as CachedDocumentReference<T>, currentData,];
  };
  /**
   *
   */
  public get size(): number {
    return this.$documentInstances.size;
  }
  /**
   * List all ids of documents.
   * @return {string[]} An array of all ids of documents
   */
  public ids = () => {
    return this.$documentInstances.keys();
  };
  /**
   *
   * @param {DocumentReferenceListener<T>} listener
   */
  public addDocumentReferenceListener = (listener: DocumentReferenceListener<T>) => {
    this.listeners.add(listener);
  };
  /**
   *
   * @param {DocumentReferenceListener<T>} listener
   */
  public removeDocumentReferenceListener = (listener: DocumentReferenceListener<T>) => {
    this.listeners.remove(listener);
  };
  /**
   * Create a new query instance for set of queries targeting the same collection with equal output.
   * @param {string} docId        Query arguments.
   * @param {T} initialData        Query arguments.
   * @return {QueryInstance<T>}
   */
  private createDocumentInstance = (
    docId: string,
    initialData: T | null = null
  ): DocumentInstance<T> => {
    const instance: DocumentInstance<T> = {
      doc: new CachedDocumentReference(
        this.collectionReference,
        docId,
        initialData
      ),
    };
    this.$documentInstances.set(docId, instance);
    instance.doc.setData(initialData ? initialData : null);
    return instance;
  };

  /**
   * Create a new query instance for set of queries targeting the same collection with equal output.
   * @param {DocumentSnapshotArgs<T>} args        Query arguments.
   * @param {DocumentInstance}                     instance        Query arguments.
   * @param {any}                     onSnapshot        Query arguments.
   * @return {QueryInstance<T>}
   */
  private listenDocument = (
    {
      docId,
      fields,
      create,
    }: Omit<DocumentSnapshotArgs<T>, 'onSnapshot'>,
    instance: DocumentInstance<T>,
    onSnapshot: StatefulBlocDocumentSnapshotListenerFunction<any, any>
  ): DocumentInstance<T> => {
    const listeners = new StatefulListener<
      // TODO: Fix typing for TS5
      any, //  StatefulBlocDocumentSnapshotListenerFunction<ListenerState<T>, any>,
      ListenerState<T>>();
    listeners.add(onSnapshot, {
      fields,
    });
    instance.listeners = listeners;

    const updateDoc: DatabaseControllerDocumentSnapshotListener<T> = (doc) => {
      try {
        this.setDocumentData(doc.id, doc.data());
      } finally {
        instance.pending = false;
      }
    };

    const unsubscribe = create(docId, updateDoc);

    instance.updateDoc = updateDoc;
    instance.unsubscribe = unsubscribe;
    return instance;
  };
}

export default DocumentReferenceSet;
