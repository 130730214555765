/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DARK_LABEL_COLOR } from 'components/canvas/canvasTypes/labelUnits';
import { useTranslation } from 'react-i18next';
import DiscreteMatrixGridGraph from 'components/canvas/graphs/Matrix2DCanvasGraph/modes/DiscreteMatrixGridGraph';
import HTMLInplaceEditor, { EMPHASIS_STYLES_ALLOWED } from '../../../editing/HTMLInplaceEditor';
import Matrix2DInput from 'components/canvas/canvasTypes/Matrix2DQuestionCanvas/Matrix2DInput';
import Matrix2DLayout from 'components/canvas/canvasTypes/Matrix2DQuestionCanvas/layouts/Matrix2DLayout';
import React, { useCallback } from 'react';
import type { AnalyzeOptions, AnswerVisibility, Matrix2DQuestionCanvas } from '../../../../@shared/schema/src';
import type { Render2DTickFunction } from 'components/canvas/canvasTypes/Matrix2DQuestionCanvas/layouts/Matrix2DLayout';
import type { Size } from './index';
import type { WithId } from '@mindhiveoy/schema';

export interface MatrixProps {
  canvas: WithId<Matrix2DQuestionCanvas>;
  size: Size;
  // editMode: EditorMode;
  analyzeOptions: AnalyzeOptions;
  graphBackgroundColor?: string;
  sessionAnswerVisibility?: AnswerVisibility;
  showOtherAnswers?: boolean;
  onChange: (name: string, value: any) => void;
}

const inplaceEditorStyle = {
  color: DARK_LABEL_COLOR,
};
const EditMatrix2D = ({
  analyzeOptions,
  canvas,
  sessionAnswerVisibility,
  showOtherAnswers,
  size,
  onChange,
}: MatrixProps) => {
  const {
    t,
  } = useTranslation();

  // const {
  //   isPresentation,
  // } = useCanvasRenderingContext();

  const handleRenderTick: Render2DTickFunction = useCallback(({
    axis,
    item: {
      label,
    },
    index,
  }) => {
    const handleTick = (name: string, value: string) => {
      // TODO: Batch update support to reduce change events
      // if (canvas.tickLabelsPresentation === 'mirrored') {
      const otherAxis = axis === 'x' ? 'y' : 'x';
      onChange(`axis.${otherAxis}.range[${index}].label`, value);
      onChange(`axis.${otherAxis}.scaleType`, 'custom');
      // }

      onChange(name, value);
      onChange(`axis.${axis}.scaleType`, 'custom');
    };

    return <HTMLInplaceEditor
      text={label as any}
      writingMode={axis === 'y' ? 'vertical-rl' : 'horizontal'}
      style={{
        margin: '0.5rem',
        maxWidth: '100%',
        maxHeight: '100%',
      }}
      name={`axis.${axis}.range[${index}].label`}
      onChange={handleTick as any}
    />;
  }, [onChange,]);

  return <Matrix2DLayout
    canvas={canvas}
    extraSpace={{
      left: 3,
      right: 3,
    }}
    xLabel={<HTMLInplaceEditor
      text={canvas?.axis.x.mainLabel}
      saveOnEnterPress
      fluid
      allowedStyles={EMPHASIS_STYLES_ALLOWED}
      name={'axis.x.mainLabel'}
      placeHolder={t('x-axis label')}
      onChange={onChange}
    />
    }
    yLabel={
      <HTMLInplaceEditor
        writingMode='vertical-rl'
        text={canvas?.axis.y.mainLabel}
        allowedStyles={EMPHASIS_STYLES_ALLOWED}
        saveOnEnterPress
        fluid
        placeHolder={t('y-axis main label')}
        name={'axis.y.mainLabel'}
        onChange={onChange} />
    }
    xMinLabel={
      <HTMLInplaceEditor
        writingMode='vertical-rl'
        text={canvas?.axis.x.minLabel}
        saveOnEnterPress
        allowedStyles={EMPHASIS_STYLES_ALLOWED}
        placeHolder={t('x-axis min label')}
        name={'axis.x.minLabel'}
        onChange={onChange} />
    }
    xMaxLabel={
      <HTMLInplaceEditor
        writingMode='vertical-lr'
        text={canvas?.axis.x.maxLabel}
        saveOnEnterPress
        allowedStyles={EMPHASIS_STYLES_ALLOWED}
        placeHolder={t('x-axis max label')}
        name={'axis.x.maxLabel'}
        onChange={onChange} />
    }
    yMinLabel={
      <HTMLInplaceEditor
        text={canvas?.axis.y.minLabel}
        saveOnEnterPress
        allowedStyles={EMPHASIS_STYLES_ALLOWED}
        placeHolder={t('y-axis min label')}
        name={'axis.y.minLabel'}
        onChange={onChange} />
    }
    yMaxLabel={
      <HTMLInplaceEditor
        text={canvas?.axis.y.maxLabel}
        allowedStyles={EMPHASIS_STYLES_ALLOWED}
        saveOnEnterPress
        placeHolder={t('y-axis max label')}
        name={'axis.y.maxLabel'}
        onChange={onChange} />
    }
    lucLabel={
      <HTMLInplaceEditor
        text={canvas?.axis.cornerLabels?.luc}
        dark
        style={inplaceEditorStyle}
        textAlign='left'
        allowedStyles={EMPHASIS_STYLES_ALLOWED}
        name={'axis.cornerLabels.luc'}
        placeHolder={t('Left upper corner label')}
        saveOnEnterPress
        onChange={onChange} />
    }
    rucLabel={
      <HTMLInplaceEditor
        text={canvas?.axis.cornerLabels?.ruc}
        allowedStyles={EMPHASIS_STYLES_ALLOWED}
        dark
        style={inplaceEditorStyle}
        textAlign='right'
        placeHolder={t('Right upper corner label')}
        name={'axis.cornerLabels.ruc'}
        saveOnEnterPress
        onChange={onChange} />
    }
    llcLabel={
      <HTMLInplaceEditor
        text={canvas?.axis.cornerLabels?.llc}
        allowedStyles={EMPHASIS_STYLES_ALLOWED}
        dark
        style={inplaceEditorStyle}
        textAlign='left'
        placeHolder={t('Left lower corner label')}
        name={'axis.cornerLabels.llc'}
        saveOnEnterPress
        onChange={onChange} />
    }
    rlcLabel={
      <HTMLInplaceEditor
        text={canvas?.axis.cornerLabels?.rlc}
        allowedStyles={EMPHASIS_STYLES_ALLOWED}
        dark
        style={inplaceEditorStyle}
        textAlign='right'
        saveOnEnterPress
        placeHolder={t('Right lower corner label')}
        name={'axis.cornerLabels.rlc'}
        onChange={onChange} />
    }
    onRenderTick={handleRenderTick}
  >
    <Matrix2DInput
      disabled
      axis={canvas.axis}
      size={size}
      // canvas={canvas}
      min={canvas.axis.min}
      max={canvas.axis.max}
      analyzeOptions={analyzeOptions}
      canvasMode={canvas.mode}
      canvasAnswerVisibility={canvas.config?.answerVisibility ?? 'inherited'}
      sessionAnswerVisibility={sessionAnswerVisibility}
    >
      {canvas.mode === 'discrete' && <DiscreteMatrixGridGraph
        analyzeOptions={analyzeOptions}
        maxX={canvas.axis.max}
        maxY={canvas.axis.max}
        // canvas={canvas as any}
        canvasMode={canvas.mode}
        axis={canvas.axis}
        // isPresentation={isPresentation}
        frequencies={showOtherAnswers ? canvas?.frequencies : undefined}
      // summaryStatistics={
      //   <Matrix2DCharacteristics
      //     axis={canvas.axis}
      //     analyzeOptions={analyzeOptions}
      //     mode={canvas.mode as any}
      //     statistics={canvas?.statistics as Statistics2D}
      //   />
      // }
      />}
    </Matrix2DInput>
  </Matrix2DLayout>;
};

export default EditMatrix2D;
