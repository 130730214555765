import { Link } from './UnsplashCopyright';
import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import type { Media } from '@shared/schema/src';

const CopyText = styled.span`
  font-size: 10px;
  text-align: right;
  margin-top: 6px;
`;

interface MediaCopyrightProps {
  media?: Partial<Media>;
}
/**
 * Show media credentials with a small print.
 * @param param0
 * @returns
 */
export const MediaCopyright = ({
  media: {
    type = 'photo',
    author,
    authorUrl,
    source,
    sourceUrl,
  } = {},
}: MediaCopyrightProps) => {
  const {
    t,
  } = useTranslation();

  const text = useMemo(() => {
    switch (type) {
      case 'photo':
        return t('Photo by');
      case 'video':
        return t('Video by');
      default:
        return null;
    }
  }, [t, type,]);

  if (!text || !author) {
    return null;
  }
  return <CopyText>
    {text} <Link
      target='_blank'
      href={authorUrl}>
      {author}
    </Link> on <Link target='_blank'
      href={sourceUrl}>{source}</Link>
  </CopyText>;
};
