import type { FeatureId, ModuleId, UserRoleId } from '@shared/schema/src';
import type { InternalState } from '.';
import type { PlanId } from '@mindhiveoy/react-auth';
import type { User } from '@mindhiveoy/schema';

/**
 * Resolve the changes to the auth state and return the new state.
 */
export const resolveAuthStateChange = (
  current: Partial<InternalState<PlanId, ModuleId, FeatureId, UserRoleId, User<any, any, any>>>,
  changes: Partial<InternalState<any, any, any, any, User<any, any, any>>>
) => {
  const result = {
    ...current,
    ...changes,
  };

  if (changes.user && changes.user.email === null) {
    result.role = undefined;
    result.authInProgress = false;
  }

  result.authenticated = !(result.user?.email === null || result.user?.email === undefined);

  if (result.authenticated && current.user) {
    result.authInProgress = false;
    result.status = 'signed-in';
    result.signingMethod = 'none';
  }

  if (changes.planId && current.user) {
    (result.user as any).plan = changes.planId;
  }
  return result;
};
