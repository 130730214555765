
/**
 * Helper hook to stop propagation of an event
 *
 * @returns A function that stops the propagation of an event
 */
const useStopPropagation = () => (event: React.MouseEvent) => {
  event.stopPropagation();
  event.preventDefault();
};

export default useStopPropagation;
