/* eslint-disable @typescript-eslint/no-explicit-any */
export const flattenArray = (array: any[]) => {
  let result: any[] = [];
  array.forEach((a) => {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flattenArray(a.children));
    }
  });
  return result;
};
