import Center from 'components/common/layout/Center';
import Chart1D from './Chart1D';
import LoadingIndicator from 'components/common/LoadingIndicator';
import type { SliderQuestionCanvas } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

interface SliderCanvasGraphProps {
  canvas: WithId<SliderQuestionCanvas>;
  graphBackgroundColor?: string;
}
const SliderCanvasGraph = ({
  canvas,
  graphBackgroundColor = '#C4C4C4',
}: SliderCanvasGraphProps): JSX.Element => {
  // const {
  //   model,
  // } = useComponentDataContext<WithId<SliderQuestionCanvas>>();

  // const [analyzeOptions, setAnalyzeOptions,] = useState<AnalyzeOptions>(
  //   () => model.component?.data?.analyzeOptions as any
  // );

  // useEffect(() => model.subscribeToDataChange(({
  //   newValue,
  // }) => setAnalyzeOptions(newValue?.data?.analyzeOptions as any ?? null)), [model,]);

  return canvas ?
    <Center>
      <Chart1D
        canvas={canvas}
        canvasMode={canvas.mode}
        graphBackgroundColor={graphBackgroundColor}
        size={{
          width: 500,
          height: 500,
        }}
        axis={canvas.axis}
        axisName="x"
      />
    </Center> :
    <LoadingIndicator />;
};

export default SliderCanvasGraph;
