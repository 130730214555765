import { ContentContainer, EditorArea, EditorContainer, commentEditorPlugins } from '.';
import { isMobileDevice } from 'components/canvas/panelistView/isMobileDevice';
import MentionLookupService from '../utils/mentionsLookup';
import PreventBubbling from 'components/common/PreventBubbling';
import React, { useMemo } from 'react';
import ReadMore from '../../../components/common/ReadMore';
import TextEditor, { defaultInitialEditorConfig } from '@mindhiveoy/react-text-editor/TextEditor';
import type { Comment } from '../../../@shared/schema/src';
import type { SerializedLexicalNode } from 'lexical';
import type { WithId } from '@mindhiveoy/schema';

interface CommentContentRowProps {
  comment: WithId<Comment>;
  editing: boolean;
  onEditorHtmlChange?: (html: string) => void;
  onEditorJSONChange?: (json: SerializedLexicalNode) => void;
  onSubmitHtml?: (html: string, json?: SerializedLexicalNode) => Promise<boolean>;
}
export const CommentContentRow = ({
  comment, editing, onEditorHtmlChange, onEditorJSONChange, onSubmitHtml,
}: CommentContentRowProps) => {
  const contentStyles = useMemo(() => ({
    fontSize: isMobileDevice() ? '0.9rem' : '1rem',
  }), []);

  return <ContentContainer
    style={contentStyles}>
    {editing ?
      <PreventBubbling>
        <TextEditor
          initialConfig={{
            ...defaultInitialEditorConfig,
            namespace: `${comment._id}_comment-editor`,
          }}
          initialHtmlData={comment.text}
          onHtmlChange={onEditorHtmlChange}
          onJSONChange={onEditorJSONChange}
          presetPlugins={commentEditorPlugins}
          EditorAreaComponent={EditorArea}
          InputAreaComponent={EditorContainer as any}
          contentEditableClassName={'editor-input-update-comment'}
          onSubmitHtml={onSubmitHtml}
          placeholderText={''} // todo: fix this, now the placeholder is located in a wrong place
          onLookupMentions={MentionLookupService.getInstance().searchMentions} />
      </PreventBubbling> :
      <ReadMore text={comment?.text} />}
  </ContentContainer>;
};
