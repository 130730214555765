import type { Email, MemberId, MemberStatus, URL, UTCTime } from '@mindhiveoy/schema';
import type { Member } from '../../users/user';
import type { PublicMemberInfo } from '../../member';
import type { Screens } from './screens';
import type { ProjectSettingDocType, UserRoleId, WithMedia } from '../..';
export * from './ResourceMetadata';

export type ProjectId = string;

export type ProjectStatus = 'draft' | 'active' | 'closed';

export const BACKEND_SETTINGS_DOC = 'backend_settings';
export const FRONTEND_SETTINGS_DOC = 'frontend_settings';

export const PROJECT_METADATA_DOC: ProjectSettingDocType = 'project_metadata';

export interface MentionInfo {
  id: MemberId;
  nick: NickName;
  invitationId?: string;
}

export interface ProjectMemberInfo extends MentionInfo {
  displayName?: string;
  status?: MemberStatus;
  email?: string;
  avatar?: string;
}

export type ProjectAuthenticationStrategy =
  /**
   * Mode where members have been invited explicitly and they must be authenticated to
   * participate.
   */
  'restricted' |
  /**
   * Project is open, so that who ever has a link to it may register autonomously and participate.
   */
  'open';

export interface Project extends WithMedia {
  /**
   * Human readable name of the project
   */
  name: string;
  /**
   * Optional description for the project to give more background on case.
   */
  description: string;
  // /**
  //  * The metadata of the project to help the AI to understand the project better.
  //  */
  // metadata?: ResourceMetadata;
  /**
   * A list of facilitators in project
   */
  facilitators: PublicMemberInfo[];
  /**
   * Owners of the project
   */
  owners: MemberId[];

  status?: ProjectStatus;

  /**
   * internal project Id
   */
  id?: string;

  /**
   * Authentication strategy used in the project. The default value is 'restricted'
   */
  authStrategy: ProjectAuthenticationStrategy;

  /**
   * List containing every member's nick name, id and avatar url to be used when
   * referencing in chat.
   */
  nicks?: ProjectMemberInfo[];

  statusText?: string;
  /**
   * Sequence giving an unique number for each query created for the project.
   */
  querySequence?: number;
  /**
   * Possible key words to categorize the project.
   */
  keyWords?: string[];
  /**
   * Name of the Space in which project is created the project.
   */
  spaceName?: string;
  /**
   * Screens defined for the project. This is only available if the
   * live -feature is enabled.
   */
  screens?: Screens;
}

export interface ProjectBackendSettings {
  /**
   * The id of the project that is used as a template for the project.
   */
  ai: {
    /**
    * The id of the vector store used by this project
    */
    fileVectorStoreId?: string;

    substanceAssistantId: string;
  };
}

export interface ProjectSettingDoc<T = any> {
  alterEgoSet: 'robots';
  /**
   * An array of already used alter ego ids
   * 
   * @deprecated use doc instead
   */
  usedAlterEgoIds?: number[];
  /**
   * The actual settings of the project included
   * in the named document.
   */
  doc: T;
}

export type NickName = string;

export interface ProjectMember<R = UserRoleId> extends Member<R> {
  nick: NickName;
  email?: Email;
  /**
   * The url for the user's avatar
   */
  avatar: URL;

  /**
   * Extra information wrapped to a single field to decrease
   * the need for schema changes and to limit us of Firestore exempts.
   */
  extra?: {
    /**
     * Description field for the member. This is currently used only with
     * pinned members.
     */
    desc?: string;
    /**
     * Pinned user is not anonymized in live mode screen presentations
     */
    pinned?: boolean;
    /**
     * Last time the user was active in the project
     */
    lastActive?: UTCTime;
    /**
     * The id of the invitation that was used to invite the user to the project.
     */
    invitationId?: string;
  };
}
