/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  collection,
  doc, getDoc, getFirestore, limit, onSnapshot, orderBy, query, where
} from 'firebase/firestore';
import type {
  DocumentData, DocumentSnapshot, FirestoreError, QueryConstraint, QuerySnapshot
} from 'firebase/firestore';

import { FirestoreDocumentConverter } from './FirestoreDocumentConverter';
import { documentCache } from './DocumentModelCache';
import { firebaseApp } from '../../schema';
import { immutable } from '../bloc/utils/immutable';
import DatabaseController from '../bloc/databaseController/DatabaseController';
import type { DatabaseControllerFactoryFunctionArgs } from '../bloc/databaseController/DatabaseController';
import type { FirebaseSchemaDocumentModel, FirestoreSchemaModel } from '@mindhiveoy/firebase-schema';
import type { TransformMap } from './FirestoreDocumentConverter';

import { debugSwitch } from 'utils/debug/debug';
import { globalCache } from '@mindhiveoy/bloc/cache/BlocCache';
import { platformEventSystem } from 'components/platformEvents/PlatformEventSystem';
import normalizeDatabasePath from '../bloc/utils/normalizeDatabasePath';
import type {
  BlocDocumentSnapshot,
  BlocErrorFunction, BlocQueryConstraint, BlocUnsubscribeFunction,
  DatabaseControllerDocumentSnapshotListener, DatabaseControllerQuerySnapshotListener
} from '../bloc/types';

const DEBUG = debugSwitch(false);

/**
 * Firestore implementation of the DatabaseController class.
 *
 * ## Support for delayed updates
 * If the saveDelay is defined, will this class wait until set milliseconds
 * before the operations will be executed. If new operations are called in
 * this time window, will the operation be merged first locally and the time
 * window will be extended.
 *
 * When the controller is disposed, all waiting operations will be executed
 * immediately
 */
class FirestoreDatabaseController<T extends DocumentData> extends DatabaseController<T> {
  private collectionPath: string;

  /**
   * @param {string} collectionPath
   */
  constructor(
    collectionPath: string,
    schema: FirestoreSchemaModel<any, any>
  ) {
    super(); // TODO: Ignored fields
    const path = normalizeDatabasePath(collectionPath);
    this.collectionPath = path;

    const transformMap = this.generateTransformMap(
      documentCache.get(schema, path)
    );

    this.convert = new FirestoreDocumentConverter(transformMap);
  }
  /**
   *
   */
  public dispose = () => {
    // console.log('disposed');
  };

  /**
   *
   * @param {string} docId
   * @returns
   */
  public async fetchDoc(docId: string): Promise<BlocDocumentSnapshot<T>> {
    const firestore = getFirestore(firebaseApp());

    const docRef = doc(firestore, `${this.collectionPath}/${docId}`) as any;

    const d = await getDoc(docRef);

    return {
      id: docId,
      data: () => d.data() as any,
    };
  }
  /**
   *
   * @param {BlocQueryConstraint[]} constraints
   * @param {any}                   next
   * @return {Promise<void>}
   */
  public onQuerySnapshot = (
    constraints: BlocQueryConstraint[],
    next: DatabaseControllerQuerySnapshotListener<T>,
    onError: BlocErrorFunction
  ): BlocUnsubscribeFunction => {
    // FIXME: remote dependency to local schema before deploying to mind-cloud
    const firestore = getFirestore(firebaseApp());

    const queryConstraints = this.mapConstraints(constraints);

    const queryRef = query<T, T>(
      collection(firestore, this.collectionPath) as any,
      ...queryConstraints
    );

    const error = (error: FirestoreError) => {
      console.error({
        at: 'firebase controller query onSnapshot',
        error,
      });
      onError(error);
    };

    const resolveQuery = (snapshot: QuerySnapshot<T>) => {
      next(snapshot.docs, snapshot.docChanges());
    };

    const resolveQueryWithConverter = (snapshot: QuerySnapshot<T>) => {
      const docs = snapshot.docs.map(this.convertDoc);

      const docChanges = snapshot.docChanges().map((change) => {
        return {
          ...change,
          doc: this.convertDoc(change.doc),
        };
      });

      next(docs, docChanges);
    };

    DEBUG && console.debug(`subscribe query: ${this.collectionPath}`);

    const listen = () => onSnapshot<T, T>(queryRef, {
      next: this.convert ? resolveQueryWithConverter : resolveQuery,
      error,
    });

    let unsubscribe = listen();

    const unsubscribePlatformEvents = platformEventSystem.subscribeForPlatformEvents(
      'user-auth-changed',
      () => {
        // Listen user auth state changes and re-subscribe to the query
        unsubscribe();
        unsubscribe = listen();
      }
    );

    return () => {
      DEBUG && console.debug(`unsubscribe query: ${this.collectionPath}`);
      unsubscribePlatformEvents();
      unsubscribe();
    };
  };
  /**
   *
   * @param {string}                                    docId
   * @param {DatabaseControllerDocumentSnapshotListener<T>}   next
   * @param {BlocErrorFunction}                         onError
   * @return {BlocUnsubscribeFunction}
   */
  public onDocumentSnapshot = (
    docId: string,
    next: DatabaseControllerDocumentSnapshotListener<T>,
    onError: BlocErrorFunction
  ): BlocUnsubscribeFunction => {
    const firestore = getFirestore(firebaseApp());

    const documentRef = doc<T, T>(
      collection(firestore, this.collectionPath) as any,
      docId
    );

    // let hasFailedBecauseOfPermissions = false;

    const error = (error: FirestoreError) => {
      // TODO: handle error
      console.error({
        at: 'firebase controller onDocumentSnapshot',
        error,
      });
      // if (error.code === 'permission-denied') {
      //   hasFailedBecauseOfPermissions = true;
      // }
      next({
        id: docId,
        data: () => null,
      });
      onError && onError(error);
    };

    const onDocumentSnapshot = (snapshot: DocumentSnapshot<T>) => {
      if (!snapshot.exists()) {
        next({
          id: snapshot.id,
          data: () => null,
        });
        return;
      }

      const data = this.convert.toFrontendFrom(snapshot.data());
      next({
        id: snapshot.id,
        data: () => data,
      });
    };

    const onSnap = (snapshot: DocumentSnapshot<T>) => {
      next(snapshot as any); // TODO: Fix types for TS5
    };

    DEBUG && console.debug(`subscribe doc: ${this.collectionPath}/${docId}`);

    const listen = () => onSnapshot(documentRef, {
      next: this.convert ? onDocumentSnapshot : onSnap,
      error,
    });

    let unsubscribe: (() => void) | null = listen();

    const unsubscribePlatformEvents = platformEventSystem.subscribeForPlatformEvents(
      'user-auth-changed',
      () => {
        // Listen user auth state changes and re-subscribe to the query
        unsubscribe?.();

        unsubscribe = listen();
      }
    );

    return () => {
      DEBUG && console.debug(`unsubscribe doc: ${this.collectionPath}/${docId}`);
      unsubscribePlatformEvents();
      unsubscribe?.();
    };
  };

  /**
   * Convert a single document with the converter.
   *
   * NOTE: For performance reasons, this method intentionally does assume that the
   * converter is defined.
   *
   * @param {DocumentSnapshot<T>} doc
   * @return {BlocDocumentSnapshot<T>}
   */
  private convertDoc = (doc: DocumentSnapshot<T>): BlocDocumentSnapshot<T> => {
    let data = doc.data();
    data = (data ? this.convert.toFrontendFrom(data) : undefined) as any;
    data = immutable(data);
    return {
      id: doc.id,
      data: () => data as any,
    };
  };

  /**
   * Map bloc constraints to Firestore Query constraints.
   * @param {BlocQueryConstraint[]} constraints
   * @return {QueryConstraint[]}    Firestore query constraints.
   */
  private mapConstraints = (constraints: BlocQueryConstraint[]) => {
    const result: QueryConstraint[] = [];

    constraints.forEach((c) => {
      const type = c.type;
      switch (type) {
        case 'limit':
          result.push(
            limit(c.limit)
          );
          break;
        case 'where':
          result.push(
            where(
              c.fieldPath,
              c.opStr,
              c.value
            ));
          break;
        case 'orderBy':
          result.push(
            orderBy(
              c.fieldPath,
              c.directionStr
            ));
          break;
      }
    });
    return result;
  };

  /**
   * Generate transformation map for the document based on schema's
   * document field configuration.
   *
   * @param {FirebaseSchemaDocumentModel} doc Schema's document model
   * @returns
   */
  private generateTransformMap = (
    doc: FirebaseSchemaDocumentModel<any, any>
  ): TransformMap<T> => {
    if (!doc) {
      throw new Error('Document model is not defined.');
    }
    const map: any = {};

    Object.entries(doc.fields).forEach(
      ([fieldName, field,]: any) => {
        if (field.serialized) {
          map[fieldName] = 'serialized';
        }
        if (field.dataType === 'datetime') {
          map[fieldName] = 'timestamp';
        }
      }
    );
    return map;
  };
}

export const registerFirebaseDatabaseController = <UserRoleId extends string, ShortUserRoleId extends string>(
  schema: FirestoreSchemaModel<UserRoleId, ShortUserRoleId>
) => {
  if (!schema) {
    throw new Error('Internal error: Schema model is not defined.');
  }
  const fun = <T extends DocumentData>({
    collectionPath,
  }: DatabaseControllerFactoryFunctionArgs) => {
    return new FirestoreDatabaseController<T>(collectionPath, schema);
  };
  globalCache.controllerFactory = fun as any; // TODO: Fix types for TS5
};

export default FirestoreDatabaseController;
