import type { PathParams } from '@shared/schema/src';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { debugSwitch } from 'utils/debug/debug';
import { globalCache } from './cache/BlocCache';
import { isDevelopmentMode } from '@mindhiveoy/foundation';
import { isEqual } from 'lodash';
import BlocBase from './BlocBase';
import normalizeDatabasePath from './utils/normalizeDatabasePath';
import parseDocumentPath from './utils/parseDocumentPath';
import type {
  BlocCacheUpdateOptions, BlocDeleteRemoteCall, BlocDocumentRemoteCall, BlocDocumentSnapshotListener,
  BlocErrorCode,
  BlocErrorFunction, BlocOptimisticCall, BlocUnsubscribeFunction, CacheConstructorArgsBase
} from './types';
import type { CachedCollectionReference } from './cache/CachedCollectionReference';
import type { CachedDocumentSnapshot } from './cache/DocumentReferenceSet';
import type { DocumentData } from '@mindhiveoy/firebase-schema';
import type { WithId } from '@mindhiveoy/schema';
import type CachedDocumentReference from './cache/CachedDocumentReference';

const DEBUG = debugSwitch(false);

export interface BlockDocumentConstructorArgs<T extends DocumentData, P = Partial<PathParams>> extends CacheConstructorArgsBase<T, P> {
  /**
   * Full document path
   */
  documentPath: string;
  /**
   * When disabled, no action or subscription is taken.
   */
  disabled?: boolean;
}

/**
 *
 * Features:
 * - Optimistic updates
 * - Single data controller listener for a path, which optimizes use the listeners with React
 *   or other reactive front end libraries.
 * - Trigger events only when documents have real changes
 *
 * TODO:
 * - Save delay mode to store changes locally for a moment to back possible multiple
 *   changes to same entity to an single update.
 */
class BlocDocument<T extends DocumentData, P = Partial<PathParams>> extends BlocBase<T, P> {
  protected onError?: BlocErrorFunction;
  public readonly collectionPath!: string;
  public readonly docId!: string;
  public readonly documentPath!: string;
  protected readonly documentReference!: CachedDocumentReference<T>;
  protected readonly collectionReference!: CachedCollectionReference<T>;
  /**
   * Constructor
   * @param {string}        props.documentPath  Path to the targeted document.
   * @param {ErrorFunction} props.onError        Error handler function.
   */
  constructor({
    cache = globalCache,
    converter,
    documentPath,
    params,
    invalidState = false,
    disabled,
    onError,
  }: BlockDocumentConstructorArgs<T, P>) {
    super(params, converter, invalidState || !documentPath, disabled);

    if (typeof window === 'undefined') {
      return;
    }
    if (!documentPath) {
      return;
    }
    this.documentPath = normalizeDatabasePath(documentPath);
    const {
      collectionPath,
      docId,
    } = parseDocumentPath(this.documentPath);

    this.collectionPath = collectionPath;
    this.docId = docId;
    this.onError = onError;

    const {
      collectionReference,
      documentReference,
    } = cache.addDocumentReference<T>(
      collectionPath,
      docId
    );
    this.documentReference = documentReference;
    this.collectionReference = collectionReference;

    if (!cache.controllerFactory) {
      throw new Error(`Cannot find controller factory method.`);
    }
    DEBUG && console.debug(`doc  : created: ${this.documentPath}`);
  }

  private _updateListener?: (data: T) => void;

  /**
   * Register the extended class to listen document changes. There can be only one listener
   * at the time.
   *
   * @param callback
   */
  protected registerUpdateListener = (callback: (data: T) => void) => {
    this._updateListener = callback;
  };
  /**
   * Dispose instance. Must be called to clean up resources before removing the
   * item from the cache.
   */
  public dispose = () => {
    this.collectionReference?.removeDocumentReference(
      this.docId
    );
    DEBUG && console.debug(`doc: disposed: ${this.documentPath}`);
  };

  protected async _create(
    onRemoteCall: BlocDocumentRemoteCall<T>,
    onError: BlocErrorFunction,
  ): Promise<WithId<T>>;
  protected async _create(
    onRemoteCall: BlocDocumentRemoteCall<T>,
    onError: BlocErrorFunction,
    onOptimisticResponse: BlocOptimisticCall<T>,
    onDocIdChange: (docId: string) => void
  ): Promise<WithId<T>>;
  /**
   * Create document
   * @param {BlocDocumentRemoteCall<T>} onRemoteCall
   * @param {BlocErrorFunction} onError
   * @param {BlocOptimisticCall<T>} onOptimisticResponse
   * @param {any} onDocIdChange
   */
  protected async _create(
    onRemoteCall: BlocDocumentRemoteCall<T>,
    onError: BlocErrorFunction,
    onOptimisticResponse?: BlocOptimisticCall<T>,
    onDocIdChange?: (docId: string) => void
  ): Promise<WithId<T>> {
    const resolveBackendResponse = async () => {
      try {
        const response = await onRemoteCall();

        const {
          _id, ...data
        } = response;

        this.documentReference.setData(data as unknown as T);

        this._updateListener?.(data as unknown as T);

        if (_id !== this.docId) {
          // TODO: Change document id in the cache
          this.collectionReference.changeDocumentId(this.docId, _id);
          onDocIdChange && onDocIdChange(_id);
        }
        return response;
      } catch (error: any) {
        this._handleError(onError)(error);
        throw error;
      }
    };
    if (onOptimisticResponse) {
      let response = onOptimisticResponse();
      if (response) {
        if (response instanceof Promise) {
          response = await response;
        }
        const data = {
          _candidate: true,
          ...response?.data,
        };
        this.documentReference.setData(data);
        this._updateListener?.(data as unknown as T);
        return {
          _id: response?._id,
          ...response?.data,
        };
      }
    }
    return resolveBackendResponse();
  };

  /**
   * Set document data.
   *
   * //TODO: Change to use object as parameter
   *
   * @param {BlocDocumentRemoteCall<T>} remoteCall
   * @param {BlocErrorFunction} onError
   * @param {BlocOptimisticCall<T>} optimisticResponse
   * @param {BlocCacheUpdateOptions} updateOptions  Update options for cache update.
   */
  protected _set = async (
    remoteCall: BlocDocumentRemoteCall<T>,
    onError?: BlocErrorFunction,
    optimisticResponse?: BlocOptimisticCall<T>,
    updateOptions?: BlocCacheUpdateOptions
  ): Promise<WithId<T> | undefined> => {
    try {
      if (optimisticResponse) {
        const resolveBackendResponse = async () => {
          const data = await remoteCall();
          return this.$update(this.preprocessDoc(data));
        };

        let data = undefined;

        try {
          data = optimisticResponse(
            this.documentReference.data ? Object.freeze({
              ...this.documentReference.data,
            }) : undefined
          );

          if (data instanceof Promise) {
            data = await data;
          }
        } catch (error) {
          console.error('Error in optimisticResponse', error);
        }
        // Optimistic update will be only done, when function returns a value
        // This will make it possible to do optional optimistic updates at the
        // front end, when there is enough data to derive optimistic response.
        if (data) {
          resolveBackendResponse();
          return this.$update(data as any, updateOptions);
        }
      }

      const data = await remoteCall();
      return this.$update(this.preprocessDoc(data));
    } catch (error: any) {
      this._handleError(onError)(error);
    }
  };

  /**
   * Delete document.
   *
   * @param {BlocDocumentRemoteCall<void>}  onRemoteCall  Remote call to implement the actual delete.
   * @param {BlocErrorFunction}             onError       Error handler function.
   * @param {boolean=}                      optimistic    When true, the deleted document will be removed
   *                                                      from the cache immediately. Default is true.
   */
  protected _delete = async (
    onRemoteCall: BlocDeleteRemoteCall,
    onError?: BlocErrorFunction,
    optimistic = true
  ): Promise<void> => {
    const resolveBackendResponse = async () => {
      try {
        await onRemoteCall();
        this.collectionReference.delete(this.docId);
      } catch (error: any) {
        // TODO: Sentry
        this._handleError(onError)(error);
      }
    };
    if (optimistic) {
      resolveBackendResponse();
      this.collectionReference.delete(this.docId);
      return;
    }
    await resolveBackendResponse();
  };
  /**
   * Get document data
   *
   * @return {WithId<T> | undefined}
   */
  public get = async (): Promise<WithId<T> | undefined> => {
    const docRef = await this.collectionReference.fetchDoc(this.docId);

    return docRef?.data ? this.preprocessDoc({
      _id: docRef.id,
      ...docRef.data,
    }) : undefined;
  };
  /**
   * Delete a  document in the collection.
   *
   * @param {CacheDocumentRemoteCrudOptions<T>} options
   */
  private $delete = async () => {
    return this.collectionReference.delete(
      this.docId
    );
  };

  /**
   * The current id of the document. Use this always to read
   * the document id, to assure the system to work also in the
   * document creation process.
   *
   * @return {string} the id of the document
   */
  public get id(): string {
    return this.documentReference.id;
  }

  /**
   * The current document data.
   */
  public get data(): T | null {
    return this.documentReference.data;
  }
  /**
   * Subscribe to listen document change changes.
   *
   * @param {BlocDocumentSnapshotListener<T>} onSnapshot
   * @param {BlocErrorFunction=} onError
   *
   * @return {BlocUnSubscribeFunction}
   */
  public subscribe(
    onSnapshot: BlocDocumentSnapshotListener<T>,
    onError?: BlocErrorFunction
  ): BlocUnsubscribeFunction;
  /**
   * Subscribe for document changes using list of fields interested by the subscriber.
   *
   * Example use:
   *
   * ```typescript
   * const unSubscribe = subscribe(
   *   ['name', 'description', 'media],
   *   (doc) => {
   *     // Will only trigger when one or more of above three fields has changed
   *   },
   *   error => // error handling here
   * );
   *
   * @param {keyof T} fields
   * @param {BlocDocumentSnapshotListener<T>} onSnapshot
   * @param {BlocErrorFunction=} onError
   *
   * @return {BlocUnSubscribeFunction}
   */
  public subscribe(
    fields: (keyof T)[],
    onSnapshot: BlocDocumentSnapshotListener<T>,
    onError?: BlocErrorFunction
  ): BlocUnsubscribeFunction;
  // eslint-disable-next-line require-jsdoc
  public subscribe(
    fieldsOrSnapshotListener: any, // (keyof T)[] | BlocDocumentSnapshotListener<T>,
    snapshotOrErrorListener: any, // BlocDocumentSnapshotListener<T> | BlocErrorFunction,
    possibleOnError?: any // BlocErrorFunction
  ): BlocUnsubscribeFunction {
    if (typeof window === 'undefined' || this.invalidState || this.disabled) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => { };
    }
    const {
      fields,
      onSnapshotListener,
      onError,
    } = this.$resolveSubscribeArguments(fieldsOrSnapshotListener, snapshotOrErrorListener, possibleOnError);
    // TODO: Remove argument resolving and drain it deeper

    const onSnapshot = (doc: CachedDocumentSnapshot<T>) => {
      if (!doc.exists) {
        onSnapshotListener(null);
        return;
      }
      const data = this.preprocessDoc({
        ...doc.data,
        _id: doc.id,
      } as WithId<T>);
      onSnapshotListener(data);
      this._updateListener?.(data);
    };

    if (fields) {
      return this.documentReference.subscribe(
        fields,
        onSnapshot,
        this._handleError(onError)
      );
    }
    return this.documentReference.subscribe(
      onSnapshot,
      this._handleError(onError)
    );
  }
  /**
   * Resolve method overloading arguments
   *
   * @param  {*}  fieldsOrSnapshotListener
   * @param  {*}  snapshotOrErrorListener
   * @param  {*}  possibleOnError
   * @return {*}
   */
  private $resolveSubscribeArguments = (
    fieldsOrSnapshotListener: (keyof T)[] | BlocDocumentSnapshotListener<T>,
    snapshotOrErrorListener: BlocDocumentSnapshotListener<T> | BlocErrorFunction, possibleOnError: BlocErrorFunction): {
      fields: any; onSnapshotListener: any;
      onError: any;
    } => {
    if (typeof fieldsOrSnapshotListener === 'function') {
      return {
        fields: undefined,
        onSnapshotListener: fieldsOrSnapshotListener,
        onError: snapshotOrErrorListener as any,
      };
    }
    return {
      fields: fieldsOrSnapshotListener,
      onSnapshotListener: snapshotOrErrorListener as any,
      onError: possibleOnError,
    };
  };
  /**
   * Set the specific document's data in the collection.
   * @param {Partial<T>}  data    The content of the document.
   * @param {any}         options Possible options for the set operation.
   */
  protected $update = async (data: Partial<T>, options?: BlocCacheUpdateOptions) => {
    // TODO: Validate if the document exists

    if (isEqual(data, this.documentReference.data)) {
      return;
    }
    try {
      this._updateListener?.(data as unknown as T);
    } catch (error) {
      console.error('Error in update listener', error);
    }

    return this.collectionReference.set(
      this.docId,
      data,
      options
    );
  };

  /**
   * Wrap up the error handling function with internal logging and
   * return the new error handler function.
   *
   * @param {BlocErrorFunction} onError Error handler function.
   * @returns {ErrorFunction} Error handler function.
   */
  protected _handleError = (onError?: BlocErrorFunction) => {
    return (error: Error) => {
      const code = (error as any).code;
      switch (code) {
        case 'permission-denied':
          this._reportError(error, 'permission-denied', onError);
          break;

        case 'not-found':
          this._reportError(error, 'not-found', onError);
          break;

        case 'unauthenticated':
          this._reportError(error, 'unauthenticated', onError);
          break;

        case 'cancelled':
          this._reportError(error, 'cancelled', onError);
          break;

        default:
          this._reportError(error, 'unknown', onError);
      }
    };
  };

  /**
   * Report an error.
   * @param error       The error object.
   * @param errorCode   The error code.
   * @param onError     The error handler function.
   */
  private _reportError = (error: Error, errorCode: BlocErrorCode, onError?: BlocErrorFunction) => {
    console.error(errorCode, {
      path: this.documentPath,
      error,
      errorCode,
    });

    if (isDevelopmentMode()) {
      alert(`Error: ${errorCode}: ${error.message}`);
    }

    onError?.(error, errorCode);
  };
}
export default BlocDocument;
