/* eslint-disable sonarjs/no-duplicate-string */
import { useComponentDataContext } from '../../../../utils/hooks/useComponentDataContext';
import HTMLInplaceEditor, { ALL_STYLES_ALLOWED, EMPHASIS_STYLES_ALLOWED } from '../../../../components/editing/HTMLInplaceEditor';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import React, { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import type { CSSProperties } from 'react';
import type { CanvasId, ContentQuestionCanvas, WithPathParamProps } from '../../../../@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export interface CanvasContentProps extends WithPathParamProps {
  canvasId: CanvasId;
  canvas: WithId<ContentQuestionCanvas>;
}

const editorTypographyStyleCentered: CSSProperties = {
  width: '100%',
  wordWrap: 'break-word',
};

const Container = styled.div`
  position: relative;    
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
`;

const CanvasContentEditor = ({
  canvas,
}: CanvasContentProps): JSX.Element => {
  const {
    updateProperty,
  } = useComponentDataContext<WithId<ContentQuestionCanvas>>();

  const handleChange = useCallback((
    key: string,
    value: string
  ) => {
    const v = value.trim();
    updateProperty(key as any, v);
  }, [updateProperty,]);

  return !canvas ?
    <LoadingIndicator /> :
    <Container>
      <Typography
        style={editorTypographyStyleCentered}
        component='span'
        variant="h2">
        <HTMLInplaceEditor
          text={canvas.name}
          name="name"
          textAlign='left'
          containerStyle={{
            padding: '0.5rem',
            width: '100%',
          }}
          allowedStyles={EMPHASIS_STYLES_ALLOWED}
          showToolbar
          onChange={handleChange}
        />
      </Typography>
      <Typography
        style={editorTypographyStyleCentered}
        component='span'
        variant="body1">
        <HTMLInplaceEditor
          text={canvas.desc}
          name="desc"
          textAlign='left'
          fluid
          containerStyle={{
            padding: '0.5rem',
            width: '100%',
          }}
          allowedStyles={ALL_STYLES_ALLOWED}
          saveOnEnterPress
          showToolbar
          onChange={handleChange}
        />

      </Typography>

    </Container>;
};

export default CanvasContentEditor;
