import 'vanilla-cookieconsent';
import { useEffect } from 'react';
import pluginConfig from './CookieConsentConfig';

const CookieConsent = () => {
    useEffect(() => {
        if (!document.getElementById('cc--main')) {
            window.CookieConsentApi = window.initCookieConsent();
            window.CookieConsentApi.run(pluginConfig);
        }
    }, []);

    return null;
};

export default CookieConsent;
