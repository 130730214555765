import { Typography } from '@mui/material';
import { mapVerticalAlign } from '../mapVerticalAlign';
import { useCallback, useMemo } from 'react';
import HTMLInplaceEditor, { ALL_STYLES_ALLOWED } from 'components/editing/HTMLInplaceEditor';
import theme from 'theme';
import type { CSSProperties } from 'react';
import type { FreeTextWidget, FreeTextWidgetContent } from '@shared/schema/src/spaces/projects/sessions/canvases';

const containerStyle = {
  width: '100%',
};

export interface FreeTextWidgetEditorPureProps {
  data: FreeTextWidgetContent;
  onSave?: (field: keyof FreeTextWidgetContent, value: string) => void;
}

export const FreeTextWidgetEditorPure = ({
  data: {
    body,
    title,
    subTitle,
    textAlign,
    verticalAlign,
  },
  onSave,
}: FreeTextWidgetEditorPureProps) => {
  const handleSaveFreeText = useCallback((name: any, value: string) => {
    onSave?.(name, value);
  }, [onSave,]);

  const verticalStyle = useMemo(() => {
    const result: CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: mapVerticalAlign(verticalAlign),
      position: 'relative',
      width: '100%',
      height: '100%',
      textAlign,
      maxHeight: '100%',
      overflowY: 'auto',
      padding: `${theme.spacing(2)}`,
    };
    return result;
  }, [textAlign, verticalAlign,]);

  return <div style={verticalStyle}>
    <Typography variant="h1">
      <HTMLInplaceEditor<FreeTextWidget>
        name="title"
        textAlign={textAlign}
        text={title}
        containerStyle={containerStyle}
        saveOnEnterPress
        allowedStyles={['bold', 'italic', 'underline',]}
        onChange={handleSaveFreeText} />
    </Typography>
    <Typography
      variant='caption'>
      <HTMLInplaceEditor
        name="subTitle"
        textAlign={textAlign}
        containerStyle={containerStyle}
        saveOnEnterPress
        text={subTitle}
        allowedStyles={['bold', 'italic', 'underline',]}
        onChange={handleSaveFreeText} />
    </Typography>
    <Typography variant='body1'>
      <HTMLInplaceEditor<FreeTextWidget>
        name="body"
        containerStyle={containerStyle}
        textAlign={textAlign}
        text={body}
        showToolbar
        allowedStyles={ALL_STYLES_ALLOWED}
        onChange={handleSaveFreeText} />
    </Typography>
  </div>;
};
