import { AnimatePresence, motion } from 'framer-motion';
import { ClickAwayListener, Typography } from '@mui/material';
import {
  MessageAttachmentType,
  MessageRecipientsOptionType, MessageRecipientsType, MessageScope
} from '@shared/schema/src';
import {
  cleanTagsFromHtmlString,
  generateUrlToCanvasOrSession, generateUrlToComment, minimizeComment
} from './messageUtils';
import { createMessage } from 'bloc/message/messageApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCanvases } from 'bloc/canvas/CanvasesBloc';
import { useCommentViewContext } from 'utils/hooks/useFilterViewContext';
import { useMessages } from './MessageContext';
import { useProject } from 'bloc/project/ProjectBloc';
import { useSession } from 'bloc/session/SessionBloc';
import { useTranslation } from 'next-i18next';
import MessageFAB from './MessageFAB';
import SimpleComponentForm from 'components/forms/SimpleComponentForm';
import styled from '@emotion/styled';
import theme from 'theme';
import usePathParams from 'utils/hooks/usePathParams';
import type { BuilderConfig } from 'components/builder/builders/componentBuilder';
import type {
  Canvas, Comment, MessageAttachment, MessageForm, MessageRecipientInput, ProjectMemberInfo, Session
} from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

let preventMessagingClickAway = false;

export const setPreventMessagingClickAway = (prevent: boolean) => {
  preventMessagingClickAway = prevent;
};

export const Container = styled.div`
  position: fixed;
`;

export const MessageWindow = styled(motion.div)`
  position: fixed;
  bottom: 24px;
  right: 0;
  z-index: 10000;
  padding: ${theme.spacing(1, 3)};
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${theme.palette.background.default};
  border: 2px solid ${theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: min(588px, 100vw);
  height: min(auto, 100vh);
  overflow-y: scroll;
`;

export const MessageWindowTitle = styled(Typography)(({
  theme,
}) => `
  ${theme.breakpoints.down('sm')} {
    font-size: 18px;
  }
  margin-left: ${theme.spacing(1)};
  font-size: 20px;
  font-weight: 500;
  text-align: left;
`);

export const MessageWindowHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const MessageFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: scroll;
  margin: auto;
  padding: ${theme.spacing(0, 0)};
`;

const windowClosedStyle = {
  opacity: 0,
  scale: 0.1,
  x: 200,
  y: 200,
};
const windowOpenStyle = {
  opacity: 1,
  scale: 1,
  x: -100,
  y: -80,
};
const windowTransitionStyle = {
  opacity: {
    duration: 0.9,
  },
};
const MessageComponent = () => {
  const {
    open,
    scope,
    selectedComment,
    selectedCanvas,
    openMessageWindowForScope,
    closeMessageWindow,
  } = useMessages();

  const pathParams = usePathParams();
  const canvasesBloc = useCanvases(pathParams);
  // const projectsBloc = useProjects(pathParams);
  // const sessionsBloc = useSessions(pathParams);
  // const [projects, setProjects,] = useState<WithId<Project>[]>([]);
  // const [currentProject, setCurrentProject,] = useState<WithId<Project>>();
  const [canvases, setCanvases,] = useState<WithId<Canvas>[]>([]);
  // const [sessions, setSessions,] = useState<WithId<Session>[]>([]);
  const [currentSession, setCurrentSession,] = useState<WithId<Session>>();
  const [allProjectMembers, setAllProjectMembers,] = useState<ProjectMemberInfo[]>([]);
  const [comments, setComments,] = useState<WithId<Comment>[]>([]);
  const [filteredRecipients, setFilteredRecipients,] = useState<ProjectMemberInfo[]>([]);

  const filterView = useCommentViewContext();

  useEffect(() => {
    if (filterView) {
      filterView?.subscribe((comments) => {
        setComments(comments);
      });
    }
  }, [filterView,]);

  const {
    t,
  } = useTranslation();

  const projectBloc = useProject();
  const sessionBloc = useSession();

  useEffect(() => projectBloc.subscribe((project) => {
    // setCurrentProject(project);
    setAllProjectMembers(project?.nicks || []);
  }), [projectBloc,]);

  useEffect(() => sessionBloc.subscribe(setCurrentSession),
    [sessionBloc, setCurrentSession,]
  );

  useEffect(() => canvasesBloc.subscribe(setCanvases),
    [canvasesBloc,]
  );

  /**
   * Close the window on escape key press
   */
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeMessageWindow();
        event.stopPropagation();
        event.preventDefault();
      }
    };
    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeMessageWindow,]);

  useEffect(() => {
    const recipients: ProjectMemberInfo[] = [];
    comments.forEach((comment) => {
      if (comment.creatorId && !recipients.find((recipient) => recipient.id === comment.creatorId)) {
        recipients.push({
          id: comment.creatorId,
          nick: comment.nick ?? '',
          avatar: comment.avatar,
        });
      }
    });
    setFilteredRecipients(recipients);
  }, [comments,]);

  const handleFABClick = useCallback(() => {
    if (open) {
      closeMessageWindow();
    } else {
      const canvas = canvases.find((canvas) => canvas._id === pathParams.canvasId);
      openMessageWindowForScope(MessageScope.GENERAL, undefined, canvas);
    }
  }, [open, closeMessageWindow, canvases, openMessageWindowForScope, pathParams.canvasId,]);

  const messageFormConfig = useMemo<BuilderConfig<MessageForm>>(() => ({
    displayName: 'Message',
    type: 'message-form',
    props: {
      subject: {
        displayName: t('subject'),
        defaultValue: '',
        type: 'text',
        required: true,
        onValidate: (value: any) => value && value.length > 0,
        message: `* ${t('subject is required')}`,
        // autoFocus: true,
      },
      attachment: {
        displayName: t('Session'),
        defaultValue: {},
        type: 'message-attachment',
        required: false,
        options: {
          canvasOptions: canvases,
          sessionOptions: currentSession,
        },
        xs: 6,
      },
      recipients: {
        displayName: t('Recipients'),
        defaultValue: [],
        type: 'message-recipients',
        required: true,
        message: `* ${t('Recipients are required')}`,
        options: {
          recipientsType: scope === MessageScope.FILTER ? MessageRecipientsType.FILTER : MessageRecipientsType.SELECTED,
          recipientsList: allProjectMembers,
          groupsList: [
            {
              id: 'a', name: 'All',
            },
            {
              id: 'f', name: 'Facilitators',
            },
            {
              id: 'p', name: 'Panelists',
            },
          ],
        },
        xs: 6,
      },
      message: {
        displayName: t('Message'),
        defaultValue: '',
        type: 'html',
        required: true,
        message: `* ${t('Message is required')}`,
      },
    },
  }), [t, canvases, currentSession, scope, allProjectMembers,]);

  const getInitialAttachments = useCallback(() => {
    let attachment: MessageAttachment;
    switch (scope) {
      case MessageScope.GENERAL:
        attachment = {
          type: MessageAttachmentType.SESSION,
          url: generateUrlToCanvasOrSession(pathParams),
          shortText: currentSession?.name ?? '',
          selectedSession: currentSession,
        };
        break;
      case MessageScope.FILTER:
        attachment = {
          type: MessageAttachmentType.CANVAS,
          url: generateUrlToCanvasOrSession(pathParams, selectedCanvas),
          shortText: selectedCanvas?.name ?? '',
          selectedCanvas,
        };
        break;
      case MessageScope.DIRECT:
        attachment = {
          type: MessageAttachmentType.COMMENT,
          url: generateUrlToComment(pathParams, selectedComment),
          shortText: minimizeComment(selectedComment?.text ?? ''),
          longText: cleanTagsFromHtmlString(selectedComment?.text ?? ''),
          selectedComment,
        };
        break;
      default:
        attachment = {
          type: MessageAttachmentType.SESSION,
          url: '',
          shortText: '',
        };
    }
    return attachment;
  }, [currentSession, pathParams, scope, selectedCanvas, selectedComment,]);

  const getInitialRecipients = useCallback((): MessageRecipientInput => {
    switch (scope) {
      case MessageScope.GENERAL:
        return {
          type: MessageRecipientsOptionType.GROUP,
          group: {
            id: 'a', name: 'All',
          },
        };
      case MessageScope.FILTER:
        return {
          type: MessageRecipientsOptionType.PARTICIPANTS,
          selectedRecipients: [...filteredRecipients,],
        };
      case MessageScope.DIRECT:
        return {
          type: MessageRecipientsOptionType.PARTICIPANTS,
          selectedRecipients: [
            {
              id: selectedComment?.creatorId ?? '',
              nick: selectedComment?.nick ?? '',
              avatar: selectedComment?.avatar,
            },
          ],
        };
      default:
        return {
          type: MessageRecipientsOptionType.PARTICIPANTS,
          selectedRecipients: [],
        };
    }
  }, [filteredRecipients, scope, selectedComment?.avatar, selectedComment?.creatorId, selectedComment?.nick,]);

  const getInitialData = useCallback(() => {
    return {
      subject: '',
      message: '',
      attachment: getInitialAttachments(),
      recipients: getInitialRecipients(),
    };
  }, [getInitialAttachments, getInitialRecipients,]);

  const handleSave = useCallback(async (data: MessageForm) => {
    closeMessageWindow();
    await createMessage({
      message: {
        ...data,
        context: 'project',
        scope,
        pathParams,
      },
    });
  }, [closeMessageWindow, pathParams, scope,]);

  const handleClickAway = useCallback(() => {
    if (preventMessagingClickAway) {
      return;
    }
    closeMessageWindow();
  }, [closeMessageWindow,]);

  return <Container>
    <AnimatePresence>
      {open ?
        <ClickAwayListener
          key='message-window'
          onClickAway={handleClickAway}
        >
          <MessageWindow
            data-testid="message-window"
            initial={windowClosedStyle}
            animate={windowOpenStyle}
            exit={windowClosedStyle}
            transition={windowTransitionStyle}
          >
            <MessageWindowTitle>
              {t('Send message')}
            </MessageWindowTitle>
            <MessageFormContainer>
              <SimpleComponentForm<MessageForm>
                config={messageFormConfig}
                initialData={getInitialData()}
                saveButtonText={t('Send')}
                onSave={handleSave}
                fullScreenSaving
                bgcolor={theme.palette.background.default}
              />
            </MessageFormContainer>
          </MessageWindow>
        </ClickAwayListener> :
        null
      }
    </AnimatePresence>
    <MessageFAB onClick={handleFABClick} />
  </Container>;
};

export default MessageComponent;
