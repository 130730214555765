import { limitToBounds } from './dragUtils';
import type { Axis, Axis2D, Coord2D, TickLabelingType } from '@shared/schema/src';

export const indexToAnswer2D = (
  axis: Axis2D,
  xIndex: number,
  yIndex: number): Coord2D => {
  const x = axis.x.range[limitToBounds(xIndex, axis.x)].value;
  const y = axis.y.range[axis.x.range.length - 1 - limitToBounds(yIndex, axis.y)].value;

  return {
    x,
    y,
  };
};

export const answerToIndex = (scaleType: TickLabelingType, axis: Axis, value: number, allowUndefined = false): number | undefined => {
  // TODO: Find out why this is needed
  // if (scaleType === 'percentage' && (value === null || value === undefined)) {
  //   return axis?.range?.findIndex((v) => v.value === 50);
  // }
  if (value === undefined && allowUndefined) {
    return undefined;
  }
  const val = value !== undefined ? value : 0;
  return axis?.range?.findIndex((v) => v.value === val);
};

export const answerValueToIndexX = (axis: Axis2D, value?: number): number => {
  return answerToIndex(axis.scaleType, axis.x, value!) as number;
};

export const answerValueToIndexY = (axis: Axis2D, value?: number): number => {
  return answerToIndex(axis.scaleType, axis.y, value!) as number;
};
