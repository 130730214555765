import { COMMAND_PRIORITY_EDITOR, PASTE_COMMAND } from 'lexical';
import { extractAllowedTags } from '../extractAllowedTags';
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import sanitize from 'sanitize-html';
import type { AllowedStyle } from '..';
import type { LexicalEditor } from 'lexical';

interface EditorPastePluginProps {
  allowedStyles?: AllowedStyle[];
  onPaste: (html: string, editor: LexicalEditor) => void;
}
export const EditorPastePlugin = ({
  allowedStyles,
  onPaste,
}: EditorPastePluginProps) => {
  const [editor,] = useLexicalComposerContext();

  useEffect(() => {
    editor.registerCommand<ClipboardEvent>(
      PASTE_COMMAND,
      (event, editor) => {
        const pastedText = event.clipboardData?.getData('text/html');

        const allowedTags = extractAllowedTags(allowedStyles);

        const html = sanitize(pastedText ?? '', {
          allowedTags,
          allowedAttributes: {
            a: ['href',],
          },
        });

        onPaste(html, editor);

        event.preventDefault();
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [allowedStyles, editor, onPaste,]);

  return null;
};
