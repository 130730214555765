
import { motion } from 'framer-motion';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import React, { useCallback, useMemo, useState } from 'react';
import SelectComponentButton from './SelectComponentButton';
import componentBuilder from '../builders/componentBuilder';
import theme from 'theme';
import type { BuilderComponentType } from './PageWidgetRenderer';
import type { CSSProperties } from 'react';
import type { PopperPlacementType } from '@mui/material/Popper';

export type SelectComponentEvent = (
  type: BuilderComponentType,
) => void;

interface InsertComponentDialogProps {
  /**
   * When true, the dialog will be shown.
   */
  open: boolean;
  /**
   * Reference to an React element where the dialog should be anchored, if any.
   */
  anchorEl: HTMLElement | null;
  placement: PopperPlacementType;
  /**
   * List of component types to be shown in dialog
   */
  componentTypes: BuilderComponentType[];
  /**
   * Event handler to fire when component is being selected
   */
  onSelect: SelectComponentEvent;
  /**
   * Event handler fired when dialog should be closed.
   */
  onClose: () => void;
}
const boxStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  maxWidth: '60%',
};

const SelectComponentDialog = ({
  componentTypes,
  anchorEl,
  open,
  placement,
  onClose,
  onSelect,
}: InsertComponentDialogProps
) => {
  const [typeConfigs,] = useState(() => componentBuilder.getConfigs(componentTypes));

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose,]);

  const handleSelectClick: SelectComponentEvent = useCallback((
    type
  ) => {
    onSelect && onSelect(type);
    handleClose();
  }, [handleClose, onSelect,]);

  const sxStyle: CSSProperties = useMemo(() => ({
    p: 1,
    backgroundColor: '#eee',
    borderRadius: `${(theme.shape.borderRadius as number) / 2}px`,
    boxShadow: '5px 7px 14px 1px #444444',
    width: '100%',
    zIndex: 10000,
  }), []);

  return <Popper key="popup-menu"
    placement={placement}
    id="insert-component-dialog"
    open={open}
    anchorEl={anchorEl}
    style={{
      zIndex: 10000,
    }}
    nonce={undefined}
    onResize={undefined}
    onResizeCapture={undefined} >
    {open &&
      <motion.div
        key="insert-dialog"
        initial={{
          rotateX: -90,
          scale: 0,
        }}
        animate={{
          rotateX: 0,
          scale: 1,
        }}
        exit={{
          rotateX: 90,
          scale: 0,
        }}
        style={{
          zIndex: 10000,
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            sx={sxStyle}
            style={boxStyle}
          >
            {typeConfigs.map((
              {
                displayName,
                icon,
                type,
              }) =>
              <SelectComponentButton
                key={type}
                type={type}
                icon={icon}
                displayName={displayName!}
                onSelect={handleSelectClick}
              />
            )}
          </Box>
        </ClickAwayListener>
      </motion.div>
    }
  </Popper>;
};

SelectComponentDialog.displayName = 'InsertComponentDialog';

export default SelectComponentDialog;
