import { schema, ShortUserRoleId } from '../@shared/schema/src/schema';
import config from '../config';
import { CustomerDocDocumentReference, CustomersCollectionReference } from './customers';
import { PaymentDocDocumentReference, PaymentsCollectionReference } from './customers/payments';
import { InquiriesCollectionReference, InquiryDocDocumentReference } from './inquiries';
import { InvitationDocumentReference, InvitationsCollectionReference } from './invitations';
import { EmailDataDocumentReference, MailCenterCollectionReference } from './mailCenter';
import { MessageCenterCollectionReference, MessageDocDocumentReference } from './messageCenter';
import { PageDocDocumentReference, PagesCollectionReference } from './pages';
import { SpaceDocumentReference, SpacesCollectionReference } from './spaces';
import { InvoiceDocDocumentReference, InvoicesCollectionReference } from './spaces/invoices';
import { MemberDocumentReference, MembersCollectionReference } from './spaces/members';
import { ProjectDocumentReference, ProjectsCollectionReference } from './spaces/projects';
import { BotDocDocumentReference, BotsCollectionReference } from './spaces/projects/bots';
import { FeedCollectionReference, FeedItemDocumentReference } from './spaces/projects/feed';
import { PanelDocumentReference, PanelsCollectionReference } from './spaces/projects/panels';
import { ProjectMemberDocumentReference, ProjectMembersCollectionReference } from './spaces/projects/projectMembers';
import { ProjectMessageDocDocumentReference, ProjectMessagesCollectionReference } from './spaces/projects/projectMessages';
import { DraftSessionDocumentDocumentReference, SessionDraftsCollectionReference } from './spaces/projects/sessionDrafts';
import { SessionDocumentReference, SessionsCollectionReference } from './spaces/projects/sessions';
import { CanvasDraftsCollectionReference, DraftCanvasDocumentDocumentReference } from './spaces/projects/sessions/canvasDrafts';
import { CanvasDocumentReference, CanvasesCollectionReference } from './spaces/projects/sessions/canvases';
import { AnswerDocumentReference, AnswersCollectionReference } from './spaces/projects/sessions/canvases/answers';
import { CommentDocumentReference, CommentsCollectionReference } from './spaces/projects/sessions/canvases/comments';
import { SessionStatisticsCollectionReference, SessionStatisticsDocumentReference } from './spaces/projects/sessions/sessionStatistics';
import { ProjectSettingDocDocumentReference, SettingsCollectionReference } from './spaces/projects/settings';
import { TemplateDocDocumentReference, TemplatesCollectionReference } from './spaces/projects/templates';
import { SpaceSettingsCollectionReference, SpaceSettingsDocumentReference } from './spaces/spaceSettings';
import { AccountTransactionDocDocumentReference, TransactionsCollectionReference } from './spaces/transactions';
import { UserDocumentReference, UsersCollectionReference } from './users';
import { MessagesCollectionReference, UserMessageDocDocumentReference } from './users/messages';
import { NotificationDocDocumentReference, NotificationsCollectionReference } from './users/notifications';
import { UserSettingsCollectionReference, UserSettingsDocumentReference } from './users/userSettings';
import { UserProjectInfoDocumentReference, UsersProjectsCollectionReference } from './users/usersProjects';
import { bootstrapRoles } from '@mindhiveoy/auth';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { browserSessionPersistence, connectAuthEmulator, getAuth, setPersistence } from 'firebase/auth';
import { connectFirestoreEmulator, Firestore, getFirestore, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

declare module 'firebase/firestore' {
  /**
   * Gets a `CollectionReference` instance that refers to the collection at
   * the specified absolute path.
   *
   * @param firestore - A reference to the root Firestore instance.
   * @param collectionName - A name of the root collection.
   * argument.
   * @throws If the final path has an even number of segments and does not point
   * to a collection.
   * @returns The `CollectionReference` instance.
   */

function collection(
  ref: SpaceSettingsDocumentReference,
  collectionName: 'spaceSettings'
): SpaceSettingsCollectionReference;
function collection(
  ref: MemberDocumentReference,
  collectionName: 'members'
): MembersCollectionReference;
function collection(
  ref: ProjectDocumentReference,
  collectionName: 'projects'
): ProjectsCollectionReference;
function collection(
  ref: InvoiceDocDocumentReference,
  collectionName: 'invoices'
): InvoicesCollectionReference;
function collection(
  ref: AccountTransactionDocDocumentReference,
  collectionName: 'transactions'
): TransactionsCollectionReference;
function collection(
  ref: BotDocDocumentReference,
  collectionName: 'bots'
): BotsCollectionReference;
function collection(
  ref: ProjectMessageDocDocumentReference,
  collectionName: 'projectMessages'
): ProjectMessagesCollectionReference;
function collection(
  ref: FeedItemDocumentReference,
  collectionName: 'feed'
): FeedCollectionReference;
function collection(
  ref: ProjectSettingDocDocumentReference,
  collectionName: 'settings'
): SettingsCollectionReference;
function collection(
  ref: SessionDocumentReference,
  collectionName: 'sessions'
): SessionsCollectionReference;
function collection(
  ref: DraftSessionDocumentDocumentReference,
  collectionName: 'sessionDrafts'
): SessionDraftsCollectionReference;
function collection(
  ref: PanelDocumentReference,
  collectionName: 'panels'
): PanelsCollectionReference;
function collection(
  ref: ProjectMemberDocumentReference,
  collectionName: 'projectMembers'
): ProjectMembersCollectionReference;
function collection(
  ref: TemplateDocDocumentReference,
  collectionName: 'templates'
): TemplatesCollectionReference;
function collection(
  ref: CanvasDocumentReference,
  collectionName: 'canvases'
): CanvasesCollectionReference;
function collection(
  ref: DraftCanvasDocumentDocumentReference,
  collectionName: 'canvasDrafts'
): CanvasDraftsCollectionReference;
function collection(
  ref: SessionStatisticsDocumentReference,
  collectionName: 'sessionStatistics'
): SessionStatisticsCollectionReference;
function collection(
  ref: AnswerDocumentReference,
  collectionName: 'answers'
): AnswersCollectionReference;
function collection(
  ref: CommentDocumentReference,
  collectionName: 'comments'
): CommentsCollectionReference;
function collection(
  ref: NotificationDocDocumentReference,
  collectionName: 'notifications'
): NotificationsCollectionReference;
function collection(
  ref: UserMessageDocDocumentReference,
  collectionName: 'messages'
): MessagesCollectionReference;
function collection(
  ref: UserSettingsDocumentReference,
  collectionName: 'userSettings'
): UserSettingsCollectionReference;
function collection(
  ref: UserProjectInfoDocumentReference,
  collectionName: 'usersProjects'
): UsersProjectsCollectionReference;
function collection(
  ref: PaymentDocDocumentReference,
  collectionName: 'payments'
): PaymentsCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'spaces'
): SpacesCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'pages'
): PagesCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'users'
): UsersCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'customers'
): CustomersCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'invitations'
): InvitationsCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'inquiries'
): InquiriesCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'messageCenter'
): MessageCenterCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'mailCenter'
): MailCenterCollectionReference;

function doc(
  ref: SpacesCollectionReference,
  spaceId: string
): SpaceDocumentReference;
function doc(
  ref: SpaceSettingsCollectionReference,
  spaceSettingId: string
): SpaceSettingsDocumentReference;
function doc(
  ref: MembersCollectionReference,
  memberId: string
): MemberDocumentReference;
function doc(
  ref: ProjectsCollectionReference,
  projectId: string
): ProjectDocumentReference;
function doc(
  ref: BotsCollectionReference,
  botId: string
): BotDocDocumentReference;
function doc(
  ref: ProjectMessagesCollectionReference,
  messageId: string
): ProjectMessageDocDocumentReference;
function doc(
  ref: FeedCollectionReference,
  feedItemId: string
): FeedItemDocumentReference;
function doc(
  ref: SettingsCollectionReference,
  settingId: string
): ProjectSettingDocDocumentReference;
function doc(
  ref: SessionsCollectionReference,
  sessionId: string
): SessionDocumentReference;
function doc(
  ref: CanvasesCollectionReference,
  canvasId: string
): CanvasDocumentReference;
function doc(
  ref: AnswersCollectionReference,
  answerId: string
): AnswerDocumentReference;
function doc(
  ref: CommentsCollectionReference,
  commentId: string
): CommentDocumentReference;
function doc(
  ref: CanvasDraftsCollectionReference,
  canvasId: string
): DraftCanvasDocumentDocumentReference;
function doc(
  ref: SessionStatisticsCollectionReference,
  statId: string
): SessionStatisticsDocumentReference;
function doc(
  ref: SessionDraftsCollectionReference,
  draftId: string
): DraftSessionDocumentDocumentReference;
function doc(
  ref: PanelsCollectionReference,
  panelId: string
): PanelDocumentReference;
function doc(
  ref: ProjectMembersCollectionReference,
  memberId: string
): ProjectMemberDocumentReference;
function doc(
  ref: TemplatesCollectionReference,
  templateId: string
): TemplateDocDocumentReference;
function doc(
  ref: InvoicesCollectionReference,
  invoiceId: string
): InvoiceDocDocumentReference;
function doc(
  ref: TransactionsCollectionReference,
  transactionId: string
): AccountTransactionDocDocumentReference;
function doc(
  ref: PagesCollectionReference,
  pageId: string
): PageDocDocumentReference;
function doc(
  ref: UsersCollectionReference,
  uid: string
): UserDocumentReference;
function doc(
  ref: NotificationsCollectionReference,
  notificationId: string
): NotificationDocDocumentReference;
function doc(
  ref: MessagesCollectionReference,
  messageId: string
): UserMessageDocDocumentReference;
function doc(
  ref: UserSettingsCollectionReference,
  settingId: string
): UserSettingsDocumentReference;
function doc(
  ref: UsersProjectsCollectionReference,
  projectId: string
): UserProjectInfoDocumentReference;
function doc(
  ref: CustomersCollectionReference,
  customerId: string
): CustomerDocDocumentReference;
function doc(
  ref: PaymentsCollectionReference,
  paymentId: string
): PaymentDocDocumentReference;
function doc(
  ref: InvitationsCollectionReference,
  invitationToken: string
): InvitationDocumentReference;
function doc(
  ref: InquiriesCollectionReference,
  inquiryId: string
): InquiryDocDocumentReference;
function doc(
  ref: MessageCenterCollectionReference,
  messageId: string
): MessageDocDocumentReference;
function doc(
  ref: MailCenterCollectionReference,
  emailToken: string
): EmailDataDocumentReference;
}
export type ContextParams = ProjectContextParams | SpaceContextParams | PlatformContextParams;

export type ProjectContextParams = {
  contextId: 'project';
  params: {
    spaceId: string;
    projectId: string;
  }
}

export type SpaceContextParams = {
  contextId: 'space';
  params: {
    spaceId: string;
  }
}

export type PlatformContextParams = {
  contextId: 'platform';
  params: {
  }
}

export type ContextId = 'project' | 'space' | 'platform';

bootstrapRoles(schema.roles);

let app: FirebaseApp;

export const firebaseApp = () => {
  if (app) {
    return app;
  }

  const isDevelopmentMode = config.environment.target === 'development' && !config.environment.disableEmulator;

  try {
    app = initializeApp(config.firebase) as FirebaseApp;

    // TODO include proxy logic. This is just a temporal build to give more time to do the actual framework
    // app = proxyApp(firebase as firebase.app.App) as any;

    if (isDevelopmentMode && schema.packages) {

      const host = 'localhost';
      console.log(`Debugger host: ${host}`);

     connectAuthEmulator(getAuth(app), `http://${host}:9099`, { disableWarnings: true });
      console.info('Running firebase auth emulator on port 9099.');

     connectFunctionsEmulator(getFunctions(app), host, 5001);
      console.info('Running firebase functions emulator on port 5001.');

      connectFirestoreEmulator(getFirestore(app), host, 8080);
      console.info('Running firebase firestore emulator on port 8080.');

     connectStorageEmulator(getStorage(app), host, 9199);
      console.info('Running firebase storage emulator on port 8080.');

    }
    if (!isDevelopmentMode && typeof window !== 'undefined') {
      /*
        * Firebase cloud functions's version of auth do not have setPersistence function. So we need to check its
        * existence dynamically and also call it dynamically
        */
      const auth = getAuth(app);

      setPersistence(auth, browserSessionPersistence)
        .then(() => {
        console.debug('Session persistance enabled.')
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // TODO Sentry
      });

      initializeFirestore(app, {
        localCache:
          persistentLocalCache({
            tabManager: persistentMultipleTabManager()
          })
      });
    }
  } catch (err: any) {
    // we skip the "already exists" message which is
    // not an actual error when we're hot-reloading
    if (!/already exists/.test(err.message)) {
      console.error('Firebase initialization error', err.message);
    }
  }
  return app;
};

