/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BackgroundFade, Card, Column, Reflection, SubTitle, TextArea, Title, selectedScale, selectedStyle
} from '.';
import { styled } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import type { CardData, CardStripMode } from '.';
// import { IconButtons, PlaceForIcons } from 'components/project/ProjectItem';
import { useMotionValue } from 'framer-motion';
import { useTheme } from '@emotion/react';
// import ArchiveIcon from '@mui/icons-material/Archive';
import ClickAwayListener from '@mui/material/ClickAwayListener';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'next-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Grow from '@mui/material/Grow';
import MediaContainer from 'components/layout/MediaContainer';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled$ from 'utils/react/styled$';
import type { CSSProperties } from 'react';

const StyledActions = styled(CardActions)`
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

const IconContainer = styled$.div`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 4vh;
  @media(max-width: 600px) {
    bottom: 7vh;
  }
`;

export interface CardStripItemWithIconsProps {
  canvasStrip?: boolean,
  card: CardData;
  // canvas: Canvas;
  cardWidth: number;
  cardHeight: number;
  selected?: boolean;
  style?: CSSProperties;
  mode?: CardStripMode;
  reflection?: boolean;
  menu?: boolean;
  showAnswerIcon?: boolean;
  setLoading?: (loading: boolean) => void;
  setLoadingTitle?: (loadingTitle: string) => void;
  onClick: (id: string) => void;
  onDelete?: (id: string) => void;
  onDuplicate?: (id: string) => void;
  onEdit?: (id: string) => void;
  onArchive?: (id: string) => void;
}

const mediaStyle: CSSProperties = {
  position: 'absolute',
  zIndex: -10,
  // width: '100%',
};

const CanvasMiniatureItem = ({
  canvasStrip,
  card,
  cardHeight,
  cardWidth,
  reflection,
  selected,
  style,
  menu = false,
  showAnswerIcon,
  setLoading,
  setLoadingTitle,
  onClick,
  onDelete,
  onDuplicate,
  // onArchive,
  // onEdit,
}: CardStripItemWithIconsProps) => {
  const [hover, setHover,] = useState<number>(1);
  const anchorRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  // const [iconsOn, setIconsOn,] = useState<boolean>(false);
  const [open, setOpen,] = useState<boolean>(false);

  const {
    t,
  } = useTranslation();

  const handleClick = useCallback((
    event: React.MouseEvent | React.TouchEvent | React.PointerEvent
  ) => {
    onClick && onClick(card._id);
    event.stopPropagation();
  }, [card._id, onClick,]);

  const selectedMultiplier = selected ? 1.15 : 1;

  const handleHover = useCallback(() => {
    // setIconsOn(true);
    setHover(1.05);
  }, []);

  const handleBlur = useCallback(() => {
    // setIconsOn(false);
    setHover(1);
  }, []);

  const y = useMotionValue(0);

  const image = card.image ?? theme.shape.imagePlaceholder;

  const handleContextMenuClick = useCallback((event: React.MouseEvent | React.TouchEvent) => {
    setOpen(true);

    event.stopPropagation();
    event.preventDefault();
  }, []);

  const handleCloseClick = useCallback(() => {
    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback(() => {
    // TODO: event handling
    alert(`${t('key-events-are-not-implemented-yet')}`);
  }, [t,]);

  const handleDuplicateClick = useCallback((event: React.MouseEvent) => {
    setOpen(false);
    setLoading?.(true);
    setLoadingTitle?.(`${t('duplicating-canvas')}`);
    onDuplicate && onDuplicate(card._id);
    event.stopPropagation();
    event.preventDefault();
  }, [card._id, onDuplicate, setLoading, setLoadingTitle, t,]);

  const handleDeleteClick = useCallback((event: React.MouseEvent) => {
    setOpen(false);
    setLoading?.(true);
    setLoadingTitle?.(`${t('duplicating-canvas')}`);
    onDelete && onDelete(card._id);
    event.stopPropagation();
    event.preventDefault();
  }, [card._id, onDelete, setLoading, setLoadingTitle, t,]);

  const cardStyle = useMemo(() => selected ? {
    ...style,
    ...selectedStyle,
    ...!card.media && {
      background: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
  } : {
    ...style,
    ...!card.media && {
      background: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
  }, [card.media, image, selected, style,]);

  return <Column
    ref={anchorRef}
    onHoverStart={handleHover}
    onHoverEnd={handleBlur}
    style={{
      y,
      z: 0,
      position: 'relative',
    }}
    onContextMenu={handleContextMenuClick}
    onBlur={handleCloseClick}
  >
    <Card
      $cardWidth={cardWidth}
      $cardHeight={cardHeight}
      color={card.media?.color}
      onClick={handleClick}
      onTap={handleClick as any}
      initial={{
        scale: 1,
      }}
      animate={{
        scale: hover * selectedMultiplier,
        y: selected ? -cardHeight * (selectedScale - 1) / 2 : 0,
      }}
      whileTap={{
        scale: selectedMultiplier * 0.9,
      }}
      style={{
        ...selected && selectedStyle,
        ...!card.media && {
          background: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        },
        position: 'relative',
        // opacity: selected ? 1 : 0.9,
      }}
    >
      <BackgroundFade />
      <MediaContainer
        style={mediaStyle}
        media={card.media}
      />
      <TextArea>
        <Title $canvasStrip={canvasStrip}
          dangerouslySetInnerHTML={{
            __html: card.title ?? '',
          }}
        />
        <SubTitle>{card.subTitle}</SubTitle>
      </TextArea>

    </Card>
    {reflection && <Reflection
      animate={{
        scaleX: hover,
      }}
    >
      <Card
        $cardWidth={cardWidth}
        $cardHeight={cardHeight}
        color={card.media?.color}
        initial={{
          scaleX: 1,
          scaleY: -1,
        }}
        animate={{
          scaleX: selectedMultiplier,
          scaleY: -selectedMultiplier,
          y: selected ?
            -cardHeight * (selectedScale - 1) / 2 :
            hover > 1 ? -4 : 0,
        }}
        style={cardStyle}
      >
        <BackgroundFade />
        <MediaContainer
          style={{
            position: 'absolute',
            zIndex: -10,
            width: '100%',
          }}
          media={card.media}>
          <StyledActions>
            <TextArea>
              <Title>{card.title}</Title>
              <SubTitle>{card.subTitle}</SubTitle>
            </TextArea>
          </StyledActions>
        </MediaContainer >
      </Card>
    </Reflection>}

    {showAnswerIcon && <IconContainer>
      <CheckCircleIcon color={/* isAnswered ? 'primary' : */'inherit'} /> {/* TODO: uncomment when answered logic is implemented */}
    </IconContainer>}

    {menu && canvasStrip && <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="top"
      transition
      disablePortal
      style={{
        zIndex: 10000,
      }}
      nonce={undefined}
      onResize={undefined}
      onResizeCapture={undefined} >
      {({
        TransitionProps,
      }) =>
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: 'left bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleCloseClick}>
              <MenuList
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem onClick={handleDuplicateClick}>{t('duplicate')}</MenuItem>
                <MenuItem onClick={handleDeleteClick}>{t('delete')}</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      }
    </Popper>}

  </Column>;
};

export default CanvasMiniatureItem;
