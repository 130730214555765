
import { Module } from '@mindhiveoy/auth';

const m = (module: Module) => module;

const modules = {
  'ai-personas': m({
    name: 'Replace with a real one, when we have on',
  }),
};

export type ModuleId = keyof typeof modules;
