import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

export const areEqual = (a: any, b: any): boolean => {
  const x = cloneDeep(a);
  const y = cloneDeep(b);

  delete x._changeId;
  delete y._changeId;
  delete x._status;
  delete y._status;
  return isEqual(x, y);
};
