import { keyframes } from '@emotion/react';
import { motion } from 'framer-motion';
import { useMediaQuery } from '@mui/material';
import Chip from '@mui/material/Chip';
import React, { useCallback } from 'react';
import styled$ from 'utils/react/styled$';
import theme, { chipColors } from '../../theme';
import type { CSSProperties } from 'react';
import type { ChipType } from '../../theme';
import type { SvgIconProps } from '@mui/material';

const animation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const StyledChip = styled$(motion(Chip))<{
  $status: ChipType;
}>(({
  $status = 'panelist',
}) => `
  background-color: ${chipColors?.[$status]?.backgroundColor};
  color: ${chipColors?.[$status]?.color};
  max-height: 1.5rem;
  .MuiChip-label {
    vertical-align: middle;
  }
    @media (max-width: 600px) {
    font-size: 0.7rem;
    }
  ${$status === 'live' &&
  `background: linear-gradient(300deg,#4cd01b,#bddbb4,#4aaf2b,#bce5ad); ` +
  `background-size: 240% 240%; ` +
  `animation: ${animation} 2s ease infinite;`}
'
`);

const HIDDEN = {
  opacity: 0,
};
const VISIBLE = {
  opacity: 1,
};

export interface StateChipProps {
  status?: ChipType;
  icon?: React.ReactElement<SvgIconProps>;
  deleteIcon?: React.ReactElement<SvgIconProps>;
  style?: CSSProperties;
  showOnMobile?: boolean;
  layout?: boolean;
  label?: string;
  layoutId?: string;
  onDelete?: (label: string) => void;
}

const StatusChip = ({
  status = 'panelist',
  icon,
  deleteIcon,
  showOnMobile = false,
  style,
  layout,
  layoutId,
  label = status,
  onDelete,
}: StateChipProps) => {
  const handleDelete = useCallback((event: React.MouseEvent) => {
    onDelete?.(label);
    event.stopPropagation();
  }, [onDelete, label,]);

  const hide = useMediaQuery(theme.breakpoints.down('md')) && !showOnMobile;

  if (hide) {
    return null;
  }

  return <StyledChip
    layout={layout ? 'position' : undefined}
    exit={HIDDEN}
    animate={VISIBLE}
    initial={HIDDEN}
    layoutId={layoutId}
    $status={status}
    style={style}
    key={status}
    label={label}
    icon={icon}
    deleteIcon={deleteIcon}
    onDelete={onDelete ? handleDelete : undefined} />;
};

export default StatusChip;
