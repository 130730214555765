import { MemberId, URL, WithId } from '@mindhiveoy/schema';
import { MentionInfo } from '../../../project';
import { Quadrant } from '../answers';
import { WithCreationInfo, WithModificationInfo } from '../../../../../common';

export type CommentId = string;

export type VoteNature = 'up' | 'down';
export type VoteNatureShort = 'u' | 'd';

interface MiniatureAnswerBase {
  type: string;
}

export interface LinkInfo {
  /**
   * Main image of the site to be shown in content
   */
  photoUrl?: URL;
  /**
   * Main video of the site to be shown in content
   */
  videoUrl?: URL;
  /**
  * Page title
  */
  title: string;
  /**
   * Author of the page
   */
  author?: string;
  /**
  * Start of the main content from site.
  */
  excerpt: string;
  /**
   * Link to the target site
   */
  url: URL;
  /**
   * The publisher if any
   */
  publisher?: string;
  /**
   * An option for the comment owner to hide the preview from the comment
   * Default is true.
   */
  showPreview?: boolean;
}
export interface Matrix2DMiniatureAnswer extends MiniatureAnswerBase {
  type: '2d';
  /**
   * Scale of x to define the knob size relative to full width
   */
  sx: number;
  /**
   * Answer on x axis
   */
  x: number;
  /**
   * Scale of y to define the knob size relative to full width
   */
  sy: number;
  /**
   * Answer on y axis
   */
  y: number;
  /**
   * Knob x position as a value between 0 and 1
   */
  zTransformedX: number;
  /**
   * Knob y position as a value between 0 and 1
   */
  zTransformedY: number;
}

export type MiniatureAnswer = Matrix2DMiniatureAnswer;

export interface LikeVote {
  id: MemberId;
  vote: VoteNatureShort;
}

export interface WithReplies {
  replies?: WithId<Comment>[];
}

export const PARENT_ID_ROOT = '_';

export type Likes = {
  up: number;
  down: number;
};


/**
 * Special fields for the Bloc system that should
 * not be stored in the Firestore.
 */
export interface WithBlocSystemFields {
  /**
   * If the comment is optimistic or not. Optimistic comments are not currently stored 
   * in the Firestore, instead they are stored in the Bloc state immediately after the user
   * has created or updated the comment. This is used to distinguish between the two.
   */
  _isOptimistic: boolean;
}

export const removeBlocSystemFields = <T extends WithBlocSystemFields>(comment: T): Omit<T, keyof WithBlocSystemFields> => {
  const { _isOptimistic, ...rest } = comment;
  return rest;
};


/**
 * A single comment for a Canvas.
 *
 * NOTE: The member id of person making the comment is stored in to a
 *       standard created -field.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Comment<T = any> extends WithCreationInfo, WithModificationInfo, WithReplies {
  /**
   * The parent comment's id if any. If this is being set, the comment refer's to some other
   * comment as a reply. If the comment is at the root level, this value will be PARENT_ID_ROOT.
   */
  parentId: CommentId | typeof PARENT_ID_ROOT;
  /**
   * Parent path from the root of the Canvas. Full path is derived by combining
   * parent path with the current comment's id.
   */
  parentPath: string;
  /**
   * The current answer value of the project member
   */
  value?: T;
  /**
   * The nick name of the comment creator
   */
  nick?: string;
  /**
   * An URL to user's avatar image
   */
  avatar?: URL;
  /**
   * User role used when commenting. This is used to distinct the facilitator and
   * bot comments from panelists ones.
   */
  role?: 'f' | 'p' | 'b',
  /**
   * The comment text written by the member.
   */
  text: string;
  /**
   * Likes
   */
  likes?: Likes;
  /**
   * List of links included in the message content.
   */
  links?: URL[];
  /**
   * Crawled information of the link retrieved from the target site.
   */
  firstLinkInfo?: LinkInfo;
  /**
   * An array of hashtags included in the message content
   */
  hashTags?: string[];
  /**
   * An array of labels included in the message content
   */
  labels?: string[];
  /**
   * An array of mentions included in the message content
   */
  mentions: MentionInfo[];
  /**
   * Number of direct replies referring to this comment
   */
  replyCount?: number;
  /**
   * Featured comments are shown as highlighted on top of the discussion
   */
  featured?: boolean;
  /**
   * Member specific vote. Each member may have a single vote.
   */
  votes?: LikeVote[];
  /**
   * The descriptor of the commenter's current answer for the Canvas.
   */
  answer?: MiniatureAnswer;

  /**
   * The quadrant of the commenter's current answer for the Canvas.
   * This only exists if the Canvas has a 2D matrix.
   */
  quadrant?: Quadrant;
}
