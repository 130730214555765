/* eslint-disable @typescript-eslint/no-explicit-any */

import { FreeTextWidgetEditorPure } from './FreeTextWidgetEditorPure';
import { useCallback } from 'react';
import { useComponentSelectionContext } from 'utils/hooks/useComponentSelectionContext';
import MediaContainer from 'components/layout/MediaContainer';
import type { BuilderFunction } from '../../../builders/componentBuilder';
import type { FreeTextWidget } from '@shared/schema/src/spaces/projects/sessions/canvases';
import type { FreeTextWidgetProps } from '../../renderers/FreeTextWidget';

const FreeTextWidgetEditor: BuilderFunction<FreeTextWidgetProps> = ({
  data,
  data: {
    background,
  },
}: FreeTextWidgetProps) => {
  const {
    selection,
  } = useComponentSelectionContext<FreeTextWidget>();

  const handleSave = useCallback((name: keyof FreeTextWidget, value: string) => {
    selection?.updateProperty(name, value);
  }, [selection,]);

  return background ?
    <MediaContainer
      media={background}>
      <FreeTextWidgetEditorPure data={data}
        onSave={handleSave} />
    </MediaContainer > :
    <FreeTextWidgetEditorPure data={data}
      onSave={handleSave} />;
};

FreeTextWidgetEditor.config = {
  type: 'free-text',
  displayName: 'Free text',
  props: {},
};

export default FreeTextWidgetEditor;
