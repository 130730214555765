import { BaseProduct } from './Product';
import { BillingInfo } from './BillingInfo';
import { InvoiceRegion, PaymentReferenceToken } from './types';
import { UTCTime } from '@mindhiveoy/schema';

/**
 * invoice state
 */
export enum InvoiceState {
  /**
   * The starting status for all invoices. You can still edit the invoice at this point.
   */
  DRAFT = 'draft',
  /**
   * The invoice has been finalized, and is awaiting customer payment.
   */
  OPEN = 'open',
  /**
   * invoice state paid
   */
  PAID = 'paid',
  /**
   * This invoice was a mistake, and must be canceled.
   */
  VOID = 'void',

}

/**
 * abstraction for invoice history
 */
export interface InvoiceHistoryRecord {
  /**
   * time of state change
   */
  time: UTCTime;
  /**
   * new state
   */
  state: InvoiceState;
}

export interface Invoice {
  /**
 * history of invoice
 */
  history: InvoiceHistoryRecord[];
  /**
   * reference token
   */
  referenceToken?: PaymentReferenceToken;
  /**
   * billing customer info
   */
  billingInfo: BillingInfo;
  /**
   * regional standard for this invoice (EU by default)
   */
  region?: InvoiceRegion;
  /**
   * product invoiced
   */
  cart: BaseProduct[];
}

/**
 * abstraction to represent invoice
 */
export interface InvoiceDoc {
  content: Invoice
}
