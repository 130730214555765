/* eslint-disable sonarjs/no-duplicate-string */
import {
  AXIS_LABEL_FONT_SIZE_REM, DARK_LABEL_COLOR, getLabelPadding, getMinMaxLabelFontSize, getTickFontSize
} from '../../labelUnits';
import {
  BottomLabeling,
  HorizontalAxisLabel, HorizontalAxisMinMaxLabel, LeftLabeling, RightLabeling, TopLabeling, VerticalAxisLabel, VerticalAxisMinMaxLabel
} from '../MatrixLabels';
import { GraphCorner } from '../../SliderQuestionCanvas';
import { XTickMarks } from '../ticks/XTickMarks';
import { YTickMarks } from '../ticks/YTickMarks';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SquareLayout from 'components/common/layout/SquareLayout';
import styled$ from 'utils/react/styled$';
import type { CSSProperties, PropsWithChildren } from 'react';
import type { Margins } from 'components/common/layout/SquareLayout';
import type { Matrix2DQuestionCanvas, RangeItem } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

/**
 * Graph2D Grid will introduce grid to position different
 * elements of the graph and give css-variables to control
 * the size of the grid elements.
 *
 * @deprecated Use SquareLayout instead
 *
 */
export const Graph2DGrid = styled$(motion.div)<{
  $showAxisLabels?: boolean,
  $showAxisMinMaxLabels?: boolean,
  $isPresentation?: boolean,
  $hasCornerLabels?: boolean,
}>(({
  theme,
  $showAxisLabels = false,
  $isPresentation,
  $hasCornerLabels,
}) => `
  --grid-area: 0.65;
  --bigger-side-area: ${$showAxisLabels ? 0.0125 : 0.025};
  --side-area: 0.05;
  @media only screen and (max-width: 1200px) {
    --grid-area: 0.7;
    --side-area: 0.05;
    --bigger-side-area: ${$showAxisLabels ? 0.0125 : 0.025};
  }
  @media only screen and (max-width: 1000px) {
    --grid-area: 0.75;
    --side-area: 0.05;
    --bigger-side-area: ${$showAxisLabels ? 0.0125 : 0.025};
  } 
  @media only screen and (max-width: 600px) {
    --grid-area: 0.9;
    --side-area: 0.05;
    --bigger-side-area: ${$showAxisLabels ? 0.0125 : 0.025};
  }
  --inner-area: calc(var(--grid-area) - var(--side-area) - var(--bigger-side-area));
  --grid-size: calc(var(--grid-area) * var(--inner-area)) ;
  --bigger-cell-size: calc(var(--grid-area) * var(--bigger-side-area));
  width: ${$isPresentation ? '600px' : 'calc(100% * var(--grid-area))'};
  ${$isPresentation ? 'height: 600px;' : ''}
  position: relative;
  display: grid;
  // grid-template-columns: auto min(calc(100% * var(--grid-size)), ${theme.shape.maxEditorAreaWidth}px) auto;
  // grid-template-rows: fit-content(20%) min(calc(100% * var(--grid-size)), ${theme.shape.maxEditorAreaWidth}px) fit-content(10%) fit-content(10%);
  grid-template-columns: auto 1fr auto;
  grid-template-rows: fit-content(10%) 1fr fit-content(10%);
  box-sizing: border-box;
  grid-template-areas:
    "luc        top-label     ruc"
    "left-label main          right-label"
    "llc        bottom-label  rlc";    
  aspect-ratio: 1/1;
  margin-left: ${$showAxisLabels ? '-30px' : '0px'};
  ${$hasCornerLabels ? `margin-top: 30px;` : ''}
  ${$hasCornerLabels ? `margin-bottom: 30px;` : ''}
  @media(max-width: 600px) {
    font-size: 14px;
    width: 95%;
  }
`);

const cornerStyleBase: CSSProperties = {
  // Use absolute positioning to prevent the underlying grid to explode with the structure.
  // position: 'absolute',
  display: 'block',
  textAlign: 'center',
  justifyContent: 'center',
};

const rotate180Style = {
  transform: 'rotateZ(180deg)',
};

/**
   * Render a custom tick mark for the axis
   * @param {string}    label The label text of the tick
   * @param {number}    value The value of the tick
   * @param {number}    index The index of the tick in range
   */
interface RenderTickArgs {
  item: RangeItem;
  // label: string;
  // value: number;
  index: number;
}

// TODO: Make the adjustment of left and top margins dynamic based on label selections
/**
   * Render a custom tick mark for the axis
   * @param {'x' | 'y'} axis  'x' or 'y'
   * @param {string}    label The label text of the tick
   * @param {number}    value The value of the tick
   * @param {number}    index The index of the tick in range
   */
type Render2DTickArgs = Render2DXTickArgs | Render2DYTickArgs;

interface Render2DXTickArgs extends RenderTickArgs {
  axis: 'x';
  side: 'top' | 'bottom';
}

interface Render2DYTickArgs extends RenderTickArgs {
  axis: 'y';
  side: 'left' | 'right';
}

/**
 * Render a custom tick mark
 *
 * @param {RenderTickArgs} args
 * @returns {JSX.Element}   The custom tick mark
 */
export type RenderTickFunction = (args: RenderTickArgs) => JSX.Element;

/**
 * Render a custom tick mark
 *
 * @param {Render2DTickArgs} args
 * @returns {JSX.Element}   The custom tick mark
 */
export type Render2DTickFunction = (args: Render2DTickArgs) => JSX.Element;

/**
 *
 *
 *
 * |                   |   axis.y.maxLabel   |                   |
 * |------------------:|:--------------------|:------------------|
 * |    luc            |   axis.x.mainLabel  | ruc               |
 * |    axis.x.mainLabel            |   axis.x.mainLabel  | ruc               |
 */

interface Matrix2DLayoutProps {
  canvas: WithId<Matrix2DQuestionCanvas>;
  xLabel?: string | JSX.Element;
  yMinLabel?: string | JSX.Element;
  yMaxLabel?: string | JSX.Element;
  yLabel?: string | JSX.Element;
  xMinLabel?: string | JSX.Element;
  xMaxLabel?: string | JSX.Element;
  lucLabel?: string | JSX.Element;
  rucLabel?: string | JSX.Element;
  llcLabel?: string | JSX.Element;
  rlcLabel?: string | JSX.Element;

  /**
   * Extra space to add to the layout in rems
   */
  extraSpace?: {
    top?: number,
    bottom?: number,
    left?: number,
    right?: number,
  };

  legend?: JSX.Element;
  /**
   * Allow labels to include raw HTML
   */
  rawHTML?: boolean;
  /**
   * Render a custom tick mark
   */
  onRenderTick?: Render2DTickFunction;
}

/**
 * A 2d matrix layout where the component's content is wrapped with axis label data
 * based on settings. The component will define a css grid to layout the different
 * elements.
 *
 * @param {Matrix2DLayoutProps} props
 * @return {JSX.Element} The component with the layout
 */
const Matrix2DLayout = ({
  canvas,
  xLabel = canvas?.axis?.x?.mainLabel,
  xMinLabel = canvas?.axis?.x?.minLabel,
  xMaxLabel = canvas?.axis?.x?.maxLabel,
  yLabel = canvas?.axis?.y?.mainLabel,
  yMinLabel = canvas?.axis?.y?.minLabel,
  yMaxLabel = canvas?.axis?.y?.maxLabel,
  lucLabel = canvas?.axis.cornerLabels?.luc,
  rucLabel = canvas?.axis.cornerLabels?.ruc,
  llcLabel = canvas?.axis.cornerLabels?.llc,
  rlcLabel = canvas?.axis.cornerLabels?.rlc,
  legend,
  rawHTML = true,
  children,
  extraSpace,
  onRenderTick,
}: PropsWithChildren<Matrix2DLayoutProps>) => {
  // const {
  //   isPresentation, // TODO: Check the presentation mode
  // } = useCanvasRenderingContext();

  const margin = useMemo(() => {
    const axisFont = 0;// getAxisLabelFontSize();
    const padding = getLabelPadding();
    const minMax = getMinMaxLabelFontSize();
    const tickSize = getTickFontSize();

    const count = (canvas.showAxisLabels ? 1 : 0) +
      (canvas.showMinMaxLabels ? 1 : 0) +
      (canvas.showTickLabels ? 1 : 0);

    const shift = count > 0 ? count * padding : 0;

    const top = (extraSpace?.top ?? 0) + shift + shift + (canvas.showAxisLabels ? axisFont : 0) +
      (canvas.showMinMaxLabels ? minMax : 0) +
      (canvas.showTickLabels ? tickSize : 0);

    const bottom = (extraSpace?.bottom ?? 0) + shift + (canvas.showMinMaxLabels ? minMax : 0) +
      (canvas.showTickLabels ? tickSize : 0);

    const left = (extraSpace?.left ?? 0) + shift + (canvas.showAxisLabels ? axisFont : 0) +
      (canvas.showMinMaxLabels ? minMax : 0) +
      (canvas.showTickLabels ? tickSize : 0);

    const right = (extraSpace?.right ?? 0) + shift + (canvas.showMinMaxLabels ? minMax : 0) +
      (canvas.showTickLabels ? tickSize : 0);

    return {
      top: {
        value: top,
        unit: 'rem',
      },
      bottom: {
        value: bottom,
        unit: 'rem',
      },
      left: {
        value: left,
        unit: 'rem',
      },
      right: {
        value: right,
        unit: 'rem',
      },
    } as Margins;
  }, [canvas.showAxisLabels, canvas.showMinMaxLabels, canvas.showTickLabels, extraSpace?.bottom, extraSpace?.left, extraSpace?.right, extraSpace?.top,]);

  return <>
    <SquareLayout
      data-testid='square-layout'
      margin={margin}
      cells={{
        top:
          <TopLabeling key="top-label">
            {/* {canvas.showAxisLabels && <HorizontalAxisLabel $showMinMax={canvas.showMinMaxLabels}>
              {canvas.showAxisArrows && <>
                <ArrowRightAltIcon style={rotate180Style} />
          &nbsp;
              </>
              }
              {xLabel}
              {canvas.showAxisArrows && <>
                <ArrowRightAltIcon />
          &nbsp;
              </>}
            </HorizontalAxisLabel>} */}
            {canvas.showMinMaxLabels && <HorizontalAxisMinMaxLabel $showAxisLabels={canvas.showMinMaxLabels}>
              {yMinLabel}
            </HorizontalAxisMinMaxLabel>}
            {
              canvas.showTickLabels && <XTickMarks
                range={canvas?.axis.x.range}
                mode={canvas?.mode}
                side="top"
                onRenderTick={onRenderTick}
              />
            }
          </TopLabeling>,
        bottom: <BottomLabeling >
          {canvas.showTickLabels && <XTickMarks
            range={canvas?.axis.x.range}
            mode={canvas?.mode}
            side="bottom"
            onRenderTick={onRenderTick}
          />}
          {canvas.showMinMaxLabels &&
            <HorizontalAxisMinMaxLabel $showAxisLabels={canvas.showAxisLabels}>
              {yMaxLabel}
            </HorizontalAxisMinMaxLabel>}
        </BottomLabeling>,
        left: <LeftLabeling>
          {/* {canvas.showAxisLabels &&
            <VerticalAxisLabel $showMinMax={canvas.showMinMaxLabels}>
              {canvas.showAxisArrows && <>
                <ArrowRightAltIcon style={{
                  transform: 'rotateZ(-90deg)',
                }} />
                &nbsp;
              </>
              }
              {yLabel}
              {canvas.showAxisArrows && <>
                &nbsp;
                <ArrowRightAltIcon style={{
                  transform: 'rotateZ(90deg)',
                }} />
              </>}
            </VerticalAxisLabel>} */}
          {canvas.showMinMaxLabels && <VerticalAxisMinMaxLabel $showAxisLabels={canvas.showMinMaxLabels}>
            {xMinLabel}
          </VerticalAxisMinMaxLabel>}
          {canvas.showTickLabels && <YTickMarks
            range={canvas.axis.y.range}
            justify='right'
            side="left"
            mode={canvas?.mode}
            onRenderTick={onRenderTick}
          />}
        </LeftLabeling>,
        right: <RightLabeling>
          {canvas.showTickLabels && <YTickMarks
            range={canvas.axis.y.range}
            justify='left'
            side="right"
            mode={canvas?.mode}
            onRenderTick={onRenderTick}
          />}
          {canvas.showMinMaxLabels &&
            <VerticalAxisMinMaxLabel $showAxisLabels={canvas.showAxisLabels}>
              {xMaxLabel}
            </VerticalAxisMinMaxLabel>
          }
        </RightLabeling>,
      }}
    >

      {children}

      {canvas.showAxisLabels && <HorizontalAxisLabel
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          fontSize: `${AXIS_LABEL_FONT_SIZE_REM}rem`,
          fontWeight: 'bold',
          color: DARK_LABEL_COLOR,
          paddingTop: 0,
          marginTop: 0,
          maxWidth: '100%',
          paddingLeft: '1rem',
          paddingRight: '1rem',
        }}
        $showMinMax={canvas.showMinMaxLabels}>
        {canvas.showAxisArrows && <>
          <ArrowRightAltIcon style={rotate180Style} />
          &nbsp;
        </>
        }
        {xLabel}
        {canvas.showAxisArrows && <>
          <ArrowRightAltIcon />
          &nbsp;
        </>}
      </HorizontalAxisLabel>}

      {canvas.showAxisLabels &&
        <VerticalAxisLabel
          style={{
            position: 'absolute',
            left: 0,
            height: '100%',
            fontSize: `${AXIS_LABEL_FONT_SIZE_REM}rem`,
            fontWeight: 'bold',
            color: DARK_LABEL_COLOR,
            padding: 0,
            margin: 0,
            maxHeight: '100%',
            paddingTop: '1rem',
            paddingBottom: '1rem',

          }}
          $showMinMax={canvas.showMinMaxLabels}>
          {canvas.showAxisArrows && <>
            <ArrowRightAltIcon style={{
              transform: 'rotateZ(-90deg)',
            }} />
            &nbsp;
          </>
          }
          {yLabel}
          {canvas.showAxisArrows && <>
            &nbsp;
            <ArrowRightAltIcon style={{
              transform: 'rotateZ(90deg)',
            }} />
          </>}
        </VerticalAxisLabel>}

      <GraphCorner
        data-testid='ruc'
        style={{
          top: 16,
          right: 16,
          color: 'rgba(0,0,0,0.7)',
          textAlign: 'right',
        }}
      >
        {canvas.showCornerLabels && <span
          style={cornerStyleBase}
        >
          {
            rawHTML && typeof rucLabel === 'string' ? <span
              dangerouslySetInnerHTML={{
                __html: rucLabel,
              }} /> : rucLabel
          }
        </span>}
      </GraphCorner>
      <GraphCorner
        data-testid='rlc'
        style={{
          right: 16,
          bottom: 16,
          color: 'rgba(0,0,0,0.7)',
          textAlign: 'right',
        }}
      >
        {canvas.showCornerLabels && <span
          style={cornerStyleBase}
        >
          {rawHTML && typeof rlcLabel === 'string' ? <span dangerouslySetInnerHTML={{
            __html: rlcLabel,
          }} /> : rlcLabel}
        </span>}
      </GraphCorner>
      <GraphCorner
        data-testid="llc"
        style={{
          left: 16,
          bottom: 16,
          color: 'rgba(0,0,0,0.7)',
        }}
      >
        {canvas.showCornerLabels && <span
          style={cornerStyleBase}
        >
          {rawHTML && typeof llcLabel === 'string' ? <span dangerouslySetInnerHTML={{
            __html: llcLabel,
          }} /> : llcLabel}
        </span>}
      </GraphCorner>
      <GraphCorner
        data-testid='luc'
        style={{
          left: 16,
          top: 16,
          color: 'rgba(0,0,0,0.7)',
        }}>
        {canvas.showCornerLabels && <span
          style={cornerStyleBase}
        >
          {rawHTML && typeof lucLabel === 'string' ? <span dangerouslySetInnerHTML={{
            __html: lucLabel,
          }} /> : lucLabel}
        </span>}
      </GraphCorner>

    </SquareLayout >
    {legend &&
      <div style={{
        gridArea: 'bottom',
      }}>
        {legend}
      </div>
    }
  </>;
};

export default Matrix2DLayout;
