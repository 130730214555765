import { SessionDraftContext } from '.';
import { useContext } from 'react';

// Custom hook to use the DraftSessionDataContext

export const useSessionDraft = () => {
  const context = useContext(SessionDraftContext);
  if (context === undefined) {
    throw new Error(
      'useDraftSessionData must be used within a DraftSessionDataProvider'
    );
  }
  return context;
};
