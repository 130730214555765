/* eslint-disable indent */
import { chipVariants } from '../LabelRow';
import { isCancelCommand, isSubmitCommand } from 'utils/keyboardCommands';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useComment } from 'bloc/comment/CommentBloc';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import useStopPropagation from 'utils/hooks/useStopPropagation';
import type { CanvasParams, Comment } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

const StyledInput = styled(motion.input)(({
  theme,
}) => `
  border-radius: 1rem;
  margin: 0;
  height: 2rem;
  color: ${theme.palette.text.primary};
  background-color: rgb(32, 32, 48, 0.3);
  border-style: solid;
  border-color: ${theme.palette.text.primary};
  border-width: 1px;
  padding-left: 8px;
  outline: none;
`);

interface NewLabelEditorProps {
  comment: WithId<Comment>;
  onClose: () => void;
  onLabelAlreadyExists?: (label: string) => void;
}

const NewLabelEditor = ({
  comment,
  onClose,
  onLabelAlreadyExists,
}: NewLabelEditorProps
) => {
  const ref = useRef<HTMLInputElement>(null);

  const {
    t,
  } = useTranslation();

  const [label, setLabel,] = useState<string>('');
  const [labels, setLabels,] = useState<string[]>(comment.labels ?? []);

  const stopPropagation = useStopPropagation();

  const {
    query,
  } = useRouter();

  const commentBloc = useComment({
    ...(query as CanvasParams),
    commentId: comment._id,
  });

  useEffect(() => {
    const input = ref.current;
    input?.focus();
  }, []);

  useEffect(() => {
    setLabels(comment.labels ?? []);
  }, [comment.labels,]);

  const handleKeyDown = useCallback(async (event: React.KeyboardEvent) => {
    if (isSubmitCommand(event) || event.key === 'Enter') {
      event.preventDefault();

      if (!label) {
        return;
      }

      if (labels.includes(label)) {
        // Animate the input to show that the label already exists
        onLabelAlreadyExists?.(label);
        return;
      }

      const newLabels = [...labels, label,];
      setLabels(newLabels);
      setLabel('');
      const comment = await commentBloc.setCommentLabels(newLabels);
      setLabels(comment?.labels ?? []);
      return;
    }

    if (isCancelCommand(event)) {
      event.preventDefault();
      setLabel('');
      onClose?.();
    }
  }, [commentBloc, label, labels, onClose, onLabelAlreadyExists,]);

  const handleChange = useCallback((event: any) => {
    setLabel(event.target.value.trim().toLowerCase());
  }, []);

  return <StyledInput
    layoutId={`${comment._id}_newLabel`}
    ref={ref}
    value={label}
    variants={chipVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    onClick={stopPropagation}
    onKeyDown={handleKeyDown}
    onChange={handleChange}
    placeholder={t('add-label')}
  />;
};
export default NewLabelEditor;
