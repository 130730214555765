/* eslint-disable valid-jsdoc */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ListenerHelper } from '../../../../utils/listener/listenerHelper';
import { getObjectField } from '@mindhiveoy/foundation';
import { startTransition } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import type { BuilderComponentPropsBase } from '../../widgets/PageWidgetRenderer';
import type { BuilderConfig } from '../../builders/componentBuilder';
import type { ComponentDataChange, ComponentModel } from '../ComponentModel';
import type { ComponentSelectionModel, SelectionDataChangeListenerFunction } from './index';
import type { DeletePropertyFunction, UpdatePropertyFunction } from 'components/builder/propertyEditors/PropertyEditorTypes';

/**
 * Selection model to point selection into a single field in the model.
 */
export class ComponentFieldSelectionModel<D extends object, F extends object,
  C extends BuilderComponentPropsBase<object>> implements ComponentSelectionModel<D> {
  private $listeners = new ListenerHelper<SelectionDataChangeListenerFunction<any>>();

  private $state: 'loading' | 'active' = 'active';
  private unsubscribe: () => void;
  /**
   *
   * @param {ComponentModel<F>}     $model          The component model of which subfield is selected
   * @param {BuilderConfig<D>}      $config         The config of the field being edited. The field must always be an object.
   * @param {string}                fieldPath       The path to the field being edited expressed using dots and square brackets
   *                                                to point to exact path in component.
  */
  constructor(
    private $model: ComponentModel<F>,
    private $config: BuilderConfig<D>,
    private fieldPath: string
  ) {
    this.unsubscribe = $model.subscribeToDataChange(this.onDataChange);
  }

  /**
   * The current status of the selection.
   */
  public get state() {
    return this.$state;
  }

  /**
   * Set the selection model status.
   *
   * @param {'loading' | 'active'} status The new status of the selection
   */
  public setState: (status: 'loading' | 'active') => void = (status) => {
    if (this.$state !== status) {
      this.$state = status;
      const data = cloneDeep(this.data());
      startTransition(() => {
        this.$listeners.fire(data);
      });
    }
  };

  /**
   *
   * @return {boolean} True if the selection is at root level. Root level selection is the selection of the whole component.
   */
  isRootLevel = (): boolean => false;

  /**
   * the type of the component. Type is being used to identify editable components in inspector.
   *
   * @return {BuilderConfig<D>} The component type
   */
  public config = () => {
    return this.$config;
  };
  /**
   *
   * @return {D} The current component data
   */
  public data = (): D => {
    return (getObjectField(this.$model.component.data, this.fieldPath) ?? {}) as D;
  };
  /**
   * The component model of the selected component.
   * @return {ComponentModel<F>} The component model of the selected component.
   */
  public model = (): ComponentModel<F> => {
    return this.$model;
  };

  public deleteProperty: DeletePropertyFunction<D> = (propertyName: any) => {
    const fieldPath = `${this.fieldPath}.${propertyName}`;
    this.$model.deleteProperty(fieldPath);
  };

  public deleteGlobalProperty: DeletePropertyFunction<any> = (propertyName: any) => {
    this.$model.deleteProperty(propertyName);
  };
  /**
   * Update the property value of the selected component.
   *
   * @param {string} propertyName Name of the property being edited.
   * @param {any}    value        The new value of the property.
   */
  public updateProperty = (propertyName: any, value: any) => {
    const fieldPath = `${this.fieldPath}.${propertyName}`;
    this.$model.updateProperty(fieldPath, value as any); // TODO typing
  };

  public updateGlobalProperty: UpdatePropertyFunction<any> = (propertyName: any, value: any) => {
    this.$model.updateProperty(propertyName, value);
  };

  /**
   *
   * @param {BuilderComponentPropsBase<D>}  _oldValue
   * @param {BuilderComponentPropsBase<D>}  newValue
   */
  private onDataChange = ({
    newValue,
  }: ComponentDataChange<D>) => {
    const data = cloneDeep(newValue ? getObjectField(newValue.data, this.fieldPath) : undefined);

    startTransition(() => {
      this.$listeners.fire(data);
    });
  };

  /**
   * Dispose the selection model. This should be called when the selection is no longer needed.
   */
  dispose = () => {
    this.unsubscribe();
    // this.$model.removeDataChangeListener(this.onDataChange);
  };

  /**
   * Add a listener to be called when the selection data changes.
   * @param {SelectionDataChangeListenerFunction<any>} listener
   */
  addSelectionDataChangeListener = (listener: SelectionDataChangeListenerFunction<any>) => {
    this.$listeners.add(listener);
    listener(this.data());
  };

  /**
   * Remove a listener from the selection data change listeners.
   * @param {SelectionDataChangeListenerFunction<any>} listener
   */
  removeSelectionDataChangeListener = (listener: SelectionDataChangeListenerFunction<any>) => {
    this.$listeners.remove(listener);
  };

  subscribeForDataChanges = (listener: SelectionDataChangeListenerFunction<D>) => {
    this.$listeners.add(listener);
    return () => {
      this.$listeners.remove(listener);
    };
  };
}
