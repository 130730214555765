import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import MaterialBreadcrumbs from '@mui/material/Breadcrumbs';
import React, { useCallback, useEffect, useState } from 'react';
import elementCache from 'utils/ElementCache';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import usePathParams from 'utils/hooks/usePathParams';

export const StyledLink = styled(Link)(
  ({
    theme,
  }) => `
  color: ${theme.palette.primary.contrastText};
  text-decoration: none;
  font-size: ${theme.typography.h6.fontSize};
  font-face: ${theme.typography.h6.fontFace};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  cursor: pointer;
  :hover {
    text-decoration: none;
    font-weight: 500;
    text-decoration-color: ${theme.palette.primary.main};
  }
  @media(min-width: 700px) and (max-width: 800px) {
    font-size: 0.98rem;
  }
`);

const StyledBreadcrumbs = styled$(MaterialBreadcrumbs)<{
  $expanded?: boolean;
}>(({
  $expanded,
  theme,
}) => `
  ${theme.breakpoints.down('md')} {
    display: none;
  }
  max-width: 100%;
  margin: 0;
  .MuiBreadcrumbs-li {
    max-width: ${$expanded ? '20vw' : '40vw'};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media(min-width: 768px) and (max-width: 900px) {
      max-width: ${$expanded ? '20vw' : '35vw'}; 
    }
  }
`);

interface BreadcrumbsProps {
  isAnonymous: boolean;
}

const Breadcrumbs = ({
  isAnonymous,
}: BreadcrumbsProps) => {
  const params = usePathParams();

  const {
    t,
  } = useTranslation();

  const [expanded, setExpanded,] = useState<boolean>(false);

  const [spaceName, setSpaceName,] = useState('');
  const [projectName, setProjectName,] = useState('');
  const [sessionName, setSessionName,] = useState('');
  const [canvasName, setCanvasName,] = useState('');

  const handleExpandClick = useCallback((): void => {
    setExpanded(true);
  }, []);

  const {
    spaceId,
    projectId,
    sessionId,
    canvasId,
  } = params;

  useEffect(() => {
    const fetchSpace = async () => {
      const data = await elementCache.get({
        keyPath: {
          spaceId,
        },
        defaultValue: {
          name: '',
        },
      });
      setSpaceName(data?.name ?? '');
    };

    const fetchProject = async () => {
      const data = await elementCache.get({
        keyPath: {
          spaceId,
          projectId,
        },
        defaultValue: {
          name: '',
        },
      });
      setProjectName(data?.name ?? '');
    };

    const fetchSession = async () => {
      const data = await elementCache.get({
        keyPath: {
          spaceId,
          projectId,
          sessionId,
        },
        defaultValue: {
          name: '',
        },
      });
      setSessionName(data?.name ?? '');
    };

    const fetchCanvas = async () => {
      const data = await elementCache.get({
        keyPath: {
          spaceId,
          projectId,
          sessionId,
          canvasId,
        },
        defaultValue: {
          name: '',
        },
      });
      setCanvasName(data?.name ?? '');
    };

    spaceId && fetchSpace();
    projectId && fetchProject();
    sessionId && fetchSession();
    canvasId && fetchCanvas();
  }, [spaceId, projectId, sessionId, canvasId,]);

  useEffect(() => {
    if (!spaceId) {
      return;
    }
    return elementCache.subscribe({
      spaceId,
    }, (data) => {
      setSpaceName(data?.name ?? '');
    });
  }, [spaceId,]);

  useEffect(() => {
    if (!projectId) {
      return;
    }
    return elementCache.subscribe({
      spaceId,
      projectId,
    }, ({
      name,
    }) => setProjectName(name ?? ''));
  }, [spaceId, projectId,]);

  useEffect(() => {
    if (!sessionId) {
      return;
    }
    return elementCache.subscribe({
      spaceId,
      projectId,
      sessionId,
    }, ({
      name,
    }) => setSessionName(name ?? ''));
  }, [projectId, sessionId, spaceId,]);

  useEffect(() => {
    if (!params.canvasId) {
      return;
    }
    return elementCache.subscribe(params,
      ({
        name,
      }) => setCanvasName(name ?? ''));
  }, [params,]);

  const isSiteRoot = !spaceId && !projectId && !sessionId && !canvasId;

  const showHome = !(isSiteRoot && isAnonymous);
  return <StyledBreadcrumbs
    $expanded={expanded}
    separator="|"
    onClick={handleExpandClick}
    maxItems={3}
    aria-label="breadcrumb">
    {showHome ? <StyledLink href="/">
      {t('Home')}
    </StyledLink> : null}

    {!isAnonymous && spaceId &&
      <StyledLink
        href={`/${spaceId}/`}
        shallow
      >
        {spaceName}
      </StyledLink>}
    {!isAnonymous && projectId &&
      <StyledLink
        href={`/${spaceId}/${projectId}/`}
        shallow
      >
        {projectName}
      </StyledLink>}
    {sessionId &&
      <StyledLink
        href={`/${spaceId}/${projectId}/${sessionId}/`}
        shallow
      >
        {sessionName}
      </StyledLink>}
    {canvasId &&
      <StyledLink
        href={`/${spaceId}/${projectId}/${sessionId}/${canvasId}/`}
        shallow >
        {canvasName}
      </StyledLink>}
  </StyledBreadcrumbs>;
};

export default Breadcrumbs;
