import { COMMAND_PRIORITY_EDITOR, KEY_DOWN_COMMAND } from 'lexical';
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { LexicalEditor } from 'lexical';

interface KeyCommandPluginProps {
  saveOnEnterPress?: boolean;
  onStopEditing: (editor: LexicalEditor) => void;
}
export const KeyCommandPlugin = ({
  saveOnEnterPress,
  onStopEditing,
}: KeyCommandPluginProps) => {
  const [editor,] = useLexicalComposerContext();

  useEffect(() => {
    if (saveOnEnterPress) {
      editor.registerCommand<KeyboardEvent>(
        KEY_DOWN_COMMAND,
        (event, editor) => {
          if (event.key === 'Enter' && !event.ctrlKey) {
            onStopEditing(editor);
            event.preventDefault();
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_EDITOR
      );
    }
  }, [editor, onStopEditing, saveOnEnterPress,]);

  return null;
};
