import { motion } from 'framer-motion';
import type { PropsWithChildren } from 'react';

const initialStyle = {
  opacity: 0,
  rotateX: 90,
};

const animateStyle = {
  opacity: 1,
  rotateX: 0,
};

const exitStyle = {
  opacity: 0,
  rotateX: -90,
};

const style = {
  overflow: 'hidden',
};

const InspectorRow = ({
  children,
}: PropsWithChildren<unknown>) =>
  <motion.div
    initial={initialStyle}
    animate={animateStyle}
    exit={exitStyle}
    style={style}
  >{children}
  </motion.div>;

export default InspectorRow;
