import { Backdrop, Stack, Switch, Typography } from '@mui/material';
import { Chevron } from '../../../common/Chevron';
import { MemberStatus } from '@mindhiveoy/schema';
import { Show } from '../../../auth/Show';
import { UserAdminBloc } from 'bloc/admin/UserAdminBloc';
import { useAuth } from '@mindhiveoy/react-auth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import ContactUsButton from 'components/contact/ContactUsButton';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonIcon from '@mui/icons-material/Person';
import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import UserProfileDrawerMenuItem from './UserProfileDrawerMenuItem';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import config from 'config';
import styled from '@emotion/styled';
import styled$ from '../../../../utils/react/styled$';
import useScreenContext from '../../useScreenContext';
import type { ShortUserRoleId, User } from '@shared/schema/src';

export const APP_DRAWER_WIDTH = 300;
export const CARD_DRAWER_WIDTH = 200;

// interface CustomStylingProps {
//   $extraIndentation?: boolean;
// }
// type Props = Partial<WithTheme<CustomStylingProps, Theme>>;

const DrawerHeader = styled.div(({
  theme,
}) => `
  display: flex;
  align-items: center;
  padding-top: ${theme.spacing(2)};
  width: 100%;
  justify-content: flex-start;
  max-height: 47px;
`);

const DrawerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DrawerListIcon = styled.div(({
  theme,
}) => `
  margin-right: ${theme.spacing(1)};
  display: flex;
  align-items: center;
`);

const SectionDataContainer = styled$(List)<{
  $extraIndentation?: boolean;
}>(({
  theme,
  $extraIndentation,
}) => `
  padding-left: ${$extraIndentation ? theme.spacing(3.5) : theme.spacing(2)};
  padding-right: ${$extraIndentation ? theme.spacing(3.5) : theme.spacing(2)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing(1.5)};
`);

const PlaybackDivider = styled(Divider)(({
  theme,
}) => `
  margin: ${theme.spacing(2, 0)};
`);

const MenuDivider = styled(Divider)(({
  theme,
}) => `
  padding: ${theme.spacing(1, 0)};
  background-color: transparent;
`);

const PlaybackText = styled(Typography)`
  margin: 0;
  font-size: 0.98rem;
`;

export type UserProfileDrawerMenuItemId =
  'account' |
  'help & support' |
  'about' |
  'dashboard' |
  'linked accounts' |
  'sign out' |
  'member settings' |
  'payments' |
  'space settings' |
  'billing & payments';

interface RouterPath {
  basePath: string;
  params?: string | string[];
  pathMask?: string;
}

export interface UserProfileDrawerMenuItemDescriptor {
  label: string;
  id: UserProfileDrawerMenuItemId;
  icon: JSX.Element;
  routerPath?: RouterPath;
  forOwner?: boolean;
}

const settingsPath = '/[spaceId]/_settings/[...path]';
const privilegedItems: UserProfileDrawerMenuItemDescriptor[] = [
  {
    id: 'space settings',
    label: 'Space settings',
    icon: <SettingsIcon />,
    routerPath: {
      basePath: settingsPath,
      params: '_space',
      pathMask: '/{spaceId}/_settings/_space',
    },
  },
  {
    id: 'member settings',
    label: 'Member settings',
    icon: <SupervisorAccountIcon />,
    routerPath: {
      basePath: settingsPath,
      params: '_members',
      pathMask: '/{spaceId}/_settings/_members',
    },
  },
  {
    id: 'payments',
    label: 'Plans and subscriptions',
    icon: <WorkspacesIcon />,
    routerPath: {
      basePath: settingsPath,
      params: '_plan',
      pathMask: '/{spaceId}/_settings/_plan',
    },
  },
];
const accountItems: UserProfileDrawerMenuItemDescriptor[] = [
  {
    id: 'account',
    label: 'Account settings',
    icon: <PersonIcon />,
    routerPath: {
      basePath: '/_account',
    },
  },
  {
    id: 'billing & payments',
    label: 'Billing info',
    icon: <PaymentIcon />,
    routerPath: {
      basePath: '/_billing',
    },
    forOwner: true,
  },
];

// TODO: should this be moved to config?
const getWikiLink = () => {
  if (config.environment.variant === 'xdelphi') {
    return 'https://mysterious-lynx-4a8.notion.site/b5bfbc2be84e4309bf9660ef77b65656?v=ff0004555d2d49aaa832afa79c6b51ec';
  }
  return 'https://mysterious-lynx-4a8.notion.site/3bb802d73c314fc195fd5f5838ffefee?v=400a3b1c6e00435a9f1eb34a6ab41d8a&pvs=4';
};

const UserProfileDrawer = () => {
  const {
    rightDrawerOpen,
    setRightDrawerOpen,
  } = useScreenContext();

  const {
    user,
    memberStatus,
    signOut,
  } = useAuth();

  const {
    query: {
      spaceId,
    },
    push,
  } = useRouter();

  const {
    t,
  } = useTranslation();

  const [selected, setSelected,] = useState<UserProfileDrawerMenuItemId | undefined>();
  const [freezeVideoPlayback, setFreezeVideoPlayback,] = useState<boolean>(true);
  const [saving, setSaving,] = useState<boolean>(false);

  const handleDrawerClose = useCallback((event: React.MouseEvent) => {
    setRightDrawerOpen(false);
    event.preventDefault();
  }, [setRightDrawerOpen,]);

  const handleSignOut = useCallback(async () => {
    setRightDrawerOpen(false);

    await signOut();
    push('/', '/', {
      shallow: true,
    });
  }, [setRightDrawerOpen, signOut, push,]);

  const items: UserProfileDrawerMenuItemDescriptor[] = useMemo(() =>
    [{
      id: 'help & support',
      label: t('Help and support'),
      icon: <HelpIcon />,
      routerPath: {
        basePath: getWikiLink(),
      },
    },
    {
      id: 'about',
      label: t('About'),
      icon: <InfoIcon />,
      routerPath: {
        basePath: '/_about',
      },
    },
      /* Hidden for now */
      /*   {
          id: 'linked accounts',
          label: 'Linked accounts',
          icon: <LinkIcon/>,
        }, */
    ], [t,]);

  // TODO: Clean out this shit
  const handleUserProfileDrawerMenuItemClick = useCallback((newSelectedItemId: UserProfileDrawerMenuItemId) => () => {
    setSelected(newSelectedItemId);
    // search items that don't require admin permissions
    let selectedItem = items.find((item) => item.id === newSelectedItemId);
    if (!selectedItem) {
      selectedItem = accountItems.find((item) => item.id === newSelectedItemId);
    }
    // search items that require admin permissions if no match
    if (memberStatus === 'a' && !selectedItem) {
      selectedItem = privilegedItems.find((item) => item.id === newSelectedItemId);
    }

    if (newSelectedItemId === 'dashboard') {
      const path = '/_dashboard';
      push(path, path, {
        shallow: true,
      });
      return;
    }
    // handle path and tab change
    if (!selectedItem?.routerPath) {
      return;
    } // && pathname !== selectedItem.routerPath.path ) {
    if (selectedItem.routerPath.basePath.startsWith('http')) {
      window.open(selectedItem.routerPath.basePath, '_blank');
      return;
    }
    if (spaceId) {
      push({
        pathname: selectedItem.routerPath.basePath,
        query: {
          ...selectedItem.routerPath.params && {
            spaceId,
            path: selectedItem.routerPath.params,
            // override for selected/active tab in SpaceSettings
            settingsTabOverride: selectedItem.routerPath.params,
          },
        },
      }, selectedItem.routerPath?.pathMask?.replaceAll('{spaceId}', spaceId as string) ?? undefined, {
        shallow: true,
      });
      return;
    }
    const path = selectedItem.routerPath.basePath;
    push(path, path, {
      shallow: true,
    });
  }, [items, memberStatus, push, spaceId,]);

  const RightDrawerBase = styled$(Drawer)<{
    $width: number
  }>(({
    theme,
    $width,
    open,
  }) => `
    flex-shrink: 0;
    & .MuiDrawer-paper {
      width: ${$width}px;
      background-color: ${theme.palette.background.default};
      transform: translateX(${open ? 0 : $width}px);
      transition: all 0.3s ease-in-out;
    }
    transition: all 0.3s ease-in-out;    
  `);

  const stopPropagation = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  const handlePauseVideos = useCallback(async (
    event: React.ChangeEvent<HTMLInputElement>, checked: boolean
  ) => {
    event.stopPropagation();

    setSaving(true);
    const freezeVideoPlayback = !checked;

    setFreezeVideoPlayback(checked);

    if (!user?.uid) {
      return;
    }
    // TODO: proper usage
    const userAdminBloc = new UserAdminBloc();

    try {
      await userAdminBloc.updateUser(
        user?.uid, {
          extra: {
            freezeVideoPlayback,
          },
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  }, [user?.uid,]);

  useEffect(() => {
    if (!user) {
      return;
    }
    setFreezeVideoPlayback(!!(user as User<ShortUserRoleId>).extra?.freezeVideoPlayback);
  }, [user,]);

  const handleCookieSettingsClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    // A small hack to make the react-cookie-consent to work from app menu
    const consent = document.getElementById(
      'cookie-setting-button'
    );

    consent?.click();
    setRightDrawerOpen(false);
  }, [setRightDrawerOpen,]);

  return <Backdrop
    open={rightDrawerOpen}
    onClick={handleDrawerClose}
  >
    <RightDrawerBase
      $width={APP_DRAWER_WIDTH}
      variant="temporary"
      anchor="right"
      // BackdropProps={{
      //   invisible: true,
      // }}
      PaperProps={{
        elevation: 0,
      }}
      open={rightDrawerOpen}
    >
      <DrawerHeader>
        <Chevron
          $anchor='right'
          $open={rightDrawerOpen}
          onClick={handleDrawerClose}
        />
        <Typography
          marginLeft={0.5}
          component={'span'}
          variant="h6">
          {user?.displayName}
        </Typography>

      </DrawerHeader>
      <PlaybackDivider />

      {/* Space */}
      <Show withStatus={MemberStatus.ACTIVE}>
        <SectionDataContainer>
          {/* <img src={user?.photoURL} alt="Avatar" /> TODO: disabled for now, enable when actual image urls are a thing */}

          <Show withStatus={MemberStatus.ACTIVE}
            withRole="admin">
            {privilegedItems.map((pItem) => <UserProfileDrawerMenuItem
              withIndentation
              key={pItem.id}
              item={pItem}
              onClick={handleUserProfileDrawerMenuItemClick(pItem.id)}
              selected={selected === pItem.id} // TODO: handle selected when changing from SpaceSettings tabs
            />)
            }
          </Show>
        </SectionDataContainer>
        <MenuDivider />
      </Show>

      {/* User */}
      <SectionDataContainer>
        <Show withRole='system'>
          <UserProfileDrawerMenuItem
            withIndentation
            key="dashboard"
            item={{
              id: 'dashboard',
              label: 'Dashboard',
              icon: <DashboardIcon />,
              routerPath: {
                basePath: '/_dashboard',
              },
            }}
            onClick={handleUserProfileDrawerMenuItemClick('dashboard')}
            selected={selected === 'dashboard'} // TODO: handle selected when changing from SpaceSettings tabs
          />
          <MenuDivider />
        </Show>
        {accountItems.map((aItem) => <UserProfileDrawerMenuItem
          key={aItem.id}
          item={aItem}
          onClick={handleUserProfileDrawerMenuItemClick(aItem.id)}
          selected={selected === aItem.id} // TODO: handle selected when changing from SpaceSettings tabs
        />)}
        {/* <SectionDataContainer $extraIndentation>
        <ListItemText primary={user?.displayName} />
        {memberStatus && <ListItemText primary={`Member status: ${expandMemberStatusCode(memberStatus)}`} />}
      </SectionDataContainer> */}
      </SectionDataContainer>

      {/* General */}
      <MenuDivider />
      <DrawerContainer>
        <div>
          <SectionDataContainer>
            {items.map((item) => (!item.forOwner || user?.customerId && item.forOwner) && <UserProfileDrawerMenuItem
              key={item.id}
              item={item}
              onClick={handleUserProfileDrawerMenuItemClick(item.id)}
              selected={selected === item.id} // TODO: handle selected when changing from SpaceSettings tabs
            />)
            }

          </SectionDataContainer>
          {/* General */}
          <MenuDivider />
        </div>

        <SectionDataContainer >
          <ContactUsButton />
          <MenuDivider />

          <Typography
            variant="body1"
            data-cc="c-settings"
            onClick={handleCookieSettingsClick}
          >
            {t('Cookie settings')}
          </Typography>

          {/* Video playback switch */}
          <Stack
            paddingRight={1}
            flex={1}
            direction="row"
            justifyContent='space-between'
            alignItems="center">

            <PlaybackText>{t('Video playback')}</PlaybackText>

            <Stack
              direction='row'
              alignItems='center'>
              {/* <PlaybackText>{t('Off')}</PlaybackText> */}
              <Switch
                disabled={saving}
                checked={!freezeVideoPlayback}
                onClick={stopPropagation}
                onChange={handlePauseVideos}
              />
              <PlaybackText>{freezeVideoPlayback ? t('Off') : t('On')}</PlaybackText>
            </Stack>
          </Stack>
          <UserProfileDrawerMenuItem
            key={'sign out'}
            item={{
              id: 'sign out',
              label: 'Sign out',
              icon: <LogoutIcon />,
            }}
            onClick={handleSignOut}
            selected={selected === 'sign out'}
          />
        </SectionDataContainer>
      </DrawerContainer>

    </RightDrawerBase>
  </Backdrop>;
};

export default UserProfileDrawer;
