
import {
  Account,
  InvoiceId, PaymentMethod, ProjectId, ShortUserRoleId, SubscriptionPerSeat, WithCreationInfo, WithMedia, WithModificationInfo
} from '..';
import { Space as BaseSpace, MemberId, UTCTime, UserId } from '@mindhiveoy/schema';
import { BillingInfo } from '../payments/BillingInfo';
import { Member } from '../users/user';
import { ModuleId } from '../modules';
import { PlanId } from '../plans';

export type SpaceId = string;

export enum SeatType {
  FACILITATOR = 'facilitator',
  USER = 'user'
}

export type SpaceSettingDocId = 'seat-map' | 'space-account';

export type SpaceSettings = SeatMapDoc | SpaceAccountDoc;

/**
 * Mapping for a single user in a space to a role in each project involved. The project
 * listing is kept in the structure to make it easy to calculate the number of seats
 * after altering the memberships of a project.
 */
export interface UserRoleInProjects {
  [projectId: ProjectId]: ShortUserRoleId
}

// TODO: Make all documents to set their fields under the content -field to separate them from
//       the schema logic.
/**
 * The SeatMap is an helper document to maintain the number of seats in use in a space.
 */
export interface SeatMapDoc {
  type: 'seat-map';
  /**
   * Map of all users in space. This is being used to to keep track of users in different roles
   * currently in space. From this map we can calculate how many seats are in use.
   */
  users: {
    [userId: UserId]: UserRoleInProjects
  }
}

export interface SpaceAccountDoc {
  type: 'space-account';
  account: Account;
}

export type SpaceSubscription = SubscriptionPerSeat<SeatType>;

/**
 * indexed type to keep payment methods of user in space wallet
 */
export interface SpaceOwnerPaymentMethod {
  /**
   * user which is  owner of the payment method
   */
  uid: UserId;
  /**
   * payment methods allowed to be use
   */
  methods: PaymentMethod[];
}

/**
 * interface declares object to keep billing info related to space
 */
export interface SpaceBillingInfo {
  /**
     * billing information for this space
     * it may be copied when first time paid plan selected
     */
  billingInfo: BillingInfo;

  /**
   * actual user who pay for this space. It may be set when paid plan is being selected
   */
  payer: UserId;

  /**
   * actual payment method
   */
  paymentMethod: PaymentMethod;

  /**
   * wallet of the space. Admins can add or remove their own payment methods here
   */
  wallet: SpaceOwnerPaymentMethod[];

  /**
   * next billing time
   */
  nextBilling?: UTCTime;
}

/**
 * single member status change
 */
export interface MemberChange {
  /**
   * member Id
   */
  memberId: MemberId;
  /**
   * where to delete this member
   */
  delete?: boolean;
  /**
   * where to change role
   */
  newRole?: ShortUserRoleId;
}

export interface MembersToChange {
  /**
   * project Id
   */
  projectId: ProjectId;
  /**
   * members to remove
   */
  members: MemberChange[];
}

/**
 * structure to keep paid but still not applied space configuration
 * the field shall be valid since invoice created till invoice paid
 * when invoice paid it will be applied
 */
export interface SpaceFutureConfiguration {
  /**
   * optional invoice reference. If it is present this will be applied on invoice paid
   */
  invoiceId?: InvoiceId;
  /**
   * future subscription
   */
  subscription: SpaceSubscription;
  /**
   * optional projects to survive
   */
  projectsToSurvive?: ProjectId[];
  /**
   * members to remove
   */
  membersToChange?: MembersToChange[];
}

export interface Seats {
  /**
   * number of users that are facilitators
   */
  facilitators: number;
  /**
   * number of total users
   */
  users: number;
}

/**
 * In use and available seats for the space
 */
export interface SeatStats {
  inUse: Seats;
  available: Seats;
}
// TODO: Move billing info and futureConfiguration to separate document
export interface Space extends BaseSpace<PlanId, ModuleId>,
  WithMedia, WithCreationInfo, WithModificationInfo {
  /**
   * Project number counter.
   *
   * @deprecated Use doc id length generated unique id's for projects
   */
  // TODO: Remove this field, when it is safe to do so.
  projectNo: number;
  /**
   * In use and available seats for the space
   */
  seatStats: SeatStats;
  /**
   * array of space owners (who are responsible for the billing)
   */
  owners: UserId[];
}

export type SpaceMember = Member
