import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';

/**
 * A typeahead option for mentions.
 */
export class MentionTypeaheadOption extends MenuOption {
  name: string;
  uid: string;
  picture: JSX.Element;

  /**
   * Creates a new mention typeahead option.
   *
   * @param name  The name of the mention
   * @param uid The unique identifier of the mention
   * @param picture The picture of the mention
   */
  constructor(name: string, uid: string, picture: JSX.Element) {
    super(name);
    this.name = name;
    this.uid = uid;
    this.picture = picture;
  }
}
