import type { Comment, Quadrant } from '@shared/schema/src';
import type { CommentViewModelFilter } from '../CommentViewModel';
import type { TFunction } from 'next-i18next';
import type { WithId } from '@mindhiveoy/schema';

export const quadrantDisplayLabels = {
  1: 'I',
  2: 'II',
  3: 'III',
  4: 'IV',
};

export interface QuadrantFilter extends CommentViewModelFilter {
  id: 'quadrant'

  quadrants: Quadrant[];

  updateQuadrants: (quadrants: Quadrant[]) => void;
}

/**
 * Filter that filters comments by date range.
 */
class QuadrantFilterImplementation implements QuadrantFilter {
  public readonly id = 'quadrant';

  public quadrants: Quadrant[] = [];

  private _quadrantSet = new Set<Quadrant>();

  public updateQuadrants = (quadrants: Quadrant[]) => {
    this.quadrants = quadrants.slice(0);

    this.quadrants.sort((a, b) => a - b);

    this._quadrantSet = new Set(quadrants);
  };

  filter = (comment: WithId<Comment>) => {
    if (this.quadrants.length === 0) {
      return true;
    }
    if (!comment.answer || !comment.quadrant) {
      return false;
    }
    return this._quadrantSet.has(comment.quadrant);
  };

  displayName = (t: TFunction) => {
    if (!this.quadrants.length) {
      return t('no-filtering');
    }
    return `${this.quadrants.map((quadrant) => quadrantDisplayLabels[quadrant]).join(', ')}`;
  };
}

export default QuadrantFilterImplementation;
