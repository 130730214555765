import { isMobileDevice } from 'components/canvas/panelistView/isMobileDevice';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import type { Comment } from '../../../@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

type CommentCountProps = {
  comment: WithId<Comment<any>>;
  onReplyClick: (event: React.MouseEvent<any, MouseEvent>, shouldFocus?: boolean) => void;
};
export const CommentCount = ({
  comment, onReplyClick,
}: CommentCountProps): React.ReactNode => {
  const {
    t,
  } = useTranslation();

  const commentCountStyle = useMemo(() => ({
    display: 'inline',
    margin: isMobileDevice() ? '0 0 0.3rem 0' : '0.5rem 0',
    fontSize: isMobileDevice() ? '0.8rem' : '1rem',
  }), []);

  return <motion.div
    style={commentCountStyle}
    whileHover={{
      scale: 1.05,
      color: 'rgba(224, 224, 255, 0.8)',
    }}
    whileTap={{
      scale: 0.95,
      opacity: 0.8,
    }}
    onClick={onReplyClick}
  >
    {t('number-of-comments', {
      count: comment?.replyCount ?? 0,
      postProcess: 'interval',
    })}
  </motion.div>;
};
