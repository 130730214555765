import { forwardRef, useMemo } from 'react';
import { motion } from 'framer-motion';
import type { CSSProperties, PropsWithChildren } from 'react';

// const Container = styled(motion.div)`
//   height: 100%;
//   margin: 0;
//   overflow: hidden;
//   padding: 0;
// `;

const variants = {
  open: {
    opacity: 1,
    width: 'auto',
  },
  collapsed: {
    opacity: 0,
    width: 0,
  },
};

interface AnimateHeightProps {
  visible: boolean;
  style?: CSSProperties;
}
const TRANSITION = {
  ease: 'easeInOut',
  duration: 0.3,
};
/**
 * Animates the width of the children when visible is toggled.
 *
 * @param {AnimateHeightProps} props - The props.
 * @returns
 */
const AnimateWidth = forwardRef<HTMLDivElement, PropsWithChildren<AnimateHeightProps>>(
  ({
    children,
    style,
    visible,
  }, ref) => {
    const mergedStyle = useMemo(() => {
      return {
        height: '100%',
        margin: 0,
        overflow: 'hidden',
        padding: 0,
        ...style,
      };
    }, [style,]);

    return <motion.div
      ref={ref}
      animate={visible ? 'open' : 'collapsed'}
      inherit={false}
      initial={visible ? 'open' : 'collapsed'}
      style={mergedStyle}
      transition={TRANSITION}
      variants={variants}
    >
      {children}
    </motion.div>;
  }
);

AnimateWidth.displayName = 'AnimateWidth';

export default AnimateWidth;
