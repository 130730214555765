/* eslint-disable @typescript-eslint/no-explicit-any */

import config from 'config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const observableMap = new Map<string, any>();

/**
 * A developer tool to be used with local debug constants. By defining
 * the debug state using this function, will make it sure that the debug
 * state is only active in the development environment.
 *
 * @param observable
 * @param name
 * @returns
 */
export const watchRef = (observable: any, name: string) => {
  const oldValue = observableMap.get(name);

  if (oldValue !== observable) {
    observableMap.set(name, observable);
    if (oldValue) {
      console.info(`%cWATCH: ${name} %cchanged`, 'color: green', 'color: yellow');
      console.log('old value:');
      prettyPrint(oldValue);

      console.log('new value:');
      prettyPrint(observable);
      return;
    }
    console.info(`%cWATCH: ${name} %cinitialized`, 'color: green', 'color: lightgray');
    prettyPrint(observable);
  }
};

/**
 * Pretty print the value to the console.
 *
 * @param value
 * @returns
 */
const prettyPrint = (value: any) => {
  if (typeof value === 'object') {
    const obj = {};
    Object
      .entries(value)
      .forEach(([key, value,]) => {
        if (typeof value === 'object') {
          (obj as any)[key] = '[object]';
          return;
        }
        (obj as any)[key] = value;
      });
    console.log(JSON.stringify(obj, null, 2));
    return;
  }
  console.log(JSON.stringify(value, null, 2));
};

/**
 * A developer tool to be used with local debug constants. By defining
 * the debug state using this function, will make it sure that the debug
 * state is only active in the development environment.
 *
 * Use it like this:
 *
 * ```typescript
 * // Tells that the debug is active, but will be false in staging and production
 * const DEBUG_MY_NEXT_GREAT_FEATURE = debugSwitch(true);
 * ```
 *
 * @param active
 * @returns
 */
export const debugSwitch = (active: boolean): boolean => {
  return config.environment.target === 'development' && active;
};
