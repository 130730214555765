import styled from '@emotion/styled';

export const ActionButtons = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${theme.spacing(2)};
  gap: ${theme.spacing(2)};
`);
