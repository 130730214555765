/* eslint-disable @typescript-eslint/no-explicit-any */
import { debugSwitch } from 'utils/debug/debug';
import { globalCache } from '../cache/BlocCache';
import { immutable } from '../utils/immutable';
import BlocBase from '../BlocBase';
import normalizeDatabasePath from '../utils/normalizeDatabasePath';
import type { BlocCache } from '../cache/BlocCache';
import type {
  BlocDocumentIdChange,
  BlocDocumentRemoteCall,
  BlocErrorFunction,
  BlocOptimisticCall, BlocOptimisticQueryCall,
  BlocQueryConstraint, BlocQueryRemoteCall,
  BlocQuerySnapshotListener, BlocWhereFilterOp, CacheConstructorArgsBase, SortOrder
} from '../types';
import type { BlocDocumentRemoteVoidCall } from '../index';
import type { CachedCollectionReference } from '../cache/CachedCollectionReference';
import type { DocumentData } from '@mindhiveoy/firebase-schema';
import type { FirestoreError } from 'firebase/firestore';
import type { WithCollectionQueryParams } from '../databaseController/DatabaseController';
import type { WithId } from '@mindhiveoy/schema';

export let DEBUG_BLOC_FRAMEWORK = debugSwitch(process.env.RUNNING_IN_VITEST === 'true');

export const startDebuggingBlocFramework = () => {
  DEBUG_BLOC_FRAMEWORK = debugSwitch(true);
};

export interface CacheCollectionConstructorArgs<T extends DocumentData, P>
  extends CacheConstructorArgsBase<T, P>, WithCollectionQueryParams<T> {
  collectionPath: string;
}

export interface CollectionSubscriptionArgs<T extends DocumentData> {
  listener: BlocCollectionListener<T>;
  onError?: (error: FirestoreError) => void;
}

export type BlocCollectionListener<T extends DocumentData> = (
  docs: WithId<T>[],
) => void;

/**
 * Cache Collection carries a local cache and reference information of a single document
 * collection. It caches documents locally and keeps listeners up to date optimistically.
 *
 * ## Optimistic updates
 * The class gives tools to do local optimistic updates of documents. So the data will be
 * updated locally immediately, before the data altering operation is sent to backend. When
 * the backend will trigger back with altered data, the change event will be trigged back
 * to local listeners only if the data is different from the optimistic update.
 * Features:
 *
 * ## Optimization on snapshot listeners
 * Snapshot listers are optimized to exists once per listening target. This makes it
 * possible to use React hooks safely without extra database operation overhead.
 *
 * ## Deep compare change trigger
 * Changes are triggered for the listeners only when there is relevant changes on the data.
 * Using ignoredFields -argument fields that should not cause update, like lastChanged ie.
 * can be ignored in comparison.
 *
 */
export class BlocQuery<T extends DocumentData, P> extends BlocBase<T, P> {
  public readonly collectionPath!: string;

  private readonly cache!: BlocCache;
  protected readonly onError?: BlocErrorFunction;
  private collectionReference!: CachedCollectionReference<T>;
  /**
   * Constructor
   * @param {CacheCollectionConstructorArgs<T>}  args  Arguments.
   */
  constructor({
    collectionPath,
    cache = globalCache,
    params,
    converter,
    onError,
  }: CacheCollectionConstructorArgs<T, P>) {
    super(params, converter);

    this.onError = onError;

    if (typeof window === 'undefined') {
      return;
    }
    this.cache = cache;

    if (this.invalidState) {
      return;
    }
    try {
      this.collectionPath = normalizeDatabasePath(collectionPath);
    } catch (error) {
      this.invalidState = true;
      return;
    }

    // TODO: Validate, if these are needed
    this.collectionReference = cache.addCollectionReference(
      this.collectionPath
    );

    // DEBUG_BLOC_FRAMEWORK && console.debug(`query: created: ${this.collectionPath}`);
  }

  protected _updateListener?: (data: T[]) => void;

  /**
   * Register the extended class to listen document changes. There can be only one listener
   * at the time.
   *
   * @param callback
   */
  protected registerUpdateListener = (callback: (data: T[]) => void) => {
    this._updateListener = callback;
  };

  /**
   * Indicates if the given document is cached locally.
   * @param {string} docId
   * @return {boolean}
   */
  public isDocumentCached = (docId: string): boolean => {
    return this.collectionReference.isDocumentCached(docId);
  };
  /**
   * Release resources before destruction.
   */
  dispose = () => {
    this.cache.removeCollectionReference(
      this.collectionPath
    );
    // DEBUG_BLOC_FRAMEWORK && console.debug(`query: disposed: ${this.collectionPath}`);
  };

  private constraints: BlocQueryConstraint[] = [];

  /**
   * Limit query with where clause
   * @param {WhereQueryConstraint} args
   * @return {BlocQuery} Self.
   */
  public where = (
    fieldPath: string,
    opStr: BlocWhereFilterOp,
    value: unknown
  ): BlocQuery<T, P> => {
    this.constraints.push({
      type: 'where',
      fieldPath,
      opStr,
      value,
    });
    return this;
  };

  /**
   * Order query with given field
   * @param {string}          fieldPath    The field used for sorting.
   * @param {'asc' | 'desc'=} directionStr Optional sorting direction. The default is 'asc'.
   * @return {BlocQuery} Self.
   */
  public orderBy = (
    fieldPath: string,
    directionStr: SortOrder = 'asc'
  ): BlocQuery<T, P> => {
    this.constraints.push({
      fieldPath,
      directionStr,
      type: 'orderBy',
    });
    return this;
  };

  /**
   * Limit result set.
   * @param {LimitQueryConstraint} args
   * @return {BlocQuery} Self.
   */
  public limit = (
    limit: number
  ): BlocQuery<T, P> => {
    this.constraints.push({
      type: 'limit',
      limit,
    });
    return this;
  };

  /**
   * Create document
   * @param {BlocDocumentRemoteCall<T>}     remoteCall
   * @param {BlocErrorFunction}     onError
   */
  protected async _create(
    remoteCall: BlocDocumentRemoteCall<T>,
    onError?: BlocErrorFunction,
  ): Promise<WithId<T>>;
  /**
   * Create document
   * @param {BlocDocumentRemoteCall<T>}     remoteCall
   * @param {BlocErrorFunction}     onError
   * @param {BlocDocumentRemoteCall<T>}     optimisticResponse
   * @param {BlocDocumentIdChange}  onDocIdChange
   */
  protected async _create(
    remoteCall: BlocDocumentRemoteCall<T>,
    onError: BlocErrorFunction,
    optimisticResponse: BlocOptimisticCall<T>,
    onDocIdChange: BlocDocumentIdChange
  ): Promise<T>;
  /**
   * Create document
   * @param {BlocDocumentRemoteCall<T>}     remoteCall
   * @param {BlocErrorFunction}     onError
   * @param {BlocOptimisticCall<T>}     optimisticResponse
   * @param {BlocDocumentIdChange}  onDocIdChange
   */
  protected async _create(
    remoteCall: BlocDocumentRemoteCall<T>,
    onError: BlocErrorFunction,
    optimisticResponse?: BlocOptimisticCall<T>,
    onDocIdChange?: BlocDocumentIdChange
  ) {
    let _candidateId: string | undefined;

    const backendCall = async (_candidateId?: string) => {
      try {
        const dataWithId = await remoteCall(_candidateId);

        if (!dataWithId) {
          throw new Error('No data returned from remote call');
        }
        const docId = dataWithId._id;
        let data = this.preprocessDoc(dataWithId);
        delete (data as any)._id;

        data = immutable(data);
        const documentReference = this.collectionReference.addDocumentReference({
          docId,
          candidate: false,
          initialData: data,
          onDataChange: ({
            dispatchChangeEvent,
          }) => {
            dispatchChangeEvent();
          },
        });
        documentReference?.validateDocumentId(docId, onDocIdChange as any);
        // documentReference?.setData(data); // Will erase candidate info

        // If there is a candidate id and document id is different, delete the candidate
        if (_candidateId && _candidateId !== docId) {
          this._delete(_candidateId, async () => { }, onError, true);
        }
        // TODO: This must trigger the listener if the document differs from the local data
        return dataWithId;
      } catch (error: any) {
        this._handleError(onError)(error);
        throw error;
      }
    };
    // Make database operation as async call and let the front end
    // to continue immediately.
    if (optimisticResponse) {
      let response = optimisticResponse() as any;
      // If response is a promise, wait for it to resolve
      if (response instanceof Promise) {
        response = await response;
      }
      const {
        _id: docId,
        ...data
      } = response;

      _candidateId = docId;
      /*
       * Create document locally and be optimistic that the document id will hold
       */
      const documentReference = this.collectionReference
        .addDocumentReference({
          docId,
          candidate: true,
          initialData: {
            _candidateId,
            ...data,
          },
        });

      backendCall(docId);

      return {
        _id: docId,
        ...documentReference?.data,
      } as WithId<T>;
    }
    return backendCall();
  }

  protected async _update(
    docId: string,
    remoteCall: BlocDocumentRemoteCall<T>,
    onError?: BlocErrorFunction,
    optimisticResponse?: BlocOptimisticCall<T>
  ): Promise<WithId<T>>;

  protected async _update(
    remoteCall: BlocQueryRemoteCall<T>,
    onError?: BlocErrorFunction,
    optimisticResponse?: BlocOptimisticQueryCall<T>
  ): Promise<WithId<T>[]>;

  /**
   * Create a new document to the collection.
   * @param {string}  docId   The id candidate of the document to be added. The id may still change
   *                          at the backend if the collide with existing document id.
   * @param {BlocDocumentRemoteCall<T>}       remoteCall    The content of the document.
   * @param {BlocErrorFunction}     onError  Optional custom update function.
   * @param {BlocOptimisticCall<T>=}     optimisticResponse  Optional custom update function.
   * @return {T}              The optimistically updated document content. The document content is the local immediate
   *                          best guess of the the new content. This may still change after the backend update.
   */
  protected async _update(
    docIdOrDocs: string | BlocQueryRemoteCall<T>,
    remoteCalOrError: BlocDocumentRemoteCall<T> | BlocErrorFunction | undefined,
    onErrorOrOptimisticResponse?: BlocErrorFunction | BlocOptimisticQueryCall<T>,
    possibleOptimisticResponse?: BlocOptimisticCall<T>
  ): Promise<WithId<T> | WithId<T>[] | undefined> {
    const {
      type,
      docId,
      remoteDocumentCall,
      remoteQueryCall,
      onError,
      optimisticResponse,
    } = this.$resolveUpdateArgs(
      docIdOrDocs,
      remoteCalOrError!,
      onErrorOrOptimisticResponse!,
      possibleOptimisticResponse!
    );

    const backendCall = async () => {
      try {
        // A bit awkward structure to help Typescript to keep up with the
        // method overloading.
        switch (type) {
          case 'doc': {
            const data = await remoteDocumentCall!();

            const {
              _id,
              ...docData
            } = this.preprocessDoc(data);

            return await this.collectionReference.set(
              _id,
              immutable(docData) as unknown as T
            );
          }
          case 'array': {
            let data = await remoteQueryCall!();

            data = immutable(this.preprocessDocs(data));

            this.collectionReference.set(
              data
            );
            return data;
          }
        }

        // TODO: Clear candidate info
      } catch (error: any) {
        this._handleError(onError)(error);
      }
      stop();
    };

    if (optimisticResponse) {
      let data = optimisticResponse();
      data = immutable(data as any);

      // TODO: update options
      this.collectionReference.set(
        docId,
        data as T,
        {
          merge: false,
        }
      );
      // Make database operation as async call and let the front end
      // to continue immediately.
      backendCall();
      return data as any;
    }

    return backendCall();
  }

  private $resolveUpdateArgs = (
    docIdOrDocs: string | BlocQueryRemoteCall<T>,
    remoteCallOrError: BlocErrorFunction | BlocDocumentRemoteCall<T>,
    onErrorOrOptimisticResponse: BlocErrorFunction | BlocOptimisticQueryCall<T>,
    possibleOptimisticResponse: BlocOptimisticCall<T>
  ) => {
    if (typeof docIdOrDocs === 'string') {
      const docId = docIdOrDocs;
      const remoteCall = remoteCallOrError as BlocDocumentRemoteCall<T>;
      const onError = onErrorOrOptimisticResponse as BlocErrorFunction;
      const optimisticResponse = possibleOptimisticResponse;
      return {
        type: 'doc',
        docId,
        remoteDocumentCall: remoteCall,
        onError,
        optimisticResponse,
      };
    }
    const remoteCall = docIdOrDocs as BlocQueryRemoteCall<T>;
    const onError = remoteCallOrError as BlocErrorFunction;
    const optimisticResponse = onErrorOrOptimisticResponse as BlocOptimisticQueryCall<T>;

    return {
      type: 'array',
      remoteQueryCall: remoteCall,
      onError,
      optimisticQueryResponse: optimisticResponse,
    };
  };

  /**
   * Delete a document to the collection.
   * @param  {string}  docId The id of the document to be added.
   * @param  {BlocDocumentRemoteCall<void>}  onRemoteCall
   * @param  {BlocErrorFunction}     onError
   * @param  {boolean=}              optimistic
   * @return {Promise<void>}
   */
  protected _delete = async (
    docId: string,
    onRemoteCall: BlocDocumentRemoteVoidCall,
    onError?: BlocErrorFunction,
    optimistic = true
  ): Promise<void> => {
    const resolveBackendResponse = async () => {
      try {
        await onRemoteCall();
        this.collectionReference.delete(docId);
      } catch (error: any) {
        this._handleError(onError)(error);
      }
    };
    if (optimistic) {
      resolveBackendResponse();
      this.collectionReference.delete(docId);
      return;
    }
    return resolveBackendResponse();
  };

  // eslint-disable-next-line valid-jsdoc
  /**
   *
   * @param {BlocCollectionListener<T>} listener
   */
  public subscribe = (
    listener: (docs: WithId<T>[]) => void,
    onError?: BlocErrorFunction
  ): () => void => {
    if (typeof window === 'undefined' || !this.collectionReference) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => { };
    }
    const _l: BlocQuerySnapshotListener<T> =
      (_docs) => {
        // TODO: cache mappings
        const _d = _docs ? _docs.map((doc) => immutable(
          this.preprocessDoc({
            ...doc.data,
            _id: doc.id,
          } as WithId<T>))) : [];
        // TODO: The listener will not not know if _docs is undefined or an empty array
        listener(_d as any);
      };
    return this.collectionReference.onQuerySnapshot(
      this.constraints,
      _l,
      this._handleError(onError)
    );
  };

  protected _handleError = (onError?: BlocErrorFunction) => {
    return (error: Error) => {
      const code = (error as any).code;
      switch (code) {
        case 'permission-denied':
          console.error('Permission denied', {
            path: this.collectionPath,
            error,
          });
          onError && onError(error, 'permission-denied');
          break;

        case 'not-found':
          console.error('Not found', {
            path: this.collectionPath,
            error,
          });
          onError && onError(error, 'not-found');
          break;

        case 'unauthenticated':
          console.error('Unauthenticated', {
            path: this.collectionPath,
            error,
          });
          onError && onError(error, 'unauthenticated');
          break;

        case 'cancelled':
          console.error('Cancelled', {
            path: this.collectionPath,
            error,
          });
          onError && onError(error, 'cancelled');
          break;

        default:
          console.error('Unknown error', {
            path: this.collectionPath,
            error,
          });
          onError && onError(error, 'unknown');
      }
    };
  };
}
