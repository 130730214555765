import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import styled$ from '../../../utils/react/styled$';

export const CardBase = styled$(Card)<{
  $maxWidthRem?: string;
  $withBorder?: boolean;
  $withoutPadding?: boolean;
  $withMaxHeight?: boolean;
  }>(({
    theme,
    $maxWidthRem,
    $withBorder,
    $withoutPadding,
    $withMaxHeight,
  }) => `
  width: ${$maxWidthRem ? $maxWidthRem : '100%'};
  background-color: ${theme.palette.background.innerPaper};
  overflow: visible;
  border: 2px solid ${$withBorder ? theme.palette.primary.main : 'transparent'};
  border-radius: ${theme.shape.borderRadius / 2}px;
  padding: ${theme.spacing($withoutPadding ? 0 : 3.5)};
  height: ${$withMaxHeight ? '100%' : 'auto'};
  @media (max-width: 390px) {
    width: 100%;
  }
`);

export const DialogBase = styled$(Dialog)<{
  $padding?: number;
  $size?: 'full' | 'medium' | 'auto' | true,
}>(({
  theme,
  $size,
  $padding = 2,
}) => `
  overflow: visible;
  border-radius: ${theme.shape.borderRadius}px;
  padding: ${theme.spacing($padding)};
  margin-left: auto;
  margin-right: auto;
  & .MuiDialog-paperScrollPaper {
    margin: 0;
    background-color:  ${theme.palette.background.default};
    border: 2px solid ${theme.palette.primary.main} !important;
    width: 100vw;
  }
  width: ${$size === 'full' || $size === true ? '100%' : $size === 'medium' ? '70%' : 'auto'}; 
  height: 100dvh;
`);

// height: 100vh;

export const PlanInfoText = styled$.div<{
    $withMargin?: boolean
  }>(({
    theme,
    $withMargin,
  }) => `
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 1.8;
  color: ${theme.palette.text.lightgrey};
  ${$withMargin && `
    margin: ${theme.spacing(0, 0.5)};
  `}
`);

export const Emphasis = styled$.span<{
    $withMargin?: boolean
  }>(({
    theme,
    $withMargin,
  }) => `
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 1.5;
  color: ${theme.palette.primary.contrastText};
  margin: ${theme.spacing(0, $withMargin ? 0.3 : 0)};
`);

export const Details = styled(Typography)`
  margin: 0;
  font-weight: 200;
  font-style: normal;
  font-size: 12px;
  line-height: 1.5;
`;

export const Grow = styled$.div<{
    $alignment?: string;
  }>(({
    $alignment,
  }) => `
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: ${$alignment ?? 'stretch'};

`);

export const Column = styled$.div<{
  $justify?: string;
  }>(({
    $justify,
  }) => `
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: ${$justify ?? 'space-between'};
`);

export const Row = styled.div<{
    $withMargin?: boolean
  }>(({
    theme,
    $withMargin,
  }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing($withMargin ? 2 : 0)};
`);

export const FormTextField = styled(TextField)(({
  theme,
}) => `
  width: 100%;
  .MuiInputBase-root {
    color: ${theme.palette.text.primary};
    margin-bottom: 0;
    // margin-top: ${theme.spacing(0.5)};
    .MuiOutlinedInput-input {
      padding: ${theme.spacing(2)};
      color: ${theme.palette.primary.contrastText};
      font-weight: 400;
      font-size: 14px;
    }
    .MuiInputBase-inputMultiline{
      padding: 0;
    }
  }
  .MuiInputLabel-root {
    color: ${theme.palette.primary.main};
  }
  .MuiInputLabel-root.Mui-focused {
    color: ${theme.palette.primary.main};
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.primary.main};
  }
`);

export const CustomLink = styled.a(({
  theme,
}) => `
  text-decoration: none;
  font-weight: 400;
  font-size: inherit;
  letter-spacing: 0px;
  line-height: 1.2;
  width: fit-content;
  color: ${theme.palette.primary.main};
  cursor: pointer;
  border-bottom: 1px solid ${theme.palette.primary.main};
  &:hover {
    color: ${theme.palette.primary.main}B0;
  }
  @media(max-width: 600px) {
    font-size: 0.9rem;
  }
`);
