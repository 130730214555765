import { getStorageValueWithExpiry } from '../../../../utils/firebase/localStorageUtils';
import type { AuthStatus } from '@mindhiveoy/react-auth/dist/AuthContext';
import type { UserId } from '@mindhiveoy/schema';

interface UserArgs {
  uid?: UserId;
  email?: string | null;
}

/**
 * Resolves the authentication status based on the user arguments.
 * @param args
 * @returns
 */
const resolveAuthStatus = (
  args: UserArgs
): AuthStatus => {
  const currentStatus = getStorageValueWithExpiry('authStatus') as AuthStatus;

  // If the current status is one of the following, return it as it is. This is
  // to avoid changing the status while the user is in the middle of the
  // authentication process.
  switch (currentStatus) {
    case 'signing-in':
    case 'signing-out':
    case 'signed-in':
    case 'signing-up':
      return currentStatus;

    default:
      if (args.uid) {
        if (args.email) {
          return 'signed-in';
        }
        return 'anonymous';
      }
      return 'initializing';
  }
};

export default resolveAuthStatus;
