import {
  Accordion, AccordionDetails, AccordionSummary, ClickAwayListener, FormControl, ListSubheader, MenuItem, Popper, Typography
} from '@mui/material';
import { FieldSet } from 'components/forms/FieldSet';
import { Legend } from 'components/forms/Legend';
import { MessageAttachmentType } from '@shared/schema/src';
import { generateUrlToCanvasOrSession } from 'components/messages/messageUtils';
import { setPreventMessagingClickAway } from 'components/messages/MessageComponent';
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropertyEditorControl from '../PropertyEditorControl';
import styled from '@emotion/styled';
import usePathParams from 'utils/hooks/usePathParams';
import type { MessageAttachment, MessageAttachmentComment, MessageAttachmentSession } from '@shared/schema/src';
import type { MessageAttachmentPropertyEditor } from '../PropertyEditorTypes';
import type { PropertyEditorFunction } from 'components/builder/builders/propertyEditorBuilder';

const StyledAccordion = styled(Accordion)(({
  theme,
}) => `
  margin: 0;
  border-radius: 0 !important;
  background: ${theme.palette.background.default};
`);

const StyledText = styled(Typography)(() => `
  margin: 0;
  padding: 0;
  `);

const AttachmentContainer = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  padding: ${theme.spacing(0, 0, 3, 0)};
`);

const StyledAccordionDetails = styled(AccordionDetails)(() => `
  max-height: 100px;
  padding-top: 0;
  overflow-y: scroll;
`);

const StyledAccordionSummary = styled(AccordionSummary)(() => `
  max-height: 50px;
  overflow-y: auto;
`);

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: 310,
//     },
//   },
// };

const MessageAttachmentEditor: PropertyEditorFunction<MessageAttachment, MessageAttachmentPropertyEditor> = ({
  data,
  propertyName,
  propertyConfig: {
    displayName,
    defaultValue,
    options,
  },
  onSave,
}) => {
  const [value, setValue,] = useState<MessageAttachment>(data ?? defaultValue);
  const pathParams = usePathParams();

  const {
    t,
  } = useTranslation();

  useEffect(() => {
    setValue((value) => value !== data ? data : value);
  }, [data, setValue,]);

  const getSelectedCanvas = useCallback((id: string) => {
    return options?.canvasOptions?.find((canvas) => canvas._id === id);
  }, [options?.canvasOptions,]);

  const [anchorEl, setAnchorEl,] = useState<null | HTMLElement>(null);

  const handlePopup = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setPreventMessagingClickAway(true);

    setAnchorEl(event.currentTarget);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'popper' : undefined;

  const theme = useTheme();

  const closePopUp = useCallback(() => {
    setAnchorEl(null);
    setPreventMessagingClickAway(false);
  }, []);

  const handleSessionClick = useCallback(() => {
    if (!options.sessionOptions?._id) {
      return;
    }
    const newSelectedOption = {
      type: MessageAttachmentType.SESSION,
      option: options?.sessionOptions,
    };

    const newValue: MessageAttachmentSession = {
      ...value,
      type: MessageAttachmentType.SESSION,
      shortText: newSelectedOption.option?.name ?? '',
      selectedSession: newSelectedOption.option,
      url: generateUrlToCanvasOrSession(pathParams),
    };
    setValue(newValue);

    if (newValue !== data && onSave) {
      onSave(propertyName, newValue);
    }
  }, [data, onSave, options.sessionOptions, pathParams, propertyName, value,]);

  const handleCanvasClick = useCallback((id: string) => () => {
    const newSelectedOption = {
      type: MessageAttachmentType.CANVAS,
      option: getSelectedCanvas(id),
    };

    const newValue = {
      ...value,
      type: newSelectedOption.type,
      shortText: newSelectedOption.option?.name ?? '',
      selectedCanvas: newSelectedOption.option,
      url: generateUrlToCanvasOrSession(pathParams, newSelectedOption.option),
    } as MessageAttachment;
    setValue(newValue);
    if (newValue !== data && onSave) {
      onSave(propertyName, newValue);
    }
    closePopUp();
  }, [closePopUp, data, getSelectedCanvas, onSave, pathParams, propertyName, value,]);

  return <>
    <FormControl fullWidth>
      <AttachmentContainer>
        {value.type === MessageAttachmentType.CANVAS || value.type === MessageAttachmentType.SESSION ?
          <PropertyEditorControl>

            <Popper
              open={open}
              id={id}
              anchorEl={anchorEl}
              style={{
                zIndex: 20000,
                backgroundColor: theme.palette.background.default,
              }}
              placement='bottom'
            >
              <ClickAwayListener onClickAway={closePopUp}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'auto',
                }}>
                  <ListSubheader>{t('Session')}</ListSubheader>
                  <MenuItem
                    onClick={handleSessionClick}
                    value={options.sessionOptions?._id}>{options.sessionOptions?.name}</MenuItem>
                  <ListSubheader>{t('Question')}</ListSubheader>
                  {options?.canvasOptions?.map(({
                    _id, name,
                  }) =>
                    <MenuItem
                      key={_id}
                      onClick={handleCanvasClick(_id)}
                      value={_id}>{name}
                    </MenuItem>
                  )}
                </div>
              </ClickAwayListener>
            </Popper>
            <div onClick={handlePopup}>
              <FieldSet >
                <Legend>{displayName}</Legend>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100',
                    position: 'relative',
                  }}
                >
                  <span
                    style={{
                      overflow: 'hidden',
                    }}>
                    {
                      value.type === MessageAttachmentType.CANVAS ? value.selectedCanvas?.name : value.selectedSession?.name
                    }
                  </span>
                  <ArrowDropDownIcon />
                </div>
              </FieldSet>
            </div>
          </PropertyEditorControl> :
          <>
            <StyledText>{displayName ?? ''}</StyledText>
            <StyledAccordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <StyledText>{value.shortText}</StyledText>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <StyledText>
                  {(value as MessageAttachmentComment).longText}
                </StyledText>
              </StyledAccordionDetails>
            </StyledAccordion>
          </>
        }
      </AttachmentContainer>
    </FormControl>
  </>;
};

MessageAttachmentEditor.config = {
  type: 'message-attachment',
};

export default MessageAttachmentEditor;
