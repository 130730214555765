/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import type { ComponentArrayModel } from 'components/builder/models/ComponentArrayModel';
import type { ComponentModel } from 'components/builder/models/ComponentModel';

type UpdateObjectProperty<D = any> = <P extends keyof D>(propertyName: keyof D, value: D[P]) => void;
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
type UpdateObjectField<D = any> = <P extends keyof D>(propertyName: string, value: any) => void;

export interface ComponentDataContext<D = any> {
  model: ComponentModel<D>;
  /**
   * Update a property on the model.
   * @param {string}  propertyName  The name of the property or a path to the property to be updated.
   * @param {any}     value
   * @returns
   */
  updateProperty: UpdateObjectProperty<D> | UpdateObjectField<D>;

  getProperty: (fieldPath: string) => any;

  deleteProperty: (fieldPath: string) => void;
}

export interface ComponentArrayDataContext<D = any> {
  model: ComponentArrayModel;
  updateProperty: UpdateObjectProperty<D> | UpdateObjectField<D>;

  getProperty: (fieldPath: string) => any;

  deleteProperty: (fieldPath: string) => void;
}

export const ComponentDataContext = React.createContext<ComponentDataContext<any>>({
  model: {} as any,
  updateProperty: () => { },
  getProperty: () => { },
  deleteProperty: () => { },
});

export const useComponentDataContext = <D = any>() => {
  return useContext(ComponentDataContext) as ComponentDataContext<D>;
};

export const useComponentArrayDataContext = <D = any>() => {
  // FIXME typing
  return useContext(ComponentDataContext) as unknown as ComponentArrayDataContext<D>;
};

export interface DataContextProviderProps<D> {
  model: ComponentModel<D> | ComponentArrayModel;
}
