/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentSelectionContext, SelectionContextInstance } from 'utils/hooks/useComponentSelectionContext';
import { useCallback, useMemo, useState } from 'react';
import { useConstant } from 'utils/hooks/useConstant';
import React from 'react';
import type { ComponentSelectionModel } from 'components/builder/models/selectionModels';
import type { PropsWithChildren } from 'react';

/**
 * Provider for component selection context. Wrap all components that should operate with the selected
 * component inside of this provider to make them act in sync with each other.
 *
 * @param {unknown}       props
 * @return {JSX.Element}  ComponentSelectionProvider element
 */
export const ComponentSelectionContextProvider = ({
  children,
}: PropsWithChildren<unknown>
) => {
  const instance = useConstant(() => new SelectionContextInstance());

  const [selection, _setSelection,] = useState<ComponentSelectionModel<object> | null>(instance.selection);
  const [rootSelection, _setRootSelection,] = useState<ComponentSelectionModel<object> | null>(instance.selection);

  const setSelection = useCallback((newSelection: ComponentSelectionModel<object>) => {
    // selection?.dispose(); // TODO: Memory handling
    instance.setSelection(newSelection);
    _setSelection(newSelection);
    _setRootSelection(instance.rootSelection);
  }, [instance,]);

  const context = useMemo(() => ({
    selection,
    rootSelection,
    setSelection,
    addSelectionListener: instance.addSelectionListener,
    removeSelectionListener: instance.removeSelectionListener,
  }), [instance.addSelectionListener, instance.removeSelectionListener,
    rootSelection, selection, setSelection,]);

  return <ComponentSelectionContext.Provider value={context as any}>
    { children }
  </ComponentSelectionContext.Provider>;
};
