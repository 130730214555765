import { debounce } from 'lodash';

/**
 * Returns an object with a `trigger` method for calling the function with debounce
 * and a `force` method for calling the function immediately regardless of debounce.
 *
 * @param callback - The function to debounce.
 * @param delay - The number of milliseconds to delay.
 * @returns An object with `trigger` and `force` methods.
 */
export const debouncedFunction = <T extends (...args: any[]) => any>(
  callback: T,
  delay: number
) => {
  const func = debounce(callback, delay);

  return {
    cancel: () => {
      func.cancel();
    },
    /**
     * Triggers the debounced function.
     *
     * @param args - Arguments for the callback function.
     */
    trigger: (...args: Parameters<T>) => {
      return func(...args);
    },
    /**
     * Forces immediate execution of the debounced function.
     *
     * @param args - Arguments for the callback function.
     */
    force: (...args: Parameters<T>) => {
      func.cancel();
      func(...args);
      func.flush();
    },
  };
};

// /**
//  * Returns an object with a `trigger` method for calling the function with debounce
//  * and a `force` method for calling the function immediately regardless of debounce.
//  *
//  * @param callback - The function to debounce.
//  * @param delay - The number of milliseconds to delay.
//  * @returns An object with `trigger` and `force` methods.
//  */
// export const debouncedMergeFunction = <T extends (...args: any[]) => any>(
//   callback: T,
//   delay: number
// ) => {
//   const func = debounce(callback, delay);

//   return {
//     cancel: () => {
//       func.cancel();
//     },
//     /**
//      * Triggers the debounced function.
//      *
//      * @param args - Arguments for the callback function.
//      */
//     trigger: (...args: Parameters<T>) => {
//       return func(...args);
//     },
//     /**
//      * Forces immediate execution of the debounced function.
//      *
//      * @param args - Arguments for the callback function.
//      */
//     force: (...args: Parameters<T>) => {
//       func.cancel();
//       func(...args);
//       func.flush();
//     },
//   };
// };
