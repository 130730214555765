import { ListenerHelper } from '@mindhiveoy/foundation';
import { startTransition } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import type { PlatformEvent, PlatformEventType } from './PlatformEvents';

/**
 * The listeners for the platform events.
 */
const listeners = new Map<PlatformEventType, ListenerHelper<PlatformEventListener<any>>>();

/**
 * The listener function that will be called when the event is broadcasted.
 */
type PlatformEventListener<T> = (event: T) => void;

/**
 * Subscribe to listen for platform events of a specific event type.
 *
 * @param events    The event to listen to.
 * @param listener  The listener function that will be called when the event is broadcasted.
 */
function subscribeForPlatformEvents<T extends PlatformEventType>(
  events: T,
  listener: PlatformEventListener<Extract<PlatformEvent, { type: T; }>>
): () => void;
/**
 * Subscribe to listen for platform events for multiple event types.
 * @param events    The list of events to listen to.
 * @param listener  The listener function that will be called when the event is broadcasted.
 */
function subscribeForPlatformEvents<T extends PlatformEventType[]>(
  events: T[],
  listener: PlatformEventListener<Extract<PlatformEvent, { type: T[number]; }>>
): () => void;
/**
 * Subscribe to listen for platform events for multiple event types.
 * @param events
 * @param listener
 * @returns
 */
function subscribeForPlatformEvents<T extends PlatformEventType | PlatformEventType[]>(
  events: T,
  listener: T extends PlatformEventType
    ? PlatformEventListener<Extract<PlatformEvent, { type: T; }>>
    : PlatformEventListener<Extract<PlatformEvent, { type: T[number]; }>>
): () => void {
  const eventTypes = Array.isArray(events) ? events : [events,];

  eventTypes.forEach((eventType) => {
    let _listeners = listeners.get(eventType);
    if (!_listeners) {
      _listeners = new ListenerHelper<PlatformEventListener<any>>();
      listeners.set(eventType, _listeners);
    }
    _listeners.add(listener);
  });

  return () => {
    eventTypes.forEach((eventType) => {
      const _listeners = listeners.get(eventType);
      if (_listeners) {
        _listeners.remove(listener);
        if (_listeners.isEmpty()) {
          listeners.delete(eventType);
        }
      }
    });
  };
};

/**
 * Broadcast a platform event to all listeners.
 *
 * @param event
 * @returns
 */
const sendPlatformEvent = (event: PlatformEvent) => {
  const _event = cloneDeep(event);

  let _listeners = listeners.get(_event.type);
  if (!_listeners || _listeners.isEmpty()) {
    return;
  }
  _listeners = cloneDeep(_listeners);

  startTransition(() => {
    _listeners.fire(_event);
  });
};

/**
 * The platform event context for the whole application's component tree. Use this context to listen and
 * broadcast events that are available in the whole application location independently.
 */
export type PlatformEventContextType = {
  /**
    * Subscribe to listen for platform events.
    * @param {PlatformEventType | PlatformEventType[]}  events    The event to listen to.
    * @param {*}                                        listener  The listener function that will be called when the event is broadcasted.
    * @returns (*) The unsubscribe function.
    */
  subscribeForPlatformEvents: {
    <T extends PlatformEventType[]>(events: T, listener: PlatformEventListener<ExtractEvents<T>>): () => void;
    <T extends PlatformEventType>(events: T, listener: PlatformEventListener<Extract<PlatformEvent, { type: T; }>>): () => void;
  };
  /**
   * Broadcast a platform event to all listeners.
   * @param event  The event to broadcast.
   */
  sendPlatformEvent: (event: PlatformEvent) => void;
};

type ExtractEvents<T extends PlatformEventType[]> = Extract<PlatformEvent, { type: T[number]; }>;

/**
 * Platform event system is a global event system that allows to broadcast and
 * listen for events in the whole application location independently. This is
 * useful to decouple components that are not directly related to each other and
 * are not under in the same component branch.
 *
 * You can access the event system directly by using this instance or use the
 * `usePlatformEventContext` -hook.
 *
 * @see {@link ./usePlatformEventContext}  for more information.
 */
export const platformEventSystem: PlatformEventContextType = {
  sendPlatformEvent,
  subscribeForPlatformEvents,
};
