import {
  Checkbox,
  ClickAwayListener,
  FormControl, ListItemText, ListSubheader, MenuItem, Popper, Typography
} from '@mui/material';
import { FieldSet } from 'components/forms/FieldSet';
import { Legend } from 'components/forms/Legend';
import { MessageRecipientsOptionType, MessageRecipientsType } from '@shared/schema/src';
import { StyledAvatar } from 'components/comments/CommentItem';
import { setPreventMessagingClickAway } from 'components/messages/MessageComponent';
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropertyEditorControl from '../PropertyEditorControl';
import styled from '@emotion/styled';
import type { MessageRecipientInput } from '@shared/schema/src';
import type { MessageRecipientsPropertyEditor } from '../PropertyEditorTypes';
import type { PropertyEditorFunction } from 'components/builder/builders/propertyEditorBuilder';

const RecipientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
`;

const StyledRecipientsText = styled(Typography)(({
  theme,
}) => `
  margin: 0;
  padding: ${theme.spacing(2, 2, 0, 2)};
  font-weight: bold;
`);

const StyledFiltersAppliedText = styled(Typography)(({
  theme,
}) => `
  margin: 0;
  padding: ${theme.spacing(0, 2)};
  color: ${theme.palette.primary.main};
`);

const StyledListItemText = styled(ListItemText)(({
  theme,
}) => `
  margin: 0;
  padding: ${theme.spacing(0, 1)};
  `);

const StyledCheckbox = styled(Checkbox)(({
  theme,
}) => `
  margin: 0;
  padding: ${theme.spacing(0, 1, 0, 0)};
  `);

const MessageRecipientsEditor: PropertyEditorFunction<MessageRecipientInput, MessageRecipientsPropertyEditor> = ({
  data,
  propertyName,
  propertyConfig: {
    displayName,
    defaultValue,
    options,
  },
  onSave,
}) => {
  const [value, setValue,] = useState(data ?? defaultValue);

  const {
    t,
  } = useTranslation();

  useEffect(() => {
    setValue((value) => value !== data ? data : value);
  }, [data,]);

  const [anchorEl, setAnchorEl,] = useState<null | HTMLElement>(null);

  const handlePopup = (event: React.MouseEvent<HTMLElement>) => {
    setPreventMessagingClickAway(true);

    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const theme = useTheme();

  const closePopUp = () => {
    setAnchorEl(null);
    setPreventMessagingClickAway(false);
  };

  const handleRecipientClick = (recipientId: string) => () => {
    const recipient = options?.recipientsList?.find((recipient) => recipient.id === recipientId);
    if (!recipient) {
      return;
    }
    if (value.type === MessageRecipientsOptionType.GROUP) {
      setValue({
        type: MessageRecipientsOptionType.PARTICIPANTS,
        selectedRecipients: [recipient,],
      });
      return;
    }
    const selectedRecipientIds = value.selectedRecipients ?? [];

    const index = selectedRecipientIds.findIndex(
      (selectedRecipient) => selectedRecipient.id === recipientId
    );
    if (index > -1) {
      selectedRecipientIds.splice(index, 1);
    } else {
      selectedRecipientIds.push(recipient);
    }
    const newValue = {
      type: MessageRecipientsOptionType.PARTICIPANTS,
      selectedRecipients: selectedRecipientIds,
    };
    setValue(newValue);
    if (newValue !== data && onSave) {
      onSave(propertyName, newValue);
    }
  };

  const handleGroupClick = useCallback((groupId: string) => () => {
    const group = options?.groupsList?.find((group) => group.id === groupId);
    if (!group) {
      return;
    }
    setValue({
      type: MessageRecipientsOptionType.GROUP,
      group,
    });
    if (data?.type !== MessageRecipientsOptionType.GROUP && onSave) {
      onSave(propertyName, {
        type: MessageRecipientsOptionType.GROUP,
        group,
      });
    }
    closePopUp();
  }, [data, onSave, propertyName, options?.groupsList,]);

  return <>
    <FormControl fullWidth>
      <RecipientsContainer>
        {
          options.recipientsType === MessageRecipientsType.FILTER && value?.type === MessageRecipientsOptionType.PARTICIPANTS ?

            <>
              <StyledRecipientsText>{value?.selectedRecipients?.length} {t('recipients')}</StyledRecipientsText>
              <StyledFiltersAppliedText>{t('Filters applied')}</StyledFiltersAppliedText>
            </> :
            <PropertyEditorControl>

              <Popper
                open={open}
                id={id}
                anchorEl={anchorEl}
                style={{
                  zIndex: 20000,
                  backgroundColor: theme.palette.background.default,
                }}
                placement='bottom'
              >
                <ClickAwayListener onClickAway={closePopUp}
                >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                  }}>
                    <ListSubheader>{t('Groups')}</ListSubheader>
                    {options?.groupsList?.map((group) =>
                      <MenuItem
                        key={group.id}
                        selected={value?.type === MessageRecipientsOptionType.GROUP && group.id === value?.group?.id}
                        value={group.id}
                        onClick={handleGroupClick(group.id)}
                      >
                        {group.name}
                      </MenuItem>
                    )}
                    <ListSubheader>{t('Participants')}</ListSubheader>
                    {options?.recipientsList?.map((recipient) =>
                      <MenuItem
                        key={recipient.id}
                        onClick={handleRecipientClick(recipient.id)}
                        selected={
                          value?.type === MessageRecipientsOptionType.PARTICIPANTS &&
                          (value?.selectedRecipients?.filter((selectedRecipient) => selectedRecipient?.id === recipient?.id)?.length ?? 0) > 0
                        }
                        value={recipient.id}>
                        <StyledCheckbox checked={
                          value?.type === MessageRecipientsOptionType.PARTICIPANTS &&
                          (value?.selectedRecipients?.filter((selectedRecipient) => selectedRecipient?.id === recipient?.id)?.length ?? 0) > 0
                        } />
                        <StyledAvatar src={recipient.avatar} />
                        <StyledListItemText>@{recipient.nick}</StyledListItemText>
                      </MenuItem>
                    )}
                  </div>
                </ClickAwayListener>
              </Popper>
              <div onClick={handlePopup}>
                <FieldSet >
                  <Legend>{displayName}</Legend>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100',
                      position: 'relative',
                    }}
                  >
                    <span
                      style={{
                        overflow: 'hidden',
                      }}>
                      {
                        // eslint-disable-next-line max-len
                        value?.type === MessageRecipientsOptionType.GROUP ? [value?.group?.name,] : value?.selectedRecipients?.map((recipient) => recipient.nick)?.join(', ')
                      }
                    </span>
                    <ArrowDropDownIcon />
                  </div>
                </FieldSet>
              </div>

            </PropertyEditorControl>
        }
      </RecipientsContainer>
    </FormControl>
  </>;
};

MessageRecipientsEditor.config = {
  type: 'message-recipients',
};

export default MessageRecipientsEditor;
