import config from 'config';

/**
 * Get the proper variant display name to show in the UI
 * @return {string} variant display name
 */
export const getVariantDisplayName = (): string => {
  // TODO: Variant specific config that will be set on pipeline
  switch (config.environment.variant) {
    case 'xdelphi':
      return 'xDelphi';

    case 'aavistus':
    default:
      return 'Aavistus';
  }
};
