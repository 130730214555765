import type { InternalBounds } from '../CategoryContainerModel';

interface ItemInfo {
  id: string;
  bounds: InternalBounds;
}

interface RowMinMax {
  min: number;
  max: number;
}

/**
 * The algorithm will first remove the dragged rectangle from the list of rectangles. Then
 * it will list all rectangles to rows based on their y position. Rectangles will maintain
 * their current natural order in the row presentation. After this rectangles will
 * be rearranged so that the dragged rectangle will be positioned to the best fit position
 * based on its center coordinates. All rectangles after the drag position will be positioned
 * after the rectangle and if needed, moved to lower rows. The algorithm will return a list of
 * rectangles sorted to original rows.
 *
 * @param {InternalBounds} viewPort
 * @param {ItemInfo[]} rectangles
 * @param {ItemInfo} draggedItem
 * @return {ItemInfo[]}  Sorted rectangles
 */
const sortRectanglesBasedOnDraggedRectanglePosition = (
  viewPort: InternalBounds,
  rectangles: ItemInfo[],
  draggedItem: ItemInfo
): ItemInfo[] => {
  // Filter out the dragged items
  const items = rectangles.filter((item) => item.id !== draggedItem.id);

  // Group items to rows based on their y position
  const {
    rows, rowDimensions,
  } = mapItemsToRows(items);

  const bestFitRowIndex = resolveBestFittingRow(draggedItem, rowDimensions);

  const dragTargetRow = rows[bestFitRowIndex].slice();

  dragTargetRow.push(draggedItem);
  // Sort the items in a row where the drag item is positioned
  const sortedRow = dragTargetRow.sort((a, b) => {
    return a.bounds.centerX - b.bounds.centerX;
  });

  rows[bestFitRowIndex] = sortedRow;

  return layoutToRows(rows, viewPort, rowDimensions);
};

const mapItemsToRows = (
  items: ItemInfo[]
) => {
  const rowMap = items.reduce((acc, item) => {
    const rowIndex = item.bounds.y;
    const row = acc[rowIndex] ?? [];
    row.push(item);

    return {
      ...acc,
      [rowIndex]: row,
    };
  }, {} as {
    [key: number]: ItemInfo[];
  });

  const rowDimensions: RowMinMax[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.keys(rowMap).forEach((key: any) => {
    const row = rowMap[key];

    const min = Math.min(...row.map((item) => item.bounds.y));
    const max = Math.max(...row.map((item) => item.bounds.y + item.bounds.height));

    rowDimensions.push({
      min,
      max,
    });
  });

  const rows: ItemInfo[][] = [];

  Object.values(rowMap).forEach((row) => {
    rows.push(row);
  });

  return {
    rows,
    rowDimensions,
  };
};

const resolveBestFittingRow = (
  draggedItem: ItemInfo,
  rowDimensions: RowMinMax[]
) => {
  const {
    centerY,
  } = draggedItem.bounds;

  // Find the best fit row for the dragged item
  let bestFitRowIndex = -1;

  for (let i = 0; i < rowDimensions.length; i++) {
    const rowDimension = rowDimensions[i];

    if (rowDimension.max > centerY) {
      bestFitRowIndex = i;
      break;
    }
  }

  const firstRowMin = rowDimensions[0]?.min ?? 0;
  const lastRowMax = rowDimensions[Object.keys(rowDimensions).length - 1]?.max ?? 0;

  if (bestFitRowIndex === -1) {
    if (centerY < firstRowMin) {
      bestFitRowIndex = 0;
    }
    if (centerY > lastRowMax) {
      bestFitRowIndex = Object.keys(rowDimensions).length - 1;
    }
  }
  return bestFitRowIndex;
};

// Wrap items from left to right, top to bottom on rows so that all items will fit in
// the view port in width direction and return reduce rows to single array.
const layoutToRows = (
  rows: ItemInfo[][],
  viewPort: InternalBounds,
  rowDimensions: RowMinMax[]
) => {
  const sortedItems: ItemInfo[] = [];

  let currentRow = 0;
  let currentRowWidth = 0;

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];

    for (const item of row) {
      const {
        width,
      } = item.bounds;

      if (currentRowWidth + width > viewPort.width) {
        currentRow++;
        currentRowWidth = 0;
      }

      const dimensions = rowDimensions[currentRow] ?? {
        min: currentRow,
        max: currentRow + 200,
      };
      const height = dimensions.max - dimensions.min;

      // Item dimensions will be mapped to the row dimensions based on the
      // projected dragging position.
      sortedItems.push({
        ...item,
        bounds: {
          ...item.bounds,
          x: currentRowWidth,
          left: currentRowWidth,
          y: dimensions.min,
          top: dimensions.min,
          bottom: dimensions.min + height,
          height,
          right: currentRowWidth + width,
          centerX: currentRowWidth + width / 2,
          centerY: dimensions.min + height / 2,
        },
      });

      currentRowWidth += width;
    }
  }
  return sortedItems;
};

export default sortRectanglesBasedOnDraggedRectanglePosition;
