import { Divider } from '@mui/material';
import { InfoItem, MessageArea, StyleFeedItem, TopRow } from '../NotificationsFeed';
import { StyledAvatar } from '../../comments/CommentItem';
import { StyledLink } from 'components/layout/Breadcrumbs';
import { extractDateToMilliseconds, renderMoment } from '../../../utils/timeUtils';
import { limitText } from 'utils/common';
import { resolveEditorMode } from '../../../utils/urls';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import React from 'react';
import ReadMore from '../../common/ReadMore';
import elementCache from 'utils/ElementCache';
import type { FeedItem } from '../../../@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

interface ItemProps {
  item: WithId<FeedItem>;
}
export const Item = ({
  item,
}: ItemProps) => {
  const {
    query,
  } = useRouter();

  const mode = resolveEditorMode(query);

  const [sessionName, setSessionName,] = useState<string>();
  const [canvasName, setCanvasName,] = useState<string>();

  useEffect(
    () => {
      const fetch = async () => {
        // limitText(getLocalCacheItem('sessions', `${item.sessionId}`, item.sessionId as string), 20, '', '...')

        if (!item.spaceId || !item.projectId || !item.sessionId) {
          return 'not known';
        }

        const data = await elementCache.get({
          keyPath: {
            spaceId: item.spaceId,
            projectId: item.projectId,
            sessionId: item.sessionId,
          },
          defaultValue: {
            name: '...loading',
          },
        });
        setSessionName(limitText(data?.name, 20, '', '...'));
      };
      fetch();
    },
    [item.projectId, item.sessionId, item.spaceId,]);

  useEffect(() => {
    const fetch = async () => {
      // limitText(getLocalCacheItem('canvases', item.canvasId!, item.canvasId as string), 20, '', '...')

      if (!item.spaceId || !item.projectId || !item.sessionId || !item.canvasId) {
        return 'not known';
      }

      const data = await elementCache.get({
        keyPath: {
          spaceId: item.spaceId,
          projectId: item.projectId,
          sessionId: item.sessionId,
          canvasId: item.canvasId,
        },
        defaultValue: {
          name: '...loading',
        },
      });
      setCanvasName(limitText(data?.name, 20, '', '...'));
    };
    fetch();
  }, [item.canvasId, item.projectId, item.sessionId, item.spaceId,]);

  const link = useMemo(() => {
    let result = `/${item.path}`;
    // When facilitator is on dashboard and clicks an item from drawer,
    // we will assume that he or she wants to facilitate.
    if (mode) {
      result += `?facilitate`;
    }
    return result;
  }, [item.path, mode,]);

  if (!link) {
    return null;
  }
  return <StyleFeedItem
  >
    <StyledLink href={link}>
      <div>
        <TopRow>
          <StyledAvatar
            src={item.avatar}
            alt={item.nick} />
          <div>
            <InfoItem>@{item.nick}</InfoItem>
            <InfoItem>
              {renderMoment(extractDateToMilliseconds(item.created))}
            </InfoItem>
          </div>
        </TopRow>
        <TopRow>
        </TopRow>
        <MessageArea>
          <ReadMore text={item.message}
            length={200} />
        </MessageArea>
      </div>
    </StyledLink>
    <Divider />
    <div>
      <StyledLink href={`/${item.spaceId}/${item.projectId}/${item.sessionId}/`}>
        {sessionName}
      </StyledLink>
      <br />
      <StyledLink href={`/${item.spaceId}/${item.projectId}/${item.sessionId}/${item.canvasId}/`}>
        {canvasName}
      </StyledLink>
    </div>
  </StyleFeedItem>;
};
