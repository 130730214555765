/* eslint-disable @typescript-eslint/no-explicit-any */
import Typography from '@mui/material/Typography';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

import { type Axis2D, type RangeItem, default2DCornerColors } from '@shared/schema/src';
import { getAxisRange } from './getAxisRange';
import CompositePropertyEditorControl from '../CompositePropertyEditorControl';
import type { Axis2DPropertyEditor } from '../PropertyEditorTypes';
import type { BuilderConfigPropsFunction, PropertyConfig } from '../../builders/componentBuilder';

export const plusMinusRange: { [length: number]: RangeItem[]; } = {
  2: [
    {
      value: -1,
      label: '-',
    },
    {
      value: 1,
      label: '+',
    },
  ],

  3: [
    {
      value: -1,
      label: '-',
    },
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '+',
    },
  ],

  4: [
    {
      value: -2,
      label: '--',
    },
    {
      value: -1,
      label: '-',
    },
    {
      value: 1,
      label: '+',
    },
    {
      value: 2,
      label: '++',
    },
  ],

  5: [
    {
      value: -2,
      label: '--',
    },
    {
      value: -1,
      label: '-',
    },
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '+',
    },
    {
      value: 2,
      label: '++',
    },
  ],
  6: [
    {
      value: -3,
      label: '---',
    },
    {
      value: -2,
      label: '--',
    },
    {
      value: -1,
      label: '-',
    },
    {
      value: 1,
      label: '+',
    },
    {
      value: 2,
      label: '++',
    },
    {
      value: 3,
      label: '+++',
    },
  ],
  7: [
    {
      value: -3,
      label: '---',
    },
    {
      value: -2,
      label: '--',
    },
    {
      value: -1,
      label: '-',
    },
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '+',
    },
    {
      value: 2,
      label: '++',
    },
    {
      value: 3,
      label: '+++',
    },
  ],
  8: [
    {
      value: -4,
      label: '----',
    },
    {
      value: -3,
      label: '---',
    },
    {
      value: -2,
      label: '--',
    },
    {
      value: -1,
      label: '-',
    },
    {
      value: 1,
      label: '+',
    },
    {
      value: 2,
      label: '++',
    },
    {
      value: 3,
      label: '+++',
    },
    {
      value: 4,
      label: '++++',
    },
  ],
  9: [
    {
      value: -4,
      label: '----',
    },
    {
      value: -3,
      label: '---',
    },
    {
      value: -2,
      label: '--',
    },
    {
      value: -1,
      label: '-',
    },
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '+',
    },
    {
      value: 2,
      label: '++',
    },
    {
      value: 3,
      label: '+++',
    },
    {
      value: 4,
      label: '++++',
    },
  ],
  10: [
    {
      value: -5,
      label: '-----',
    },
    {
      value: -4,
      label: '----',
    },
    {
      value: -3,
      label: '---',
    },
    {
      value: -2,
      label: '--',
    },
    {
      value: -1,
      label: '-',
    },
    {
      value: 1,
      label: '+',
    },
    {
      value: 2,
      label: '++',
    },
    {
      value: 3,
      label: '+++',
    },
    {
      value: 4,
      label: '++++',
    },
    {
      value: 5,
      label: '+++++',
    },
  ],
};

export const percentageRange: { [length: number]: RangeItem[]; } = {

  2: [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 100,
      label: '100%',
    },
  ],
  3: [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 100,
      label: '100%',
    },
  ],

  4: [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 25,
      label: '25%',
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
  ],
  5: [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 25,
      label: '25%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
  ],
  6: [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 16.6,
      label: '16.6%',
    },
    {
      value: 33.3,
      label: '33.3%',
    },
    {
      value: 66.6,
      label: '66.6%',
    },
    {
      value: 83.3,
      label: '83.3%',
    },
    {
      value: 100,
      label: '100%',
    },
  ],

  7: [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 16.6,
      label: '16.6%',
    },
    {
      value: 33.3,
      label: '33.3%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 66.6,
      label: '66.6%',
    },
    {
      value: 83.3,
      label: '83.3%',
    },
    {
      value: 100,
      label: '100%',
    },
  ],
  8: [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 12.5,
      label: '12.5%',
    },
    {
      value: 25,
      label: '25%',
    },
    {
      value: 37.5,
      label: '37.5%',
    },
    {
      value: 62.5,
      label: '62.5%',
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 87.5,
      label: '87.5%',
    },
    {
      value: 100,
      label: '100%',
    },
  ],
  9: [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 12.5,
      label: '12.5%',
    },
    {
      value: 25,
      label: '25%',
    },
    {
      value: 37.5,
      label: '37.5%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 62.5,
      label: '62.5%',
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 87.5,
      label: '87.5%',
    },
    {
      value: 100,
      label: '100%',
    },
  ],
  10: [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 11.1,
      label: '11.1%',
    },
    {
      value: 22.2,
      label: '22.2%',
    },
    {
      value: 33.3,
      label: '33.3%',
    },
    {
      value: 44.4,
      label: '44.4%',
    },
    {
      value: 55.5,
      label: '55.5%',
    },
    {
      value: 66.6,
      label: '66.6%',
    },
    {
      value: 77.7,
      label: '77.7%',
    },
    {
      value: 88.8,
      label: '99.8%',
    },
    {
      value: 100,
      label: '100%',
    },
  ],
  11: [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 10,
      label: '10%',
    },
    {
      value: 20,
      label: '20%',
    },
    {
      value: 30,
      label: '30%',
    },
    {
      value: 40,
      label: '40%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 60,
      label: '60%',
    },
    {
      value: 70,
      label: '70%',
    },
    {
      value: 80,
      label: '80%',
    },
    {
      value: 90,
      label: '90%',
    },
    {
      value: 100,
      label: '100%',
    },
  ],
};

export const numericRange: { [length: number]: RangeItem[]; } = {

  2: [
    {
      value: -1,
      label: '-1',
    },
    {
      value: 1,
      label: '+1',
    },
  ],

  3: [
    {
      value: -1,
      label: '-1',
    },
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '+1',
    },
  ],

  4: [
    {
      value: -2,
      label: '-1',
    },
    {
      value: -1,
      label: '-1',
    },
    {
      value: 1,
      label: '+1',
    },
    {
      value: 2,
      label: '2',
    },
  ],

  5: [
    {
      value: -2,
      label: '-2',
    },
    {
      value: -1,
      label: '-1',
    },
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '+1',
    },
    {
      value: 2,
      label: '+2',
    },
  ],
  6: [
    {
      value: -3,
      label: '-3',
    },
    {
      value: -2,
      label: '-2',
    },
    {
      value: -1,
      label: '-1',
    },
    {
      value: 1,
      label: '+1',
    },
    {
      value: 2,
      label: '+2',
    },
    {
      value: 3,
      label: '+3',
    },
  ],

  7: [
    {
      value: -3,
      label: '-3',
    },
    {
      value: -2,
      label: '-2',
    },
    {
      value: -1,
      label: '-1',
    },
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '+1',
    },
    {
      value: 2,
      label: '+2',
    },
    {
      value: 3,
      label: '+3',
    },
  ],
  8: [
    {
      value: -4,
      label: '-4',
    },
    {
      value: -3,
      label: '-3',
    },
    {
      value: -2,
      label: '-2',
    },
    {
      value: -1,
      label: '-1',
    },
    {
      value: 1,
      label: '+1',
    },
    {
      value: 2,
      label: '+2',
    },
    {
      value: 3,
      label: '+3',
    },
    {
      value: 4,
      label: '+4',
    },
  ],

  9: [
    {
      value: -4,
      label: '-4',
    },
    {
      value: -3,
      label: '-3',
    },
    {
      value: -2,
      label: '-2',
    },
    {
      value: -1,
      label: '-1',
    },
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '+1',
    },
    {
      value: 2,
      label: '+2',
    },
    {
      value: 3,
      label: '+3',
    },
    {
      value: 4,
      label: '+4',
    },
  ],

  10: [
    {
      value: -5,
      label: '-5',
    },
    {
      value: -4,
      label: '-4',
    },
    {
      value: -3,
      label: '-3',
    },
    {
      value: -2,
      label: '-2',
    },
    {
      value: -1,
      label: '-1',
    },
    {
      value: 1,
      label: '+1',
    },
    {
      value: 2,
      label: '+2',
    },
    {
      value: 3,
      label: '+3',
    },
    {
      value: 4,
      label: '+4',
    },
    {
      value: 5,
      label: '+5',
    },

  ],

  11: [
    {
      value: -5,
      label: '-5',
    },
    {
      value: -4,
      label: '-4',
    },
    {
      value: -3,
      label: '-3',
    },
    {
      value: -2,
      label: '-2',
    },
    {
      value: -1,
      label: '-1',
    },
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '+1',
    },
    {
      value: 2,
      label: '+2',
    },
    {
      value: 3,
      label: '+3',
    },
    {
      value: 4,
      label: '+4',
    },
    {
      value: 5,
      label: '+5',
    },

  ],

};

interface Axis2DVirtual {
  scaleLength: number; // TODO Evaluate, is it so bad if this will be saved to Firestore
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const axis2DConfig: BuilderConfigPropsFunction<any> = (component) => {
  // TODO Exact typing and support for axis in different contexes
  const mode = component?.mode;
  const configIndependentAxes = {
    x: {
      type: 'axis',
      displayName: 'X-Axis',
      order: 130,
    },
    y: {
      type: 'axis',
      displayName: 'Y-Axis',
      order: 150,
    },
  } as Partial<PropertyConfig<Axis2D & Axis2DVirtual>>;

  const configMirroredAxes = {
    x: {
      type: 'axis',
      // section: 'Labels',
      displayName: 'Axes',
      order: 130,
    },
  } as Partial<PropertyConfig<Axis2D & Axis2DVirtual>>;

  const colorPicker = {
    colors: {
      type: 'corner-color-picker',
      displayName: 'Corner colors',
      defaultValue: default2DCornerColors,
      mode: component?.interaction === 'sliders' ? 'slider' : '2d',
    },
  };

  switch (mode) {
    case 'discrete':
      return {
        ...component.tickLabelsPresentation === 'single' ?
          configIndependentAxes : configMirroredAxes,

        scaleLength: {
          type: 'picker',
          displayName: 'Number of intervals',
          defaultValue: 7,
          options: valueRange(2, 10),
          order: 120,

          onPropertyChange: ({
            data,
            propertyValue,
            component,
            updateProperty,
          }: any) => {
            // TODO Validation

            const axis = data as Axis2D;

            const rangeX = getAxisRange({
              scaleLength: propertyValue,
              currentRange: data.x.range,
              scaleType: axis.x.scaleType,
              mode: component.mode,
              max: component.axis.x.max,
            });

            if (rangeX) {
              updateProperty('x.range', rangeX);
              updateProperty('y.range', rangeX);
            }
          },
        },
        ...colorPicker,
      };
    case 'continuous':
      return {
        ...component.tickLabelsPresentation === 'single' ?
          configIndependentAxes : configMirroredAxes,

        scaleLength: {
          type: 'picker',
          displayName: 'Tick steps',
          defaultValue: 2,
          options: valueRange(2, 10),

          order: 11,

          onPropertyChange: ({
            data,
            propertyValue,
            updateProperty,
          }: any) => {
            const axis = data as Axis2D;

            const rangeX = getAxisRange({
              scaleLength: propertyValue,
              currentRange: data.range,
              scaleType: axis.x.scaleType,
              mode: component.mode,
              max: component.axis.x.max,
            });

            if (rangeX) {
              updateProperty('x.range', rangeX);
              updateProperty('y.range', rangeX);
            }
          },
        },
        // ...component.axis?.scaleType === 'numeric' && {
        //   max: {
        //     type: 'number',
        //     displayName: 'Maximum value',
        //     defaultValue: -3,
        //     order: 12,
        //   },
        // },
        ...colorPicker,
      };

    default:
      throw new Error(`Unexpected Canvas mode: ${component.mode}`);
  }
};

const valueRange = (from: number, to: number): RangeItem[] => {
  const range = [];
  for (let i = from; i <= to; i++) {
    range.push({
      value: i,
      label: `${i}`,
    });
  }
  return range;
};

const Axis2DEditor: PropertyEditorFunction<Axis2D, Axis2DPropertyEditor> = ({
  data,
  component,
  propertyName,
  propertyNamePath,
  propertyConfig: {
    defaultValue,
    displayName,
  },
  onDelete,
  onSave,
}) => {
  const id = `editor-${propertyName}`;
  return <>
    <Typography component={'span'}
      variant="h4"
      id={`${id}-label`}>
      {displayName}
    </Typography>

    <CompositePropertyEditorControl
      component={component}
      propertyName={propertyName}
      propertyNamePath={propertyNamePath}
      data={data ?? defaultValue}
      config={axis2DConfig}
      onSave={onSave}
      onDelete={onDelete}
    />
  </>;
};

Axis2DEditor.config = {
  type: 'axis-2d',
  displayName: 'Axis',
};

export default Axis2DEditor;
