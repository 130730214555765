import { createContext, useContext, useRef } from 'react';
import { resolveFieldPaths } from 'components/builder/widgets/resolveFieldPaths';
import type { PropsWithChildren } from 'react';

export interface PropertyFieldPathType {
  propertyFieldPath: string;
}

const defaultValue = {
  propertyFieldPath: '',
};
export const PropertyFieldPathContext = createContext<PropertyFieldPathType>(defaultValue);

const usePropertyFieldPath = () => {
  return useContext(PropertyFieldPathContext);
};

interface PropertyFieldPathProviderProps {
  propertyFieldPath?: string;
}

/**
 * Provider for property field path for nested components. This can
 * be used to provide a path to the property field for nested components.
 *
 * @param {PropertyFieldPathProviderProps} props  The props
 */
export const PropertyFieldPathProvider = ({
  children,
  propertyFieldPath = '',
}: PropsWithChildren<PropertyFieldPathProviderProps>) => {
  const current = usePropertyFieldPath();

  const ref = useRef({
    propertyFieldPath: resolveFieldPaths(current.propertyFieldPath, propertyFieldPath),
  });

  return <PropertyFieldPathContext.Provider value={ref.current}>
    {children}
  </PropertyFieldPathContext.Provider>;
};

export default usePropertyFieldPath;
