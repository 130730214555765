import { MediaCopyright } from '.';
import React from 'react';
import config from 'config';
import styled from '@emotion/styled';
import type { Media } from '@shared/schema/src';
import type { UnsplashPhotoResponse } from '@shared/schema/src/https/media/unsplashTypes';

export const Link = styled.a`
  text-decoration: none;
  font-weight: bold;
  color: white;
`;

interface UnsplashCopyrightProps {
  item: UnsplashPhotoResponse;
}
export const UnsplashCopyright = ({
  item,
}: UnsplashCopyrightProps) => {
  return <MediaCopyright media={extractMediaAuthorInfoFromUnsplash(item)} />;
};

export const extractMediaAuthorInfoFromUnsplash = (item: UnsplashPhotoResponse): Partial<Media> => ({
  type: 'photo',
  author: item.user.name,
  authorUrl: `https://unsplash.com/@${item?.user?.username}?utm_source=${config.environment.variant}&utm_medium=referral`,
  source: 'unsplash',
  sourceUrl: `https://unsplash.com/?utm_source=${config.environment.variant}&utm_medium=referral`,
});
