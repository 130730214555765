import BackgroundFadeTop from '../../../components/styling/BackgroundFadeTop';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import styled$ from '../../../utils/react/styled$';
import theme from '../../../theme';
import type { LinkInfo } from '../../../@shared/schema/src';

const StyledLinkPreview = styled$.div<{
  $isMobile?: boolean;
}>(({
  theme,
  $isMobile,
}) => `
  position: relative;
  box-shadow: 2px 1px 5px #0000004D;
  margin-top: ${theme.spacing(2)};
  background-color: ${theme.palette.background.innerPaper};
  border-radius: ${$isMobile ? 2 : theme.shape.borderRadius}px;
  padding: ${theme.spacing($isMobile ? 0 : 2)};
  ${$isMobile && `
    margin-left: ${theme.spacing(-2)};
    margin-right: ${theme.spacing(-2)};
  `}
`);

const RemovePreview = styled(HighlightOffIcon)(({
  theme,
}) => `
  position: absolute;
  left: -5px;
  top: -5px;
  z-index:5;
  background-color: ${theme.palette.background.paper};
  border-radius: 50%;
  font-size: 1.6rem;
  cursor: pointer;
  &:hover {
   transform: scale(1.1);
  }
`);

const LinkPreviewTitle = styled(Typography)(({
  theme,
}) => `
  position: absolute;
  padding: ${theme.spacing(2)};
`);

const StyledLinkImage = styled$.img<{
  $isMobile?: boolean;
}>(({
  theme,
  $isMobile,
}) => `
  position: absolute;
  border-radius: ${$isMobile ? 0 : theme.shape.borderRadius}px;
  width: 100%;
  height: 300px;
  object-fit: cover;
`);

const StyledLink = styled.a(({
  theme,
}) => `
  text-decoration: none;
  color: ${theme.palette.text.primary};
  &hover: {
    transform: scale(1.05);
  }
`);

const Excerpt = styled$(Typography)<{
  $isMobile?: boolean;
}>(({
  theme,
  $isMobile,
}) => `
  padding: ${$isMobile ? theme.spacing(0, 2, 1, 2) : 0};
`);

const PublisherInfo = styled.div(({
  theme,
}) => `
  position: absolute; 
  bottom: ${theme.spacing(1)};
  left: ${theme.spacing(1)};
`);

interface LinkPreviewProps {
  info: LinkInfo;
  isMobile?: boolean;
  permissionToRemove?: boolean;
  onRemoveLinkClick: () => void;
}
export const LinkPreview = ({
  info,
  permissionToRemove,
  isMobile,
  onRemoveLinkClick,
}: LinkPreviewProps) => {
  if (!info) {
    return null;
  }

  return <StyledLinkPreview $isMobile={isMobile}>
    {permissionToRemove && <RemovePreview onClick={onRemoveLinkClick} />}
    <StyledLink href={info.url}
      target="_blank"
      rel="noreferrer">
      <div style={{
        position: 'relative',
        height: 300,
        margin: 0,
        padding: 0,
        borderRadius: isMobile ? 0 : `${theme.shape.borderRadius}px`,
        overflow: 'hidden',
      }}>
        <StyledLinkImage $isMobile={isMobile}
          src={info.photoUrl} />
        <BackgroundFadeTop />
        <LinkPreviewTitle variant="h5">{info.title}</LinkPreviewTitle>
        {(info.publisher || info.author) &&
          <PublisherInfo>
            {info.publisher}{info.publisher && info.author && ' | '}{info.author}
          </PublisherInfo>}
      </div>
      <Excerpt $isMobile={isMobile}
        variant="body1">{info.excerpt}</Excerpt>
    </StyledLink>
  </StyledLinkPreview>;
};
