import { createUseBlocHook } from '@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import { updateSession } from './sessionApi';
import { validateSessionProps } from '@shared/schema/src';
import BlocDocument from '@mindhiveoy/bloc/BlocDocument';
import elementCache from 'utils/ElementCache';
import type { BlocErrorFunction } from '@mindhiveoy/bloc/types';
import type { Session, SessionParams } from '@shared/schema/src';

/**
 *
 */
export class SessionBloc extends BlocDocument<Session, SessionParams> {
  /**
   * @param {SessionParams}   params
   * @param {ErrorFunction} onError Error listener.
   */
  constructor(
    params: SessionParams,
    onError?: BlocErrorFunction
  ) {
    let invalidState = false;
    try {
      validateSessionProps(params, 'sessionId');
    } catch (e) {
      // Fail gracefully
      invalidState = true;
    }
    const {
      projectId,
      sessionId,
      spaceId,
    } = params;

    super({
      documentPath: invalidState ? '' :
        `spaces/${spaceId
        }/projects/${projectId
        }/sessions/${sessionId}`,
      params,
      invalidState,
      onError,
    });

    this.registerUpdateListener((data) => {
      if (!data) {
        return;
      }
      elementCache.set({
        keyPath: {
          spaceId,
          projectId,
          sessionId,
        },
        data,
      });
    });
  }
  /**
   * Update a query.
   *
   * @param {Session}     session        Session base data
   * @return {CreateSessionResponse} A data object containing the id of the created project and project's initial data.
   */
  public update = async (
    session: Partial<Session>
  ) => {
    return this._set(
      async () => {
        return await updateSession({
          spaceId: this.params.spaceId,
          projectId: this.params.projectId,
          sessionId: this.params.sessionId,
          session: session as Session,
        });
      },
      this.onError,
      (data) => data && {
        ...data,
        ...session,
        _id: this.docId, // TODO: Make some shortcut for default optimized function
      }
    );
  };

  public setLiveCanvas = (activeCanvasId: string) => {
    return this.update({
      activeCanvasId,
    });
  };
}

export const useSession = createUseBlocHook<Session, SessionParams, SessionBloc>(
  ({
    params,
    onError,
  }) => new SessionBloc(params, onError)
);
