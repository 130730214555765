import cloneDeep from 'lodash/cloneDeep';
import type { Canvas, DraftCanvasDocument } from '@shared/schema/src';
import type { Canvases } from '..';
import type { WithId } from '@mindhiveoy/schema';

/**
 * Merge draft and actual documents to a canvas map. The draft documents will override
 * the actual ones. Depending on the status of the draft documents, the canvases may
 * be created or deleted also.
 *
 * @param canvases
 * @param canvasDrafts
 */
export const mergeWithDrafts = (
  canvases: Record<string, WithId<Canvas>>,
  canvasDrafts: Record<string, DraftCanvasDocument>
): Canvases => {
  const results: Canvases = cloneDeep({
    ...canvases,
  });

  Object
    .entries(canvasDrafts)
    .forEach(([canvasId, draft,]: any) => {
      switch (draft.status) {
        case 'created':
        case 'modified':

          if (!results[canvasId]) {
            results[canvasId] = {
              ...draft.content,
              _draft: true,
            };
            return;
          }
          results[canvasId] = draft.content;
          delete (results[canvasId] as any)._draft;
          return;

        case 'deleted':
          delete results[canvasId];
      }
    });

  return results;
};
