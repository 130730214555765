import React, { useRef } from 'react';

interface TemplateContextType {
  canvasElement: JSX.Element;
}

export const TemplateContext = React.createContext<TemplateContextType | null>(null);

interface TemplateContextProviderProps {
  canvasElement: JSX.Element;
}

export const TemplateContextProvider = ({
  children,
  canvasElement,
}: React.PropsWithChildren<TemplateContextProviderProps>) => {
  const value = useRef({
    canvasElement,
  });

  if (value.current.canvasElement !== canvasElement) {
    value.current = {
      canvasElement,
    };
  }

  return <TemplateContext.Provider value={value.current}>
    {children}
  </TemplateContext.Provider>;
};

export const useTemplateContext = () => {
  return React.useContext(TemplateContext);
};
