import { answerValueToIndexY } from '../../../../canvasTypes/Matrix2DQuestionCanvas/answer2DUtils';

export const answerY = ({
  canvasMode, axis, y, minY, scaleY,
}: any) => {
  switch (canvasMode) {
    case 'discrete':
      return axis.y.range.length - 1 - answerValueToIndexY(axis, y);

    case 'continuous':
      return scaleY - y + minY;
  }
};
