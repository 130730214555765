import isEqual from 'lodash/isEqual';
import type { AuthContext } from '..';
import type { User } from '@shared/schema/src';

export const hasUserRoleChanged = (context: AuthContext, user: User<any>): boolean => {
  const oldUserInfo = context.user;
  if (!oldUserInfo) {
    return !!user;
  }
  return !(
    isEqual(
      oldUserInfo.memberships, user.memberships) &&
    oldUserInfo.role === user.role &&
    oldUserInfo.plan === user.plan &&
    oldUserInfo.ownedSpaces === user.ownedSpaces &&
    oldUserInfo.email === user.email
  );
};
