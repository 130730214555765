/* eslint-disable @typescript-eslint/no-explicit-any */
import { StyledSvg } from './Matrix2DInput';
import { XAxis } from './XAxis';
import { YAxis } from './YAxis';
import { useMemo } from 'react';
import React from 'react';
import type { PropsWithChildren } from 'react';

interface Graph2DCanvasProps {
  graphBackgroundColor?: string;
  rowCount: number;
  columnCount: number;
  mode?: 'continuous' | 'discrete';
  showGrid?: boolean;
}

/**
 * The svg canvas element for the graph with background and axes
 * @param {Graph2DCanvasProps} props  The props
 * @return {JSX.Element} The svg element
 */
const Graph2DCanvas = React.forwardRef(({
  children,
  graphBackgroundColor = '#C4C4C4',
  rowCount,
  columnCount,
  mode = 'discrete',
  showGrid = true,
}: PropsWithChildren<Graph2DCanvasProps>, ref) => {
  return <StyledSvg
    ref={ref as any}
    width="100%"
    height="100%"
    viewBox="0 0 1000 1000"
    fill="none"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0"
      y="0"
      width="1000"
      height="1000"
      fill={graphBackgroundColor}
    />
    {showGrid && <Axis2DHelperLiners
      mode={mode}
      rowCount={rowCount}
      columnCount={columnCount}
    />}

    {children}
    <XAxis />
    <YAxis />
  </StyledSvg>;
});

Graph2DCanvas.displayName = 'Graph2DCanvas';

interface Axis2DHelperLinersProps {
  rowCount?: number;
  columnCount?: number;
  color?: string;
  mode?: 'continuous' | 'discrete';
}
const Axis2DHelperLiners = ({
  rowCount = 1,
  columnCount = 1,
  color = 'rgba(160,160,160,0.5)',
}: Axis2DHelperLinersProps) => {
  const grid: JSX.Element[] = useMemo(() => {
    const result: JSX.Element[] = [];

    for (let y = 0; y < rowCount; y++) {
      result.push(<line
        key={`r_${y}`}
        x1={0}
        y1={y * 1000 / rowCount}
        x2={1000}
        y2={y * 1000 / rowCount}
        stroke={color}
        strokeWidth={3} />);
    }
    for (let x = 0; x < columnCount; x++) {
      result.push(<line
        key={`c_${x}`}
        y1={0}
        x1={x * 1000 / columnCount}
        y2={1000}
        x2={x * 1000 / columnCount}
        stroke={color}
        strokeWidth={3} />);
    }
    return result;
  }, [rowCount, columnCount, color,]);

  return <>{grid}</>;
};

export default Graph2DCanvas;
