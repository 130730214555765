import { aggregateStats } from 'utils/aggregateStats';
import { motion } from 'framer-motion';
import { scaleToRange } from './scaleToRange';
import { useMemo } from 'react';
import type { AnalyzeOptions, Axis2D, Statistics2D } from '@shared/schema/src';

interface CharacteristicPlotProps {
  analyzeOptions: AnalyzeOptions;
  statistics: Statistics2D;
  axis: Axis2D;
}
export const CharacteristicPlot = ({
  analyzeOptions = {
    stats: {
      average: true,
      mean: true,
      q1: true,
      median: true,
      q3: true,
      // stdDeviation: true,
      // variance: true,
    },
  }, statistics, axis,
}: CharacteristicPlotProps) => {
  const stats = useMemo(() => {
    if (!analyzeOptions?.stats || !statistics) {
      return null;
    }
    // Skip standard deviation and variance as they are not used in the graph
    return aggregateStats({
      data: statistics,
      characteristics: analyzeOptions.stats,
    }, ['stdDeviation', 'variance',]);
  }, [analyzeOptions?.stats, statistics,]);

  return <>
    {/* <span>{JSON.stringify(!!statistics)}</span> */}
    {stats?.map((stat, index) => {
      const cx = scaleToRange(stat.value.x, axis);
      const cy = 1000 - scaleToRange(stat.value.y, axis);

      const valid = !(isNaN(cx) || isNaN(cy));

      const inactiveCircleStyle = {
        cx,
        cy,
        opacity: 0,
        scale: 0,
      };

      const activeCircleStyle = {
        cx,
        cy,
        opacity: 1,
        scale: 1,
      };

      if (!valid) {
        return null;
      }
      return <g key={index}>
        <motion.circle
          layoutId={stat.label}
          initial={inactiveCircleStyle}
          animate={activeCircleStyle}
          exit={inactiveCircleStyle}
          fill={stat.color}
          r={16} />
        <text
          x={cx}
          y={cy + 40}
          fill='black'
          fontSize={24}
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {stat.label}
        </text>
      </g>;
    })}</>;
};
