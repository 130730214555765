import { generatePathUrl } from 'utils/urls';
import type { Canvas, Comment, PathParams } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

// remove all HTML tags from a string and return only the first three words followed by ...
export const minimizeComment = (comment: string) => {
  const commentWithoutTags = cleanTagsFromHtmlString(comment);
  const words = commentWithoutTags.split(' ');
  if (words.length > 4) {
    return `${words.slice(0, 4).join(' ')}...`;
  }
  return commentWithoutTags;
};

export const cleanTagsFromHtmlString = (html: string) => {
  return html.replace(/(<([^>]+)>)/gi, '');
};

export const generateUrlToComment = (pathParams: PathParams, comment?: WithId<Comment>) => {
  const path = generatePathUrl(pathParams, 'canvasId');
  const commentPath = `${comment?.parentPath ?? ''}/${comment?._id ?? ''}`;
  return `${path}${commentPath}`;
};

export const generateUrlToCanvasOrSession = (pathParams: PathParams, canvas?: WithId<Canvas>) => {
  const path = generatePathUrl(pathParams, 'sessionId');
  return `${path}/${canvas?._id ?? ''}`;
};
