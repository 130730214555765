/* eslint-disable require-jsdoc */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthError } from '@mindhiveoy/auth';
import { SignInScreen } from 'components/auth/SignIn/SignInScreen';
import { useCallback, useContext } from 'react';
import ErrorBoundary from 'components/common/ErrorBoundary';
import MediaContainer from 'components/layout/MediaContainer';
import React, { useRef } from 'react';
import ScreenProvider from 'components/layout/ScreenProvider';
import styled$ from 'utils/react/styled$';
import type { CSSProperties } from 'react';
import type { Media } from '../../@shared/schema/src/common';

const AppContainer = styled$.div<{
  $noScroll?: boolean;
}>(({
  $noScroll,
  theme,
}) => `
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0;
  background-color: ${theme.palette.background.default};
  height: 100dvh;
  min-height: 100dvh;
  max-height: 100dvh;
  overflow-x: hidden;
  overflow-y: ${$noScroll ? 'hidden' : 'auto'};
  position: relative;
  scroll-behavior: smooth;
`);

const ScrollAreaRefContext = React.createContext<React.MutableRefObject<HTMLDivElement | null> | null>(null);

export const useScrollAreaRef = () => {
  return useContext(ScrollAreaRefContext);
};

export interface AppProps {
  /**
   * Fade more the background image at the top
   */
  fadeExtended?: boolean;
  /**
   * Disable scrolling for the screen
   */
  noScroll?: boolean;
  media?: Media;
  blurBackground?: boolean;
  noBackground?: boolean;
}

const backgroundMediaStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

/**
 * Component container for the application. This component will render the background media and
 * the children components. Wrap each NextJs page with this component to ensure the correct layout.
 *
 * The component will also handle the error boundary for the application and introduce the
 * `ScreenProvider` to manage the screen scroll position and drawer states.
 *
 * @param {AppProps} props  The component props
 */
const App = ({
  blurBackground,
  noScroll,
  children,
  media,
  noBackground,
}: React.PropsWithChildren<AppProps>) => {
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);

  const handleError = useCallback((error: Error, renderDefault: any) => {
    if (error instanceof AuthError && error.errorCode === 'auth_permission_denied') {
      return <SignInScreen />;
    }
    return renderDefault();
  }, []);

  return <AppContainer ref={scrollAreaRef}
    $noScroll={noScroll}>
    <ScrollAreaRefContext.Provider value={scrollAreaRef}>
      {!noBackground ?
        <MediaContainer
          fullScreen
          media={media}
          style={backgroundMediaStyle}
          noRadius
          blur={blurBackground} /> : null}
      <ErrorBoundary name="app"
        onError={handleError}>
        <ScreenProvider>
          {children}
        </ScreenProvider>
      </ErrorBoundary>
    </ScrollAreaRefContext.Provider>
  </AppContainer>;
};

export default App;
