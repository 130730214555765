import { ActionButtons } from '../PinnedMemberEditor/ActionButtons';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import Cropper from 'react-cropper';
import resizeImageDataUrl from './resizeImageDataUrl';
import type { ReactCropperElement } from 'react-cropper';

export interface ImageCropperProps {
  src?: string;
  cropData?: Cropper.Data;
  onCrop: (croppedImage: string, data: Cropper.Data) => void;
  onCancel: () => void;
}
export const ImageCropper = ({
  cropData,
  src,
  onCancel,
  onCrop,
}: ImageCropperProps) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [cropping, setCropping,] = useState(false);

  const {
    t,
  } = useTranslation();

  const handleCrop = useCallback(async () => {
    if (cropperRef.current?.cropper === undefined) {
      return;
    }

    setCropping(true);
    try {
      const from = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();

      const data = cropperRef.current?.cropper.getData();

      const resized = await resizeImageDataUrl(from, {
        width: 128,
        height: 128,
      });
      onCrop(resized, data);
    } finally {
      setCropping(false);
    }
  }, [onCrop,]);

  return <div style={{
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr auto',
    height: '100%',
    maxHeight: 'min(80dvh, 100%)',
    minHeight: 'min(400px, 100%)',
    width: '100%',
  }}>
    <div
      style={{
        position: 'relative',
        border: 'none',
      }}
    >
      <Cropper
        initialAspectRatio={1}
        aspectRatio={1}
        preview=".img-preview"
        src={src}
        data={cropData}
        ref={cropperRef}
        viewMode={2}
        guides={true}
        minCropBoxHeight={128}
        minCropBoxWidth={128}
        background={false}
        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
      />

    </div>
    <ActionButtons>
      <Button
        disabled={cropping}
        onClick={onCancel}>
        {t('Close')}
      </Button>
      <Button
        variant='contained'
        disabled={cropping}
        onClick={handleCrop}>
        {cropping ? `${t('Cropping')}...` : t('crop')}
      </Button>
    </ActionButtons>
  </div>;
};
