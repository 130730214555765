import React from 'react';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

const CustomTitle = styled(Typography)(({
  theme,
}) => `
  color: ${theme.palette.text.primary};
  margin-top: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
  text-align: center;
  word-wrap: break-word;
`);

const SectionTitle = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  return <CustomTitle variant="h4">{children}</CustomTitle>;
};

export default SectionTitle;
