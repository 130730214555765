import { motion } from 'framer-motion';
import WarningIcon from '@mui/icons-material/Warning';
import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

const StyledAlert = styled(motion.div)(({
  theme,
}) => `
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${theme.spacing(1)};
  justify-content: center;
  letter-spacing: 0.4px;
  width: 100%;
  overflow-y: hidden;
  padding: ${theme.spacing(1)};
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${theme.palette.warning.main};
  & * {
    color: ${theme.palette.text.lightgrey};
  }
`);

const TextContainer = styled.div`
  flex-grow: 1;
`;

const variants = {
  visible: {
    opacity: 1,
    scale: [0.8, 1.05, 1,],
    height: ['0px', 'auto', 'auto',], // Adjust these values to match your design needs
    transition: {
      duration: 0.8,
      ease: 'easeInOut',
      height: {
        duration: 0.8,
        ease: [0.6, 0.01, -0.05, 0.95,], // Custom ease to match scale
      },
    },
  },
  hidden: {
    opacity: 0,
    scale: 0.8,
    height: 0,
    transition: {
      height: {
        duration: 0.8,
        ease: 'easeInOut',
      },
    },
  },
};

interface AlertProps {
  /**
   * The class name for the component. This makes the component styleable
   */
  className?: string;
  /**
   * The visibility state of the alert.
   */
  visible: boolean;
  /**
   * The style for the component.
   */
  style?: React.CSSProperties;
}

/**
 * A warning alert component.
 *
 * @param props The component props.
 */
const Alert = ({
  children,
  className,
  style,
  visible = false,
}: PropsWithChildren<AlertProps>) => {
  return <StyledAlert
    animate={visible ? 'visible' : 'hidden'}
    className={className}
    initial="hidden"
    style={style}
    variants={variants}
  >
    <WarningIcon />
    <TextContainer>
      {children}
    </TextContainer>
  </StyledAlert>;
};

export default Alert;
