/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlatformEventContext } from './usePlatformEventContext';
import { platformEventSystem } from './PlatformEventSystem';
import React from 'react';
import type { PropsWithChildren } from 'react';

/**
 * The platform event context provider for the component tree.
 *
 * The platform event is a generic tool to send and listen to events in the whole frontend app.
 *
 * @param param0
 */
const PlatformEventContextProvider = ({
  children,
}: PropsWithChildren<any>
) => {
  return <PlatformEventContext.Provider value={platformEventSystem}>
    {children}
  </PlatformEventContext.Provider>;
};

export default PlatformEventContextProvider;
