import { motion } from 'framer-motion';
import styled$ from 'utils/react/styled$';
import type { PropsWithChildren } from 'react';

const BlockContainer = styled$(motion.div)<{
  $noPadding?: boolean;
}>(({
  theme,
  $noPadding,
}) => `
  width: min(100%, 1200px);
  position: relative;
  padding: ${$noPadding ? 0 : theme.spacing(1)};
`);

interface CanvasViewBlockProps {
  layoutId?: string;
  noPadding?: boolean;
}
/**
 * CanvasViewBlock will be used to wrap the canvas view blocks
 * to have a uniform look and feel. Block will define the width
 * of the layout and the padding for each block.
 *
 */
const CanvasViewBlock = ({
  children,
  layoutId,
  noPadding,
}: PropsWithChildren<CanvasViewBlockProps>) => {
  return <BlockContainer
    key="canvas"
    layoutRoot
    $noPadding={noPadding}
    layoutId={`canvas-${layoutId}`}
    initial={{
      x: '100vw',
      opacity: 0,
      y: 0,
    }}
    animate={{
      x: 0,
      opacity: 1,
      y: 0,
    }}
    exit={{
      x: '-100vw',
      opacity: 0,
      y: 0,
    }}
    transition={{
      duration: 0.3,
    }}
  >{
      children
    }</BlockContainer>;
};

export default CanvasViewBlock;
