import { AnimatePresence } from 'framer-motion';
import { ScatterPlot2DGraph } from '../../ScatterPlot2DGraph';
import { useMemo } from 'react';
import Graph2DCanvas from '../../../../canvasTypes/Matrix2DQuestionCanvas/Graph2DCanvas';
import SquareLayout from 'components/common/layout/SquareLayout';
import type {
  AnalyzeOptions,
  Axis2D, Coord2D, Frequencies, Scale2DAnswer, Statistics2D
} from '@shared/schema/src';
import type { Size } from '../../../../canvasTypes/Matrix2DQuestionCanvas';
import type { WithId } from '@mindhiveoy/schema';

export const MIN_RADIUS = 0.1;
export const MAX_RADIUS = 0.95;

interface ContinuousMatrixGridGraphProps {
  axis: Axis2D;
  size?: Size;
  color?: string;
  knobColor?: string;
  frequencies?: Frequencies;
  statistics?: Statistics2D;
  maxX?: number;
  maxY?: number;
  answers: WithId<Scale2DAnswer>[];
  analyzeOptions?: AnalyzeOptions;
}

const ContinuousMatrixGridGraph = ({
  axis,
  color = 'lightgray',
  answers,
  knobColor = 'darkgray',
  maxX,
  maxY,
  statistics,
  analyzeOptions,
}: ContinuousMatrixGridGraphProps): JSX.Element => {
  const scaleX = (maxX ?? 1) * 2;
  const scaleY = (maxY ?? 1) * 2;
  const rowCount = (scaleX ?? 0) + 1;
  const columnCount = (scaleY ?? 0) + 1;

  const coords = useMemo(() => {
    return answers?.map((answer) => ({
      _id: answer._id,
      x: answer.value.x,
      y: answer.value.y,
    })) ?? [] as WithId<Coord2D>[];
  }, [answers,]);

  return <AnimatePresence>
    <SquareLayout key="square">
      <Graph2DCanvas
        rowCount={rowCount}
        columnCount={columnCount}
      >
        <ScatterPlot2DGraph
          color={color}
          coords={coords}
          analyzeOptions={analyzeOptions}
          statistics={statistics}
          knobColor={knobColor}
          axis={axis}
        />
      </Graph2DCanvas>
    </SquareLayout>
  </AnimatePresence>;
};

export default ContinuousMatrixGridGraph;
