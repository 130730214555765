/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Center from '../../../common/layout/Center';
import styled from '@emotion/styled';
import type { BuilderComponentPropsBase } from '../PageWidgetRenderer';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { TextAlign } from '@shared/schema/src/common';
import type { URL } from '@mindhiveoy/schema';

export type HeroLayout = 'image-left' | 'image-right';

export interface HeroProps {
  title?: string;
  paragraph?: string;
  textAlign: TextAlign;
  buttonUrl?: URL;
  buttonText?: string;
  buttonStyle?: 'text' | 'contained';
  image?: URL;
  layout: HeroLayout;
  id?: string;
}

export const HERO_DEFAULT_PROPS: HeroProps = {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  layout: 'image-left',
  buttonStyle: 'text',
  textAlign: 'left',
  buttonText: 'Insert button text',
  title: 'insert text here',
  paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse et leo quis nibh maximus mattis. Nulla auctor varius sodales. Etiam tempor, massa id blandit rutrum, sem metus consequat sem, nec aliquet quam massa non libero. Donec scelerisque accumsan sapien, sed rhoncus ligula eleifend non. Cras nibh velit, porttitor v',
  image: 'https://images.unsplash.com/photo-1639135110923-734dd899e608?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2346&q=80',
};

export const StyledHeroImage = styled.img`
  object-fit: cover;
  max-height: 100%;
  flex: 1;
  height: 100%;  
  width: 100%;
  margin: 0;
`;

export const Hero = styled.div<{ layout: HeroLayout; }>(({
  layout,
}) => `
  display: flex; 
  flex-direction: ${layout === 'image-left' ? 'row' : 'row-reverse'};
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`);

export const HeroTextBlock = styled.div(({
  theme,
}) => `
  flex: 1;
  width: 50%;
  max-width: 50%;
  padding: ${theme.spacing(4)} 
`);

export const HeroButton = styled(Button)(({
  theme,
}) => `
  margin: ${theme.spacing(3)}
`);

export const HeroImageBlock = styled.div`
  flex: 1;
  width: 50%;
  padding: 0;
  margin: 0;
`;

export interface HeroWidgetProps extends BuilderComponentPropsBase<HeroProps> {
  type: 'hero';
}

const HeroWidget: BuilderFunction<HeroWidgetProps, HeroProps> = ({
  data: {
    title = HERO_DEFAULT_PROPS.title,
    layout = HERO_DEFAULT_PROPS.layout,
    paragraph = HERO_DEFAULT_PROPS.paragraph,
    image = HERO_DEFAULT_PROPS.image,
    textAlign = HERO_DEFAULT_PROPS.textAlign,
    buttonStyle = HERO_DEFAULT_PROPS.buttonStyle,
    buttonUrl = HERO_DEFAULT_PROPS.buttonUrl,
    buttonText = HERO_DEFAULT_PROPS.buttonText,
  } = HERO_DEFAULT_PROPS,
}: HeroWidgetProps) => {
  return <Hero layout={layout}>
    <HeroTextBlock>
      <Center>
        <Typography
          component={'span'}
          variant="h2"
          style={{
            textAlign: textAlign as any,
          }}
        >
          {title}
        </Typography>
        <Typography
          component={'span'}
          variant="body2"
          style={{
            textAlign: textAlign as any,
          }}
        >
          {paragraph}
        </Typography>
        <div>
          <Center>
            <HeroButton
              variant={buttonStyle}
              href={buttonUrl}
            >
              {buttonText}
            </HeroButton>
          </Center>
        </div>
      </Center>
    </HeroTextBlock>
    <HeroImageBlock>
      <StyledHeroImage src={image} />
    </HeroImageBlock>
  </Hero>;
};

HeroWidget.config = {
  type: 'hero',
  // dataType: 'String',

  displayName: 'Hero',
  props: {
    title: {
      category: 'general',
      type: 'text',
    },
    paragraph: {
      category: 'general',
      type: 'text',
      multiline: true,
    },
    image: { // TODO Image editor
      category: 'general',
      type: 'text',
    },
    buttonText: { // TODO Image editor
      category: 'general',
      type: 'text',
    },
    buttonUrl: { // TODO Image editor
      category: 'general',
      type: 'text',
    },
    buttonStyle: {
      category: 'general',
      type: 'picker',
      defaultValue: 'text',
      options: [
        {
          value: 'text',
          label: 'Text',
        },
        {
          value: 'contained',
          label: 'Button',
        },
      ],
    },
    textAlign: {
      category: 'general',
      type: 'text-align',
      defaultValue: 'left',
      displayName: 'Text align',
    },
    layout: {
      category: 'general',
      type: 'picker',
      defaultValue: 'image-left',
      options: [
        {
          value: 'image-left',
          label: '2 columns with image on the left',
        },
        {
          value: 'image-right',
          label: '2 columns with image on the right',
        },
      ],
    },
  },
};

export default HeroWidget;
