import FormControl from '@mui/material/FormControl';

import React, { useCallback, useEffect, useState } from 'react';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';
import type { VerticalAlign } from '@shared/schema/src/common';

import FormLabel from '@mui/material/FormLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import type { VerticalAlignPropertyEditor } from '../PropertyEditorTypes';

const VerticalAlignEditor: PropertyEditorFunction<VerticalAlign, VerticalAlignPropertyEditor>= ({
  data,
  propertyName,
  propertyConfig: {
    displayName,
  },
  onSave,
}) => {
  const [value, setValue,] = useState<VerticalAlign>();

  useEffect(() => {
    setValue(data);
  },[data,]);

  const handleChange = useCallback((event: any, value: any) => {
    if (value !== data && onSave) {
      onSave(propertyName, value);
    }
    setValue(value);
    event?.stopPropagation();
  }, [data, onSave, propertyName,]);

  const label = displayName ?? propertyName;

  return <FormControl fullWidth>
    <FormLabel
      component="legend"
    >
      { label }
    </FormLabel>
    <ToggleButtonGroup
      value={value}
      exclusive
      id={propertyName}
      onChange={handleChange}
      aria-label={displayName}
    >
      <ToggleButton value="top"
        aria-label="top aligned">
        <VerticalAlignTopIcon />
      </ToggleButton>
      <ToggleButton value="middle"
        aria-label="centered">
        <VerticalAlignCenterIcon />
      </ToggleButton>
      <ToggleButton value="bottom"
        aria-label="bottom aligned">
        <VerticalAlignBottomIcon />
      </ToggleButton>
    </ToggleButtonGroup>

  </FormControl>;
};

VerticalAlignEditor.config = {
  type: 'vertical-align',
  defaultValue: 'middle',
};

export default VerticalAlignEditor;
