import type { PropsWithChildren } from 'react';

interface HtmlProps {
  children?: string;
  element?: 'div' | 'span';
}
/**
 * Render html string as react component. The html string is given as child of this component.
 * @param props.children  The html string to render
 * @param props.element   The html element to render. Default is div.
 *
 * @returns React component
 */
export const Html = ({
  children, element = 'div',
}: PropsWithChildren<HtmlProps>) => {
  if (!children) {
    return null;
  }
  if (typeof children !== 'string') {
    throw new Error('Html children must be string');
  }
  switch (element) {
    case 'div':
      return <div dangerouslySetInnerHTML={{
        __html: children,
      }} />;
    case 'span':
      return <span dangerouslySetInnerHTML={{
        __html: children,
      }} />;
  }
};
