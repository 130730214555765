/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */
import { LayoutGroup, type ResolvedValues, useAnimation } from 'framer-motion';
import { answerValueToIndexX, answerValueToIndexY, indexToAnswer2D } from './answer2DUtils';
import {
  floorDragX, floorDragY, limitToBounds, roundDragX, roundDragY
} from './dragUtils';
import { orchestrateKnobDropAnimation } from '../../utils/orchestrateKnobAnimation';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { vibrate } from '../../../../utils/haptics';
import Dimensions, { useDimensionsContext } from 'components/common/layout/Dimensions';
import Graph2DCanvas from './Graph2DCanvas';
import Knob from '../common/Knob';
import React from 'react';
import ShadowKnob from './ShadowKnob';
import styled from '@emotion/styled';
import theme from '../../../../theme';
import type {
  AnalyzeOptions,
  AnswerVisibility, Axis2D, Coord2D
} from '@shared/schema/src';
import type { CSSProperties, PropsWithChildren } from 'react';
import type { Canvas2DMatrixProps } from '.';

export interface Matrix2DInputProps extends Pick<Canvas2DMatrixProps, 'graphBackgroundColor' | 'size'> {
  analyzeOptions?: AnalyzeOptions;
  answer?: Coord2D;
  axis: Axis2D;
  canvasAnswerVisibility: AnswerVisibility | 'inherited';
  canvasMode?: 'continuous' | 'discrete';
  disabled?: boolean;
  max: number;
  min: number;
  hideKnob?: boolean;
  sessionAnswerVisibility?: AnswerVisibility;
  /**
   * The summary statistics to be displayed below the graph.
   */
  summaryStatistics?: React.ReactNode;
  onChange?: (value: Coord2D) => void;
}

export const StyledSvg = styled.svg(({
  theme,
}) => `
  border-radius: ${theme.shape.borderRadius}px;  
  position: absolute;
  left: 0;
  top: 0;
`);

const Matrix2DInput = (props: PropsWithChildren<Matrix2DInputProps>) => {
  return <Dimensions>
    <Matrix2DInputInstance {...props} />
  </Dimensions>;
};
/**
 * An input field, where user may input as an 2D coordinate.
 * @param {Matrix2DInputProps} props The properties.
 * @return {JSX.Element} The input field.
 */
const Matrix2DInputInstance = ({
  answer,
  axis,
  // canvasAnswerVisibility = 'inherited',
  canvasMode = 'continuous',
  children,
  disabled,
  graphBackgroundColor = theme.palette.background.graph,
  hideKnob = false,
  max = 1,
  min = -1,
  // sessionAnswerVisibility = AnswerVisibility.VISIBLE,
  summaryStatistics = null,
  onChange,
}: PropsWithChildren<Matrix2DInputProps>) => {
  const ref = useRef<HTMLDivElement>(null);

  // Ref to element to point out the current drop position of the knob in discrete mode
  const shadowRef = useRef<{
    x: number;
    y: number;
  } | null>(null);

  const canvasRef = useRef<HTMLDivElement>(null);

  const knobRef = useRef(null);
  const [dragging, setDragging,] = useState(false);

  const {
    minX,
    maxX,
    minY,
    maxY,
    scale,
    scaleX,
    scaleY,
  } = useMemo(() => {
    const isPercentage = axis.x.scaleType === 'percentage';
    const _min = (typeof min === 'string' ? parseFloat(min) : isPercentage ? 0 : min) ?? -1;
    const _max = (typeof max === 'string' ? parseFloat(max) : isPercentage ? 100 : max) ?? 1;
    const minX = _min;
    const maxX = _max;
    const minY = _min;
    const maxY = _max;
    const scale = _max - _min;
    const scaleX = maxX - minX;
    const scaleY = maxY - minY;

    return {
      minX,
      maxX,
      minY,
      maxY,
      scale,
      scaleX,
      scaleY,
    };
  }, [axis.x.scaleType, max, min,]);

  const componentSize = useDimensionsContext();

  const {
    cellWidth,
    cellHeight,
  } = useMemo(() => {
    const cellWidth = componentSize.width / (canvasMode === 'discrete' ? axis?.x?.range?.length : 7);
    const cellHeight = componentSize.height / (canvasMode === 'discrete' ? axis?.y?.range?.length : 7);

    return {
      cellWidth,
      cellHeight,
    };
  }, [componentSize.width, componentSize.height, canvasMode, axis?.x?.range?.length, axis?.y?.range?.length,]);

  // To transfer knob to the center of the cell, nt top-left corner
  const diff = 0;

  // Special function for the Y axis inversion
  const zTransformFunction = useCallback((value: number, max: number, min: number) => {
    return (1 - (value - min) / (max - min)) * (max - min) + min;
  }, []);

  const answerX = useCallback((answer: Coord2D) => {
    const centerX = minX + scaleX / 2;
    if (!answer) {
      switch (canvasMode) {
        case 'discrete':
          return axis.x.range.length / 2 - 0.5;

        case 'continuous':
          return centerX;
        default:
          return;
      }
    }
    switch (canvasMode) {
      case 'discrete':
        return answerValueToIndexX(axis, answer?.x);

      case 'continuous':
        if (axis.x.scaleType === 'percentage' && (answer?.x === null || answer?.x === undefined)) {
          return 50;
        }
        return answer?.x ?? centerX;
    }
  }, [axis, minX, canvasMode, scaleX,]);

  const answerY = useCallback((answer: Coord2D) => {
    if (!answer) {
      switch (canvasMode) {
        case 'discrete':
          return axis.y.range.length / 2 - 0.5;

        case 'continuous':
          return minY + scaleY / 2;
        default:
          return;
      }
    }

    switch (canvasMode) {
      case 'discrete':
        return axis.y.range.length - answerValueToIndexY(axis, answer?.y) - 1;

      case 'continuous': {
        if (axis.y.scaleType === 'percentage' && (answer?.y === null || answer?.y === undefined)) {
          return zTransformFunction(50, 100, 0);
        }
        const y = (answer?.y ?? minY) + scaleY / 2;
        return zTransformFunction(y, maxY, minY);
      }
    }
  }, [axis, maxY, minY, canvasMode, scaleY, zTransformFunction,]);

  const knobAnimation = useAnimation();
  const shadowAnimation = useAnimation();

  const handleDragStart = useCallback((
  ) => {
    if (disabled || !knobRef.current) {
      return;
    }
    setDragging(true);
  }, [disabled,]);

  // const checkAnswerVisibility = useCallback((
  // ): boolean => {
  //   return areOtherAnswersVisible(canvasAnswerVisibility, sessionAnswerVisibility, answer);
  // }, [answer, canvasAnswerVisibility, sessionAnswerVisibility,]);

  useEffect(() => {
    if (dragging) {
      return;
    }
    const callback = async () => {
      if (!answer) {
        switch (canvasMode) {
          case 'discrete': {
            // TODO: Examine this
            const newX = answerX(answer!)!;
            const newY = answerY(answer!)!;

            await knobAnimation.start({
              x: newX * cellWidth + diff,
              y: newY * cellHeight + diff,
              opacity: 0.5,
            }, {
              duration: 0.1,
            });

            break;
          }
          case 'continuous': {
            const xPos = (componentSize.width - cellWidth) / 2;
            const yPos = (componentSize.height - cellHeight) / 2;
            await knobAnimation.start({
              x: xPos,// + diff,
              y: yPos,// + diff,
              opacity: 0.5,
            }, {
              duration: 0.3,
            });
            break;
          }
        }
        return;
      }

      const xv = answerX(answer)!;
      const yv = answerY(answer)!;

      switch (canvasMode) {
        case 'discrete': {
          shadowAnimation.start({
            x: xv * cellWidth + diff,
            y: yv * cellHeight + diff,
          }, {
            type: 'spring',
            duration: 0.3,
          });

          knobAnimation.start({
            x: xv * cellWidth + diff,
            y: yv * cellHeight + diff,
            opacity: 1,
          }, {
            type: 'spring',
            duration: 0.3,
          });
          break;
        }
        case 'continuous': {
          const w = componentSize.width - componentSize.width / 7;
          const h = componentSize.height - componentSize.height / 7;

          const x1 = answer ?
            (answer.x - minX) * w / scaleX :
            minX + scaleX / 2 * w;
          const y1 = answer ?
            (zTransformFunction(answer.y, maxY, minY) - minY) * h / scaleY :
            minY + scaleY * h;

          knobAnimation.start({
            x: x1,
            y: y1,
            opacity: 1,
          }, {
            type: 'spring',
            duration: 0.3,
          });
          break;
        }
      }
    };
    callback();
  }, [answer, answerX, answerY, axis, cellWidth, cellHeight, componentSize.height, componentSize.width,
    shadowAnimation, diff, handleDragStart, knobAnimation, max, maxY, min, minX, minY, canvasMode, scale,
    scaleX, scaleY, zTransformFunction, dragging,]);

  const extractDragCoords = useCallback(() => {
    const matrixRect = ref.current?.getBoundingClientRect();
    // TODO: Make this more robust
    const knobRect = (knobRef.current as unknown as HTMLDivElement)?.getBoundingClientRect();

    if (!matrixRect || !knobRect) {
      return {
        vx: 0,
        vy: 0,
      };
    }
    const vx = knobRect.left - matrixRect.left + knobRect.width / 2;
    const vy = knobRect.top - matrixRect.top + knobRect.height / 2;
    return {
      vx,
      vy,
    };
  }, []);

  const handleUpdate = useCallback((values: ResolvedValues) => {
    if (disabled) {
      return;
    }
    if (values.x !== undefined && values.y !== undefined) {
      switch (canvasMode) {
        case 'discrete': {
          /*
          * Animate the cue drop position if the cell has changed
          */
          const x = limitToBounds(roundDragX(componentSize, axis, values.x as number), axis.x) * cellWidth + diff;
          const y = limitToBounds(roundDragY(componentSize, axis, values.y as number), axis.y) * cellHeight + diff - 4;

          // Prevent the cue from animating if the knob is in the same cell
          if (shadowRef.current && (x === shadowRef.current.x && y === shadowRef.current.y)) {
            return;
          }

          shadowRef.current = {
            x,
            y,
          };

          shadowAnimation.start({
            x,
            y,
            opacity: 0.75,
          }, {
            duration: 0.1,
          });
          break;
        }
        case 'continuous': {
          // Continuous mode is self expiatory as the dragged knob is in the
          // precise position of the current value.

          break;
        }
        default:
          console.error(`Graph2D: Unidentified mode: ${canvasMode}`);
      }
    }
  }, [axis, cellWidth, cellHeight, componentSize, shadowAnimation, diff, disabled, canvasMode,]);

  const handleDragEnd = useCallback(async (
  ) => {
    if (disabled || !ref.current) {
      return;
    }

    try {
      const {
        vx,
        vy,
      } = extractDragCoords();

      switch (canvasMode) {
        case 'discrete': {
          const newX = limitToBounds(floorDragX(componentSize, axis, vx), axis.x);
          const newY = limitToBounds(floorDragY(componentSize, axis, vy), axis.y);

          const x = newX * cellWidth + diff;
          const y = newY * cellHeight + diff;

          const promises = [];

          // If the browser supports, make a vibration
          vibrate();

          const animation = orchestrateKnobDropAnimation(
            canvasRef,
            knobRef,
            x, y);

          try {
            if (animation) {
              promises.push(
                knobAnimation.start(animation)
              );

              promises.push(
                shadowAnimation.start({
                  x,
                  y,
                  opacity: 0,
                }, {
                  duration: 0.3,
                })
              );
              await Promise.all(promises);
            }
          } catch (e) {
            console.error(e);
          } finally {
            const newAnswer = indexToAnswer2D(axis, newX, newY);

            onChange?.(newAnswer);
          }
          break;
        }
        case 'continuous': {
          const w = componentSize.width - componentSize.width / 7;
          const h = componentSize.height - componentSize.height / 7;

          let x = (vx - componentSize.width / 7 / 2) / w;
          let y = (vy - componentSize.height / 7 / 2) / h;

          x = minX + x * scaleX;
          y = minX + y * scaleY;

          x = Math.min(maxX, Math.max(minX, x));
          y = Math.min(maxY, Math.max(minY, y));

          y = zTransformFunction(y, maxY, minY);

          try {
            const animation = orchestrateKnobDropAnimation(
              canvasRef,
              knobRef
            );

            vibrate();

            if (animation) {
              // If the browser supports, make a vibration
              await knobAnimation.start(animation);
            }
          } catch (e) {
            console.error(e);
          } finally {
            onChange?.({
              x,
              y,
            });
          }
          break;
        }
      }
    } finally {
      setDragging(false);
      shadowRef.current = null;
    }
  }, [axis, cellWidth, cellHeight, componentSize, shadowAnimation, diff, disabled, extractDragCoords,
    knobAnimation, maxX, maxY, minX, minY, canvasMode, onChange, scaleX, scaleY, zTransformFunction,]);

  const areaStyle: CSSProperties = useMemo(() => {
    return {
      width: '100%',
      height: '100%',
      aspectRatio: '1/1',
      position: 'relative',
      backgroundColor: 'darkblue',
      borderRadius: theme.shape.borderRadius,
    };
  }, []);

  return <LayoutGroup>
    <div
      ref={ref}
      style={areaStyle}
    >
      <Graph2DCanvas
        rowCount={axis.y.range.length}
        columnCount={axis.x.range.length}
        graphBackgroundColor={graphBackgroundColor}
        mode={canvasMode}
        ref={canvasRef}
      >
        {children}
        {/* {canvasMode === 'discrete' && grid && <DiscreteMatrixGridGraph
          analyzeOptions={analyzeOptions}
          maxX={maxX}
          maxY={maxY}
          // canvas={canvas as any}
          canvasMode={canvasMode}
          axis={axis}
          isPresentation={isPresentation}
          frequencies={checkAnswerVisibility() ? canvas?.frequencies : undefined}
          statistics={canvas?.statistics as Statistics2D}
        />} */}
      </Graph2DCanvas>
      {summaryStatistics}
      {/* <Matrix2DCharacteristics
        axis={axis}
        analyzeOptions={analyzeOptions}
        mode={canvasMode as any}
        statistics={canvas?.statistics as Statistics2D}
      /> */}

      <ShadowKnob
        visible={canvasMode === 'discrete' && dragging}
        ref={shadowRef}
        width={`${cellWidth}px`}
        height={`${cellHeight}px`}
        animate={shadowAnimation}
      />
      {!hideKnob ? <Knob
        ref={knobRef}
        animate={knobAnimation}
        width={cellWidth}
        height={cellHeight}
        showDragCues={!answer}
        active={!!answer}
        mode={canvasMode}
        dragConstraints={ref}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragUpdate={handleUpdate}
      /> : null}
    </div>

  </LayoutGroup>;
};

export default Matrix2DInput;
