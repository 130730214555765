
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { PropertyEditorFunction } from '../../builders/propertyEditorBuilder';

import { CirclePicker } from 'react-color';
import { Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useTheme } from '@emotion/react';
import AnimateHeight from 'components/common/AnimateHeight';
import PropertyEditorControl from '../PropertyEditorControl';
import PropertyEditorLegend from '../PropertyEditorLegend';
import type { ColorResult } from 'react-color';
import type { PickerPropertyEditor } from '../PropertyEditorTypes';

const CirclePickerHack = CirclePicker as any;

/**
 * 32 vivid colors that are easy to distinguish from each other
 */
export const COLOR_PALETTE = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#C0C0C0',
  '#800000',
  '#008000',
  '#000080',
  '#808000',
  '#008080',
  '#800080',
  '#FFC0CB',
  '#FFA500',
  '#FFFFE0',
  '#A52A2A',
  '#FF7F50',
  '#90EE90',
  '#D2691E',
  '#FFD700',
  '#FFF8DC',
  '#008B8B',
  '#006400',
  '#4B0082',
  '#FFFFFF',
  '#000000',
  '#888888',
  'transparent',
];

const ColorPickerEditor: PropertyEditorFunction<string, PickerPropertyEditor<string>> = ({
  data,
  propertyName,
  propertyConfig: {
    displayName,
    defaultValue,
    section,
  },
  onSave,
}) => {
  const [value, setValue,] = useState(data ?? defaultValue);

  const theme = useTheme();

  const [open, setOpen,] = useState(false);

  useEffect(() => {
    if (data && data !== value) {
      setValue(data);
    }
  }, [data, value,]);

  const handleChange = useCallback(
    (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = color.hex;
      setValue(newValue);
      if (newValue !== data && onSave) {
        onSave(propertyName, newValue);
      }
      event.stopPropagation();
    }, [data, onSave, propertyName,]);

  const id = useMemo(() => `editor-${propertyName}`,
    [propertyName,]
  );

  const handleOpenPicker = useCallback(() => {
    setOpen(!open);
  }, [open,]);

  const color = useMemo(() => {
    try {
      return theme.palette.getContrastText(value);
    } catch (e) {
      return 'black';
    }
  }, [theme.palette, value,]);
  // TODO: Section mast be its own property editor
  return <>
    {section &&
      <>
        <Typography component={'span'}
          variant="h4"
          id={`${id}-label`}>{section}
        </Typography>
        <br />
      </>
    }<PropertyEditorControl>
      <PropertyEditorLegend label={displayName} >
        <motion.button
          style={{
            width: '100%',
            marginTop: 0,
            height: '3rem',
            borderRadius: theme?.shape?.borderRadius,
            border: 'none',
          }}
          animate={{
            backgroundColor: value,
          }}
          onClick={handleOpenPicker}
        >
          <span style={{
            color,
          }}>
            {open ? 'Close' : 'Open'}
          </span>
        </motion.button>

        <AnimateHeight visible={open}>
          <motion.div
            key="picker"
            style={{
              paddingTop: 18,
              overflow: 'hidden',
            }}
            initial={{
              maxHeight: 0,
            }}
            animate={{
              maxHeight: 'inherit',
            }}
            exit={{
              maxHeight: 0,
            }}
          >
            <CirclePickerHack
              colors={COLOR_PALETTE}
              width="100%"
              color={value}
              onChangeComplete={handleChange}
            />
          </motion.div>
        </AnimateHeight>
      </PropertyEditorLegend>
    </PropertyEditorControl>
  </>;
};

ColorPickerEditor.config = {
  type: 'color',
};

export default ColorPickerEditor;
