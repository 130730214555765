/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import Typography from '@mui/material/Typography';

import {
  HERO_DEFAULT_PROPS, Hero, HeroImageBlock, HeroTextBlock, StyledHeroImage
} from '../../renderers/HeroWidget';
import { useComponentSelectionContext } from '../../../../../utils/hooks/useComponentSelectionContext';
import Center from '../../../../common/layout/Center';
import InplaceEditor from '../../../../editing/InplaceEditor';
import styled from '@emotion/styled';
import type { BuilderFunction } from '../../../builders/componentBuilder';
import type { HeroProps, HeroWidgetProps } from '../../renderers/HeroWidget';

const StyledTypography = styled(Typography)`
  position: relative;
`;

const HeroWidgetEditor: BuilderFunction<HeroWidgetProps> = ({
  data: {
    title = HERO_DEFAULT_PROPS.title,
    layout = HERO_DEFAULT_PROPS.layout,
    paragraph = HERO_DEFAULT_PROPS.paragraph,
    image = HERO_DEFAULT_PROPS.image,
    textAlign = HERO_DEFAULT_PROPS.textAlign,
    // buttonStyle = HERO_DEFAULT_PROPS.buttonStyle,
    // buttonUrl = HERO_DEFAULT_PROPS.buttonUrl,
    // buttonText = HERO_DEFAULT_PROPS.buttonText,
  } = HERO_DEFAULT_PROPS,
}) => {
  const {
    selection,
  } = useComponentSelectionContext<HeroProps>();

  const handleSave = useCallback((name: string, value: string) => {
    selection?.updateProperty(name as any, value);
  }, [selection,]);

  return <Hero layout={layout}>
    <HeroTextBlock>
      <Center>
        <StyledTypography variant="h2"
          style={{
            textAlign: textAlign as any,
          }}
        >
          <InplaceEditor
            name='title'
            value={title}
            multiline
            style={{
              textAlign: textAlign as any,
            }}
            onEditDone={handleSave}
          />
        </StyledTypography>
        <StyledTypography variant="body2">
          <InplaceEditor
            name='paragraph'
            value={paragraph}
            multiline
            style={{
              textAlign: textAlign as any,
            }}
            onEditDone={handleSave}
          />
        </StyledTypography>
        {/* <div>
          <Center>
            <HeroButton
              variant={buttonStyle}
              href={buttonUrl}
            >
              {buttonText}
            </HeroButton>
          </Center>
        </div> */}
      </Center>
    </HeroTextBlock>
    <HeroImageBlock>
      <StyledHeroImage src={image} />
    </HeroImageBlock>
  </Hero>;
};

HeroWidgetEditor.config = {
  type: 'hero',
  displayName: 'Hero',
  props: {},
};

export default HeroWidgetEditor;
