import FormControl from '@mui/material/FormControl';
import React from 'react';
import type { FormControlProps } from '@mui/material/FormControl';
import type { PropsWithChildren } from 'react';

const PropertyEditorControl = ({
  children,
  ...props
}: PropsWithChildren<FormControlProps>) => <FormControl
  fullWidth
  {...props}>{ children }
</FormControl>;

export default PropertyEditorControl;
