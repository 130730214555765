/* eslint-disable @typescript-eslint/no-explicit-any */

import { ComponentFieldSelectionModel } from 'components/builder/models/selectionModels/ComponentFieldSelectionModel';
import { WidgetCellEditor } from '../WidgetCellEditor';
import { resolveCellCount } from './resolveCellCount';
import { resolveElements } from './resolveElements';
import { resolveFieldPaths } from '../resolveFieldPaths';
import { templateComponentTypes } from 'components/templates/templateAdmin/TemplateEditor/ContentArea';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useComponentDataContext } from 'utils/hooks/useComponentDataContext';
import { useComponentSelectionContext } from 'utils/hooks/useComponentSelectionContext';
import Box from '@mui/material/Box';
import GridLayoutWidgetImplementation from '../renderers/GridLayoutWidget';
import componentBuilder from '../../builders/componentBuilder';
import usePropertyFieldPath, { PropertyFieldPathProvider } from 'utils/hooks/usePropertyFieldPath';
import type { BuilderComponentPropsBase, BuilderComponentType } from '../BuilderComponentTypes';
import type { BuilderFunction } from '../../builders/componentBuilder';
import type { GridLayoutWidget } from '@shared/schema/src/spaces/projects/sessions/canvases';

export interface GridWidgetProps extends BuilderComponentPropsBase<GridLayoutWidget> {
  type: 'grid-layout';
}

const GridLayoutWidgetEditor: BuilderFunction<GridWidgetProps, any> = ({
  data: {
    columnTemplate,
    rowTemplate,
    maxWidth,
    cells = [],
  },

}) => {
  const {
    setSelection,
  } = useComponentSelectionContext();

  const {
    propertyFieldPath,
  } = usePropertyFieldPath();

  const [elements, setElements,] = useState<BuilderComponentPropsBase[]>(cells);

  const {
    model,
    getProperty,
  } = useComponentDataContext();

  const {
    style,
    widgets,
  } = useMemo(() => {
    const columns = resolveElements(columnTemplate);
    const rows = resolveElements(rowTemplate);

    const rowCount = resolveCellCount(rows);
    const columnCount = resolveCellCount(columns);
    const count = columnCount * rowCount;

    const widgets: BuilderComponentPropsBase[] = [];
    for (let i = 0; i < count; i++) {
      const element = elements?.[i] ?? null;
      widgets.push(element as any);
    }
    return {
      widgets,
      style: {
        display: 'grid',
        gridTemplateColumns: columnTemplate,
        gridTemplateRows: rowTemplate,
        height: '100%',
        width: '100%',
        border: '1px solid black',
        maxWidth,
        minWidth: 300,
      },
    };
  }, [elements, columnTemplate, maxWidth, rowTemplate,]);

  useEffect(() => model?.subscribeToDataChange(() => {
    const fieldPath = resolveFieldPaths(propertyFieldPath, 'data', 'cells');
    const cells = getProperty(fieldPath) ?? [];

    setElements(cells);
  }), [getProperty, model, propertyFieldPath, setElements,]);

  const handleClick = useCallback((index: number) => {
    const componentPath = resolveFieldPaths(propertyFieldPath, 'data', 'cells', `[${index}]`);

    const component = model.getPropertyValue(componentPath);
    if (!component) {
      return;
    }

    const config = componentBuilder.getConfig(component.type);

    setSelection(new ComponentFieldSelectionModel(
      model,
      config as any,
      resolveFieldPaths(componentPath, 'data')
    ));
  }, [model, propertyFieldPath, setSelection,]);

  const handleDelete = useCallback((index: number) => {
    const fieldPath = resolveFieldPaths(propertyFieldPath, 'data', 'cells', `[${index}]`);

    model.deleteProperty(fieldPath);
    setSelection(null);
  }, [model, propertyFieldPath, setSelection,]);

  const handleSetClick = useCallback(
    (componentType: BuilderComponentType, index: number) => {
      if (index < 0) {
        return;
      }
      const fieldPath = resolveFieldPaths(propertyFieldPath, 'data', 'cells', `[${index}]`);

      const config = componentBuilder.getConfig(componentType);

      const component = {
        type: componentType,
        data: {},
      };
      model.updateProperty(
        fieldPath,
        component
      );
      // const component = elementModel.set(
      //   componentType,
      //   index,
      //   {},
      //   true
      // );
      setSelection(new ComponentFieldSelectionModel(
        model,
        config as any,
        fieldPath
      ));
    }, [model, propertyFieldPath, setSelection,]);

  return <Box
    style={style}
    padding={2}>
    <PropertyFieldPathProvider propertyFieldPath='data.cells'>
      {
        widgets.map((props, index) => {
          return <WidgetCellEditor
            editMode="edit"
            component={props as any}
            cellIndex={index}
            componentTypes={templateComponentTypes}
            key={index.toString()}
            onClick={handleClick}
            onDelete={handleDelete}
            onSelect={handleSetClick}
          />;
        })
      }
    </PropertyFieldPathProvider>
  </Box>;
};

GridLayoutWidgetEditor.config = GridLayoutWidgetImplementation.config;

export default GridLayoutWidgetEditor;
