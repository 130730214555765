import { BlocQuery } from '../../@mindhiveoy/bloc/BlocQuery';
import { createUseBlocHook } from '../../@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import type { BlocErrorFunction } from '../../@mindhiveoy/bloc/types';
import type { NotificationDoc } from '../../@shared/schema/src';
import type { UserId } from '@mindhiveoy/schema';

interface UidParams {
  uid: UserId;
}

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @template {string} MemberRoleId
 * @template {MemberShip} AppMember
 */
export class NotificationsBloc extends BlocQuery<NotificationDoc, UidParams> {
  /**
   * @param {ErrorFunction} onError Error listener
   * @param {UserId} uid Current user id
   */
  constructor(
    private $uid: UserId,
    onError?: BlocErrorFunction
  ) {
    const collectionPath = `users/${$uid}/notifications`;
    super({
      collectionPath,
      params: {
        uid: $uid,
      },
      onError,
    });
  }

  /**
   * The current user id if any
   *
   * @return {UserId} user id
   */
  public get uid(): UserId | undefined {
    return this.$uid;
  }
}

export const useNotifications =
  createUseBlocHook<NotificationDoc, UidParams, NotificationsBloc>(
    ({
      onError,
      params: {
        uid,
      },
    }) => new NotificationsBloc(uid, onError)
  );
