import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import React from 'react';
import styled from '@emotion/styled';
import type { CSSProperties } from 'react';
import type { Transition } from 'framer-motion';

// TODO: Make animation to cue the panelist to move the knob
// import TouchAppIcon from '@mui/icons-material/TouchApp';

export const AnimatedIconContainer = styled(motion.div)`
  background-color: transparent;
  border: none;
  color: white;
  padding: 0;
  margin: 0;
`;

/**
 * The animation range for the arrows in pixels.
 */
const ARROW_ANIM_RANGE = 6;
/**
 * The range in pixels for the arrows to vanish.
 */
const VANISH_RANGE = 20;

const cycleTransition: Transition = {
  repeat: Infinity,
  repeatType: 'mirror',
  duration: 0.6,
};

const vanishTransition: Transition = {
  duration: 1.6,
};

const chevronStyleLeft: CSSProperties = {
  position: 'absolute',
  right: '100%',
  height: '100%',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const chevronStyleRight: CSSProperties = {
  position: 'absolute',
  left: 'calc(100% - 2px)',
  height: '100%',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const chevronStyleUp: CSSProperties = {
  position: 'absolute',
  left: -1,
  bottom: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

const chevronStyleDown: CSSProperties = {
  position: 'absolute',
  left: -1,
  top: 'calc(100% - 2px)',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

const opacityZero = {
  opacity: 0,
};
const opacityOne = {
  opacity: 1,
};

const UP_VISIBLE = {
  y: [0, -ARROW_ANIM_RANGE,],
};
const UP_HIDDEN = {
  y: -VANISH_RANGE,
};
const DOWN_VISIBLE = {
  y: [0, ARROW_ANIM_RANGE,],
};
const DOWN_HIDDEN = {
  y: VANISH_RANGE,
};
const LEFT_VISIBLE = {
  x: [0, -ARROW_ANIM_RANGE,],
};
const LEFT_HIDDEN = {
  x: -VANISH_RANGE,
};
const RIGHT_VISIBLE = {
  x: [0, ARROW_ANIM_RANGE,],
};
const RIGHT_HIDDEN = {
  x: VANISH_RANGE,
};

interface MoveKnobDragCueProps {
  axis: 'x' | 'y' | 'xy';
  visible: boolean;
  width: number | string;
  height: number | string;
}

/**
 * Cue the panelist to move the knob. This will render a set of animated arrows to indicate the direction of
 * the knob movement.
 *
 * @param {MoveKnobDragCueProps} props  The props for the MoveKnobCue component.
 */
const MoveKnobDragCue = ({
  axis = 'xy',
  visible = false,
  // width,
  height,
}: MoveKnobDragCueProps) => {
  const x = axis?.includes('x');
  const y = axis?.includes('y');

  const transition = visible ? cycleTransition : vanishTransition;
  const up = visible ? UP_VISIBLE : UP_HIDDEN;
  const down = visible ? DOWN_VISIBLE : DOWN_HIDDEN;
  const left = visible ? LEFT_VISIBLE : LEFT_HIDDEN;
  const right = visible ? RIGHT_VISIBLE : RIGHT_HIDDEN;

  const style: CSSProperties = useMemo(() => ({
    position: 'relative',
    // width,
    height,
    maxHeight: '100%',
    zIndex: 100000,
    cursor: 'grab',
  }), [height,]);

  return <AnimatePresence>
    {visible && <motion.div
      key="navi-hint"
      id="navi-hint"
      style={style}
      initial={opacityZero}
      animate={opacityOne}
      exit={opacityZero}
    >
      {y ? <AnimatedIconContainer
        key="up"
        style={chevronStyleUp}
        animate={up}
        transition={transition}>
        <ArrowUpward fontSize='large' />
      </AnimatedIconContainer> : null}

      {y ? <AnimatedIconContainer
        key="down"
        style={chevronStyleDown}
        animate={down}
        transition={transition}
      >
        <ArrowDownward fontSize='large' />
      </AnimatedIconContainer> : null}

      {x ? <AnimatedIconContainer
        key="left"
        style={chevronStyleLeft}
        animate={left}
        transition={transition}>
        <ArrowBack fontSize='large' />
      </AnimatedIconContainer> : null}

      {x ? <AnimatedIconContainer
        key="right"
        style={chevronStyleRight}
        animate={right}
        transition={transition}>
        <ArrowForward fontSize='large' />
      </AnimatedIconContainer> : null}
    </motion.div>}
  </AnimatePresence>;
};

export default MoveKnobDragCue;
