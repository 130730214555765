/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BlocQuery } from '@mindhiveoy/bloc/BlocQuery';
import { validateSessionProps } from '@shared/schema/src';
import type { BlocErrorFunction } from '@mindhiveoy/bloc/types';
import type { Comment, CommentId, CommentParams } from '@shared/schema/src';
import type { MemberId, WithId } from '@mindhiveoy/schema';

import { cleanUpObject } from '@mindhiveoy/foundation';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import { createComment, updateComment } from './commentApi';
import { createUseBlocHook } from '@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import { firebaseApp } from 'schema';
import { resolveSessionPath } from './utils/resolveSessionPath';

const firestore = getFirestore(firebaseApp());

export type CommentBlocMode =
  /**
   * Show all comments that a direct children to the given comment or root
   */
  'direct-children' |
  /**
   * List latest 100 comments
   */
  'latest' |
  /**
   * List all comments
   */
  'all';

/**
 *
 * @template {string} MemberRoleId
 * @template {MemberShip} AppMember
 */
export class CommentsBloc extends BlocQuery<WithId<Comment>, CommentParams> {
  /**
   *
   * @param {CommentParams} params
   * @param {ErrorFunction} onError Error listener
   */
  constructor(
    params: CommentParams & {
      parentId?: CommentId;
    },
    onError?: BlocErrorFunction,
    mode: CommentBlocMode = 'direct-children'
  ) {
    validateSessionProps(params, 'canvasId');

    let collectionPath = '';

    const {
      spaceId,
      projectId,
      sessionId,
      canvasId,
      parentId,
    } = resolveSessionPath(params);

    collectionPath = `spaces/${spaceId}/projects/${projectId}/sessions/${sessionId}/canvases/${canvasId}/comments`;
    super({
      params,
      collectionPath,
      onError,
    });
    this.orderBy('created');

    switch (mode) {
      case 'direct-children':

        if (parentId && params.commentId !== null) {
          this.where(
            'parentId', '==', parentId
          );
        }
        break;

      case 'latest':
        this.limit(100);
        break;

      case 'all':
        break;
    }
  }

  /**
   * Create a new project.
   *
   * @param {Comment}     comment        Session base data
   * @param {CommentId}   creatorId      The creator's member id.
   * @return {Comment} A data object containing the id of the created project and project's initial data.
   */
  public createComment = async (
    comment: Comment,
    creatorId?: MemberId
  ): Promise<WithId<Comment>> => {
    // const candidateCommentId = generateCommentId();

    return await this._create(
      async (candidateCommentId) => {
        const data = this.preprocessDoc(comment as any);
        return await createComment({
          ...this.params,
          candidateCommentId,
          comment: data,
        });
      },
      this.onError!,

      async () => {
        const created = new Date().getTime();
        const data = cleanUpObject({
          ...comment,
          // _id: candidateCommentId,
          created,
          _isOptimistic: true,
          lastModified: created,
          ...creatorId && {
            modifierId: creatorId,
            creatorId,
          },
        });

        // As a workaround for the issue that the document id is not generated by the server
        // when the document is created by the client, we create a document with a random id
        // and then update the document with the id generated by the server.

        const commentsRef = collection(firestore, this.collectionPath);
        const commentRef = doc(commentsRef);

        try {
          await setDoc(commentRef, data);
        } catch (error) {
          console.error(error);
          alert(error); // TODO: Implementation
        }
        return {
          ...data,
          _id: commentRef.id,
        };
      },

      (docId: string) => {
        console.log(`hit... ${docId}`); // TODO: collision handling
      }
    );
  };

  /**
   * Update a single comment
   * @param {CommentId}         commentId
   * @param {Partial<Comment>}  comment
   * @return {Promise<WithId<Comment>>}
   */
  public updateComment = async (
    commentId: CommentId,
    comment: Partial<Comment>
  ): Promise<WithId<Comment>> => {
    return this._update(
      commentId,
      async () => {
        return await updateComment({
          ...this.params,
          comment: comment as Comment,
          commentId: commentId as CommentId,
        });
      },
      (error: Error) => {
        console.error(error);
        alert(error); // TODO: Implementation
      },
      (data) =>
        data && {
          ...data,
          ...comment,
          _id: commentId,
        }
    );
  };
}

export const useComments =
  createUseBlocHook<WithId<Comment>, CommentParams & {
    parentId?: CommentId;
  }, CommentsBloc>(({
    params,
    onError,
  }) => new CommentsBloc(
    params,
    onError
  ));

export const useLatestComments =
  createUseBlocHook<WithId<Comment>, CommentParams, CommentsBloc>(({
    params,
    onError,
  }) => new CommentsBloc(
    params,
    onError,
    'latest'
  ));

export const useAllComments =
  createUseBlocHook<WithId<Comment>, CommentParams, CommentsBloc>(({
    params,
    onError,
  }) => new CommentsBloc(
    params,
    onError,
    'all'
  ));
