import { breakpoints } from 'theme';
import type { AavistusOptions, AavistusTheme } from 'theme';

// XDelphi-specific theme overrides

// TODO Create a theme with this tool: https://bareynol.github.io/mui-theme-creator/

const ITEM_HOVER_STYLE = 'transform: scale(1.03);' +
  'box-shadow: 0px 10px 13px -7px #000000, 11px 15px 15px 3px rgba(0,0,0,0);';

export const getXdelphiThemeOptions = (theme: AavistusTheme): AavistusOptions => {
  return {
    animations: {
      hovers: {
        card: ITEM_HOVER_STYLE,
      },
    },
    breakpoints,
    shape: {
      borderRadius: 12,
      smokeGlassBlur: 8,
      imagePlaceholder: '/images/ImagePlaceholder.png',
      maxEditorAreaWidth: 1200,
    },
    constants: {
      headerHeight: '80px',
      bottomHeight: '72px',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        html {
          -webkit-font-smoothing: auto;
          margin: 0;
        }
        body {
          margin: 0;
        }
        `,
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 12,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 12,
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          colorSecondary: {
            color: '#D1D2D7',
          },
        },
      },
    },
    typography: {
      allVariants: {
        color: '#f2f2f2',
      },
      fontFamily: [
        'Poppins',
        'Lato',
        'Nunito',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Helvetica Neue"',
        'sans-serif',
      ].join(','),
      h1: {
        fontSize: '2.4rem',
        marginBottom: '1rem',
        fontWeight: 500,
        letterSpacing: '0px',
        lineHeight: 1.2,
        [theme.breakpoints.down('sm')]: {
          fontSize: '2.1rem',
        },
      },
      h2: {
        fontSize: '2.1rem',
        marginTop: '1rem',
        marginBottom: '1rem',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.9rem',
        },
      },
      h3: {
        fontSize: '1.8rem',
        marginTop: '1rem',
        marginBottom: '1rem',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.7rem',
        },
      },
      h4: {
        fontSize: '1.5rem',
        marginTop: '1rem',
        marginBottom: '1rem',
        fontWeight: 400,
      },
      h5: {
        fontSize: '1.25rem',
        marginTop: '1rem',
        marginBottom: '1rem',
        fontWeight: 400,
      },
      h6: {
        fontSize: '1.1rem',
        marginTop: '1rem',
        marginBottom: '1rem',
        fontWeight: 400,
      },
      body1: {
        marginTop: '0.75rem',
        marginBottom: '1rem',
        fontWeight: 300,
      },
      body2: {
        fontSize: '0.75rem',
        marginTop: '0.75rem',
        marginBottom: '0.75rem',
        fontWeight: 300,
      },
    },
    palette: {
      mode: 'dark',
      primary: {
        // main: '#1B628A',
        main: '#4Bc2fA',
        contrastText: '#fff',
        light: '#5BC2FA',
      },
      secondary: {
        main: '#00a15b',
      },
      action: {
        disabledBackground: '#C2C2C2',
        disabled: '#ffffff80',
      },
      error: {
        main: '#FF5F5F',
      },
      background: {
        // default: '#101018',
        default: '#202030',
        defaultTransparent: 'rgba(32,32,48,0.7)',
        // paper: 'rgba(40,40,40,0.85)',
        paper: 'rgba(50,50,50,0.85)',
        paper60: 'rgba(50,50,50,0.6)',
        paper70: 'rgba(50,50,50,0.75)',
        comment: '#55595B',
        innerPaper: '#4f4f6f',
        graph: '#C4C4C4',
        lightInnerPaper: '#50516f',
        paperDropdown: 'rgb(50,50,50)',
      },
      text: {
        primary: 'rgba(230,232,255,0.8)',
        secondary: '#E5E5E5',
        lightgrey: '#D3D3D3',
      },
      warning: {
        main: '#C64C06',
        dark: '#a72505',
      },
      question: {
        main: '#4Bc2fA',
      },
    },
  };
};
