import { AnimatePresence, motion } from 'framer-motion';
import { Backdrop } from '@mui/material';
import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

const DialogContainer = styled(motion.div)`
  z-index: 10000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface CustomDialogProps {
  open?: boolean;
}

const CustomDialog = ({
  open,
  children,
}: PropsWithChildren<CustomDialogProps>) => {
  return <AnimatePresence>
    {open && <DialogContainer
      key="modal"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
    >
      <Backdrop open />
      {children}
    </DialogContainer>}
  </AnimatePresence>;
};

export default CustomDialog;
