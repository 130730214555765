
/**
 * Check if the app is running in Storybook. The Storybook environment is set
 * the environment variable STORYBOOK_ENV to 'true' when running in Storybook.
 * 
 * For other runtimes this will be always false.
 * 
 * @returns  {boolean}  True if running in Storybook
 */
export const isRunningInStoryBook = () => process?.env?.STORYBOOK_ENV === 'true';
