import { AnswerVisibility, SessionId } from '../session';
import { Coord2D } from '../../../../coordinates';
import { Media, TextAlign, VerticalAlign, WithMedia, } from '../../../../common';
import { WithId } from '@mindhiveoy/schema';

export type CanvasType =
  'text' |
  '1d' |
  '2d' |
  'category' |
  'timeserie' |
  'form' |
  'layout' |
  'timeserie';

export type CanvasId = string;

export const defaultAxisScaleMin = -3;

export const defaultAxisScaleMax = 3;

export const defaultMatrixScaleRange: RangeItem[] = [
  {
    value: -3,
    label: '---',
  },
  {
    value: -2,
    label: '--',
  },
  {
    value: -1,
    label: '-',
  },
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '+',
  },
  {
    value: 2,
    label: '++',
  },
  {
    value: 3,
    label: '+++',
  },
];

export interface WithCanvas {
  canvas: WithId<Canvas>;
}

export type CanvasStripInfo = WithId<Pick<Canvas, 'media' | 'name' | 'orderNo'>>;

export type Canvas =
  ContentQuestionCanvas |
  SliderQuestionCanvas |
  Matrix2DQuestionCanvas |
  CategoryQuestionCanvas |
  LayoutCanvas;

export interface WithInteractionConfig<C> {
  // TODO these should be one level higher at query level or both?
  /**
   * The strategy to show answer visibility
   * @deprecated use config instead
   */
  answerVisibility: AnswerVisibility;
  /**
  * Allow voting for comments
   * @deprecated use config instead
  */
  voting?: boolean;
  /**
  * Allow commenting for the argument
   * @deprecated use config instead
  */
  commenting?: boolean;
  /**
   * Show user's answer next to comment. The answer will be copied to comment,
   * so the value will be the one at the answering time.
   * @deprecated use config instead
   */
  showAnswer?: boolean;
  /**
   * Show basic statistics info
   * @deprecated use config instead
   */
  showStats?: boolean;
  /**
   * Canvas configuration
   */
  config?: C;
}

export interface CanvasBase extends WithMedia, WithInteractionConfig<CanvasConfigBase> {
  type: CanvasType;
  paramId?: string;
  name: string;
  desc: string;
  /**
   * Canvas order number used for sorting canvases
   */
  orderNo?: number;
  /**
   * Commenting related info for the canvas
   */
  commentInfo?: {
    /**
     * The number of comments in total for this canvas 
     */
    commentCountRecursive?: number;
    /**
     * The number of root level comments for this canvas
     */
    rootLevelCommentCount?: number;
  };
}

// TODO Make With-interface for orderNo
export interface RangeItem {
  value: number;
  /**
   * Item label. If no value is set, the value should be used.
   */
  label?: string;
}

/**
 * Tick labeling type
 *
 * | Type | Description |
 * | :----------- | :-----------------------------------------------------------------------------------|
 * | 'plus-minus' | Ticks are presented with plus and minus like:<br/> ```+++, ++, +, 0, -, --, ---```  |
 * | 'numeric'    | Ticks are presented with numeric values like: ```-3, -2, -1, 0, +1, +2, +3```       |
 * | 'percentage' | Ticks are presented with percentage values like: ```0%, 25%, 50%, 75%, 100%```      |
 * | 'custom'     | Ticks are presented with custom labels like: ```'bad', 'ok', 'good'``` defined      |
 * |              | the user. This mode is always set implicitly when user edits labels in editor.
 */
export type TickLabelingType = 'plus-minus' | 'numeric' | 'percentage' | 'custom';

export const analyzeOptionKeys: (keyof StatCharacteristics)[] = [
  'mean',
  'average',
  'q1',
  'median',
  'q3',
  'stdDeviation',
  'variance',
];

export type StatCharacteristics = {
  mean?: boolean;
  average?: boolean;
  q1?: boolean;
  median?: boolean;
  q3?: boolean;
  stdDeviation?: boolean;
  variance?: boolean;
};

export type StatCharacteristic = keyof StatCharacteristics;

export interface AnalyzeOptions {
  /**
   * Different statistics to be shown
   */
  stats?: StatCharacteristics;

  showValues?: boolean;
  // /**
  //  * Graph representing the data to be shown
  //  *
  //  * TODO: No spaces!!!
  //  */
  // graphType?: '2d graph' | 'histogram' | 'density plot';
}

export const defaultSliderColors: AxisColors = {
  min: '#f00',
  max: '#0f0',
};

export const default2DCornerColors: CornerColors = {
  luc: '#00f',
  ruc: '#0f0',
  llc: '#f00',
  rlc: '#ff0',
};

export interface AxisColors {
  min: string;
  max: string;
}

export interface Axis {
  scaleType: TickLabelingType;
  /**
   * Main label
   */
  mainLabel?: string;
  /**
   * Max Axis label
   */
  maxLabel?: string;
  /**
   * Min Axis label
   */
  minLabel?: string;
  /**
   * Minimum value in the axis
   */
  min: number;
  /**
   * Maximum value in the axis
   */
  max: number;
  /**
   * Items in the range
   */
  range: RangeItem[];
  /**
   * Number of items in the range. This is being used with continuous mode to mark the number of ticks
   * to render for numeric and percentage presentation types..
   */
  scaleLength: number;

  color?: AxisColors;
}

/**
 * Labeling for 2d matrix corners
 */
export interface CornerLabels {
  /**
   * Left upper corner label
   */
  luc?: string;
  /**
   * Right upper corner label
   */
  ruc?: string;
  /**
   * Left lower corner label
   */
  llc?: string;
  /**
   * Right lower corner label
   */
  rlc?: string;
}

/**
 * Corner colors for 2d matrix
 */
export interface CornerColors {
  /**
   * Left upper corner label
   */
  luc?: string;
  /**
   * Right upper corner label
   */
  ruc?: string;
  /**
   * Left lower corner label
   */
  llc?: string;
  /**
   * Right lower corner label
   */
  rlc?: string;
}

export interface Axis2D {
  scaleLength: number;
  scaleType: TickLabelingType;
  /**
   * Minimum value in both axes
   */
  min: number;
  /**
   * Maximum value in both axes
   */
  max: number;
  /**
  * X axis
  */
  x: Axis;
  /**
  * Y axis
  */
  y: Axis;
  /**
   * Corner labels are used to label the corners of the 2d matrix
   */
  cornerLabels?: CornerLabels;
  /**
   * Colors used to visualize the user's answer in the 2d matrix.
   * Colors will be scaled between the corners.
   */
  colors?: CornerColors;
}

export type Frequencies = {
  [key: string]: number;
};

/**
 * Basic statistics of the Canvas answers
 */
export type Statistics = Statistics1D | Statistics2D;

export interface StatisticsBase {
  type: string;
  /**
   * The number of samples ie. frequency.
   */
  count: number;
  /**
   * Indicate how many times the current stats have been
   * derived based on prior stats. This information is used
   * to indicate when it is best to recalculate values fully to
   * avoid too big rounding errors in values.
   */
  deriveCount: number;
}
export interface Statistics1D extends StatisticsBase {
  type: '1d',
  average?: number;
  q1?: number;
  median?: number;
  q3?: number;
  stdDeviation?: number;
  variation?: number;
}

export interface Statistics2D extends StatisticsBase {
  type: '2d',
  averageX?: number;
  averageY?: number;
  q1X?: number;
  q1Y?: number;
  medianX?: number;
  medianY?: number;
  q3X?: number;
  q3Y?: number;
  stdDeviationX?: number;
  stdDeviationY?: number;
  variationX?: number;
  variationY?: number;
}

export interface CategoryItem {
  id: string;
  name: string;
  media?: Media;
  color: string;
}

export interface CategoryOptionItem {
  id: string;
  name: string;
  imageUrl?: URL;
}

export interface WithStatistics {
  statistics?: Statistics;
}

export interface WithFrequencies {
  frequencies?: Frequencies;
}

/**
 * Category question mode:
 *
 * - single:      The item can be in a single category.
 * - one-to-many: The item can be in multiple categories.
 */
export type CategoryQuestionMode = 'single' | 'multiple';

export interface CategoryQuestionCanvas extends CanvasBase, WithStatistics, WithFrequencies {
  type: 'category';
  /**
   * The mode of the category question. The default should be *single*.
   */
  mode: CategoryQuestionMode;

  ordered?: boolean;
  /**
   * Categories where different selections can be dropped
   */
  categories: CategoryItem[];
  /**
   * Options to be set in categories
   */
  options: CategoryOptionItem[];
}

export interface TimeserieQuestionCanvas extends CanvasBase, WithStatistics, WithFrequencies {
  type: 'timeserie',
}

export type MatrixMode = 'continuous' | 'discrete';

export type MatrixInteraction = 'graphic' | 'sliders';

export type TickLabelsPresentation = 'single' | 'mirrored';

export interface SessionConfig {
  /**
   * Show commenting for the canvas
   * default value is true.
   */
  commenting: boolean;
  /**
   * Allow voting for comments
   * default value is true.
   */
  voting: boolean;
  /**
   * The strategy to show answer visibility
   * default value is VISIBLE.
   */
  answerVisibility: AnswerVisibility;
  /**
   * Show user's answer next to comment. The answer will be copied to comment,
   * so the value will be the one at the answering time.
   * default value is true.
   */
  showAnswer: boolean;
  /**
   * Show basic statistics info
   * default value is true.
   */
  showStats: boolean;
}

export interface CanvasConfigBase {
  /**
   * Show commenting for the canvas
   * default value is inherited.
   */
  commenting: boolean | 'inherited',
  /**
   * Allow voting for comments
   * default value is inherited.
   */
  voting: boolean | 'inherited',
  /**
   * The strategy to show answer visibility
   * default value is 'inherited'.
   */
  answerVisibility: AnswerVisibility | 'inherited',
  /**
   * Show user's answer next to comment. The answer will be copied to comment,
   * so the value will be the one at the answering time.
   * default value is inherited.
   */
  showAnswer: boolean | 'inherited',
  /**
   * Show basic statistics info
   * default value is inherited.
   */
  showStats: boolean | 'inherited',
}

export type CanvasConfig = Matrix2DConfig;

export interface Matrix2DConfig extends CanvasConfigBase {
  type: '2d',
  /**
   * The style of the presentation. The default is *default*.
   * 
   * - default: The presentation is the same as in the old version.
   * - modern:  The presentation is rendering some of the labels inside of the 
   *            matrix. This way we can maximize the size of the matrix.
   */
  presentationStyle?: 'modern' | 'default';
}

export interface WIthDataMode {
  mode: MatrixMode;
}

export interface Matrix2DQuestionCanvas extends CanvasBase, WithStatistics, WithFrequencies {
  type: '2d';
  mode: MatrixMode;
  interaction: MatrixInteraction;
  axis: Axis2D;
  showAxisLabels?: boolean;
  showMinMaxLabels?: boolean;
  showTickLabels?: boolean;
  /**
   * Show arrows indicating the direction of the axis (x and y)
   */
  showAxisArrows?: boolean;
  showCornerLabels?: boolean;
  /**
   * @deprecated This is causing too much confusion. Tick labels are always shown in the same way.
   */
  tickLabelsPresentation: TickLabelsPresentation;
  /**
   * Minimum value in both axes
   */
  min: number;
  /**
   * Maximum value in both axes
   */
  max: number;
  analyzeOptions?: AnalyzeOptions;
}

export type Characteristics = 'mean' | 'median' | 'q1' | 'q3';

export interface GraphBase extends WithStatistics, WithFrequencies {
  sessionId: SessionId;
  canvasId: CanvasId;
  dataMode?: DataMode;
  snapshot?: ContentQuestionCanvas | SliderQuestionCanvas | Matrix2DQuestionCanvas | CategoryQuestionCanvas;
  characteristics?: StatCharacteristics;
  answer?: Coord2D;
  description?: string;
}

export type DataMode =
  /**
   * Data of exact time will be saved as a snapshot
   */
  'snapshot' |
  /**
   * The data is shown in real time
   */
  'real-time';

export const enum SlideVisibility {
  FACILITATORS = 'f',
  MEMBERS = 'm',
  EVERYONE = 'e',
}

export type Layout =
  'single' |
  'two-column' |
  'three-column' |
  /**
   * One column on the left and rest of the space on the right side filled
   */
  '1-grow' |
  'grow-1' |
  'matrix-2x2';

export interface BuilderComponent {
  id?: string;
  type: string;
  data: Record<string, unknown>;
}
/**
 * Canvas to combine different widgets on chosen layout
 */
export interface LayoutCanvas extends CanvasBase, WithStatistics, WithFrequencies {
  type: 'layout';
  /**
   * The layout of the canvas. Layout defines how root level content areas are placed
   * on screen.
   */
  layout: Layout;
  /**
   * Layout elements in the canvas.
   */
  visibility: SlideVisibility;
  elements: string;
}

export interface QuestionDescriptionWidget extends GraphBase {
  type: 'question-description';
  questionTitle?: string;
  questionDescription?: string;
  content?: string;
  background?: Media;
  textAlign?: TextAlign;
  verticalAlign?: VerticalAlign;
}

export interface SliderQuestionCanvas extends CanvasBase, WithStatistics, WithFrequencies {
  type: '1d';
  value: number;
  axis: Axis;
  showAxisLabels: boolean;
  showMinMaxLabels: boolean;
  showTickLabels: boolean;
  mode: 'continuous' | 'discrete';
  /**
   * Minimum value in both axes
   */
  min: number;
  /**
   * Maximum value in both axes
   */
  max: number;
  analyzeOptions?: AnalyzeOptions;
}

export interface ContentQuestionCanvas extends CanvasBase, WithStatistics {
  type: 'text',
  content: string;
}

export interface FormField {
  paramId: string;
  title: string;
  type: 'text' | 'memo' | 'optionlist';
}

export interface FormCanvas extends CanvasBase {
  type: 'form',
  fields: FormField[];
}
