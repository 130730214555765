/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MessageScope } from '@shared/schema/src';
import React, { useCallback, useState } from 'react';
import type { Canvas, Comment } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

interface MessageContextType {
  open: boolean;
  scope: MessageScope;
  selectedComment?: WithId<Comment> | undefined;
  selectedCanvas?: WithId<Canvas> | undefined;
  openMessageWindowForScope: (scope: MessageScope, comment?: WithId<Comment>, canvas?: WithId<Canvas>) => void;
  closeMessageWindow: () => void;
}

const dummyFun: any = () => { };

export const MessageContext = React.createContext<MessageContextType>({
  open: false,
  scope: MessageScope.GENERAL,
  selectedComment: undefined,
  selectedCanvas: undefined,
  openMessageWindowForScope: dummyFun,
  closeMessageWindow: dummyFun,
});

interface MessageContextProps {
  initialValue?: Partial<MessageContextType>;
}

export const MessageContextProvider = ({
  children,
  initialValue = {
    open: false,
    scope: MessageScope.GENERAL,
    selectedComment: undefined,
    selectedCanvas: undefined,
  },
}: React.PropsWithChildren<MessageContextProps>) => {
  const [open, setOpen,] = useState(initialValue.open ?? false);
  const [scope, setScope,] = useState<MessageScope>(initialValue.scope ?? MessageScope.GENERAL);
  const [selectedComment, setSelectedComment,] = useState<WithId<Comment> | undefined>(initialValue.selectedComment);
  const [selectedCanvas, setSelectedCanvas,] = useState<WithId<Canvas> | undefined>(initialValue.selectedCanvas);

  const closeMessageWindow = useCallback(() => {
    setScope(MessageScope.GENERAL);
    setSelectedCanvas(undefined);
    setSelectedComment(undefined);
    setOpen(false);
  }, []);

  const openMessageWindowForScope = useCallback((
    scope: MessageScope = MessageScope.GENERAL,
    comment?: WithId<Comment>,
    canvas?: WithId<Canvas>) => {
    if (scope === MessageScope.DIRECT && comment) {
      setSelectedComment(comment);
    } else {
      setSelectedComment(undefined);
      if (canvas) {
        setSelectedCanvas(canvas);
      }
    }
    setScope(scope);
    setOpen(true);
  }, []);

  return <MessageContext.Provider value={{
    open,
    scope,
    selectedComment,
    selectedCanvas,
    openMessageWindowForScope,
    closeMessageWindow,
  }}>
    {children}
  </MessageContext.Provider>;
};

export const useMessages = () => {
  return React.useContext(MessageContext);
};
