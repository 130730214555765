/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable valid-jsdoc */
/* eslint-disable sonarjs/no-duplicate-string */
import { isItemOrderSame } from './utils/isItemOrderSame';
import isEqual from 'lodash/isEqual';
import sortRectanglesBasedOnDraggedRectanglePosition from './utils/sortRectanglesBasedOnDraggedRectanglePosition';
import type { CategoryItem } from '@shared/schema/src';
import type { RefObject } from 'react';

interface RectBoundaries {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface InternalBounds extends RectBoundaries {
  centerX: number;
  centerY: number;
}

export const NEW_CATEGORY = '__NEW_CATEGORY';

export const NEW_ITEM = '__NEW_ITEM';

export interface Offset {
  x: number;
  y: number;
}
export interface Offsets {
  [key: string]: Offset;
}
export const zeroOffset: Offset = {
  x: 0,
  y: 0,
};

export interface CategoryDescriptor {
  id: string;
  name: string;
  color: string;
  /**
   * When category is fixed, it will be ignored in
   * category dragging and it can not be deleted.
   */
  fixed?: boolean;
}

export interface WithIdInfo {
  categoryId: string;
  itemId: string;
  fixed?: boolean;
}

export interface CategoryState {
  isDropTarget?: boolean;
}
export type CategoryStateObserverFunction = (state: CategoryState) => void;

export type CategoryObserverFunction<T extends CategoryDescriptor> = (
  categories: T[],

) => void;

export type DragItemsObserverFunction<T extends WithIdInfo> = (
  items: T[],
  offsets: { [key: string]: { x: number; y: number; }; },
  containerMinHeight?: number,
) => void;

/**
 * Model for categorization container. This class holds the logic for drag and drop
 * operations and altering the categories and items.
 *
 * @template C - Category descriptor
 * @template I - Item descriptor
 */
export class CategoryContainerModel<C extends CategoryDescriptor = CategoryDescriptor, I extends WithIdInfo = WithIdInfo> {
  private _categories: C[] = [];

  private _items = new Map<string, I[]>();

  private _viewPortRef: RefObject<HTMLDivElement> = {
    current: null,
  };

  private _itemBoundaries: {
    [itemId: string]: RefObject<HTMLDivElement>;
  } = {};

  private _categoryBoundaries: {
    [categoryId: string]: RefObject<HTMLDivElement>;
  } = {};

  /**
   * Update category items if there are changes detected
   * @param {CategoryItem[]} categories
   */
  public updateCategories = (categories: CategoryItem[]) => {
    if (!categories || isEqual(this._categories, categories)) {
      return;
    }
    this._categories = categories.slice(0) as unknown as C[];
    this._fireCategoriesChanged(this._categories);
  };
  /**
   * Update category's data
   * @param {string}      categoryId
   * @param {Partial<C>}  values
   */
  public updateCategory = (categoryId: string, values: Partial<C>) => {
    const index = this._categories.findIndex((c) => c.id === categoryId);
    if (index === -1) {
      this._categories.push({
        id: categoryId ?? '_',
        ...values,
      } as C);
    } else {
      this._categories[index] = {
        ...this._categories[index],
        ...values,
      };
    }
    this._fireCategoriesChanged(this._categories);
  };

  /**
   * Category boundaries show boundaries based on the
   * current drag status of the categories. This is used
   * to avoid transitions to have an impact on the drag
   * sorting.
   */
  private _draggedCategoryBoundaries: {
    [categoryId: string]: InternalBounds;
  } = {};

  private _categoryContentBoundaries: {
    [categoryId: string]: RefObject<HTMLDivElement>;
  } = {};

  private _state: 'idle' | 'dragging-item' | 'dragging-category' = 'idle';

  private _draggedItems = new Map<string, I[]>();
  private _draggedItemId: string | undefined;
  private _dragItemStartIndex = -1;

  private _draggedCategories: C[] = [];
  // private _draggedCategoryId: string | undefined;
  private _dragCategoryStartIndex = -1;

  private _categoriesSubscribers: CategoryObserverFunction<C>[] = [];

  private _itemsSubscribers = new Map<string, DragItemsObserverFunction<I>[]>();

  private _categoryStateSubscribers = new Map<string, CategoryStateObserverFunction[]>();

  private _draggedItemBoundaries: {
    [itemId: string]: InternalBounds;
  } = {};

  private _categoryItemOffsets: {
    [categoryId: string]: Offsets;
  } = {};

  private _dragItemStartCategoryId?: string;

  private _targetCategoryId: any;
  private _currentDragDropIndex?: number;
  private _categoryAnimating: {
    [categoryId: string]: boolean;
  } = {};
  private _lastCategoryBoundsBeforeAnimation: {
    [categoryId: string]: InternalBounds;
  } = {};
  /**
   * Content offsets will tell the offset of the content from the top left corner of the category.
   * This is used to sort the items based on the content offset while dragging.
   */
  private _categoryContentOffsets: {
    [categoryId: string]: {
      left: number;
      top: number;
      bottom: number;
    };
  } = {};

  /**
   * Current dragging target category id.
   * @return {string} - Category id
   */
  public get currentDraggingTarget(): string {
    return this._targetCategoryId;
  }

  private _fixedCategories: C[] = [];

  private _fixedItems: Map<string, I[]> = new Map();

  /**
 *
 * @param {C[]} categories  - List of categories
 * @param {I[]} items       - List of items
 */
  constructor(
    categories: C[] = [],
    items: I[] = []
  ) {
    this._fixedCategories = categories.filter((c) => c.fixed);
    this._categories = categories
      .filter((c) => !c.fixed)
      .slice(0);

    items.forEach((item) => {
      if (item.fixed) {
        const categoryItems = this._fixedItems.get(item.categoryId) || [];
        categoryItems.push(item);
        this._fixedItems.set(item.categoryId, categoryItems);
        return;
      }
      const categoryItems = this._items.get(item.categoryId) || [];
      categoryItems.push(item);
      this._items.set(item.categoryId, categoryItems);
    });
  }

  /**
   * Delete category and all items in it.
   * @param {string} id - Category id
   */
  public deleteCategory = (id: string) => {
    this._categories = this._categories.filter((c) => c.id !== id);
    this._items.delete(id);
    this._fireCategoriesChanged(this._categories);
  };

  /**
   * Resolve from coordinates on which category the item is dropped if any.
   * @param {number} x - X coordinate
   * @param {number} y - Y coordinate
   * @return {string | undefined} - Category id or undefined if no category is found
   */
  private _resolveTargetCategory = (x: number, y: number): string | undefined => {
    const categoryIds = Object.keys(this._categoryBoundaries);
    for (let i = 0; i < categoryIds.length; i++) {
      const categoryId = categoryIds[i];
      const boundaries = this._categoryBoundaries[categoryId]?.current?.getBoundingClientRect();
      if (boundaries && (x >= boundaries.left && x <= boundaries.right && y >= boundaries.top && y <= boundaries.bottom)) {
        return categoryId;
      }
    }
    return undefined;
  };

  /**
   *
   */
  public get categories() {
    return this._categories.concat(this._fixedCategories);
  }

  public getCategory = (id?: string) => {
    if (!id) {
      return undefined;
    }
    return this._categories.find((c) => c.id === id);
  };

  public items = (categoryId: string) => {
    return this._items.get(categoryId) || [];
  };

  /**
   * The state of the model.
   *
   * idle: No drag operation is in progress.
   * dragging-item: An item is being dragged.
   * dragging-category: A category is being dragged.
   *
   * @return {string}  - State of the model.
   */
  public get state() {
    return this._state;
  }

  /**
   * Set the reference to the viewport component. The viewport is the container
   * that holds all the categories and items.
   *
   * @param {RefObject<HTMLDivElement>} ref - Reference to the viewport component.
   */
  public setViewPortRef = (
    ref: RefObject<HTMLDivElement>
  ) => {
    this._viewPortRef = ref;
  };

  public setCategoryRef = (
    categoryId: string,
    ref: RefObject<HTMLDivElement>,
    contentRef: RefObject<HTMLDivElement>
  ) => {
    this._categoryBoundaries[categoryId] = ref;
    this._categoryContentBoundaries[categoryId] = contentRef;
  };

  public setItemRef = (
    id: string,
    ref: RefObject<HTMLDivElement>
  ) => {
    if (!ref) {
      return;
    }
    this._itemBoundaries[id] = ref;
  };

  public setCategoryAnimating = (categoryId: string, isAnimating: boolean) => {
    this._categoryAnimating[categoryId] = isAnimating;
  };

  public canDragCategory = (categoryId: string) => {
    const category = this.getCategory(categoryId);
    return category && !category.fixed;
  };

  public startCategoryDrag = (categoryId: string) => {
    this.storeDraggingStateCategoryBoundaries();

    this._state = 'dragging-category';
    this._dragItemStartCategoryId = categoryId;

    const items = this._categories.slice(0);

    this._dragCategoryStartIndex = items.findIndex((item) => item.id === categoryId);
    this._draggedCategories = items;
  };

  public startItemDrag = (categoryId: string, id: string) => {
    this.storeDraggingStateItemBoundaries();
    this.storeDraggingStateCategoryBoundaries();

    this._state = 'dragging-item';
    this._draggedItemId = id;
    this._dragItemStartCategoryId = categoryId;

    const items = this._items.get(categoryId) || [];

    this._dragItemStartIndex = items.findIndex((item) => item.itemId === id);

    this._draggedItems.set(categoryId, items.slice(0));
  };

  /**
   * Called when a category is being dragged.
   *
   * @param {string} _categoryId - Id of the category being dragged.
   * @param {number} _x          - X position of the mouse.
   * @param {number} _y          - Y position of the mouse.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onCategoryDrag = (_categoryId: string, _x: number, _y: number) => {
    if (this._state !== 'dragging-category') {
      return;
    }

    if (!this._dragItemStartCategoryId) {
      throw new Error('Drag start category is not set');
    }

    const items: any = this._sortCategories();

    const startItems = this._draggedCategories;
    if (!startItems) {
      throw new Error('Drag start items are not set');
    }

    if (items) {
      this._draggedCategories = items;
      this._fireCategoriesChanged(items);
    }
    this._dragItemStartCategoryId === undefined;
  };

  /**
   * Called when an item is being dragged.
   * @param {string} id  - Id of the item being dragged.
   * @param {number} x
   * @param {number} y
   */
  public onItemDrag = (id: string, x: number, y: number) => {
    // TODO: Same virtual boundaries system for items like we now have with categories
    if (this._state !== 'dragging-item') {
      return;
    }
    if (!this._dragItemStartCategoryId) {
      throw new Error('Drag start category id is not set');
    }

    const currentTargetCategoryId = this._targetCategoryId;
    this._targetCategoryId = this._resolveTargetCategory(x, y);

    const items = this._sortItems(this._dragItemStartCategoryId);
    const startItems = this._draggedItems.get(this._dragItemStartCategoryId);
    if (!startItems) {
      throw new Error('Drag start items are not set');
    }
    const {
      changed: startChanged,
    } = this._updateItemOffsets(this._dragItemStartCategoryId, startItems);

    const events: any[] = [];
    if (items) {
      this._draggedItems.set(this._dragItemStartCategoryId, items);
    }
    if (items || startChanged) {
      events.push({
        categoryId: this._dragItemStartCategoryId,
        items: startItems,
      });
    }

    if (currentTargetCategoryId &&
      currentTargetCategoryId !== this._targetCategoryId &&
      currentTargetCategoryId !== this._dragItemStartCategoryId
    ) {
      const items = this._items.get(currentTargetCategoryId);
      this._resetItemOffsets(currentTargetCategoryId,
        (items ?? []).slice(0)
      );
      events.push({
        categoryId: currentTargetCategoryId,
        items: this._items.get(currentTargetCategoryId) ?? [],
      });
    }

    if (this._targetCategoryId && this._targetCategoryId !== this._dragItemStartCategoryId) {
      const items = (this._items.get(this._targetCategoryId) ?? []).slice(0);

      const {
        changed: offsetsChanged,
        minHeight,
      } = this._updateItemOffsets(this._targetCategoryId, items);

      // TODO: Make the min height calculation to be based on each container and their contents in the same row

      if (offsetsChanged) {
        events.push({
          categoryId: this._targetCategoryId,
          items,
          minHeight,
        });
      }
    }
    events.forEach((event) => {
      this._fireItemsChanged(event.categoryId, event.items, event.minHeight);
    });

    if (this._targetCategoryId !== currentTargetCategoryId) {
      this._fireCategoryStateChanged(currentTargetCategoryId, {
        isDropTarget: false,
      });
      this._fireCategoryStateChanged(this._targetCategoryId, {
        isDropTarget: true,
      });
    }
  };

  public endCategoryDrag = () => {
    if (this._state !== 'dragging-category') {
      return;
    }
    this._state = 'idle';

    if (!this._dragItemStartCategoryId) {
      throw new Error('Drag start category id is not set');
    }

    const items = this._sortCategories();
    if (items) {
      this._fireCategoriesChanged(items as any);
    }
    this._draggedCategories = [];
    this._dragItemStartCategoryId = undefined;
    // this._targetCategoryDropIndex = undefined;
  };

  public endItemDrag = () => {
    // console.log('endDrag')

    if (this._targetCategoryId === undefined) {
      return;
    }
    this._state = 'idle';

    if (!this._targetCategoryId) {
      return;
    }

    if (this._targetCategoryId !== this._dragItemStartCategoryId) {
      this._dropToNewTargetCategory();
    } else {
      this._dropToOriginCategory();
    }

    this._fireCategoryStateChanged(this._targetCategoryId, {
      isDropTarget: false,
    });
    this._targetCategoryId = undefined;
  };

  /**
   *
   */
  private _dropToNewTargetCategory = () => {
    if (!this._dragItemStartCategoryId) {
      throw new Error('Drag start category id is not set');
    }
    const originItems = this._items.get(this._dragItemStartCategoryId)?.slice(0);
    const dragItem = originItems?.[this._dragItemStartIndex];
    if (!dragItem) {
      throw new Error('Dragged item is not set');
    }
    dragItem.categoryId = this._targetCategoryId;

    const index = originItems.findIndex((item) => item.itemId === this._draggedItemId);
    originItems.splice(index, 1);
    this._items.set(this._dragItemStartCategoryId, originItems);

    const targetItems = this._items.get(this._targetCategoryId)?.slice(0) ?? [];
    targetItems.splice(this._currentDragDropIndex!, 0, dragItem);
    this._items.set(this._targetCategoryId, targetItems);

    this._resetItemOffsets(this._dragItemStartCategoryId, originItems);
    this._resetItemOffsets(this._targetCategoryId, targetItems);

    this._fireItemsChanged(this._dragItemStartCategoryId, originItems);
    this._fireItemsChanged(this._targetCategoryId, targetItems);

    // console.loerrorg('endDrag', `Item from ${this._dragItemStartCategoryId} moved to ${this._targetCategoryId}`);
  };

  /**
   * Store current item boundaries for dragging state
   */
  private storeDraggingStateItemBoundaries() {
    this._categoryContentOffsets = this._categories.reduce(
      (acc, {
        id,
      }) => ({
        ...acc,
        [id]: this._getCategoryContentOffset(id),
      }), {});

    this._draggedItemBoundaries = Object.keys(this._itemBoundaries)
      .reduce(
        (acc, id) => ({
          ...acc,
          [id]: this._getItemBounds(id),
        }), {});
  }

  /**
   * Get category content offset
   *
   * @param {string} categoryId
   * @return {CategoryContentOffset} Category content offset
   */
  _getCategoryContentOffset = (categoryId: string) => {
    const bounds = this._getCategoryBounds(categoryId);
    if (!bounds) {
      throw new Error('Category bounds are not set');
    }
    const ref = this._categoryContentBoundaries[categoryId];

    if (!ref?.current) {
      throw new Error('Category content ref is not set');
    }

    const {
      top, left, bottom,
    } = ref.current.getBoundingClientRect();

    return {
      top: top - bounds.top,
      left: left - bounds.left,
      bottom: bounds.bottom - bottom,
    };
  };

  /**
   * Get category bounds
   * @param {string} categoryId Category id
   */
  private storeDraggingStateCategoryBoundaries() {
    this._draggedCategoryBoundaries = this._categories.reduce(
      (acc, {
        id,
      }) => ({
        ...acc,
        [id]: this._getCategoryBounds(id),
      }), {});
  }

  /**
   * Get category bounds
   * @param {string} categoryId Category id
   */
  private _dropToOriginCategory() {
    if (!this._dragItemStartCategoryId) {
      throw new Error('Drag start category id is not set');
    }
    const items = this._draggedItems.get(this._dragItemStartCategoryId);
    if (!items) {
      throw new Error('Dragged items are not set');
    }
    this._items.set(this._dragItemStartCategoryId, items);
    this._draggedItems.set(this._dragItemStartCategoryId, []);
    this._resetItemOffsets(this._dragItemStartCategoryId, items);
    this._fireItemsChanged(this._dragItemStartCategoryId, items);
  }

  private _sortCategories = () => {
    if (!this._dragItemStartCategoryId) {
      throw new Error('Drag start category id is not set');
    }

    const categories = this._draggedCategories ?? [];

    const draggedCategories = categories
      .slice(0)
      .filter((c) => !c.fixed);

    const newOrder = sortRectanglesBasedOnDraggedRectanglePosition(
      this.getViewPortBounds(),
      draggedCategories.map((c) => ({
        id: c.id,
        bounds: this._draggedCategoryBoundaries[c.id],
      })),
      {
        id: this._dragItemStartCategoryId,
        bounds: this._getCategoryBounds(this._dragItemStartCategoryId) as any,
      }
    );

    if (!isItemOrderSame(newOrder, draggedCategories as any, (a, b) => a.id === b.id)) {
      // this._targetCategoryDropIndex = newOrder.findIndex(({ id }) => id === this._dragItemStartCategoryId);

      const newItems = newOrder.map(({
        id,
      }) => draggedCategories.find((c: any) => c.id === id));
      this._draggedCategories = newItems as any;

      this._draggedCategoryBoundaries = newOrder.reduce((acc, {
        id, bounds,
      }) => {
        return {
          ...acc,
          [id]: bounds,
        };
      }, {} as any);

      return newItems;
    }
    return false;
  };
  /**
   * Sorts the items based their center y position. If the item is dragged, it will be placed at the
   * position of the dragged item.
   *
   * @param {string} categoryId Category id
   * @return {I[]} Sorted items
   */
  private _sortItems = (categoryId: string): I[] | false => {
    const items = (this._draggedItems.get(categoryId) ?? this._items.get(categoryId) ?? []).slice(0);
    const draggedItems = items.slice(0);
    if (!this._draggedItemId) {
      return false;
    }
    const draggedItemIndex = items.findIndex((i) => i.itemId === this._draggedItemId);
    if (draggedItemIndex === -1) {
      return false;
    }

    if (this._dragItemStartCategoryId === categoryId) {
      const draggedItemBoundaries = this._getItemBounds(this._draggedItemId);
      if (!draggedItemBoundaries) {
        return false;
      }
    }

    const isStartCategoryWhileTargetElsewhere =
      this._dragItemStartCategoryId === categoryId &&
      this._targetCategoryId !== categoryId;

    items.sort((a, b) => {
      const aBoundaries = this._getItemBounds(a.itemId);
      const bBoundaries = this._getItemBounds(b.itemId);

      // If the target category differs from the start category,
      // the dragged item will be placed at the position of the dragged item.
      if (isStartCategoryWhileTargetElsewhere && // TODO: Optimize
        (a.itemId === this._draggedItemId ||
          b.itemId === this._draggedItemId)) {
        return 1;
      }

      if (!aBoundaries || !bBoundaries) {
        return 0;
      }
      if (aBoundaries.centerY < bBoundaries.centerY) {
        return -1;
      }
      if (aBoundaries.centerY > bBoundaries.centerY) {
        return 1;
      }
      return 0;
    });

    if (!isItemOrderSame(items, draggedItems as any, (a, b) => a.itemId === b.itemId)) {
      this._draggedItems.set(categoryId, items);

      // TODO: Update item boundaries for dragging state based on new order

      const categoryBoundaries = this._getCategoryBounds(categoryId);
      if (!categoryBoundaries) {
        throw new Error('Category boundaries are not set');
      }
      const offset = this._getCategoryContentOffset(categoryId);

      let top = categoryBoundaries.top + offset.top;
      const left = categoryBoundaries.left + offset.left;

      items.forEach((item) => {
        const itemBoundaries = this._getItemBounds(item.itemId);
        if (itemBoundaries) {
          this._draggedItemBoundaries[item.itemId] = {
            top,
            y: top,
            left,
            x: left,
            width: itemBoundaries.width,
            height: itemBoundaries.height,
            centerY: top + itemBoundaries.height / 2,
            centerX: left + itemBoundaries.width / 2,
            right: left + itemBoundaries.width,
            bottom: top + itemBoundaries.height,
          };
          top += itemBoundaries.height;
        }
      });
      return items;
    }
    return false;
  };

  public subscribe = (callback: CategoryObserverFunction<C>) => {
    this._categoriesSubscribers.push(callback);

    return () => {
      const index = this._categoriesSubscribers.indexOf(callback);
      if (index !== -1) {
        this._categoriesSubscribers.splice(index, 1);
      }
    };
  };

  public subscribeForCategory = (
    categoryId: string,
    callback: DragItemsObserverFunction<I>
  ) => {
    const subscribers = this._itemsSubscribers.get(categoryId) || [];

    subscribers.push(callback);

    this._itemsSubscribers.set(categoryId, subscribers);

    return () => {
      const index = subscribers.indexOf(callback);
      if (index !== -1) {
        subscribers.splice(index, 1);
      }
    };
  };

  public subscribeForCategoryStateChanges = (
    categoryId: string,
    callback: CategoryStateObserverFunction
  ) => {
    const subscribers = this._categoryStateSubscribers.get(categoryId) || [];

    subscribers.push(callback);
    this._categoryStateSubscribers.set(categoryId, subscribers);

    return () => {
      const index = subscribers.indexOf(callback);
      if (index !== -1) {
        subscribers.splice(index, 1);
      }
    };
  };

  private _fireCategoriesChanged = (categories: C[]) => {
    this._categoriesSubscribers.forEach((callback) => {
      callback(categories.concat(this._fixedCategories));
    });
  };

  private _fireItemsChanged = async (
    categoryId: string,
    items: I[],
    containerMinHeight?: number
  ) => {
    const subscribers = this._itemsSubscribers.get(categoryId) || [];

    const fixedItems = this._fixedItems.get(categoryId);

    const newItems = fixedItems ? items.concat(fixedItems) : items;

    subscribers.forEach((callback) => {
      callback(newItems, this._categoryItemOffsets[categoryId], containerMinHeight);
    });
  };

  private _fireCategoryStateChanged = (categoryId: string, state: CategoryState) => {
    const subscribers = this._categoryStateSubscribers.get(categoryId) || [];
    subscribers.forEach((callback) => {
      callback(state);
    });
  };

  // /**
  //  * Update item offsets that are used to position the items in the list in a way
  //  * that the drag will make a room for the dragged item to be placed to closest
  //  * position to the container. In practice, this means that the items next to it
  //  * must use offsets to move away from the dragged item.
  //  *
  //  * @param {string}  categoryId  The category id
  //  * @param {C[]}     items       The items in the category
  //  * @return {boolean}           True if the offsets have changed
  //  */
  // private _updateCategoryOffsets(
  //   categoryId: string,
  //   items: C[]
  // ) {
  //   const current = JSON.stringify(this._categoryItemOffsets[categoryId] ?? {});

  //   const minHeight = undefined;
  //   switch (this._state) {
  //     case 'dragging-category':
  //       // TODO: Implement
  //       throw new Error('Not implemented');

  //       // minHeight = this.updateItemDraggingStateOffsets(categoryId, items);
  //       break;

  //     default:
  //       throw new Error(`Invalid state: ${this._state}`);
  //   }
  //   // TODO: Make this more efficient
  //   const updated = JSON.stringify(this._categoryItemOffsets[categoryId] ?? {});

  //   return {
  //     changed: current !== updated,
  //     minHeight,
  //   };
  // }

  /**
   * Update item offsets that are used to position the items in the list in a way
   * that the drag will make a room for the dragged item to be placed to closest
   * position to the container. In practice, this means that the items next to it
   * must use offsets to move away from the dragged item.
   *
   * @param {string}  categoryId  The category id
   * @param {I[]}     items       The items in the category
   * @return {boolean}           True if the offsets have changed
   */
  private _updateItemOffsets(
    categoryId: string,
    items: I[]
  ) {
    const current = JSON.stringify(this._categoryItemOffsets[categoryId] ?? {});

    let minHeight = undefined;
    switch (this._state) {
      case 'idle':
        this._resetItemOffsets(categoryId, items);
        break;

      case 'dragging-item':
        minHeight = this.updateItemDraggingStateOffsets(categoryId, items);
        break;

      default:
        throw new Error(`Invalid state: ${this._state}`);
    }
    // TODO: Make this more efficient
    const updated = JSON.stringify(this._categoryItemOffsets[categoryId] ?? {});

    return {
      changed: current !== updated,
      minHeight,
    };
  }

  /**
   * Update item offsets that are used to position the items in the list in a way
   * that the drag will make a room for the dragged item to be placed to closest
   * position to the container. In practice, this means that the items next to it
   * must use offsets to move away from the dragged item.
   *
   * @param {string} categoryId   The category id
   * @param {i[]}    items        The items in the category
   */
  private _resetItemOffsets(categoryId: string, items: I[]) {
    this._categoryItemOffsets[categoryId] = items.reduce((acc, item) => {
      return {
        ...acc,
        [item.itemId]: {
          ...zeroOffset,
        },
      };
    }, {});
  }

  /**
   * Update item offsets that are used to position the items in the list in a way
   * that the drag will make a room for the dragged item to be placed to closest
   * position to the container. In practice, this means that the items next to it
   * must use offsets to move away from the dragged item.
   * @param {string}  categoryId  The category id
   * @param {I[]}     items       The items in the category
   * @return {number} The minimum height of the container
   */
  private updateItemDraggingStateOffsets(categoryId: string, items: I[]) {
    if (this._dragItemStartCategoryId === this._targetCategoryId) {
      this.updateOffsetsWhenDraggingInsideCategory(categoryId, items);
      return;
    }
    if (categoryId !== this._targetCategoryId && categoryId !== this._dragItemStartCategoryId) {
      this._resetItemOffsets(categoryId, items);
      return;
    }
    return this._updateItemOffsetsWhenDraggingBetweenCategories(categoryId, items);
  }

  /**
   * Offsets will open a new place for dropping the dragged item in the target category. The origin
   * category will remove the place of dragged item by offsets. This way user will always know
   * where the drop will be targeted.
   * @param {string}  categoryId  The category id
   * @param {I[]}     items       The items in the category
   * @return {number} The minimum height of the container
   */
  private _updateItemOffsetsWhenDraggingBetweenCategories = (categoryId: string, items: I[]) => {
    if (!this._dragItemStartCategoryId) {
      throw new Error('No drag start category id defined.');
    }
    if (!this._draggedItemId) {
      throw new Error('No dragged item id defined.');
    }

    const draggedBounds = this._getItemBounds(this._draggedItemId);
    if (!draggedBounds) {
      throw new Error(`No dragged item boundaries defined for item ${this._draggedItemId}`);
    }

    const _updateStartDragCategoryOffsets = () => {
      let minHeight = 0;

      this._categoryItemOffsets[categoryId] = items.reduce((acc, item, index) => {
        let y = 0;
        if (index >= this._dragItemStartIndex) {
          y -= draggedBounds.height;
        }
        const bounds = this._getItemBounds(item.itemId);
        minHeight += bounds?.height ?? 0;
        return {
          ...acc,
          [item.itemId]: {
            x: 0,
            y,
          },
        };
      }, {});

      return minHeight;
    };
    if (this._dragItemStartCategoryId === categoryId) {
      return _updateStartDragCategoryOffsets();
    }

    const resolveDragItemIndex = () => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        const bound = this._getItemBounds(item.itemId);
        if (!bound) {
          return items.length;
        }
        if (draggedBounds.centerY < bound.centerY) {
          return i;
        }
      }
      return items.length;
    };

    this._currentDragDropIndex = resolveDragItemIndex();

    let minHeight = 0;

    this._categoryItemOffsets[categoryId] = items.reduce((acc, item, index) => {
      let y = 0;
      if (this._currentDragDropIndex && index >= this._currentDragDropIndex) {
        y += draggedBounds.height;
        minHeight += draggedBounds.height;
      }
      const bounds = this._getItemBounds(item.itemId);
      minHeight += bounds?.height ?? 0;
      return {
        ...acc,
        [item.itemId]: {
          x: 0,
          y,
        },
      };
    }, {});

    return minHeight;
  };

  /**
   * Offsets will open a new place for dropping the dragged item inside the origin category.
   * @param {string}  categoryId  The category id
   * @param {I[]}     items       The items in the category
   */
  private updateOffsetsWhenDraggingInsideCategory(categoryId: string, items: I[]) {
    if (!this._dragItemStartCategoryId) {
      throw new Error('No drag start category id defined.');
    }
    if (!this._draggedItemId) {
      throw new Error('No dragged item id defined.');
    }

    const draggedBounds = this._getItemBounds(this._draggedItemId);
    if (!draggedBounds) {
      throw new Error(`No dragged item boundaries defined for item ${this._draggedItemId} (category: ${categoryId}).`);
    }
    const currentDragItemIndex = this._dragItemStartCategoryId !== categoryId ?
      items.length :
      items.findIndex((item) => item.itemId === this._draggedItemId);

    this._categoryItemOffsets[categoryId] = items.reduce((acc, item, index) => {
      let y = 0;
      if (this._targetCategoryId &&
        this._targetCategoryId !== this._dragItemStartCategoryId &&
        categoryId === this._dragItemStartCategoryId
      ) {
        if (currentDragItemIndex <= index && Math.abs(currentDragItemIndex - index) < 1) {
          y -= draggedBounds.height;
        }
      } else if (item.itemId !== this._draggedItemId && Math.abs(currentDragItemIndex - index) < 1) {
        if (index > this._dragItemStartIndex) {
          y -= draggedBounds.height;
        }
        if (index > currentDragItemIndex) {
          y += draggedBounds.height;
        }
      }

      return {
        ...acc,
        [item.itemId]: {
          x: 0,
          y,
        },
      };
    }, {});
  }

  offset = (id: string) => {
    return this._categoryItemOffsets[id] ?? {
      x: 0, y: 0,
    };
  };

  private _getCategoryBounds = (categoryId: string): InternalBounds | undefined => {
    if (this._state === 'dragging-category' && categoryId !== this._dragItemStartCategoryId) {
      return this._draggedCategoryBoundaries[categoryId];
    }
    const ref = this._categoryBoundaries[categoryId];
    if (!ref?.current) {
      return undefined;
    }
    if (this._categoryAnimating[categoryId]) {
      return this._lastCategoryBoundsBeforeAnimation[categoryId];
    }

    const rect = ref.current.getBoundingClientRect();
    const bounds = {
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
      centerX: rect.x + rect.width / 2,
      centerY: rect.y + rect.height / 2,
    };
    this._lastCategoryBoundsBeforeAnimation[categoryId] = bounds;

    return bounds;
  };

  private _cache: { [key: string]: InternalBounds; } = {};

  private _getItemBounds = (itemId: string): InternalBounds | undefined => {
    if (this._state === 'dragging-item' && itemId !== this._draggedItemId) {
      return this._draggedItemBoundaries[itemId];
    }
    const ref = this._itemBoundaries[itemId];
    if (!ref?.current) {
      return this._cache[itemId];
    }
    const rect = ref.current.getBoundingClientRect();

    return this._cache[itemId] = {
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
      centerX: rect.x + rect.width / 2,
      centerY: rect.y + rect.height / 2,
    };
  };

  /**
   * Boundaries of the whole categorize component
   * @return {InternalBounds} Viewport boundaries
   */
  private getViewPortBounds = (): InternalBounds => {
    const rect = this._viewPortRef.current?.getBoundingClientRect();
    if (!rect) {
      return {
        x: 0,
        y: 0,
        width: 1200,
        height: 1000,
        top: 0,
        right: 1200,
        bottom: 1000,
        left: 0,
        centerX: 600,
        centerY: 500,
      };
    }
    return {
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
      centerX: rect.x + rect.width / 2,
      centerY: rect.y + rect.height / 2,
    };
  };
}
