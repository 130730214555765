import { BlocQuery } from '@mindhiveoy/bloc/BlocQuery';
import { createUseBlocHook } from '@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import { eraseAnswerData } from './answerApi';
import { validateSessionProps } from '@shared/schema/src';
import type { Answer, CanvasParams } from '@shared/schema/src';
import type { BlocErrorFunction } from '@mindhiveoy/bloc/types';
import type { WithId } from '@mindhiveoy/schema';

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @template {string} MemberRoleId
 * @template {MemberShip} AppMember
 */
export class AnswersBloc extends BlocQuery<WithId<Answer>, CanvasParams> {
  /**
   *
   * @param {CanvasParams}  params  Params to point out the Canvas.
   * @param {ErrorFunction} onError Error listener
   */
  constructor(
    params: CanvasParams,
    onError?: BlocErrorFunction
  ) {
    validateSessionProps(params, 'canvasId');
    const {
      spaceId, projectId, sessionId, canvasId,
    } = params;

    super({
      collectionPath:
        `spaces/${spaceId
        }/projects/${projectId
        }/sessions/${sessionId
        }/canvases/${canvasId
        }/answers`,
      params,
      onError,
    });
  }

  public eraseAllAnswers = async (): Promise<void> => {
    const {
      spaceId, projectId, sessionId, canvasId,
    } = this.params;
    await eraseAnswerData({
      spaceId,
      projectId,
      sessionId,
      canvasId,
    });
  };
}

export const useAnswers =
  createUseBlocHook<WithId<Answer>, CanvasParams, AnswersBloc>(
    ({
      params,
      onError,
    }) => new AnswersBloc(params, onError)
  );
