import AppDrawer from '../../components/layout/AppDrawer';
import ContactUsDialog from 'components/contact/ContactUsDialog';
import MainContent from '../../components/layout/MainContent';
import Navbar from '../../components/layout/Navbar';
import React from 'react';
import UserProfileDrawer from './AppDrawer/UserProfileDrawer';
import type { PropsWithChildren } from 'react';

export interface DefaultScreenLayoutProps {
  hideFade?: boolean;
  controls?: (JSX.Element | null)[] | null;
}
/**
 * Default screen layout for the application. This layout will render the main content of the
 * application with the navbar, user profile component and drawers.
 *
 * @param {DefaultScreenLayoutProps}  props The component props
 */
const DefaultScreenLayout = ({
  controls,
  hideFade,
  children,
}: PropsWithChildren<DefaultScreenLayoutProps>) => {
  return <>
    <AppDrawer />
    <UserProfileDrawer />
    <Navbar
      controls={controls}
      hideFade={hideFade}
    />
    <MainContent>
      {children}
    </MainContent>
    <ContactUsDialog />
  </>;
};

export default DefaultScreenLayout;
