/* eslint-disable sonarjs/no-duplicate-string */
import {
  Image, StepComponentContainer, StepContent, StepText, StepTextContainer
} from './OnboardingDialog';
import { useTranslation } from 'next-i18next';

const Onboarding4 = () => {
  const {
    t,
  } = useTranslation();

  return <StepContent>
    <StepComponentContainer>
      <Image
        src='/images/onboarding/Navigation.png' />
    </StepComponentContainer>

    <StepTextContainer>
      <StepText>
        {t('move-to-other-questions-by-tabbing-the-arrow-icon-at-the-bottom-of-the-screen')}.
      </StepText>
    </StepTextContainer>

    <StepComponentContainer>
      <Image src='/images/onboarding/Cards.png' />
    </StepComponentContainer>

    <StepTextContainer>
      <StepText>
        {t('expand-to-detailed-view-by-clicking-the-arrow')}.
      </StepText>
    </StepTextContainer>
  </StepContent>;
};
export default Onboarding4;
