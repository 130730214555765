
export const knobTransition = {
  x: {
    type: 'spring',
    stiffness: 1,
  },
  y: {
    type: 'spring',
    stiffness: 1,
  },
};

export const knobTapStyle = {
  scale: 1.08,
  opacity: 0.9,
  cursor: 'grabbing',
  z: 1,
};

export const knobHoverStyle = {
  scale: 1.05,
  z: 1,
};

export const knobDragTransition = {
  bounceStiffness: 600,
  bounceDamping: 10,
};
