import { AuthError } from '@mindhiveoy/auth';
import { AuthErrorCodes } from 'firebase/auth';
import type { AuthError as FirebaseAuthError } from 'firebase/auth';

/**
 * Converts firebases authentication errors to mindcloud's auth error codes.
 * Please use the AuthErrorCodes found from Firebase package to make sure that no typos happen.
 * @param {FirebaseAuthError} error Object from firebase authentication error.
 */
export const raiseAsAuthError = (error: FirebaseAuthError) => {
  switch (error.code) {
    case AuthErrorCodes.INVALID_PASSWORD:
    case AuthErrorCodes.INVALID_EMAIL:
    case AuthErrorCodes.USER_DELETED:
      throw new AuthError(
        'auth_wrong_password_or_email',
        'Wrong email address or password'
      );
    case AuthErrorCodes.WEAK_PASSWORD:
      throw new AuthError(
        'auth_too_weak_password',
        'Too weak password'
      );
    case 'auth_email-already-exists':
    case AuthErrorCodes.EMAIL_EXISTS:
      // TODO change the error code to correct one when pr is merged to mindcloud
      throw new AuthError('auth_internal_error', 'The email address is already in use by another account.');
    // TODO Review required error codes and make the mapping
    default:
      throw new AuthError(
        'auth_internal_error',
        error.message
      );
  }
};
