import type { Variants } from 'framer-motion';

export const cardsVariants: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.4,
      staggerChildren: .5,
      delayChildren: .5,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.4,
      when: 'beforeChildren',
      delayChildren: 0.3,
      staggerChildren: 0.3,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.7,
    transition: {
      duration: 0.4,
      delayChildren: 0.3,
      staggerChildren: 0.3,
      when: 'afterChildren',
    },
  },
};

export const formVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const cardVariants: Variants = {
  hidden: {
    opacity: 0,
    rotateX: 10,
    rotateZ: 5,
    scale: 0.9,
    transition: {
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    rotateX: 0,
    rotateZ: 0,
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.9,
    transition: {
      duration: 0.3,
    },
  },
};

export const commentContainerVariants: Variants = {
  show: {
    transition: {
      staggerChildren: 0.3,
    },
  },
};

export const commentVariants: Variants = {
  hidden: {
    opacity: 0,
    scaleY: 0.85,
  },
  show: {
    opacity: 1,
    scaleY: 1,
    transition: {
      duration: 0.2,
    },
  },
  exit: {
    opacity: 0,
    scaleY: 0.85,
    transition: {
      duration: 0.2,
    },
  },
};

export const hoverTabVariants: Variants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.2,
    },
  },
  tap: {
    scale: 0.95,
    opacity: 0.8,
    transition: {
      duration: 0.2,
    },
  },
};
