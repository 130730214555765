const DEFAULT_TTL = 1000 * 60 * 60 * 24; // 24 hours

// TODO: Move to the @mindcloud project
/**
 * Sets data in local storage with an expiration time.
 * @param {string} key - The key under which to store the data.
 * @param {any} value - The data to store.
 * @param {number} ttl - Time to live in milliseconds.
 */
export const setStorageValueWithExpiry = <T = string>(key: string, value: T, ttl = DEFAULT_TTL): void => {
  if (typeof localStorage === 'undefined') {
    return;
  }
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

/**
* Retrieves data from local storage and checks if it has expired.
* @param {string} key - The key of the data to retrieve.
* @returns {any | null} The retrieved data or null if not found or expired.
*/
export const getStorageValueWithExpiry = <T = string>(key: string): T | null => {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value as T;
};

/**
 * Removes a value from local storage.
 * @param key
 * @returns
 */
export const removeStorageValue = (key: string): void => {
  if (typeof localStorage === 'undefined') {
    return;
  }
  localStorage.removeItem(key);
};
