import PasswordValidator from 'password-validator';

const passwordSchema = new PasswordValidator();

/**
 * Password schema for validating password strength.
 *
 * Password must be at least 8 characters long and contain at least one uppercase letter,
 * one lowercase letter and one digit.
 */
passwordSchema
  .is().min(8) // Minimum length 8
  .is().max(30) // Maximum length 30
  .has().uppercase() // Must have uppercase letters
  .has().lowercase() // Must have lowercase letters
  .has().digits(1) // Must have at least 2 digits
  .has().not().spaces() // Should not have spaces
  .is().not().oneOf(['Passw0rd', 'Password123', 'password',]); // Blacklist these values

export default passwordSchema;
