/* eslint-disable valid-jsdoc */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { BuilderComponentId, BuilderComponentType } from '../widgets/PageWidgetRenderer';
import type { FC, FunctionComponent, ReactNode } from 'react';
import type { PropertyEditor, PropertyEditorBase } from '../propertyEditors/PropertyEditorTypes';
import type { PropertyEditorProps } from '../propertyEditors/PropertyEditorTypes';

export type PropertyEditorType = string;

export interface PropertyEditorFunction<
  T,
  C extends PropertyEditorBase<T>
> extends FunctionComponent<PropertyEditorProps<T, C>> {
  config: PropertyEditor;
}

/**
 * Property editor builder to control use of different property editors
 */
class PropertyEditorBuilder {
  private propertyEditors = new Map<string, FC<any>>();

  private propertyConfigs = new Map<string,
    PropertyEditor>();
  /**
   * Get a property editor react component for given type.
   *
   * @param   {BuilderComponentType} type
   * @return {FC<PropertyEditorProps>}
   */
  public get = (type: PropertyEditorType): FC<PropertyEditorProps> => {
    return this.propertyEditors.get(type) as FC<PropertyEditorProps>;
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  public render = (type: PropertyEditorType, id: BuilderComponentId, props: {}): ReactNode => {
    const Component = this.get(type) as any;
    if (!Component) {
      return null;
    }
    return <Component {...props} />;
  };

  /**
   * Get a configuration for a property editor type
   * @param type
   * @returns
   */
  public getConfig = (type: BuilderComponentType) => {
    return this.propertyConfigs.get(type);
  };

  public register = (
    component: PropertyEditorFunction<any, any>
  ) => {
    const config = component?.config;
    if (!config) {
      throw new Error(`Component ${component.name} needs to have a static config designed to be able to register.`);
    }
    // const type = resolveConfigType(config);
    const {
      type,
    } = config;
    if (!type) {
      throw new Error(`PropertyEditor requires an unique type name to be defined for all registered components. (${component.name})`);
    }
    if (this.propertyEditors.has(type)) {
      throw new Error(`Type ${type} is already registered.`);
    }
    this.propertyConfigs.set(type, config);
    this.propertyEditors.set(type, component);
  };
}

export const propertyEditorBuilder = new PropertyEditorBuilder();

export default propertyEditorBuilder;
