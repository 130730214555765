/* eslint-disable @typescript-eslint/no-explicit-any */
import { ListenerHelper } from '../../../utils/listener/listenerHelper';
import { ListenerSetHelper } from '../../../utils/listener/ListenerSetHelper';
import type {
  BlocDocumentSnapshot,
  BlocErrorFunction, BlocQueryConstraint, BlocUnsubscribeFunction,
  DatabaseControllerDocumentSnapshotListener as DatabaseControllerDocumentSnapshot,
  DatabaseControllerQuerySnapshotListener
} from '../types';
import type { DocumentConverter } from './DocumentConverter';
import type { DocumentData } from '@mindhiveoy/firebase-schema';
import type { DocumentSnapshot, QuerySnapshot } from 'firebase/firestore';

export interface DatabaseControllerFactoryFunctionArgs {
  /**
   * Collection path for what the controller is being created.
   */
  collectionPath: string;
  /**
   * Possible delay in milliseconds before changes will be saved by the controller. This is being used as
   * optimization to batch changes while editing to bigger chunks to improve performance and save transaction costs
   */
  saveDelay?: number;
}

interface SortFieldDescription<T extends DocumentData> {
  field: keyof T;
  order?: 'asc' | 'desc';
}

const FIELD_VALUE_ARRAY_UNION = Symbol('array-union');
const FIELD_VALUE_ARRAY_REMOVE = Symbol('array-remove');
const FIELD_VALUE_DELETE = Symbol('delete');
const FIELD_VALUE_DECREMENT = Symbol('decrement');
const FIELD_VALUE_INCREMENT = Symbol('increment');
const FIELD_VALUE_SERVER_TIME = Symbol('server-time');

export type FieldValueTypes =
  typeof FIELD_VALUE_ARRAY_UNION |
  typeof FIELD_VALUE_ARRAY_REMOVE |
  typeof FIELD_VALUE_DELETE |
  typeof FIELD_VALUE_DECREMENT |
  typeof FIELD_VALUE_INCREMENT |
  typeof FIELD_VALUE_SERVER_TIME;

interface WhereRuleDescription<T extends DocumentData> {
  field: keyof T;
  operator:
    '==' | '!=' |
    '>' | '<' |
    '<=' | '>=' |
    'array-contains' | 'array-contains-any' |
    'in' | 'not-in';
  value?: T[keyof T] | FieldValueTypes
}

export interface WithCollectionQueryParams<T extends DocumentData> {
  sort?: SortFieldDescription<T>[];
  where?: WhereRuleDescription<T>[];
  maxDocuments?: number;
}

export type DocumentSnapshotListener<T extends DocumentData> = (snapshot: DocumentSnapshot<T>) => void;
export type CollectionSnapshotListener<T extends DocumentData> = (snapshot: QuerySnapshot<T>) => void;

/**
 * Factory function to create a new instance of DatabaseController.
 */
export type DatabaseControllerFactoryFunction =
  <T extends DocumentData>(
    args?: DatabaseControllerFactoryFunctionArgs
  ) => DatabaseController<T>;

/**
 * Database controller operates with the back end database. It will alter the data
 * and trigger possible changes coming upstream from the backend.
 */
abstract class DatabaseController<T extends DocumentData> {
  protected documentSnapshotListeners = new ListenerSetHelper<string, DatabaseControllerDocumentSnapshot<T>>();
  protected collectionSnapshotListeners = new ListenerHelper<DatabaseControllerQuerySnapshotListener<T>>();
  protected convert!: DocumentConverter<T, any>;
  /**
   *
   * @param {string[]} ignoredFields
   */
  constructor(
    public readonly ignoredFields: string[] = []
  ) {
  }

  /**
   * Set the document converted used in controller.
   * @param {DocumentConverter<T>} converter The converter to use.
   */
  public setDocumentConverter = (converter: DocumentConverter<T, any>) => {
    this.convert = converter;
  };

  /**
   * Release system resource and validate data state on shut down.
   */
  public abstract dispose(): void;

  /**
   * Create query snapshot listener
   * @param query
   */
  public abstract onQuerySnapshot(
    query: BlocQueryConstraint[],
    onSnapshot: DatabaseControllerQuerySnapshotListener<T>,
    onError: BlocErrorFunction
    // TODO: Fields on interest
  ): BlocUnsubscribeFunction;

  /**
   * Create document snapshot listener.
   * @param docId
   * @param onSnapshot
   */
  public abstract onDocumentSnapshot(
    docId: string,
    onSnapshot: DatabaseControllerDocumentSnapshot<T>,
    onError: BlocErrorFunction
  ): BlocUnsubscribeFunction;
  /**
   * Fetch a single document from the database.
   * @param {string}  docId Document id
   */
  public abstract fetchDoc(docId: string): Promise<BlocDocumentSnapshot<T>>;
}

export default DatabaseController;
