export type Coord2D = {
  x: number;
  y: number;
};

export const key2d = ({
  x, y,
}: Coord2D): string => {
  return `${y}_${x}`;
};

export const coord2DFromKey = (key: string): {
  x: number,
  y: number,
} => {
  const values = key.split('_');
  return {
    x: parseFloat(values[1]),
    y: parseFloat(values[0]),
  };
};
