/* eslint-disable @next/next/no-img-element */
/* eslint-disable require-jsdoc */
/* eslint-disable security/detect-non-literal-regexp */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  LexicalTypeaheadMenuPlugin,
  useBasicTypeaheadTriggerMatch
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import type { MenuRenderFn } from '@lexical/react/LexicalTypeaheadMenuPlugin';

import { useCallback, useMemo, useState } from 'react';
import type { TextNode } from 'lexical';

import { $createMentionNode } from '../../nodes/MentionNode';
import { MentionTypeaheadOption } from './MentionTypeaheadOption';
import { MentionsTypeaheadMenuItem } from './MentionsTypeaheadMenuItem';
import { getPossibleQueryMatch } from './getPossibleQueryMatch';
import { muteEvent } from 'utils/browserEvents';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

const PUNCTUATION =
  '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';

const NAME = `\\b[A-Z][^\\s${PUNCTUATION}]`;

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

export const CapitalizedNameMentionsRegex = new RegExp(
  `(^|[^#])((?:${DocumentMentionsRegex.NAME}{${1},})$)`
);

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ['@',].join('');

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = `[^${TRIGGERS}${PUNC}\\s]`;

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  `(?:` +
  `\\.[ |$]|` + // E.g. "r. " in "Mr. Smith"
  ` |` + // E.g. " " in "Josh Duck"
  `[${PUNC
  }]|` + // E.g. "-' in "Salier-Hellendag"
  `)`;

const LENGTH_LIMIT = 75;

export const AtSignMentionsRegex = new RegExp(
  `(^|\\s|\\()(` +
  `[${TRIGGERS
  }]` +
  `((?:${VALID_CHARS
  }${VALID_JOINS
  }){0,${LENGTH_LIMIT
  }})` +
  `)$`
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
export const AtSignMentionsRegexAliasRegex = new RegExp(
  `(^|\\s|\\()(` +
  `[${TRIGGERS
  }]` +
  `((?:${VALID_CHARS
  }){0,${ALIAS_LENGTH_LIMIT
  }})` +
  `)$`
);

// At most, 15 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 15;

export interface MentionSuggestion {
  name: string;
  uid: string;
  imageUrl: string;
}

interface MentionsPluginProps {
  onLookupMentions?: (query: string) => MentionSuggestion[];
}

export const MentionsPlugin = ({
  onLookupMentions,
}: MentionsPluginProps) => {
  const [editor,] = useLexicalComposerContext();
  const [queryString, setQueryString,] = useState<string | null>(null);

  const [results, setResults,] = useState<MentionSuggestion[]>([]);

  React.useEffect(() => {
    if (onLookupMentions) {
      setResults(onLookupMentions(queryString || ''));
    }
  }, [queryString, onLookupMentions,]);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });

  const options = useMemo(
    () =>
      results
        .map(
          (result) =>
            new MentionTypeaheadOption(result.name, result.uid, <img
              alt={result.name}
              src={result.imageUrl}
              width={24}
              height={24} />)
        )
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT),
    [results,]
  );

  const onSelectOption = useCallback(
    (
      selectedOption: MentionTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(selectedOption.name, selectedOption.uid);
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        mentionNode.select();
        closeMenu();
      });
    },
    [editor,]
  );

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const mentionMatch = getPossibleQueryMatch(text);
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      return !slashMatch && mentionMatch ? mentionMatch : null;
    },
    [checkForSlashTriggerMatch, editor,]
  );

  const onRenderMenu: MenuRenderFn<MentionTypeaheadOption> = useCallback(
    (
      anchorElementRef,
      {
        selectedIndex,
        selectOptionAndCleanUp,
        setHighlightedIndex,
      }
    ) => {
      return anchorElementRef.current && results.length ?
        ReactDOM.createPortal(
          <div
            className="typeahead-popover mentions-menu"
            onMouseDown={muteEvent}
          >
            <ul>
              {options.map((option, i: number) => {
                return <MentionsTypeaheadMenuItem
                  index={i}
                  isSelected={selectedIndex === i}
                  onClick={() => {
                    setHighlightedIndex(i);
                    selectOptionAndCleanUp(option);
                  }}
                  onMouseEnter={() => {
                    setHighlightedIndex(i);
                  }}
                  key={option.key}
                  option={option} />;
              })}
            </ul>
          </div>,
          anchorElementRef.current
        ) as any :
        null;
    }, [options, results.length,]);

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      anchorClassName="mentions-menu-anchor"
      options={options}
      menuRenderFn={onRenderMenu
      }
    />
  );
};

export default MentionsPlugin;
