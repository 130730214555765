import { AnimatePresence, animate, motion, useMotionValue } from 'framer-motion';
import { RenderHTML } from './RenderHTML';
import {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'next-i18next';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DOMPurify from 'dompurify';
import styled from '@emotion/styled';
import type { CSSProperties } from 'react';

const ReadMoreText = styled.a(({
  theme,
}) => `
    display: flex;
    padding: 0.2rem;
    cursor: pointer;
    background-color: ${theme.palette.background.default}80;
    float: right;
    z-index: 1;
    &: hover {
      color: ${theme.palette.text.secondary};
    }
  `);

type IconTypes = 'small' | 'medium' | 'large';

export interface RenderHTMLProps {
  html: string;
  contentStyles?: CSSProperties;
}

interface ReadMoreProps {
  /**
   * The complete text content to show
   */
  text?: string;

  /**
   * Number of characters after which the read more -text is shown
   */
  length?: number;
  /**
   * Read more arrow icon size
   */
  iconSize?: IconTypes;
  /**
   * Styles to customize the actual text content
   */
  contentStyles?: CSSProperties;
  /**
   * Styles to customize the read more -text
   */
  readMoreStyles?: CSSProperties;
}

const Container = styled(motion.div)`
  position: relative;
  overflow-y: hidden;
`;

const ReadMoreArea = styled(motion.div)`
  float: right;
  clear: right;
  overflow: hidden;
  z-index: 1;
  position: sticky;
  border-radius: 4px;
  @media (max-width: 600px) {
    font-size: 0.8rem;
  }
`;

const ChevronContainer = styled(motion.div)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform-origin: center;
`;

const contentStyle = {
  textOverflow: 'ellipsis',
};

const lessStyle: any = {
  animate: {
    height: '8.8rem',
  },
  chevron: {
    rotate: 180,
  },
};

const moreStyle: any = {
  animate: {
    height: 'auto',
    rotateZ: 0,
  },
  chevron: {
    rotate: 0,
  },
};

/**
 * A smart component for showing rich text content
 * with a read more/less -functionality.
 * Accepts both plain text or text with HTML-tags,
 * which are stripped and ignored when counting the
 * characters. Text sanitization is implemented to
 * prevent cross-site scripting.
 *
 * @param {string} text Rich text to be shown
 * @param {number} length Number of shown characters. Default is 400.
 * @param {IconTypes} iconSize Read more -icon size variants
 * @param {CSSProperties} contentStyles Styles applied to text content
 * @param {CSSProperties} readMoreStyles Styles applied to read more -text
 * @return {JSX.Element} Content to be rendered
 */
const ReadMore = ({
  text = '',
  length = 400,
  iconSize = 'small',
  contentStyles,
}: ReadMoreProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const readMoreRef = useRef<HTMLDivElement>(null);

  const {
    strippedText, sanitizedText,
  } = useMemo(() => {
    const sanitizedText = DOMPurify.sanitize(text, {
      ADD_ATTR: ['target',],
    });

    const strippedText = sanitizedText.replace(/<\/?[^>]+(>|$)/g, '');
    return {
      sanitizedText,
      strippedText,
    };
  }, [text,]);

  const [showLess, setShowLess,] = useState<boolean>(true);
  const readMoreHeight = useMotionValue<number>(0);

  const {
    t,
  } = useTranslation();

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver(() => {
        if (!(ref.current && readMoreRef.current)) {
          return;
        }
        animate(readMoreHeight, ref.current.clientHeight - readMoreRef.current.clientHeight);
      });

      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [readMoreHeight,]);

  const readMoreOnClick = useCallback((): void => {
    setShowLess(!showLess);
  }, [showLess,]);

  const readMoreStyle = useMemo(() => {
    return {
      float: 'right',
      height: readMoreHeight,
    } as unknown as CSSProperties;
  }, [readMoreHeight,]);

  // const renderContentBlock = useCallback(({
  //   showLess,
  //   textToRender,
  // }: { showLess: boolean, textToRender: string }): JSX.Element => {
  //   return ;
  // }, [iconSize, readMoreOnClick, readMoreStyle, t,]);

  if (strippedText.length < length) {
    return <RenderHTML contentStyles={contentStyles}
      html={sanitizedText} />;
  }

  return <AnimatePresence initial={false}>
    <Container
      ref={ref}
      key="read-more"
      data-testid='read-more'
      animate={showLess ? lessStyle.animate : moreStyle.animate}
      transition={{
        duration: 0.3,
        ease: 'easeOut',
      }}
    >
      <motion.div
        style={readMoreStyle}
        animate={{
          height: showLess ? '7.01rem' : '90%',
        }}
      />
      <ReadMoreArea
        ref={readMoreRef}
      >
        <ReadMoreText onClick={readMoreOnClick}>
          <ChevronContainer
            animate={{
              rotate: showLess ? 180 : 0,
            }}
            transition={{
              duration: 0.3,
            }}
          >
            <ArrowDropUpIcon fontSize={iconSize} />
          </ChevronContainer>
          [{t('read')} {showLess ? t('more') : t('less')}]
        </ReadMoreText>
      </ReadMoreArea>
      <RenderHTML
        contentStyles={contentStyle}
        html={sanitizedText}
      />
    </Container>
  </AnimatePresence>;

  // return renderContentBlock({
  //   showLess, textToRender: sanitizedText,
  // } );
};

export default ReadMore;
