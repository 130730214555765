import styled from '@emotion/styled';

export const Legend = styled.legend(({
  theme,
}) => `
  padding: 0 ${theme.spacing(0.5)};
  margin-left: ${theme.spacing(-0.5)};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  color: ${theme.palette.grey[500]};
`);
