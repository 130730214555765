import { cloneDeep } from 'lodash';

/**
 * Recursively clean out empty fields from the form data.
 * @param initialData
 */
export const preprocessFormData = <T extends Record<string, any>>(initialData: T): T => {
  const data = cloneDeep(initialData);

  const clean = (obj: Record<string, any>) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        clean(obj[key]);
      }
    });
  };

  clean(data);
  return data;
};
