import { bundleFunctionCall } from './bundleFunctionCall';
import type { HttpMessage } from '@shared/schema/src/https/HttpMessage';
import type { HttpsCallableOptions } from 'firebase/functions';

/**
 * Bundle factory is a helper function that which will help you to create
 * a bundle function call for a specific bundle.
 *
 * @param bundle  Bundle name
 * @returns       Function that will create a bundle function call for a specific message
 */
export const createBundleFunctionFactory = (
  bundle: string
) => {
  /**
   * Bundle function call is a helper function that will help you to create
   * @param type The actual message to be sent to the backend
   * @param options_ Options for the https callable function
   * @returns       Function that will call the backend with the given message
   */
  return <Message extends HttpMessage<any, any, any>>(message: Message['type'],
    options?: HttpsCallableOptions) => {
    return bundleFunctionCall<Message, Message['response']>(bundle, message, options);
  };
};
