import { FacebookAuthProvider, GoogleAuthProvider, getAuth } from 'firebase/auth';
import { FirebaseAuthPlatformAdapter } from 'components/auth/firebasePlatformAdapter';
import { firebaseApp } from 'schema';
import { getFirestore } from 'firebase/firestore';
import { platformEventSystem } from 'components/platformEvents/PlatformEventSystem';

/**
 * The Firebase Auth Platform Adapter that is used to interact with the Firebase Auth service.
*/
const createFirebaseAuthPlatformAdapter = () => {
  const app = firebaseApp();
  return new FirebaseAuthPlatformAdapter(
    getAuth(app),
    getFirestore(app as any),
    platformEventSystem,
    {
      newGoogleAuthProvider: () => new GoogleAuthProvider(),
      newFacebookAuthProvider: () => new FacebookAuthProvider(),
    }
  );
};

export default createFirebaseAuthPlatformAdapter;
//
