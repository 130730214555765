import type { CountryInfo } from '.';

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countriesFI: readonly CountryInfo[] = [
  {
    code: 'AD',
    label: 'Andorra',
    phone: '376',
  },
  {
    code: 'AE',
    label: 'Yhdistyneet arabiemiirikunnat',
    phone: '971',
  },
  {
    code: 'AF',
    label: 'Afganistan',
    phone: '93',
  },
  {
    code: 'AG',
    label: 'Antigua ja Barbuda',
    phone: '1-268',
  },
  {
    code: 'AI',
    label: 'Anguilla',
    phone: '1-264',
  },
  {
    code: 'AL',
    label: 'Albania',
    phone: '355',
  },
  {
    code: 'AM',
    label: 'Armenia',
    phone: '374',
  },
  {
    code: 'AO',
    label: 'Angola',
    phone: '244',
  },
  {
    code: 'AQ',
    label: 'Antarktis',
    phone: '672',
  },
  {
    code: 'AR',
    label: 'Argentiina',
    phone: '54',
  },
  {
    code: 'AS',
    label: 'Amerikan Samoa',
    phone: '1-684',
  },
  {
    code: 'AT',
    label: 'Itävalta',
    phone: '43',
  },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
  },
  {
    code: 'AW',
    label: 'Aruba',
    phone: '297',
  },
  {
    code: 'AX',
    label: 'Ahvenanmaa',
    phone: '358',
  },
  {
    code: 'AZ',
    label: 'Azerbaidžan',
    phone: '994',
  },
  {
    code: 'BA',
    label: 'Bosnia ja Hertsegovina',
    phone: '387',
  },
  {
    code: 'BB',
    label: 'Barbados',
    phone: '1-246',
  },
  {
    code: 'BD',
    label: 'Bangladesh',
    phone: '880',
  },
  {
    code: 'BE',
    label: 'Belgia',
    phone: '32',
  },
  {
    code: 'BF',
    label: 'Burkina Faso',
    phone: '226',
  },
  {
    code: 'BG',
    label: 'Bulgaria',
    phone: '359',
  },
  {
    code: 'BH',
    label: 'Bahrain',
    phone: '973',
  },
  {
    code: 'BI',
    label: 'Burundi',
    phone: '257',
  },
  {
    code: 'BJ',
    label: 'Benin',
    phone: '229',
  },
  {
    code: 'BL',
    label: 'Saint Barthelemy',
    phone: '590',
  },
  {
    code: 'BM',
    label: 'Bermuda',
    phone: '1-441',
  },
  {
    code: 'BN',
    label: 'Brunei',
    phone: '673',
  },
  {
    code: 'BO',
    label: 'Bolivia',
    phone: '591',
  },
  {
    code: 'BR',
    label: 'Brasilia',
    phone: '55',
  },
  {
    code: 'BS',
    label: 'Bahama',
    phone: '1-242',
  },
  {
    code: 'BT',
    label: 'Bhutan',
    phone: '975',
  },
  {
    code: 'BV',
    label: 'Bouvet’nsaari',
    phone: '47',
  },
  {
    code: 'BW',
    label: 'Botswana',
    phone: '267',
  },
  {
    code: 'BY',
    label: 'Valko-Venäjä',
    phone: '375',
  },
  {
    code: 'BZ',
    label: 'Belize',
    phone: '501',
  },
  {
    code: 'CA',
    label: 'Kanada',
    phone: '1',
  },
  {
    code: 'CC',
    label: 'Kookossaaret',
    phone: '61',
  },
  {
    code: 'CD',
    label: 'Kongon demokraattinen tasavalta',
    phone: '243',
  },
  {
    code: 'CF',
    label: 'Keski-Afrikan tasavalta',
    phone: '236',
  },
  {
    code: 'CG',
    label: 'Kongon tasavalta',
    phone: '242',
  },
  {
    code: 'CH',
    label: 'Sveitsi',
    phone: '41',
  },
  {
    code: 'CI',
    label: 'Norsunluurannikko',
    phone: '225',
  },
  {
    code: 'CK',
    label: 'Cookinsaaret',
    phone: '682',
  },
  {
    code: 'CL',
    label: 'Chile',
    phone: '56',
  },
  {
    code: 'CM',
    label: 'Cameroon',
    phone: '237',
  },
  {
    code: 'CN',
    label: 'China',
    phone: '86',
  },
  {
    code: 'CO',
    label: 'Colombia',
    phone: '57',
  },
  {
    code: 'CR',
    label: 'Costa Rica',
    phone: '506',
  },
  {
    code: 'CU',
    label: 'Cuba',
    phone: '53',
  },
  {
    code: 'CV',
    label: 'Cape Verde',
    phone: '238',
  },
  {
    code: 'CW',
    label: 'Curaçao',
    phone: '599',
  },
  {
    code: 'CX',
    label: 'Joulusaari',
    phone: '61',
  },
  {
    code: 'CY',
    label: 'Kypros',
    phone: '357',
  },
  {
    code: 'CZ',
    label: 'Tšekki',
    phone: '420',
  },
  {
    code: 'DE',
    label: 'Saksa',
    phone: '49',
  },
  {
    code: 'DJ',
    label: 'Djibouti',
    phone: '253',
  },
  {
    code: 'DK',
    label: 'Tanska',
    phone: '45',
  },
  {
    code: 'DM',
    label: 'Dominica',
    phone: '1-767',
  },
  {
    code: 'DO',
    label: 'Dominikaaninen tasavalta',
    phone: '1-809',
  },
  {
    code: 'DZ',
    label: 'Algeria',
    phone: '213',
  },
  {
    code: 'EC',
    label: 'Ecuador',
    phone: '593',
  },
  {
    code: 'EE',
    label: 'Viro',
    phone: '372',
  },
  {
    code: 'EG',
    label: 'Egypti',
    phone: '20',
  },
  {
    code: 'EH',
    label: 'Western Sahara',
    phone: '212',
  },
  {
    code: 'ER',
    label: 'Eritrea',
    phone: '291',
  },
  {
    code: 'ES',
    label: 'Espanja',
    phone: '34',
  },
  {
    code: 'ET',
    label: 'Etiopia',
    phone: '251',
  },
  {
    code: 'FI',
    label: 'Suomi',
    phone: '358',
  },
  {
    code: 'FJ',
    label: 'Fidži',
    phone: '679',
  },
  {
    code: 'FK',
    label: 'Falklandinsaaret',
    phone: '500',
  },
  {
    code: 'FM',
    label: 'Mikronesian liittovaltio',
    phone: '691',
  },
  {
    code: 'FO',
    label: 'Färsaaret',
    phone: '298',
  },
  {
    code: 'FR',
    label: 'Ranska',
    phone: '33',
  },
  {
    code: 'GA',
    label: 'Gabon',
    phone: '241',
  },
  {
    code: 'GB',
    label: 'Iso-Britannia',
    phone: '44',
  },
  {
    code: 'GD',
    label: 'Grenada',
    phone: '1-473',
  },
  {
    code: 'GE',
    label: 'Georgia',
    phone: '995',
  },
  {
    code: 'GF',
    label: 'Ranskan Guayana',
    phone: '594',
  },
  {
    code: 'GG',
    label: 'Guernsey',
    phone: '44',
  },
  {
    code: 'GH',
    label: 'Ghana',
    phone: '233',
  },
  {
    code: 'GI',
    label: 'Gibraltar',
    phone: '350',
  },
  {
    code: 'GL',
    label: 'Grönlanti',
    phone: '299',
  },
  {
    code: 'GM',
    label: 'Gambia',
    phone: '220',
  },
  {
    code: 'GN',
    label: 'Guinea',
    phone: '224',
  },
  {
    code: 'GP',
    label: 'Guadeloupe',
    phone: '590',
  },
  {
    code: 'GQ',
    label: 'Päiväntasaajan Guinea',
    phone: '240',
  },
  {
    code: 'GR',
    label: 'Kreikka',
    phone: '30',
  },
  {
    code: 'GS',
    label: 'Etelä-Georgia ja Eteläiset Sandwichsaaret',
    phone: '500',
  },
  {
    code: 'GT',
    label: 'Guatemala',
    phone: '502',
  },
  {
    code: 'GU',
    label: 'Guam',
    phone: '1-671',
  },
  {
    code: 'GW',
    label: 'Guinea-Bissau',
    phone: '245',
  },
  {
    code: 'GY',
    label: 'Guyana',
    phone: '592',
  },
  {
    code: 'HK',
    label: 'Hongkong',
    phone: '852',
  },
  {
    code: 'HM',
    label: 'Heard ja McDonaldinsaaret',
    phone: '672',
  },
  {
    code: 'HN',
    label: 'Honduras',
    phone: '504',
  },
  {
    code: 'HR',
    label: 'Kroatia',
    phone: '385',
  },
  {
    code: 'HT',
    label: 'Haiti',
    phone: '509',
  },
  {
    code: 'HU',
    label: 'Unkari',
    phone: '36',
  },
  {
    code: 'ID',
    label: 'Indonesia',
    phone: '62',
  },
  {
    code: 'IE',
    label: 'Irlanti',
    phone: '353',
  },
  {
    code: 'IM',
    label: 'Mansaari',
    phone: '44',
  },
  {
    code: 'IN',
    label: 'Intia',
    phone: '91',
  },
  {
    code: 'IN',
    label: 'Intia',
    phone: '91',
  },
  {
    code: 'IO',
    label: 'Brittiläinen Intian valtameren alue',
    phone: '246',
  },
  {
    code: 'IQ',
    label: 'Irak',
    phone: '964',
  },
  {
    code: 'IR',
    label: 'Iran',
    phone: '98',
  },
  {
    code: 'IS',
    label: 'Islanti',
    phone: '354',
  },
  {
    code: 'IT',
    label: 'Italia',
    phone: '39',
  },
  {
    code: 'JE',
    label: 'Jersey',
    phone: '44',
  },
  {
    code: 'JM',
    label: 'Jamaika',
    phone: '1-876',
  },
  {
    code: 'JO',
    label: 'Jordania',
    phone: '962',
  },
  {
    'code': 'JP',
    'label': 'Japani',
    'phone': '81',
  },
  {
    code: 'KE',
    label: 'Kenia',
    phone: '254',
  },
  {
    code: 'KG',
    label: 'Kirgisia',
    phone: '996',
  },
  {
    code: 'KH',
    label: 'Kambodza',
    phone: '855',
  },
  {
    code: 'KI',
    label: 'Kiribati',
    phone: '686',
  },
  {
    code: 'KM',
    label: 'Komorit',
    phone: '269',
  },
  {
    code: 'KN',
    label: 'Saint Kitts ja Nevis',
    phone: '1-869',
  },
  {
    code: 'KP',
    label: 'Pohjois-Korea',
    phone: '850',
  },
  {
    code: 'KR',
    label: 'Etelä-Korea',
    phone: '82',
  },
  {
    code: 'KW',
    label: 'Kuwait',
    phone: '965',
  },
  {
    code: 'KY',
    label: 'Caymansaaret',
    phone: '1-345',
  },
  {
    code: 'KZ',
    label: 'Kazakstan',
    phone: '7',
  },
  {
    code: 'LA',
    label: 'Laos',
    phone: '856',
  },
  {
    code: 'LB',
    label: 'Libanon',
    phone: '961',
  },
  {
    code: 'LC',
    label: 'Saint Lucia',
    phone: '1-758',
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
    phone: '423',
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
    phone: '94',
  },
  {
    code: 'LR',
    label: 'Liberia',
    phone: '231',
  },
  {
    code: 'LS',
    label: 'Lesotho',
    phone: '266',
  },
  {
    code: 'LT',
    label: 'Liettua',
    phone: '370',
  },
  {
    code: 'LU',
    label: 'Luxemburg',
    phone: '352',
  },
  {
    code: 'LV',
    label: 'Latvia',
    phone: '371',
  },
  {
    code: 'LY',
    label: 'Libya',
    phone: '218',
  },
  {
    code: 'MA',
    label: 'Marokko',
    phone: '212',
  },
  {
    code: 'MC',
    label: 'Monaco',
    phone: '377',
  },
  {
    code: 'MD',
    label: 'Moldova',
    phone: '373',
  },
  {
    code: 'ME',
    label: 'Montenegro',
    phone: '382',
  },
  {
    code: 'MF',
    label: 'Saint Martin',
    phone: '590',
  },
  {
    code: 'MG',
    label: 'Madagaskar',
    phone: '261',
  },
  {
    code: 'MH',
    label: 'Marshallinsaaret',
    phone: '692',
  },
  {
    code: 'MK',
    label: 'Pohjois-Makedonia',
    phone: '389',
  },
  {
    code: 'ML',
    label: 'Mali',
    phone: '223',
  },
  {
    code: 'MM',
    label: 'Myanmar (Burma)',
    phone: '95',
  },
  {
    code: 'MN',
    label: 'Mongolia',
    phone: '976',
  },
  {
    code: 'MO',
    label: 'Macao',
    phone: '853',
  },
  {
    code: 'MP',
    label: 'Pohjois-Mariaanit',
    phone: '1-670',
  },
  {
    code: 'MQ',
    label: 'Martinique',
    phone: '596',
  },
  {
    code: 'MR',
    label: 'Mauritania',
    phone: '222',
  },
  {
    code: 'MS',
    label: 'Montserrat',
    phone: '1-664',
  },
  {
    code: 'MT',
    label: 'Malta',
    phone: '356',
  },
  {
    code: 'MU',
    label: 'Mauritius',
    phone: '230',
  },
  {
    code: 'MV',
    label: 'Malediivit',
    phone: '960',
  },
  {
    code: 'MW',
    label: 'Malawi',
    phone: '265',
  },
  {
    code: 'MX',
    label: 'Meksiko',
    phone: '52',
  },
  {
    code: 'MY',
    label: 'Malesia',
    phone: '60',
  },
  {
    code: 'MZ',
    label: 'Mosambik',
    phone: '258',
  },
  {
    code: 'NA',
    label: 'Namibia',
    phone: '264',
  },
  {
    code: 'NC',
    label: 'Uusi-Kaledonia',
    phone: '687',
  },
  {
    code: 'NE',
    label: 'Niger',
    phone: '227',
  },
  {
    code: 'NF',
    label: 'Norfolkinsaari',
    phone: '672',
  },
  {
    code: 'NG',
    label: 'Nigeria',
    phone: '234',
  },
  {
    code: 'NI',
    label: 'Nicaragua',
    phone: '505',
  },
  {
    code: 'NL',
    label: 'Alankomaat',
    phone: '31',
  },
  {
    code: 'NO',
    label: 'Norja',
    phone: '47',
  },
  {
    code: 'NP',
    label: 'Nepal',
    phone: '977',
  },
  {
    code: 'NR',
    label: 'Nauru',
    phone: '674',
  },
  {
    code: 'NU',
    label: 'Niue',
    phone: '683',
  },
  {
    code: 'NZ',
    label: 'Uusi-Seelanti',
    phone: '64',
  },
  {
    code: 'OM',
    label: 'Oman',
    phone: '968',
  },
  {
    code: 'PA',
    label: 'Panama',
    phone: '507',
  },
  {
    code: 'PE',
    label: 'Peru',
    phone: '51',
  },
  {
    code: 'PF',
    label: 'Ranskan Polynesia',
    phone: '689',
  },
  {
    code: 'PG',
    label: 'Papua-Uusi-Guinea',
    phone: '675',
  },
  {
    code: 'PH',
    label: 'Filippiinit',
    phone: '63',
  },
  {
    code: 'PK',
    label: 'Pakistan',
    phone: '92',
  },
  {
    code: 'PL',
    label: 'Puola',
    phone: '48',
  },
  {
    code: 'PM',
    label: 'Saint-Pierre ja Miquelon',
    phone: '508',
  },
  {
    code: 'PN',
    label: 'Pitcairn',
    phone: '870',
  },
  {
    code: 'PR',
    label: 'Puerto Rico',
    phone: '1',
  },
  {
    code: 'PS',
    label: 'Palestiinalaisalueet',
    phone: '970',
  },
  {
    code: 'PT',
    label: 'Portugali',
    phone: '351',
  },
  {
    code: 'PW',
    label: 'Palau',
    phone: '680',
  },
  {
    code: 'PY',
    label: 'Paraguay',
    phone: '595',
  },
  {
    code: 'QA',
    label: 'Qatar',
    phone: '974',
  },
  {
    code: 'RE',
    label: 'Réunion',
    phone: '262',
  },
  {
    code: 'RO',
    label: 'Romania',
    phone: '40',
  },
  {
    code: 'RS',
    label: 'Serbia',
    phone: '381',
  },
  {
    code: 'RU',
    label: 'Venäjä',
    phone: '7',
  },
  {
    code: 'RW',
    label: 'Ruanda',
    phone: '250',
  },
  {
    code: 'SA',
    label: 'Saudi-Arabia',
    phone: '966',
  },
  {
    code: 'SB',
    label: 'Salomonsaaret',
    phone: '677',
  },
  {
    code: 'SC',
    label: 'Seychellit',
    phone: '248',
  },
  {
    code: 'SD',
    label: 'Sudan',
    phone: '249',
  },
  {
    code: 'SE',
    label: 'Ruotsi',
    phone: '46',
  },
  {
    code: 'SG',
    label: 'Singapore',
    phone: '65',
  },
  {
    code: 'SH',
    label: 'Saint Helena',
    phone: '290',
  },
  {
    code: 'SI',
    label: 'Slovenia',
    phone: '386',
  },
  {
    code: 'SJ',
    label: 'Huippuvuoret ja Jan Mayen',
    phone: '47',
  },
  {
    code: 'SK',
    label: 'Slovakia',
    phone: '421',
  },
  {
    code: 'SL',
    label: 'Sierra Leone',
    phone: '232',
  },
  {
    code: 'SM',
    label: 'San Marino',
    phone: '378',
  },
  {
    code: 'SN',
    label: 'Senegal',
    phone: '221',
  },
  {
    code: 'SO',
    label: 'Somalia',
    phone: '252',
  },
  {
    code: 'SR',
    label: 'Surinam',
    phone: '597',
  },
  {
    code: 'SS',
    label: 'Etelä-Sudan',
    phone: '211',
  },
  {
    code: 'ST',
    label: 'São Tomé ja Príncipe',
    phone: '239',
  },
  {
    code: 'SV',
    label: 'El Salvador',
    phone: '503',
  },
  {
    code: 'SX',
    label: 'Sint Maarten',
    phone: '1-721',
  },
  {
    code: 'SY',
    label: 'Syyria',
    phone: '963',
  },
  {
    code: 'SZ',
    label: 'Eswatini',
    phone: '268',
  },
  {
    code: 'TC',
    label: 'Turks- ja Caicossaaret',
    phone: '1-649',
  },
  {
    code: 'TD',
    label: 'Tšad',
    phone: '235',
  },
  {
    code: 'TF',
    label: 'Ranskan eteläiset alueet',
    phone: '262',
  },
  {
    code: 'TG',
    label: 'Togo',
    phone: '228',
  },
  {
    code: 'TH',
    label: 'Thaimaa',
    phone: '66',
  },
  {
    code: 'TJ',
    label: 'Tadžikistan',
    phone: '992',
  },
  {
    code: 'TK',
    label: 'Tokelau',
    phone: '690',
  },
  {
    code: 'TL',
    label: 'Itä-Timor',
    phone: '670',
  },
  {
    code: 'TM',
    label: 'Turkmenistan',
    phone: '993',
  },
  {
    code: 'TN',
    label: 'Tunisia',
    phone: '216',
  },
  {
    code: 'TO',
    label: 'Tonga',
    phone: '676',
  },
  {
    code: 'TR',
    label: 'Turkki',
    phone: '90',
  },
  {
    code: 'TT',
    label: 'Trinidad ja Tobago',
    phone: '1-868',
  },
  {
    code: 'TV',
    label: 'Tuvalu',
    phone: '688',
  },
  {
    code: 'TW',
    label: 'Taiwan',
    phone: '886',
  },
  {
    code: 'TZ',
    label: 'Tansania',
    phone: '255',
  },
  {
    code: 'UA',
    label: 'Ukraina',
    phone: '380',
  },
  {
    code: 'UG',
    label: 'Uganda',
    phone: '256',
  },
  {
    code: 'US',
    label: 'Yhdysvallat',
    phone: '1',
  },
  {
    code: 'UY',
    label: 'Uruguay',
    phone: '598',
  },
  {
    code: 'UZ',
    label: 'Uzbekistan',
    phone: '998',
  },
  {
    code: 'VA',
    label: 'Vatikaani',
    phone: '379',
  },
  {
    code: 'VC',
    label: 'Saint Vincent ja Grenadiinit',
    phone: '1-784',
  },
  {
    code: 'VE',
    label: 'Venezuela',
    phone: '58',
  },
  {
    code: 'VG',
    label: 'Brittiläiset Neitsytsaaret',
    phone: '1-284',
  },
  {
    code: 'VI',
    label: 'Yhdysvaltain Neitsytsaaret',
    phone: '1-340',
  },
  {
    code: 'VN',
    label: 'Vietnam',
    phone: '84',
  },
  {
    code: 'VU',
    label: 'Vanuatu',
    phone: '678',
  },
  {
    code: 'WF',
    label: 'Wallis ja Futuna',
    phone: '681',
  },
  {
    code: 'WS',
    label: 'Samoa',
    phone: '685',
  },
  {
    code: 'XK',
    label: 'Kosovo',
    phone: '383',
  },
  {
    code: 'YE',
    label: 'Jemen',
    phone: '967',
  },
  {
    code: 'YT',
    label: 'Mayotte',
    phone: '262',
  },
  {
    code: 'ZA',
    label: 'Etelä-Afrikka',
    phone: '27',
  },
  {
    code: 'ZM',
    label: 'Sambia',
    phone: '260',
  },
  {
    code: 'ZW',
    label: 'Zimbabwe',
    phone: '263',
  },
];
