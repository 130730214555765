/* eslint-disable max-len */
import { motion } from 'framer-motion';
import styled$ from 'utils/react/styled$';

export const BackgroundImage = styled$(motion.div)<{
  $imageUrl?: string;
}>(({
  $imageUrl,
}) => `
  position: absolute;
  width: 100vw;
  height: 100dvh;
  background-size: cover;
  background-image: url('${$imageUrl ?? 'https://images.unsplash.com/photo-1689046194076-62199393c464?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80'}');
`);
