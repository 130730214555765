/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable require-jsdoc */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimatePresence, motion } from 'framer-motion';
import { ResetPasswordForm } from './ResetPasswordForm';
import { SignInFormView } from './SignInForm/SignInFormView';
import { useRouter } from 'next/router';
import Center from '../../common/layout/Center';
import React, { useCallback, useState } from 'react';
import SignUpForm from './SignUpForm';
import styled from '@emotion/styled';
import useStopPropagation from 'utils/hooks/useStopPropagation';
import type {
  FeatureId, Invitation, PlanId, ShortUserRoleId, User
} from '@shared/schema/src';
import type { LoginProps } from '@mindhiveoy/react-auth';
import type { ModuleId } from '@shared/schema/src/modules';
import type { PropsWithChildren } from 'react';
import type { WithId } from '@mindhiveoy/schema';

export interface SignInFormType {
  email: string;
  password: string;
}

interface LayoutElementProps {
  layoutId: string;
}

export const LayoutElement = ({
  layoutId,
  children,
}: PropsWithChildren<LayoutElementProps>) => {
  const handleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  return <motion.div
    layout
    style={{
      width: '100%',
    }}
    layoutId={layoutId}
    onClick={handleClick}
    initial="hidden"
    animate="visible"
    exit="hidden"
  >
    {children}
  </motion.div>;
};

const FullScreen = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 10000;
`;

const FormContainer = styled.div(({
  theme,
}) => `
  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${theme.spacing(1, 2)};
  overflow-y: auto;
`);

interface FormLayoutProps {
  onClick?: React.MouseEventHandler;
}

const FormLayout = ({
  // layoutId,
  children,
  onClick,
}: PropsWithChildren<FormLayoutProps>) =>
  <motion.div
    // variants={formVariants}
    initial={false}
    animate="visible"
    exit="hidden"
    key="login"
    onClick={onClick}
    style={{
      width: '100%',
      maxWidth: 600, // TODO adjust size with clamp
    }}
  >
    {children}
  </motion.div>;

export type SignInFormMode = 'sign-up' | 'sign-in' | 'reset-password';

/**
 * SignIn component for the application. This component will contain the sign in form and
 */
export interface SignInProps extends LoginProps<PlanId, ModuleId, FeatureId, ShortUserRoleId, User<ShortUserRoleId>> {
  invitation?: WithId<Invitation<ShortUserRoleId>>;
  mode?: SignInFormMode;
  planId?: PlanId;
  proposedMethod?: 'sign-in' | 'sign-up';
  reloadAfter?: boolean;
  signUpFromTitlePage?: boolean;
  onSignedIn?: (invitation?: WithId<Invitation<ShortUserRoleId>>) => void;
}

export const SignIn: React.FunctionComponent<Partial<SignInProps>> = ({
  authState,
  invitation,
  mode,
  planId,
  proposedMethod = 'sign-in',
  reloadAfter,
  signUpFromTitlePage = false,
  onSignedIn,
}) => {
  const {
    query: {
      invitationId,
    },
  } = useRouter();

  const [method, setMethod,] = useState<SignInFormMode>(
    () => mode === 'sign-up' || invitationId !== undefined ? 'sign-up' : proposedMethod
  );

  const handleFormAreaClick = useStopPropagation();

  const handleForgotPasswordClick = useCallback(() => {
    setMethod('reset-password');
  }, []);

  return (
    <FullScreen>
      <Center>
        <AnimatePresence initial={false}>
          <FormContainer>
            {method === 'sign-in' ?
              <FormLayout
                key='login-form'
                onClick={handleFormAreaClick}
              >
                <SignInFormView
                  invitation={invitation}
                  onForgotPasswordClick={handleForgotPasswordClick}
                  onSignedIn={onSignedIn}
                  onRegisterClick={invitationId ? () => setMethod('sign-up') : undefined}
                />
              </FormLayout> : null}

            {method === 'sign-up' ?
              <FormLayout
                key='Sign up-form'
                onClick={handleFormAreaClick}
              >
                <SignUpForm
                  authState={authState!}
                  invitation={invitation}
                  mode={mode}
                  planId={planId}
                  reloadAfter={reloadAfter}
                  setMethod={setMethod}
                  signUpFromTitlePage={signUpFromTitlePage}
                  onSignedIn={onSignedIn}
                />
              </FormLayout> : null}

            {method === 'reset-password' ?
              <ResetPasswordForm setMethod={setMethod} /> : null}

          </FormContainer>
        </AnimatePresence>
      </Center>
    </FullScreen>);
};

export default SignIn;
