/* eslint-disable @typescript-eslint/no-explicit-any */
import { ADMIN_BUNDLE } from '@shared/schema/src/https/bundles';
import { createBundleFunctionFactory } from 'utils/firebase/bundles/createBundleFunctionFactory';
import type {
  PexelsVideoSearchMessage, RandomUnsplashPhotoMessage, TrackUnsplashDownloadMessage,
  UnsplashPhotosSearchMessage
} from '@shared/schema/src/https/media';

const factory = createBundleFunctionFactory(ADMIN_BUNDLE);

export const randomUnsplashPhoto = factory<RandomUnsplashPhotoMessage>('random-unsplash-photo');

export const unsplashPhotosSearch = factory<UnsplashPhotosSearchMessage>('unsplash-photos-search');

export const trackUnsplashDownload = factory<TrackUnsplashDownloadMessage>('track-unsplash-download');

export const pexelsVideoSearch = factory<PexelsVideoSearchMessage>('pexels-video-search');
