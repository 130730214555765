import { Item } from './Item';
import { useFeedItems } from '../../../bloc/feed/FeedBloc';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Feed from '../Feed';
import React, { useEffect } from 'react';
import type { FeedItem } from '../../../@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

const ProjectFeed = () => {
  const {
    t,
  } = useTranslation();

  const feedBloc = useFeedItems();

  const [items, setItems,] = useState<WithId<FeedItem>[]>([]);

  useEffect(
    () => feedBloc.subscribe(setItems),
    [feedBloc,]
  );

  return <Feed
    layout>{
      items.length ?
        items.map((item) => <Item
          key={item._id}
          item={item} />) :
        t('feed-is-empty')
    }</Feed>;
};

export default ProjectFeed;
