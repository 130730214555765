/* eslint-disable @typescript-eslint/no-explicit-any */

import { FormContext } from './Form';
import FieldMessage from './messages/FieldMessage';
import FormControl from '@mui/material/FormControl';
import React, { useContext } from 'react';
import styled$ from 'utils/react/styled$';
import type { FormObject } from 'ts-react-form-validator';
import type { PropsWithChildren } from 'react';

const StyledFormControl = styled$(FormControl)<{
  $horizontal?: boolean;
  $withTopMargin?: boolean;
  $withBottomMargin?: boolean;
}>(({
  theme,
  $horizontal,
  $withTopMargin,
  $withBottomMargin,
}) => `
  width: 100%;
  margin-top: ${$withTopMargin ? theme.spacing(2) : 0};
  margin-bottom: ${$withBottomMargin ? theme.spacing(2) : 0};
  ${$horizontal && `
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
  &:last-child {
    margin-bottom: ${theme.spacing(0)};
  }
`);

export interface FormElementProps<T> {
  field?: keyof T,
  horizontal?: boolean;
  withTopMargin?: boolean;
  withBottomMargin?: boolean;
}
const FormElement = <T extends FormObject,>({
  children,
  field,
  horizontal,
  withTopMargin,
  withBottomMargin,
}: PropsWithChildren<FormElementProps<T>>): JSX.Element => {
  const {
    messages,
  } = useContext(FormContext);

  return (
    <StyledFormControl
      $horizontal={horizontal}
      $withTopMargin={withTopMargin ?? false}
      $withBottomMargin={withBottomMargin ?? false}
    >
      {children}
      {field && <FieldMessage<T>
        field={field}
        messages={messages}
      />}
    </StyledFormControl>
  );
};

export default FormElement;
